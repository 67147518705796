import React from 'react';
import {useIntl} from 'react-intl';
import isEmpty from 'lodash.isempty';
import DocumentIcon from '../../../../../shared/icons/Document';
import useDateFormats from '../../../../../../hooks/useDateFormats';
import './transactionDetails.less';

export default function TransactionDetails({details = {}}) {
    const intl = useIntl();
    const {getLongDate} = useDateFormats();
    const {
        documents,
        signers
    } = details;

    function renderDocument({
        id,
        completed,
        firstSigned,
        lastSigned,
        name
    }) {
        const firstSignedLabel = intl.formatMessage({id: 'esl.templates.usagereport_completion.first_signed', defaultMessage: ''});
        const lastSignedLabel = intl.formatMessage({id: 'esl.templates.usagereport_completion.last_signed', defaultMessage: ''});
        const firstSignedDate = firstSigned
            ? getLongDate(firstSigned)
            : intl.formatMessage({id: 'esl.generic.na', defaultMessage: ''});
        const lastSignedDate = lastSigned
            ? getLongDate(lastSigned)
            : intl.formatMessage({id: 'esl.generic.na', defaultMessage: ''});

        return (
            <div className="transaction-summary-document" key={id}>
                <div className="info-container">
                    <div className="info name-container">
                        <div className="icon-container">
                            <DocumentIcon />
                        </div>
                        <div className="name">
                            {name}
                        </div>
                    </div>
                    <div className="info completed">
                        {completed ? intl.formatMessage({id: 'esl.package.status.completed', defaultMessage: ''}) : ''}
                    </div>
                    <div className="info date">
                        <div className="date-title">
                            {`${firstSignedLabel}:`}
                        </div>
                        <div className="date-value">
                            {firstSignedDate}
                        </div>
                    </div>
                    <div className="info date">
                        <div className="date-title">
                            {`${lastSignedLabel}:`}
                        </div>
                        <div className="date-value">
                            {lastSignedDate}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderRecipient({
        id,
        completed,
        email,
        firstName,
        lastName,
        firstSigned,
        lastSigned
    }) {
        const firstSignedLabel = intl.formatMessage({id: 'esl.templates.usagereport_completion.first_signed', defaultMessage: ''});
        const lastSignedLabel = intl.formatMessage({id: 'esl.templates.usagereport_completion.last_signed', defaultMessage: ''});
        const firstSignedDate = firstSigned
            ? getLongDate(firstSigned)
            : intl.formatMessage({id: 'esl.generic.na', defaultMessage: ''});
        const lastSignedDate = lastSigned
            ? getLongDate(lastSigned)
            : intl.formatMessage({id: 'esl.generic.na', defaultMessage: ''});

        return (
            <div className="transaction-summary-recipient" key={id}>
                <div className="info name-container">
                    <div className="name">
                        {`${firstName} ${lastName}`}
                    </div>
                    <div className="email">
                        {email}
                    </div>
                </div>
                <div className="info completed">
                    {completed ? intl.formatMessage({id: 'esl.package.status.completed', defaultMessage: ''}) : ''}
                </div>
                <div className="info date">
                    <div className="date-title">
                        {`${firstSignedLabel}:`}
                    </div>
                    <div className="date-value">
                        {firstSignedDate}
                    </div>
                </div>
                <div className="info date">
                    <div className="date-title">
                        {`${lastSignedLabel}:`}
                    </div>
                    <div className="date-value">
                        {lastSignedDate}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="transaction-details">
            <div className="transaction-summary-documents-container">
                <div className="title">
                    {`${intl.formatMessage({id: 'esl.generic.documents', defaultMessage: ''})} (${documents?.length || 0})`}
                </div>
                {!isEmpty(documents) && (
                    <div className="transaction-summary-documents">
                        {documents.map(renderDocument)}
                    </div>
                )}
            </div>
            <div className="transaction-summary-recipients-container">
                <div className="title">
                    {`${intl.formatMessage({id: 'esl.account.quota_labels.signers', defaultMessage: ''})} (${signers?.length || 0})`}
                </div>
                {!isEmpty(signers) && (
                    <div className="transaction-summary-recipients">
                        {signers.map(renderRecipient)}
                    </div>
                )}
            </div>
        </div>
    );
}
