import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {HiOutlineX} from '@react-icons/all-files/hi/HiOutlineX';
import get from 'lodash.get';
import {fetchSessionInfo, selectUser} from '../../../Login/userSlice';
import {saveDataAttr} from '../../../Dashboard/dashboardSlice';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';

export default function HideActions({closeActions, isDisabled, tooltipPlacement}) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const personalPreferences = get(user, 'data.personal_preferences', {});

    async function hide() {
        await dispatch(saveDataAttr({
            personal_preferences: {
                ...personalPreferences,
                floatingMenu: {
                    ...personalPreferences?.floatingMenu,
                    isEnabled: false
                }
            }
        }));
        dispatch(fetchSessionInfo());
    }

    function openConfirmationModal() {
        setIsConfirmationModalOpen(true);
        closeActions();
    }

    function renderButton() {
        return (
            <div className="floating-action-btn-container">
                <IconButton
                    size="small"
                    classes={{root: 'floating-action-btn hide-actions'}}
                    onClick={openConfirmationModal}
                    disabled={isDisabled}
                >
                    <HiOutlineX aria-hidden className="icon" />
                </IconButton>
            </div>
        );
    }

    return (
        <>
            {isDisabled ? renderButton() : (
                <Tooltip
                    placement={tooltipPlacement}
                    title={intl.formatMessage({id: 'shared.floatingActions.hide', defaultMessage: ''})}
                    arrow
                >
                    {renderButton()}
                </Tooltip>
            )}
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                descriptionKeys={[
                    'shared.floatingActions.hide.description',
                    'shared.floatingActions.hide.confirmation'
                ]}
                setIsOpen={setIsConfirmationModalOpen}
                onConfirm={hide}
            />
        </>
    );
}
