import React from 'react';
import Bullet from '../Bullet/Bullet';
import './bullets.less';

export default function Bullets({steps, activeStep, onChange}) {
    if (!steps) {
        return null;
    }

    const stepsComponent = [];

    for (let i = 0; i < steps; i += 1) {
        stepsComponent.push(<Bullet
            step={i}
            isActive={activeStep === i}
            onChange={onChange}
            key={`bullet-${i}`}
        />);
    }

    return (
        <div className="bullets-container">
            <div className="bullets">
                {stepsComponent}
            </div>
        </div>
    );
}
