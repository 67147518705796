import React from 'react';

export default function Azure(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 1000"
            style={{
                enableBackground: 'new 0 0 1000 1000'
            }}
            xmlSpace="preserve"
            {...props}
        >
            <path
                d="M616.7 236.8 473.9 524.6l251.5 291.6-466.9 53 724.5 7.5-366.3-639.9zm-28.2-113.5-328 274.5L17 808.6l207.3-21.1 364.2-664.2z"
                style={{
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                    fill: '#3861ad'
                }}
            />
        </svg>
    );
}
