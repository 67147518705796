import React from 'react';
import classnames from 'classnames';
import './baseLabel.less';

const AppLabel = ({
    children, className, disabled, ...rest
}) => {
    const containerClassName = classnames('app-label', className, {
        disabled
    });
    return (
        <label
            className={containerClassName}
            {...rest}
        >
            {children}
        </label>
    );
};
export default AppLabel;
