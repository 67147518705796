import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, useNavigate, useLocation} from 'react-router';
import queryString from 'query-string';
import List from '@material-ui/core/List';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import {
    fetchSenderTransactionSummary,
    fetchTransactionsSummary,
    selectIsSenderSummaryFetching,
    selectSenderSummary,
    selectSenderSummaryFetchingError,
    selectTransactionsSummary,
    selectTransactionsSummaryCount
} from '../../reportsSlice';
import {PER_PAGE_OPTIONS, REPORTS_PATH, TRANSACTION_SUMMARY_STATUSES} from '../../../../../constants';
import TypeSelector from './TypeSelector/TypeSelector';
import TransactionSummary from './TransactionSummary/TransactionSummary';
import PagePagination from '../../../shared/Pagination/Pagination';
import PageTitle from '../../../shared/PageTitle';
import useDateFormats from '../../../../hooks/useDateFormats';
import './senderSummary.less';

function getStatusToShow(summary = {}, params) {
    if (!isEmpty(summary.packages)) {
        if (params && params.status && summary.packages[params.status] > 0) {
            return params.status;
        }
        return TRANSACTION_SUMMARY_STATUSES
            .find((transactionStatus) => summary.packages[transactionStatus] && summary.packages[transactionStatus] > 0);
    }
    return '';
}

function getSenderSummary(summaries = [], senderId = '') {
    if (!isEmpty(summaries)) {
        return summaries.find((summary) => summary?.sender?.id === senderId) || {};
    }
    return {};
}

export default function SenderSummary() {
    const intl = useIntl();
    const {getLongDate} = useDateFormats();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const params = queryString.parse(location.search);
    const [from, setFrom] = useState(params.from || 1);
    const [to, setTo] = useState(params.to || 20);
    const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS['20']);
    const sendersSummary = useSelector(selectSenderSummary);
    const transactionsSummary = useSelector(selectTransactionsSummary);
    const transactionsSummaryCount = useSelector(selectTransactionsSummaryCount);
    const isSenderSummaryFetching = useSelector(selectIsSenderSummaryFetching);
    const senderSummaryFetchingError = useSelector(selectSenderSummaryFetchingError);
    const selectedSummary = getSenderSummary(sendersSummary, params.senderId);
    const statusToShow = getStatusToShow(selectedSummary, params);
    const [status, setStatus] = useState(statusToShow);
    const hasParams = !!params.startDate && !!params.endDate && !!params.senderId;
    const startDateLabel = getLongDate(params.startDate);
    const endDateLabel = getLongDate(params.endDate);

    async function fetchTransactions(summary, options) {
        if (!isEmpty(summary) && !isEmpty(summary.packages)) {
            dispatch(fetchTransactionsSummary(options));
        }
    }

    async function fetchSenderTransaction() {
        if (isEmpty(selectedSummary) && !isSenderSummaryFetching && !senderSummaryFetchingError && hasParams) {
            const senderTransactionsSummary = await dispatch(fetchSenderTransactionSummary({
                senderId: params.senderId,
                startDate: params.startDate,
                endDate: params.endDate,
                status
            }));
            const senderSummary = getSenderSummary(senderTransactionsSummary, params.senderId);
            const transactionsStatus = getStatusToShow(senderSummary, params);

            fetchTransactions(senderSummary, {
                senderId: params.senderId,
                startDate: params.startDate,
                endDate: params.endDate,
                status: transactionsStatus
            });
            if (status !== transactionsStatus) {
                setStatus(transactionsStatus);
            }
        } else {
            const transactionsStatus = getStatusToShow(selectedSummary, params);

            fetchTransactions(selectedSummary, {
                senderId: params.senderId,
                startDate: params.startDate,
                endDate: params.endDate,
                status: getStatusToShow(selectedSummary, params)
            });
            if (status !== transactionsStatus) {
                setStatus(transactionsStatus);
            }
        }
    }

    function changeStatus(changedStatus) {
        const query = queryString.stringify({
            ...params,
            status: changedStatus
        });
        navigate(`${location.pathname}?${query}`, {replace: true});
        setStatus(changedStatus);
        fetchTransactions(selectedSummary, {
            ...params,
            status: changedStatus
        });
    }

    function changePage(options) {
        setFrom(options.from);
        setTo(options.to);

        fetchTransactions(selectedSummary, {
            ...params,
            ...options
        });
    }

    function changePerPage(changedPerPage) {
        setPerPage(changedPerPage);
    }

    useEffect(() => {
        fetchSenderTransaction();
    }, []);

    return (
        <div className="sender-summary">
            {!hasParams && <Navigate to={`${REPORTS_PATH}${location.search}`} />}
            <PageTitle
                pageTitle={intl.formatMessage({id: 'esl.partials.reports.transactions_for', defaultMessage: ''}, {
                    senderName: `${get(selectedSummary, 'sender.firstName', '')} ${get(selectedSummary, 'sender.lastName', '')}`
                })}
            />
            <div className="page-title-container">
                <div className="page-title">
                    <h2 className="sub-title">
                        {intl.formatMessage({id: 'esl.generic.account_summary_report', defaultMessage: ''})}
                    </h2>
                    <h1 className="report-name">
                        {intl.formatMessage({id: 'esl.partials.reports.transactions_for', defaultMessage: ''}, {
                            senderName: `${get(selectedSummary, 'sender.firstName', '')} ${get(selectedSummary, 'sender.lastName', '')}`
                        })}
                    </h1>
                </div>
            </div>
            <div className="sender-summary-header">
                <TypeSelector
                    value={status}
                    changeStatus={changeStatus}
                    packages={selectedSummary.packages}
                />
                <div className="start-date-container">
                    {`${intl.formatMessage({id: 'esl.generic.from', defaultMessage: ''})}: ${startDateLabel}`}
                </div>
                <div className="end-date-container">
                    {`${intl.formatMessage({id: 'esl.generic.to', defaultMessage: ''})}: ${endDateLabel}`}
                </div>
            </div>
            <div className="transactions-summary-container">
                <List classes={{root: 'transactions-summary'}}>
                    {transactionsSummary.map((transaction) => <TransactionSummary transaction={transaction} key={transaction.uid} />)}
                </List>
            </div>
            {transactionsSummaryCount > perPage && (
                <div className="sender-summary-pagination-container">
                    <PagePagination
                        from={from}
                        to={to}
                        total={transactionsSummaryCount}
                        perPage={perPage}
                        className="sender-summary-pagination"
                        changePage={changePage}
                        changePerPage={changePerPage}
                    />
                </div>
            )}
        </div>
    );
}
