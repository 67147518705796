import {useIntl} from 'react-intl';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ZoomIn from '../../icons/ZoomIn';
import ZoomOut from '../../icons/ZoomOut';
import ResetZoom from '../../icons/ResetZoom';
import {
    DEFAULT_ZOOM_FACTOR,
    MAX_ZOOM_IN,
    MAX_ZOOM_OUT,
    ZOOM_STEP
} from '../constants';
import './documentsTools.less';
import DocumentsNavigation from './DocumentsNavigation/DocumentsNavigation';
import {roundScaleToNearestZoomStep} from '../utils';

export default function DocumentsTools({
    documentId,
    pageIndex,
    documents = [],
    zoomFactor,
    isZoomVisible = true,
    className,
    navigationProps,
    onZoomChange = () => {},
    onDocumentAndPageChange = () => {},
    renderChild = () => {}
}) {
    const intl = useIntl();
    const classNames = classnames('documents-tool', className);
    const hasNextZoomInFactor = zoomFactor + ZOOM_STEP <= MAX_ZOOM_IN;
    const hasNextZoomOutFactor = zoomFactor - ZOOM_STEP >= MAX_ZOOM_OUT;
    const isDefaultZoomFactor = zoomFactor === DEFAULT_ZOOM_FACTOR;
    const scaleToNearestZoomStep = roundScaleToNearestZoomStep(zoomFactor);
    const zoomFactorInPercentage = scaleToNearestZoomStep * 100;
    const document = documents?.find(({id}) => id === documentId) || {};
    const zoomInClassName = classnames('zoom-in', 'zoom-icon', {
        'is-disabled': hasNextZoomInFactor
    });
    const zoomOutClassName = classnames('zoom-out', 'zoom-icon', {
        'is-disabled': hasNextZoomOutFactor
    });
    const zoomResetClassName = classnames('zoom-reset', 'zoom-icon', {
        'is-disabled': isDefaultZoomFactor
    });

    function zoomIn() {
        if (hasNextZoomInFactor) {
            onZoomChange(zoomFactor + ZOOM_STEP);
        }
    }

    function zoomOut() {
        if (hasNextZoomOutFactor) {
            onZoomChange(zoomFactor - ZOOM_STEP);
        }
    }

    function resetZoom() {
        onZoomChange(DEFAULT_ZOOM_FACTOR);
    }

    return (
        <div className={classNames}>
            <p className="document-name">
                {document.name}
            </p>
            {isZoomVisible && (
                <div className="zoom-tool" id="zoom-tools">
                    <Tooltip
                        placement="top"
                        title={intl.formatMessage({id: 'esl.generic.zoom_out', defaultMessage: ''})}
                        arrow
                    >
                        <IconButton
                            size="small"
                            edge="end"
                            onClick={zoomOut}
                            classes={{root: zoomOutClassName}}
                            disabled={!hasNextZoomOutFactor}
                        >
                            <ZoomOut />
                        </IconButton>
                    </Tooltip>
                    <div className="zoom-factor-percentage">
                        {`${zoomFactorInPercentage}%`}
                    </div>
                    <Tooltip
                        placement="top"
                        title={intl.formatMessage({id: 'esl.generic.zoom_in', defaultMessage: ''})}
                        arrow
                    >
                        <IconButton
                            size="small"
                            edge="end"
                            onClick={zoomIn}
                            classes={{root: zoomInClassName}}
                            disabled={!hasNextZoomInFactor}
                        >
                            <ZoomIn />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="top"
                        title={intl.formatMessage({id: 'page.designer.resetZoom', defaultMessage: ''})}
                        arrow
                    >
                        <IconButton
                            size="small"
                            edge="end"
                            classes={{root: zoomResetClassName}}
                            disabled={isDefaultZoomFactor}
                            onClick={resetZoom}
                            color="inherit"
                        >
                            <ResetZoom />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
            <DocumentsNavigation
                documentId={documentId}
                pageIndex={pageIndex}
                documents={documents}
                navigationProps={navigationProps}
                onDocumentAndPageChange={onDocumentAndPageChange}
            />
            {renderChild()}
        </div>
    );
}
