import {useSelector} from 'react-redux';
import {selectIsInitialStateSet, selectSignerUiPage} from '../signerUiSlice';
import {SIGNER_UI_PAGES} from '../../../../constants';

export default function useSignerPage() {
    const isInitialStateSet = useSelector(selectIsInitialStateSet);
    const signerUiPage = useSelector(selectSignerUiPage);

    return {
        isAttachmentPage: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.ATTACHMENT,
        isDeclinePage: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.DECLINE,
        isHtmlPage: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.HTML,
        isInPersonPage: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.IN_PERSON,
        isInPersonDeclinePage: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.IN_PERSON_DECLINE,
        isOverviewPage: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.OVERVIEW_PAGE,
        isPdfPage: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.PDF,
        isSummary: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.SUMMARY,
        isThankYou: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.THANK_YOU,
        isThankYouHost: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.THANK_YOU_HOST,
        isVrOverviewPage: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.VR_OVERVIEW_PAGE,
        isVirtualRoomPage: isInitialStateSet && signerUiPage === SIGNER_UI_PAGES.VIRTUAL_ROOM,
        pageName: signerUiPage
    };
}
