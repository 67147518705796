import {MAX_DOCUMENTS_CHUNK_SIZE} from '../../constants';

export default function getDocumentsFormDataChunks(documents = []) {
    const documentsChunks = documents
        .slice()
        .reduce((chunks, file) => {
            const lastChunk = chunks[chunks.length - 1] || {};
            const lastAvailableChunk = lastChunk?.available >= file.size ? lastChunk : null;

            if (lastAvailableChunk) {
                lastAvailableChunk.files.push(file);
                lastAvailableChunk.available -= file.size;
            } else {
                chunks.push({
                    files: [file],
                    available: MAX_DOCUMENTS_CHUNK_SIZE - file.size
                });
            }

            return chunks;
        }, [])
        .map(({files}) => files);
    return documentsChunks.map((chunkDocuments) => {
        const formData = new window.FormData();
        const documentsPayload = chunkDocuments.map(({name}) => ({name}));
        chunkDocuments.forEach((document) => {
            formData.append('file', document);
        });
        formData.append('payload', JSON.stringify(documentsPayload));

        return formData;
    });
}
