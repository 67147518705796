import {createSlice} from '@reduxjs/toolkit';
import {getPageFromOffset} from './SigningDocumentView/utils';
import {
    DEFAULT_LOCALE,
    SIGNER_UI_PAGES,
    STORAGE_KEY_SESSION,
    THEMES,
    PAGES_MARGIN_BOTTOM,
    VIEW_MIDPOINT_PADDING
} from '../../../constants';
import {isHostAndSignerInSessionStorage} from '../../utils/storageSelectors';
import {
    viewStatusForInDecline,
    viewStatusForInPersonDecline,
    getInPersonPageUIState,
    getSummaryPageUIState,
    getThankYouPageUIState,
    getThankYouHostPageUIState
} from './utils/rules';

import {
    firstPendingDocumentId,
    firstPendingDocumentIdByAll,
    nextPendingUpload
} from './utils/selectors';

export const signerUiSlice = createSlice({
    name: 'signerUi',
    initialState: {
        screenOrientation: null,
        isInitialStateSet: false,
        transactionId: null,
        selectedSigner: null,
        isLeftMenuOpen: false,
        isDocumentsMenuOpen: false,
        isAttachmentsMenuOpen: false,
        isLeftMenuVisible: false,
        isMoreActionsVisible: false,
        selectedDocumentId: null,
        selectedAttachmentId: null,
        signerUiPage: null,
        theme: THEMES.LIGHT,
        isVirtualRoomHost: false,
        locale: DEFAULT_LOCALE,
        isDeclinePanelOpen: false,
        isReassignPaneOpen: false,
        pageIdx: null,
        isInterfaceHidden: false,
        hoveredField: {
            id: '',
            originalId: '',
            approvalId: '',
            documentId: '',
            actionType: ''
        },
        pagesFields: {
            0: []
        },
        documentFields: [],
        fieldsConditions: [],
        hoveredFieldInCondition: {},
        authObject: {},
        iFrameNotifications: null,
        isPCCOpen: false,
        cachedHanddrawnSignature: null,
        cachedImageSignature: null,
        cachedSignatureData: null,
        isInPersonCeremonyControlledByHost: false,
        animatedSignatureIds: []
    },
    reducers: {
        setInitialState: (state, action) => ({
            ...state,
            ...action.payload,
            selectedDocumentId: action.payload.initialDocumentId,
            isInitialStateSet: true
        }),
        setIsInitialStateSet: (state, action) => {
            state.isInitialStateSet = action.payload;
        },
        setTransactionId: (state, action) => {
            state.transactionId = action.payload;
        },
        setSelectedSigner: (state, action) => {
            state.selectedSigner = action.payload;
        },
        setIsLeftMenuOpen: (state, action) => {
            state.isLeftMenuOpen = action.payload;
            if (!action.payload) {
                state.isDocumentsMenuOpen = false;
                state.isAttachmentsMenuOpen = false;
            }
        },
        setIsDocumentsMenuOpen: (state, action) => {
            state.isDocumentsMenuOpen = action.payload;
        },
        setIsAttachmentsMenuOpen: (state, action) => {
            state.isAttachmentsMenuOpen = action.payload;
        },
        setSelectedDocumentId: (state, action) => {
            state.signerUiPage = SIGNER_UI_PAGES.PDF;
            state.selectedDocumentId = action.payload;
            state.selectedAttachmentId = null;
        },
        setSelectedAttachmentId: (state, action) => {
            state.signerUiPage = SIGNER_UI_PAGES.ATTACHMENT;
            state.selectedAttachmentId = action.payload;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        setIsDeclinePanelOpen: (state, action) => {
            state.isDeclinePanelOpen = action.payload;
        },
        setIsReassignPaneOpen: (state, action) => {
            state.isReassignPaneOpen = action.payload;
        },
        // pages
        goToOverview: (state) => {
            state.signerUiPage = SIGNER_UI_PAGES.OVERVIEW_PAGE;
            state.isLeftMenuVisible = false;
            state.isLeftMenuOpen = false;
            state.isDocumentsMenuOpen = false;
            state.isAttachmentsMenuOpen = false;
            state.isMoreActionsVisible = true;
            state.xExitSigningOption = false;
            state.xLanguageOption = false;
            state.xMoreActions = true;
            state.xZoom = false;
            state.xAccessibility = false;
        },
        goToSummaryPage: (state) => ({...state, ...getSummaryPageUIState()}),
        goToInPersonPage: (state) => ({...state, ...getInPersonPageUIState()}),
        goToThankYouPage: (state) => ({...state, ...getThankYouPageUIState()}),
        goToThankYouHostPage: (state) => ({...state, ...getThankYouHostPageUIState()}),
        startSigning: (state) => {
            state.signerUiPage = SIGNER_UI_PAGES.PDF;
            state.isLeftMenuVisible = true;
            state.isLeftMenuOpen = false;
            state.isDocumentsMenuOpen = false;
            state.isAttachmentsMenuOpen = false;
            state.isMoreActionsVisible = true;
        },
        startInPersonSigning: (state) => {
            state.isInPersonCeremonyControlledByHost = true;
            state.signerUiPage = SIGNER_UI_PAGES.PDF;
            state.isLeftMenuVisible = true;
            state.isLeftMenuOpen = false;
            state.isDocumentsMenuOpen = false;
            state.isAttachmentsMenuOpen = false;
            state.isMoreActionsVisible = true;
        },
        openDocument: (state, {payload}) => {
            const {id} = payload;
            state.signerUiPage = SIGNER_UI_PAGES.PDF;
            state.isAttachmentsMenuOpen = false;
            state.isDocumentsMenuOpen = true;
            state.isLeftMenuOpen = true;
            state.isLeftMenuVisible = true;
            state.selectedDocumentId = id;
        },
        // TODO: should be refactor in order to be reused
        openAttachment: (state, payload) => {
            const {id} = payload;
            state.signerUiPage = SIGNER_UI_PAGES.ATTACHMENT;
            state.isAttachmentsMenuOpen = true;
            state.isDocumentsMenuOpen = false;
            state.isLeftMenuVisible = true;
            state.isLeftMenuOpen = true;
            state.selectedAttachmentId = id;
            state.selectedDocumentId = null;
        },
        exitInPersonSigning: (state) => ({
            isInPersonCeremonyControlledByHost: false,
            ...state,
            ...getInPersonPageUIState()
        }),
        doneThankYou: (state, action) => {
            const {
                role, inPersonCeremonyHost, isTransactionCompleted
            } = action.payload;

            const {locale} = state;
            const {signerId: hostSignerId, localLanguage} = inPersonCeremonyHost;
            const isRoleHost = hostSignerId && hostSignerId === role.signerId;

            if (isRoleHost) {
                if (isTransactionCompleted) {
                    if (localLanguage !== locale) {
                        state.locale = localLanguage;
                    }
                    return {...state, ...getThankYouHostPageUIState()};
                }
                return {...state, ...getInPersonPageUIState()};
            }
            return {...state, ...getThankYouPageUIState()};
        },
        setOffsetAndPageIdx: (state, action) => {
            const {scrollTop, pages} = action;
            const {availableHeight, zoomFactor} = state;

            const nextPage = getPageFromOffset(
                PAGES_MARGIN_BOTTOM,
                VIEW_MIDPOINT_PADDING
            )(
                pages,
                availableHeight,
                zoomFactor
            )(scrollTop);

            state.pageIdx = nextPage.index;
        },
        // TODO Needs to refactor code related to app navigation, it's too difficult to read
        doneWithDocument: (state, action) => {
            const {isTransactionCompleted, signerId, inPersonCeremonyHost} = action.payload;

            if (inPersonCeremonyHost) {
                if (isTransactionCompleted && inPersonCeremonyHost.signerId === signerId
                ) {
                    return {...state, ...getThankYouHostPageUIState()};
                }

                if (inPersonCeremonyHost.signerId !== signerId && !isHostAndSignerInSessionStorage(STORAGE_KEY_SESSION)
                ) {
                    return {...state, ...getSummaryPageUIState({isMoreActionsVisible: state.isMoreActionsVisible})};
                }

                return {
                    ...state,
                    ...getThankYouPageUIState(),
                    isReviewing: false,
                    isLeftMenuOpen: false
                };
            }
        },

        openFirstPendingDocument: (state, action) => {
            const documents = action.payload;
            state.isLeftMenuOpen = true;
            state.isDocumentsMenuOpen = true;
            state.isAttachmentsMenuOpen = false;
            state.selectedDocumentId = state.isVirtualRoomSigning
                ? firstPendingDocumentIdByAll(documents)
                : firstPendingDocumentId(documents);
            state.selectedAttachmentId = null;
            state.signerUiPage = SIGNER_UI_PAGES.PDF;
            state.xZoom = true;
        },
        openNextPendingUpload: (state, action) => {
            state.isAttachmentsMenuOpen = true;
            state.isLeftMenuOpen = true;
            state.isDocumentsMenuOpen = false;
            state.selectedDocumentId = null;
            const attachments = action.payload;
            const selectedAttachmentId = nextPendingUpload(
                attachments,
                state.selectedAttachmentId
            )?.id;
            state.selectedAttachmentId = selectedAttachmentId;
            state.lastSelectedAttachmentTimestamp = Date.now();
            state.signerUiPage = SIGNER_UI_PAGES.ATTACHMENT;
        },
        showDeclineMessageView: (state) => {
            const view = state.inPersonCeremonyHost
                ? viewStatusForInPersonDecline
                : viewStatusForInDecline;
            return {...state, ...view};
        },
        setHoveredField: (state, action) => {
            state.hoveredField = action.payload;
        },
        setPagesFields: (state, action) => {
            const {pageNumber} = action.payload;
            if (!pageNumber) return;
            const updatedFieldsList = {
                ...state.pagesFields,
                [pageNumber]: action.payload.pageFields
            };
            state.pagesFields = updatedFieldsList;
            state.documentFields = Object.values(updatedFieldsList).flat();
        },
        setFieldsConditions: (state, action) => {
            state.fieldsConditions = action.payload;
        },
        setHoveredFieldInCondition: (state, action) => {
            state.hoveredFieldInCondition = action.payload;
        },
        setSignerUiPage: (state, action) => {
            state.signerUiPage = action.payload;
        },
        setAuthObject: (state, action) => {
            state.authObject = action.payload;
        },
        setScreenOrientation: (state, action) => {
            state.screenOrientation = action.payload;
        },
        setIFrameNotifications: (state, action) => {
            state.iFrameNotifications = action.payload;
        },
        setIsPCCOpen: (state, action) => {
            state.isPCCOpen = action.payload;
        },
        setIsInterfaceHidden: (state, action) => {
            state.isInterfaceHidden = action.payload;
        },
        setCachedHanddrawnSignature: (state, action) => {
            state.cachedHanddrawnSignature = action.payload;
        },
        setCachedImageSignature: (state, action) => {
            state.cachedImageSignature = action.payload;
        },
        setCachedSignatureData: (state, action) => {
            state.cachedSignatureData = action.payload;
        },
        clearCachedSignatureData: (state, action) => {
            state.cachedSignatureData = action.payload;
        },
        clearCachedSignatures: (state) => {
            state.cachedHanddrawnSignature = null;
            state.cachedImageSignature = null;
        },
        setAnimatedSignatureId: (state, action) => {
            if (!state.animatedSignatureIds.includes(action.payload)) {
                state.animatedSignatureIds.push(action.payload);
            }
        },
        removeAnimatedSignatureId: (state, action) => {
            state.animatedSignatureIds = state.animatedSignatureIds.filter(sigId => sigId !== action.payload);
        }
    }
});

export const {
    setInitialState,
    setTransactionId,
    setSelectedSigner,
    setIsLeftMenuOpen,
    setIsDocumentsMenuOpen,
    setIsAttachmentsMenuOpen,
    setSelectedDocumentId,
    setSelectedAttachmentId,
    setTheme,
    setIsDeclinePanelOpen,
    setIsReassignPaneOpen,
    goToOverview,
    goToSummaryPage,
    goToInPersonPage,
    goToThankYouPage,
    goToThankYouHostPage,
    startSigning,
    startInPersonSigning,
    exitInPersonSigning,
    doneThankYou,
    setOffsetAndPageIdx,
    doneWithDocument,
    openFirstPendingDocument,
    openNextPendingUpload,
    showDeclineMessageView,
    setHoveredField,
    setPagesFields,
    setFieldsConditions,
    setHoveredFieldInCondition,
    setIsInitialStateSet,
    setSignerUiPage,
    setAuthObject,
    openDocument,
    openAttachment,
    setIFrameNotifications,
    setIsPCCOpen,
    setIsInterfaceHidden,
    setCachedHanddrawnSignature,
    setCachedImageSignature,
    clearCachedSignatures,
    setAnimatedSignatureId,
    removeAnimatedSignatureId,
    setCachedSignatureData,
    clearCachedSignatureData
} = signerUiSlice.actions;

export const selectIsInitialStateSet = (state) => state.signerUi.isInitialStateSet;
export const selectTransactionId = (state) => state.signerUi.transactionId;
export const selectSelectedSigner = (state) => state.signerUi.selectedSigner;
export const selectIsLeftMenuOpen = (state) => state.signerUi.isLeftMenuOpen;
export const selectIsDocumentsMenuOpen = (state) => state.signerUi.isDocumentsMenuOpen;
export const selectIsAttachmentsMenuOpen = (state) => state.signerUi.isAttachmentsMenuOpen;
export const selectSelectedDocumentId = (state) => state.signerUi.selectedDocumentId;
export const selectSelectedAttachmentId = (state) => state.signerUi.selectedAttachmentId;
export const selectIsLeftMenuVisible = (state) => state.signerUi.isLeftMenuVisible;
export const selectIsMoreActionsVisible = (state) => state.signerUi.isMoreActionsVisible;
export const selectSignerUiPage = (state) => state.signerUi.signerUiPage;
export const selectTheme = (state) => state.signerUi.theme;
export const selectIsVirtualRoomHost = (state) => state.signerUi.isVirtualRoomHost;
export const selectIsDeclinePanelOpen = (state) => state.signerUi.isDeclinePanelOpen;
export const selectIsReassignPaneOpen = (state) => state.signerUi.isReassignPaneOpen;
export const selectHoveredField = (state) => state.signerUi.hoveredField;
export const selectPagesFields = (state) => state.signerUi.pagesFields;
export const selectDocumentFields = (state) => state.signerUi.documentFields;
export const selectFieldsConditions = (state) => state.signerUi.fieldsConditions;
export const selectHoveredFieldInCondition = (state) => state.signerUi.hoveredFieldInCondition;
export const selectAuthObject = (state) => state.signerUi.authObject;
export const selectScreenOrientation = (state) => state.signerUi.screenOrientation;
export const selectIFrameNotifications = (state) => state.signerUi.iFrameNotifications;
export const selectIsPCCOpen = (state) => state.signerUi.isPCCOpen;
export const selectIsInterfaceHidden = (state) => state.signerUi.isInterfaceHidden;
export const selectCachedHanddrawnSignature = (state) => state.signerUi.cachedHanddrawnSignature;
export const selectCachedImageSignature = (state) => state.signerUi.cachedImageSignature;
export const selectisInPersonCeremonyControlledByHost = (state) => state.signerUi.isInPersonCeremonyControlledByHost;
export const selectAnimatedSignatureIds = (state) => state.signerUi.animatedSignatureIds;
export const selectCachedSignatureData = (state) => state.signerUi.cachedSignatureData;

export default signerUiSlice.reducer;
