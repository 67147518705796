/* eslint-disable react/no-danger */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {HiDocumentDuplicate} from '@react-icons/all-files/hi/HiDocumentDuplicate';
import {HiOutlineDocumentDuplicate} from '@react-icons/all-files/hi/HiOutlineDocumentDuplicate';
import DOMPurify from 'isomorphic-dompurify';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import classnames from 'classnames';
import {
    BROADCAST_MESSAGES,
    DEFAULT_FONT_SIZE,
    SHORTCUTS,
    TRANSACTION_PATH
} from '../../../../../../constants';
import {fetchTransactionsSummary, selectTransactionsSummary} from '../../../../Transactions/transactionsSlice';
import {createTransaction, selectIsCreatingTransaction} from '../../../../Dashboard/dashboardSlice';
import {saveReminderConfig} from '../../../../Transaction/transactionSlice';
import useBroadcaster from '../../../../../hooks/useBroadcaster';
import {configurePackageDueDate} from '../../../../../utils/helpers';
import {selectUser} from '../../../../Login/userSlice';
import useMarkedMessage from '../../../../SignerUi/hooks/useMarkedMessage';
import {setIsGlobalLoading} from '../../../../appSlice';
import {fetchTemplate} from '../../../../Template/templateSlice';
import {getSubMenuStyle} from '../../stylesUtils';
import './cloneTransaction.less';

export default function CloneTransaction({
    closeActions,
    setActiveAction,
    isOpen: isMenuOpen,
    tooltipPlacement,
    isOnLeft,
    isVertical
}) {
    const intl = useIntl();
    const broadcast = useBroadcaster();
    const formatMessage = useMarkedMessage();
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const transactionsSummary = useSelector(selectTransactionsSummary);
    const isCreatingTransaction = useSelector(selectIsCreatingTransaction);
    const {templates = []} = get(user, 'data.personal_preferences.floatingMenu', {});
    const allowCreation = get(transactionsSummary, 'allow_creation', true);
    const isSingleTemplate = templates.length === 1;
    const personalPreferences = get(user, 'data.personal_preferences', {});
    const className = classnames('floating-action-btn-container clone-transaction', {
        'is-loading': isCreatingTransaction,
        'is-open': isOpen
    });
    const cloneTemplateMessage = isSingleTemplate
        ? formatMessage({
            key: 'shared.floatingActions.cloneTransactionFromTemplate',
            options: {name: templates?.[0]?.name}
        })
        : intl.formatMessage({id: 'shared.floatingActions.cloneTransaction', defaultMessage: ''});
    const message = allowCreation
        ? cloneTemplateMessage
        : intl.formatMessage({id: 'shared.transactionLimit.reached', defaultMessage: ''});
    const openCreatedTransaction = (id) => {
        navigate({
            pathname: `${TRANSACTION_PATH}/${id}`,
            state: {fromDashboard: true}
        });
    };

    function create({id}) {
        return async () => {
            if (!allowCreation) {
                return false;
            }

            closeActions();
            dispatch(setIsGlobalLoading(true));
            const selectedTemplate = await dispatch(fetchTemplate({params: {packageId: id}}));
            const transaction = {
                name: selectedTemplate.name,
                due: configurePackageDueDate({selectedTemplate, personalPreferences}),
                data: {
                    senderVisible: false,
                    origin: 'validsign'
                },
                settings: {
                    ceremony: {
                        fontSize: personalPreferences.fontSizeSelected ? personalPreferences.fontSize : DEFAULT_FONT_SIZE
                    }
                }
            };
            const createdId = await dispatch(createTransaction({
                transaction,
                template: selectedTemplate,
                documents: [],
                bulkCSV: null
            }));

            dispatch(setIsGlobalLoading(false));

            if (createdId) {
                if (personalPreferences.reminder_schedule && !isEmpty(personalPreferences.reminder_schedule_values)) {
                    await dispatch(saveReminderConfig(
                        createdId,
                        get(personalPreferences, 'reminder_schedule_values[0]', {})
                    ));
                }

                openCreatedTransaction(createdId);
                broadcast(BROADCAST_MESSAGES.TRANSACTIONS_UPDATED);
            }
        };
    }

    function toggleSubActions() {
        const newState = !isOpen;
        setIsOpen(newState);
        setActiveAction(newState ? SHORTCUTS.CLONE_TRANSACTION : null);
    }

    function renderTemplate(template, index) {
        const templateShortName = template.name.substring(0, 2);
        const style = getSubMenuStyle({
            index,
            isOpen,
            isVertical,
            isOnLeft,
            length: templates.length
        });

        return (
            <li
                className="template-floating-action-container"
                style={style}
                key={template.id}
            >
                <Tooltip
                    placement="top"
                    title={template.name}
                    arrow
                >
                    <IconButton
                        size="small"
                        classes={{root: 'template-floating-action-btn'}}
                        onClick={create(template)}
                        disabled={isCreatingTransaction || !allowCreation}
                    >
                        {templateShortName}
                    </IconButton>
                </Tooltip>
            </li>
        );
    }

    function renderButton() {
        return (
            <IconButton
                size="small"
                classes={{root: 'floating-action-btn'}}
                onClick={isSingleTemplate ? create(templates[0]) : toggleSubActions}
                disabled={isCreatingTransaction || !allowCreation}
            >
                {isOpen
                    ? <HiDocumentDuplicate aria-hidden className="icon" />
                    : <HiOutlineDocumentDuplicate aria-hidden className="icon" />}
            </IconButton>
        );
    }

    useEffect(() => {
        if (!isMenuOpen) {
            setIsOpen(false);
        }
    }, [isMenuOpen]);

    useEffect(() => {
        dispatch(fetchTransactionsSummary());
    }, []);

    return (
        <div className={className}>
            {!isOpen ? (
                <Tooltip
                    placement={tooltipPlacement}
                    classes={{popper: 'floating-action-btn-tooltip'}}
                    title={(<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(message)}} />)}
                    arrow
                >
                    {renderButton()}
                </Tooltip>
            ) : renderButton()}
            {templates && templates.length > 1 && (
                <ul className="template-floating-actions-list">
                    {templates.map(renderTemplate)}
                </ul>
            )}
        </div>
    );
}
