import {gql} from '@apollo/client/core';

const getCachedSignatureAndDocumentsFragment = gql`
  fragment getCachedSignatureAndDocuments on Transaction {
    isSignatureCaptureEnforced
    cachedSignature
    documents {
      id
      currentSignerApprovals {
        id
        isAccepted
        isFromFile
        originalId
        isSignatureCaptureEnforced
        fields {
          isMobileSig
          isCaptureSig
        }
        fromFileData {
          value
          type
        }
      }
    }
  }
`;

const signerApprovalsFragment = gql`
  fragment signerApprovals on Document {
    signerApprovals {
      id
      isAccepted
      originalId
      isOptional
    }
    allSignersApprovals {
      id
      isAccepted
      originalId
      isOptional
    }
  }
`;
const documentWasReadyToConfirm = gql`
  fragment documentWasReadyToConfirm on Document {
    isReadyToConfirm
    isReadyToConfirmByAll
  }
`;

const roleFragment = gql`
  fragment role on Role {
    id
    progress
  }
`;

const documentIsReadyToConfirm = gql`
  fragment documentIsReadyToConfirm on Document {
    hasAtLeastOneAcceptedApproval
    hasOnlyOptionalApprovals
    hasOptionalNotAcceptedApprovals
    isReadyToConfirm
    isReadyToConfirmByAll
  }
`;
const cachedSignatureFragment = gql`
  fragment cachedSignatureFragment on Transaction {
    cachedSignature
  }
`;

const readPagesFromDocument = gql`
  fragment pages on Document {
    pages {
      index
      signerFields {
        id
        isCaptureSig
        isMobileSig
        approval {
          isAccepted
        }
      }
    }
  }
`;

const roleIdFragment = gql`
  query roleId($transactionId: ID!) {
    ceremony(transactionId: $transactionId) {
      role {
        id
      }
    }
  }
`;

export {
    readPagesFromDocument,
    getCachedSignatureAndDocumentsFragment,
    cachedSignatureFragment,
    documentWasReadyToConfirm,
    documentIsReadyToConfirm,
    signerApprovalsFragment,
    roleFragment,
    roleIdFragment
};
