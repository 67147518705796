import React from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import isEmpty from 'lodash.isempty';
import classNames from 'classnames';
import UploadAttachment from '../UploadAttachment/UploadAttachment';
import InPerson from '../InPerson/InPerson';
import InPersonThankYou from '../pages/InPersonThankYou/InPersonThankYou';
import InPersonThankYouHost from '../pages/InPersonThankYouHost/InPersonThankYouHost';
import Loading from '../../shared/Loading/Loading';
import PageTitle from '../../shared/PageTitle';
import {selectIsInitialStateSet, selectIsLeftMenuVisible} from '../signerUiSlice';
import useSignerPage from '../hooks/useSignerPage';
import useInitialSigningState from '../hooks/useInitialSigningState';
import SigningDocumentView from '../SigningDocumentView/SigningDocumentView';
import {useMessages, useCeremony} from '../hooks/queries';
import DeclinePanel from '../../common/SignerUiHeader/SignerUiMenu/DeclinePanel/DeclinePanel';
import ReassignPanel from '../../common/SignerUiHeader/SignerUiMenu/ReassignPanel/ReassignPanel';
import Decline from '../Decline/Decline';
import InPersonDecline from '../Decline/InPersonDecline';
import useInitializeGraphicalSignature from '../hooks/useInitializeGraphicalSignature';
import TransactionSummary from '../pages/TransactionSummary/TransactionSummary';
import HandOverRedirect from '../pages/HandOverRedirect/HandOverRedirect';
import Welcome from '../Welcome/Welcome';
import {useGeoLocationEvent} from '../hooks/useGeoLocationEvent';
import './signingCeremony.less';
import {shouldDisplayRedirectView} from '../utils/selectors';
import AnimatedSignature from '../../shared/AnimatedSignature/AnimatedSignature';

export default function SigningCeremony() {
    const intl = useIntl();
    const isInitialStateSet = useSelector(selectIsInitialStateSet);
    const hasLeftMenu = useSelector(selectIsLeftMenuVisible);
    const {transaction, role} = useCeremony();
    const handOver = transaction?.settings?.ceremony?.handOver;
    const {isSender} = role;
    const {name} = transaction;
    const {messages} = useMessages();
    const {
        isAttachmentPage,
        isDeclinePage,
        isHtmlPage,
        isInPersonPage,
        isInPersonDeclinePage,
        isOverviewPage,
        isPdfPage,
        isSummary,
        isThankYou,
        isThankYouHost,
        isVrOverviewPage,
        isVirtualRoomPage,
        pageName
    } = useSignerPage();
    const externalVerificationIsRequired = role.verificationType.method === 'EXTERNAL';
    const hasRedirect = !isSender && handOver && handOver.autoRedirect && shouldDisplayRedirectView();
    const hasRedirectESV = !isSender && handOver && handOver.autoRedirect && externalVerificationIsRequired;
    const shouldShowRedirect = hasRedirect || hasRedirectESV;
    const containerClassName = classNames('signing-ceremony', pageName?.toLowerCase(), {
        'has-left-menu': hasLeftMenu
    });
    useInitialSigningState();
    useInitializeGraphicalSignature();
    useGeoLocationEvent();

    return (
        <div className={containerClassName} data-ceremony-role-email={role.email}>
            {!isEmpty(messages) && name && (
                <PageTitle
                    pageTitle={intl.formatMessage({id: 'oss.components.TopBar.appTitle', defaultMessage: ''}, {
                        appTitle: name
                    })}
                />
            )}
            <Loading isLoading={!isInitialStateSet} />
            {isAttachmentPage && <UploadAttachment />}
            {isDeclinePage && <Decline />}
            {isHtmlPage && <div>HtmlPage</div>}
            {isInPersonPage && <InPerson />}
            {isInPersonDeclinePage && <InPersonDecline />}
            {isOverviewPage && <Welcome />}
            {isPdfPage && <SigningDocumentView />}
            {isSummary && shouldShowRedirect && <HandOverRedirect />}
            {isSummary && !shouldShowRedirect && <TransactionSummary />}
            {isThankYou && <InPersonThankYou />}
            {isThankYouHost && <InPersonThankYouHost />}
            {isVrOverviewPage && <div>VrOverviewPage</div>}
            {isVirtualRoomPage && <div>VirtualRoomPage</div>}
            <DeclinePanel />
            <ReassignPanel />
        </div>
    );
}
