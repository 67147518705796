import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import {v4} from 'uuid';
import classnames from 'classnames';
import AppInputLabel from '../../labels/AppInputLabel/AppInputLabel';
import BaseInput from '../BaseInput/BaseInput';
import './appInput.less';

export default function AppInput({
    label,
    helperText,
    error,
    disabled,
    readOnly,
    className,
    id,
    required,
    inputRef,
    htmlFor,
    // props
    inputProps,
    labelProps,
    helperTextProps,
    // mui props which throws an error because we don't need them
    InputLabelProps,
    formatErrorLabel,
    dataWdio,
    ...rest
}) {
    const classNames = classnames('app-input-container', className, {
        disabled, required, error, readOnly
    });
    const uid = v4();
    const htmlForId = htmlFor !== undefined ? htmlFor : id || uid;
    const props = {
        ...rest,
        id: id || htmlForId,
        disabled: disabled || readOnly,
        required,
        error,
        inputProps

    };

    return (
        <div className={classNames}>
            {label && (
                <AppInputLabel
                    disabled={disabled}
                    error={error}
                    htmlFor={htmlForId}
                    required={required}
                    {...labelProps}
                >
                    {label}
                </AppInputLabel>
            )}
            <BaseInput
                {...props}
                dataWdio={dataWdio}
                ref={inputRef || inputProps?.ref}
            />
            {helperText && (
                <FormHelperText error={error} {...helperTextProps}>
                    {helperText}
                </FormHelperText>
            )}
        </div>
    );
}
