import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import {IoMdGlobe} from '@react-icons/all-files/io/IoMdGlobe';
import {GoGlobe} from '@react-icons/all-files/go/GoGlobe';
import {HiOutlineChevronDown} from '@react-icons/all-files/hi/HiOutlineChevronDown';
import {HiOutlineChevronUp} from '@react-icons/all-files/hi/HiOutlineChevronUp';
import get from 'lodash.get';
import {
    setLocale, loadTranslations, selectLocale, fetchInfoLinks
} from '../../../appSlice';
import {saveUserPreferences, selectUser} from '../../../Login/userSlice';
import {LOCALES} from '../../../../../constants';
import './languageSelector.less';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        padding: `${theme.spacing(1)}px 10px`,
        backgroundColor: '#222',
        fontSize: '12px',
        borderRadius: 0
    }
}));

export default function LanguageSelector({shouldShowIcon}) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const currentLocale = useSelector(selectLocale);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const isFatIcons = get(user, 'data.fatIcons');
    const isMenuOpen = !!anchorEl;
    const menuId = 'language-menu';
    const ITEM_HEIGHT_PX = 46;

    function handleMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function changeLanguage(locale) {
        return () => {
            dispatch(setLocale({locale, shouldSetCookie: true}));
            dispatch(loadTranslations());
            dispatch(fetchInfoLinks());
            handleMenuClose();
            if (user && user.id) {
                dispatch(saveUserPreferences({
                    ...user,
                    language: locale
                }));
            }
        };
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT_PX * 9
                }
            }}
            MenuListProps={{
                disablePadding: true,
                classes: {root: 'language-menu-list'}
            }}
        >
            {LOCALES.map((locale) => (
                <MenuItem
                    onClick={changeLanguage(locale)}
                    selected={locale === currentLocale}
                    key={locale}
                    classes={{
                        root: classes.itemRoot
                    }}
                >
                    {intl.formatMessage({id: `esl.language.${locale}`, defaultMessage: ''})}
                </MenuItem>
            ))}
        </Menu>
    );

    const selectorButton = shouldShowIcon
        ? (
            <IconButton
                edge="end"
                aria-label="select language"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color="inherit"
            >
                {!isFatIcons ? <IoMdGlobe aria-hidden="true" /> : <GoGlobe aria-hidden="true" />}
            </IconButton>
        )
        : (
            <button type="button" className="selector-btn-container" onClick={handleMenuOpen}>
                <div className="selector-title">
                    {intl.formatMessage({id: `esl.language.${currentLocale}`, defaultMessage: ''})}
                </div>
                <div className="selector-chevron-container">
                    {isMenuOpen ? (
                        <HiOutlineChevronUp
                            className="selector-chevron"
                            aria-hidden="true"
                        />
                    ) : (
                        <HiOutlineChevronDown
                            className="selector-chevron"
                            aria-hidden="true"
                        />
                    )}
                </div>
            </button>
        );
    return (
        <div className="language-selector">
            <Tooltip
                title={intl.formatMessage({id: 'esl.tooltips.language_list', defaultMessage: ''})}
                arrow
            >
                {selectorButton}
            </Tooltip>
            {renderMenu}
        </div>
    );
}
