import {useContext} from 'react';
import {BroadcastContext} from '../components/BroadcastStation';

export default function useBroadcaster() {
    const {broadcasterChannel, handleMessage} = useContext(BroadcastContext);

    return (message) => {
        handleMessage(message);
        if (broadcasterChannel && broadcasterChannel.postMessage) {
            broadcasterChannel.postMessage(message);
        }
    };
}
