import React, {
    forwardRef, memo, useEffect, useState
} from 'react';
import {isMobile} from 'react-device-detect';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import Input from '@material-ui/core/Input';
import debounce from 'lodash.debounce';
import messages from '../../../../messages/formMessages';
import FieldWrapper from '../../FieldWrapper/FieldWrapper';
import {useScrollContext} from '../../../../../shared/Documents/ScrollContext';

const TextField = forwardRef(({
    originalField,
    field,
    className,
    onChangeField,
    saved,
    setSaved,
    isHovered,
    isFocused,
    setIsFocused,
    hasInteracted,
    setHasInteracted,
    selectField,
    unselectField,
    validationError,
    mutationError,
    updateFieldValue,
    loading,
    errorMessage,
    showErrorTooltip,
    fieldsRef,
    onFocus: onFocusProp = () => {},
    ...props
}, ref) => {
    const {
        id,
        isDisabled,
        isTextArea
    } = field;
    const {formatMessage} = useIntl();
    const hasError = !!errorMessage;
    const classNameContainer = classNames(className, isTextArea ? 'textarea' : 'input', {
        disabled: isDisabled
    });
    const [localInput, setLocalInput] = useState(field.value);

    function handleChange({target}) {
        setLocalInput(target.value);
        debounce(() => onChangeField({name: 'value', value: target.value}), 300)();
    }
    const {
        centerFieldOnFocusZoom,
        setZoomFactor: onZoomChange
    } = useScrollContext();

    const fieldContainerEl = ref?.current?.offsetParent?.offsetParent;
    function handleFocus() {
        setIsFocused(true);
        if (!isFocused && isMobile) {
            centerFieldOnFocusZoom({fieldEl: fieldContainerEl, onZoomChange});
        }

        onFocusProp();
    }

    function handleBlur(event) {
        if (isFocused && isMobile) {
            const zoomToolsContainerEl = document.getElementById('zoom-tools');
            const pagesNavigationContainerEl = document.getElementById('pages-navigation');
            // User tries to navigate to another page
            if (pagesNavigationContainerEl.contains(event.relatedTarget)) {
                onZoomChange(1);

            // Avoid focus reset when user tries to change the zoom value
            } else if (!zoomToolsContainerEl.contains(event.relatedTarget)) {
                centerFieldOnFocusZoom({fieldEl: fieldContainerEl, onZoomChange, forcedZoom: 1});
            }
        }
        setHasInteracted(true);
        setIsFocused(false);
        const newValue = event?.target?.value || '';
        if (newValue === originalField.value) {
            return;
        }
        onChangeField({name: 'value', value: newValue});

        if ((hasError && newValue === originalField.value) || newValue !== originalField.value) {
            updateFieldValue(newValue);
        }
    }

    function preventScrollingDocumentWhenScrollFieldContent() {
        ref.current.addEventListener('touchstart', (e) => {
            e.stopPropagation();
        });
    }

    useEffect(() => {
        if (ref?.current) {
            preventScrollingDocumentWhenScrollFieldContent();
        }
    }, []);

    useEffect(() => {
        setLocalInput(originalField?.value);
    }, [originalField?.value]);

    return (
        <FieldWrapper
            className={classNameContainer}
            field={field}
            fieldsRef={fieldsRef}
            onMouseEnter={selectField}
            onMouseLeave={unselectField}
            withCondition
            loading={loading}
            showErrorTooltip={showErrorTooltip}
            errorMessage={errorMessage}
        >
            <Input
                disableUnderline
                multiline={isTextArea}
                placeholder={formatMessage(messages.placeholder)}
                value={localInput}
                onChange={handleChange}
                disabled={isDisabled}
                id={id}
                onFocus={handleFocus}
                onBlur={handleBlur}
                inputRef={ref}
                {...props}
            />
        </FieldWrapper>

    );
});
export default memo(TextField);
