import React from 'react';
import {useSelector} from 'react-redux';
import {selectVSFeatures} from '../../Login/userSlice';
import {isVsFeatureEnabled} from '../../../utils/helpers';

function Enabled({children}) {
    return children;
}

function Disabled({children}) {
    return children;
}

/**
    example of usage:

    import VSFeature, {Disabled, Enabled} from '...../VSFeature';

    <VSFeature feature="draftPageReworked">
        <Enabled>
            ...
        </Enabled>
        <Disabled>
            ...
        </Disabled>
    </VSFeature>
 */

export default function VSFeature({feature = {}, children = []}) {
    const vsFeatures = useSelector(selectVSFeatures);
    const isEnabled = isVsFeatureEnabled({feature, vsFeatures});
    const childrenArray = Array.isArray(children) ? children : [children];

    return (
        <>
            {childrenArray.map((child) => {
                if ((isEnabled && child.type === Enabled) || (!isEnabled && child.type === Disabled)) {
                    return child;
                }
                return null;
            })}
        </>
    );
}

export {
    Enabled,
    Disabled
};
