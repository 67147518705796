import React from 'react';
import {useIntl} from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import {TRANSACTION_SUMMARY_STATUSES} from '../../../../../../constants';
import AppSelect from '../../../../Core/inputs/AppSelect/AppSelect';

export default function TypeSelector({value, changeStatus, packages = []}) {
    const intl = useIntl();

    const items = TRANSACTION_SUMMARY_STATUSES.map((status) => ({status, amount: packages[status] || 0}));

    function onChange({target}) {
        changeStatus(target.value);
    }

    return (
        <div className="report-type-selector-container">
            <AppSelect
                label={intl.formatMessage({id: 'esl.partials.reports.transaction_status', defaultMessage: ''})}
                value={value}
                onChange={onChange}
                MenuProps={{
                    disablePortal: true
                }}
            >
                {items.map(({status, amount = 0}) => (
                    <MenuItem value={status} key={status} disabled={amount === 0}>
                        {`${intl.formatMessage({id: `esl.package.status.${status}`, defaultMessage: ''})} (${amount})`}
                    </MenuItem>
                ))}
            </AppSelect>
        </div>
    );
}
