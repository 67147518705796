import {useIntl} from 'react-intl';
import {Tooltip} from '@material-ui/core';
import {AppIconButton} from '@components/Core/buttons/AppIconButton/AppIconButton';
import TrashButton from '@components/common/TrashButton/TrashButton';
import {
    MdOutlineUndo,
    MdOutlineRedo
} from 'react-icons/md';
import {FaPlay} from 'react-icons/fa6';

import './styles.less';

export const Panel = ({
    undo, redo, play, clear
}) => {
    const intl = useIntl();

    return (
        <>
            <div className="center-action">
                <Tooltip
                    placement="top"
                    title={intl.formatMessage({id: 'esl.generic.undo', defaultMessage: ''})}
                    arrow
                >
                    <AppIconButton {...undo}>
                        <MdOutlineUndo />
                    </AppIconButton>
                </Tooltip>

                <Tooltip
                    placement="top"
                    title={intl.formatMessage({id: 'esl.generic.play', defaultMessage: ''})}
                    arrow
                >
                    <AppIconButton {...play}>
                        <FaPlay />
                    </AppIconButton>
                </Tooltip>

                <Tooltip
                    placement="top"
                    title={intl.formatMessage({id: 'esl.generic.redo', defaultMessage: ''})}
                    arrow
                >
                    <AppIconButton {...redo} className="redo-button">
                        <MdOutlineRedo />
                    </AppIconButton>
                </Tooltip>

            </div>
            <TrashButton {...clear} className="right-action" />
        </>
    );
};
