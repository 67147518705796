import React from 'react';
import {getCountries, getCountryCallingCode} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import flags from 'react-phone-number-input/flags';
import InternationalIcon from '../internationalIcon';
import BaseSelect from '../../../Core/inputs/BaseSelect/BaseSelect';
import './countrySelect.less';

const CountrySelect = ({
    value, onChange, disabled, ...restProps
}) => {
    const containerClassName = classNames('country-select-container', {disabled});
    return (
        <>
            <div className={containerClassName}>
                {flags[value] ? flags[value](value) : <InternationalIcon />}
                <BaseSelect
                    {...restProps}
                    onChange={(event) => {
                        onChange(event.target.value || '');
                    }}
                    value={value || ''}
                    IconComponent={undefined}
                    options={[]}
                    disabled={disabled}
                    MenuProps={{disablePortal: false}}
                >

                    <MenuItem>
                        {en.ZZ}
                    </MenuItem>
                    {getCountries().map((country) => (
                        <MenuItem value={country} key={country}>
                            {`${en[country]} + ${getCountryCallingCode(country)}`}
                        </MenuItem>
                    ))}
                </BaseSelect>
            </div>
        </>
    );
};

export default CountrySelect;
