import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {GET_DOCUMENTS_WITH_PAGES} from '../../../../../gql/queries';
import {selectTransactionId} from '../../signerUiSlice';

export default function useDocumentsWithPages() {
    const transactionId = useSelector(selectTransactionId);
    const {
        data, error, loading, refetch
    } = useQuery(GET_DOCUMENTS_WITH_PAGES, {
        variables: {transactionId}
    });
    const {ceremony = {}} = data || {};
    const {
        role = {},
        session = {},
        transaction = {}
    } = ceremony;

    return useMemo(() => ({
        role,
        session,
        transaction,
        error,
        loading,
        refetch
    }), [ceremony, error, loading]);
}
