import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import GET_ATTACHMENT_FILE_PREVIEW from '../../../../gql/getAttachmentFilePreview.gql';
import {selectSelectedAttachmentId, selectTransactionId} from '../signerUiSlice';

export default function useAttachmentPreview(id) {
    const transactionId = useSelector(selectTransactionId);
    const attachmentId = useSelector(selectSelectedAttachmentId);
    const {data = {}, error, loading} = useQuery(GET_ATTACHMENT_FILE_PREVIEW, {
        variables: {id, attachmentId, transactionId}
    });
    const {attachmentFilePreview = {}} = data;

    return useMemo(() => ({
        attachmentFilePreview, error, loading
    }), [attachmentFilePreview, error, loading]);
}
