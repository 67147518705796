import React, {forwardRef} from 'react';
import {useIntl} from 'react-intl';
import Radio from '@material-ui/core/Radio';
import classNames from 'classnames';
import get from 'lodash.get';
import SELECT_FIELD from '../../../../../../../gql/selectField.gql';
import useFieldMutation from '../../utils/useFieldMutation';
import {getFieldErrorMessage, shouldShowErrorTooltip} from '../../../utils';

import FieldWrapper from '../../FieldWrapper/FieldWrapper';

const RadioButton = forwardRef(({
    field = {},
    fields,
    className,
    selectField,
    unselectField,
    hasInteracted,
    setHasInteracted,
    isHovered,
    isFocused,
    setIsFocused,
    validationError,
    changeFields,
    fieldsRef

}, ref) => {
    const {
        radioGroup,
        value,
        hasConditions,
        originalId,
        isDisabled
    } = field;

    const {
        updateFieldValue, loading,
        error: mutationError
    } = useFieldMutation({
        approvalId: field?.approval?.originalId,
        hasConditions,
        originalId,
        radioGroupId: radioGroup.id,
        mutation: SELECT_FIELD
    });
    const intl = useIntl();
    const errorMessage = getFieldErrorMessage({intl, mutationError, validationError});
    const showError = hasInteracted && !!errorMessage;
    const classNameContainer = classNames(className, 'radio', {
        loading,
        error: showError
    });
    const showErrorTooltip = shouldShowErrorTooltip({
        isHovered, isFocused, hasInteracted, isDisabled, errorMessage
    });

    const {name: groupName} = radioGroup;
    const isChecked = radioGroup.selectedFieldId === originalId;

    function handleChange() {
        const updatedFieldsWithChangedGroupRadioBtns = fields.map((data) => {
            if (get(data, 'radioGroup.id') === radioGroup.id) {
                return {
                    ...data,
                    radioGroup: {
                        ...data.radioGroup,
                        selectedFieldId: field.originalId
                    }
                };
            }
            return data;
        });
        changeFields(updatedFieldsWithChangedGroupRadioBtns);
        updateFieldValue(true);
    }

    function onFocus() {
        setIsFocused(true);
    }

    function onBlur() {
        setHasInteracted(true);
        setIsFocused(false);
    }

    return (
        <FieldWrapper
            field={field}
            fieldsRef={fieldsRef}
            className={classNameContainer}
            onMouseEnter={selectField}
            onMouseLeave={unselectField}
            showErrorTooltip={showErrorTooltip}
            errorMessage={errorMessage}
            loading={loading}
        >
            <Radio
                name={groupName}
                value={value}
                checked={isChecked}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={isDisabled}
                inputRef={ref}
                tabIndex={0}
            />
        </FieldWrapper>
    );
});

export default RadioButton;
