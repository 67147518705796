export default {
    CHECKBOX: {
        defaultMessage: 'Checkbox',
        id: 'oss.components.Fields.checkBox'
    },
    DATEPICKER: {
        defaultMessage: 'Date field',
        id: 'oss.components.Fields.dateField'
    },
    LIST: {
        defaultMessage: 'List field',
        id: 'oss.components.Fields.listField'
    },
    RADIO: {
        defaultMessage: 'Radio button',
        id: 'oss.components.Fields.radioButton'
    },
    TEXTAREA: {
        defaultMessage: 'Text area',
        id: 'oss.components.Fields.textArea'
    },
    TEXTFIELD: {
        defaultMessage: 'Text field',
        id: 'oss.components.Fields.textField'
    },
    calendar: {
        defaultMessage: 'Calendar',
        id: 'oss.components.Fields.DateField.calendar'
    },
    checkboxGroup: {
        defaultMessage: '{name} checkbox group',
        id: 'oss.components.Fields.checkboxGroup'
    },
    errorMessage: {
        defaultMessage: 'This field is required',
        id: 'oss.components.Fields.Field.requiredFieldMissing'
    },
    exactlyRequired: {
        defaultMessage:
      'Exactly {exactlyRequired} option(s) must be selected for this checkbox group.',
        id: 'oss.components.Fields.Field.exactlyRequiredMissing'
    },
    invalidDate: {
        defaultMessage: 'Date format must be {format}',
        id: 'oss.components.Fields.DateField.invalidDate'
    },
    maxLength: {
        defaultMessage: 'Maximum length of {maxLength} characters exceeded',
        id: 'oss.components.Fields.Field.maxLength'
    },
    maxLengthAllowedAda: {
        defaultMessage:
      'Maximum number of characters allowed for the field is {maxLength}',
        id: 'oss.components.Fields.Field.maxLengthAllowedAda'
    },
    maxLengthReachedAda: {
        defaultMessage: 'You have reached the maximum number of characters allowed',
        id: 'oss.components.Fields.Field.maxLengthReachedAda'
    },
    maximumRequired: {
        defaultMessage:
      'At most {maximumRequired} option(s) must be selected for this checkbox group.',
        id: 'oss.components.Fields.Field.maximumRequiredMissing'
    },
    minLength: {
        defaultMessage: 'Minimum length of {minLength} characters required',
        id: 'oss.components.Fields.Field.minLength'
    },
    minimumRequired: {
        defaultMessage:
      'At least {minimumRequired} option(s) must be selected for this checkbox group.',
        id: 'oss.components.Fields.Field.minimumRequiredMissing'
    },
    pattern: {
        defaultMessage: 'Pattern not respected',
        id: 'oss.components.Fields.textField.pattern'
    },
    placeholder: {
        defaultMessage: 'Enter Text',
        id: 'oss.components.Fields.Field.placeholder'
    },
    radioGroup: {
        defaultMessage: '{name} radio group',
        id: 'oss.components.Fields.radioGroup'
    },
    rangeRequired: {
        defaultMessage:
      '{minimumRequired} to {maximumRequired} option(s) must be selected for this checkbox group.',
        id: 'oss.components.Fields.Field.rangeRequiredMissing'
    },
    required: {
        defaultMessage: 'Required',
        id: 'oss.components.Fields.Field.required'
    }
};
