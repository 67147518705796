import React, {forwardRef} from 'react';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import {useIntl} from 'react-intl';
import isEmpty from 'lodash.isempty';
import FieldWrapper from '../../FieldWrapper/FieldWrapper';
import BaseSelect from '../../../../../Core/inputs/BaseSelect/BaseSelect';

const ListField = forwardRef(({
    field,
    onChangeField,
    className,
    setIsFocused,
    setHasInteracted,
    selectField,
    unselectField,
    updateFieldValue,
    errorMessage,
    showErrorTooltip,
    fieldsRef,
    loading
}, ref) => {
    const {options} = field;
    const intl = useIntl();
    const classNameContainer = classNames(className, 'list');
    const emptyOption = '';
    const emptyOptionLabel = intl.formatMessage({id: 'esl.generic.none', defaultMessage: ''});
    const optionsData = isEmpty(options) ? [emptyOption] : [emptyOption, ...options];

    function onFocus() {
        setIsFocused(true);
    }
    function onBlur() {
        setIsFocused(false);
    }

    function renderOptions() {
        return optionsData && optionsData.map((option) => (
            <MenuItem key={`${field.id}${option.id}`} value={option} className={option === emptyOption ? 'default-option' : ''}>
                {option === emptyOption ? emptyOptionLabel : option}
            </MenuItem>
        ));
    }
    function onChange({target}) {
        setHasInteracted(true);
        const {value: newValue} = target;
        if (field.value !== newValue) {
            onChangeField({name: 'value', value: newValue});
            updateFieldValue(newValue);
        }
    }
    const select = (
        <BaseSelect
            value={field.value}
            className="list-field"
            MenuProps={{
                classes: {list: 'field-list-menu'}
            }}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            inputRef={ref}
            tabIndex={0}
        >
            {renderOptions()}
        </BaseSelect>
    );

    return (
        <FieldWrapper
            field={field}
            fieldsRef={fieldsRef}
            className={classNameContainer}
            onMouseEnter={selectField}
            onMouseLeave={unselectField}
            showErrorTooltip={showErrorTooltip}
            errorMessage={errorMessage}
            loading={loading}
        >
            {select}
        </FieldWrapper>
    );
});

export default ListField;
