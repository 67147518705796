import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import isEmpty from 'lodash.isempty';
import {selectAuthObject, selectTransactionId} from '../signerUiSlice';
import {NEW_DIRECT_SIGN_PATH, TRANSACTION_PATH} from '../../../../constants';
import SmsLogin from './SmsLogin/SmsLogin';
import {useAuthenticate} from '../hooks/mutations';
import {setHostSession} from '../../../utils/storageSelectors';
import {getAuthenticationType} from './utils';
import {hardRedirectTo} from '../../../utils/helpers';
import {SIGNER_GUEST_TYPES} from './constants';
import useNotifications from '../../../hooks/useNotifications';
import QnaLogin from './QnaLogin/QnaLogin';
import './login.less';

export default function Login() {
    const intl = useIntl();
    const navigate = useNavigate();
    const {showErrorAlert} = useNotifications();
    const authObject = useSelector(selectAuthObject);

    const {
        sessionId,
        sms,
        qna
    } = authObject;
    const transactionId = useSelector(selectTransactionId);
    const ceremonyUrl = `${TRANSACTION_PATH}/${transactionId}${NEW_DIRECT_SIGN_PATH}`;
    const {authenticate} = useAuthenticate({
        onCompleted: ({authenticate: authenticateResult}) => {
            const nextAuthMethod = getAuthenticationType(authenticateResult);

            if (nextAuthMethod === SIGNER_GUEST_TYPES.session) {
                setHostSession(authenticateResult.sessionId);
            }

            if (authenticateResult?.redirectTo) {
                return hardRedirectTo(authenticateResult?.redirectTo);
            }

            return navigate(ceremonyUrl, {replace: true});
        },
        onError: (err) => {
            if (!isEmpty(err?.graphQLErrors)) {
                err.graphQLErrors.forEach(({messageKey, message}) => {
                    showErrorAlert(intl.formatMessage({id: messageKey, defaultMessage: message}));
                });
            }
        }
    });

    useEffect(() => {
        if (sessionId) {
            navigate(ceremonyUrl, {replace: true});
        }
    }, []);

    return (
        <div className="signing-login">
            {sms && <SmsLogin authenticate={authenticate} />}
            {qna && <QnaLogin authenticate={authenticate} />}
        </div>
    );
}
