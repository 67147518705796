import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import {getCornerWidgetSize} from '../CornerWidget/utils';
import './fieldLoader.less';

export const FieldLoader = ({
    loading, width, height, ...restProps
}) => {
    const containerClasssName = classNames('field-loader');

    const size = getCornerWidgetSize({width, height});

    return (
        <Fade
            in={loading}
            timeout={{appear: 2000, enter: 2000, exit: 0}}
        >
            <CircularProgress
                className={containerClasssName}
                size={Math.round(size * 0.8)}
                aria-hidden
                {...restProps}
            />
        </Fade>
    );
};
