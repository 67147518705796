import React, {memo} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Tooltip} from '@material-ui/core';
import classNames from 'classnames';
import {getFieldLabel} from '../../../../Designer/utils';
import ConditionIcon from '../ConditionIcon/ConditionIcon';
import FieldMessageTooltip from '../FieldMessageTooltip/FieldMessageTooltip';
import {selectLocale} from '../../../../appSlice';
import {getCustomFieldValue} from '../../utils';
import {getFieldPositionStyles} from '../utils/fieldUtils';
import {CornerWidget} from '../SigningField/CornerWidget/CornerWidget';
import {FieldLoader} from '../SigningField/FieldLoader/FieldLoader';
import {RequiredIcon} from '../RequiredIcon/RequiredIcon';
import './fieldWrapper.less';

const FieldWrapper = ({
    field,
    fieldsRef,
    className = '',
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    children,
    showErrorTooltip,
    errorMessage,
    loading,
    dataWdio,
    isTooltipDisabled,
}) => {
    const intl = useIntl();
    const locale = useSelector(selectLocale);
    const customFieldLabel = getCustomFieldValue({locale, field});
    const fieldTooltipLabelkey = field.name || (field.isCustomField ? customFieldLabel : getFieldLabel(field));
    const spanClassName = classNames('field-wrapper-span');
    const loaderWidgetClassName = classNames('loader-widget', {active: loading});
    const titleNode = (
        <span className="tooltip-title-container">
            {fieldTooltipLabelkey ? intl.formatMessage({id: fieldTooltipLabelkey, defaultMessage: ''}) : ''}
        </span>
    );
    const position = getFieldPositionStyles(field);

    const fieldWrapper = (
        <div
            className={className}
            style={position}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-wdio={dataWdio || `test-field-${field.subtype.toLowerCase()}`}
            data-id={field.originalId}
            data-type={field.type}
            data-left={field.left}
            data-top={field.top}
            data-width={field.width}
            data-height={field.height}
            data-top-boundary={field.pageWithDimensions.topBoundary}
            data-page-to-container-scale-factor={field.pageWithDimensions.pageToContainerScaleFactor}
        >
            <span className={spanClassName}>
                <FieldMessageTooltip open={showErrorTooltip} title={errorMessage}>
                    {children}
                </FieldMessageTooltip>
            </span>
            <ConditionIcon field={field} fieldsRef={fieldsRef} position={position} />
            <CornerWidget width={position.width} height={position.height} className={loaderWidgetClassName}>
                <FieldLoader width={position.width} height={position.height} loading={loading} />
            </CornerWidget>
            <RequiredIcon field={field} position={position} />
        </div>
    );

    return isTooltipDisabled ? fieldWrapper : (
        <Tooltip
            title={titleNode}
            placement="top"
            arrow
        >
            {fieldWrapper}
        </Tooltip>
    );
};
export default memo(FieldWrapper);
