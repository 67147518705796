import {useQuery} from '@apollo/client';
import {useSelector} from 'react-redux';
import {useValidSignThemeAsRoot} from '@ValidSignBV/theme-switcher';
import {GET_THEME, SIGNING_THEMES} from '../../../../../gql/queries';
import {selectTheme, selectTransactionId} from '../../signerUiSlice';
import {getIsExampleTransactionID} from '../../utils/functions';
import {THEMES} from '../../../../../constants';

/**
 * This hook is used to fetch user theme from API and set it to root variables
 */

export const useUserTheme = () => {
    const transactionId = useSelector(selectTransactionId);
    const themeSelected = useSelector(selectTheme) || THEMES.LIGHT;
    const isExampleTransactionID = getIsExampleTransactionID(transactionId);
    const variables = {};
    const query = isExampleTransactionID ? SIGNING_THEMES : GET_THEME;

    if (!isExampleTransactionID) {
        variables.transactionId = transactionId;
    }

    const {data = {}} = useQuery(query, {
        variables
    });
    const {theme = {}, signingThemes = {}} = data;
    const userTheme = isExampleTransactionID ? signingThemes : theme;

    useValidSignThemeAsRoot({
        theme: themeSelected, userColors: userTheme.colors, styleTagId: 'sender-ui-css-vars'
    });
};
