import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import SidePanel from '../../../../shared/SidePanel/SidePanel';
import useDeclineSigning from '../../../../SignerUi/hooks/useDeclineSigning';
import useGetDeclineReasons from '../../../../SignerUi/hooks/useGetDeclineReasons';
import useGetDeclineData from '../../../../SignerUi/hooks/useGetDeclineData';
import useGetHandoverUrl from '../../../../SignerUi/hooks/useGetHandoverUrl';
import {
    selectIsDeclinePanelOpen, selectTransactionId, setIsDeclinePanelOpen, showDeclineMessageView
} from '../../../../SignerUi/signerUiSlice';
import {updateCache} from './utils';
import {getCustomHandOverUrl} from '../../../../SignerUi/utils/functions';
import {redirectTo} from '../../../../../utils/helpers';
import {STORAGE_KEY_FROM_SENDER} from '../../../../../../constants';
import {getValueForKey} from '../../../../../utils/storageSelectors';
import AppInput from '../../../../Core/inputs/AppInput/AppInput';
import {
    PACKAGE_DECLINE_ERROR_EVENT,
    PACKAGE_DECLINE_STARTED_EVENT,
    PACKAGE_DECLINE_SUCCESS_EVENT
} from '../../../../../utils/iFrameNotifyer/events';
import IFrameEventNotifications from '../../../../../utils/iFrameNotifyer/IFrameEventNotifications';
import './declinePanel.less';

export default function DeclinePanel() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const transactionId = useSelector(selectTransactionId);
    const isDeclinePanelOpen = useSelector(selectIsDeclinePanelOpen);
    const iFrameEventNotifications = IFrameEventNotifications.getInstance();
    const {declineSigning, loading} = useDeclineSigning({
        update: updateCache({transactionId}),
        onCompleted: async () => {
            await iFrameEventNotifications.postEvent(PACKAGE_DECLINE_SUCCESS_EVENT);
        },
        onError: async () => {
            await iFrameEventNotifications.postEvent(PACKAGE_DECLINE_ERROR_EVENT);
        }
    });
    const {declineReasons: apiDeclineReasons = []} = useGetDeclineReasons();
    const {transaction, role} = useGetDeclineData();
    const inSender = !!getValueForKey(STORAGE_KEY_FROM_SENDER);
    const [reason, setReason] = useState(null);
    const [otherReasonValue, setOtherReasonValue] = useState('');
    const {
        ceremony: {
            disableDeclineOther,
            declineReasons: packageDeclineReasons = [],
            handOverUrl: packageHandoverUrl,
            handOver: packageHandover
        } = {}
    } = transaction?.settings || {};
    const {handoverData} = useGetHandoverUrl();
    const reasons = apiDeclineReasons.length
        ? apiDeclineReasons
        : packageDeclineReasons;
    const {href} = handoverData?.handoverUrl || {};
    const handOverUrl = getCustomHandOverUrl({
        status: 'PACKAGE_DECLINE',
        handOverUrl: href || packageHandoverUrl,
        transactionId,
        signerId: role?.signerId || '',
        parameters: packageHandover?.parameters
    });
    const numberOfReasons = reasons.length;
    const otherReason = intl.formatMessage({id: 'oss.components.DeclineToSign.otherReason', defaultMessage: ''});
    const reasonsWithOther = !disableDeclineOther && numberOfReasons > 0
        ? [...reasons, otherReason]
        : reasons;
    const reasonReasonToSend = reason === otherReason || numberOfReasons === 0
        ? otherReasonValue
        : reason;
    const shouldDisplayTextArea = useCallback(
        (reasonName) => numberOfReasons === 0
            || (!disableDeclineOther && reasonsWithOther.indexOf(reasonName) === reasonsWithOther.length - 1),
        [disableDeclineOther, numberOfReasons, reasonsWithOther]
    );

    function close() {
        dispatch(setIsDeclinePanelOpen(false));
    }

    function changeReason({target}) {
        setReason(target.value);
    }

    async function decline(e) {
        e.preventDefault();
        if (!reasonReasonToSend) {
            return null;
        }

        await iFrameEventNotifications.postEvent(PACKAGE_DECLINE_STARTED_EVENT);

        await declineSigning({
            variables: {
                reason: reasonReasonToSend,
                transactionId
            }
        });

        close();

        if (!handOverUrl || inSender) {
            dispatch(showDeclineMessageView());
        } else {
            redirectTo(handOverUrl);
        }
    }

    function changeOtherReasonValue({target}) {
        setOtherReasonValue(target.value);
    }

    useEffect(() => {
        if (!isDeclinePanelOpen) {
            setReason(null);
            setOtherReasonValue('');
        }
    }, [isDeclinePanelOpen]);

    const buttonsNode = (
        <>
            <Button
                onClick={close}
                classes={{root: 'btn secondary-btn'}}
            >
                {intl.formatMessage({id: 'oss.components.DeclineToSign.cancel', defaultMessage: ''})}
            </Button>
            <Button
                classes={{root: 'btn primary-btn'}}
                type="submit"
                disabled={!reasonReasonToSend}
            >
                {intl.formatMessage({id: 'oss.components.DeclineToSign.decline', defaultMessage: ''})}
            </Button>
        </>
    );

    return (
        <SidePanel
            className="decline-signing-panel"
            open={isDeclinePanelOpen}
            onClose={close}
            onSubmit={decline}
            isLoading={loading}
            buttonsNode={buttonsNode}
            title={intl.formatMessage({id: 'oss.components.DeclineToSign.title', defaultMessage: ''})}
            ModalProps={{keepMounted: false}}
        >
            <div className="decline-signing-panel-container">
                <div className="content-title">
                    {intl.formatMessage({id: 'oss.components.DeclineToSign.contentTitle', defaultMessage: ''})}
                </div>
                <div className="content">
                    {intl.formatMessage({id: 'oss.components.DeclineToSign.content', defaultMessage: ''})}
                </div>
                {numberOfReasons > 0 && (
                    <RadioGroup value={reason} onChange={changeReason}>
                        {reasonsWithOther.map((reasonName) => (
                            <FormControlLabel
                                value={reasonName}
                                control={<Radio size="small" />}
                                label={reasonName}
                                key={reasonName}
                            />
                        ))}
                    </RadioGroup>
                )}
                {shouldDisplayTextArea(reason) && (
                    <AppInput
                        label={intl.formatMessage({id: 'oss.components.DeclineToSign.otherReasonLabel', defaultMessage: ''})}
                        classes={{root: 'other-reason'}}
                        multiline
                        rows={5}
                        rowsMax={5}
                        onChange={changeOtherReasonValue}
                        value={otherReasonValue}
                    />
                )}
            </div>
        </SidePanel>
    );
}
