import React, {forwardRef} from 'react';
import classnames from 'classnames';

const Image = forwardRef(({
    alt = 'image', maxHeight, maxWidth, className, style, ...restProps
}, ref) => {
    const classNames = classnames('image-component', className);

    return (
        <img
            ref={ref}
            className={classNames}
            alt={alt}
            style={{
                maxWidth: `${maxWidth}px`,
                maxHeight: `${maxHeight}px`,
                ...style
            }}
            {...restProps}
        />
    );
});

export default Image;
