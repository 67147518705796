import React from 'react';
import {useIntl} from 'react-intl';

export default function ReassignThankYou({
    firstName,
    lastName,
    email,
    title,
    company,
    emailMessage
}) {
    const intl = useIntl();

    return (
        <div className="reassign-thank-you">
            <div className="form-instruction">
                {intl.formatMessage({id: 'oss.components.ReassignThankYou.thankYouMessage', defaultMessage: ''})}
            </div>
            <div className="field-container">
                <div className="field-label">
                    {intl.formatMessage({id: 'oss.components.ReassignThankYou.firstNameLabel', defaultMessage: ''})}
                </div>
                <div className="field-text">
                    {firstName}
                </div>
            </div>
            <div className="field-container">
                <div className="field-label">
                    {intl.formatMessage({id: 'oss.components.ReassignThankYou.lastNameLabel', defaultMessage: ''})}
                </div>
                <div className="field-text">
                    {lastName}
                </div>
            </div>
            <div className="field-container">
                <div className="field-label">
                    {intl.formatMessage({id: 'oss.components.ReassignThankYou.emailLabel', defaultMessage: ''})}
                </div>
                <div className="field-text">
                    {email}
                </div>
            </div>
            {title && (
                <div className="field-container">
                    <div className="field-label">
                        {intl.formatMessage({id: 'oss.components.ReassignThankYou.titleLabel', defaultMessage: ''})}
                    </div>
                    <div className="field-text">
                        {title}
                    </div>
                </div>
            )}
            {company && (
                <div className="field-container">
                    <div className="field-label">
                        {intl.formatMessage({id: 'oss.components.ReassignThankYou.companyLabel', defaultMessage: ''})}
                    </div>
                    <div className="field-text">
                        {company}
                    </div>
                </div>
            )}
            {emailMessage && (
                <div className="field-container">
                    <div className="field-label">
                        {intl.formatMessage({id: 'oss.components.ReassignThankYou.personalMessageLabel', defaultMessage: ''})}
                    </div>
                    <div className="field-text">
                        {emailMessage}
                    </div>
                </div>
            )}
        </div>
    );
}
