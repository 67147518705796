import React, {useCallback, useState, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {MdBorderColor as PencilIcon} from '@react-icons/all-files/md/MdBorderColor';
import {useIntl} from 'react-intl';
import {PCC_STEPS} from '../../constants';
import {getComparator, stableSort} from '../../utils';
import {PCCBridge} from '../../pccBridge';
import {AppButton} from '../../../../Core/buttons/AppButton/AppButton';
import {AppOutlinedButton} from '../../../../Core/buttons/AppOutlinedButton/AppOutlinedButton';

const columns = [
    {
        id: 'subject',
        labelKey: 'issuedTo'
    },
    {
        id: 'issuer',
        labelKey: 'issuedBy'
    },
    {
        id: 'expiryDate',
        labelKey: 'expiration'
    }
];

const CertificateList = ({
    certificates, setStep, setOpen, reconnect
}) => {
    const intl = useIntl();

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('subject');
    const [certsReady, setCertsReady] = useState(false);

    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const seeDetails = (cert) => {
        PCCBridge.setCertificateDetails(cert);
        setStep(PCC_STEPS.certificateDetail);
    };

    const selectCert = (cert) => {
        setStep(PCC_STEPS.confirmLoading);
        PCCBridge.selectCertificate(cert);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        setCertsReady(certificates.length > 0);
    }, [certificates.length]);

    return (
        <>
            <h2 className="title">
                {intl.formatMessage({id: 'oss.pcc.CertificateList.title', defaultMessage: ''})}
            </h2>
            <div className="content">
                {certsReady ? (
                    <>
                    <TableContainer>
                        <Table
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            sortDirection={orderBy === column.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={() => handleRequestSort(column.id)}
                                            >
                                                {intl.formatMessage(
                                                    {id: `oss.pcc.CertificateList.Column.${column.labelKey}`, defaultMessage: ''}
                                                )}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(certificates, getComparator(order, orderBy)).map(
                                    (row) => (
                                        <TableRow
                                            key={row.certificateThumbprint}
                                        >
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                >
                                                    {row[column.id]}
                                                </TableCell>
                                            ))}
                                            <TableCell>
                                                <AppOutlinedButton
                                                    onClick={() => seeDetails(row)}
                                                >
                                                    {intl.formatMessage(
                                                        {id: 'oss.pcc.CertificateList.Column.Action.details', defaultMessage: ''}
                                                    )}
                                                </AppOutlinedButton>
                                            </TableCell>
                                            <TableCell>
                                                <AppButton
                                                    onClick={() => selectCert(row)}
                                                    className="action-button"
                                                >
                                                    {intl.formatMessage({id: 'oss.pcc.generic.sign', defaultMessage: ''})}
                                                    <PencilIcon aria-hidden className="icon pencil-icon" />
                                                </AppButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                        <div className="actions">
                            <AppOutlinedButton
                                onClick={onClose}
                            >
                                {intl.formatMessage({id: 'oss.pcc.generic.cancel', defaultMessage: ''})}
                            </AppOutlinedButton>
                        </div>
                    </>
                ) : (
                    <div>
                        <p>
                            {intl.formatMessage({id: 'oss.personal_certificate_client.error.empty_certificate_list', defaultMessage: ''})}
                        </p>
                        <div className="actions">
                            <AppOutlinedButton
                                onClick={onClose}
                            >
                                {intl.formatMessage({id: 'oss.pcc.generic.cancel', defaultMessage: ''})}
                            </AppOutlinedButton>
                            <AppButton
                                onClick={reconnect}
                            >
                                {intl.formatMessage({id: 'oss.components.VirtualRoom.ControlPanel.connecting', defaultMessage: ''})}
                            </AppButton>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default CertificateList;
