import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import THANK_YOU_VIEW from '../../../../gql/thankYouView.gql';
import {selectTransactionId} from '../signerUiSlice';

export default function useThankYouView() {
    const transactionId = useSelector(selectTransactionId);
    const {data = {}, error, loading} = useQuery(THANK_YOU_VIEW, {
        skip: !transactionId,
        variables: {transactionId}
    });
    const {ceremony = {}} = data;
    const {
        role = {},
        transaction = {}
    } = ceremony;

    return useMemo(() => ({
        role, transaction, error, loading
    }), [role, transaction, error, loading]);
}
