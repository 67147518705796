import {useLocation} from 'react-router';

export const integrationNames = {
    dynamics: 'dynamics',
    salesforce: 'salesforce',
    onlyDesigner: 'onlyDesigner',
}

const DESIGNER_ENTITIES = {
    HEADER: 'header',
    SEND_BUTTON: 'sendButton',
    NAVIGATION_MENU: 'navigationMenu',
    COOKIE_BANNER: 'cookieBanner',
    GO_BACK_BUTTON: 'goBackButton'
};

export const integrationOrigins = {
    [integrationNames.dynamics]: ['unq', 'org'],
    [integrationNames.salesforce]: ['salesforce'],
}

const integrationsProfiles = {
    [integrationNames.onlyDesigner]: {
        hideHeader: true,
        hideSendButton: false,
        hideGoBackButton: true,
        hideCookieBanner: true,
        hideNavigationMenu: false
    },
    [integrationNames.salesforce]: {
        hideHeader: true,
        hideSendButton: true,
        hideGoBackButton: true,
        hideCookieBanner: true,
        hideNavigationMenu: false
    },
    [integrationNames.dynamics]: {
        hideHeader: true,
        hideSendButton: true,
        hideGoBackButton: true,
        hideCookieBanner: true,
        hideNavigationMenu: false
    }
};

function getProfileByUrlParam(param = '') {
    const profileNames = Object.keys(integrationsProfiles);
    // Find the key that matches the beginning of the param
    const matchedProfileName = profileNames.find((name) => param?.startsWith(name));

    return matchedProfileName ? integrationsProfiles[matchedProfileName] : null;
}

export default function useAccessRestrictions() {
    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    const profile = searchParams.get('profile');
    const entitiesToHide = searchParams.get('disable')?.split(',') || [];

    return {
        hideSendButton: entitiesToHide.includes(DESIGNER_ENTITIES.SEND_BUTTON),
        hideHeader: entitiesToHide.includes(DESIGNER_ENTITIES.HEADER),
        hideGoBackButton: entitiesToHide.includes(DESIGNER_ENTITIES.GO_BACK_BUTTON),
        hideNavigationMenu: entitiesToHide.includes(DESIGNER_ENTITIES.NAVIGATION_MENU),
        hideCookieBanner: entitiesToHide.includes(DESIGNER_ENTITIES.COOKIE_BANNER),
        // Profile should have higher priority of the configuration over the url parameters
        ...getProfileByUrlParam(profile)
    };
}

export function getProfileName() {
    const profileName = new URLSearchParams(window.location.search).get('profile');
    return profileName;
}
