/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import {HiOutlineChevronLeft} from '@react-icons/all-files/hi/HiOutlineChevronLeft';
import {HiOutlineChevronRight} from '@react-icons/all-files/hi/HiOutlineChevronRight';
import NewsStep from './NewsStep/NewsStep';
import Bullets from './Bullets/Bullets';
import {selectNews} from '../../appSlice';
import './loginNews.less';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function LoginNews() {
    const [activeStep, setActiveStep] = useState(0);
    const news = useSelector(selectNews);
    const shouldShowArrows = news && news.length > 1;

    function handleStepChange(step) {
        setActiveStep(step);
    }

    function setNextStep() {
        const nextStep = activeStep === news.length - 1 ? 0 : activeStep + 1;
        setActiveStep(nextStep);
    }

    function setBackStep() {
        const backStep = activeStep === 0 ? news.length - 1 : activeStep - 1;
        setActiveStep(backStep);
    }

    return (
        <div className="login-news">
            <div className="news-steps-container">
                {shouldShowArrows && (
                    <div className="back-arrow-container" onClick={setBackStep}>
                        <div className="back-arrow">
                            <HiOutlineChevronLeft className="back-arrow-icon" />
                        </div>
                    </div>
                )}
                <AutoPlaySwipeableViews
                    axis="x"
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    interval={8000}
                    enableMouseEvents
                    className="news-steps"
                >
                    {news.map((step) => (
                        <NewsStep {...step} key={step.title} />
                    ))}
                </AutoPlaySwipeableViews>
                {shouldShowArrows && (
                    <div className="next-arrow-container" onClick={setNextStep}>
                        <div className="next-arrow">
                            <HiOutlineChevronRight className="next-arrow-icon" />
                        </div>
                    </div>
                )}
            </div>
            <Bullets
                steps={news.length}
                activeStep={activeStep}
                onChange={handleStepChange}
            />
            {/* <MobileStepper
                variant="dots"
                steps={news.length}
                activeStep={activeStep}
                position="static"
                classes={{root: 'dots'}}
            /> */}
        </div>
    );
}
