/* eslint-disable import/prefer-default-export */
import {createSlice} from '@reduxjs/toolkit';
import pick from 'lodash.pick';
import {BASE_PATH} from '../../../constants';
import {getErrorMessage} from '../../utils/requests/error';
import httpClient from '../../utils/httpClient';

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        groups: [],
        group: {},
        groupsCount: null,
        isGroupsFetching: false,
        groupsFetchingError: null,
        isGroupCreating: false,
        groupCreatingError: null,
        isGroupUpdating: false,
        groupsError: null,
        groupUpdatingError: null,
        groupsOptions: {
            sort: 'name',
            dir: 'asc',
            from: 1,
            to: 15,
            query: 'accountGroups'
        },
        groupsPerPage: 15,
        customFields: [],
        isCustomFieldsFetching: false,
        customFieldsFetchingError: null,
        isCustomFieldSaving: false,
        customFieldsSavingError: null,
        isCustomFieldDeleting: false,
        customFieldsDeletingError: null,
        callbackInfo: {},
        isFetchingCallback: false,
        isUpdatingCallback: false,
        fetchingCallbackError: null,
        isUpdatingCallbackError: false,
        updatingCallbackError: null,
        clientApps: [],
        isFetchingClientApps: false,
        fetchingClientAppsError: null,
        isDeletingClientApps: false,
        deletingClientAppsError: null,
        isCreatingClientApp: false,
        clientAppCreatingError: null,
        apiKey: '',
        fetchApiKeyError: null
    },
    reducers: {
        setGroups: (state, action) => {
            state.groups = action.payload;
        },
        setGroup: (state, action) => {
            state.group = action.payload;
        },
        setIsGroupsFetching: (state, action) => {
            state.isGroupsFetching = action.payload;
        },
        setIsGroupsCreating: (state, action) => {
            state.isGroupCreating = action.payload;
        },
        setGroupsOptions: (state, action) => {
            state.groupsOptions = action.payload;
        },
        setGroupsCount: (state, action) => {
            state.groupsCount = action.payload;
        },
        setGroupsFetchingError: (state, action) => {
            state.groupsFetchingError = action.payload;
        },
        setGroupCreatingError: (state, action) => {
            state.groupCreatingError = action.payload;
        },
        setIsGroupUpdating: (state, action) => {
            state.isGroupUpdating = action.payload;
        },
        setGroupUpdatingError: (state, action) => {
            state.groupUpdatingError = action.payload;
        },
        setCustomFields: (state, action) => {
            state.customFields = action.payload;
        },
        setIsCustomFieldsFetching: (state, action) => {
            state.isCustomFieldsFetching = action.payload;
        },
        setCustomFieldsFetchingError: (state, action) => {
            state.customFieldsFetchingError = action.payload;
        },
        setIsCustomFieldSaving: (state, action) => {
            state.isCustomFieldSaving = action.payload;
        },
        setCustomFieldsSavingError: (state, action) => {
            state.customFieldsSavingError = action.payload;
        },
        setIsCustomFieldDeleting: (state, action) => {
            state.isCustomFieldDeleting = action.payload;
        },
        setCustomFieldsDeletingError: (state, action) => {
            state.customFieldsDeletingError = action.payload;
        },
        setIsFetchingCallback: (state, action) => {
            state.isFetchingCallback = action.payload;
        },
        setFetchingCallbackError: (state, action) => {
            state.fetchingCallbackError = action.payload;
        },
        setCallbackInfo: (state, action) => {
            state.callbackInfo = action.payload;
        },
        setIsUpdatingCallback: (state, action) => {
            state.isUpdatingCallback = action.payload;
        },
        setUpdatingCallbackError: (state, action) => {
            state.updatingCallbackError = action.payload;
        },
        setClientApps: (state, action) => {
            state.clientApps = action.payload;
        },
        setIsFetchingClientApps: (state, action) => {
            state.isFetchingClientApps = action.payload;
        },
        setFetchingClientAppsError: (state, action) => {
            state.fetchingClientAppsError = action.payload;
        },
        setIsCreatingClientApp: (state, action) => {
            state.isCreatingClientApp = action.payload;
        },
        setClientAppCreatingError: (state, action) => {
            state.clientAppCreatingError = action.payload;
        },
        setIsDeletingClientApps: (state, action) => {
            state.isDeletingClientApps = action.payload;
        },
        setDeletingClientAppsError: (state, action) => {
            state.deletingClientAppsError = action.payload;
        },
        setApiKey: (state, action) => {
            state.apiKey = action.payload;
        },
        setFetchApiKeyError: (state, action) => {
            state.fetchApiKeyError = action.payload;
        }
    }
});
export const {
    setGroups,
    setGroup,
    setIsGroupsFetching,
    setIsGroupsCreating,
    setGroupsOptions,
    setGroupsCount,
    setGroupsFetchingError,
    setGroupCreatingError,
    setIsGroupUpdating,
    setGroupUpdatingError,
    setCustomFields,
    setIsCustomFieldsFetching,
    setCustomFieldsFetchingError,
    setIsCustomFieldSaving,
    setCustomFieldsSavingError,
    setIsCustomFieldDeleting,
    setCustomFieldsDeletingError,
    setCallbackInfo,
    setClientApps,
    setIsFetchingCallback,
    setFetchingCallbackError,
    setIsUpdatingCallback,
    setUpdatingCallbackError,
    setIsFetchingClientApps,
    setFetchingClientAppsError,
    setIsCreatingClientApp,
    setClientAppCreatingError,
    setIsDeletingClientApps,
    setDeletingClientAppsError,
    setApiKey,
    setFetchApiKeyError
} = adminSlice.actions;

export const fetchGroups = () => async (dispatch, getState) => {
    const {
        sort,
        dir,
        from,
        to,
        query
    } = getState().admin.groupsOptions;
    const url = `${BASE_PATH}/proxy/groups?sort=${sort}&dir=${dir}&from=${from}&to=${to}&query=${query}&requestType=group`;
    dispatch(setIsGroupsFetching(true));
    try {
        const {data} = await httpClient.get(url);
        dispatch(setGroups(data.results));
        dispatch(setGroupsCount(data.count));
        dispatch(setIsGroupsFetching(false));
        return data;
    } catch (err) {
        dispatch(setIsGroupsFetching(false));
        dispatch(setGroupsFetchingError(getErrorMessage(err)));
        return false;
    }
};

export const fetchGroup = (id) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/groups/${id}`;
    dispatch(setIsGroupsFetching(true));
    try {
        const {data} = await httpClient.get(url);
        dispatch(setGroup(data));
        dispatch(setIsGroupsFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsGroupsFetching(false));

        return false;
    }
};

export const createGroup = (group) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/groups`;
    dispatch(setIsGroupsCreating(true));
    try {
        await httpClient.post(url, group);
        dispatch(setIsGroupsCreating(false));
        dispatch(fetchGroups());
        return true;
    } catch (err) {
        dispatch(setGroupCreatingError(getErrorMessage(err)));
        dispatch(setIsGroupsCreating(false));

        return false;
    }
};

export const updateGroup = (group) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/groups/${group.id}`;
    dispatch(setIsGroupUpdating(true));

    try {
        const {data} = await httpClient.put(url, group);
        dispatch(setIsGroupUpdating(false));
        dispatch(setGroup(data));

        return true;
    } catch (err) {
        dispatch(setIsGroupUpdating(false));
        dispatch(setGroupUpdatingError(getErrorMessage(err)));
        return false;
    }
};

export const deleteGroup = (id) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/groups/${id}`;
    dispatch(setIsGroupsFetching(true));
    try {
        await httpClient.delete(url);
        await dispatch(fetchGroups());
        return true;
    } catch (err) {
        dispatch(setIsGroupsFetching(false));
        return false;
    }
};

export const inviteGroupMember = ({id, email}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/groups/${id}/invite`;
    dispatch(setIsGroupUpdating(true));
    dispatch(setGroupUpdatingError(null));
    try {
        const {data} = await httpClient.post(url, {email});
        dispatch(setGroup(data));
        dispatch(setIsGroupUpdating(false));
        await dispatch(fetchGroups());
        return true;
    } catch (err) {
        dispatch(setIsGroupUpdating(false));
        dispatch(setGroupUpdatingError(getErrorMessage(err)));
        return false;
    }
};

export const fetchCustomFields = (params) => async (dispatch) => {
    const queryParams = pick(params, [
        'sort',
        'dir'
    ]);
    const url = `${BASE_PATH}/proxy/account/customfields`;
    dispatch(setIsCustomFieldsFetching(true));
    dispatch(setCustomFieldsFetchingError(null));

    try {
        const {data} = await httpClient.get(url, {
            params: {
                ...queryParams,
                query: 'accountCustomFields'
            }
        });
        dispatch(setCustomFields(data));
        dispatch(setIsCustomFieldsFetching(false));

        return true;
    } catch (err) {
        dispatch(setCustomFieldsSavingError(getErrorMessage(err)));
        dispatch(setIsCustomFieldsFetching(false));
    }
};

export const fetchCallbackInfo = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/callback`;
    dispatch(setIsFetchingCallback(true));
    dispatch(setFetchingCallbackError(null));

    try {
        const {data} = await httpClient.get(url);
        dispatch(setIsFetchingCallback(false));
        dispatch(setCallbackInfo(data));
        return data;
    } catch (err) {
        dispatch(setIsFetchingCallback(false));
        dispatch(setFetchingCallbackError(getErrorMessage(err)));
        return false;
    }
};

export const createCustomField = (customField) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/customfields`;
    dispatch(setIsCustomFieldSaving(true));
    dispatch(setCustomFieldsSavingError(null));

    try {
        await httpClient.post(url, customField);
        dispatch(setIsCustomFieldSaving(false));

        return true;
    } catch (err) {
        dispatch(setCustomFieldsSavingError(getErrorMessage(err)));
        dispatch(setIsCustomFieldSaving(false));

        return false;
    }
};

export const updateCustomField = (customField) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/customfields`;
    dispatch(setIsCustomFieldSaving(true));
    dispatch(setCustomFieldsSavingError(null));

    try {
        await httpClient.put(url, customField);
        dispatch(setIsCustomFieldSaving(false));

        return true;
    } catch (err) {
        dispatch(setCustomFieldsSavingError(getErrorMessage(err)));
        dispatch(setIsCustomFieldSaving(false));

        return false;
    }
};

export const deleteCustomFields = (customFieldIds = []) => async (dispatch) => {
    dispatch(setIsCustomFieldDeleting(true));
    dispatch(setCustomFieldsDeletingError(null));

    try {
        await Promise.all(customFieldIds.map((id) => {
            const url = `${BASE_PATH}/proxy/account/customfields/${id}`;
            return httpClient.delete(url);
        }));
        dispatch(setIsCustomFieldDeleting(false));

        return true;
    } catch (err) {
        dispatch(setCustomFieldsDeletingError(getErrorMessage(err)));
        dispatch(setIsCustomFieldDeleting(false));
    }
};

export const updateCallbackInfo = (callbackData) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/callback`;
    dispatch(setIsUpdatingCallback(true));
    dispatch(setUpdatingCallbackError(null));

    try {
        const {data} = await httpClient.post(url, callbackData);
        dispatch(setCallbackInfo(data));
        dispatch(setIsUpdatingCallback(false));
        return true;
    } catch (err) {
        dispatch(setIsUpdatingCallback(false));
        dispatch(setUpdatingCallbackError(getErrorMessage(err)));
        return false;
    }
};

export const fetchClientApps = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/apitoken/clientApps`;
    dispatch(setFetchingClientAppsError(null));
    dispatch(setIsFetchingClientApps(true));

    try {
        const {data} = await httpClient.get(url);
        dispatch(setClientApps(data.data));
        dispatch(setIsFetchingClientApps(false));

        return true;
    } catch (err) {
        dispatch(setFetchingClientAppsError(getErrorMessage(err)));
        dispatch(setIsFetchingClientApps(false));

        return false;
    }
};

export const createClientApp = (app) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/apitoken/clientApps`;
    dispatch(setClientAppCreatingError(null));
    dispatch(setIsCreatingClientApp(true));

    try {
        const {data} = await httpClient.post(url, app);
        dispatch(setIsCreatingClientApp(false));
        dispatch(fetchClientApps());

        return data;
    } catch (err) {
        dispatch(setIsCreatingClientApp(false));
        dispatch(setClientAppCreatingError(getErrorMessage(err)));

        return false;
    }
};

export const deleteClientApps = (clientAppsIds = []) => async (dispatch) => {
    dispatch(setIsDeletingClientApps(true));
    dispatch(setDeletingClientAppsError(null));

    try {
        await Promise.all(clientAppsIds.map((id) => {
            const url = `${BASE_PATH}/proxy/apitoken/clientApps/${id}`;
            return httpClient.delete(url);
        }));
        dispatch(setIsDeletingClientApps(false));
        return true;
    } catch (err) {
        dispatch(setDeletingClientAppsError(getErrorMessage(err)));
        dispatch(setIsDeletingClientApps(false));
        return false;
    }
};

export const fetchApiKey = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/session/apiKey`;
    dispatch(setFetchApiKeyError(null));

    try {
        const {data} = await httpClient.get(url);
        dispatch(setApiKey(data.apiKey));

        return true;
    } catch (err) {
        dispatch(setFetchApiKeyError(getErrorMessage(err)));

        return false;
    }
};

export const selectGroups = (state) => state.admin.groups;
export const selectGroup = (state) => state.admin.group;
export const selectGroupsOptions = (state) => state.admin.groupsOptions;
export const selectGroupsPerPage = (state) => state.admin.groupsPerPage;
export const selectIsGroupsFetching = (state) => state.admin.isGroupsFetching;
export const selectIsGroupCreating = (state) => state.admin.isGroupCreating;
export const selectIsGroupUpdating = (state) => state.admin.isGroupUpdating;
export const selectGroupsCount = (state) => state.admin.groupsCount;
export const selectGroupsFetchingError = (state) => state.admin.groupsFetchingError;
export const selectGroupCreatingError = (state) => state.admin.groupCreatingError;
export const selectGroupUpdatingError = (state) => state.admin.groupUpdatingError;
export const selectCustomFields = (state) => state.admin.customFields;
export const selectIsCustomFieldsFetching = (state) => state.admin.isCustomFieldsFetching;
export const selectCustomFieldsFetchingError = (state) => state.admin.customFieldsFetchingError;
export const selectIsCustomFieldSaving = (state) => state.admin.isCustomFieldSaving;
export const selectCustomFieldsSavingError = (state) => state.admin.customFieldsSavingError;
export const selectIsCustomFieldDeleting = (state) => state.admin.isCustomFieldDeleting;
export const selectCustomFieldsDeletingError = (state) => state.admin.customFieldsDeletingError;
export const selectCallbackInfo = (state) => state.admin.callbackInfo;
export const selectIsFetchingCallback = (state) => state.admin.isFetchingCallback;
export const selectIsUpdatingCallback = (state) => state.admin.isUpdatingCallback;
export const selectFetchingCallbackError = (state) => state.admin.fetchingCallbackError;
export const selectUpdatingCallbackError = (state) => state.admin.updatingCallbackError;
export const selectClientApps = (state) => state.admin.clientApps;
export const selectIsFetchingClientApps = (state) => state.admin.isFetchingClientApps;
export const selectFetchingClientAppsError = (state) => state.admin.fetchingClientAppsError;
export const selectIsCreatingClientApp = (state) => state.admin.isCreatingClientApp;
export const selectCreatingClientAppError = (state) => state.admin.clientAppCreatingError;
export const selectDeletingClientAppsError = (state) => state.admin.deletingClientAppsError;
export const selectIsDeletingClientApps = (state) => state.admin.isDeletingClientApps;
export const selectApiKey = (state) => state.admin.apiKey;
export const selectFetchApiKeyError = (state) => state.admin.fetchApiKeyError;

export default adminSlice.reducer;
