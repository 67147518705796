import React from 'react';

function UploadIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="108px"
            height="121px"
            viewBox="0 0 108.0 121.0"
            {...props}

        >
            <defs>
                <clipPath id="uploadIconIdd0"><path d="M1548,0 L1548,1037 L0,1037 L0,0 L1548,0 Z" /></clipPath>
                <clipPath id="uploadIconIdd1"><path d="M33,42.35 C34.656738,42.35 36,43.7044559 36,45.375 C36,47.0455442 34.656738,48.4 33,48.4 L18,48.4 C11.3759766,48.408493 6.0084228,53.8207764 6,60.5 L6,102.85 C6.0084228,109.529224 11.3759766,114.941507 18,114.95 L90,114.95 C96.6240234,114.941507 101.991577,109.529224 102,102.85 L102,60.5 C101.991577,53.8207764 96.6240234,48.408493 90,48.4 L81,48.4 C79.343262,48.4 78,47.0455442 78,45.375 C78,43.7044559 79.343262,42.35 81,42.35 L90,42.35 C99.9367674,42.3610782 107.989013,50.4804262 108,60.5 L108,102.85 C107.989013,112.869574 99.9367674,120.988922 90,121 L18,121 C8.0632326,120.988922 0.0109866,112.869574 0,102.85 L0,60.5 C0.0109866,50.4804262 8.0632326,42.3610782 18,42.35 Z M54,0 C54.020508,0 54.0377196,0.011447205 54.058227,0.011816255 C54.4288326,0.01920149 54.797241,0.08862282 55.1455074,0.23337391 C55.5135492,0.38698704 55.8457026,0.608175645 56.1229242,0.888076475 L77.068359,22.0080566 C78.239868,23.1896972 78.239502,25.1050631 77.067993,26.285965 C76.505493,26.8531525 75.7426758,27.1718259 74.9472654,27.1718259 C74.151489,27.1729337 73.3879392,26.8538906 72.8261718,26.2855953 L57,10.3271697 L57,93.775 C57.0010992,95.4448061 55.6593018,96.7988922 54.0036624,96.8000007 C52.343994,96.8011078 51.0010986,95.4481293 51,93.7783233 L51,10.3271697 L35.1738282,26.2855959 C34.0144044,27.4786838 32.114868,27.4978853 30.9316404,26.3287996 C29.7484128,25.1597139 29.72937,23.2447177 30.8887938,22.0516299 L30.9316404,22.0084256 L51.8781738,0.88696872 C52.1550294,0.60780599 52.4860842,0.38661799 52.85376,0.23337391 C53.2071534,0.08566921 53.582886,0.013293665 53.9593506,0.00812394 C53.973633,0.007754285 53.9857176,0 54,0 Z" /></clipPath>
            </defs>
            <g transform="translate(-1005.0 -275.0)"><g clipPath="url(#uploadIconIdd0)"><g transform="translate(1005.0 274.9999999999999)"><g clipPath="url(#uploadIconIdd1)"><polygon points="0,0 108,0 108,121 0,121 0,0" stroke="none" fill="#608BB4" /></g></g></g></g>
        </svg>

    );
}

export default UploadIcon;
