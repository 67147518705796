import {gql} from '@apollo/client/core';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import {
    FIELD_SUBTYPES, FIELD_TYPES, PAGES_MARGIN_BOTTOM, PAGE_PADDING
} from '../../../../constants';
import {getViewportWidth, isInBrowser} from '../utils/functions';
import GET_FIELDS_VALIDATION from '../../../../gql/getFieldsValidation.gql';
import {GET_CEREMONY} from '../../../../gql/queries';
import {getConditionFromString} from '../../Designer/FieldSettingsPanel/ConditionalSettings/utils';
import {getFieldLabel} from '../../Designer/utils';
import {isActionContainValue, isEnabledOrRequiredInputField} from '../utils/selectors';

export const WHITESPACE_OFFSET_HEIGHT_PX = 20;

const breakpoints = {
    lg: 1280,
    md: 960,
    sm: 600,
    xl: 1920,
    xs: 0
};

export const breakpointToHeight = {
    acceptConsentBar: {
        lg: 82,
        md: 84,
        sm: 84,
        xl: 82,
        xs: 134
    },
    confirmBar: {
        lg: 70,
        md: 70,
        sm: 70,
        xl: 70,
        xs: 60
    },
    pageBottomMargin: PAGES_MARGIN_BOTTOM
};

const getBreakpointFromWidth = (width) => {
    const {
        sm, md, lg, xl
    } = breakpoints;

    if (width < sm) {
        return 'xs';
    }
    if (width < md) {
        return 'sm';
    }
    if (width < lg) {
        return 'md';
    }
    if (width < xl) {
        return 'lg';
    }
    return 'xl';
};

export const getSpaceHeight = ({config, consentBarHeight}) => {
    const viewportWidth = isInBrowser() ? getViewportWidth() : breakpoints.sm;
    const breakpoint = getBreakpointFromWidth(viewportWidth);

    let whitespace = 0;

    Object.keys(breakpointToHeight).forEach((feature) => {
        if (config[feature]) {
            if (feature === 'acceptConsentBar' && consentBarHeight) {
                whitespace += consentBarHeight + WHITESPACE_OFFSET_HEIGHT_PX;
            } else {
                const size = breakpointToHeight[feature];
                whitespace += typeof size === 'number' ? size : size[breakpoint];
            }
        }
    });

    return whitespace;
};

export const getPageHeightAdjustedForScale = (
    pages,
    shouldShowConsentBar = () => { },
    consentBarHeight = null
) => (index) => {
    const {height, scale = 1} = pages[index];
    const pageHeight = height * scale;
    const isLastPage = index === pages.length - 1;

    const whitespace = getSpaceHeight({
        config: {
            acceptConsentBar: shouldShowConsentBar(index),
            confirmBar: isLastPage,
            pageBottomMargin: true
        },
        consentBarHeight
    });

    return pageHeight + whitespace;
};

export const isStickyButtonAvailable = (pages) => (pages
    ? pages.some(
        (page) => page.signerFields
            && !!page.signerFields.some((field = {}) => (field.type === FIELD_TYPES.SIGNATURE
                ? !field.approval?.isOptional
                : field.isRequired))
    )
    : false);

export const areRequiredSignaturesCompleted = (pages) => (pages
    ? pages.some(
        (page) => page.signerFields
            && !!page.signerFields.some(
                (field = {}) => field.isSignature
                    && !field.approval?.isOptional
                    && field.approval?.isAccepted
            )
    )
    : false);

export const areRequiredFieldsCompleted = (pages) => (pages
    ? pages.some(
        (page) => page.signerFields
            && !!page.signerFields.some(
                (field = {}) => !field.isSignature
                    && field.isRequired
                    && (field.isRadio ? field.radioGroup?.isSelected : field.value)
            )
    )
    : false);
export const hasSomeSignerField = (pages) => (pages ? pages.some((page) => page.signerFields?.length > 0) : false);
export const areRequiredActionsCompleted = (pages) => areRequiredSignaturesCompleted(pages) || areRequiredFieldsCompleted(pages);
export const getShouldShowConsentBar = ({
    canSign,
    isConfirmedDocument,
    isConsentDocument,
    isReviewDocument,
    showConsentBar,
    isReviewView
}) => (pageIndex) => pageIndex === 0
    && isConsentDocument
    && !(isConfirmedDocument || isReviewDocument)
    && canSign
    && showConsentBar
    && !isReviewView;

export const getPageScale = (containerWidth, pageWidth) => {
    const scale = (containerWidth - PAGE_PADDING * 2) / pageWidth;

    // Adjust the scale so that it never goes above 1 (which would stretch the pages)
    // and never 0 (server-side rendering doesn't know about containerWidth)
    return (scale <= 1 && scale) || 1;
};

export const getPagesFromDocument = (document, containerWidth) => {
    let absoluteOffset = 0;

    return document.pages.map((page) => {
        const offset = absoluteOffset;

        const scale = getPageScale(containerWidth, page.width);
        const scaledWidth = scale >= 1 ? page.width : page.width * scale;
        const scaledHeight = scale >= 1 ? page.height : page.height * scale;

        const isFirstPage = page.index === 0;
        const isLastPage = page.index === document.pages.length - 1;

        const whitespace = getSpaceHeight({
            config: {
                acceptConsentBar:
                    isFirstPage
                    && document.index === 0
                    && document.isConsent
                    && !document.isConfirmed,
                confirmBar: isLastPage,
                pageBottomMargin: true
            }
        });

        absoluteOffset += scaledHeight + whitespace;

        return {
            ...page,
            offset,
            scale,
            scaledHeight,
            scaledWidth
        };
    });
};

export const getAverageHeight = (pages) => pages.reduce((heightSum, page) => heightSum + page.height * page.scale + PAGES_MARGIN_BOTTOM, 0) / pages.length;

export const getPages = ({
    containerWidth,
    document
}) => {
    const computedContainerWidth = containerWidth;

    return getPagesFromDocument(document, computedContainerWidth);
};

function getPageViewWeight(upperBound, lowerBound, pagesMarginBottom) {
    return (page) => {
        const boundedSpace = lowerBound - upperBound;

        const slicedUpperBound = Math.max(upperBound, page.offset);
        const pageLowerBound = page.offset + page.height * page.scale + pagesMarginBottom;
        const slicedLowerBound = Math.min(lowerBound, pageLowerBound);

        const occupiedBoundedSpace = slicedUpperBound < slicedLowerBound
            ? slicedLowerBound - slicedUpperBound
            : 0;

        return [page, occupiedBoundedSpace / boundedSpace];
    };
}
function pageWeightSort(weight1, weight2) {
    return weight2[1] - weight1[1];
}
export function getPageFromOffset(pagesMarginBottom, viewMidpointPadding) {
    return (pages, availableHeight, zoomFactor) => (scrollTop) => {
        const baseScrollTop = scrollTop / zoomFactor;
        const middlePoint = availableHeight / 2;
        const upperBound = baseScrollTop + (middlePoint - availableHeight * viewMidpointPadding);
        const lowerBound = baseScrollTop + (middlePoint + availableHeight * viewMidpointPadding);

        // If the last item is in view return it
        const lastItem = pages[pages.length - 1];

        if (lastItem.offset < baseScrollTop + availableHeight) {
            return lastItem;
        }

        return pages
            .map(getPageViewWeight(upperBound, lowerBound, pagesMarginBottom))
            .slice(0)
            .sort(pageWeightSort)[0][0];
    };
}
export const getScrollPosition = (viewerScrollTop) => window.pageYOffset || document.documentElement.scrollTop || viewerScrollTop;

// this is workaround for the issue with the positioning in the viewer
export function triggerScroll(timeout) {
    window.scrollBy(0, 1);
    // eslint-disable-next-line no-param-reassign
    timeout = setTimeout(() => window.scrollBy(0, -1), 310);
    return timeout;
}

export const getElementBoundingRect = (element, scrollContainerBRect) => {
    const {
        left: clientLeft,
        top: clientTop,
        width,
        height
    } = element.getBoundingClientRect();
    const left = scrollContainerBRect.left + clientLeft;
    const top = scrollContainerBRect.top + clientTop;
    const right = left + width;
    const bottom = top + height;

    return {
        bottom,
        height,
        left,
        right,
        top,
        width
    };
};

export const getFieldRootElement = (fieldType, fieldRef) => {
    switch (fieldType) {
        case 'LIST':
            return fieldRef?.node?.parentElement;
        case 'INPUT':
        case 'RADIO':
            // Clicking on empty radio should focus on it and selected
            return null;
        case 'TEXTAREA':
        case 'CHECKBOX':
        case 'DATEPICKER':
            return fieldRef?.parentElement;
        default:
            return fieldRef;
    }
};

export const updateCache = ({
    documentId,
    transactionId,
    hasConditions,
    isConditionalTarget,
    client,
    radioGroupId,
    originalId,
    checkboxGroupId
}) => async (cache) => {
    if (checkboxGroupId) {
        const checkboxGroup = cache.readFragment({
            fragment: gql`
          fragment selectedFieldIds on CheckboxGroup {
            selectedFieldIds
          }
        `,
            id: `CheckboxGroup:${checkboxGroupId}`
        });
        const selectedFieldIds = checkboxGroup?.selectedFieldIds || [];

        const newSelections = selectedFieldIds.some((id) => id === originalId)
            ? selectedFieldIds.filter((id) => id !== originalId)
            : [originalId, ...selectedFieldIds];

        cache.writeFragment({
            data: {
                selectedFieldIds: newSelections
            },
            fragment: gql`
          fragment selectedFieldIds on CheckboxGroup {
            selectedFieldIds
          }
        `,
            id: `CheckboxGroup:${checkboxGroupId}`
        });
    }

    if (radioGroupId) {
        cache.writeFragment({
            data: {
                selectedFieldId: originalId,
                isSelected: true
            },
            fragment: gql`
          fragment selectedFieldId on RadioGroup {
            selectedFieldId
            isSelected
          }
        `,
            id: `RadioGroup:${radioGroupId}`
        });
    }

    // If field has a condition,
    // refetch the fields form the network without yet affecting cache.
    // The fields' disabled value will be reinserted into the cache.
    if (hasConditions || isConditionalTarget) {
        try {
            const {
                ceremony: {
                    transaction: {raw: rawTransaction},
                    session: {raw: rawSession}
                }
            } = cache.readQuery({
                query: GET_CEREMONY,
                variables: {transactionId}
            });

            // Fetching a single document lacks the transaction information
            // So I'm passing the raw data into the query to reshape a Ceremony
            await client.query({
                query: GET_FIELDS_VALIDATION,
                variables: {
                    documentId,
                    transactionId,
                    rawTransaction,
                    rawSession
                },
                fetchPolicy: 'network-only'
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            // console.error(err); // I believe apollo client will handle this
        }
    }
};

export function getFieldErrorMessage({
    intl, mutationError, validationError
}) {
    if (!isEmpty(validationError)) {
        if (validationError.type === 'required') {
            return intl.formatMessage(
                {id: 'esl.validation.required', defaultMessage: ''}
            );
        }
    }
    if (!isEmpty(mutationError)) {
        const message = get(mutationError, 'graphQLErrors[0].messageKey', 'error_500.oops_broken');
        if (!message) return;
        return intl.formatMessage(
            {id: `esl.${message}`, defaultMessage: ''}
        );
    }
}

export function getSignatureErrorMessage({intl, mutationError, validationError}) {
    if (!isEmpty(mutationError)) {
        const message = get(mutationError, 'graphQLErrors[0].messageKey', 'error_500.oops_broken');
        if (!message) return;
        return intl.formatMessage(
            {id: `esl.${message}`, defaultMessage: ''}
        );
    } if (!isEmpty(validationError)) {
        if (validationError.type === 'required') {
            return intl.formatMessage(
                {id: 'esl.validation.custom_field_required', defaultMessage: ''}
            );
        }
    }

    if (!isEmpty(mutationError) || !isEmpty(validationError)) {
        return intl.formatMessage(
            {id: 'esl.validation.custom_field_required', defaultMessage: ''}
        );
    }
}

export const shouldShowErrorTooltip = ({
    isHovered,
    isFocused,
    hasInteracted,
    isDisabled,
    errorMessage,
    loading
}) => (isHovered || isFocused) && (hasInteracted || isDisabled) && !!errorMessage && !loading;

function getAdaptedFieldFromSignerField(field, documentId) {
    const baseDate = {
        ...field,
        documentId,
        approvalId: field.approval?.originalId,
        role: field.approval?.role?.id,
        id: field.originalId

    };

    if (field.subtype === FIELD_SUBTYPES.LIST) {
        return {
            ...baseDate,
            validation: {
                enum: field.options
            }
        };
    }

    return baseDate;
}
export function getSigningFieldsConditions({
    signingFields, conditions, documentId
}) {
    if (isEmpty(signingFields) || isEmpty(conditions) || !documentId) return;
    const adaptedFields = signingFields.map((field) => getAdaptedFieldFromSignerField(field, documentId));
    // TOOD: check if needs to map back id
    return adaptedFields.map((field) => {
        const containedFieldConditions = conditions.filter((condition) => condition.condition.includes(field.originalId));
        const containedFieldConditionData = containedFieldConditions.map((condition) => getConditionFromString({
            field,
            condition,
            allFields: adaptedFields
        }));

        return containedFieldConditionData;
    }).flat();
}

export function getFieldConditions({field, fieldsConditions = []}) {
    const fieldRelatedConditions = fieldsConditions.filter((condition) => condition.actionFields
        ?.some(({originalId}) => originalId === field.originalId));
    const fieldConditions = fieldsConditions.filter((condition) => condition.field.originalId === field.originalId);

    if (field.isConditionalTarget && field.hasConditions) {
        return [...fieldRelatedConditions, ...fieldConditions];
    }
    if (field.isConditionalTarget) {
        return fieldRelatedConditions;
    }
    if (field.hasConditions) {
        return fieldConditions;
    }

    return [];
}
export function getSigningFieldTooltipLabel(field) {
    const {isCustomField} = field;
    const fieldTooltipLabelkey = isCustomField ? 'page.designer.fields.customField' : getFieldLabel(field);
    return fieldTooltipLabelkey;
}

export function getCustomFieldValue({locale, field: {customFieldNames}}) {
    return customFieldNames?.find(({language}) => language === locale)?.name || customFieldNames?.[0]?.name || '';
}

const validateFieldError = (field) => {
    const {
        isDateField,
        isRadio,
        value,
        isRequired
    } = field;

    const requiredError = {
        message: 'esl.validation.required', type: 'required', field, approvalId: field.approval.originalId
    };

    if (isRadio) {
        const isRadioButtonRequiredError = isRequired && !value && !field.radioGroup.isSelected;
        return isRadioButtonRequiredError ? requiredError : {};
    }

    // Validation for date fields shouldn't be there. But because of backend issue. We have to create some workaround. RFT-157
    if (isRequired && !value && !isDateField) {
        return requiredError;
    }

    // Validation for DateField was moved inside DateField component in order to create a workaround which unblocks user to sign

    return null;
};

export function getFieldsErrors(fields = []) {
    return fields.reduce((errors, field) => {
        const error = validateFieldError(field);

        if (error) {
            return {...errors, [field.id]: error};
        }

        if (!error && errors[field.id]) {
            const {[field.id]: removed, ...rest} = errors;
            return rest;
        }

        return errors;
    }, {});
}

const approvalFieldsErrors = (fieldsErrors, approvalId) => {
    if (!fieldsErrors || !approvalId) return;
    return Object.values(fieldsErrors)
        .filter((fieldError) => fieldError?.approvalId === approvalId);
};

export const getSignatureErrors = ({approvalId, fieldsErrors}) => {
    const signatureSubFieldsErrors = approvalFieldsErrors(fieldsErrors, approvalId);
    return signatureSubFieldsErrors;
};

export function getNodeFromRef(ref) {
    if (!ref) return;
    return ref.htmlNode;
}

export function getNextIndex(refs = [], currentIndex) {
    if (isEmpty(refs)) return;

    const index = currentIndex + 1 >= refs.length ? 0 : currentIndex + 1;
    for (let i = index; i < refs.length; i += 1) {
        const element = getNodeFromRef(refs[i]);
        if (element) {
            return i;
        }
    }
    for (let i = 0; i < refs.length; i += 1) {
        const element = getNodeFromRef(refs[i]);
        if (element) {
            return i;
        }
    }
    return 0;
}

export function getFieldsSortedByPageAndOffsets(refs = []) {
    return refs.sort((a, b) => {
        const {
            page: aPage,
            top: aTop,
            left: aLeft,
            height: aHeight
        } = a?.getFieldData() || {};
        const {
            page: bPage,
            top: bTop,
            left: bLeft,
            height: bHeight
        } = b?.getFieldData() || {};
        const aTopBoundary = aTop;
        const aBottomBoundary = aTop + aHeight;
        const bTopBoundary = bTop;
        const bBottomBoundary = bTop + bHeight;

        if (aPage !== bPage) {
            return aPage - bPage;
        }

        // Check if fields top and bottom boundary lines intersect other fields
        if (aTop <= bBottomBoundary && bTop <= aBottomBoundary) {
            // If they intersect, sort based on left offset
            return aLeft - bLeft;
        }

        // If they don't intersect vertically, sort both vertically and horizontally
        if (aTopBoundary !== bTopBoundary) {
            return aTopBoundary - bTopBoundary;
        }

        return aLeft - bLeft;
    });
}

function getPendingFieldsRefs({fieldRefs, shouldIncludeOptional}) {
    return fieldRefs.filter((ref) => {
        if (isEmpty(ref)) {
            return false;
        }

        const field = ref.getFieldData();
        const isOptional = (!field?.isSignature && !field?.isRequired) || field?.approval?.isOptional;
        const isPending = isEnabledOrRequiredInputField(field) && !isActionContainValue(field);

        if (shouldIncludeOptional) {
            return isPending;
        }

        return isPending && !isOptional;
    });
}

export function getNextFieldInfo({fieldsRef, currentFocusableFieldIndex, shouldIncludeOptional}) {
    if (!fieldsRef) return {};
    const refs = Object.values(fieldsRef.current);
    const pendingFieldsRefs = getPendingFieldsRefs({
        fieldRefs: refs, shouldIncludeOptional
    });
    const focusableFieldsRefs = getFieldsSortedByPageAndOffsets(pendingFieldsRefs);
    const nextIndex = getNextIndex(focusableFieldsRefs, currentFocusableFieldIndex);
    const nextFieldRef = get(focusableFieldsRefs, nextIndex);
    const nextFieldNode = getNodeFromRef(nextFieldRef);
    const nextFieldWithPage = pendingFieldsRefs?.[nextIndex]?.getFieldData();
    const hasNextField = pendingFieldsRefs.length > 0;

    return {
        nextFieldNode,
        nextIndex,
        nextFieldWithPage,
        hasNextField
    };
}
