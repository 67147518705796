/* eslint-disable no-underscore-dangle */
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash.get';
import {clearCookie, selectSenderExpiryRedirect} from '../components/Login/userSlice';
import httpClient from '../utils/httpClient';

export default function useUnauthInterceptor() {
    const UNAUTHORIZED = 401;
    const dispatch = useDispatch();
    const senderExpiryRedirect = useSelector(selectSenderExpiryRedirect);
    const [interceptor, setInterceptor] = useState(null);

    function handleError(redirectUrl) {
        return (err) => {
            const error = err.response;
            const status = get(error, 'status', null);
            const url = get(error, 'config.url', '');
            /**
             * Skipauth is a possible header to resolve a problem in the inPerson fetchAvatar call
             * when allowInPersonForAccountSender is enabled. We ignore the 401 error in this case.
             */
            const skipAuth = get(error, 'config.headers.skipAuth', false);

            if (skipAuth) return Promise.reject(error);

            if (status === UNAUTHORIZED && !url.includes('proxyAuth') && !url.includes('logout') && !error.config.__isRetryRequest) {
                dispatch(clearCookie());
                error.config.__isRetryRequest = true;
                window.location.href = redirectUrl;
            }
            return Promise.reject(error);
        };
    }

    useEffect(() => {
        if (interceptor !== null) {
            httpClient.interceptors.response.eject(interceptor);
        }
        const interceptorId = httpClient.interceptors.response.use(
            (response) => response,
            handleError(senderExpiryRedirect)
        );
        setInterceptor(interceptorId);
    }, [senderExpiryRedirect]);
}
