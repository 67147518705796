/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SimpleBar from 'simplebar-react';
import {HiOutlinePlusCircle} from '@react-icons/all-files/hi/HiOutlinePlusCircle';
import {HiOutlineX} from '@react-icons/all-files/hi/HiOutlineX';
import {HiOutlineExclamation} from '@react-icons/all-files/hi/HiOutlineExclamation';
import classnames from 'classnames';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import {isMobile} from 'react-device-detect';
import Document from '../icons/Document';
import CSV from '../icons/CSV';
import {selectUploadErrors} from '../../Dashboard/dashboardSlice';
import getDocumentUId from '../../../utils/getDocumentUId';
import {selectUser} from '../../Login/userSlice';
import useNotifications from '../../../hooks/useNotifications';
import {formatBytesToMb} from '../../AccountSettings/CaptureSignature/helpers';
import UploadIcon from '../icons/UploadIcon';
import AppInputLabel from '../../Core/labels/AppInputLabel/AppInputLabel';
import './dropzone.less';

const defaultAcceptedFilesExt = [
    '.pdf',
    '.pdfa',
    '.doc',
    '.docx',
    '.odt',
    '.rtf',
    '.txt'
];

const fileErrorMessageTypes = {
    'file-invalid-type': 'esl.error.validation.invalidFileType',
    'file-too-large': 'esl.error.validation.invalidFileMaxSize',
    'too-many-files': 'esl.error.validation.invalidFileAmount'
};

export default function Dropzone({
    documents,
    updateDocuments,
    removeDocument,
    hideDocuments,
    isButtonView,
    acceptedFilesExt = defaultAcceptedFilesExt,
    dropZoneProps = {},
    setUploadFileError = () => {},
    id,
    dropzoneTabIndex,
    packageName,
    dropzoneTitle,
    hideDropzoneTitle,
    showExtension = false,
    isBulkCSV,
    messageKeys,
    shouldSortByName
}) {
    const intl = useIntl();
    const {showErrorAlert} = useNotifications();
    const user = useSelector(selectUser);
    const [globalDragCounter, setGlobalDragCounter] = useState(0);
    const [isMobileDevice, setIsMobileDevice] = useState();
    const isFatIcons = get(user, 'data.fatIcons');
    const uploadError = useSelector(selectUploadErrors);
    const onDrop = useCallback((acceptedFiles) => {
        let files = [...acceptedFiles];

        if (shouldSortByName && !isEmpty(files)) {
            files = files.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true}));
        }

        updateDocuments(files);
        if (isEmpty(files)) {
            setUploadFileError('esl.error.validation.unsupportedFileType');
        }
    }, [packageName, updateDocuments]);

    const onDropRejected = useCallback((rejectedFiles) => {
        rejectedFiles.forEach((fileObj) => {
            fileObj.errors.forEach((error) => {
                const errorMsg = fileErrorMessageTypes[error.code];
                if (errorMsg === 'esl.error.validation.invalidFileMaxSize') {
                    showErrorAlert(`${intl.formatMessage(
                        {id: errorMsg, defaultMessage: ''},
                        {size: formatBytesToMb(fileObj.file.size)}
                    )} (${fileObj.file.name})`);
                } else if (errorMsg) showErrorAlert(`${intl.formatMessage({id: errorMsg, defaultMessage: ''})} (${fileObj.file.name})`);
            });
        });
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        isDragAccept
    } = useDropzone({
        onDrop,
        onDropRejected,
        accept: acceptedFilesExt.join(','),
        ...dropZoneProps
    });
    const {
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDrop: onDropRootProp,
        ...rootProps
    } = getRootProps();
    const updatedProps = {
        ...rootProps,
        tabIndex: dropzoneTabIndex || rootProps.tabIndex
    };

    const dropzoneContainerClassName = classnames('dropzone-container', {
        'is-mobile': isMobile
    });

    const dropzoneClassName = classnames('dropzone', {
        'is-active': isDragActive,
        'is-reject': isDragReject,
        'is-accept': isDragAccept,
        'is-button-view': isButtonView
    });

    const dropzoneOverlayClassName = classnames('dropzone-overlay', {
        'is-active': isDragActive,
        'is-reject': isDragReject,
        'is-accept': isDragAccept,
        'is-dragging': globalDragCounter > 0
    });

    function onRemove(document) {
        return (e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            removeDocument(document);
        };
    }

    const dragEnter = useCallback(() => {
        setGlobalDragCounter((prevGlobalDragCounter) => prevGlobalDragCounter + 1);
    }, []);

    const dragLeave = useCallback(() => {
        setGlobalDragCounter((prevGlobalDragCounter) => prevGlobalDragCounter - 1);
    }, []);

    useEffect(() => {
        window.addEventListener('dragenter', dragEnter);
        window.addEventListener('dragleave', dragLeave);
        window.addEventListener('drop', dragLeave);

        return () => {
            window.removeEventListener('dragenter', dragEnter);
            window.removeEventListener('dragleave', dragLeave);
            window.removeEventListener('drop', dragLeave);
        };
    }, [dragEnter, dragLeave]);

    useEffect(() => {
        setIsMobileDevice(isMobile);
    }, [isMobile]);

    function renderMobileBtn() {
        return (
            <div className="mobile-add-btn-container">
                <div className="add-btn">
                    <HiOutlinePlusCircle className="add-icon" />
                    <div className="label">
                        {intl.formatMessage({id: messageKeys ? messageKeys.addDocument : 'esl.generic.add_document', defaultMessage: ''})}
                    </div>
                </div>
            </div>
        );
    }
    function renderButtonView() {
        return (
            <Tooltip
                title={intl.formatMessage({id: messageKeys ? messageKeys.addDocument : 'esl.generic.add_document', defaultMessage: ''})}
                arrow
            >
                <div className="button-view">
                    <div className="add-btn">
                        <HiOutlinePlusCircle className="add-icon" />
                    </div>
                </div>
            </Tooltip>
        );
    }
    const uploadDocumentsLabel = intl.formatMessage({id: 'page.dashboard.newTransaction.dropTitle', defaultMessage: ''});

    function renderDocument(document, index) {
        const error = uploadError
            .find(({documentUId}) => documentUId === getDocumentUId(document));
        const errorKey = get(error, 'messageKey', null);
        const errorTag = get(error, 'parameters.tag', null);
        const documentClassname = classnames('document', {
            'is-from-template': !!document.id,
            'has-error': errorKey
        });
        const defaultErrorMessage = intl.formatMessage({
            id: 'esl.http.status_500', defaultMessage: 'Error'
        });
        const errorMessage = errorKey && intl.formatMessage({
            id: `esl.${errorKey}`, defaultMessage: defaultErrorMessage
        }, {
            tag: errorTag
        });
        const documentName = showExtension ? document.name : document.name.split('.').slice(0, -1).join('.') || document.name;
        return (
            <div
                className={documentClassname}
                key={`${document.name}-${index}`}
                aria-label={intl.formatMessage({id: 'esl.generic.document', defaultMessage: ''})}
            >
                <div className="icon-container">
                    {isBulkCSV ? <CSV aria-hidden /> : <Document aria-hidden />}
                </div>
                {errorKey && (
                    <div className="error-icon-container">
                        <Tooltip
                            title={errorMessage}
                            classes={{popper: 'document-upload-error-tooltip'}}
                            interactive
                            arrow
                        >
                            <div>
                                <HiOutlineExclamation aria-hidden />
                            </div>
                        </Tooltip>
                    </div>
                )}
                <div
                    className="name-container"
                    tabIndex={0}
                    aria-label={`${intl.formatMessage({id: 'esl.generic.document_name', defaultMessage: ''})} ${documentName}`}
                >
                    {documentName}
                </div>
                <div className="remove-container">
                    {!document.id && (
                        <IconButton
                            edge="end"
                            color="inherit"
                            size="small"
                            onClick={onRemove(document)}
                            aria-label={`${intl.formatMessage({id: 'esl.generic.delete_document', defaultMessage: ''})} ${documentName}`}
                        >
                            <HiOutlineX aria-hidden />
                        </IconButton>
                    )}
                </div>
            </div>
        );
    }

    function renderDropMoreMessage() {
        if (isBulkCSV) {
            return null;
        }
        return isMobileDevice ? renderMobileBtn() : (
            <div className="drop-more-msg">
                {intl.formatMessage({id: 'page.dashboard.newTransaction.dropMore', defaultMessage: ''})}
            </div>
        );
    }

    const documentsList = (
        <div
            className="documents-list-container"
            aria-label={uploadDocumentsLabel}
        >
            <div className="documents-list">
                <SimpleBar style={{maxHeight: '100%'}}>
                    {!isEmpty(documents) && !hideDocuments && documents.map(renderDocument)}
                </SimpleBar>
            </div>
            {renderDropMoreMessage()}
        </div>
    );

    function renderDropMessageContainer() {
        if (isButtonView) {
            return renderButtonView();
        }
        if (isMobileDevice) {
            return renderMobileBtn();
        }
        return (
            <div
                className="drop-message-container"
                aria-label={uploadDocumentsLabel}
                role="button"
            >
                {!isFatIcons
                    ? <UploadIcon className="upload-icon" aria-hidden />
                    : <UploadIcon className="upload-icon" aria-hidden />}
                <div className="drag-drop-message">
                    <div>
                        {intl.formatMessage({id: 'page.dashboard.newTransaction.dragDrop1', defaultMessage: ''})}
                    </div>
                    <div>
                        {intl.formatMessage({id: 'page.dashboard.newTransaction.dragDrop2', defaultMessage: ''})}
                    </div>
                </div>
            </div>
        );
    }

    function renderNotActiveComponent() {
        if (isEmpty(documents) || hideDocuments) {
            return renderDropMessageContainer();
        }
        return documentsList;
    }

    return (
        <div className={dropzoneContainerClassName}>
            {!isButtonView && !hideDropzoneTitle && (
                <AppInputLabel htmlFor="dropzoneId">
                    { intl.formatMessage({id: dropzoneTitle || 'page.dashboard.newTransaction.dropTitle', defaultMessage: ''})}
                </AppInputLabel>
            )}
            <div
                className={dropzoneOverlayClassName}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDragOver={onDragOver}
                onDrop={onDropRootProp}
            >
                <div className="drop-border-overlay">
                    <div className="drop-overlay-message">
                        {intl.formatMessage({id: 'page.dashboard.newTransaction.dropHere', defaultMessage: ''})}
                    </div>
                </div>
            </div>
            <div
                className={dropzoneClassName}
                id={id}
                data-focus-id={id}
                {...updatedProps}
            >
                <input {...getInputProps()} data-wdio="test-dropzone" id="dropzoneId" />
                {!isDragActive && renderNotActiveComponent()}
                {isDragActive && (
                    <div className="drop-here-message">
                        {intl.formatMessage({id: 'page.dashboard.newTransaction.dropHere', defaultMessage: ''})}
                    </div>
                )}
            </div>
        </div>
    );
}
