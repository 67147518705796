/* eslint-disable no-restricted-properties */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import {compress} from 'image-conversion';
import jQuery from 'jquery';
import MD5 from './utils/md5';
import isClient from '../../../utils/isClient';
import b64 from './utils/b64';

export function convertSignatureToESL({lines, width, height}) {
    const eSLNativeSig = {
        width,
        height,
        deltas: []
    };

    const {deltas} = eSLNativeSig;
    let lastPoint = {x: 0, y: 0};

    for (let l = 0, ll = lines.length; l < ll; l += 1) {
        const line = lines[l];
        for (let p = 0, pl = line.x.length; p < pl; p += 1) {
            const point = {x: line.x[p], y: line.y[p]};
            const delta = {x: line.x[p] - lastPoint.x, y: line.y[p] - lastPoint.y};
            if (p === 0) delta.up = true;
            deltas.push(delta);
            lastPoint = point;
        }
    }

    return eSLNativeSig;
}

export function convertESLToSignature({eSLNativeSig, width, height}) {
    const {deltas} = eSLNativeSig;

    let ratio = width / eSLNativeSig.width;
    if ((ratio * eSLNativeSig.height) > height) {
        ratio = height / eSLNativeSig.height;
    }

    const offsetX = (width - (ratio * eSLNativeSig.width)) / 2;
    const offsetY = (height - (ratio * eSLNativeSig.height)) / 2;

    const lines = [];
    let oldLine = null;
    const lastPoint = {x: 0, y: 0};

    for (let p = 0, l = deltas.length; p < l; p++) {
        const point = deltas[p];

        if (point.up) {
            oldLine = null;
        }

        if (!oldLine) {
            oldLine = {x: [], y: []};
            lines.push(oldLine);
        }

        lastPoint.x += point.x;
        lastPoint.y += point.y;

        oldLine.x.push((lastPoint.x * ratio) + offsetX);
        oldLine.y.push((lastPoint.y * ratio) + offsetY);
    }
    return lines;
}

function compressDelta(d) {
    const signs = (d.x < 0 ? 0x20 : 0)
              | (d.y < 0 ? 0x10 : 0);

    const x = Math.abs(d.x);
    const y = Math.abs(d.y);

    const m = Math.max(x, y);
    let t = null;
    let packets;
    if (!d.up) {
        t = m <= 0x0003 && 1 // 2 bits
          || m <= 0x003f && 2 // 6 bits
          || m <= 0x03ff && 3 // 10 bits
          || m <= 0x0fff && 4 // 12 bits
          || 5; // 16 bits
    } else {
        t = m <= 0x000f && 6 // 4 bits
          || m <= 0x00ff && 7 // 8 bits
          || m <= 0x0fff && 4 // 12 bits
          || 5; // 16 bits
    }

    switch (t) {
        case 1:
            packets = [0x80 | signs
                             | x << 2
                             | y];
            break;
        case 2:
            packets = [0x40 | signs
                             | (x >>> 2) & 0x0c
                             | (y >>> 4) & 0x03
            , ((x << 4) & 0xf0
                        | y & 0x0f)
            ];
            break;
        case 3:
            packets = [0xc0 | signs
                             | (x >>> 6) & 0x0c
                             | (y >>> 8) & 0x03,
            x & 0xff,
            y & 0xff
            ];
            break;
        case 4:
            packets = [signs | 0x04 | (d.up ? 0 : 1)
                , ((x >>> 4) & 0xf0
                        | (y >>> 8) & 0x0f),
                x & 0xff,
                y & 0xff
            ];
            break;
        case 5:
            packets = [signs | 0x06 | (d.up ? 0 : 1),
                (x >>> 8) & 0xff,
                x & 0xff,
                (y >>> 8) & 0xff,
                y & 0xff
            ];
            break;
        case 6:
            packets = [signs,
                x << 4 | y];
            break;
        case 7:
            packets = [signs | 0x02,
                x, // & 0xff
                y]; // & 0xff
            break;
        default:
            // eslint-disable-next-line no-throw-literal
            throw 'Packet type could not be determine correctly';
    }

    return String.fromCharCode.apply(null, packets);
}

function compressDeltas(dts) {
    let l = dts.length;
    const ar = [];
    while (l--) {
        ar.unshift(compressDelta(dts[l]));
    }
    return ar.join('');
}

export function encode(drawing) {
    const compressed = compressDeltas(drawing.deltas);
    const dtsCount = drawing.deltas.length;
    const w = drawing.width;
    const h = drawing.height;

    const wBytes = [w & 0xff,
        w >> 8 & 0xff];
    const hBytes = [h & 0xff,
        h >> 8 & 0xff];
    const imgdesc = String.fromCharCode(drawing.width & 0xff,
        drawing.width >> 8 & 0xff,
        drawing.height & 0xff,
        drawing.height >> 8 & 0xff,

        0x03, 0x00, 0x03, 0x00,

        (compressed.length + 12) & 0xFF,
        (compressed.length + 12) >> 8 & 0xFF,
        (compressed.length + 12) >> 16 & 0xFF,
        (compressed.length + 12) >> 24 & 0xFF,

        0x03, 0x00, 0x00, 0x00,

        drawing.width & 0xff,
        drawing.width >> 8 & 0xff,
        drawing.height & 0xff,
        drawing.height >> 8 & 0xff,

        dtsCount & 0xFF,
        dtsCount >> 8 & 0xFF,
        dtsCount >> 16 & 0xFF,
        dtsCount >> 24 & 0xFF);

    return [
        String.fromCharCode(0x01, 0x00, 0x00, 0x00),
        MD5.encode(imgdesc + compressed, false),
        imgdesc,
        compressed
    ].join('');
}

function decompressDelta(bytes) {
    function pt(x, y, up) {
        const p = {
            x,
            y
        };
        if (up === true) { p.up = true; }
        return p;
    }

    let d = null;
    const b0 = bytes.read(1);
    let b1;
    let pen = {
        down: false,
        up: true
    };
    const sign = {
        x: b0 & 0x20 ? -1 : 1,
        y: b0 & 0x10 ? -1 : 1
    };
    switch (b0 & 0xC0) {
        case 0x80:
            d = [(b0 & 0x0C) >> 2,
                b0 & 0x03,
                pen.down];
            break;
        case 0x40:
            b1 = bytes.read(1);
            d = [(b0 & 0x0c) << 2 | (b1 & 0xf0) >> 4,
                (b0 & 0x03) << 4 | (b1 & 0x0f),
                pen.down];
            break;
        case 0xC0:
            d = [(b0 & 0x0c) << 6 | bytes.read(1),
                (b0 & 0x03) << 8 | bytes.read(1),
                pen.down];
            break;

        default:
            pen = (b0 & 0x01) ? pen.down : pen.up;

            // eslint-disable-next-line default-case
            switch (b0 & 0x06) {
                case 0x00:
                    b1 = bytes.read(1);
                    d = [b1 >> 4,
                        b1 & 0x0f];
                    break;

                case 0x02:
                    d = [bytes.read(1),
                        bytes.read(1)];
                    break;

                case 0x04:
                    b1 = bytes.read(1);
                    d = [(b1 & 0xf0) << 4 | bytes.read(1),
                        (b1 & 0x0f) << 8 | bytes.read(1)];
                    break;

                case 0x06:

                    d = [bytes.read(2),
                        bytes.read(2)];

                    break;
            }
            d.push(pen);
            break;
    }
    return pt(d[0] * sign.x,
        d[1] * sign.y,
        d[2]);
}

function decompressDeltas(bytes) {
    let l = bytes.length;
    const ar = [];

    while (bytes.length && l--) { // Suicide prevention program
        ar.push(decompressDelta(bytes));
    }
    return ar;
}

export function decode(binstr) {
    const bytes = binstr.split('')
        .slice(36)
        .map((c) => c.charCodeAt());

    bytes.read = function (n, littleEndian) {
        let num = 0;
        let mul = 0;
        const n2 = n - 1;
        // eslint-disable-next-line no-param-reassign
        while (n--) {
            mul = littleEndian ? n2 - n
                : n;
            num |= this.shift() << (mul * 8);
        }
        return num;
    };

    const drawing = {
        width: bytes.read(2, true),
        height: bytes.read(2, true),
        deltas: []
    };
    const ptsCount = bytes.read(4, true);

    drawing.deltas = decompressDeltas(bytes);

    return drawing;
}

export function formatBytesToKb(bytes, decimals = 2) {
    if (bytes === 0) return 0;
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const kilobytes = 1;
    return parseFloat((bytes / Math.pow(k, kilobytes)).toFixed(dm));
}
export function formatBytesToMb(bytes, decimals = 1) {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    return parseFloat(bytes / (k ** 2)).toFixed(dm);
}
export function calculateStringSize(str) {
    return formatBytesToKb(new Blob([str]).size);
}

const MAX_WIDTH = 1400;

export function checkShouldCompressFile({width, height}) {
    return width > MAX_WIDTH;
}

export async function compressFile(file) {
    return compress(file, {
        quality: 1,
        type: 'image/jpeg',
        width: MAX_WIDTH
    });
}
/**
 * @returns {data:image/png;base64,iVBORw...}
 */
export function readFile(file) {
    return new Promise((resolve) => {
        const reader = new window.FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}

export function getTypeOfBase64String(base64String = '') {
    if (!base64String) return;
    return base64String.split(',')[0];
}
// input -> data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfIAAACnCAMAA
// output ->  iVBORw0KGgoAAAANSUhEUgAAAfIAAACnCAMAAA
export function getDataOfBase64String(base64String = '') {
    return base64String.split(',')[1];
}

export function isFileExtensionAccepted(filename = '', acceptedExt = []) {
    const parts = filename.split('.');
    const ext = parts[parts.length - 1];

    if (!ext) {
        return false;
    }

    return acceptedExt.includes(ext.toLowerCase());
}

export function configurePayloadToSignFromBase64String(base64String) {
    return {
        type: getTypeOfBase64String(base64String),
        value: getDataOfBase64String(base64String)
    };
}

// returns signature in SVG format
async function exportSignatureFromCanvas(canvasEl) {
    const [type, value] = jQuery(canvasEl).jSignature('getData', 'svgbase64');
    return `data:${type},${value}`;
}

export async function renderJsSignature({
    canvasEl, signature, width, height
}) {
    if (!signature && !canvasEl) return;
    if (isClient && jQuery(canvasEl).jSignature) {
        const decodedLines = convertESLToSignature({
            eSLNativeSig: decode(b64.decode(signature)),
            width,
            height
        });
        jQuery(canvasEl).jSignature({
            width,
            height,
            color: '#000'
        });
        jQuery(canvasEl).jSignature('setData', decodedLines, 'native');

        const formattedSignatureString = await exportSignatureFromCanvas(canvasEl);

        return formattedSignatureString;
    }
}

/**
 * This function use jsSignature string -> imports jsSignature library -> append pseudo canvas element into DOM
 * -> render signature into that element -> return convertjsSiganture data in image format -> remove element form DOM
 */

// width and height it's just values which provide "ok" appearance of the image
export async function convertOneSpanSignatureToSVG({signature, width = 300, height = 200}) {
    const pseudoCanvasEl = document.createElement('div');
    document.body.appendChild(pseudoCanvasEl);
    pseudoCanvasEl.width = width;
    pseudoCanvasEl.height = height;

    const formattedSignatureString = await renderJsSignature({
        canvasEl: pseudoCanvasEl, signature, width, height
    });
    document.body.removeChild(pseudoCanvasEl);

    return formattedSignatureString;
}

function getSegmentLengthPX(line) {
    const deltaX = line.lx - line.mx;
    const deltaY = line.ly - line.my;
    const segmentLength = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    return segmentLength;
}

export function getSignatureLengthPX(signatureEL) {
    const lines = jQuery(signatureEL).signaturePad().getSignature();

    let totalLength = 0;

    for (const line of lines) {
        const segmentLength = getSegmentLengthPX(line);
        totalLength += segmentLength;
    }

    return totalLength;
}
