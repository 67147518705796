/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import getValidPackagesParams from '../../utils/getValidPackagesParams';
import {
    BASE_PATH,
    DEFAULT_TEMPLATES_OPTIONS,
    PACKAGES_PER_PAGE_OPTIONS,
    PACKAGE_TYPES,
    VISIBILITY_SENDER
} from '../../../constants';
import httpClient from '../../utils/httpClient';
import permissionsService from '../../utils/permissionsService';
import {getRecipientsEmailsFromPackage} from '../../utils/helpers';
import {fetchAvatarsByEmails} from '../Packages/Recipients/recipientsSlice';
import {getErrorMessage} from '../../utils/requests/error';

export const templatesSlice = createSlice({
    name: 'templates',
    initialState: {
        templates: [],
        count: 0,
        templatesPerPage: PACKAGES_PER_PAGE_OPTIONS[10],
        visibility: {},
        isTemplatesFetching: false,
        fetchingTemplatesError: null,
        isVisibilityFetching: false,
        fetchingVisibilityError: null,
        isDeletingTemplate: false,
        deleteTemplateError: null,
        options: DEFAULT_TEMPLATES_OPTIONS,
        fastTrackUrls: {},
        isfastTrackUrlsFetching: false,
        fetchingFastTrackUrlsError: null,
        isFetchingFastTrackInfo: false,
        fetchingFastTrackInfoError: null,
        isUpdatingFastTrackInfo: false,
        updatingFastTrackInfoError: null

    },
    reducers: {
        setTemplates: (state, action) => {
            state.templates = action.payload;
        },
        setCount: (state, action) => {
            state.count = action.payload;
        },
        setTemplatesPerPage: (state, action) => {
            state.templatesPerPage = action.payload;
        },
        setIsTemplatesFetching: (state, action) => {
            state.isTemplatesFetching = action.payload;
        },
        setFetchingTemplatesError: (state, action) => {
            state.fetchingTemplatesError = action.payload;
        },
        setOptions: (state, action) => {
            state.options = action.payload;
        },
        setVisibility: (state, action) => {
            state.visibility = action.payload;
        },
        setIsVisibilityFetching: (state, action) => {
            state.isVisibilityFetching = action.payload;
        },
        setFetchingVisibilityError: (state, action) => {
            state.fetchingVisibilityError = action.payload;
        },
        setIsDeletingTemplate: (state, action) => {
            state.isDeletingTemplate = action.payload;
        },
        setDeleteTemplateError: (state, action) => {
            state.deleteTemplateError = action.payload;
        },
        setFastTrackUrls: (state, action) => {
            state.fastTrackUrls = action.payload;
        },
        setIsfastTrackUrlsFetching: (state, action) => {
            state.isfastTrackUrlsFetching = action.payload;
        },
        setFetchingFastTrackUrlsError: (state, action) => {
            state.fetchingFastTrackUrlsError = action.payload;
        },
        setFetchingFastTrackInfoError: (state, action) => {
            state.fetchingFastTrackInfoError = action.payload;
        },
        setUpdatingFastTrackInfoError: (state, action) => {
            state.updatingFastTrackInfoError = action.payload;
        }

    }
});

export const {
    setTemplates,
    setCount,
    setTemplatesPerPage,
    setIsTemplatesFetching,
    setFetchingTemplatesError,
    setOptions,
    setVisibility,
    setIsVisibilityFetching,
    setFetchingVisibilityError,
    setIsDeletingTemplate,
    setDeleteTemplateError,
    setFastTrackUrls,
    setIsfastTrackUrlsFetching,
    setFetchingFastTrackUrlsError,
    setFetchingFastTrackInfoError,
    setUpdatingFastTrackInfoError
} = templatesSlice.actions;

export const fetchTemplates = ({
    headers, baseUrl
} = {}) => async (dispatch, getState) => {
    const {templates, user} = getState();
    const {features, permissions, hasUserPermissionOnRoot} = user;
    const {
        from,
        to,
        sort,
        dir,
        search,
        predefined,
        visibility
    } = templates.options;
    const servicePath = 'packages';
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const isTemplatesShareEnabled = permissionsService.hasShareTemplatePermission({features, permissions, hasUserPermissionOnRoot});
    const options = {
        params: getValidPackagesParams({
            type: PACKAGE_TYPES.TEMPLATE,
            from,
            to,
            sort,
            dir,
            predefined,
            visibility
        })
    };

    if (headers) {
        options.headers = headers;
    }

    if (search) {
        options.params.search = search;
    }

    dispatch(setIsTemplatesFetching(true));
    dispatch(setFetchingTemplatesError(null));

    try {
        const result = await httpClient.get(url, options);
        const resultTemplates = get(result, 'data.results', []);
        const recipientEmails = getRecipientsEmailsFromPackage(resultTemplates);
        await dispatch(fetchAvatarsByEmails({baseUrl, headers, emails: recipientEmails}));

        dispatch(setTemplates(get(result, 'data.results', [])));
        dispatch(setCount(get(result, 'data.count', 0)));
        dispatch(setIsTemplatesFetching(false));
        dispatch(setFetchingTemplatesError(null));
        return true;
    } catch (err) {
        dispatch(setIsTemplatesFetching(false));
        dispatch(setFetchingTemplatesError(getErrorMessage(err)));
        return false;
    }
};

export const fetchVisibility = (packageId) => async (dispatch, getState) => {
    const {templates} = getState();
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/visibility`;

    dispatch(setIsVisibilityFetching(true));
    dispatch(setFetchingVisibilityError(null));

    try {
        const result = await httpClient.get(url);
        const configurations = get(result, 'data.configurations', []);

        dispatch(setVisibility({
            ...templates.visibility,
            [packageId]: configurations
        }));
        dispatch(setIsVisibilityFetching(false));
        dispatch(setFetchingVisibilityError(null));
        return true;
    } catch (err) {
        dispatch(setIsVisibilityFetching(false));
        dispatch(setFetchingVisibilityError(getErrorMessage(err)));
        return false;
    }
};

export const deleteTemplate = (packageId) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}`;

    dispatch(setIsDeletingTemplate(true));
    dispatch(setDeleteTemplateError(null));

    try {
        await httpClient.delete(url);

        dispatch(setIsDeletingTemplate(false));
        dispatch(setDeleteTemplateError(null));
        return true;
    } catch (err) {
        dispatch(setIsDeletingTemplate(false));
        dispatch(setDeleteTemplateError(getErrorMessage(err)));
        return false;
    }
};

export const fetchFastTrackSigningUrl = (templateId) => async (dispatch, getState) => {
    const signingApiUrl = `${BASE_PATH}/proxy/fastTrack/${templateId}/url?signing=true`;

    dispatch(setIsfastTrackUrlsFetching(true));
    dispatch(setFetchingFastTrackUrlsError(null));

    try {
        const signingUrlResult = await httpClient.get(signingApiUrl);
        const {templates} = getState();
        const signingUrl = get(signingUrlResult, 'data.url', '');
        dispatch(setFastTrackUrls({
            ...templates.fastTrackUrls,
            [templateId]: {
                ...templates.fastTrackUrls[templateId],
                signingUrl
            }
        }));
        dispatch(setIsfastTrackUrlsFetching(false));
        dispatch(setFetchingFastTrackUrlsError(null));
        return signingUrl;
    } catch (err) {
        dispatch(setIsfastTrackUrlsFetching(false));
        dispatch(setFetchingFastTrackUrlsError(getErrorMessage(err)));
        return false;
    }
};

export const fetchFastTrackSendingUrl = (templateId) => async (dispatch, getState) => {
    const sendingApiUrl = `${BASE_PATH}/proxy/fastTrack/${templateId}/url?signing=false`;

    dispatch(setIsfastTrackUrlsFetching(true));
    dispatch(setFetchingFastTrackUrlsError(null));

    try {
        const sendingUrlResult = await httpClient.get(sendingApiUrl);
        const {templates} = getState();

        dispatch(setFastTrackUrls({
            ...templates.fastTrackUrls,
            [templateId]: {
                ...templates.fastTrackUrls[templateId],
                sendingUrl: get(sendingUrlResult, 'data.url', '')
            }
        }));
        dispatch(setIsfastTrackUrlsFetching(false));
        dispatch(setFetchingFastTrackUrlsError(null));
        return true;
    } catch (err) {
        dispatch(setIsfastTrackUrlsFetching(false));
        dispatch(setFetchingFastTrackUrlsError(getErrorMessage(err)));
        return false;
    }
};

export const fetchFastTrackInfo = (token) => async (dispatch) => {
    dispatch(setFetchingFastTrackInfoError(null));

    const url = `${BASE_PATH}/baseProxy/fts?token=${token}`;
    try {
        const result = await httpClient.get(url);
        return result;
    } catch (err) {
        dispatch(setFetchingFastTrackInfoError(getErrorMessage(err)));
        return false;
    }
};
export const submitFastTrackInfo = (token, payload) => async (dispatch) => {
    dispatch(setUpdatingFastTrackInfoError(null));
    const url = `${BASE_PATH}/baseProxy/fts?token=${token}`;
    try {
        const result = await httpClient.post(url, payload);
        dispatch(setUpdatingFastTrackInfoError(null));
        return result;
    } catch (err) {
        dispatch(setUpdatingFastTrackInfoError(getErrorMessage(err)));
        return false;
    }
};

export const selectTemplates = (state) => state.templates.templates;
export const selectCount = (state) => state.templates.count;
export const selectTemplatesPerPage = (state) => state.templates.templatesPerPage;
export const selectIsTemplatesFetching = (state) => state.templates.isTemplatesFetching;
export const selectFetchingTemplatesError = (state) => state.templates.fetchingTemplatesError;
export const selectVisibility = (state) => state.templates.visibility;
export const selectIsVisibilityFetching = (state) => state.templates.isVisibilityFetching;
export const selectIsDeletingTemplate = (state) => state.templates.isDeletingTemplate;
export const selectOptions = (state) => state.templates.options;
export const selectFastTrackUrls = (state) => state.templates.fastTrackUrls;
export const selectIsfastTrackUrlsFetching = (state) => state.templates.isfastTrackUrlsFetching;
export const selectFetchingFastTrackUrlsError = (state) => state.templates.fetchingFastTrackUrlsError;
export const selectFetchingFastTrackInfoError = (state) => state.templates.fetchingFastTrackInfoError;
export const selectUpdatingFastTrackInfoError = (state) => state.templates.updatingFastTrackInfoError;

export default templatesSlice.reducer;
