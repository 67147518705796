/* eslint-disable no-undef */
import PING from '../../../../gql/ping.gql';
import {getCurrentSessionId} from '../../../utils/storageSelectors';

const EXAMPLE_TRANSACTION_ID = 'example';

export const stringBetween = (originalStr, left, right) => {
    if (left && right) {
        return originalStr.split(left).pop().split(right)[0];
    }
    return originalStr;
};

export const ping = (client) => client.query({
    fetchPolicy: 'no-cache',
    query: PING
});

export const downloadInfo = (url) => {
    const isZip = url.indexOf('/zip') > 0;
    const type = isZip ? 'application/zip' : 'application/pdf';
    const fileName = isZip
        ? `${stringBetween(url, 'static/', '/documents')}.zip`
        : `${stringBetween(url, 'documents/', '/pdf')}.pdf`;
    return {isZip, type, fileName};
};

export const getFileUrlWithNameToDownload = ({downloadUrl, name, id}) => new Promise((resolve, reject) => {
    const xhr = new window.XMLHttpRequest();
    // such tipe needed to prevent opening PDF reader after PDF download
    const type = 'application/octet-stream';

    xhr.onload = () => {
        const {status, response} = xhr;
        if (status === 200) {
            const blob = new window.Blob([response], {type});
            const fileName = name.endsWith('.pdf') ? name : `${name}.pdf`;

            resolve({
                name: fileName,
                url: URL.createObjectURL(blob)
            });
        }
    };
    xhr.onerror = () => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
            status: xhr.status,
            statusText: xhr.statusText
        });
    };

    xhr.open('GET', downloadUrl, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('token', getCurrentSessionId());
    xhr.responseType = 'blob';
    xhr.send('parameter1=&parameter2=');
});

const downloadFile = ({url, fileName}) => {
    const xhr = new window.XMLHttpRequest();
    const {type} = downloadInfo(url);

    xhr.onload = () => {
        const {status, response} = xhr;
        if (status === 200) {
            const blob = new window.Blob([response], {type});
            if (window.navigator.userAgent.includes('CriOS')) {
                const reader = new window.FileReader();
                reader.onload = () => {
                    window.location.href = reader.result;
                };
                reader.readAsDataURL(blob);
                return;
            }
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.target = '_blank';
            link.download = (fileName || 'package') + (type === 'application/pdf' ? '.pdf' : '.zip');
            link.click();
        }
    };

    xhr.open('GET', url, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('token', getCurrentSessionId());
    xhr.responseType = 'blob';
    xhr.send('parameter1=&parameter2=');
};

export const download = ({fileName, url, client}) => {
    const checkNetwork = ping(client);
    // Now we have safari downloads in iOS
    checkNetwork.then(() => {
        downloadFile({url, fileName});
    });
};

export function getViewportWidth() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth);
}

export function getViewportHeight() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight);
}

export const isInBrowser = () => typeof window !== 'undefined';

export function isInIframe() {
    if (isInBrowser()) {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    } else {
        return false;
    }
}

export const isTouchEnabledDevice = () => 'ontouchstart' in window
  || navigator.maxTouchPoints > 0
  || navigator.msMaxTouchPoints > 0;

export const getCustomHandOverUrl = ({
    handOverUrl,
    status,
    transactionId,
    signerId,
    parameters
}) => {
    if (!handOverUrl) {
        return null;
    }

    const [url, query] = handOverUrl.split('?');
    const params = new URLSearchParams(query);
    const paramsObject = {
        status,
        package: transactionId,
        signer: signerId
    };

    let keysToInclude = Object.keys(paramsObject);
    if (parameters) {
        const lowerCaseParams = parameters.map((p) => p.toLowerCase());
        keysToInclude = keysToInclude.filter((key) => lowerCaseParams.includes(key));
    }
    keysToInclude.forEach((key) => params.append(key, paramsObject[key]));
    const paramsString = params.toString();

    return paramsString.length ? `${url}?${paramsString}` : url;
};

export const getScroller = () => document.documentElement;

export const saveToTempStorage = ({value, key}) => {
    if (!window.tempStore) {
        window.tempStore = {};
    }
    window.tempStore[key] = value;
};

export const getIsExampleTransactionID = (id) => id === EXAMPLE_TRANSACTION_ID;
