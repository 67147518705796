import {useIntl} from 'react-intl';

const useJoinMessage = () => {
    const intl = useIntl();

    return (...params) => {
        const strings = params.map((str) => {
            if ((str && str.indexOf('.') === -1) || str === ',') {
                return str;
            }

            try {
                return intl.formatMessage({id: str, defaultMessage: str});
            } catch (error) {
                return str;
            }
        });
        return strings.join(' ');
    };
};

export default useJoinMessage;
