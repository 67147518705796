import {RESTRICTED_SHORTCUTS, SHORTCUTS} from '../../../../constants';
import CloneTransaction from './Actions/CloneTransaction/CloneTransaction';
import CreateTransaction from './Actions/CreateTransaction';
import HideActions from './Actions/HideActions';
import SignNext from './Actions/SignNext';
import SupportPortal from './Actions/SupportPortal';

const actionsMap = [{
    id: RESTRICTED_SHORTCUTS.HIDE,
    ActionComponent: HideActions,
    isPrivate: true
}, {
    id: RESTRICTED_SHORTCUTS.SUPPORT_PORTAL,
    ActionComponent: SupportPortal
}, {
    id: SHORTCUTS.SIGN_NEXT,
    ActionComponent: SignNext,
    isPrivate: true
}, {
    id: SHORTCUTS.CLONE_TRANSACTION,
    ActionComponent: CloneTransaction,
    isPrivate: true
}, {
    id: SHORTCUTS.CREATE_TRANSACTION,
    ActionComponent: CreateTransaction,
    isPrivate: true
}];

export default actionsMap;
