import React from 'react';
import {useIntl} from 'react-intl';
import {
    Divider, List, ListItem, IconButton
} from '@material-ui/core';
import {Close, ArrowBack} from '@material-ui/icons';
import {MdBorderColor as PencilIcon} from '@react-icons/all-files/md/MdBorderColor';
import {PCC_STEPS} from '../../constants';
import {PCCBridge} from '../../pccBridge';
import {AppOutlinedButton} from '../../../../Core/buttons/AppOutlinedButton/AppOutlinedButton';
import {AppButton} from '../../../../Core/buttons/AppButton/AppButton';

const CertificateDetails = ({setStep, setOpen}) => {
    const intl = useIntl();
    const rawCertDetails = PCCBridge?.certificateDetails || {};

    const certDetails = {
        sn: rawCertDetails.sn,
        issuer: rawCertDetails.issuerDN || rawCertDetails.issuer,
        subject: rawCertDetails.subjectDN || rawCertDetails.subject,
        validFrom: rawCertDetails.validFrom,
        validTo: rawCertDetails.validTo,
        publicKey: rawCertDetails.publicKey,
        thumbprint: rawCertDetails.certificateThumbprint,
        keyUsage: rawCertDetails.keyUsage
    };

    const onClose = () => {
        setOpen(false);
        setStep(PCC_STEPS.certificateList);
    };

    const onBack = () => {
        setStep(PCC_STEPS.certificateList);
        PCCBridge.setCertificateDetails({});
    };

    const selectCert = () => {
        setStep(PCC_STEPS.confirmLoading);
        PCCBridge.selectCertificate(rawCertDetails);
    };

    return (
        <>
            <div className="certificate-signing-header">
                <div className="certificate-details-header-left">
                    <IconButton
                        onClick={onBack}
                    >
                        <ArrowBack />
                    </IconButton>
                    <h2>{intl.formatMessage({id: 'oss.pcc.CertificateDetails.title', defaultMessage: ''})}</h2>
                </div>
            </div>
            <div className="content">
                <List>
                    {Object.entries(certDetails).map(([key, value], index) => (
                        <React.Fragment key={key}>
                            <ListItem className="certificate-signing-list-item">
                                <span className="certificate-details-value">
                                    {intl.formatMessage({id: `oss.pcc.CertificateDetails.Field.${key}`, defaultMessage: ''})}
                                    :
                                </span>
                                <span>
                                    {value}
                                </span>
                            </ListItem>
                            {index !== Object.keys(certDetails).length - 1 && (
                                <Divider component="li" />
                            )}
                        </React.Fragment>
                    ))}
                </List>
            </div>
            <div className="actions">
                <AppOutlinedButton
                    onClick={onClose}
                >
                    {intl.formatMessage({id: 'oss.pcc.generic.cancel', defaultMessage: ''})}
                </AppOutlinedButton>

                <AppButton
                    onClick={selectCert}
                    endIcon={<PencilIcon aria-hidden />}
                >
                    {intl.formatMessage({id: 'oss.pcc.generic.sign', defaultMessage: ''})}
                </AppButton>
            </div>
        </>
    );
};
export default CertificateDetails;
