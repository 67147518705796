import {
    PACKAGES_PER_PAGE_OPTIONS,
    PACKAGE_TYPES,
    PACKAGE_SORT_OPTIONS,
    PACKAGE_QUERY_OPTIONS,
    PACKAGE_DIR_OPTIONS,
    VISIBILITY_ACCOUNT,
    VISIBILITY_SENDER,
    PACKAGE_PREDEFINED_OPTIONS
} from '../../constants';

function isQueryValid(query) {
    return query && Object.values(PACKAGE_QUERY_OPTIONS).includes(query);
}

function isTypeValid(type) {
    return type && Object.values(PACKAGE_TYPES).includes(type);
}

function isFromValid(from) {
    return from && !Number.isNaN(from)
        && (from === 1
            || from % PACKAGES_PER_PAGE_OPTIONS[3] === 1
            || from % PACKAGES_PER_PAGE_OPTIONS[5] === 1
            || from % PACKAGES_PER_PAGE_OPTIONS[20] === 1
            || from % PACKAGES_PER_PAGE_OPTIONS[20] === 1);
}

function isToValid(to) {
    return to && !Number.isNaN(to)
        && (to % PACKAGES_PER_PAGE_OPTIONS[3] === 0
            || to % PACKAGES_PER_PAGE_OPTIONS[5] === 0
            || to % PACKAGES_PER_PAGE_OPTIONS[20] === 0
            || to % PACKAGES_PER_PAGE_OPTIONS[20] === 0);
}

function isSortValid(sort) {
    return sort && Object.values(PACKAGE_SORT_OPTIONS).includes(sort);
}

function isDirValid(dir) {
    return dir && Object.values(PACKAGE_DIR_OPTIONS).includes(dir);
}

function isVisibilityValid(visibility) {
    return visibility && (visibility === VISIBILITY_ACCOUNT || visibility === VISIBILITY_SENDER);
}

function isPredefinedValid(predefined) {
    return predefined && Object.values(PACKAGE_PREDEFINED_OPTIONS).includes(predefined);
}

export default function getValidPackagesParams(params = {}, personalPreferences) {
    const validParams = {};

    if (isQueryValid(params.query)) {
        validParams.query = params.query;
    }

    if (isTypeValid(params.type)) {
        validParams.type = params.type;
    }

    if (isFromValid(params.from)) {
        validParams.from = params.from;
    }

    if (isToValid(params.to)) {
        validParams.to = params.to;
    }

    if (isSortValid(params.sort)) {
        validParams.sort = params.sort;
    }

    if (isDirValid(params.dir)) {
        validParams.dir = params.dir;
    }

    if (isVisibilityValid(params.visibility)) {
        validParams.visibility = params.visibility;
    } else if (personalPreferences) {
        validParams.visibility = personalPreferences.sharedTemplates ? VISIBILITY_ACCOUNT : VISIBILITY_SENDER;
    }

    if (isPredefinedValid(params.predefined)) {
        validParams.predefined = params.predefined;
    }

    validParams.lastUpdatedStartDate = params.lastUpdatedStartDate;

    validParams.search = params.search;

    return validParams;
}
