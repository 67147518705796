import {useState, useCallback} from 'react';
import {getScaledSignature} from './utils';
import {options as defaultOptions, defaultSignatureColor} from '../constants';
import {ORIENTATION, useScreenOrientation} from '../../../../../reusable/hooks/useScreenOrientation';

export function useSignatureCanvas() {
    const [currentStroke, setCurrentStroke] = useState([]);
    const [lines, setLines] = useState([]);
    const [options, setOptions] = useState(defaultOptions);
    const [redoStack, setRedoStack] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [color, setColor] = useState(defaultSignatureColor);
    const [tempColor, setTempColor] = useState(color);
    const [tempOptions, settempOptions] = useState(options);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [undoStack, setUndoStack] = useState([]);
    const [isPointerPressed, setIsPointerPressed] = useState(false);
    const isLandscape = useScreenOrientation().orientation === ORIENTATION.LANDSCAPE;

    const adjustCoordinatesForMobile = (x, y) => {
        if (!isLandscape && window.innerWidth <= 780) {
            return [y, window.innerWidth - x];
        }
        return [x, y];
    };

    const handlePointerDown = (event) => {
        const [x, y] = adjustCoordinatesForMobile(event.clientX, event.clientY);
        setIsPointerPressed(true);
        setIsDrawing(true);
        setCurrentStroke([[x, y, event.pressure]]);
    };

    const handlePointerMove = (event) => {
        if (!isDrawing || !isPointerPressed) return;
        const [x, y] = adjustCoordinatesForMobile(event.clientX, event.clientY);
        setCurrentStroke((prev) => [...prev, [x, y, event.pressure]]);
    };

    const handlePointerUp = () => {
        if (currentStroke.length > 0) {
            setLines((prevStrokes) => {
                const newStrokes = [...prevStrokes, currentStroke];
                setUndoStack((prevUndoStack) => [...prevUndoStack, newStrokes]);
                setRedoStack([]);
                return newStrokes;
            });
            setHasUnsavedChanges(true);
        }
        setCurrentStroke([]);
        setIsDrawing(false);
        setIsPointerPressed(false);
    };

    const undo = useCallback(() => {
        setUndoStack((prevUndoStack) => {
            if (prevUndoStack.length > 0) {
                const currentState = prevUndoStack[prevUndoStack.length - 1];
                const newState = prevUndoStack.length > 1 ? prevUndoStack[prevUndoStack.length - 2] : [];
                setRedoStack((prevRedoStack) => [...prevRedoStack, currentState]);
                setLines(newState);
                setHasUnsavedChanges(true);
                return prevUndoStack.slice(0, -1);
            }
            return prevUndoStack;
        });
    }, []);

    const redo = useCallback(() => {
        setRedoStack((prevRedoStack) => {
            if (prevRedoStack.length > 0) {
                const newState = prevRedoStack[prevRedoStack.length - 1];
                setUndoStack((prevUndoStack) => [...prevUndoStack, newState]);
                setLines(newState);
                setHasUnsavedChanges(true);
                return prevRedoStack.slice(0, -1);
            }
            return prevRedoStack;
        });
    }, []);

    const clear = useCallback(() => {
        setUndoStack((prevUndoStack) => [...prevUndoStack, []]);
        setLines([]);
        setRedoStack([]);
        setHasUnsavedChanges(true);
    }, []);

    const animateDrawing = useCallback(() => {
        setIsDrawing(true);
        setIsPointerPressed(false);
        setLines([]);
        let strokeIndex = 0;

        function drawNextStroke() {
            if (strokeIndex >= lines.length) {
                setIsDrawing(false);
                return;
            }

            const newStroke = lines[strokeIndex];
            let i = 0;
            let animatedStroke = [];
            const interval = setInterval(() => {
                if (i < newStroke.length) {
                    animatedStroke = [...animatedStroke, newStroke[i]];
                    setLines((prevStrokes) => {
                        const updatedStrokes = [...prevStrokes];
                        updatedStrokes[strokeIndex] = animatedStroke;
                        return updatedStrokes;
                    });
                    i++;
                } else {
                    clearInterval(interval);
                    strokeIndex++;
                    drawNextStroke();
                }
            }, 5);
        }

        drawNextStroke();
    }, [lines]);

    const handleColorHover = useCallback((hoverColor) => {
        setTempColor(hoverColor);
    }, []);

    const handleColorLeave = useCallback(() => {
        setTempColor(null);
    }, []);

    const handleColorChange = useCallback((newColor) => {
        setColor(newColor);
        setTempColor(newColor);
        setHasUnsavedChanges(true);
    }, []);

    const handlePresetChange = useCallback((newPreset) => {
        setOptions(newPreset);
        settempOptions(newPreset);
        setHasUnsavedChanges(true);
    }, []);

    const saveChanges = useCallback(() => {
        // Implement the logic to save changes to the backend
        setHasUnsavedChanges(false);
    }, []);

    const scaledSignature = getScaledSignature({
        signatureData: {lines, options},
        width: 2000,
        height: 700,
        shouldAddPadding: false
    });

    return {
        currentStroke,
        lines,
        options,
        tempOptions,
        scaledLines: scaledSignature.lines,
        scaledOptions: scaledSignature.options,
        isDrawing,
        color,
        tempColor,
        undoStack,
        redoStack,
        hasUnsavedChanges,
        handlePointerDown,
        handlePointerMove,
        handlePointerUp,
        undo,
        redo,
        clear,
        animateDrawing,
        handleColorHover,
        handleColorLeave,
        handleColorChange,

        handlePresetChange,
        saveChanges
    };
}
