import moment from 'moment';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {selectLocale} from '../components/appSlice';

export default function useDateFormats() {
    const intl = useIntl();
    const locale = useSelector(selectLocale);
    const defaultFormat = 'DD-MM-YYYY';
    const dateFormats = {
        get TIME_DATE_FORMAT() {
            return intl.formatMessage({id: 'shared.format.timeDate', defaultMessage: defaultFormat});
        },
        get SHORTER_DATE_FORMAT() {
            return intl.formatMessage({id: 'shared.format.shorterDate', defaultMessage: defaultFormat});
        },
        get SHORT_DATE_FORMAT() {
            return intl.formatMessage({id: 'shared.format.shortDate', defaultMessage: defaultFormat});
        },
        get LONG_DATE_FORMAT() {
            return intl.formatMessage({id: 'shared.format.longDate', defaultMessage: defaultFormat});
        },
        getFormatErrorMessage() {
            return intl.formatMessage({id: 'esl.error.validation.date.invalidFormat'});
        },
        getTimeDate(date) {
            return moment(date).locale(locale).format(dateFormats.TIME_DATE_FORMAT);
        },
        getShorterDate(date) {
            return moment(date).locale(locale).format(dateFormats.SHORTER_DATE_FORMAT);
        },
        getShortDate(date) {
            return moment(date).locale(locale).format(dateFormats.SHORT_DATE_FORMAT);
        },
        getLongDate(date) {
            return moment(date).locale(locale).format(dateFormats.LONG_DATE_FORMAT);
        }
    };
    return dateFormats;
}
