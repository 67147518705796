import {pdfjs} from 'react-pdf';
import {AUTOMATION_ENV, DEVELOPMENT_ENV} from '../../../../constants';
import {getCurrentSessionId} from '../../../utils/storageSelectors';
import {getIsExampleTransactionID} from '../../SignerUi/utils/functions';
import {
    VISIBLE_PAGES_AMOUNT,
    FOOTER_HEIGHT_PX,
    SCROLL_DIRECTION_DOWN,
    ZOOM_STEP,
    MAX_ZOOM_OUT,
    MAX_ZOOM_IN
} from './constants';

const PAGE_HORIZONTAL_PADDING_PX = 18;
const PAGE_ID_SEPARATOR = '-';
const EXAMPLE_DOCUMENT_URL = '/assets/example.pdf';

export function initPDFJS() {
    pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/pdf.worker.min.js';
}

export const pdfDocumentOptions = {cMapUrl: '/pdfjs/', cMapPacked: true};

export function getPdfFileUrl({
    transactionId, config, document, roleId
}) {
    const isDevelopment = config.VS_ENV === DEVELOPMENT_ENV;
    const isAutomation = config.VS_ENV === AUTOMATION_ENV;
    const {
        id,
        isConfirmed,
        isConfirmedByAll,
        flattenedPdfUrl,
        originalPdfUrl
    } = document;

    // maxAge indicates that the response remains fresh until N seconds after the response is generated
    // we need roleId in params to update cached document for new signer.
    const isExampleTransactionID = getIsExampleTransactionID(transactionId);
    const fileCacheParameters = `utf8=✓&isConfirmed=${isConfirmed}&isConfirmedByAll=${isConfirmedByAll}&maxAge=60&roleId=${roleId}`;
    const packageDocumentFileUrl = `/proxy/packages/${transactionId}/documents/${id}/pdf?flatten=true&${fileCacheParameters}`;
    const prodFileUrl = flattenedPdfUrl || originalPdfUrl;
    const prodFileUrlWithParam = `${prodFileUrl}?${fileCacheParameters}`;

    if (isExampleTransactionID) {
        return EXAMPLE_DOCUMENT_URL;
    }

    if (isDevelopment || isAutomation) {
        return packageDocumentFileUrl;
    }

    return prodFileUrl ? prodFileUrlWithParam : packageDocumentFileUrl;
}

export function getPdfDocumentForRendered({
    transactionId, config, document = {}, roleId
}) {
    return {
        url: getPdfFileUrl({
            document, transactionId, config, roleId
        }),
        httpHeaders: {token: getCurrentSessionId()},
        withCredentials: true
    };
}

export function getDocumentsWithSortedPagesByIndex(documents) {
    if (!documents) return [];
    return documents.map((doc) => {
        const newPages = doc.pages?.map((page) => ({
            ...page,
            documentId: doc.id
        })).sort((a, b) => a.index - b.index);

        return {
            ...doc,
            sortedPages: newPages
        };
    });
}

export function getPageId({documentId, pageIndex}) {
    return `${documentId}${PAGE_ID_SEPARATOR}${pageIndex}`;
}

/**
* @param {string} id
* @returns {string} id without everything after the page id seperator ('-')
*/
export const trimId = (id) => {
    const parts = id.split(PAGE_ID_SEPARATOR);
    return parts.slice(0, -1).join(PAGE_ID_SEPARATOR);
};

export function getDocIdAndPageIndexByPageId({pageId = ''}) {
    const parts = pageId.split(PAGE_ID_SEPARATOR);
    const parsedDocumentId = trimId(pageId);
    const parsedIndex = parseInt(parts[parts.length - 1], 10);

    return {
        documentId: parsedDocumentId,
        pageIndex: !isNaN(parsedIndex) ? parsedIndex : 0
    };
}

export function getPageToContainerScaleFactor({containerWidth, page}) {
    const pageToContainerScaleFactor = containerWidth / page.width;
    return !isNaN(pageToContainerScaleFactor) ? pageToContainerScaleFactor : 1;
}

export function getActivePage({topOffset, pagesWithDimensions}) {
    return pagesWithDimensions
        .find(({topBoundary, bottomBoundary}) => (
            topOffset >= Math.floor(topBoundary) && topOffset < bottomBoundary));
}

export function getActivePageId({topOffset, pagesWithDimensions}) {
    return getActivePage({topOffset, pagesWithDimensions})?.pageId;
}

export function getRenderedPagesIds({
    scrollDirection = SCROLL_DIRECTION_DOWN,
    topOffset = 0,
    pagesWithDimensions = []
}) {
    const {
        BY_SCROLL_FORWARD,
        BY_SCROLL_BACKWARD
    } = VISIBLE_PAGES_AMOUNT;
    const isDownDirection = scrollDirection === SCROLL_DIRECTION_DOWN;
    const activePageId = getActivePageId({topOffset, pagesWithDimensions});
    const activePageIndex = pagesWithDimensions.findIndex(({pageId}) => pageId === activePageId) || 0;
    const topPagesAmount = isDownDirection ? BY_SCROLL_BACKWARD : BY_SCROLL_FORWARD;
    const bottomPagesAmount = isDownDirection ? BY_SCROLL_FORWARD : BY_SCROLL_BACKWARD;
    const visibleStartIndex = activePageIndex - topPagesAmount;
    const visibleEndIndex = activePageIndex + bottomPagesAmount;
    // Ensure that the start and end indices are within the amount of pages.
    const possibleStartIndex = Math.max(visibleStartIndex, 0);
    const possibleEndIndex = Math.min(visibleEndIndex, pagesWithDimensions.length);
    const pages = pagesWithDimensions.slice(possibleStartIndex, possibleEndIndex);

    return pages.map(({pageId}) => pageId);
}

function getPageStickyFooterHorizontalStyles({
    isStickToLeft,
    leftOffset,
    scrollDimensions
}) {
    const {
        container: {
            width: containerWidth
        },
        content: {
            width: contentWidth
        }
    } = scrollDimensions;
    const rightFromLeftOffset = leftOffset + containerWidth;
    const rightOffset = contentWidth - rightFromLeftOffset - PAGE_HORIZONTAL_PADDING_PX;
    // Keep offsets within the page boundaries
    const right = Math.max(0, rightOffset);
    const left = Math.max(0, leftOffset);

    if (isStickToLeft) {
        return {
            left
        };
    }

    return {
        right
    };
}

function getPageStickyFooterVerticalStyles({
    topOffset,
    topBoundary,
    bottomBoundary,
    heightScaledToContainer,
    scrollDimensions
}) {
    const bottomFromTopOffset = topOffset + scrollDimensions?.container?.height;

    if (topBoundary < bottomFromTopOffset && bottomFromTopOffset < bottomBoundary) {
        const footerTopOffset = bottomFromTopOffset - topBoundary - FOOTER_HEIGHT_PX;
        const maximumFooterTopOffset = Math.min(footerTopOffset, heightScaledToContainer);

        return {
            opacity: 0.85,
            top: Math.max(0, maximumFooterTopOffset)
        };
    }
    if (topOffset < bottomBoundary && bottomBoundary < bottomFromTopOffset) {
        return {
            bottom: 0
        };
    }
    return {
        top: 0
    };
}

export function getPageStickyFooterStyles({
    topOffset,
    topBoundary,
    bottomBoundary,
    heightScaledToContainer,
    leftOffset,
    scrollDimensions,
    isStickToLeft
}) {
    const horizontalStyles = getPageStickyFooterHorizontalStyles({
        isStickToLeft,
        leftOffset,
        scrollDimensions
    });
    const verticalStyles = getPageStickyFooterVerticalStyles({
        topOffset,
        topBoundary,
        scrollDimensions,
        bottomBoundary,
        heightScaledToContainer
    });
    const baseStyles = {
        height: FOOTER_HEIGHT_PX,
        lineHeight: `${FOOTER_HEIGHT_PX}px`
    };

    return {
        ...baseStyles,
        ...verticalStyles,
        ...horizontalStyles
    };
}

export function getPageWithDimensionsByPageId({pagesWithDimensions, pageId: id}) {
    const pageWithDimensions = pagesWithDimensions.find(({pageId}) => pageId === id);

    return pageWithDimensions;
}

export function getPageWithDimensions({pagesWithDimensions, documentId, pageIndex}) {
    const pageId = getPageId({
        documentId,
        pageIndex
    });

    return getPageWithDimensionsByPageId({pagesWithDimensions, pageId});
}

export function getPagesWithDimensionsByDocument({pagesWithDimensions, documentId}) {
    return pagesWithDimensions.filter(({documentId: docid}) => documentId === docid);
}

export function getScaleInZoomRange(scale) {
    return Math.min(Math.max(scale, MAX_ZOOM_OUT), MAX_ZOOM_IN);
}

export function roundScaleToNearestZoomStep(scale) {
    const stepsAmount = 1 / (ZOOM_STEP);
    const scaleToNearestZoomStep = Math.round(scale * stepsAmount) / stepsAmount;

    return getScaleInZoomRange(scaleToNearestZoomStep);
}

export function getRenderedPagesInSlidesIds({pagesWithDimensions, pageIndex}) {
    return pagesWithDimensions
        .filter(({index}) => index >= pageIndex - 3 && index <= pageIndex + 3)
        .map(({pageId}) => pageId);
}
