import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import httpClient from '../../../utils/httpClient';
import batchClient from '../../../utils/batchClient';
import {
    BASE_PATH
} from '../../../../constants';
import {getErrorMessage} from '../../../utils/requests/error';

export const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        roles: {},
        isRolesFetching: false,
        rolesFetchingError: null,
        isRoleSaving: false,
        roleSavingError: null
    },
    reducers: {
        setRoles: (state, action) => {
            state.roles = action.payload;
        },
        setIsRolesFetching: (state, action) => {
            state.isRolesFetching = action.payload;
        },
        setRolesFetchingError: (state, action) => {
            state.rolesFetchingError = action.payload;
        },
        setIsRoleSaving: (state, action) => {
            state.isRoleSaving = action.payload;
        },
        setRoleSavingError: (state, action) => {
            state.roleSavingError = action.payload;
        }
    }
});

export const {
    setRoles,
    setIsRolesFetching,
    setRolesFetchingError,
    setIsRoleSaving,
    setRoleSavingError
} = rolesSlice.actions;

export const fetchRoles = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/roles`;
    dispatch(setIsRolesFetching(true));
    dispatch(setRolesFetchingError(null));

    try {
        const result = await httpClient.get(url);
        dispatch(setRoles(get(result, 'data.results', [])));
        dispatch(setIsRolesFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsRolesFetching(false));
        dispatch(setRolesFetchingError(getErrorMessage(err)));
        return false;
    }
};

async function createRole(role) {
    const url = `${BASE_PATH}/proxy/account/roles`;

    return httpClient.post(url, role);
}

async function updateRole(role) {
    const url = `${BASE_PATH}/proxy/account/roles/${role.id}`;

    return httpClient.put(url, role);
}

export const saveRole = (role = {}) => async (dispatch) => {
    dispatch(setIsRoleSaving(true));
    dispatch(setRoleSavingError(null));

    try {
        if (role.id) {
            await updateRole(role);
        } else {
            await createRole(role);
        }

        dispatch(fetchRoles());
        dispatch(setIsRoleSaving(false));
        return true;
    } catch (err) {
        dispatch(setIsRoleSaving(false));
        dispatch(setRoleSavingError(getErrorMessage(err)));
        return false;
    }
};

export const toggleRoles = (rolesIds = [], enabled) => async (dispatch) => {
    const requests = rolesIds.map((id) => ({
        method: 'PUT',
        url: `/account/roles/${id}`,
        data: {enabled}
    }));

    dispatch(setIsRoleSaving(true));
    dispatch(setRoleSavingError(null));

    try {
        await batchClient(requests);

        dispatch(fetchRoles());
        dispatch(setIsRoleSaving(false));
        return true;
    } catch (err) {
        dispatch(setIsRoleSaving(false));
        dispatch(setRoleSavingError(getErrorMessage(err)));
        return false;
    }
};

export const deleteRoles = (rolesIds = []) => async (dispatch) => {
    const requests = rolesIds.map((id) => ({
        method: 'DELETE',
        url: `/account/roles/${id}`
    }));

    dispatch(setIsRoleSaving(true));
    dispatch(setRoleSavingError(null));

    try {
        await batchClient(requests);

        dispatch(fetchRoles());
        dispatch(setIsRoleSaving(false));
        return true;
    } catch (err) {
        dispatch(setIsRoleSaving(false));
        dispatch(setRoleSavingError(getErrorMessage(err)));
        return false;
    }
};

export const selectRoles = (state) => state.roles.roles;
export const selectIsRolesFetching = (state) => state.roles.isRolesFetching;
export const selectRolesFetchingError = (state) => state.roles.rolesFetchingError;
export const selectIsRoleSaving = (state) => state.roles.isRoleSaving;
export const selectRoleSavingError = (state) => state.roles.roleSavingError;

export default rolesSlice.reducer;
