import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {useIntl} from 'react-intl';
import isEmpty from 'lodash.isempty';
import {HiOutlineUpload} from '@react-icons/all-files/hi/HiOutlineUpload';
import {useDispatch, useSelector} from 'react-redux';
import {useApolloClient} from '@apollo/client';
import get from 'lodash.get';
import {HiDotsHorizontal} from '@react-icons/all-files/hi/HiDotsHorizontal';
import NextTransactionToSignModal from '../../NextTransactionToSignModal/NextTransactionToSignModal';
import useNextCeremony from '../../hooks/useNextCeremony';
import {useCeremony} from '../../hooks/queries';
import useOverviewDocuments from '../../hooks/useOverviewDocuments';
import Attachments from '../../Welcome/Attachments/Attachments';
import useTransactionOptions from '../../hooks/useTransactionOptions';
import useSummaryOptions from '../../hooks/useSummaryOptions';
import {allowDownload, getIsSigningCompleted} from '../../utils/selectors';
import getDocIconAndStatus from '../../utils/getDocIconAndStatus';
import Documents from '../../Welcome/Documents/Documents';
import DocumentsLayers from '../../../shared/icons/DocumentsLayers';
import useMarkedMessage from '../../hooks/useMarkedMessage';
import {openAttachment, openDocument} from '../../signerUiSlice';
import {download, getCustomHandOverUrl} from '../../utils/functions';
import {AppButton} from '../../../Core/buttons/AppButton/AppButton';
import {hardRedirectTo, redirectTo} from '../../../../utils/helpers';
import {SectionWithTitle} from '../../../Core/company/layouts/SectionWithTitle/SectionWithTitle';
import useInPerson from '../../hooks/useInPerson';
import {selectUser} from '../../../Login/userSlice';
import IFrameEventNotifications from '../../../../utils/iFrameNotifyer/IFrameEventNotifications';
import {DOCUMENT_NAVIGATION_STARTED_EVENT} from '../../../../utils/iFrameNotifyer/events';
import useThankYouView from '../../hooks/useThankYouView';
import {getValueForKey} from '../../../../utils/storageSelectors';
import {STORAGE_KEY_FROM_SENDER} from '../../../../../constants';
import './transactionSummary.less';

const TransactionSummary = () => {
    const intl = useIntl();
    const client = useApolloClient();
    const {transaction = {}, role} = useCeremony();
    const {transaction: transactionOptions} = useTransactionOptions();
    const {completeSummaryOptions} = useSummaryOptions();
    const {nextCeremony, refetch} = useNextCeremony();
    const {transaction: nextTransaction} = nextCeremony || {};
    const nextTransactionId = nextTransaction?.id;
    const containerClassName = classnames('transaction-summary-page');
    const [isOpenNextTransactionToSignModal, setIsOpenNextTransactionToSignModal] = useState(null);
    const isSigningCompleted = getIsSigningCompleted(transaction?.documents);
    const {inPersonCeremonyHost} = useInPerson();
    const isCeremonyHostOwnerOfNextTransaction = transaction.isInPerson ? inPersonCeremonyHost?.signerId === role?.signerId : true;
    const isFromSenderUI = !!getValueForKey(STORAGE_KEY_FROM_SENDER);
    const haveNextTransactionToSign = nextTransactionId && transaction.id !== nextTransactionId
        && isCeremonyHostOwnerOfNextTransaction
        && isSigningCompleted
        && isFromSenderUI;
    const user = useSelector(selectUser);
    const {
        review: canReview,
        message: showMessage,
        download: showDownload,
        continue: showContinue,
        documentSection,
        uploadSection
    } = completeSummaryOptions;

    const {handOverText, handOverUrl, handOver} = get(transaction, 'settings.ceremony', {});
    const canDownload = allowDownload(transactionOptions);
    const canDownloadDocuments = canDownload && showDownload;
    const showContinueButton = !role.isSender && showContinue && handOverUrl;
    const formatMessage = useMarkedMessage();

    const dispatch = useDispatch();
    const {
        role: {
            fullName: signerFullName,
            isReviewer,
            isOwner,
            attachmentRequirements: attachments = []
        } = {}

    } = useOverviewDocuments();
    const iFrameEventNotifications = IFrameEventNotifications.getInstance();

    async function reviewDocument({id}) {
        await iFrameEventNotifications.postEvent(
            DOCUMENT_NAVIGATION_STARTED_EVENT
        );
        dispatch(openDocument({id}));
    }
    function reviewAttachment({id}) {
        dispatch(openAttachment({id}));
    }
    function redirectToContinueUrl() {
        const urlWithParams = getCustomHandOverUrl({
            handOverUrl,
            status: 'SIGNER_COMPLETE',
            transactionId: transaction.id,
            signerId: role.signerId,
            parameters: handOver.parameters
        });
        redirectTo(urlWithParams);
    }

    const handleDownload = (downloadUrl, documentName) => {
        download({url: downloadUrl, client, fileName: documentName});
    };

    const handleDownloadAll = () => {
        download({url: transaction.zipUrl, client});
    };

    const redirectToDashboard = () => hardRedirectTo('/dashboard');

    useEffect(() => {
        refetch();
    }, [transaction.id]);

    useEffect(() => {
        setIsOpenNextTransactionToSignModal(haveNextTransactionToSign);
    }, [haveNextTransactionToSign]);

    const uploadButton = !uploadSection && canReview && !isEmpty(attachments) && (
        <AppButton onClick={() => reviewAttachment(attachments[0].id)}>
            {intl.formatMessage({id: 'oss.components.SummaryView.uploadReviewAda', defaultMessage: ''})}
        </AppButton>
    );

    const reviewDocumentButton = (
        <AppButton onClick={() => reviewDocument(transaction?.documents[0])}>
            {intl.formatMessage({id: 'oss.components.SummaryView.reviewDocuments', defaultMessage: ''})}
        </AppButton>
    );

    const downloadButton = !documentSection && canDownloadDocuments && (
        <AppButton onClick={handleDownloadAll}>
            {intl.formatMessage({id: 'oss.components.SummaryView.downloadDocuments', defaultMessage: ''})}
        </AppButton>
    );

    const goToDashboardButton = !documentSection && user && isFromSenderUI && (
        <AppButton onClick={redirectToDashboard}>
            {intl.formatMessage({id: 'oss.components.SummaryView.goToDashboard', defaultMessage: ''})}
        </AppButton>
    );

    return (
        <div className={containerClassName} data-wdio="test-page-transaction-summary">
            <SectionWithTitle
                title={intl.formatMessage({id: 'oss.components.InPerson.Decline.Signer.Message.Title', defaultMessage: ''},
                    {fullName: signerFullName})}
                className="message-section"
            >
                {showMessage && (
                    <p
                        className="message"
                        dangerouslySetInnerHTML={{__html: formatMessage({key: isReviewer ? 'oss.components.SummaryView.messageReviewer' : 'oss.components.SummaryView.message'})}}
                    />
                )}
                {(uploadButton || reviewDocumentButton || downloadButton || goToDashboardButton) && (
                    <div className="no-section-actions-container">
                        {uploadButton}
                        {reviewDocumentButton}
                        {downloadButton}
                        {goToDashboardButton}
                    </div>
                )}
            </SectionWithTitle>
            {documentSection && (
                <SectionWithTitle
                    icon={<DocumentsLayers className="icon" aria-hidden />}
                    title={<p dangerouslySetInnerHTML={{__html: formatMessage({key: 'oss.components.SummaryView.reviewDocuments'})}} />}
                    className="documents-section"
                >
                    <Documents
                        onClick={canReview ? reviewDocument : null}
                        documents={transaction.documents}
                        getDocIconAndStatus={getDocIconAndStatus({isReviewer, isOwner})}
                        onDownload={canDownloadDocuments && handleDownload}
                    />
                    {canDownloadDocuments && (
                        <AppButton
                            onClick={handleDownloadAll}
                        >
                            {intl.formatMessage({id: 'oss.components.SummaryView.downloadDocuments', defaultMessage: ''})}
                        </AppButton>
                    )}
                </SectionWithTitle>
            )}
            {uploadSection && !isEmpty(attachments) && (
                <SectionWithTitle
                    icon={<HiOutlineUpload className="icon" aria-hidden />}
                    title={<p dangerouslySetInnerHTML={{__html: formatMessage({key: 'oss.components.SummaryView.uploadSectionTitle'})}} />}
                >
                    <Attachments attachments={attachments} onClick={canReview ? reviewAttachment : null} />
                </SectionWithTitle>
            )}
            {(haveNextTransactionToSign || showContinueButton) && (
                <SectionWithTitle
                    icon={<HiDotsHorizontal className="icon" aria-hidden />}
                    title={<p dangerouslySetInnerHTML={{__html: formatMessage({key: 'oss.components.MoreActions.moreActions'})}} />}
                    className="more-actions-section"
                >
                    {haveNextTransactionToSign && (
                        <>
                            <AppButton
                                className="next-to-sign-btn"
                                onClick={() => setIsOpenNextTransactionToSignModal(true)}
                            >
                                {intl.formatMessage({id: 'oss.components.NextTransactionDialog.title', defaultMessage: ''})}
                            </AppButton>
                            <NextTransactionToSignModal
                                isOpen={isOpenNextTransactionToSignModal}
                                setIsOpen={setIsOpenNextTransactionToSignModal}
                            />
                        </>
                    )}
                    {showContinueButton && (
                        <AppButton
                            onClick={redirectToContinueUrl}
                            className="continue-btn"
                        >
                            <span dangerouslySetInnerHTML={{__html: handOverText || formatMessage({key: 'oss.components.SummaryView.continueText'})}} />
                        </AppButton>
                    )}
                </SectionWithTitle>
            )}
        </div>
    );
};
export default TransactionSummary;
