export const defaultIntroductionInfo = {
    stepIndex: null,
    validation: {}
};

export const CONFIRMATION_MODAL_FOCUS_ELEMENTS = {
    noBtn: 'confirmation-no-btn',
    yesBtn: 'confirmation-yes-btn'
};
export const CONFIRMATION_MODAL_FOCUS_ELEMENTS_IDS = Object.values(CONFIRMATION_MODAL_FOCUS_ELEMENTS);

export const JOYRIDE_POPUP_FOCUS_ELEMENTS = {
    skipBtn: 'joyride-skip',
    backBtn: 'joyride-back',
    nextBtn: 'joyride-next',
    doneBtn: 'joyride-done'
};
export const JOYRIDE_POPUP_FOCUS_ELEMENTS_IDS = Object.values(JOYRIDE_POPUP_FOCUS_ELEMENTS);

export const ADDITIONAL_FOCUS_ELEMENTS = {
    gdprButton: 'gdpr-button'
};
export const ADDITIONAL_FOCUS_ELEMENTS_IDS = Object.values(ADDITIONAL_FOCUS_ELEMENTS);

export const DASHBOARD_FOCUS_ELEMENTS = {
    infoLink: 'infoLink',
    container: 'new-transaction',
    transactionName: 'transaction-name',
    uploadTemplatesId: 'upload-templates',
    sharedSwitchId: 'shared-switch',
    dropzoneId: 'dropzone-documents',
    startFocusFromElem: 'infoLink',
    test: 'MuiInputBase-input',
    startTransactionBtn: 'start-transaction-btn'
};
export const DASHBOARD_FOCUS_ELEMENTS_IDS = Object.values(DASHBOARD_FOCUS_ELEMENTS);

export const TRANSACTIONS_FOCUS_ELEMENTS = {
    firstNameInput: 'firstName',
    firstName: 'firstName',
    recipientInfoContainer: 'recipient-info-container',
    designerBtn: 'designer-btn',
    documents: 'documents',
    recipientSelector: 'recipient-selector-btn',
    deleteRecipientBtn: 'delete-recipient-btn'
};
export const NAVIGATION_TRANSACTION_ELEMENTS_IDS = Object.values(TRANSACTIONS_FOCUS_ELEMENTS);

export const DESIGNER_FOCUS_ELEMENTS = {
    signatureFieldsWrapper: 'signature-fields-wrapper',
    designerTag: 'designer-tag',
    sendToSignBtn: 'send-to-sign-btn'
};
export const DESIGNER_FOCUS_ELEMENTS_IDS = Object.values(DESIGNER_FOCUS_ELEMENTS);

export const PACKAGE_PAGE_FOCUS_ELEMENTS = {
    recipientsContainer: 'recipients-container',
    addRecipientBtn: 'focus-add-recipient-button',
    recipientsSidePanel: 'recipients-settings-side-panel',
    createRecipientBtn: 'create-recipient-btn',
    goToDesignerBtn: 'go-to-designer-btn',
    documentsContainer: 'documents-container',
    documents: TRANSACTIONS_FOCUS_ELEMENTS.documents,
    recipientSelector: 'recipient-selector-btn',
    deleteRecipientBtn: 'delete-recipient-btn',
    packageSettingsContainer: 'package-settings-container',
    packageSettingsNameField: 'package-settings-first-field'
};
