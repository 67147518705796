export default {
    accepted: {
        defaultMessage: 'Accepted',
        id: 'oss.components.FieldsList.SignatureBlock.accepted'
    },
    capsigActivationMessage: {
        defaultMessage: 'Use your pointer to sign within the signature panel.',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_activation_message'
    },
    capsigAdaAnnouncement: {
        defaultMessage: 'Signature has been applied',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_ada_announcement'
    },
    capsigCancelButton: {
        defaultMessage: 'Cancel',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_cancel_button'
    },
    capsigCancelMessage: {
        defaultMessage: 'Are you sure you want to close the signature panel?',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_cancel_message'
    },
    capsigCancelMobileMessage: {
        defaultMessage: 'Are you sure you want to cancel signing from your mobile?',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_cancel_mobile_message'
    },
    capsigCancelMobileTitle: {
        defaultMessage: 'Cancel mobile signing',
        id: 'oss.components.FieldsList.SignatureBLock.capsig_cancel_mobile_title'
    },
    capsigCancelNo: {
        defaultMessage: 'No',
        id: 'oss.generic.no'
    },
    capsigCancelTitle: {
        defaultMessage: 'Confirm',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_cancel_title'
    },
    capsigCancelYes: {
        defaultMessage: 'Yes',
        id: 'oss.generic.yes'
    },
    capsigClear: {
        defaultMessage: 'Clear',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_clear'
    },
    capsigClose: {
        defaultMessage: 'Close',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_close'
    },
    capsigDisableScreenReaderPhone: {
        defaultMessage:
      'To record your signature, you must disable your screen reader and rotate the device to landscape mode. You can re-enable your screen reader after recording your signature.',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_disable_screen_reader_phone'
    },
    capsigDrawSignature: {
        defaultMessage: 'Draw a signature.',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_draw_signature'
    },
    capsigHeader: {
        defaultMessage: 'Capture Signature',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_header'
    },
    capsigMouseInsideSignaturePanel: {
        defaultMessage: 'Your pointer is inside the signature panel.',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_mouse_inside_signature_panel'
    },
    capsigMouseNearOutsideSignaturePanel: {
        defaultMessage: 'Your pointer is nearing the edge of the signature panel.',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_mouse_near_outside_signature_panel'
    },
    capsigMouseOutsideSignaturePanel: {
        defaultMessage: 'Your pointer has exited the signature panel.',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_mouse_outside_signature_panel'
    },
    capsigSendToMobile: {
        defaultMessage: 'Sign with Mobile Device',
        id: 'oss.components.Fields.Field.SignatureField.capsig_send_to_mobile'
    },
    capsigSubmit: {
        defaultMessage: 'Done',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_submit'
    },
    captureMobileConfirm: {
        defaultMessage: 'I Signed on My Mobile',
        id: 'oss.components.Fields.Field.SignatureField.confirm'
    },
    captureMobileText: {
        defaultMessage:
      'You will receive an email with instructions to sign from your mobile device.\nOpen the email on your touch-enabled device and provide your signature.\nPlease do not close this message before signing on your mobile device.',
        id: 'oss.components.Fields.Field.SignatureField.text'
    },
    captureMobileTitle: {
        defaultMessage: 'Capture Instructions',
        id: 'oss.components.Fields.Field.SignatureField.title'
    },
    captureSignature: {
        defaultMessage: 'Sign',
        id: 'oss.components.FieldsList.SignatureBlock.capture_signature'
    },
    captureSignatureOptional: {
        defaultMessage: 'Sign (Optional)',
        id: 'oss.components.FieldsList.SignatureBlock.capture_signature_optional'
    },
    clickToInitial: {
        defaultMessage: 'Initial',
        id: 'oss.components.FieldsList.SignatureBlock.click_to_initial'
    },
    clickToInitialOptional: {
        defaultMessage: 'Initial (Optional)',
        id: 'oss.components.FieldsList.SignatureBlock.click_to_initial_optional'
    },
    clickToSign: {
        defaultMessage: 'Sign',
        id: 'oss.components.FieldsList.SignatureBlock.click_to_sign'
    },
    clickToSignOptional: {
        defaultMessage: 'Sign (Optional)',
        id: 'oss.components.FieldsList.SignatureBlock.click_to_sign_optional'
    },
    dateField: {
        defaultMessage: 'Signing Date',
        id: 'oss.components.FieldsList.SignatureBlock.autofill_date'
    },
    initialedBy: {
        defaultMessage: 'Initialed by',
        id: 'oss.components.FieldsList.SignatureBlock.initialedBy'
    },
    mobileCapture: {
        defaultMessage: 'Sign',
        id: 'oss.components.FieldsList.SignatureBlock.mobile_capture'
    },
    mobileCaptureOptional: {
        defaultMessage: 'Sign (Optional)',
        id: 'oss.components.FieldsList.SignatureBlock.mobile_capture_optional'
    },
    signatureOptional: {
        defaultMessage: 'Optional',
        id: 'oss.components.FieldsList.SignatureBlock.signature_optional'
    },
    signedBy: {
        defaultMessage: 'Signed by',
        id: 'oss.components.FieldsList.SignatureBlock.signedBy'
    },
    signerCompany: {
        defaultMessage: 'Company',
        id: 'oss.components.FieldsList.SignatureBlock.autofill_signer_company'
    },
    signerName: {
        defaultMessage: 'Name',
        id: 'oss.components.FieldsList.SignatureBlock.autofill_signer_name'
    },
    signerTitle: {
        defaultMessage: 'Title',
        id: 'oss.components.FieldsList.SignatureBlock.autofill_signer_title'
    },
    undo: {
        defaultMessage: 'Undo Signature',
        id: 'oss.components.Fields.Field.SignatureField.undo'
    },
    undoSignatureAriaLabel: {
        defaultMessage: 'Undo {signatureType}.',
        id: 'oss.components.Fields.Field.SignatureField.undoSignatureAriaLabel'
    },
    uploadImage: {
        defaultMessage: 'Upload image',
        id: 'oss.components.FieldsList.SignatureBlock.capsig_upload_image'
    },
    validationError: {
        defaultMessage: 'Validation Error',
        id: 'esl.http.status.400'
    },
    wrongFromFileData: {
        defaultMessage:
      'Only jpg and png files are accepted. Maximum size is 1 MB.',
        id: 'esl.error.validation.approval.wrongFromFileData'
    }
};
