import React, {useState} from 'react';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import {useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import useInPerson from '../../hooks/useInPerson';
import {useCeremony} from '../../hooks/queries';
import {getAvatarByEmail} from '../../../../utils/helpers';
import {selectAvatars} from '../../../Packages/Recipients/recipientsSlice';
import './inPersonSessionInfo.less';

const InPersonSessionInformation = () => {
    const {isInPersonCeremonyControlledByHost} = useInPerson();
    const {role = {}} = useCeremony();
    const avatars = useSelector(selectAvatars);
    const {formatMessage} = useIntl();
    const containerClassName = classnames('in-person-session-information');
    const [anchorEl, setAnchorEl] = useState(null);

    const openPopover = ({currentTarget} = {}) => {
        setAnchorEl(currentTarget);
    };

    const cloesePopover = () => {
        setAnchorEl(null);
    };

    if (!isInPersonCeremonyControlledByHost) return null;

    const label = (
        <div className="label">
            {`${formatMessage({id: 'sui.shared.session', defaultMessage: ''})}: `}
            {role.fullName}
        </div>
    );

    return (
        <div className={containerClassName}>
            <Avatar
                classes={{root: 'avatar'}}
                src={getAvatarByEmail(avatars, role.email)}
                alt={`${role.fullName}`}
                onClick={openPopover}
            >
                {`${role.fullName?.substr(0, 1)}`}
            </Avatar>
            {label}
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={cloesePopover}
                classes={{paper: 'in-person-session-information-popover'}}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                {label}
            </Popover>
        </div>

    );
};
export default InPersonSessionInformation;
