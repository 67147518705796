import React, {forwardRef} from 'react';
import {Button} from '../../../atoms/Button';
import './underlinedButton.less';

export const UnderlinedButton = forwardRef(({
    children,
    className = '',
    ...rest
}, ref) => (
    <Button
        ref={ref}
        className={`underlined-button ${className}`}
        {...rest}
    >
        {children}
    </Button>
));
