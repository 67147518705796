import {AUTH_TYPES, REASSIGN_SCHEME_TYPES} from '../../../../../../constants';

export default function getSignerAuthByType({type, maskResponse}) {
    const defaultQnAChallenge = {
        answer: '',
        question: '',
        maskInput: maskResponse
    };
    const defaultQnAChallenges = [defaultQnAChallenge, defaultQnAChallenge];
    const defaultSMSChallenges = [{question: ''}];
    const defaultSSOChallenges = [];

    switch (type) {
        case REASSIGN_SCHEME_TYPES.QNA:
            return {scheme: type, challenges: defaultQnAChallenges};
        case REASSIGN_SCHEME_TYPES.SMS:
            return {scheme: type, challenges: defaultSMSChallenges};
        case AUTH_TYPES.SSO:
            return {scheme: type, challenges: defaultSSOChallenges};
        default:
            return {scheme: type};
    }
}
