/* eslint-disable no-nested-ternary */
import {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import classnames from 'classnames';
import {Alert} from '@material-ui/lab';
import {PerfectSignatureModal} from '@components/Core/components/PerfectSignatureModal/PerfectSignatureModal';
import {exportImage, getChecksumFromBase64} from '@components/Core/components/PerfectSignature/hooks/utils';
import {
    clearCachedSignatureData, setCachedHanddrawnSignature, setCachedImageSignature, setCachedSignatureData
} from '@components/SignerUi/signerUiSlice';
import {useDispatch} from 'react-redux';
import GraphicalSignature from '@components/Core/components/GraphicalSignature/GraphicalSignature';
import {useSignatureCanvas} from '@components/Core/components/PerfectSignature/hooks/useSignatureCanvas';
import {AppOutlinedButton} from '@components/Core/buttons/AppOutlinedButton/AppOutlinedButton';
import {MAX_SIZE_1MB_BYTES} from '../../../../../../../../constants';
import useSignatureImage from '../../../../../hooks/useSignatureImage';
import {
    configurePayloadToSignFromBase64String,
    formatBytesToMb,
    readFile
} from '../../../../../../AccountSettings/CaptureSignature/helpers';
import useJoinMessage from '../../../../../../../hooks/useJoinMessage';
import Signature from '../../../../../../shared/icons/Signature';
import Image from '../../../../../../common/Image/Image';
import ImageThumbnail from '../../../../../../common/ImageThumbnail/ImageThumbnail';
import {Dialog, DialogButton} from '../../../../../../Core/company/modals/Dialog';
import {AppButton} from '../../../../../../Core/buttons/AppButton/AppButton';
import './signatureSelectionModalV2.less';

const acceptedFilesStr = 'image/jpeg,image/png,image/jpg';

export const SignatureSelectionModalV2 = ({
    isOpen,
    setIsOpen,
    signUsingImage,
    signUsingSignature,
    defaultHanddrawnSignature
}) => {
    const svgRef = useRef(null);
    const exportSvgRef = useRef(null);
    const intl = useIntl();
    const dispatch = useDispatch();
    const {signatureImage: defaultSignatureImage} = useSignatureImage({skip: false});
    const [signatureImage, setSignatureImage] = useState({
        type: '',
        name: '',
        base64String: ''
    });
    const perfectSignatureProps = useSignatureCanvas();

    const pseudoCanvasEl = useRef(null);
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const containerClassName = classnames('signature-selection-modal-v2');
    const imageError = signatureImage.size > MAX_SIZE_1MB_BYTES;

    const inputRef = useRef(null);
    const joinMessage = useJoinMessage();

    async function uploadNewImage({target}) {
        const sourceFile = target.files[0];

        const base64String = await readFile(sourceFile);
        if (base64String) {
            setSignatureImage({
                type: sourceFile.type,
                name: sourceFile.name,
                size: sourceFile.size,
                base64String,
                isFileUpload: true
            });
        }
    }

    async function handleSign() {
        // Clear signature data (freehand lines, options e.t.c.) if it was a file upload
        if (signatureImage.isFileUpload) {
            dispatch(clearCachedSignatureData());
        }

        if (signatureImage.base64String) {
            signUsingImage(configurePayloadToSignFromBase64String(signatureImage.base64String), signatureImage.isFileUpload);
            dispatch(setCachedImageSignature(configurePayloadToSignFromBase64String(signatureImage.base64String)));
            return setIsOpen(false);
        }

        if (defaultHanddrawnSignature) {
            signUsingSignature(defaultHanddrawnSignature);
            dispatch(setCachedImageSignature(null));
            dispatch(clearCachedSignatureData());
            dispatch(setCachedHanddrawnSignature(defaultHanddrawnSignature));
            return setIsOpen(false);
        }
    }

    async function onSignUsingPerfectSignature() {
        const {base64String, signatureData} = await exportImage({svgRef: exportSvgRef, perfectSignatureProps});
        const imageBase64 = configurePayloadToSignFromBase64String(base64String);
        const checksum = getChecksumFromBase64(imageBase64.value);
        setIsSignatureModalOpen(false);
        setIsOpen(false);
        setSignatureImage({
            type: 'png',
            name: 'drawing-signature',
            size: null,
            base64String
        });
        signUsingImage(imageBase64);
        dispatch(setCachedSignatureData({
            ...signatureData,
            checksum
        }));
        dispatch(setCachedImageSignature(configurePayloadToSignFromBase64String(base64String)));
    }

    function openFileSystemToUploadImage() {
        inputRef.current.click();
    }

    useEffect(() => {
        if (defaultSignatureImage) {
            setSignatureImage({
                type: defaultSignatureImage.type,
                name: defaultSignatureImage.name,
                base64String: `${defaultSignatureImage.type},${defaultSignatureImage.value}`
            });
        }
    }, [defaultSignatureImage]);

    return (
        <Dialog
            open={isOpen}
            className={containerClassName}
            data-wdio="test-signature-selection-modal-v2"
            content={(
                <>
                    <h2 className="title">
                        {joinMessage('sui.shared.signature')}
                    </h2>
                    <div className="content">
                        <input
                            ref={inputRef}
                            type="file"
                            hidden
                            accept={acceptedFilesStr}
                            onChange={uploadNewImage}
                            data-wdio="test-signature-image-input"
                        />
                        <div className="drawed-signature-container">
                            {signatureImage.base64String ? (
                                <div className="image-container">
                                    <Image
                                        src={signatureImage.base64String}
                                    />
                                </div>
                            ) : (defaultHanddrawnSignature ? (
                                <GraphicalSignature
                                    signature={defaultHanddrawnSignature}
                                    pseudoCanvasEl={pseudoCanvasEl}
                                    disabledToDraw
                                />
                            ) : (
                                <ImageThumbnail
                                    icon={<Signature aria-hidden className="icon" />}
                                    onClick={() => setIsSignatureModalOpen(true)}
                                />
                            )
                            )}
                            {imageError && (
                                <Alert
                                    severity="info"
                                    variant="outlined"
                                    classes={{root: 'info-alert'}}
                                >
                                    {intl.formatMessage(
                                        {id: 'sui.shared.bigFileSize', defaultMessage: ''},
                                        {
                                            size: formatBytesToMb(signatureImage.size, 0),
                                            maxSize: formatBytesToMb(MAX_SIZE_1MB_BYTES, 0)
                                        }
                                    )}
                                </Alert>
                            )}
                        </div>

                    </div>
                    <PerfectSignatureModal
                        svgRef={svgRef}
                        exportSvgRef={exportSvgRef}
                        isOpen={isSignatureModalOpen}
                        close={() => { setIsSignatureModalOpen(false); }}
                        perfectSignatureProps={perfectSignatureProps}
                        actions={
                            (
                                <>

                                    <DialogButton
                                        onClick={() => { setIsSignatureModalOpen(false); }}
                                    >
                                        {intl.formatMessage({id: 'esl.generic.close', defaultMessage: ''})}
                                    </DialogButton>
                                    <AppButton onClick={onSignUsingPerfectSignature} disabled={!perfectSignatureProps.lines?.length}>
                                        {intl.formatMessage({id: 'esl.generic.sign', defaultMessage: ''})}
                                    </AppButton>
                                </>
                            )
                        }
                    />
                </>
            )}
            actions={(
                <>
                    <div className="middle-actions-container">
                        <AppOutlinedButton
                            onClick={() => setIsSignatureModalOpen(true)}
                        >
                            {joinMessage('sui.signature.drawNew')}
                        </AppOutlinedButton>
                        <AppOutlinedButton
                            onClick={openFileSystemToUploadImage}
                            className="upload-new-button"
                        >
                            {joinMessage('sui.shared.upload')}
                        </AppOutlinedButton>
                    </div>
                    <div className="main-actions-wrapper">
                        <DialogButton
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            {intl.formatMessage({id: 'oss.components.FieldsList.SignatureBlock.capsig_cancel_button', defaultMessage: ''})}
                        </DialogButton>
                        <AppButton
                            onClick={() => handleSign()}
                        >
                            {intl.formatMessage({id: 'oss.components.FieldsList.SignatureBlock.capture_signature', defaultMessage: ''})}
                        </AppButton>
                    </div>
                </>
            )}
        />
    );
};
