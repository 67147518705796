/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classnames from 'classnames';
import './bullet.less';

export default function Bullet({step, isActive, onChange}) {
    const className = classnames('bullet', {
        'is-active': isActive
    });

    function onClick() {
        onChange(step);
    }

    return (
        <div className={className} onClick={onClick} />
    );
}
