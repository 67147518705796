import classNames from 'classnames';
import {LuAsterisk} from 'react-icons/lu';

const getFieldIconStyles = ({width, height}) => {
    const iconSize = 10;
    const adjustedRight = width < iconSize ? -iconSize : -iconSize / 2;
    const adjustedTop = height < iconSize ? -iconSize : -iconSize / 2;

    return {
        top: adjustedTop,
        right: adjustedRight,
        width: iconSize,
        height: iconSize,
        fontSize: iconSize
    };
};

export const RequiredIcon = ({field, position}) => {
    const containerClasssName = classNames('required-icon');
    const styles = getFieldIconStyles(position);
    if (!field.isInput || !field.isRequired) return null;
    return (
        <LuAsterisk
            className={containerClasssName}
            style={styles}
        />
    );
};
