/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import httpClient from '../../../utils/httpClient';
import {
    BASE_PATH, FETCH_OPTIONS_STEP, PACKAGE_TYPE_NAME
} from '../../../../constants';
import {fetchAvatars} from '../../../utils/requests/recipients';
import {getErrorMessage} from '../../../utils/requests/error';

const getPackageStatePath = (packageType) => {
    if (packageType === PACKAGE_TYPE_NAME.TEMPLATE) return 'template.template';
    if (packageType === PACKAGE_TYPE_NAME.TRANSACTION) return 'transaction.transaction';
};

export const recipientsSlice = createSlice({
    name: 'recipients',
    initialState: {
        suggestions: [],
        updatedSuggestions: {
            name: {
                results: [],
                count: null,
                search: ''
            }
        },
        groups: [],
        verificationTypes: [],
        verifications: {},
        isFetchingSuggestions: false,
        fetchSuggestionsError: null,
        isUpdatingRoles: false,
        updateRolesError: null,
        isUpdatingFlow: false,
        updateFlowError: null,
        isUpdatingRole: false,
        updateRoleError: null,
        isFetchingGroups: false,
        fetchGroupsError: null,
        isDeletingRole: false,
        deleteRoleError: null,
        isVerificationTypesFetching: false,
        verificationTypesFetchError: null,
        isUpdatingSettings: false,
        sVerificationTypeFetching: false,
        updateSettingsError: null,
        verificationTypeFetchError: null,
        isVerificationTypeSaving: false,
        verificationTypeSaveError: null,
        avatars: []
    },
    reducers: {
        setSuggestions: (state, action) => {
            state.suggestions = action.payload;
        },
        setUpdatedSuggestions: (state, {payload}) => {
            state.updatedSuggestions = {
                ...state.updatedSuggestions,
                [payload.name]: {
                    results: get(payload, 'data.results', []),
                    count: get(payload, 'data.count', []),
                    search: get(payload, 'search', '')
                }
            };
        },
        setIsFetchingSuggestions: (state, action) => {
            state.isFetchingSuggestions = action.payload;
        },
        setFetchSuggestionsError: (state, action) => {
            state.fetchSuggestionsError = action.payload;
        },
        setIsUpdatingRoles: (state, action) => {
            state.isUpdatingRole = action.payload;
        },
        setUpdateRolesError: (state, action) => {
            state.updateRoleError = action.payload;
        },
        setIsUpdatingFlow: (state, action) => {
            state.isUpdatingFlow = action.payload;
        },
        setUpdateFlowError: (state, action) => {
            state.updateFlowError = action.payload;
        },
        setIsUpdatingRole: (state, action) => {
            state.isUpdatingRole = action.payload;
        },
        setUpdateRoleError: (state, action) => {
            state.updateRoleError = action.payload;
        },
        setGroups: (state, action) => {
            state.groups = action.payload;
        },
        setIsFetchingGroups: (state, action) => {
            state.isFetchingGroups = action.payload;
        },
        setFetchGroupsError: (state, action) => {
            state.fetchGroupsError = action.payload;
        },
        setIsDeletingRole: (state, action) => {
            state.isDeletingRole = action.payload;
        },
        setDeleteRoleError: (state, action) => {
            state.deleteRoleError = action.payload;
        },
        setVerificationTypes: (state, action) => {
            state.verificationTypes = action.payload;
        },
        setVerifications: (state, action) => {
            const {
                packageId,
                roleId,
                verification
            } = action.payload;
            const pacakgeVerifications = get(state, `verifications[${packageId}]`, {});

            state.verifications = {
                ...state.verifications,
                [packageId]: {
                    ...pacakgeVerifications,
                    [roleId]: {
                        ...verification
                    }
                }
            };
        },
        setIsVerificationTypesFetching: (state, action) => {
            state.isVerificationTypesFetching = action.payload;
        },
        setVerificationTypesFetchError: (state, action) => {
            state.verificationTypesFetchError = action.payload;
        },
        setIsUpdatingSettings: (state, action) => {
            state.isUpdatingSettings = action.payload;
        },
        setUpdateSettingsError: (state, action) => {
            state.updateSettingsError = action.payload;
        },
        setIsVerificationTypeFetching: (state, action) => {
            state.isVerificationTypeFetching = action.payload;
        },
        setVerificationTypeFetchError: (state, action) => {
            state.verificationTypeFetchError = action.payload;
        },
        setIsVerificationTypeSaving: (state, action) => {
            state.isVerificationTypeSaving = action.payload;
        },
        setVerificationTypeSaveError: (state, action) => {
            state.verificationTypeSaveError = action.payload;
        },
        setAvatars: (state, action) => {
            state.avatars = [
                ...state.avatars,
                ...action.payload
            ];
        }
    }
});

export const {
    setSuggestions,
    setUpdatedSuggestions,
    setGroups,
    setIsFetchingSuggestions,
    setFetchSuggestionsError,
    setIsUpdatingRoles,
    setUpdateRolesError,
    setIsUpdatingFlow,
    setUpdateFlowError,
    setIsUpdatingRole,
    setUpdateRoleError,
    setIsFetchingGroups,
    setFetchGroupsError,
    setIsDeletingRole,
    setDeleteRoleError,
    setVerificationTypes,
    setVerifications,
    setIsVerificationTypesFetching,
    setVerificationTypesFetchError,
    setIsUpdatingSettings,
    setUpdateSettingsError,
    setIsVerificationTypeFetching,
    setVerificationTypeFetchError,
    setIsVerificationTypeSaving,
    setVerificationTypeSaveError,
    setAvatars
} = recipientsSlice.actions;

export const resetRecipientErrors = () => async (dispatch) => {
    dispatch(setFetchSuggestionsError(null));
    dispatch(setUpdateRolesError(null));
    dispatch(setUpdateFlowError(null));
    dispatch(setUpdateRoleError(null));
    dispatch(setFetchGroupsError(null));
    dispatch(setDeleteRoleError(null));
    dispatch(setVerificationTypesFetchError(null));
    dispatch(setUpdateSettingsError(null));
    dispatch(setVerificationTypeFetchError(null));
    dispatch(setVerificationTypeSaveError(null));
};

export const fetchSuggestions = (
    search = ''
) => async (dispatch) => {
    dispatch(setSuggestions([]));
    dispatch(setIsFetchingSuggestions(true));
    dispatch(setFetchSuggestionsError(null));

    try {
        const result = await httpClient.get(`${BASE_PATH}/proxy/users?from=1&to=11&search=${search}`);
        dispatch(setSuggestions(get(result, 'data.results', [])));
        dispatch(setIsFetchingSuggestions(false));
        return true;
    } catch (err) {
        dispatch(setFetchSuggestionsError(getErrorMessage(err)));
        dispatch(setIsFetchingSuggestions(false));
        return false;
    }
};

export const fetchUpdatedSuggestions = ({name, params}) => async (dispatch) => {
    const {
        from = 0,
        to = FETCH_OPTIONS_STEP,
        search = ''
    } = params;
    dispatch(setIsFetchingSuggestions(true));
    dispatch(setFetchSuggestionsError(null));

    try {
        const result = await httpClient.get(`${BASE_PATH}/proxy/users?from=${from}&to=${to}&search=${search}`);
        // RESEARCH: when trying to get 20 items API return count equal to 22 but only 16 items in result
        dispatch(setUpdatedSuggestions({
            data: result.data,
            search,
            name
        }));
        dispatch(setIsFetchingSuggestions(false));
        return true;
    } catch (err) {
        const messageKey = `esl.${get(err, 'response.data.messageKey', 'error_500.oops_broken')}`;
        dispatch(setFetchSuggestionsError(messageKey));
        dispatch(setIsFetchingSuggestions(false));
        return false;
    }
};

export const updateRoles = ({
    roles, isSigningFlow, packageType, setRoles
}) => async (dispatch, getState) => {
    const packageData = get(getState(), getPackageStatePath(packageType));

    if (isSigningFlow) {
        dispatch(setIsUpdatingFlow(true));
        dispatch(setUpdateFlowError(null));
    } else {
        dispatch(setIsUpdatingRoles(true));
        dispatch(setUpdateRolesError(null));
    }

    try {
        const result = await httpClient.put(`${BASE_PATH}/proxy/packages/${packageData.id}/roles`, roles);
        await dispatch(setRoles(get(result, 'data.results')));
        if (isSigningFlow) {
            dispatch(setIsUpdatingFlow(false));
        } else {
            dispatch(setIsUpdatingRoles(false));
        }
        return true;
    } catch (err) {
        if (isSigningFlow) {
            dispatch(setIsUpdatingFlow(false));
            dispatch(setUpdateFlowError(getErrorMessage(err)));
        } else {
            dispatch(setIsUpdatingRoles(false));
            dispatch(setUpdateRolesError(getErrorMessage(err)));
        }
        return false;
    }
};
export const updateSettings = ({
    role, packageType
}) => async (dispatch, getState) => {
    const packageData = get(getState(), getPackageStatePath(packageType));

    const url = `${BASE_PATH}/proxy/packages/${packageData.id}/roles`;
    dispatch(setIsUpdatingSettings(true));
    dispatch(setUpdateSettingsError(null));

    try {
        const result = role.id
            ? await httpClient.put(`${url}/${role.id}`, role) : await httpClient.post(`${url}`, role);
        dispatch(setIsUpdatingSettings(false));
        return result.data;
    } catch (err) {
        dispatch(setUpdateSettingsError(getErrorMessage(err)));
        dispatch(setIsUpdatingSettings(false));
        return false;
    }
};
export const updateRole = ({
    role, packageType
}) => async (dispatch, getState) => {
    const packageData = get(getState(), getPackageStatePath(packageType));
    const url = `${BASE_PATH}/proxy/packages/${packageData.id}/roles`;
    dispatch(setIsUpdatingRole(true));
    dispatch(setUpdateRoleError(null));

    try {
        const result = role.id
            ? await httpClient.put(`${url}/${role.id}`, role) : await httpClient.post(`${url}`, role);

        dispatch(setIsUpdatingRole(false));
        return result.data;
    } catch (err) {
        dispatch(setUpdateRoleError(getErrorMessage(err)));
        dispatch(setIsUpdatingRole(false));
        return false;
    }
};

export const deleteRole = ({
    roleId, packageType
}) => async (dispatch, getState) => {
    const packageData = get(getState(), getPackageStatePath(packageType));

    dispatch(setIsDeletingRole(true));
    dispatch(setDeleteRoleError(null));

    try {
        await httpClient.delete(`${BASE_PATH}/proxy/packages/${packageData.id}/roles/${roleId}`);
        dispatch(setIsDeletingRole(false));

        return true;
    } catch (err) {
        dispatch(setIsDeletingRole(false));
        dispatch(setUpdateRoleError(getErrorMessage(err)));

        return false;
    }
};

export const fetchGroupsSummary = ({
    headers, baseUrl
} = {}) => async (dispatch) => {
    const servicePath = 'groups?sort=name&dir=asc&from=1&to=999';
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {};
    if (headers) {
        options.headers = headers;
    }
    dispatch(setFetchGroupsError(null));
    dispatch(setIsFetchingGroups(true));

    try {
        const result = await httpClient.get(url, options);

        dispatch(setGroups(get(result, 'data.results', [])));
        dispatch(setIsFetchingGroups(false));

        return true;
    } catch (err) {
        dispatch(setFetchGroupsError(getErrorMessage(err)));
        dispatch(setIsFetchingGroups(false));
        return false;
    }
};

export const fetchVerificationTypes = ({
    headers, baseUrl
} = {}) => async (dispatch, getState) => {
    const {user} = getState();
    const servicePath = `account/${user.account.id}/verificationTypes`;
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {};
    if (headers) {
        options.headers = headers;
    }

    dispatch(setIsVerificationTypesFetching(true));
    dispatch(setVerificationTypesFetchError(null));
    try {
        const result = await httpClient.get(url, options);

        dispatch(setVerificationTypes(result.data.results));
        dispatch(setIsVerificationTypesFetching(false));
        dispatch(setVerificationTypesFetchError(null));
        return true;
    } catch (err) {
        dispatch(setIsVerificationTypesFetching(false));
        dispatch(setVerificationTypesFetchError(getErrorMessage(err)));
        return false;
    }
};

export const fetchRecipientVerification = ({
    roleId, packageData
} = {}) => async (dispatch, getState) => {
    const servicePath = `packages/${packageData.id}/roles/${roleId}/verification`;
    const url = `${BASE_PATH}/proxy/${servicePath}`;

    dispatch(setIsVerificationTypeFetching(true));
    dispatch(setVerificationTypeFetchError(null));
    try {
        const result = await httpClient.get(url);

        dispatch(setVerifications({
            packageId: packageData.id,
            roleId,
            verification: get(result, 'data', {})
        }));
        dispatch(setIsVerificationTypeFetching(false));
        return get(result, 'data', {});
    } catch (err) {
        dispatch(setIsVerificationTypeFetching(false));
        dispatch(setVerificationTypeFetchError(getErrorMessage(err)));
        return false;
    }
};

export const createVerificationType = ({
    roleId, typeId, packageType
} = {}) => async (dispatch, getState) => {
    const packageData = get(getState(), getPackageStatePath(packageType));
    const servicePath = `packages/${packageData.id}/roles/${roleId}/verification`;
    const url = `${BASE_PATH}/proxy/${servicePath}`;

    dispatch(setIsVerificationTypeSaving(true));
    dispatch(setVerificationTypeSaveError(null));
    try {
        await httpClient.post(url, {
            typeId
        });
        dispatch(setIsVerificationTypeSaving(false));
        dispatch(fetchRecipientVerification({roleId, packageData}));
        return true;
    } catch (err) {
        dispatch(setIsVerificationTypeSaving(false));
        dispatch(setVerificationTypeSaveError(getErrorMessage(err)));
        return false;
    }
};

export const updateVerificationType = ({
    roleId, typeId, packageType
} = {}) => async (dispatch, getState) => {
    const packageData = get(getState(), getPackageStatePath(packageType));
    const servicePath = `packages/${packageData.id}/roles/${roleId}/verification`;
    const url = `${BASE_PATH}/proxy/${servicePath}`;

    dispatch(setIsVerificationTypeSaving(true));
    dispatch(setVerificationTypeSaveError(null));
    try {
        await httpClient.put(url, {
            typeId
        });
        dispatch(setIsVerificationTypeSaving(false));
        dispatch(fetchRecipientVerification({roleId, packageData}));
        return true;
    } catch (err) {
        dispatch(setIsVerificationTypeSaving(false));
        dispatch(setVerificationTypeSaveError(getErrorMessage(err)));
        return false;
    }
};

export const deleteVerificationType = ({
    roleId, packageType
} = {}) => async (dispatch, getState) => {
    const packageData = get(getState(), getPackageStatePath(packageType));
    const servicePath = `packages/${packageData.id}/roles/${roleId}/verification`;
    const url = `${BASE_PATH}/proxy/${servicePath}`;

    dispatch(setIsVerificationTypeSaving(true));
    dispatch(setVerificationTypeSaveError(null));
    try {
        await httpClient.delete(url);
        dispatch(setIsVerificationTypeSaving(false));
        dispatch(fetchRecipientVerification({roleId, packageData}));
        return true;
    } catch (err) {
        dispatch(setIsVerificationTypeSaving(false));
        dispatch(setVerificationTypeSaveError(getErrorMessage(err)));
        return false;
    }
};

export const fetchAvatarsByEmails = ({
    baseUrl, headers, emails = []
} = {}) => async (dispatch, getState) => {
    const options = {};
    const {avatars} = getState().recipients;
    const newEmails = emails
        .filter((email) => !!email && !avatars.some((avatar) => avatar.email === email));

    if (isEmpty(newEmails)) {
        return false;
    }

    if (headers) {
        options.headers = headers;
    }

    try {
        const result = baseUrl
            ? await fetchAvatars(`${baseUrl}`, newEmails, options)
            : await httpClient.post(`${BASE_PATH}/fetchAvatars`, newEmails, options);

        dispatch(setAvatars(baseUrl ? result : result.data));
        return true;
    } catch (err) {
        return false;
    }
};

export const selectSuggestions = (state) => state.recipients.suggestions;
export const selectUpdatedSuggestions = (state) => state.recipients.updatedSuggestions;
export const selectIsFetchingSuggestions = (state) => state.recipients.isFetchingSuggestions;
export const selectFetchSuggestionsError = (state) => state.recipients.fetchSuggestionsError;

export const selectIsUpdatingRoles = (state) => state.recipients.isUpdatingRoles;
export const selectUpdateRolesError = (state) => state.recipients.updateRolesError;

export const selectsUpdatingFlow = (state) => state.recipients.isUpdatingFlow;
export const selectupdateFlowError = (state) => state.recipients.updateFlowError;

export const selectIsUpdatingRole = (state) => state.recipients.isUpdatingRole;
export const selectUpdateRoleError = (state) => state.recipients.updateRoleError;

export const selectIsDeletingRole = (state) => state.recipients.isDeletingRole;
export const selectDeleteRoleError = (state) => state.recipients.deleteRoleError;

export const selectGroupsSummary = (state) => state.recipients.groups;
export const selectIsFetchingGroups = (state) => state.recipients.isFetchingGroups;
export const selectFetchGroupsError = (state) => state.recipients.fetchGroupsError;

export const selectVerificationTypes = (state) => state.recipients.verificationTypes;
export const selectVerifications = (state) => state.recipients.verifications;
export const selectIsVerificationTypesFetching = (state) => state.recipients.isVerificationTypesFetching;
export const selectVerificationTypesFetchError = (state) => state.recipients.verificationTypesFetchError;

export const selectIsVerificationTypeFetching = (state) => state.recipients.isVerificationTypeFetching;
export const selectVerificationTypeFetchError = (state) => state.recipients.verificationTypeFetchError;

export const selectIsVerificationTypeSaving = (state) => state.recipients.isVerificationTypeSaving;
export const selectVerificationTypeSaveError = (state) => state.recipients.verificationTypeSaveError;

export const selectIsUpdatingSettings = (state) => state.recipients.isUpdatingSettings;
export const selectUpdateSettingsError = (state) => state.recipients.updateSettingsError;
export const selectAvatars = (state) => state.recipients.avatars;

export default recipientsSlice.reducer;
