import React from 'react';

function Security() {
    return (
        <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            className="icon"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill="none"
                stroke="#000"
                strokeWidth="1.5"
                d="M12,22 C12,22 2.99999999,18 3,11 L3,5 L12,2 L21,5 C21,5 21,11 21,11 C21,18 12,22 12,22 Z M12,14 C13.6568542,14 15,12.6568542 15,11 C15,9.34314575 13.6568542,8 12,8 C10.3431458,8 9,9.34314575 9,11 C9,12.6568542 10.3431458,14 12,14 Z M12,8 L12,5 M12,17 L12,14 M6,11 L9,11 M15,11 L18,11 M8,7 L10,9 M14,13 L16,15 M16,7 L14,9 M10,13 L8,15"
            />

        </svg>
    );
}

export default Security;
