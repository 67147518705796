import React, {
    memo, useEffect, useRef, useState
} from 'react';
import get from 'lodash.get';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {selectHoveredField} from '@components/SignerUi/signerUiSlice';
import TextField from './TextField/TextField';
import DateField from './DateField';
import ListField from './ListField/ListField';
import RadioButton from './RadioButtonField/RadioButtonField';
import CheckboxField from './Checkbox/Checkbox';
import {useFieldClassName} from '../hooks';
import useFieldMutation from '../utils/useFieldMutation';
import {getFieldErrorMessage, shouldShowErrorTooltip} from '../../utils';
import './inputFields.less';

const InputField = ({
    field, fields, changeFields, changeField, fieldsErrors, originalField, selectField,
    unselectField,
    fieldsRef,
    setFieldDataToRef
}) => {
    const {
        isDateField,
        isRadio,
        isList,
        isCheckbox,
        isTextField,
        isTextArea,
        isDisabled
    } = field;
    const selectedField = useSelector(selectHoveredField);
    const {updateFieldValue, loading, error: mutationError} = useFieldMutation(field);
    const validationError = get(fieldsErrors, field.id);
    const intl = useIntl();
    const errorMessage = getFieldErrorMessage({
        intl, mutationError, validationError
    });
    const [isFocused, setIsFocused] = useState(false);
    const [hasInteracted, setHasInteracted] = useState(false);
    const isHovered = selectedField.id === field.id;
    const showError = hasInteracted && !!errorMessage;
    const showErrorTooltip = shouldShowErrorTooltip({
        isHovered, isFocused, hasInteracted, isDisabled, errorMessage, loading
    });
    const hasExceptedValidation = isRadio || isCheckbox;
    const className = useFieldClassName(field, !hasExceptedValidation && {
        loading,
        error: showError
    });
    const fieldRef = useRef(null);

    function onFocus() {
        // scroll into view only by focus by user.
        // if (isHovered) {
        //     scrollIntoViewByFieldWithPage({field, isFocused: true});
        // }
    }

    function onChangeField({name, value}) {
        changeField({
            ...field,
            [name]: value
        });
    }

    useEffect(() => {
        setFieldDataToRef({fieldRef});
    }, [field, fieldRef]);

    if (isTextField || isTextArea) {
        return (
            <TextField
                originalField={originalField}
                field={field}
                onChangeField={onChangeField}
                className={className}
                isHovered={isHovered}
                isFocused={isFocused}
                setIsFocused={setIsFocused}
                hasInteracted={hasInteracted}
                setHasInteracted={setHasInteracted}
                selectField={selectField}
                unselectField={unselectField}
                mutationError={mutationError}
                validationError={validationError}
                updateFieldValue={updateFieldValue}
                showErrorTooltip={showErrorTooltip}
                errorMessage={errorMessage}
                fieldsRef={fieldsRef}
                ref={fieldRef}
                loading={loading}
                onFocus={onFocus}
            />
        );
    }

    if (isDateField) {
        return (
            <DateField
                className={className}
                originalField={originalField}
                field={field}
                onChangeField={onChangeField}
                setIsFocused={setIsFocused}
                setHasInteracted={setHasInteracted}
                selectField={selectField}
                unselectField={unselectField}
                updateFieldValue={updateFieldValue}
                errorMessage={errorMessage}
                showErrorTooltip={showErrorTooltip}
                fieldsRef={fieldsRef}
                ref={fieldRef}
                loading={loading}
                onFocus={onFocus}
            />
        );
    }

    if (isList) {
        return (
            <ListField
                className={className}
                field={field}
                onChangeField={onChangeField}
                setIsFocused={setIsFocused}
                setHasInteracted={setHasInteracted}
                selectField={selectField}
                unselectField={unselectField}
                updateFieldValue={updateFieldValue}
                showError={showError}
                showErrorTooltip={showErrorTooltip}
                errorMessage={errorMessage}
                ref={fieldRef}
                fieldsRef={fieldsRef}
                loading={loading}
            />
        );
    }

    if (isRadio) {
        return (
            <RadioButton
                field={field}
                className={className}
                hasInteracted={hasInteracted}
                setHasInteracted={setHasInteracted}
                isFocused={isFocused}
                setIsFocused={setIsFocused}
                selectField={selectField}
                unselectField={unselectField}
                validationError={validationError}
                fields={fields}
                changeFields={changeFields}
                fieldsRef={fieldsRef}
                ref={fieldRef}
                loading={loading}
            />
        );
    }

    if (isCheckbox) {
        return (
            <CheckboxField
                originalField={originalField}
                field={field}
                fields={fields}
                changeFields={changeFields}
                onChangeField={onChangeField}
                className={className}
                selectField={selectField}
                unselectField={unselectField}
                validationError={validationError}
                isHovered={isHovered}
                isFocused={isFocused}
                hasInteracted={hasInteracted}
                setHasInteracted={setHasInteracted}
                setIsFocused={setIsFocused}
                fieldsRef={fieldsRef}
                ref={fieldRef}
                loading={loading}
            />
        );
    }

    return null;
};
export default memo(InputField);
