import React, {forwardRef} from 'react';
import {AppButton} from '../AppButton/AppButton';
import './appOutlinedButton.less';

export const AppOutlinedButton = forwardRef(({
    children,
    className = '',
    ...rest
}, ref) => (
    <AppButton
        ref={ref}
        className={`app-outlined-button ${className}`}
        variant="outlined"
        {...rest}
    >
        {children}
    </AppButton>
));
