import React, {useEffect} from 'react';
import {useLocation} from 'react-router';
import queryString from 'query-string';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {setLocale} from '../appSlice';
import ErrorPage from '../common/ErrorPage/ErrorPage';

function getErrorFromQuery(query) {
    const {error: encodedError = ''} = queryString.parse(query);
    try {
        // eslint-disable-next-line no-undef
        const decodedError = atob(encodedError);

        return JSON.parse(decodedError);
    } catch (e) {
        return {};
    }
}

export default function FullErrorPage() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const location = useLocation();
    const {
        code,
        messageKey,
        messageLanguage
    } = getErrorFromQuery(location.search);
    const customTitleMessage = intl.formatMessage({id: `esl.http.status_${code}`, defaultMessage: ''});
    const customErrorMessage = intl.formatMessage({id: `esl.${messageKey}`, defaultMessage: ''});

    useEffect(() => {
        dispatch(setLocale({locale: messageLanguage, shouldSetCookie: false}));
    }, [messageLanguage]);

    return (
        <ErrorPage
            isDashboardLinkVisible={false}
            customTitleMessage={customTitleMessage}
            customErrorMessage={customErrorMessage}
        />
    );
}
