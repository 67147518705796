import React from 'react';
import {useIntl} from 'react-intl';
import getUploadIconAndStatus from '../../../utils/getUploadIconAndStatus';
import './attachment.less';
import {UnderlinedButton} from '../../../../Core/company/buttons/UnderlinedButton/UnderlinedButton';
import {BorderedTextLabel} from '../../../../Core/company/labels/BorderedTextLabel/BorderedTextLabel';

export default function Attachment({attachment = {}, onClick}) {
    const intl = useIntl();
    const {
        icon,
        status
    } = getUploadIconAndStatus(attachment);
    const {
        name,
        description,
        required
    } = attachment;

    return (
        <div className="sui-attachment-container">
            <div className="status-container">
                {icon}
                <div className="status">
                    {intl.formatMessage({id: status, defaultMessage: ''})}
                </div>
            </div>
            <div className="name-container">
                <div className="name">
                    {onClick ? (
                        <UnderlinedButton
                            onClick={() => onClick(attachment)}
                        >
                            {name}
                        </UnderlinedButton>
                    ) : name}
                    {!required && (
                        <BorderedTextLabel
                            className="optional-label"
                        >
                            {intl.formatMessage({
                                id: required
                                    ? 'oss.components.SideBar.Uploads.required'
                                    : 'oss.components.SideBar.Document.optional',
                                defaultMessage: ''
                            })}
                        </BorderedTextLabel>
                    )}
                </div>
                {description && (
                    <div className="description">
                        {description}
                    </div>
                )}
            </div>
        </div>
    );
}
