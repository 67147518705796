import {useMutation} from '@apollo/client';
import {REASSIGN} from '../../../../../gql/mutations';

export default function useReassign(options) {
    const [reassign, {
        loading, error, data, reset
    }] = useMutation(REASSIGN, options);

    return {
        reassign, loading, error, data, reset
    };
}
