import React from 'react';
import {useIntl} from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import {HiOutlinePhotograph} from '@react-icons/all-files/hi/HiOutlinePhotograph';
import {HiOutlineEye} from '@react-icons/all-files/hi/HiOutlineEye';
import {HiOutlineEyeOff} from '@react-icons/all-files/hi/HiOutlineEyeOff';
import {HiOutlineTrash} from '@react-icons/all-files/hi/HiOutlineTrash';
import AttachmentFilePreview from './AttachmentFilePreview/AttachmentFilePreview';
import './uploadedAttachmentFile.less';

export default function UploadedAttachment({
    file,
    thumbnail,
    deleteFile,
    openPreview,
    closePreview,
    isPreviewOpen
}) {
    const intl = useIntl();

    return (
        <ListItem
            button
            disableGutters
            component="li"
            classes={{root: 'uploaded-file'}}
            onClick={isPreviewOpen ? closePreview : openPreview}
            selected={isPreviewOpen}
        >
            <div className="uploaded-file-container">
                <div className="uploaded-file-overview">
                    <div className="uploaded-attachment-thumbnail-container">
                        {thumbnail ? (
                            <img
                                // are we sure that this should be a png format?
                                src={`data:image/png;base64, ${thumbnail}`}
                                className="uploaded-attachment-thumbnail"
                                alt="Thumbnail"
                            />
                        ) : (
                            <HiOutlinePhotograph className="uploaded-attachment-thumbnail-placeholder" />
                        )}
                    </div>
                    <div className="uploaded-attachment-name-container">
                        {file.name}
                    </div>
                    <div className="uploaded-attachment-actions-container">
                        <Tooltip
                            placement="top"
                            title={intl.formatMessage({id: 'esl.generic.preview', defaultMessage: ''})}
                            arrow
                        >
                            <div className="action-btn-container preview-btn-container">
                                <IconButton
                                    edge="end"
                                    onClick={isPreviewOpen ? closePreview : openPreview}
                                    color="inherit"
                                >
                                    {isPreviewOpen
                                        ? <HiOutlineEyeOff className="icon" />
                                        : <HiOutlineEye className="icon" />}
                                </IconButton>
                            </div>
                        </Tooltip>
                        <Tooltip
                            placement="top"
                            title={intl.formatMessage({id: 'oss.components.AttachmentView.delete', defaultMessage: ''})}
                            arrow
                        >
                            <div className="action-btn-container delete-btn-container">
                                <IconButton
                                    edge="end"
                                    onClick={deleteFile}
                                    color="inherit"
                                >
                                    <HiOutlineTrash className="icon" />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <AttachmentFilePreview
                    id={file.id}
                    isOpen={isPreviewOpen}
                    hasPreview={file.preview}
                />
            </div>
        </ListItem>
    );
}
