import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet} from 'react-router';
import {Helmet} from 'react-helmet';
import {useIntl} from 'react-intl';
import Loading from '../shared/Loading/Loading';
import LoginNews from './LoginNews/LoginNews';
import Logo from '../common/Logo/Logo';
import LanguageSelector from '../common/Header/LanguageSelector/LanguageSelector';
import ThemeSelector from '../common/Header/ThemeSelector/ThemeSelector';
import {
    selectIsAuthenticating
} from './userSlice';
import {
    selectLastLoadedNewsLocale,
    selectLastLoadedNotificationsLocale,
    selectLocale,
    fetchNews,
    fetchNotifications,
    selectIsFetchingNews,
    selectIsFetchingNotifications
} from '../appSlice';
import './login.less';

export default function Login() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isAuthenticating = useSelector(selectIsAuthenticating);
    const locale = useSelector(selectLocale);
    const isFetchingNews = useSelector(selectIsFetchingNews);
    const isFetchingNotifications = useSelector(selectIsFetchingNotifications);
    const lastLoadedNewsLocale = useSelector(selectLastLoadedNewsLocale);
    const lastLoadedNotificationsLocale = useSelector(selectLastLoadedNotificationsLocale);

    useEffect(() => {
        if (locale !== lastLoadedNewsLocale && !isFetchingNews) {
            dispatch(fetchNews());
        }
        if (locale !== lastLoadedNotificationsLocale && !isFetchingNotifications) {
            dispatch(fetchNotifications());
        }
    }, [locale]);

    return (
        <div className="login-page">
            <Loading isLoading={isAuthenticating} />
            <Helmet>
                <title alt="logo">
                    {`${intl.formatMessage({id: 'esl.generic.login', defaultMessage: ''})} - 
                    ${intl.formatMessage({id: 'page.title', defaultMessage: ''})}`}
                </title>
            </Helmet>
            <div className="login-form-container">
                <div className="logo-container">
                    <Logo />
                </div>
                <Outlet />
                <div className="support-container">
                    <div className="support-title">
                        {intl.formatMessage({id: 'page.login.needSupport', defaultMessage: ''})}
                    </div>
                    <div className="support-link">
                        {intl.formatMessage({id: 'page.login.portalTitle', defaultMessage: ''})}
                        &nbsp;
                        <a
                            href={intl.formatMessage({id: 'page.login.portalLink', defaultMessage: ''})}
                            rel="noopener noreferrer"
                            target="_blank"
                            aria-label={intl.formatMessage({id: 'shared.learnMore', defaultMessage: ''})}
                        >
                            {intl.formatMessage({id: 'page.login.portalLinkTitle', defaultMessage: ''})}
                        </a>
                    </div>
                    <div className="support-email">
                        {intl.formatMessage({id: 'page.login.emailTitle', defaultMessage: ''})}
                        &nbsp;
                        <a
                            href={`mailto:${intl.formatMessage({id: 'page.login.emailLink', defaultMessage: ''})}`}
                            aria-label={intl.formatMessage({id: 'esl.generic.email', defaultMessage: ''})}
                        >
                            {intl.formatMessage({id: 'page.login.emailLink', defaultMessage: ''})}
                        </a>
                    </div>
                </div>
                <LanguageSelector />
                <div className="divider" />
                <ThemeSelector />
            </div>
            <LoginNews />
        </div>
    );
}
