import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Tab, Tabs} from '@material-ui/core';
import classnames from 'classnames';
import {Alert} from '@material-ui/lab';
import {HiOutlineUpload} from '@react-icons/all-files/hi/HiOutlineUpload';
import GraphicalSignature from '../../../../../../Core/components/GraphicalSignature/GraphicalSignature';
import {MAX_SIZE_1MB_BYTES, METHODS_TO_SIGN} from '../../../../../../../../constants';
import SignatureModal from '../../../../../../AccountSettings/CaptureSignature/SignatureModal/SignatureModal';
import useSignatureImage from '../../../../../hooks/useSignatureImage';
import {
    configurePayloadToSignFromBase64String,
    formatBytesToMb,
    readFile
} from '../../../../../../AccountSettings/CaptureSignature/helpers';
import './signatureSelectionModal.less';
import useJoinMessage from '../../../../../../../hooks/useJoinMessage';
import Signature from '../../../../../../shared/icons/Signature';
import Image from '../../../../../../common/Image/Image';
import ImageThumbnail from '../../../../../../common/ImageThumbnail/ImageThumbnail';
import {Dialog, DialogButton} from '../../../../../../Core/company/modals/Dialog';
import {AppButton} from '../../../../../../Core/buttons/AppButton/AppButton';
import {ORIENTATION, useScreenOrientation} from '../../../../../../../reusable/hooks/useScreenOrientation';

const canvasWidthPx = 330;
const canvasWidthLandScapePx = 200;
const canvasHeightPx = 150;
const canvasHeightLandScapePx = 100;
const borderWidthPx = 4;
const acceptedFilesStr = 'image/jpeg,image/png,image/jpg';

const SignatureSelectionModal = ({
    isOpen,
    setIsOpen,
    signUsingImage,
    signUsingSignature,
    field,
    defaultSignature
}) => {
    const intl = useIntl();
    const {orientation} = useScreenOrientation();
    const canUseImageToSign = field.approval.isFromFile;
    const {signatureImage: defaultSignatureImage} = useSignatureImage({skip: !canUseImageToSign});
    const defaultMethodToSign = canUseImageToSign ? METHODS_TO_SIGN.IMAGE : METHODS_TO_SIGN.DRAWED_SIGNATURE;
    const [methodToSign, setMethodToSign] = useState(defaultMethodToSign);
    const [signatureImage, setSignatureImage] = useState({
        type: '',
        name: '',
        base64String: ''
    });
    const [signature, setSignature] = useState(defaultSignature);
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(defaultMethodToSign === METHODS_TO_SIGN.DRAWED_SIGNATURE && !signature);
    const getSelectionContainerClassName = (method) => classnames('signing-method', method.toLowerCase());
    const containerClassName = classnames('signature-selection-modal');
    const imageError = signatureImage.size > MAX_SIZE_1MB_BYTES;
    const imageContainerClassName = classnames(getSelectionContainerClassName(METHODS_TO_SIGN.IMAGE), {error: imageError});
    const isDisabledSign = !methodToSign
        || (methodToSign === METHODS_TO_SIGN.IMAGE && (imageError || !signatureImage.base64String))
        || (methodToSign === METHODS_TO_SIGN.DRAWED_SIGNATURE && !signature);
    const pseudoCanvasEl = useRef(null);
    const inputRef = useRef(null);
    const joinMessage = useJoinMessage();
    const isLandscape = orientation === ORIENTATION.LANDSCAPE;

    async function uploadNewImage({target}) {
        const sourceFile = target.files[0];
        setMethodToSign(METHODS_TO_SIGN.IMAGE);

        const base64String = await readFile(sourceFile);
        if (base64String) {
            setSignatureImage({
                type: sourceFile.type,
                name: sourceFile.name,
                size: sourceFile.size,
                base64String
            });
        }
    }

    async function handleSign(signatureToSign = signature) {
        if (methodToSign === METHODS_TO_SIGN.DRAWED_SIGNATURE) {
            signUsingSignature(signatureToSign);
        }
        if (methodToSign === METHODS_TO_SIGN.IMAGE) {
            signUsingImage(configurePayloadToSignFromBase64String(signatureImage.base64String));
        }
        setIsOpen(false);
        setMethodToSign(defaultMethodToSign);
    }

    function onSignUsingDrawedSignature(parsedSignature) {
        setIsSignatureModalOpen(false);
        setMethodToSign(METHODS_TO_SIGN.DRAWED_SIGNATURE);
        setSignature(parsedSignature);
        handleSign(parsedSignature);
    }
    function changeMethodToSign(event, method) {
        setMethodToSign(method);
    }

    function openFileSystemToUploadImage() {
        inputRef.current.click();
    }

    useEffect(() => {
        if (defaultSignatureImage) {
            setSignatureImage({
                type: defaultSignatureImage.type,
                name: defaultSignatureImage.name,
                base64String: `${defaultSignatureImage.type},${defaultSignatureImage.value}`
            });
        }
    }, [defaultSignatureImage]);

    useEffect(() => {
        if (defaultSignature) {
            setSignature(defaultSignature);
        }
    }, [defaultSignature]);

    useEffect(() => {
        if (isOpen) {
            setIsSignatureModalOpen(defaultMethodToSign === METHODS_TO_SIGN.DRAWED_SIGNATURE && !signature);
        }
    }, [isOpen]);

    return (
        <Dialog
            open={isOpen}
            className={containerClassName}
            data-wdio="test-signature-selection-modal"
            content={(
                <>
                    <h2 className="title">
                        {joinMessage('sui.shared.select', 'sui.shared.signature')}
                    </h2>
                    <Tabs
                        value={methodToSign}
                        onChange={changeMethodToSign}
                        className="tabs-container"
                        orientation="horizontal"
                    >
                        {canUseImageToSign && (
                            <Tab
                                value={METHODS_TO_SIGN.IMAGE}
                                disableRipple
                                icon={<HiOutlineUpload aria-hidden className="icon" />}
                                label={intl.formatMessage({id: 'sui.shared.image', defaultMessage: ''})}
                            />
                        )}
                        <Tab
                            value={METHODS_TO_SIGN.DRAWED_SIGNATURE}
                            disableRipple
                            icon={<Signature aria-hidden className="icon signature-icon" />}
                            label={intl.formatMessage({id: 'sui.shared.signature', defaultMessage: ''})}
                        />
                    </Tabs>
                    <div className="tabs-content">
                        {methodToSign === METHODS_TO_SIGN.IMAGE && canUseImageToSign && (
                            <div className={imageContainerClassName}>
                                <div className="content">
                                    <div className="upload-image-container">
                                        {signatureImage.base64String ? (
                                            <div className="image-container">
                                                <Image
                                                    src={signatureImage.base64String}
                                                    maxHeight={isLandscape ? canvasHeightLandScapePx : canvasHeightPx + borderWidthPx * 2}
                                                    maxWidth={isLandscape ? canvasWidthLandScapePx : canvasWidthPx + borderWidthPx * 2}
                                                />
                                            </div>
                                        ) : <ImageThumbnail onClick={openFileSystemToUploadImage} />}
                                        {imageError && (
                                            <Alert
                                                severity="info"
                                                variant="outlined"
                                                classes={{root: 'info-alert'}}
                                            >
                                                {intl.formatMessage(
                                                    {id: 'sui.shared.bigFileSize', defaultMessage: ''},
                                                    {
                                                        size: formatBytesToMb(signatureImage.size, 0),
                                                        maxSize: formatBytesToMb(MAX_SIZE_1MB_BYTES, 0)
                                                    }
                                                )}
                                            </Alert>
                                        )}
                                    </div>
                                    <input
                                        ref={inputRef}
                                        type="file"
                                        hidden
                                        accept={acceptedFilesStr}
                                        onChange={uploadNewImage}
                                        data-wdio="test-signature-image-input"
                                    />
                                </div>
                                <div className="footer">
                                    <AppButton
                                        onClick={openFileSystemToUploadImage}
                                    >
                                        {joinMessage('sui.signature.uploadNew')}
                                    </AppButton>
                                </div>
                            </div>
                        )}
                        {methodToSign === METHODS_TO_SIGN.DRAWED_SIGNATURE && (
                            <div className={getSelectionContainerClassName(METHODS_TO_SIGN.DRAWED_SIGNATURE)}>
                                <div className="content">
                                    <div className="drawed-signature-container">
                                        {signature ? (
                                            <GraphicalSignature
                                                signature={signature}
                                                width={isLandscape ? canvasWidthLandScapePx : canvasWidthPx}
                                                height={isLandscape ? canvasHeightLandScapePx : canvasHeightPx}
                                                pseudoCanvasEl={pseudoCanvasEl}
                                                disabledToDraw
                                            />
                                        ) : (
                                            <ImageThumbnail
                                                icon={<Signature aria-hidden className="icon" />}
                                                onClick={() => setIsSignatureModalOpen(true)}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="footer">
                                    <AppButton
                                        onClick={() => setIsSignatureModalOpen(true)}
                                    >
                                        {joinMessage('sui.signature.drawNew')}
                                    </AppButton>
                                </div>
                            </div>
                        )}
                    </div>
                    <SignatureModal
                        isOpen={isSignatureModalOpen}
                        close={() => { setIsSignatureModalOpen(false); }}
                        save={onSignUsingDrawedSignature}
                        saveBtnText={intl.formatMessage({
                            id: 'oss.components.FieldsList.SignatureBlock.capture_signature', defaultMessage: ''
                        })}
                    />
                </>
            )}
            actions={(
                <>
                    <DialogButton
                        onClick={() => {
                            setIsOpen(false);
                            setMethodToSign(defaultMethodToSign);
                        }}
                    >
                        {intl.formatMessage({id: 'oss.components.FieldsList.SignatureBlock.capsig_cancel_button', defaultMessage: ''})}
                    </DialogButton>
                    <AppButton
                        onClick={() => handleSign()}
                        disabled={isDisabledSign}
                    >
                        {intl.formatMessage({id: 'oss.components.FieldsList.SignatureBlock.capture_signature', defaultMessage: ''})}
                    </AppButton>
                </>
            )}
        />
    );
};
export default SignatureSelectionModal;
