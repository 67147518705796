import pick from 'lodash.pick';
import getValidPackagesParams from '../../utils/getValidPackagesParams';
import {
    fetchTransactions,
    setOptions
} from './transactionsSlice';
import {
    DEFAULT_TRANSACTIONS_OPTIONS
} from '../../../constants';

export default function ssrFetch({
    store, headers, baseUrl, params
}) {
    if (params && params.query) {
        const newOptions = {
            ...DEFAULT_TRANSACTIONS_OPTIONS,
            ...getValidPackagesParams(params)
        };
        const filteredNewOptions = pick(newOptions, [
            'from',
            'to',
            'sort',
            'dir',
            'query',
            'search',
            'predefined',
            'lastUpdatedStartDate'
        ]);
        store.dispatch(setOptions(filteredNewOptions));
    }
    return store.dispatch(fetchTransactions({headers, baseUrl}));
}
