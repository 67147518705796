import React, {forwardRef} from 'react';
import classnames from 'classnames';
import {IconButton} from '@material-ui/core';
import './appOutlinedIconButton.less';

const AppOutlinedIconButton = forwardRef(({
    className, children, disabled, size = 'small', ...restProps
}, ref) => {
    const containerClassName = classnames('outlined-icon-button', className, {disabled});

    return (
        <IconButton
            ref={ref}
            disabled={disabled}
            className={containerClassName}
            size={size}
            {...restProps}
        >
            {children}
        </IconButton>
    );
});

export default AppOutlinedIconButton;
