import {
    getRequiredActionsByPages,
    hasOptionalNotAcceptedApprovals as hasOptionalNotAcceptedApprovalsSelector,
    hasOnlyOptionalApprovals as hasOnlyOptionalApprovalsSelector,
    hasAtLeastOneAcceptedApproval as hasAtLeastOneAcceptedApprovalSelector,
    getDocumentById
} from '../../../utils/selectors';
import {
    signerApprovalsFragment,
    getCachedSignatureAndDocumentsFragment,
    documentWasReadyToConfirm,
    documentIsReadyToConfirm,
    cachedSignatureFragment,
    readPagesFromDocument,
    roleFragment,
    roleIdFragment
} from './fragments';
import {GET_CEREMONY} from '../../../../../../gql/queries';
import GET_FIELDS_VALIDATION from '../../../../../../gql/getFieldsValidation.gql';
import {SIGNING_TRANSACTION_STATUS} from './constants';
/* eslint-disable import/prefer-default-export */

export const getShouldUpdateCachedSignature = (
    isSignatureCaptureEnforced,
    isCurrentSignatureCaptureEnforced,
    hasSomeCaptureSignatureAccepted,
    cachedSignature,
    isUndo
) => isSignatureCaptureEnforced
  || isCurrentSignatureCaptureEnforced
  || (hasSomeCaptureSignatureAccepted && !cachedSignature)
  || (!hasSomeCaptureSignatureAccepted && isUndo);

export const getTransactionStatus = (hasSomeSignatureAccepted) => (hasSomeSignatureAccepted
    ? SIGNING_TRANSACTION_STATUS.INPROGRESS
    : SIGNING_TRANSACTION_STATUS.NOTSTARTED);

export const updateCache = ({
    documentId,
    transactionId,
    hasConditions,
    client
}) => async (cache) => {
    const {pages} = cache.readFragment({
        fragment: readPagesFromDocument,
        id: `Document:${documentId}`
    });

    const {signerApprovals, allSignersApprovals} = cache.readFragment({
        fragment: signerApprovalsFragment,
        id: `Document:${documentId}`
    });

    const {documents} = cache.readFragment({
        fragment: getCachedSignatureAndDocumentsFragment,
        id: `Transaction:${transactionId}`
    });

    const {
        isReadyToConfirm: wasReadyToConfirm,
        isReadyToConfirmByAll: wasReadyToConfirmByAll
    } = cache.readFragment({
        fragment: documentWasReadyToConfirm,
        id: `Document:${documentId}`
    });

    const hasOnlyOptionalApprovals = hasOnlyOptionalApprovalsSelector(signerApprovals);

    const hasOnlyOptionalApprovalsAndFields = getRequiredActionsByPages({pages}).length === 0;

    const isReadyToConfirm = signerApprovals.every(
        ({isAccepted, isOptional}) => isAccepted || isOptional
    );

    const isReadyToConfirmByAll = allSignersApprovals.every(
        ({isAccepted, isOptional}) => isAccepted || isOptional
    );

    const hasOptionalNotAcceptedApprovals = hasOptionalNotAcceptedApprovalsSelector(signerApprovals);

    const hasAtLeastOneAcceptedApproval = hasAtLeastOneAcceptedApprovalSelector(signerApprovals);

    const document = getDocumentById(documents, documentId);

    const hasSomeSignatureAccepted = document.currentSignerApprovals.some(
        ({isAccepted}) => isAccepted
    );

    if (
        isReadyToConfirm
      || isReadyToConfirm !== wasReadyToConfirm
      || isReadyToConfirmByAll
      || isReadyToConfirmByAll !== wasReadyToConfirmByAll
    ) {
        cache.writeFragment({
            data: {
                __typename: 'Document',
                hasAtLeastOneAcceptedApproval,
                hasOnlyOptionalApprovals,
                hasOnlyOptionalApprovalsAndFields,
                hasOptionalNotAcceptedApprovals,
                id: documentId,
                isReadyToConfirm,
                isReadyToConfirmByAll
            },
            fragment: documentIsReadyToConfirm,
            id: `Document:${documentId}`
        });
    }

    const {ceremony} = cache.readQuery({
        query: roleIdFragment,
        variables: {
            transactionId
        }
    });

    const roleId = ceremony?.role?.id;

    cache.writeFragment({
        data: {
            __typename: 'Role',
            id: roleId,
            progress: getTransactionStatus(hasSomeSignatureAccepted)
        },
        fragment: roleFragment,
        id: `Role:${roleId}`
    });

    if (hasConditions) {
        try {
            const {
                ceremony: {
                    transaction: {raw: rawTransaction},
                    session: {raw: rawSession}
                }
            } = cache.readQuery({
                query: GET_CEREMONY,
                variables: {transactionId}
            });

            await client.query({
                query: GET_FIELDS_VALIDATION,
                variables: {
                    documentId,
                    transactionId,
                    rawTransaction,
                    rawSession
                },
                fetchPolicy: 'network-only'
            });
        } catch (err) {
        // continue
        }
    }
};
export const getCachedSignatureFromCache = (client, transactionId) => {
    const queryResult = client.readFragment({
        fragment: cachedSignatureFragment,
        id: `Transaction:${transactionId}`
    });
    return queryResult?.cachedSignature || null;
};

export const getFieldPositionStyles = (field) => {
    const {
        left = 0,
        top = 0,
        width = 0,
        height = 0
    } = field;
    const defaultFontSize = 12;

    return {
        left: Math.round(left),
        top: Math.round(top),
        width: Math.round(width),
        height: Math.round(height),
        transform: 'translate3d(0, 0, 0)',
        transformOrigin: '0 0 0',
        fontSize: defaultFontSize
    };
};
