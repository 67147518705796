import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import GET_ATTACHMENT_THUMBNAILS from '../../../../gql/getAttachmentThumbnails.gql';
import {selectSelectedAttachmentId, selectTransactionId} from '../signerUiSlice';

export default function useAttachmentThumnails() {
    const transactionId = useSelector(selectTransactionId);
    const attachmentId = useSelector(selectSelectedAttachmentId);
    const {data = {}, error, loading} = useQuery(GET_ATTACHMENT_THUMBNAILS, {
        variables: {attachmentId, transactionId}
    });
    const {attachmentThumbnails = []} = data;

    return useMemo(() => ({
        attachmentThumbnails, error, loading
    }), [attachmentThumbnails, error, loading]);
}
