import {useMutation} from '@apollo/client';
import {RESEND_SMS} from '../../../../../gql/mutations';

export default function useResendSms(options) {
    const [resendSms, {
        loading, error, data, reset
    }] = useMutation(RESEND_SMS, options);

    return {
        resendSms, loading, error, data, reset
    };
}
