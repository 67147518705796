import {defaultSignatureColor} from '../../constants';
import './styles.less';

const colors = [defaultSignatureColor, '#000000', '#F26522', '#C9002B'];

export const Colors = ({
    color,
    handleColorChange,
    handleColorHover,
    handleColorLeave,
    className
}) => (
    <div className={`colors-container ${className}`}>
        {colors.map((c) => (
            <div
                key={c}
                className={`color-item ${c === color ? 'selected' : ''}`}
                onClick={() => handleColorChange(c)}
                onKeyDown={(e) => e.key === 'Enter' && handleColorChange(c)}
                onMouseEnter={() => handleColorHover(c)}
                onMouseLeave={handleColorLeave}
                tabIndex={0}
                role="button"
                aria-label={`Color ${c}`}
            >
                <div
                    className="color"
                    style={{
                        backgroundColor: c
                    }}
                />
            </div>
        ))}
    </div>
);
