import {gql} from '@apollo/client';

export const GET_FEATURES = gql`query getFeatures($transactionId: ID!) {
  ceremony(transactionId: $transactionId) {
    session {
      features {
        inPersonSmsAuth
      }
    }
  }
}`;
