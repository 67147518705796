import React from 'react';

export default function Document(props) {
    return (
        <svg
            viewBox="0 0 25 25"
            xmlns="http://www.w3.org/2000/svg"
            className="document-icon"
            {...props}
        >
            <defs>
                <linearGradient
                    x1={-0.038}
                    y1={0.058}
                    x2={0.915}
                    y2={1.031}
                    id="prefix__a"
                >
                    <stop offset={0} stopColor="#FFF" stopOpacity={0} />
                    <stop offset={0.86} stopColor="#5E5E5E" stopOpacity={0.059} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.102} />
                </linearGradient>
                <linearGradient x1={0.5} y1={0.97} x2={0.5} y2={0.03} id="prefix__b">
                    <stop offset={0} stopColor="#828282" stopOpacity={0} />
                    <stop offset={0.86} stopColor="#303030" stopOpacity={0.059} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.102} />
                </linearGradient>
                <linearGradient x1={7.5} y1={28.94} x2={7.5} y2={27.06} id="prefix__c">
                    <stop offset={0} stopColor="#828282" stopOpacity={0} />
                    <stop offset={0.86} stopColor="#303030" stopOpacity={0.059} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.102} />
                </linearGradient>
                <linearGradient
                    x1={24.5}
                    y1={28.94}
                    x2={24.5}
                    y2={27.06}
                    id="prefix__d"
                >
                    <stop offset={0} stopColor="#828282" stopOpacity={0} />
                    <stop offset={0.86} stopColor="#303030" stopOpacity={0.059} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.102} />
                </linearGradient>
                <linearGradient x1={16} y1={28.97} x2={16} y2={28.03} id="prefix__e">
                    <stop offset={0} stopColor="#828282" stopOpacity={0} />
                    <stop offset={0.86} stopColor="#303030" stopOpacity={0.059} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.102} />
                </linearGradient>
                <linearGradient x1={16} y1={22.94} x2={16} y2={21.06} id="prefix__f">
                    <stop offset={0} stopColor="#828282" stopOpacity={0} />
                    <stop offset={0.86} stopColor="#303030" stopOpacity={0.059} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.102} />
                </linearGradient>
                <linearGradient x1={0.5} y1={0.907} x2={0.5} y2={0.147} id="prefix__g">
                    <stop offset={0} stopColor="#FFF" stopOpacity={0} />
                    <stop offset={0.34} stopColor="#FFF" stopOpacity={0.039} />
                    <stop offset={0.9} stopColor="#FFF" stopOpacity={0.129} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.149} />
                </linearGradient>
                <linearGradient x1={7} y1={23.72} x2={7} y2={21.44} id="prefix__h">
                    <stop offset={0} stopColor="#FFF" stopOpacity={0} />
                    <stop offset={0.34} stopColor="#FFF" stopOpacity={0.039} />
                    <stop offset={0.9} stopColor="#FFF" stopOpacity={0.129} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.149} />
                </linearGradient>
                <linearGradient x1={25} y1={23.72} x2={25} y2={21.44} id="prefix__i">
                    <stop offset={0} stopColor="#FFF" stopOpacity={0} />
                    <stop offset={0.34} stopColor="#FFF" stopOpacity={0.039} />
                    <stop offset={0.9} stopColor="#FFF" stopOpacity={0.129} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.149} />
                </linearGradient>
                <linearGradient
                    x1={0.482}
                    y1={27.36}
                    x2={0.518}
                    y2={26.22}
                    id="prefix__j"
                >
                    <stop offset={0} stopColor="#FFF" stopOpacity={0} />
                    <stop offset={0.34} stopColor="#FFF" stopOpacity={0.039} />
                    <stop offset={0.9} stopColor="#FFF" stopOpacity={0.129} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.149} />
                </linearGradient>
                <linearGradient
                    x1={0.689}
                    y1={0.316}
                    x2={0.869}
                    y2={0.14}
                    id="prefix__k"
                >
                    <stop offset={0} stopColor="#FFF" stopOpacity={0} />
                    <stop offset={0.45} stopColor="#FFF" stopOpacity={0.012} />
                    <stop offset={0.64} stopColor="#FFF" stopOpacity={0.039} />
                    <stop offset={0.78} stopColor="#FFF" stopOpacity={0.078} />
                    <stop offset={0.89} stopColor="#FFF" stopOpacity={0.149} />
                    <stop offset={0.99} stopColor="#FFF" stopOpacity={0.239} />
                    <stop offset={1} stopColor="#FFF" stopOpacity={0.251} />
                </linearGradient>
            </defs>
            <path d="M20.834 23.643H4.167V1.357h12.5l4.167 4.286v18z" fill="#FFF" />
            <path
                d="M16.666.5H3.333v24h18.333V5.643l-5-5.143zm-12.5 23.143h16.667V6.5h-5V1.357H4.166v22.286zm12.5-21.857l3.75 3.857h-3.75V1.786z"
                fill="#A6A6A6"
                fillRule="evenodd"
            />
            <path
                d="M5.833 8.214h13.333v.857H5.833v-.857zM5.833 9.929h13.333v.857H5.833v-.857zM5.833 11.643h13.333v.857H5.833v-.857zM5.833 13.357h13.333v.857H5.833v-.857zM5.833 3.071h5v.858h-5V3.07z"
                fill="#C8C8C8"
            />
            <path
                d="M8.333 15.929h8.333v6.857H8.333v-6.857zm7.5 6v-5.143H9.166v5.143h6.667z"
                fill="#D65532"
                fillRule="evenodd"
            />
            <path
                d="M2.5 16.786h5v5.143h-5v-5.143zM17.5 16.786h5v5.143h-5v-5.143z"
                fill="#D65532"
            />
            <path
                d="M20 16.286h-.833V5.143l-5-5.143H.833v16.286H0v5.143h.833V24h18.334v-2.571H20v-5.143z"
                fill="url(#prefix__a)"
                transform="translate(2.5 .5)"
            />
            <path
                d="M0 0h5v1.714H0V0z"
                transform="translate(15.833 6.5)"
                fill="url(#prefix__b)"
            />
            <path
                d="M0 0h4.167v1.714H0V0z"
                transform="translate(3.333 21.929)"
                fill="url(#prefix__c)"
            />
            <path
                d="M0 0h4.167v1.714H0V0z"
                transform="translate(17.5 21.929)"
                fill="url(#prefix__d)"
            />
            <path
                d="M0 0h8.333v.857H0V0z"
                transform="translate(8.333 22.786)"
                fill="url(#prefix__e)"
            />
            <path
                d="M0 0h6.667v1.714H0V0z"
                transform="translate(9.167 16.786)"
                fill="url(#prefix__f)"
            />
            <path
                d="M8.333 3.429H7.5V.857H.833V3.43H0V0h8.333v3.429z"
                transform="translate(8.333 15.929)"
                fill="url(#prefix__g)"
            />
            <path
                d="M0 0h5v2.571H0V0z"
                transform="translate(2.5 16.786)"
                fill="url(#prefix__h)"
            />
            <path
                d="M0 0h5v2.571H0V0z"
                transform="translate(17.5 16.786)"
                fill="url(#prefix__i)"
            />
            <path
                d="M0 0h6.667v1.286H0V0z"
                transform="translate(9.167 21.071)"
                fill="url(#prefix__j)"
            />
            <path
                d="M0 0v.857h12.5V6h5v13.286h.833V5.143l-5-5.143H0z"
                transform="translate(3.333 .5)"
                fill="url(#prefix__k)"
            />
        </svg>
    );
}
