import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {HiOutlinePlus} from '@react-icons/all-files/hi/HiOutlinePlus';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import classnames from 'classnames';
import {
    BROADCAST_MESSAGES,
    DEFAULT_FONT_SIZE,
    TRANSACTION_PATH
} from '../../../../../constants';
import {fetchTransactionsSummary, selectTransactionsSummary} from '../../../Transactions/transactionsSlice';
import {createTransaction, selectIsCreatingTransaction} from '../../../Dashboard/dashboardSlice';
import {saveReminderConfig} from '../../../Transaction/transactionSlice';
import useBroadcaster from '../../../../hooks/useBroadcaster';
import {configurePackageDueDate} from '../../../../utils/helpers';
import {selectUser} from '../../../Login/userSlice';
import {setIsGlobalLoading} from '../../../appSlice';

export default function CreateTransaction({closeActions, isDisabled, tooltipPlacement}) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const transactionsSummary = useSelector(selectTransactionsSummary);
    const isCreatingTransaction = useSelector(selectIsCreatingTransaction);
    const allowCreation = get(transactionsSummary, 'allow_creation', true);
    const broadcast = useBroadcaster();
    const personalPreferences = get(user, 'data.personal_preferences', {});
    const transactionName = intl.formatMessage({id: 'shared.floatingActions.newTransactionName', defaultMessage: ''});
    const className = classnames('floating-action-btn-container', {
        'is-loading': isCreatingTransaction
    });
    const message = allowCreation
        ? intl.formatMessage({id: 'page.dashboard.newTransaction.action.START_TRANSACTION', defaultMessage: ''})
        : intl.formatMessage({id: 'shared.transactionLimit.reached', defaultMessage: ''});

    const openCreatedTransaction = (id) => {
        navigate({
            pathname: `${TRANSACTION_PATH}/${id}`,
            state: {fromDashboard: true}
        });
    };

    async function create() {
        if (!allowCreation) {
            return false;
        }

        closeActions();
        dispatch(setIsGlobalLoading(true));

        const transaction = {
            name: transactionName,
            due: configurePackageDueDate({selectedTemplate: null, personalPreferences}),
            data: {
                senderVisible: false,
                origin: 'validsign'
            },
            settings: {
                ceremony: {
                    fontSize: personalPreferences.fontSizeSelected ? personalPreferences.fontSize : DEFAULT_FONT_SIZE
                }
            }
        };
        const createdId = await dispatch(createTransaction({
            transaction,
            template: null,
            documents: [],
            bulkCSV: null
        }));

        dispatch(setIsGlobalLoading(false));

        if (createdId) {
            if (personalPreferences.reminder_schedule && !isEmpty(personalPreferences.reminder_schedule_values)) {
                await dispatch(saveReminderConfig(
                    createdId,
                    get(personalPreferences, 'reminder_schedule_values[0]', {})
                ));
            }

            openCreatedTransaction(createdId);
            broadcast(BROADCAST_MESSAGES.TRANSACTIONS_UPDATED);
        }
    }

    function renderButton() {
        return (
            <div className={className}>
                <IconButton
                    size="small"
                    classes={{root: 'floating-action-btn hide-actions'}}
                    onClick={create}
                    disabled={isCreatingTransaction || !allowCreation || isDisabled}
                >
                    <HiOutlinePlus aria-hidden className="icon" />
                </IconButton>
            </div>
        );
    }

    useEffect(() => {
        dispatch(fetchTransactionsSummary());
    }, []);

    return (
        <>
            {isDisabled ? renderButton() : (
                <Tooltip
                    placement={tooltipPlacement}
                    title={message}
                    arrow
                >
                    {renderButton()}
                </Tooltip>
            )}
        </>
    );
}
