/* eslint-disable no-useless-escape */
/* eslint-disable no-multi-assign */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
// BASE64

const B64_STR = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const B64_AR = B64_STR.split('');
const VALID_REX = /^[A-Za-z0-9+\/]+={0,2}$/;
const {fromCharCode} = String;

export default {
    encode: (input) => {
        const output = [];
        let chr1;
        let chr2;
        let chr3;
        let enc1;
        let enc2;
        let enc3;
        let enc4;
        let i = 0;
        const l = input.length;

        while (i < l) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (Number.isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (Number.isNaN(chr3)) {
                enc4 = 64;
            }

            output.push(B64_AR[enc1],
                B64_AR[enc2],
                B64_AR[enc3],
                B64_AR[enc4]);
        }

        return output.join('');
    },
    decode: (input) => {
        if (!input) return '';
        if (!VALID_REX.test(input)) {
            throw new Error('b64.decode input contains invalid character');
        }

        const output = [];
        let chr1;
        let chr2;
        let chr3;
        let enc1;
        let enc2;
        let enc3;
        let enc4;
        let i = 0;
        const l = input.length;

        while (i < l) {
            enc1 = B64_STR.indexOf(input.charAt(i++));
            enc2 = B64_STR.indexOf(input.charAt(i++));
            enc3 = B64_STR.indexOf(input.charAt(i++));
            enc4 = B64_STR.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output.push(fromCharCode(chr1));

            if (enc3 !== 64) { output.push(fromCharCode(chr2)); }
            if (enc4 !== 64) { output.push(fromCharCode(chr3)); }
        }

        return output.join('');
    }
};
