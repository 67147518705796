import {gql} from '@apollo/client';

export const GET_NATIVE_BRIDGE_CONFIG = gql`query getNativeBridgeConfig($transactionId: ID!) {
  nativeBridgeConfig(transactionId: $transactionId) {
    url
    macUrl
    version
    __typename
  }
}`;
