import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {HiOutlineLink} from '@react-icons/all-files/hi/HiOutlineLink';
import {useIntl} from 'react-intl';
import isEmpty from 'lodash.isempty';
import Popper from '@material-ui/core/Popper';
import get from 'lodash.get';
import uniqBy from 'lodash.uniqby';
import {getFieldConditions} from '../../utils';
import {selectFieldsConditions, setHoveredFieldInCondition} from '../../../signerUiSlice';
import {FIELD_SUBTYPES, Z_INDEX} from '../../../../../../constants';
import {getFieldLabel, getFieldName} from '../../../../Designer/utils';
import {isInputFieldEnabled} from '../../../utils/selectors';
import {useScrollContext} from '../../../../shared/Documents/ScrollContext';
import {AppButton} from '../../../../Core/buttons/AppButton/AppButton';

import './conditionIcon.less';

const defaultSize = 18;
const minSize = 8;
const maxSize = defaultSize;

const getConditionIconSize = ({width, height}) => {
    const smallestFieldDimension = Math.min(width, height);
    const relativeSize = smallestFieldDimension ? smallestFieldDimension * 0.4 : defaultSize;

    if (relativeSize > maxSize) return maxSize;
    if (relativeSize < minSize) return minSize;
    return Math.round(relativeSize);
};

const ConditionIcon = ({field, fieldsRef, position}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const fieldsConditions = useSelector(selectFieldsConditions);
    const [anchorEl, setAnchorEl] = useState(null);
    const {hasConditions, isConditionalTarget} = field;
    const containerClassName = classNames('signing-condition-icon', getFieldName(field));
    const fieldRelatedConditions = getFieldConditions({field, fieldsConditions});
    const {scrollIntoViewByFieldWithPage} = useScrollContext();
    const size = getConditionIconSize({width: position.width, height: position.height});

    function open(event) {
        setAnchorEl(event.currentTarget);
    }

    function close() {
        setAnchorEl(null);
    }

    function goToField(fld) {
        const nextField = get(fieldsRef, `current.${fld.originalId}`, null);
        if (nextField) {
            const nextFieldWithPage = nextField.getFieldData();
            nextField.htmlNode?.focus();
            scrollIntoViewByFieldWithPage({field: nextFieldWithPage});
        }
    }

    function onHoverConditionalField(fld) {
        dispatch(setHoveredFieldInCondition(fld));
    }
    function onBlurConditionalField() {
        dispatch(setHoveredFieldInCondition({}));
    }
    function renderGoToFieldButton(fld, fields = [], idx = 0) {
        const separator = !isEmpty(fields) && fields.length - 1 !== idx ? ', ' : '';
        return (
            <>
                <AppButton
                    key={fld.id}
                    onClick={() => goToField(fld)}
                    onMouseEnter={() => onHoverConditionalField(fld)}
                    onMouseLeave={() => onBlurConditionalField(fld)}
                    className={classNames('go-to-field-btn', {
                        disabled: !isInputFieldEnabled(fld)
                    })}
                >
                    {fld.name || intl.formatMessage({id: getFieldLabel(fld), defaultMessage: ''})}
                </AppButton>
                {separator}
            </>

        );
    }

    function renderFieldCondition(condition) {
        const actionFields = uniqBy(condition.actionFields, 'id');
        const isList = condition.field.subtype === FIELD_SUBTYPES.LIST;
        return (
            <div className="condition-container" key={condition.id}>
                <p className="condition-text">
                    {intl.formatMessage({id: 'esl.conditionals.if', defaultMessage: ''})}
                    &nbsp;
                    <span className="lowercase-text">
                        {intl.formatMessage({id: 'page.designer.fields.thisField', defaultMessage: ''})}
                        &nbsp;
                    </span>
                    <span className="lowercase-text">
                        {intl.formatMessage({id: 'esl.conditionals.is', defaultMessage: ''})}
                        &nbsp;
                    </span>
                    <span>
                        {isList ? condition.state : intl.formatMessage({id: `page.designer.fields.is.${condition.state}`, defaultMessage: ''})}
                        &nbsp;
                    </span>
                    <span className="lowercase-text">
                        {intl.formatMessage({id: 'esl.conditionals.then', defaultMessage: ''})}
                        &nbsp;
                    </span>
                    <span>
                        {intl.formatMessage({id: `page.designer.fields.then.${condition.action.value}`, defaultMessage: ''})}
                        &nbsp;
                    </span>
                    <span className="lowercase-text">
                        {intl.formatMessage({id: 'esl.generic.fields', defaultMessage: ''})}
                        &nbsp;
                        <span className="lowercase-text">
                            {actionFields.map((fld, idx) => renderGoToFieldButton(fld, actionFields, idx))}
                        </span>
                    </span>
                </p>
            </div>
        );
    }

    function renderTargetedFieldCondition(condition) {
        // const condition = getViceVersedCondition(cond);
        const isList = condition.field.subtype === FIELD_SUBTYPES.LIST;

        return (
            <div className="condition-container" key={condition.id}>
                <p className="condition-text">
                    <span>
                        {intl.formatMessage({id: 'page.designer.fields.thisField', defaultMessage: ''})}
                        &nbsp;
                    </span>
                    <span className="lowercase-text">
                        {intl.formatMessage({id: 'esl.conditionals.is', defaultMessage: ''})}
                        &nbsp;
                    </span>
                    <span className="lowercase-text">
                        {intl.formatMessage({id: `page.designer.targetedFields.then.${condition.action.value}`, defaultMessage: ''})}
                        &nbsp;
                    </span>
                    <span className="lowercase-text">
                        {intl.formatMessage({id: 'esl.conditionals.if', defaultMessage: ''})}
                        &nbsp;
                    </span>
                    <span>
                        {renderGoToFieldButton(condition.field)}
                        &nbsp;
                    </span>
                    <span className="lowercase-text">
                        {intl.formatMessage({id: 'esl.conditionals.is', defaultMessage: ''})}
                        &nbsp;
                    </span>
                    <span className="lowercase-text">
                        {isList ? condition.state : intl.formatMessage({id: `page.designer.fields.is.${condition.state}`, defaultMessage: ''})}
                    </span>
                    <p />
                </p>
            </div>
        );
    }

    const content = fieldRelatedConditions ? (
        <div className="content">
            {isConditionalTarget && (
                <div className="conditions conditional-target-conditions">
                    {fieldRelatedConditions.map(renderTargetedFieldCondition)}
                </div>
            )}
            {isConditionalTarget && hasConditions && <div className="divider" />}
            {hasConditions && (
                <div className="conditions field-conditions">
                    {fieldRelatedConditions.map(renderFieldCondition)}
                </div>
            )}
        </div>
    ) : null;

    function handleClickOutside(event) {
        if (anchorEl?.current && !anchorEl?.current?.contains(event.target)) {
            close();
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [anchorEl]);

    if (isEmpty(fieldRelatedConditions)) return null;

    return (
        <>
            <button
                type="button"
                className={containerClassName}
                onClick={anchorEl ? close : open}
                onBlur={close}
                onMouseEnter={open}
                onMouseLeave={close}
                style={{
                    zIndex: Z_INDEX.BASE,
                    width: size,
                    height: size
                }}
            >
                <span className="icon-container">
                    <HiOutlineLink aria-hidden />
                </span>
                <Popper
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    className="signing-field-condition-popper"
                    style={{zIndex: Z_INDEX.TOOLTIP}}
                >
                    {content}
                </Popper>
            </button>

        </>
    );
};
export default ConditionIcon;
