/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';
import get from 'lodash.get';
import httpClient from '../../utils/httpClient';
import {
    BASE_PATH,
    PACKAGE_DIR_OPTIONS,
    PACKAGES_PER_PAGE_OPTIONS,
    PACKAGE_TYPES,
    PACKAGE_QUERY_OPTIONS,
    PACKAGE_SORT_OPTIONS,
    VISIBILITY_SENDER,
    PACKAGE_PREDEFINED_OPTIONS,
    VISIBILITY_ACCOUNT,
    DEFAULT_TEMPLATES_OPTIONS,
    FETCH_OPTIONS_STEP
} from '../../../constants';
import getValidPackagesParams from '../../utils/getValidPackagesParams';
import {createPackageRequest, sendBulk} from '../../utils/requests/packages';
import permissionsService from '../../utils/permissionsService';
import {getRecipientsEmailsFromPackage} from '../../utils/helpers';
// eslint-disable-next-line import/no-cycle
import {fetchAvatarsByEmails} from '../Packages/Recipients/recipientsSlice';
import {getErrorMessage} from '../../utils/requests/error';
import { fetchTransactionsSummary } from '../Transactions/transactionsSlice';

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        overview: {},
        lastTransactions: [],
        signerTransactions: [],
        visibility: {},
        auditEvents: {},
        templates: [],
        templatesCount: null,
        allTemplates: [],
        uploadErrors: [],
        remindersInfo: {},
        reminders: {},
        verifications: {},
        isOverviewFetching: false,
        fetchingOverviewError: null,
        isSavingDataAttr: false,
        savingDashboardTypeError: null,
        isLastTransactionsFetching: false,
        fetchingLastTransactionsError: null,
        isSignerTransactionsFetching: false,
        fetchingSignerTransactionsError: null,
        selectedTransactionId: null,
        isVisibilityFetching: false,
        fetchingVisibilityError: null,
        isTemplatesFetching: false,
        fetchingTemplatesError: null,
        isCreatingTransaction: false,
        creatingTransactionError: null,
        isAuditEventsFetching: false,
        isRemindersFetching: false,
        packageSettings: {}
    },
    reducers: {
        setOverview: (state, action) => {
            state.overview = action.payload;
        },
        setLastTransactions: (state, action) => {
            state.lastTransactions = action.payload;
        },
        setSignerTransactions: (state, action) => {
            state.signerTransactions = action.payload;
        },
        setVisibility: (state, action) => {
            state.visibility = action.payload;
        },
        setAuditEvents: (state, action) => {
            state.auditEvents = {
                ...state.auditEvents,
                ...action.payload
            };
        },
        setIsOverviewFetching: (state, action) => {
            state.isOverviewFetching = action.payload;
        },
        setFetchingOverviewError: (state, action) => {
            state.fetchingOverviewError = action.payload;
        },
        setIsSavingDataAttr: (state, action) => {
            state.isSavingDataAttr = action.payload;
        },
        setSavingDashboardTypeError: (state, action) => {
            state.savingDashboardTypeError = action.payload;
        },
        setIsLastTransactionsFetching: (state, action) => {
            state.isLastTransactionsFetching = action.payload;
        },
        setFetchingLastTransactionsError: (state, action) => {
            state.fetchingLastTransactionsError = action.payload;
        },
        setIsSignerTransactionsFetching: (state, action) => {
            state.isSignerTransactionsFetching = action.payload;
        },
        setFetchingSignerTransactionsError: (state, action) => {
            state.fetchingSignerTransactionsError = action.payload;
        },
        setSelectedTransactionId: (state, action) => {
            state.selectedTransactionId = action.payload;
        },
        setIsVisibilityFetching: (state, action) => {
            state.isVisibilityFetching = action.payload;
        },
        setFetchingVisibilityError: (state, action) => {
            state.fetchingVisibilityError = action.payload;
        },
        setTemplates: (state, action) => {
            const {payload} = action;
            let updatedPayload = [...state.templates];
            if (payload.reset) {
                updatedPayload = [];
            } else {
                updatedPayload.splice(payload.from - 1, payload.data.length, ...payload.data);
            }
            state.templates = updatedPayload;
        },
        setTemplatesCount: (state, action) => {
            state.templatesCount = action.payload;
        },
        setAllTemplates: (state, action) => {
            state.allTemplates = action.payload;
        },
        setIsTemplatesFetching: (state, action) => {
            state.isTemplatesFetching = action.payload;
        },
        setFetchingTemplatesError: (state, action) => {
            state.fetchingTemplatesError = action.payload;
        },
        setIsCreatingTransaction: (state, action) => {
            state.isCreatingTransaction = action.payload;
        },
        setCreatingTransactionError: (state, action) => {
            state.creatingTransactionError = action.payload;
        },
        setUploadErrors: (state, action) => {
            state.uploadErrors = action.payload;
        },
        setIsAuditEventsFetching: (state, action) => {
            state.isAuditEventsFetching = action.payload;
        },
        setRemindersInfo: (state, action) => {
            const {
                packageId,
                roleId
            } = action.payload;
            const transactionState = get(state, `remindersInfo[${packageId}]`, {});
            const roleState = get(state, `remindersInfo[${packageId}][${roleId}]`, {});

            state.remindersInfo = {
                ...state.remindersInfo,
                [packageId]: {
                    ...transactionState,
                    [roleId]: {
                        ...roleState,
                        ...action.payload
                    }
                }
            };
        },

        setIsRemindersFetching: (state, action) => {
            state.isRemindersFetching = action.payload;
        },
        setReminders: (state, action) => {
            const {
                packageId,
                reminders
            } = action.payload;
            state.reminders = {
                ...state.auditEvents,
                [packageId]: reminders
            };
        },
        setPackageSettings: (state, action) => {
            state.packageSettings = action.payload;
        }
    }
});

export const {
    setOverview,
    setLastTransactions,
    setVisibility,
    setAuditEvents,
    setIsOverviewFetching,
    setFetchingOverviewError,
    setIsSavingDataAttr,
    setSavingDashboardTypeError,
    setIsLastTransactionsFetching,
    setFetchingLastTransactionsError,
    setSelectedTransactionId,
    setIsVisibilityFetching,
    setFetchingVisibilityError,
    setTemplates,
    setTemplatesCount,
    setIsTemplatesFetching,
    setFetchingTemplatesError,
    setIsCreatingTransaction,
    setCreatingTransactionError,
    setUploadErrors,
    setSignerTransactions,
    setIsSignerTransactionsFetching,
    setFetchingSignerTransactionsError,
    setIsAuditEventsFetching,
    setRemindersInfo,
    setReminders,
    setIsRemindersFetching,
    setPackageSettings,
    setAllTemplates
} = dashboardSlice.actions;

export const fetchOverview = ({
    headers, baseUrl
} = {}) => async (dispatch) => {
    const servicePath = 'packages/overview';
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {
        params: {
            startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
        }
    };

    if (headers) {
        options.headers = headers;
    }
    dispatch(setFetchingOverviewError(null));
    dispatch(setIsOverviewFetching(true));

    try {
        const result = await httpClient.get(url, options);

        dispatch(setIsOverviewFetching(false));
        dispatch(setOverview(result.data));
        return true;
    } catch (err) {
        dispatch(setIsOverviewFetching(false));
        dispatch(setFetchingOverviewError(getErrorMessage(err)));
        return false;
    }
};

export const fetchLastTransactions = ({
    headers, baseUrl
} = {}) => async (dispatch) => {
    const servicePath = 'packages';
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {
        params: getValidPackagesParams({
            query: PACKAGE_QUERY_OPTIONS.INBOX,
            type: PACKAGE_TYPES.PACKAGE,
            from: 1,
            to: PACKAGES_PER_PAGE_OPTIONS[5],
            sort: PACKAGE_SORT_OPTIONS.UPDATED,
            dir: PACKAGE_DIR_OPTIONS.DESC
        })
    };
    if (headers) {
        options.headers = headers;
    }

    dispatch(setIsLastTransactionsFetching(true));
    dispatch(setFetchingLastTransactionsError(null));

    try {
        const result = await httpClient.get(url, options);
        const lastTransactions = get(result, 'data.results', []);
        const recipientEmails = getRecipientsEmailsFromPackage(lastTransactions);
        await dispatch(fetchAvatarsByEmails({baseUrl, headers, emails: recipientEmails}));
        dispatch(setLastTransactions(lastTransactions));
        dispatch(setIsLastTransactionsFetching(false));
        dispatch(setFetchingLastTransactionsError(null));
        return true;
    } catch (err) {
        dispatch(setIsLastTransactionsFetching(false));
        dispatch(setFetchingLastTransactionsError(getErrorMessage(err)));
        return false;
    }
};

export const fetchSignerTransactions = ({
    headers, baseUrl
} = {}) => async (dispatch) => {
    const servicePath = 'packages';
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {
        params: getValidPackagesParams({
            query: PACKAGE_QUERY_OPTIONS.INBOX,
            type: PACKAGE_TYPES.PACKAGE,
            from: 1,
            to: PACKAGES_PER_PAGE_OPTIONS[3],
            sort: PACKAGE_SORT_OPTIONS.DUE,
            dir: PACKAGE_DIR_OPTIONS.DESC,
            predefined: PACKAGE_PREDEFINED_OPTIONS.AWAITING_SIGNATURE
        })
    };
    if (headers) {
        options.headers = headers;
    }

    dispatch(setIsSignerTransactionsFetching(true));
    dispatch(setFetchingSignerTransactionsError(null));

    try {
        const result = await httpClient.get(url, options);
        const sortedbyDue = get(result, 'data.results', [])
            .sort((a, b) => {
                if (!a.due) {
                    return 1;
                }
                return moment(a.due).valueOf() - moment(b.due).valueOf();
            });

        dispatch(setSignerTransactions(sortedbyDue));
        dispatch(setIsSignerTransactionsFetching(false));
        dispatch(setFetchingSignerTransactionsError(null));
        return true;
    } catch (err) {
        dispatch(setIsSignerTransactionsFetching(false));
        dispatch(setFetchingSignerTransactionsError(getErrorMessage(err)));
        return false;
    }
};

export const fetchVisibility = (packageId) => async (dispatch, getState) => {
    const {dashboard} = getState();
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/visibility`;

    dispatch(setIsVisibilityFetching(true));
    dispatch(setFetchingVisibilityError(null));

    try {
        const result = await httpClient.get(url);
        const configurations = get(result, 'data.configurations', []);

        dispatch(setVisibility({
            ...dashboard.visibility,
            [packageId]: configurations
        }));
        dispatch(setIsVisibilityFetching(false));
        dispatch(setFetchingVisibilityError(null));
        return true;
    } catch (err) {
        dispatch(setIsVisibilityFetching(false));
        dispatch(setFetchingVisibilityError(getErrorMessage(err)));
        return false;
    }
};

export const fetchAudit = (packageId) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/audit`;
    dispatch(setIsAuditEventsFetching(true));

    try {
        const result = await httpClient.get(url);
        const auditEvents = get(result, 'data["audit-events"]', []);

        await dispatch(setAuditEvents({
            [packageId]: auditEvents
        }));
        dispatch(setIsAuditEventsFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsAuditEventsFetching(false));
        return false;
    }
};

export const fetchReminders = (packageId) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/reminders`;
    dispatch(setIsRemindersFetching(true));

    try {
        const result = await httpClient.get(url);
        dispatch(setIsRemindersFetching(false));
        dispatch(setReminders({
            packageId,
            reminders: get(result, 'data.reminders', [])
        }));
        return true;
    } catch (err) {
        dispatch(setIsRemindersFetching(false));
        return false;
    }
};

export const sendReminder = (packageId, roleId) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/roles/${roleId}/notifications`;

    dispatch(setRemindersInfo({
        packageId,
        roleId,
        wasSaccessful: false,
        isSending: true,
        error: null
    }));

    try {
        const wasSuccessful = await httpClient.post(url);
        dispatch(setRemindersInfo({
            packageId,
            roleId,
            wasSaccessful: !!wasSuccessful,
            isSending: false,
            error: null
        }));

        return true;
    } catch (err) {
        dispatch(setRemindersInfo({
            packageId,
            roleId,
            wasSaccessful: false,
            isSending: false,
            error: getErrorMessage(err)
        }));

        return false;
    }
};

export const saveDataAttr = (data) => async (dispatch, getState) => {
    const {user} = getState();
    const payload = {data};

    dispatch(setIsSavingDataAttr(true));
    dispatch(setSavingDashboardTypeError(null));

    try {
        await httpClient.post(`${BASE_PATH}/proxy/account/senders/${user.user.id}`, payload);
        dispatch(setIsSavingDataAttr(false));
        dispatch(setSavingDashboardTypeError(null));
        return true;
    } catch (err) {
        dispatch(setIsSavingDataAttr(false));
        dispatch(setSavingDashboardTypeError(getErrorMessage(err)));
        return false;
    }
};

export const fetchTemplates = ({
    headers, baseUrl, params = {}, skipAvatars
} = {}) => async (dispatch, getState) => {
    const servicePath = 'packages';
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;

    const options = {
        params: getValidPackagesParams({
            ...DEFAULT_TEMPLATES_OPTIONS,
            type: PACKAGE_TYPES.TEMPLATE,
            from: params.from || 1,
            to: params.to || FETCH_OPTIONS_STEP,
            visibility: params.visibility
        })
    };

    if (headers) {
        options.headers = headers;
    }
    if (params.search) {
        options.params.search = params.search;
    }

    dispatch(setIsTemplatesFetching(true));
    dispatch(setFetchingTemplatesError(null));

    try {
        const result = await httpClient.get(url, options);
        const resultTemplates = get(result, 'data.results', []);
        const recipientEmails = getRecipientsEmailsFromPackage(resultTemplates);

        if (!skipAvatars) {
            await dispatch(fetchAvatarsByEmails({baseUrl, headers, emails: recipientEmails}));
        }

        if (!params.shouldSkipTemplatesSet) {
            dispatch(setTemplates({from: params.from, data: resultTemplates}));
            dispatch(setTemplatesCount(get(result, 'data.count', null)));
        }
        dispatch(setIsTemplatesFetching(false));
        dispatch(setFetchingTemplatesError(null));
        return resultTemplates;
    } catch (err) {
        dispatch(setIsTemplatesFetching(false));
        dispatch(setFetchingTemplatesError(getErrorMessage(err)));
        return false;
    }
};

export const createTransaction = ({
    transaction, template, documents, bulkCSV
}) => async (dispatch, getState) => {
    const {app, user, dashboard} = getState();
    const isTemplateBulkSendable = get(template, 'bulkSendable', false) && get(user.features, 'bulkSend', false) && !!bulkCSV;
    const defaultData = {
        autocomplete: true,
        data: {senderVisible: false, origin: 'validsign'},
        description: '',
        due: isTemplateBulkSendable ? '' : null,
        emailMessage: '',
        language: app.locale,
        name: '',
        notarized: false,
        settings: {ceremony: dashboard.packageSettings},
        timezoneId: user.user.timezoneId,
        type: PACKAGE_TYPES.PACKAGE,
        visibility: VISIBILITY_SENDER
    };

    dispatch(setIsCreatingTransaction(true));
    dispatch(setCreatingTransactionError(null));

    try {
        let transactionId = null;
        if (isTemplateBulkSendable) {
            transactionId = await sendBulk({
                defaultData, template, packageData: transaction, bulkCSV
            });
        } else {
            transactionId = await createPackageRequest({
                defaultData,
                template,
                packageData: transaction,
                documents,
                packageSettings: dashboard.packageSettings,
                user: user.user,
                onDocumentsUpload: (errors) => {
                    dispatch(setUploadErrors(errors));
                }
            });
        }

        dispatch(setIsCreatingTransaction(false));
        dispatch(setCreatingTransactionError(null));
        dispatch(fetchTransactionsSummary());

        return transactionId;
    } catch (err) {
        dispatch(setIsCreatingTransaction(false));
        if (!isTemplateBulkSendable) {
            dispatch(setCreatingTransactionError(getErrorMessage(err)));
        } else {
            try {
                const error = JSON.parse(err.data.replace(/<pre>|<\/pre>/g, ''));
                dispatch(setCreatingTransactionError(error));
            } catch (e) {
                dispatch(setCreatingTransactionError([getErrorMessage(err)]));
            }
        }

        return false;
    }
};

export const fetchPackageSettings = ({
    headers, baseUrl
} = {}) => async (dispatch) => {
    const servicePath = 'packages/settings';
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {};

    if (headers) {
        options.headers = headers;
    }

    try {
        const result = await httpClient.get(url, options);
        const ceremony = get(result, 'data.ceremony', {});
        dispatch(setPackageSettings(ceremony));
        return true;
    } catch (err) {
        return false;
    }
};

export const selectOverview = (state) => state.dashboard.overview;
export const selectIsOverviewFetching = (state) => state.dashboard.isOverviewFetching;
export const selectFetchingOverviewError = (state) => state.dashboard.fetchingOverviewError;
export const selectIsSavingDataAttr = (state) => state.dashboard.isSavingDataAttr;
export const selectLastTransactions = (state) => state.dashboard.lastTransactions;
export const selectIsLastTransactionsFetching = (state) => state
    .dashboard.isLastTransactionsFetching;
export const selectFetchingLastTransactionsError = (state) => state
    .dashboard.fetchingLastTransactionsError;
export const selectVisibility = (state) => state.dashboard.visibility;
export const selectAuditEvents = (state) => state.dashboard.auditEvents;
export const selectSelectedTransactionId = (state) => state.dashboard.selectedTransactionId;
export const selectIsVisibilityFetching = (state) => state.dashboard.isVisibilityFetching;
export const selectTemplates = (state) => state.dashboard.templates;
export const selectTemplatesCount = (state) => state.dashboard.templatesCount;
export const selectAllTemplates = (state) => state.dashboard.allTemplates;
export const selectIsTemplatesFetching = (state) => state.dashboard.isTemplatesFetching;
export const selectIsCreatingTransaction = (state) => state.dashboard.isCreatingTransaction;
export const selectCreatingTransactionError = (state) => state.dashboard.creatingTransactionError;
export const selectUploadErrors = (state) => state.dashboard.uploadErrors;
export const selectSignerTransactions = (state) => state.dashboard.signerTransactions;
export const selectIsSignerTransactionsFetching = (state) => state
    .dashboard.isSignerTransactionsFetching;
export const selectFetchingSignerTransactionsError = (state) => state
    .dashboard.fetchingSignerTransactionsError;
export const selectIsAuditEventsFetching = (state) => state.dashboard.isAuditEventsFetching;
export const selectRemindersInfo = (state) => state.dashboard.remindersInfo;
export const selectReminders = (state) => state.dashboard.reminders;
export const selectIsRemindersFetching = (state) => state.dashboard.isRemindersFetching;
export const selectPackageSettings = (state) => state.dashboard.packageSettings;

export default dashboardSlice.reducer;
