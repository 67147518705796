/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import httpClient from '../../utils/httpClient';
import {BASE_PATH} from '../../../constants';
import {getErrorMessage} from '../../utils/requests/error';

export const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        senderSummary: [],
        transactionsSummary: [],
        transactionDetails: [],
        senderSummaryCount: 0,
        transactionsSummaryCount: 0,
        isSenderSummaryFetching: false,
        senderSummaryFetchingError: null,
        isTransactionsSummaryFetching: false,
        transactionsSummaryFetchingError: null
    },
    reducers: {
        setSenderSummary: (state, action) => {
            state.senderSummary = action.payload;
        },
        setSenderSummaryCount: (state, action) => {
            state.senderSummaryCount = action.payload;
        },
        setIsSenderSummaryFetching: (state, action) => {
            state.isSenderSummaryFetching = action.payload;
        },
        setSenderSummaryFetchingError: (state, action) => {
            state.senderSummaryFetchingError = action.payload;
        },
        setTransactionsSummary: (state, action) => {
            state.transactionsSummary = action.payload;
        },
        setTransactionsSummaryCount: (state, action) => {
            state.transactionsSummaryCount = action.payload;
        },
        setIsTransactionsSummaryFetching: (state, action) => {
            state.isTransactionsSummaryFetching = action.payload;
        },
        setTransactionsSummaryFetchingError: (state, action) => {
            state.transactionsSummaryFetchingError = action.payload;
        },
        setTransactionDetails: (state, action) => {
            state.transactionDetails = action.payload;
        }
    }
});

export const {
    setSenderSummary,
    setSenderSummaryCount,
    setIsSenderSummaryFetching,
    setSenderSummaryFetchingError,
    setTransactionsSummary,
    setTransactionsSummaryCount,
    setIsTransactionsSummaryFetching,
    setTransactionsSummaryFetchingError,
    setTransactionDetails
} = reportsSlice.actions;

export const fetchSenderTransactionSummary = (params) => async (dispatch) => {
    const servicePath = 'reports/sender-transaction-summary';
    const url = `${BASE_PATH}/proxy/${servicePath}`;
    const {
        senderID,
        startDate,
        endDate,
        from,
        to
    } = params;

    const options = {
        params: {
            senderID,
            startDate,
            endDate,
            from,
            to
        }
    };

    dispatch(setIsSenderSummaryFetching(true));
    dispatch(setSenderSummaryFetchingError(null));

    try {
        const result = await httpClient.get(url, options);

        dispatch(setSenderSummary(get(result, 'data.senders', [])));
        dispatch(setSenderSummaryCount(get(result, 'data.totalCount', 0)));
        dispatch(setIsSenderSummaryFetching(false));
        return get(result, 'data.senders', []);
    } catch (err) {
        dispatch(setIsSenderSummaryFetching(false));
        dispatch(setSenderSummaryFetchingError(getErrorMessage(err)));
        return false;
    }
};

export const fetchTransactionsSummary = (params) => async (dispatch) => {
    const servicePath = 'reports/transaction-summary';
    const url = `${BASE_PATH}/proxy/${servicePath}`;
    const {
        startDate,
        endDate,
        status,
        senderId,
        from,
        to
    } = params;

    const options = {
        params: {
            startDate,
            endDate,
            status,
            senderId,
            from,
            to
        }
    };

    dispatch(setIsTransactionsSummaryFetching(true));
    dispatch(setTransactionsSummaryFetchingError(null));

    try {
        const result = await httpClient.get(url, options);

        dispatch(setTransactionsSummary(get(result, 'data.content', [])));
        dispatch(setTransactionsSummaryCount(get(result, 'data.totalElements', 0)));
        dispatch(setIsTransactionsSummaryFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsTransactionsSummaryFetching(false));
        dispatch(setTransactionsSummaryFetchingError(getErrorMessage(err)));
        return false;
    }
};

export const fetchTransactionDetail = (id) => async (dispatch, getState) => {
    const {reports} = getState();
    const url = `${BASE_PATH}/proxy/reports/transaction-detail/${id}`;

    try {
        const result = await httpClient.get(url);

        dispatch(setTransactionDetails({
            ...reports.transactionDetails,
            [id]: get(result, 'data', [])
        }));
        return true;
    } catch (err) {
        return false;
    }
};

export const fetchUsageSummaryCSV = ({startDate, endDate}) => async (dispatch, getState) => {
    const url = `${BASE_PATH}/proxy/reports/usage`;
    const options = {
        headers: {
            Accept: 'text/csv',
            'Content-Type': 'text/csv'
        }
    };

    if (!!startDate && !!endDate) {
        options.params = {
            from: startDate,
            to: endDate
        };
    }

    try {
        const result = await httpClient.get(url, options);

        return result.data;
    } catch (err) {
        return false;
    }
};

export const selectSenderSummary = (state) => state.reports.senderSummary;
export const selectSenderSummaryCount = (state) => state.reports.senderSummaryCount;
export const selectIsSenderSummaryFetching = (state) => state.reports.isSenderSummaryFetching;
export const selectSenderSummaryFetchingError = (state) => state.reports.senderSummaryFetchingError;
export const selectTransactionsSummary = (state) => state.reports.transactionsSummary;
export const selectTransactionsSummaryCount = (state) => state.reports.transactionsSummaryCount;
export const selectIsTransactionsSummaryFetching = (state) => state.reports.isTransactionsSummaryFetching;
export const selectTransactionsSummaryFetchingError = (state) => state.reports.transactionsSummaryFetchingError;
export const selectTransactionDetails = (state) => state.reports.transactionDetails;

export default reportsSlice.reducer;
