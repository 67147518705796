import React from 'react';
import {useIntl} from 'react-intl';
import Collapse from '@material-ui/core/Collapse';
import {HiOutlinePhotograph} from '@react-icons/all-files/hi/HiOutlinePhotograph';
import classnames from 'classnames';
import Loading from '../../../../shared/Loading/Loading';
import useAttachmentPreview from '../../../hooks/useAttachmentPreview';
import './attachmentFilePreview.less';

export default function AttachmentFilePreview({id, isOpen, hasPreview}) {
    const intl = useIntl();
    const {attachmentFilePreview, error} = useAttachmentPreview(id);
    const preview = attachmentFilePreview?.content;
    const shouldShowPreview = hasPreview && !error;
    const className = classnames('uploaded-file-preview', {
        'has-preview': shouldShowPreview
    });

    return (
        <div className="uploaded-file-preview-container">
            <Collapse
                in={isOpen}
                timeout="auto"
                unmountOnExit
            >
                <div className={className}>
                    {shouldShowPreview ? (
                        <>
                            {preview ? (
                                <img
                                    src={`data:image/png;base64,${preview}`}
                                    alt="Preview of attached file"
                                    className="preview-img"
                                />
                            ) : (
                                <Loading isLoading className="preview-loading" />
                            )}
                        </>
                    )
                        : (
                            <div className="no-preview-container">
                                <div className="no-preview-icon-container">
                                    <HiOutlinePhotograph className="no-preview-icon" />
                                </div>
                                <div className="no-preview-message">
                                    {intl.formatMessage({id: 'oss.components.AttachmentFilePreview.noPreview', defaultMessage: ''})}
                                </div>
                            </div>
                        )}
                </div>
            </Collapse>
        </div>
    );
}
