/* eslint-disable react/jsx-no-useless-fragment */
import {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {fetchSessionInfo} from './Login/userSlice';
import {LOGIN_PATH} from '../../constants';

export default function PrivateRoute({children, isPrivate}) {
    const dispatch = useDispatch();
    const [isAuthenticated, setisAuthenticated] = useState(true);

    useEffect(() => {
        (async () => {
            if (isPrivate) {
                const wasSuccessful = await dispatch(fetchSessionInfo());
                if (wasSuccessful) {
                    setisAuthenticated(true);
                }
            }
        })();
    }, []);

    return isAuthenticated || !isPrivate ? children : <Navigate to={LOGIN_PATH} />;
}
