import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import httpClient from '../../../utils/httpClient';
import {
    BASE_PATH
} from '../../../../constants';
import {getErrorMessage} from '../../../utils/requests/error';

export const themesAndStylesSlice = createSlice({
    name: 'themes',
    initialState: {
        signingThemes: {},
        isSigningThemesFetching: false,
        signingThemesFetchingError: null,
        isSigningThemesSaving: false,
        signingThemesSavingError: null,
        signingLogos: [],
        isSigningLogosFetching: false,
        signingLogosFetchingError: null,
        isSigningLogosSaving: false,
        signingLogosSavingError: null,
        isSigningLogosReseting: false,
        signingLogosResetingError: null
    },
    reducers: {
        setSigningThemes: (state, action) => {
            state.signingThemes = action.payload;
        },
        setIsSigningThemesFetching: (state, action) => {
            state.isSigningThemesFetching = action.payload;
        },
        setSigningThemesFetchingError: (state, action) => {
            state.signingThemesFetchingError = action.payload;
        },
        setIsSigningThemesSaving: (state, action) => {
            state.isSigningThemesSaving = action.payload;
        },
        setSigningThemesSavingError: (state, action) => {
            state.signingThemesSavingError = action.payload;
        },
        setSigningLogos: (state, action) => {
            state.signingLogos = action.payload;
        },
        setIsSigningLogosFetching: (state, action) => {
            state.isSigningLogosFetching = action.payload;
        },
        setSigningLogosFetchingError: (state, action) => {
            state.signingLogosFetchingError = action.payload;
        },
        setIsSigningLogosSaving: (state, action) => {
            state.isSigningLogosSaving = action.payload;
        },
        setSigningLogosSavingError: (state, action) => {
            state.signingLogosSavingError = action.payload;
        },
        setIsSigningLogosReseting: (state, action) => {
            state.isSigningLogosReseting = action.payload;
        },
        setSigningLogosResetingError: (state, action) => {
            state.signingLogosResetingError = action.payload;
        }
    }
});

export const {
    setSigningThemes,
    setIsSigningThemesFetching,
    setSigningThemesFetchingError,
    setIsSigningThemesSaving,
    setSigningThemesSavingError,
    setSigningLogos,
    setIsSigningLogosFetching,
    setSigningLogosFetchingError,
    setIsSigningLogosSaving,
    setSigningLogosSavingError,
    setIsSigningLogosReseting,
    setSigningLogosResetingError
} = themesAndStylesSlice.actions;

export const fetchSigningThemes = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/signingThemes`;
    dispatch(setIsSigningThemesFetching(true));
    dispatch(setSigningThemesFetchingError(null));

    try {
        const result = await httpClient.get(url);
        dispatch(setSigningThemes(get(result, 'data.default.color', {})));
        dispatch(setIsSigningThemesFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsSigningThemesFetching(false));
        dispatch(setSigningThemesFetchingError(getErrorMessage(err)));
        return false;
    }
};

export const saveSigningThemes = (signingThemes) => async (dispatch, getState) => {
    const url = `${BASE_PATH}/proxy/account/signingThemes`;
    const {signingThemes: storredSigningThemes} = getState().themes;
    dispatch(setIsSigningThemesSaving(true));
    dispatch(setSigningThemesSavingError(null));

    try {
        const result = isEmpty(storredSigningThemes)
            ? await httpClient.post(url, signingThemes)
            : await httpClient.put(url, signingThemes);
        dispatch(setSigningThemes(get(result, 'data.default.color', {})));
        dispatch(setIsSigningThemesSaving(false));
        return true;
    } catch (err) {
        dispatch(setIsSigningThemesSaving(false));
        dispatch(setSigningThemesSavingError(getErrorMessage(err)));
        return false;
    }
};

export const resetSigningThemes = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/signingThemes`;
    dispatch(setIsSigningLogosReseting(true));
    dispatch(setSigningLogosResetingError(null));

    try {
        await httpClient.delete(url);
        dispatch(setSigningThemes({}));
        dispatch(setIsSigningLogosReseting(false));
        return true;
    } catch (err) {
        dispatch(setIsSigningLogosReseting(false));
        dispatch(setSigningLogosResetingError(getErrorMessage(err)));
        return false;
    }
};

export const fetchLogos = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/admin/signingLogos`;
    dispatch(setIsSigningLogosFetching(true));
    dispatch(setSigningLogosFetchingError(null));

    try {
        const result = await httpClient.get(url);
        dispatch(setSigningLogos(get(result, 'data', {})));
        dispatch(setIsSigningLogosFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsSigningLogosFetching(false));
        dispatch(setSigningLogosFetchingError(getErrorMessage(err)));
        return false;
    }
};

export const saveLogos = (logos) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/admin/signingLogos`;
    dispatch(setIsSigningLogosSaving(true));
    dispatch(setSigningLogosSavingError(null));

    try {
        await httpClient.post(url, logos);
        await dispatch(fetchLogos());
        dispatch(setIsSigningLogosSaving(false));
        return true;
    } catch (err) {
        dispatch(setIsSigningLogosSaving(false));
        dispatch(setSigningLogosSavingError(getErrorMessage(err)));
        return false;
    }
};

export const selectSigningThemes = (state) => state.themes.signingThemes;
export const selectIsSigningThemesFetching = (state) => state.themes.setIsSigningThemesFetching;
export const selectSigningThemesFetchingError = (state) => state.themes.setSigningThemesFetchingError;
export const selectSigningLogos = (state) => state.themes.signingLogos;
export const selectIsSigningLogosFetching = (state) => state.themes.isSigningLogosFetching;
export const selectSigningLogosFetchingError = (state) => state.themes.signingLogosFetchingError;
export const selectIsSigningThemesSaving = (state) => state.themes.isSigningThemesSaving;
export const selectSigningThemesSavingError = (state) => state.themes.signingThemesSavingError;
export const selectIsSigningLogosSaving = (state) => state.themes.isSigningLogosSaving;
export const selectSigningLogosSavingError = (state) => state.themes.signingLogosSavingError;

export default themesAndStylesSlice.reducer;
