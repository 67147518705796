import React from 'react';
import {Helmet} from 'react-helmet';
import {useIntl} from 'react-intl';

export default function PageTitle({pageTitle}) {
    const intl = useIntl();
    const brandTitle = intl.formatMessage({id: 'page.title', defaultMessage: ''});
    const title = pageTitle ? `${pageTitle} - ${brandTitle}` : brandTitle;

    return (
        <Helmet>
            <title>
                {title}
            </title>
        </Helmet>
    );
}
