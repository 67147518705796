import {Dialog} from '@components/Core/company/modals/Dialog';
import {isMobile, isIOS} from 'react-device-detect';
import {enterFullScreen, exitFullscreen} from '@components/AccountSettings/CaptureSignature/utils/fullscreen';
import {useEffect, useState} from 'react';
import {PerfectSignature} from '../PerfectSignature/PerfectSignature';
import {useScreenOrientation} from '../../../../reusable/hooks/useScreenOrientation';
import './perfectSignatureModal.less';

export const PerfectSignatureModal = ({
    isOpen,
    exportSvgRef,
    svgRef,
    close,
    actions,
    perfectSignatureProps
}) => {
    const {orientation} = useScreenOrientation();
    const [wasRendered, setWasRendered] = useState(false);

    useEffect(() => {
        if (wasRendered && isOpen && isMobile) {
            enterFullScreen(isIOS);
            if (screen.orientation && screen.orientation.lock) {
                screen.orientation.lock('portrait').catch((error) => {
                    console.error('Failed to lock screen orientation:', error);
                });
            }
        } else {
            exitFullscreen();
            if (screen.orientation && screen.orientation.unlock) {
                screen.orientation.unlock();
            }
        }
    }, [wasRendered, isOpen, orientation]);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={close}
            onRendered={() => setWasRendered(true)}
            className="perfect-signature-modal"
            content={(
                <PerfectSignature
                    ref={svgRef}
                    exportSvgRef={exportSvgRef}
                    actions={actions}
                    {...perfectSignatureProps}
                />
            )}
        />
    );
};
