import React, {forwardRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import {KeyboardDatePicker} from '@material-ui/pickers';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import {Z_INDEX} from '../../../../../../constants';
import {getIsExampleTransactionID} from '@components/SignerUi/utils/functions';
import {selectTransactionId} from '@components/SignerUi/signerUiSlice';

function getFormattedValue(value, format) {
    return value ? moment(value, format).format(format) : null;
}

const DateField = forwardRef(({
    originalField,
    field,
    onChangeField,
    className,
    setIsFocused,
    setHasInteracted,
    selectField,
    unselectField,
    updateFieldValue,
    errorMessage,
    showErrorTooltip,
    fieldsRef,
    loading,
    onFocus: onFocusProp = () => {}

}, ref) => {
    const {isDisabled, format} = field;
    const [value, setValue] = useState(null);
    const classNameContainer = classNames(className, 'date');
    const transactionId = useSelector(selectTransactionId);
    const isExampleTransactionID = getIsExampleTransactionID(transactionId);

    function getErrorMessage() {
        if (errorMessage) {
            return errorMessage;
        }
        if (value && format && !moment(value, format).isValid()) {
            return {
                message: 'esl.error.validation.date.invalidFormat', type: 'invalid', field, approvalId: field.approval.originalId
            };
        }
    }
    const errMessage = getErrorMessage();
    const shouldShowErrorTooltip = showErrorTooltip && !!errMessage;

    async function handleSave(date) {
        setHasInteracted(true);
        
        if (!isExampleTransactionID) {
            const {data} = await updateFieldValue(date || '');
            setValue(data?.updatedField?.value);
        }
    }

    function handleChange(date, inputValue) {
        setValue(inputValue);
    }

    function handleFieldChange() {
        onChangeField({name: 'value', value});
    }
    function handleAccept(date) {
        setHasInteracted(true);
        setIsFocused(false);
        handleFieldChange();
        handleSave(getFormattedValue(date, format));
    }

    function onFocus() {
        setIsFocused(true);
        onFocusProp();
    }

    function onBlur() {
        setHasInteracted(true);
        setIsFocused(false);
        const dateChanged = (originalField.value || value) && !moment(originalField.value).isSame(value);
        if (!dateChanged) return;
        handleFieldChange();
        handleSave(value);
    }

    useEffect(() => {
        setValue(originalField.value);
    }, []);

    return (

        <FieldWrapper
            field={field}
            fieldsRef={fieldsRef}
            className={classNameContainer}
            onMouseEnter={selectField}
            onMouseLeave={unselectField}
            showErrorTooltip={shouldShowErrorTooltip}
            errorMessage={errMessage}
            loading={loading}
        >
            <span tabIndex={0} ref={ref} className="field-wrapper-span">
                <KeyboardDatePicker
                    format={format}
                    onAccept={handleAccept}
                    value={field.value || null}
                    inputValue={value || null}
                    onChange={handleChange}
                    placeholder={format}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    disabled={isDisabled}
                    DialogProps={{
                        style: {zIndex: Z_INDEX.TOOLTIP + 1}
                    }}
                    InputProps={{
                        disableUnderline: true
                    }}
                    helperText={null}
                />
            </span>
        </FieldWrapper>

    );
});

export default DateField;
