import React from 'react';
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {ToastContainer, Slide} from 'react-toastify';
import {DESIGNER_MOBILE_WIDTH, DESIGNER_PATH} from '../../../../constants';
import useWindowSize from '../../../hooks/useWindowSize';

export default function NotificationsHub() {
    const location = useLocation();
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < DESIGNER_MOBILE_WIDTH;
    const isMobileDesigner = isMobile && location.pathname?.includes(DESIGNER_PATH);
    const containerClassName = classNames('toast-notification-container', {
        'mobile-designer-toast': isMobileDesigner
    });
    return (
        <ToastContainer
            position={isMobileDesigner ? 'top-right' : 'bottom-left'}
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className={containerClassName}
            transition={Slide}
        />
    );
}
