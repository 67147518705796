import overviewSsrFetch from './Overview/ssrFetch';
import lastTransactionsSsrFetch from './LastTransactions/ssrFetch';
import newTransactionSsrFetch from './NewTransaction/ssrFetch';

export default function ssrFetch(options) {
    return Promise.all([
        overviewSsrFetch(options),
        lastTransactionsSsrFetch(options),
        newTransactionSsrFetch(options)
    ]);
}
