import React from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';
import {FiMinimize, FiMaximize} from 'react-icons/fi';
import {AppIconButton} from '../../../Core/buttons/AppIconButton/AppIconButton';
import {selectIsInterfaceHidden, setIsInterfaceHidden} from '../../signerUiSlice';
import './toggle.less';

export default function Toggle() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isInterfaceHidden = useSelector(selectIsInterfaceHidden);
    const Icon = isInterfaceHidden ? FiMaximize : FiMinimize;
    const message = isInterfaceHidden
        ? intl.formatMessage({id: 'signer.maximizeInterface', defaultMessage: ''})
        : intl.formatMessage({id: 'signer.minimizeInterface', defaultMessage: ''});
    const classNames = classnames('toggle-signer-interface-container', {
        'is-interface-hidden': isInterfaceHidden
    });

    function toggleInterface() {
        dispatch(setIsInterfaceHidden(!isInterfaceHidden));
    }

    return (
        <div className={classNames}>
            <AppIconButton
                className="toggle-signer-interface"
                onClick={toggleInterface}
            >
                <Icon className="toggle-icon" />
                <span className="toggle-label">
                    {message}
                </span>
            </AppIconButton>
        </div>
    );
}
