import React, {
    useRef,
    useEffect,
    useState,
    createRef
} from 'react';
import {useIntl} from 'react-intl';
import jQuery from 'jquery';
import {isMobile, isIOS} from 'react-device-detect';
import decodeCanvasContext from '../utils/decodeCanvasContext';
import useWindowSize from '../../../../hooks/useWindowSize';
import {ORIENTATION, useScreenOrientation} from '../../../../reusable/hooks/useScreenOrientation';
import {AppButton} from '../../../Core/buttons/AppButton/AppButton';
import {Dialog, DialogButton} from '../../../Core/company/modals/Dialog';
import {enterFullScreen, exitFullscreen} from '../utils/fullscreen';
import {getSignatureLengthPX} from '../helpers';

import './signatureModal.less';

const BORDER_WIDTH_PX = 2;
const MIN_SIGNATURE_LENGTH_PX = 20;

export default function SignatureModal({
    isOpen,
    close,
    save,
    signatureLineAspect = 0.18,
    saveBtnText = ''
}) {
    const intl = useIntl();
    const canvasEl = useRef(null);
    const modalPaperEl = useRef(null);
    const canvasContainerEl = useRef(null);
    const titleRef = createRef(null);
    const actionsRef = createRef(null);
    const contentRef = createRef(null);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [signatureLengthPX, setSignatureLengthPX] = useState(0);
    const {orientation} = useScreenOrientation();
    const windowSize = useWindowSize();
    const isRotated = orientation === ORIENTATION.PORTRAIT && isMobile;
    const signatureLineBottomOffset = height * signatureLineAspect;
    const [wasRendered, setWasRendered] = useState(false);
    const rotationAngleDeg = 90;
    const fullScreenStyles = {
        position: 'fixed',
        width: windowSize.width,
        height: windowSize.height,
        margin: 0,
        maxHeight: 'none',
        maxWidth: 'none'
    };
    const rotatedStyles = {
        ...fullScreenStyles,
        width: windowSize.height,
        height: windowSize.width,
        transform: `rotate(${rotationAngleDeg}deg)`
    };
    const mobileStyles = isRotated ? rotatedStyles : fullScreenStyles;
    const paperProps = {
        disablePortal: true,
        ref: modalPaperEl,
        style: isMobile ? mobileStyles : undefined
    };
    const isSignatureLengthValid = signatureLengthPX >= MIN_SIGNATURE_LENGTH_PX;

    function setSignatureLength() {
        const newSignatureLengthPX = getSignatureLengthPX(canvasContainerEl.current);

        setSignatureLengthPX(newSignatureLengthPX);
    }

    function saveSignature() {
        if (isSignatureLengthValid) {
            const context = jQuery(canvasContainerEl.current).signaturePad().getContext();
            const parsedSignature = decodeCanvasContext(context, 100);
            save(parsedSignature);
        }
    }

    function closeModal() {
        close();
        setWasRendered(false);
        setSignatureLengthPX(0);
    }

    function clear() {
        jQuery(canvasContainerEl.current).signaturePad().clearCanvas();
        setSignatureLength();
    }

    function renderSignaturePage() {
        const canvasWidth = width - BORDER_WIDTH_PX;
        const canvasHeight = height - BORDER_WIDTH_PX;
        const lineMarginPX = 80;
        const lineWidth = canvasWidth - lineMarginPX * 2;

        return (
            <div className="signature-page">
                <div ref={canvasContainerEl} className="signature-canvas-container">
                    <div className="line-overlay">
                        <span
                            className="signature-line"
                            style={{
                                bottom: signatureLineBottomOffset,
                                width: lineWidth
                            }}
                        />
                    </div>
                    <canvas
                        ref={canvasEl}
                        className="signature-canvas"
                        width={canvasWidth}
                        height={canvasHeight}
                    />
                </div>
            </div>
        );
    }

    async function renderSignatureCanvas() {
        if (canvasContainerEl.current) {
            jQuery(canvasContainerEl.current).signaturePad({
                drawOnly: true,
                penColour: '#000000',
                drawBezierCurves: true,
                variableStrokeWidth: false,
                lineWidth: 0,
                bezierSkip: 6,
                isRotated,
                onDrawEnd: setSignatureLength
            });
        }
    }

    function setCanvasDims() {
        if (wasRendered && modalPaperEl.current && titleRef.current && actionsRef.current) {
            const modalDims = modalPaperEl.current.getBoundingClientRect();
            const titleDims = titleRef.current.getBoundingClientRect();
            const actionsDims = actionsRef.current.getBoundingClientRect();
            const contentStyles = window.getComputedStyle(contentRef.current);
            const modalWidth = modalDims.width;
            const modalHeight = modalDims.height;
            const titleHeight = isRotated ? titleDims.width : titleDims.height;
            const actionsHeight = isRotated ? actionsDims.width : actionsDims.height;
            const contentVerticalPaddings = parseFloat(contentStyles.paddingTop) + parseFloat(contentStyles.paddingBottom);
            const contentHorizontalPaddings = parseFloat(contentStyles.paddingLeft) + parseFloat(contentStyles.paddingRight);
            const canvasWidth = isRotated
                ? modalHeight - contentHorizontalPaddings
                : modalWidth - contentHorizontalPaddings;
            const canvasHeight = isRotated
                ? modalWidth - titleHeight - actionsHeight - contentVerticalPaddings
                : modalHeight - titleHeight - actionsHeight - contentVerticalPaddings;

            setWidth(canvasWidth);
            setHeight(canvasHeight);
        }
    }

    useEffect(() => {
        if (wasRendered) {
            Promise.all([
                import(/* webpackChunkName: "signaturePad" */ '../utils/signaturePad/signaturePad'),
                import(/* webpackChunkName: "jSignature" */ 'jsignature/libs/jSignature.min')
            ]).then(() => {
                renderSignatureCanvas();
                setSignatureLength();
            });
        }
    }, [wasRendered, canvasContainerEl.current, orientation, height, width]);

    useEffect(() => {
        setCanvasDims();
    }, [
        wasRendered,
        isRotated,
        canvasContainerEl.current,
        modalPaperEl.current,
        titleRef.current,
        actionsRef.current,
        windowSize.width,
        windowSize.height
    ]);

    useEffect(() => {
        if (wasRendered && isOpen && isMobile) {
            enterFullScreen(isIOS);
        } else {
            exitFullscreen();
        }
    }, [wasRendered, isOpen, isRotated]);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={closeModal}
            onRendered={() => setWasRendered(true)}
            className="signature-modal"
            PaperProps={paperProps}
            title={intl.formatMessage({id: 'page.signature.draw', defaultMessage: ''})}
            content={renderSignaturePage()}
            titleProps={{ref: titleRef}}
            actionsProps={{ref: actionsRef}}
            contentProps={{ref: contentRef}}
            actions={(
                <>
                    <DialogButton onClick={closeModal}>
                        {intl.formatMessage({id: 'esl.generic.cancel', defaultMessage: ''})}
                    </DialogButton>
                    <DialogButton onClick={clear}>
                        {intl.formatMessage({id: 'esl.generic.clear', defaultMessage: ''})}
                    </DialogButton>
                    <AppButton onClick={saveSignature} disabled={!isSignatureLengthValid}>
                        {saveBtnText}
                    </AppButton>
                </>
            )}
        />
    );
}
