import {useIntl} from 'react-intl';
import {useEffect, useMemo, useState} from 'react';
import isFunction from 'lodash.isfunction';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import messages from '../../messages/fieldsMessages';
import DataIds from './utils/dataIds';
import {
    getApprovalRelatedErrors,
    getDocumentById,
    getFieldsByPages
} from '../../utils/selectors';
import {
    selectHoveredField, selectFieldsConditions, selectHoveredFieldInCondition, selectSelectedDocumentId
} from '../../signerUiSlice';
import {getFieldConditions} from '../utils';
import {useDocumentsWithPages} from '../../hooks/queries';

export const useSignatureMetadata = ({
    isClickToSign,
    isCaptureSig,
    isClickToInitial,
    isOptional,
    isMobileSig
}) => {
    const {formatMessage} = useIntl();

    const getAriaLabel = () => {
        let key;
        if (isOptional) {
            if (isClickToSign) {
                key = 'clickToSignOptional';
            } else if (isCaptureSig || isMobileSig) {
                key = 'captureSignatureOptional';
            } else if (isClickToInitial) {
                key = 'clickToInitialOptional';
            }
        } else if (isClickToSign) {
            key = 'clickToSign';
        } else if (isCaptureSig || isMobileSig) {
            key = 'captureSignature';
        } else if (isClickToInitial) {
            key = 'clickToInitial';
        }
        return key ? formatMessage(messages[key]) : '';
    };

    const getMessageAndTestId = () => {
        let signMessage;
        let testId;

        switch (true) {
            case isClickToInitial:
                signMessage = formatMessage(messages.clickToInitial);
                testId = isOptional
                    ? DataIds.Fields.Field.SignatureField.InitialOptionalButton.id
                    : DataIds.Fields.Field.SignatureField.InitialButton.id;
                break;
            case isCaptureSig:
            case isMobileSig:
                signMessage = formatMessage(messages.captureSignature);
                testId = isOptional
                    ? DataIds.Fields.Field.SignatureField.CaptureSignatureOptionalButton
                        .id
                    : DataIds.Fields.Field.SignatureField.CaptureSignatureButton.id;
                break;
            default:
                signMessage = formatMessage(messages.clickToSign);
                testId = isOptional
                    ? DataIds.Fields.Field.SignatureField.SignOptionalButton.id
                    : DataIds.Fields.Field.SignatureField.SignButton.id;
                break;
        }
        return {signMessage, testId};
    };
    const messageAndTestId = getMessageAndTestId();
    return {
        ariaLabel: getAriaLabel(),
        ...messageAndTestId
    };
};

export const useValidateSignature = ({approvalId, callback, fieldsErrors}) => {
    const documentId = useSelector(selectSelectedDocumentId);
    const [clickedApproval, setClickedApproval] = useState();
    const [isSubmitted, setIsSubmitted] = useState();
    const documentsWithPages = useDocumentsWithPages();
    const {transaction: {documents}} = documentsWithPages;
    const fields = useMemo(() => getFieldsByPages(getDocumentById(documents, documentId)).filter(
        (f) => f.approval.originalId === approvalId
    ), [documents, documentId, approvalId]);
    const [errors, setErrors] = useState(null);
    const getErrors = async (apprvlId) => {
        setClickedApproval(apprvlId);
        setIsSubmitted(true);
        return true;
    };

    useEffect(() => {
        const signatureFieldsErrors = getApprovalRelatedErrors({
            approvalId,
            fields,
            fieldsErrors
        });
        setErrors(signatureFieldsErrors);

        if (clickedApproval === approvalId && isSubmitted) {
            if (signatureFieldsErrors.length === 0 && isFunction(callback)) {
                callback();
            }
            setClickedApproval();
            setIsSubmitted(false);
        }
    }, [clickedApproval, fields, fieldsErrors, isSubmitted]);

    return {getErrors, fields, errors};
};

const validateFieldError = (field) => {
    const {
        isDateField,
        value,
        isRequired,
        format
    } = field;

    if (isRequired && !value) {
        return {
            message: 'esl.validation.required', type: 'required', field, approvalId: field.approval.originalId
        };
    }

    if (isDateField) {
        if (value && !moment(value, format).isValid()) {
            return {
                message: 'esl.error.validation.date.invalidFormat', type: 'invalid', field, approvalId: field.approval.originalId
            };
        }
    }

    return null;
};

export function getFieldsErrors(fields = []) {
    return fields.reduce((errors, field) => {
        const error = validateFieldError(field);

        if (error) {
            return {...errors, [field.id]: error};
        }

        if (!error && errors[field.id]) {
            const {[field.id]: removed, ...rest} = errors;
            return rest;
        }

        return errors;
    }, {});
}

function isConditionMakesFieldDisabled(field, condition) {
    const isDisabled = field.isConditionalTarget && (field.isDisabled || field.approval?.isDisabled);
    switch (condition.action.value) {
        case 'enable':
            return isDisabled;
        case 'disable':
            return isDisabled;
        default:
    }
    return false;
}
export const useFieldConditionsClassName = (field, hoveredField) => {
    const {hasConditions} = field;
    const fieldsConditions = useSelector(selectFieldsConditions);
    const fieldConditions = getFieldConditions({field, fieldsConditions});

    if (hasConditions) {
        const isConditionAffectingDisabledStatusOfActiveField = fieldConditions
            .some((condition) => condition.actionFields
                ?.some((fld) => fld.originalId === hoveredField.originalId) && isConditionMakesFieldDisabled(hoveredField, condition));

        return {
            'is-condition-makes-active-field-disabled': isConditionAffectingDisabledStatusOfActiveField
        };
    }
};
function checkValue(field) {
    if (field.isRadio) {
        const isChecked = field.radioGroup?.selectedFieldId === field.originalId;
        return isChecked;
    }

    return !!field.value;
}
export const useFieldClassName = (field, classes) => {
    const hoveredField = useSelector(selectHoveredField);
    const hoveredFieldInCondition = useSelector(selectHoveredFieldInCondition);
    const conditionsClassNames = useFieldConditionsClassName(field, hoveredField);

    const isAutofill = field.isAutofill || field.isCustomField;
    const showApprovalRelatedField = !isAutofill && hoveredField?.approvalId === field.approval.originalId
        && hoveredField?.originalId !== field.originalId;
    const newClass = classNames('signing-field-container', {
        'form-field': !field.isLabel && !field.isSignature && field.isInput,
        'signature-field': field.isSignature && !field.approval.isAccepted,
        'capture-signature': field.isSignature && field.isCaptureSig,
        autofill: isAutofill,
        required: field.isRequired,
        disabled: field.isDisabled,
        optional: field.approval?.isOptional,
        'highlight-approval-related-fields': showApprovalRelatedField,
        'is-hovering-approval': showApprovalRelatedField && hoveredField.isSignature,
        'is-hovering-field-from-condition': hoveredFieldInCondition.originalId === field.originalId,
        'has-value': checkValue(field),
        'has-conditions': field.hasConditions,
        'is-conditional-target': field.isConditionalTarget,
        ...conditionsClassNames,
        ...classes
    });

    return newClass;
};
