/* eslint-disable no-undef */
import React from 'react';
import ColorHash from 'color-hash';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import {
    HiOutlineCalendarDays,
    HiOutlineBuildingOffice2,
    HiBars3BottomLeft,
    HiOutlineIdentification
} from 'react-icons/hi2';
import {IoCheckboxOutline} from '@react-icons/all-files/io5/IoCheckboxOutline';
import {FiType} from '@react-icons/all-files/fi/FiType';
import {IoIosArrowDropdown} from '@react-icons/all-files/io/IoIosArrowDropdown';
import {IoMdRadioButtonOn} from '@react-icons/all-files/io/IoMdRadioButtonOn';
import {HiOutlineUser} from '@react-icons/all-files/hi/HiOutlineUser';
import {BiLabel} from '@react-icons/all-files/bi/BiLabel';
import {AiOutlineCheckSquare} from '@react-icons/all-files/ai/AiOutlineCheckSquare';
import {deltaE} from 'chroma-js';
import Signature2 from '../shared/icons/Signature2';
import {
    INHERIT_SIZE,
    FIELD_SUBTYPES,
    FIELD_TYPES,
    PACKAGE_TYPE_NAME,
    TEMPLATE_PATH,
    TRANSACTION_PATH,
    RECIPIENT_TYPES
} from '../../../constants';
import {getDefaultSignatureType} from './FieldsPanel/Fields/constants';
import {getPageFields} from './designerSlice';

export const DEFAULT_WIDTH = 180; // px
export const DEFAULT_HEIGHT = 40; // px
export const DEFAULT_SQUARE_WIDTH = 20; // px
export const DEFAULT_SQUARE_HEIGHT = DEFAULT_SQUARE_WIDTH;

export const DEFAULT_DATE_FIELD_VALUE = 'DD-MM-YYYY';

export const DATE_SETTINGS_LIST = [
    {
        key: 'DMY',
        label: 'esl.generic.date_format_DD_MM_YYYY',
        value: DEFAULT_DATE_FIELD_VALUE
    },
    {
        key: 'YMD',
        label: 'esl.generic.date_format_YYYY_MM_DD',
        value: 'YYYY-MM-DD'
    },
    {
        key: 'YDM',
        label: 'esl.generic.date_format_YYYY_DD_MM',
        value: 'YYYY-DD-MM'
    },
    {
        key: 'MDY',
        label: 'esl.generic.date_format_MM_DD_YYYY',
        value: 'MM-DD-YYYY'
    }
];

export const FIELD_KEYS = {
    TEXT_FIELD: 'input.textfield',
    TEXT_AREA: 'input.textarea',
    CHECKBOX: 'input.checkbox',
    RADIO: 'input.radio',
    LIST: 'input.list',
    LABEL: 'input.label',
    DATEPICKER: 'input.datepicker'
};
const FORM_FIELDS_DATA = {
    [FIELD_KEYS.LIST]: {
        label: 'page.designer.fields.dropdown',
        icon: <IoIosArrowDropdown />,
        name: 'dropdown',
        typeName: FIELD_TYPES.FORM
    },
    [FIELD_KEYS.TEXT_AREA]: {
        label: 'page.designer.fields.multiLine',
        icon: <HiBars3BottomLeft />,
        name: 'multi-line',
        typeName: FIELD_TYPES.FORM
    },
    [FIELD_KEYS.TEXT_FIELD]: {
        label: 'page.designer.fields.singleLine',
        icon: <FiType />,
        name: 'single-field',
        typeName: FIELD_TYPES.FORM

    },
    [FIELD_KEYS.CHECKBOX]: {
        label: 'esl.spot.menu.datafield.checkbox.label',
        icon: <IoCheckboxOutline />,
        name: 'checkbox',
        typeName: FIELD_TYPES.FORM
    },
    [FIELD_KEYS.RADIO]: {
        label: 'page.designer.fields.radioButton',
        icon: <IoMdRadioButtonOn />,
        name: 'radio',
        typeName: FIELD_TYPES.FORM
    },
    [FIELD_KEYS.LABEL]: {
        label: 'esl.spot.menu.datafield.labelfield.label',
        icon: <BiLabel />,
        name: 'label',
        typeName: FIELD_TYPES.FORM
    }
};
const FIELDS_DATA = {
    'action.accept_only': {
        label: 'esl.partials.dialogs.document_options_tab.accept_only',
        icon: <AiOutlineCheckSquare />,
        name: 'accept-only'
    },
    'signature.capture': {
        label: 'page.designer.fields.capture',
        icon: <Signature2 />,
        name: 'capture-signature',
        typeName: FIELD_TYPES.SIGNATURE
    },
    'signature.initials': {
        label: 'page.designer.fields.clickToInitial',
        icon: <Signature2 />,
        name: 'initials-signature',
        typeName: FIELD_TYPES.SIGNATURE
    },
    'signature.fullname': {
        label: 'page.designer.fields.clickToSign',
        icon: <Signature2 />,
        name: 'fullname-signature',
        typeName: FIELD_TYPES.SIGNATURE
    },
    'signature.mobile_capture': {
        label: 'page.designer.fields.capture',
        icon: <Signature2 />,
        name: 'capture-signature',
        typeName: FIELD_TYPES.SIGNATURE
    },
    'input.label': {
        label: 'esl.spot.menu.datafield.labelfield.label',
        icon: <HiOutlineCalendarDays />,
        name: 'label',
        typeName: FIELD_TYPES.FORM
    },
    'input.label.approval.signed': {
        label: 'esl.spot.menu.datafield.signing_date.label',
        icon: <HiOutlineCalendarDays />,
        name: 'signing-date',
        typeName: FIELD_TYPES.RECIPIENT
    },
    'input.label.signer.name': {
        label: 'esl.generic.name',
        icon: <HiOutlineUser />,
        name: 'signer-name',
        typeName: FIELD_TYPES.RECIPIENT
    },
    'input.label.signer.title': {
        label: 'esl.generic.title',
        icon: <HiOutlineIdentification />,
        name: 'title',
        typeName: FIELD_TYPES.RECIPIENT
    },
    'input.label.signer.company': {
        label: 'esl.generic.company',
        icon: <HiOutlineBuildingOffice2 />,
        name: 'company',
        typeName: FIELD_TYPES.RECIPIENT
    },
    'input.datepicker': {
        label: 'page.designer.fields.date',
        icon: <HiOutlineCalendarDays />,
        name: 'date',
        typeName: FIELD_TYPES.FORM
    },
    ...FORM_FIELDS_DATA
};

const defaultField = {
    type: '',
    subtype: '',
    name: '',
    value: '',
    binding: '',
    data: null,
    height: 0,
    width: 0,
    top: 0,
    left: 0,
    page: 0,
    extract: false,
    extractAnchor: null,
    fontSize: INHERIT_SIZE,
    formattedValue: '',
    validation: null
};

const defaultApproval = {
    data: null,
    fields: [],
    role: ''
};
export const MIN_FIELD_MAX_LENGTH = 1;
export const MAX_FIELD_MAX_LENGTH = 4000;
export const DEFAULT_FIELD_MAX_LENGTH_VALUE = MAX_FIELD_MAX_LENGTH;

function getFieldById(approvals = [], fieldId) {
    const allFields = approvals.map(({fields}) => fields).flat();
    return allFields.find(({id}) => id === fieldId);
}

export const colorArray = [
    '#7b6e6e',
    '#f59eff',
    '#fdacd7',
    '#8379db',
    '#4533e6',
    '#f032e6',
    '#aaffc3',
    '#42ac61',
    '#bfef45',
    '#79c027',
    '#469990',
    '#32f049',
    '#1f3193',
    '#4363d8',
    '#78cdff',
    '#16dbc9',
    '#ffb59d',
    '#ffd8b1',
    '#fffbaa',
    '#ffc44e',
    '#f26522',
    '#ff646a',
    '#e6194b',
    '#800000',
    '#dbc899',
    '#9a6324',
    '#808000',
    '#000'
];

function generateColorById(uuid) {
    if (!uuid) {
        return '#aaa';
    }
    const colorHash = new ColorHash({
        lightness: [0.7],
        saturation: [0.8]
    });

    return colorHash.hex(uuid);
}

function getColorsForSigners(signers) {
    if (isEmpty(signers)) {
        return [];
    }
    const newSigners = signers.reduce((prevSigners, signer) => {
        const idColor = generateColorById(signer.roleId);

        const usedSignersColors = prevSigners.map(({color}) => color);
        const filteredColors = colorArray.filter(
            (c) => !usedSignersColors.includes(c)
        );
        if (!filteredColors.length) {
            return [...prevSigners, {...signer, color: idColor}];
        }

        const newColorsResults = filteredColors.map((color) => ({
            color,
            diff: deltaE(idColor, color)
        }));

        let closestColor = {diff: 100};

        newColorsResults.forEach((color) => {
            if (closestColor.diff > color.diff) {
                closestColor = color;
            }
        });

        return [...prevSigners, {...signer, color: closestColor.color}];
    }, []);

    return newSigners;
}

function getRoleById({roles, roleId}) {
    return roles.find(({id}) => id === roleId) || [];
}

function getRoleIndexById({roles, roleId}) {
    return roles.findIndex(({id}) => id === roleId);
}

// tag with index 0 is equal to z-index: 0, index 1 to z-index: 1 etc...
// reverse was used to make fields z-index order to be simmiliar to old signer ui
function getTags({roles, approvals, pageIndex}) {
    const reversedApprovals = approvals.filter(({signed}) => !signed).reverse();
    const tags = reversedApprovals
        .flatMap((approval) => {
            const reversedFields = [...approval?.fields];
            const fieldsWithRole = reversedFields.map((field) => ({
                field,
                role: getRoleById({roles, roleId: approval.role}),
                roleIndex: getRoleIndexById({roles, roleId: approval.role}),
                approval,
                fieldIndex: reversedFields.findIndex(({id}) => id === field.id),
                approvalIndex: reversedApprovals.findIndex(({id}) => id === approval.id)
            }));

            return fieldsWithRole.filter(({field}) => field.page === pageIndex);
        });

    return tags;
}

function getConvertedFieldPositionFromResize(target) {
    let left = target.getBoundingClientRect().left
      - target.parentElement.getBoundingClientRect().left;
    const right = target.getBoundingClientRect().right
      - target.parentElement.getBoundingClientRect().right;
    const top = target.getBoundingClientRect().top
      - target.parentElement.getBoundingClientRect().top;
    const docScale = target.parentElement.getBoundingClientRect().width
      / parseFloat(target.parentElement.getAttribute('data-page-width'));

    // Catch left positions under 0
    left = left < 0 ? 0 : left;
    // Catch right positions over 0
    left = right > 0 ? left - right : left;

    return {
        left: Math.round(left / docScale),
        top: Math.round(top / docScale)
    };
}

function getConvertedFieldDimensionFromResize(target) {
    const {width, height} = target.getBoundingClientRect();
    const docScale = target.parentElement.getBoundingClientRect().width
      / parseFloat(target.parentElement.getAttribute('data-page-width'));
    const parentHeight = target.parentElement.getBoundingClientRect().height / docScale;
    const calculatedPosition = getConvertedFieldPositionFromResize(target);
    const calculatedDimensions = {
        width: Math.round(width / docScale),
        height: Math.round(height / docScale)
    };

    // If field is beyond bottom, reduce height
    calculatedDimensions.height = calculatedPosition.top + calculatedDimensions.height > parentHeight - 1
        ? calculatedDimensions.height
          - Math.ceil(calculatedPosition.top + calculatedDimensions.height - parentHeight + 1)
        : calculatedDimensions.height;

    return {
        width: Math.round(calculatedDimensions.width),
        height: Math.round(calculatedDimensions.height)
    };
}

function modifyFieldFromResize(field, target) {
    const options = {
        ...getConvertedFieldPositionFromResize(target),
        ...getConvertedFieldDimensionFromResize(target)
    };
    const updatedField = {...field, ...options};

    return updatedField;
}

function getConvertedFieldPosition(event) {
    const el = event.relatedTarget ? event.relatedTarget : event.target;
    const parentElement = event.relatedTarget
        ? event.target
        : event.target.parentElement;
    const elRect = el.getBoundingClientRect();
    const parentRect = parentElement.getBoundingClientRect();
    let left = elRect.left - parentRect.left;
    const right = elRect.right - parentRect.right;
    let top = elRect.top - parentRect.top;
    const docScale = parentRect.width / parseFloat(parentElement.getAttribute('data-page-width'));
    const parentHeight = parseFloat(parentElement.getAttribute('data-page-height'));
    const blockHeight = elRect.height / docScale;

    // When changing page, catch and inverse top value as it becomes negative
    top = top < 0 ? -top : top;

    // Catch left positions under 0
    left = left < 0 ? 0 : left;
    // Catch right positions over 0
    left = right > 0 ? left - right : left;

    const calculatedPosition = {
        left: Math.round(left / docScale),
        top: Math.round(top / docScale)
    };

    // If field is beyond bottom
    calculatedPosition.top = calculatedPosition.top + blockHeight > parentHeight
        ? parentHeight - blockHeight - 1
        : calculatedPosition.top;

    return calculatedPosition;
}

function getConvertedFieldDimension(event) {
    const el = event.relatedTarget ? event.relatedTarget : event.target;
    const parentElement = event.relatedTarget
        ? event.target
        : event.target.parentElement;
    const {width, height} = el.getBoundingClientRect();
    const docScale = parentElement.getBoundingClientRect().width
      / parseFloat(parentElement.getAttribute('data-page-width'));

    return {
        width: Math.round(width / docScale),
        height: Math.round(height / docScale)
    };
}

function modifyFieldFromDrop(field, event) {
    const options = {
        page: parseInt(event.target.getAttribute('data-page-index'), 10),
        ...getConvertedFieldPosition(event),
        ...getConvertedFieldDimension(event)
    };
    const updatedField = {...field, ...options};

    return updatedField;
}

export function getFieldKey({type, subtype, binding}) {
    const bindingPath = binding ? `.${binding.replace(/[{()}]/g, '')}` : '';
    const fieldKey = `${type}.${subtype}${bindingPath}`;

    return fieldKey.toLowerCase();
}

function getFieldLabel({type, subtype, binding}) {
    const key = getFieldKey({type, subtype, binding});
    return (FIELDS_DATA[key] && FIELDS_DATA[key].label) || '';
}
function getFieldName({type, subtype, binding}) {
    const key = getFieldKey({type, subtype, binding});
    return (FIELDS_DATA[key] && FIELDS_DATA[key].name) || '';
}
function getFieldTypeName({type, subtype, binding}) {
    const key = getFieldKey({type, subtype, binding});
    return (FIELDS_DATA[key] && FIELDS_DATA[key].typeName) || '';
}

export function getApprovalFromPackage({
    packageData = {}, documentId, approvalId
}) {
    const approvals = packageData?.documents?.find(({id}) => id === documentId)?.approvals || [];
    const approval = approvals.find(({id}) => id === approvalId) || {};
    return approval;
}
export function getAllPackageFields(packageData) {
    const arr = [];
    if (!packageData.documents) return arr;
    packageData.documents.forEach((document) => document.approvals.forEach((approval) => {
        approval.fields.forEach((field) => arr.push({
            documentId: document.id,
            approvalId: approval.id,
            role: approval.role,
            ...field
        }));
    }));
    return arr;
}

export function getAllPackageApprovals(packageData = {}) {
    const arr = [];
    packageData.documents?.forEach((document) => document.approvals.forEach((approval) => {
        arr.push({
            documentId: document.id,
            ...approval
        });
    }));
    return arr;
}

function getFieldFromPackageById(packageData, fieldId) {
    const field = packageData.documents.find(({approvals}) => approvals.find(({fields}) => fields.find(({id}) => id === fieldId)));
    return field;
}

export function getFieldFromPackage({
    packageData = {}, documentId, approvalId, fieldId
}) {
    if (!documentId && !approvalId && fieldId) {
        return getFieldFromPackageById(packageData, fieldId);
    }
    const approvals = packageData?.documents?.find(({id}) => id === documentId)?.approvals || [];
    const approval = approvals.find(({id}) => id === approvalId) || {};
    const field = approval.fields?.find(({id}) => id === fieldId) || {};
    return field;
}

export const defaultRadioGroupName = 'default';

function getUniqueArray(arr) {
    return arr.filter((item, pos, self) => self.indexOf(item) === pos);
}

export function getGroupsFromPackage(packageData) {
    const checkboxGroups = [];
    const radioGroups = [];

    if (!isEmpty(packageData)) {
        packageData.documents.forEach((document) => document.approvals.forEach((approval) => approval.fields.forEach((field) => {
            if (field.subtype === FIELD_SUBTYPES.CHECKBOX && field.validation?.group) {
                checkboxGroups.push(field.validation?.group);
            } else if (field.subtype === FIELD_SUBTYPES.RADIO && get(field, 'validation.enum[0]', '')) {
                radioGroups.push(get(field, 'validation.enum[0]'));
            }
        })));
    }
    if (isEmpty(radioGroups)) radioGroups.push(defaultRadioGroupName);

    return {
        checkboxGroups: getUniqueArray(checkboxGroups),
        radioGroups: getUniqueArray(radioGroups)
    };
}

function getConvertedCloneFieldPosition({target, parentElement}) {
    let left = target.getBoundingClientRect().left - parentElement.getBoundingClientRect().left;
    const right = target.getBoundingClientRect().right - parentElement.getBoundingClientRect().right;
    const top = target.getBoundingClientRect().top - parentElement.getBoundingClientRect().top;
    const docScale = parentElement.getBoundingClientRect().width / parseFloat(parentElement.getAttribute('data-page-width'));

    // Catch left positions under 0
    left = left < 0 ? 0 : left;
    // Catch right positions over 0
    left = right > 0 ? left - right : left;

    return {
        left: Math.round(left / docScale),
        top: Math.round(top / docScale)
    };
}

function getConvertedCloneFieldDimensions({target, parentElement, isSquare}) {
    const docScale = parentElement.getBoundingClientRect().width
      / parseFloat(parentElement.getAttribute('data-page-width'));
    const parentHeight = parentElement.getBoundingClientRect().height / docScale;
    const calculatedPosition = getConvertedCloneFieldPosition({target, parentElement});
    const calculatedDimensions = !isSquare ? {
        width: DEFAULT_WIDTH,
        height: DEFAULT_HEIGHT
    } : {
        width: DEFAULT_SQUARE_WIDTH,
        height: DEFAULT_SQUARE_HEIGHT
    };

    // If field is beyond bottom, reduce height
    calculatedDimensions.height = calculatedPosition.top + calculatedDimensions.height > parentHeight - 1
        ? calculatedDimensions.height
          - Math.ceil(calculatedPosition.top + calculatedDimensions.height - parentHeight + 1)
        : calculatedDimensions.height;

    return {
        width: Math.round(calculatedDimensions.width),
        height: Math.round(calculatedDimensions.height)
    };
}

const step = 25;
function checkIsAvailableToPlace({
    left, top, fields, pageHeight, pageWidth
}) {
    return fields.every((field) => (
        (
            (left + DEFAULT_WIDTH < (field.left) || left > (field.left + field.width)) // available by left
            || (top + DEFAULT_HEIGHT < (field.top) || top > (field.top + field.height)) // available by top
        )
        && (top + DEFAULT_HEIGHT < pageHeight && left + DEFAULT_WIDTH < pageWidth) // is inside page
    ));
}

function getAvailablePosition({
    x, y, isAvailable, pageHeight, pageWidth, fields
}) {
    if (isAvailable) return {x, y, isAvailable};
    if (y + DEFAULT_HEIGHT > pageHeight || x + DEFAULT_WIDTH > pageWidth) {
        return {};
    }
    const left = x + step;
    return getAvailablePosition({
        x: left + DEFAULT_WIDTH > pageWidth ? 0 : left,
        y: left + DEFAULT_WIDTH > pageWidth ? y + step : y,
        isAvailable: checkIsAvailableToPlace({
            left, top: y, fields, pageHeight, pageWidth
        }),
        pageWidth,
        pageHeight,
        fields
    });
}

function getCenteredPosition({parentElement, fields, startFromPosition = {}}) {
    const pageWidth = parseFloat(parentElement.getAttribute('data-page-width'));
    const pageHeight = parseFloat(parentElement.getAttribute('data-page-height'));
    const pageOffset = parentElement.getBoundingClientRect();
    const containerElement = parentElement.parentElement.parentElement.parentElement;
    const containerOffset = containerElement.getBoundingClientRect();
    const scrollOffset = containerElement.scrollTop % pageOffset.height;

    // find place from the center of the page
    const centerY = containerOffset.height / 2 - DEFAULT_HEIGHT / 2 + scrollOffset;
    const centerPagePosition = {
        x: 0,
        y: centerY
    };

    const centerPos = getAvailablePosition({
        x: startFromPosition.x >= 0 ? startFromPosition.x : centerPagePosition.x,
        y: startFromPosition.y >= 0 ? startFromPosition.y : centerPagePosition.y,
        pageWidth,
        pageHeight,
        fields
    });

    if (centerPos.isAvailable) {
        return {left: centerPos.x, top: centerPos.y};
    }

    // find place from the start of the page
    const startPagePosition = {
        x: 0,
        y: step
    };
    const startPos = getAvailablePosition({
        ...startPagePosition,
        pageWidth,
        pageHeight,
        fields
    });
    return {left: startPos.x, top: startPos.y};
}

function convertPixelsToPercentage(field, page) {
    const leftPercentage = (field.left / page.width) * 100;
    const topPercentage = (field.top / page.height) * 100;

    return {
        leftPercentage,
        topPercentage
    };
}
function convertPercentageToPixels(fieldPercent, page) {
    const leftPixels = (fieldPercent.leftPercentage / 100) * page.width;
    const topPixels = (fieldPercent.topPercentage / 100) * page.height;

    return {
        left: leftPixels,
        top: topPixels
    };
}

export function getDefaultFieldValidation({subtype}) {
    switch (subtype) {
        case FIELD_SUBTYPES.RADIO:
            return {
                enum: [defaultRadioGroupName]
            };
        case FIELD_SUBTYPES.DATEPICKER:
            return {
                pattern: DEFAULT_DATE_FIELD_VALUE
            };
        case FIELD_SUBTYPES.TEXTAREA:
            return {
                maxLength: DEFAULT_FIELD_MAX_LENGTH_VALUE
            };
        case FIELD_SUBTYPES.TEXTFIELD:
            return {
                maxLength: DEFAULT_FIELD_MAX_LENGTH_VALUE
            };
        case FIELD_SUBTYPES.LIST:
            return {
                required: false
            };
        case FIELD_SUBTYPES.CHECKBOX:
            return {};
        default:
            return null;
    }
}
export function getFieldMaxLengthValue(maxLength) {
    return maxLength > MAX_FIELD_MAX_LENGTH ? MAX_FIELD_MAX_LENGTH : maxLength;
}

export function enumToString(value = []) {
    return value.join('\n') || '';
}

export function getConfiguredField({
    dimensions,
    customFieldId,
    page,
    type,
    subtype,
    binding
}) {
    return {
        ...defaultField,
        ...dimensions,
        page,
        type,
        subtype,
        binding,
        validation: getDefaultFieldValidation({subtype}),
        fontSize: INHERIT_SIZE,
        name: customFieldId || ''
    };
}

export function configureFieldFromEvent({event, customFields}) {
    const dimensions = JSON.parse(event.relatedTarget.getAttribute('dimensions'));
    const type = event.relatedTarget.getAttribute('data-type');
    const subtype = event.relatedTarget.getAttribute('data-subtype');
    const binding = event.relatedTarget.getAttribute('data-binding');
    const customFieldId = event.relatedTarget.getAttribute('data-custom-field-id');
    const page = parseInt(event.target.getAttribute('data-page-index'), 10);

    const field = {
        ...getConfiguredField({
            dimensions,
            customFieldId,
            page,
            type,
            subtype,
            binding
        }),
        value: customFieldId ? customFields.find(({id}) => id === customFieldId)?.value : ''
    };
    return field;
}

function renderIcon({
    firstName,
    lastName,
    role,
    type,
    subtype,
    binding
}) {
    if (subtype === FIELD_SUBTYPES.CUSTOMFIELD) return <FiType />;
    if (subtype === FIELD_SUBTYPES.INITIALS) {
        const firstNameLetter = firstName ? firstName.substr(0, 1) : get(role, 'name', '').substr(0, 1);
        const lastNameLetter = lastName ? lastName.substr(0, 1) : get(role, 'name', '').slice(-1);
        const initials = `${firstNameLetter}${lastNameLetter}`;

        return (
            <div className="initials">
                {initials}
            </div>
        );
    }

    const key = getFieldKey({type, subtype, binding});
    return (FIELDS_DATA[key] && FIELDS_DATA[key].icon) || null;
}

function getFieldValue({
    type, subtype, binding, value, validation, role
}) {
    let fieldValue = '';
    const signer = get(role, 'signers[0]', {});
    const isPlaceholderRecipient = isEmpty(signer);
    const name = isPlaceholderRecipient ? role?.name : `${signer.firstName} ${signer.lastName}`;
    const listValues = get(validation, 'enum', null) || [];
    if (type === FIELD_TYPES.SIGNATURE) {
        fieldValue = name;
    } else {
        switch (subtype) {
            case FIELD_SUBTYPES.TEXTFIELD:
            case FIELD_SUBTYPES.TEXTAREA:
                fieldValue = value;
                break;
            case FIELD_SUBTYPES.LIST:
                fieldValue = listValues.join(', ');
                break;
            case FIELD_SUBTYPES.LABEL:
                switch (binding) {
                    case null:
                        fieldValue = value;
                        break;
                    case '{signer.name}':
                        fieldValue = name;
                        break;
                    case '{signer.title}':
                        fieldValue = signer.title || '';
                        break;
                    case '{signer.company}':
                        fieldValue = signer.company || '';
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }
    return fieldValue;
}

export const isTransactionHasFieldsWithUploadSignature = (packageData) => getAllPackageApprovals(packageData)
    .some((approval) => approval.fromFile && !approval.accepted);

function getPackageType(path) {
    const transactionPathPattern = new RegExp(`${TRANSACTION_PATH}/[A-Za-z0-9-_]+=*/.*`);
    const templatePathPattern = new RegExp(`${TEMPLATE_PATH}/[A-Za-z0-9-_]+=*/.*`);

    const isTransaction = transactionPathPattern.test(path);
    const isTemplate = templatePathPattern.test(path);
    if (isTransaction) return PACKAGE_TYPE_NAME.TRANSACTION;
    if (isTemplate) return PACKAGE_TYPE_NAME.TEMPLATE;
}

function getDefaultDocumentVisibility(documentUid, roleUids) {
    return {
        data: null,
        documentUid,
        id: '',
        name: '',
        roleUids
    };
}

function getDefaultDocumentsVisibility(documents = [], signers = [], storedDocumentsVisibility) {
    const roleUids = signers.map(({roleId}) => roleId);
    return documents.map(({id}) => storedDocumentsVisibility.find(({documentUid}) => documentUid === id)
        || getDefaultDocumentVisibility(id, roleUids));
}

function getDocumentVisibilityByIndex({
    documents, documentIndex, documentsVisibility
}) {
    const documentId = get(documents, `[${documentIndex}].id`, null);

    if (documentId) {
        return documentsVisibility.find(({documentUid}) => documentUid === documentId);
    }
}

function getIsVisibilityChangeDisabled({roleId, documentIndex, packageData}) {
    const document = packageData.documents[documentIndex] || {};
    const approvals = document.approvals || [];
    return approvals.some(({role}) => role === roleId);
}

function getVisibilityByRole({documentVisibility, roleId, document}) {
    if (!isEmpty(documentVisibility)) {
        const visibleRoles = get(documentVisibility, 'roleUids', []);
        const roleHasSomeFields = get(document, 'approvals', []).some(({role}) => role === roleId);
        return visibleRoles.includes(roleId) || roleHasSomeFields;
    }

    return true;
}

function checkIsAcceptedRecipient(approval = {}, sender = {}) {
    return approval && isEmpty(approval.fields) && approval.role !== sender.id;
}

function getAcceptedRecipientsIdsForDocument(document, sender) {
    if (document && !isEmpty(document.approvals)) {
        const acceptedIds = document.approvals
            .filter((approval) => checkIsAcceptedRecipient(approval, sender))
            .map((item) => item.role);
        return acceptedIds;
    }
    return [];
}

function checkIsAcceptedDocument(document = {}, sender = {}) {
    if (!isEmpty(document.approvals)) {
        const acceptedRecipientsIds = getAcceptedRecipientsIdsForDocument(document, sender);
        const hasSomeFields = document.approvals.some((app) => !isEmpty(app.fields));
        const isDocumentHaveAcceptedRecipients = acceptedRecipientsIds.length > 0 && !hasSomeFields;
        return isDocumentHaveAcceptedRecipients;
    }
    return false;
}

export function getDocumentInfo({sender, document, packageData}) {
    const isAdaDocument = !isEmpty(document) && get(packageData, 'settings.ceremony.ada') && document.tagged;
    const isConsentDocument = !isEmpty(document) && packageData.consent === document.id;
    const isAcceptOnlyDocument = checkIsAcceptedDocument(document, sender);

    return {
        isAdaDocument,
        isConsentDocument,
        isAcceptOnlyDocument
    };
}

export function getDisabledSignaturesErrorMessage({
    packageData, documentId, documentSigners = [], selectedApproval
}) {
    const document = packageData.documents?.find(({id}) => id === documentId) || {};
    const hasSignedSigner = documentSigners.some(({hasSignedApprovals}) => hasSignedApprovals);
    const roles = get(packageData, 'roles', []);
    const sender = roles.find((role) => role.type === RECIPIENT_TYPES.SENDER) || {};
    const {isAdaDocument, isConsentDocument, isAcceptOnlyDocument} = getDocumentInfo({sender, document, packageData});
    let message = '';

    if (isAcceptOnlyDocument) {
        message = 'page.designerValidation.signaturesDisabled';
    } else if (hasSignedSigner) {
        message = 'esl.error.forbidden.cannotEditDocumentWithApprovals';
    } else if (isConsentDocument) {
        message = 'esl.error.validation.defaultConsent.modifyingApproval';
    } else if (isAdaDocument) {
        message = 'esl.generic.ada_document_tagged';
    } else if (selectedApproval && !selectedApproval.id) {
        message = 'page.designerValidation.disabledByNoSelectedSignatureOnDocument';
    }
    return message;
}

function getAvailableSignatureFields({
    activeDocument = {},
    signatureFields,
    features,
    selectedSigner = {},
    defaultSignatureType
}) {
    const isOptionalSignatureActive = get(features, 'optionalSignature', false);
    const isMobileActive = get(features, 'mobileCapture', false);
    const selectedDefaultSignatureType = getDefaultSignatureType(isMobileActive, defaultSignatureType);
    const signerApprovals = get(activeDocument, 'approvals', [])
        .filter(({role, fields}) => role === selectedSigner.roleId && !isEmpty(fields)) || [];
    const signerSignatures = signerApprovals.map(({fields = []}) => fields.filter(({type}) => type === FIELD_TYPES.SIGNATURE)).flat();
    const hasPlacedCaptureSignature = signerSignatures.some(({subtype}) => subtype === FIELD_SUBTYPES.CAPTURE);
    const hasPlacedMobileSignature = signerSignatures.some(({subtype}) => subtype === FIELD_SUBTYPES.MOBILE_CAPTURE);

    return signatureFields.filter((field) => {
        if ((field.subtype === FIELD_SUBTYPES.MOBILE_CAPTURE && !isMobileActive) || (field.isOptional && !isOptionalSignatureActive)) {
            return false;
        }
        const hasPlacedConflictedField = (hasPlacedCaptureSignature && field.subtype === FIELD_SUBTYPES.MOBILE_CAPTURE)
            || (hasPlacedMobileSignature && field.subtype === FIELD_SUBTYPES.CAPTURE);

        if (hasPlacedConflictedField) return false;
        if (field.isOptional && isOptionalSignatureActive && !field.subtype) {
            // eslint-disable-next-line no-param-reassign
            field.subtype = selectedDefaultSignatureType;
            return true;
        }

        return true;
    });
}
let scrollUpdateTimeout;
let resetScrollPosition = true;
function scrollByDrag(scrollbar, el) {
    clearTimeout(scrollUpdateTimeout);

    if (!el || !scrollbar) return;

    const offsetToStart = 5;
    const xDelay = 1000;
    const yDelay = 1800;

    const elRect = el.getBoundingClientRect();

    const {bounding, offset, limit} = scrollbar || {};

    const scrollViewportHeight = bounding.bottom - bounding.top;
    const scrollViewportWidth = bounding.right - bounding.left;

    if (limit.y > 0 && elRect.top < bounding.top + offsetToStart && offset.y > 0) {
        scrollbar.scrollTo(offset.x, 0, (offset.y / scrollViewportHeight) * yDelay);
        resetScrollPosition = true;
    } else if (limit.x > 0 && elRect.left < bounding.left + offsetToStart && offset.x > 0) {
        scrollbar.scrollTo(0, offset.y, (limit.x / scrollViewportWidth) * xDelay);
        resetScrollPosition = true;
    } else if (limit.x > 0 && elRect.right > bounding.right - offsetToStart && offset.x < limit.x) {
        scrollbar.scrollTo(limit.x, offset.y, ((limit.x - offset.x) / scrollViewportWidth) * xDelay);
        resetScrollPosition = true;
    } else if (limit.y > 0 && elRect.bottom > bounding.bottom - offsetToStart && offset.y < limit.y) {
        scrollbar.scrollTo(offset.x, limit.y, ((limit.y - offset.y) / scrollViewportHeight) * yDelay);
        resetScrollPosition = true;
    } else if (resetScrollPosition) {
        scrollbar.scrollTo(offset.x, offset.y);
        resetScrollPosition = false;
    } else {
        scrollUpdateTimeout = setTimeout(() => {
            scrollbar.scrollTo(offset.x, offset.y);
        }, 100);
    }
}

function scrollToBottom(scrollNodeRef) {
    setTimeout(() => {
        if (scrollNodeRef?.current) {
            // eslint-disable-next-line no-param-reassign
            scrollNodeRef.current.getScrollElement().scrollTop = 9999;
        }
    }, 0);
}

const configDuplicatedField = ({
    field, approval, documentId, prevPageIndex, pageIndex, packageData, dispatch
}) => {
    const validation = field.subtype === FIELD_SUBTYPES.RADIO && !field.validation?.enum ? {
        enum: [defaultRadioGroupName]
    } : field.validation;
    const pageTarget = document.querySelector(`[data-doc-page-id="${documentId}-${pageIndex}"]`);
    const pageFields = dispatch(getPageFields(documentId, pageIndex));
    const isTheSamePage = prevPageIndex === pageIndex && prevPageIndex >= 0;
    return {
        ...field,
        approvalId: approval.id,
        packageId: packageData.id,
        documentId,
        id: undefined,
        name: field.subtype === FIELD_SUBTYPES.CUSTOMFIELD ? field.name : '',
        role: approval.role,
        validation,
        ...getCenteredPosition({
            parentElement: pageTarget,
            fields: pageFields,
            startFromPosition: isTheSamePage ? {x: field.left, y: field.top} : {}
        })
    };
};

const configDuplicatedFieldForOtherPages = ({
    field, approval, documentId, packageData, sourcePage, targetPage
}) => {
    const validation = field.subtype === FIELD_SUBTYPES.RADIO && !field.validation?.enum ? {
        enum: [defaultRadioGroupName]
    } : field.validation;
    const targetFieldPosition = convertPercentageToPixels(convertPixelsToPercentage(field, sourcePage), targetPage);
    return {
        ...field,
        approvalId: approval.id,
        packageId: packageData.id,
        documentId,
        id: undefined,
        name: field.subtype === FIELD_SUBTYPES.CUSTOMFIELD ? field.name : '',
        role: approval.role,
        validation,
        ...targetFieldPosition

    };
};
export {
    getColorsForSigners,
    getTags,
    getRoleIndexById,
    getConvertedCloneFieldPosition,
    getConvertedCloneFieldDimensions,
    getCenteredPosition,
    defaultApproval,
    defaultField,
    getFieldById,
    getFieldLabel,
    getFieldValue,
    getFieldName,
    getFieldTypeName,
    modifyFieldFromResize,
    modifyFieldFromDrop,
    renderIcon,
    getDocumentVisibilityByIndex,
    getVisibilityByRole,
    getPackageType,
    getAcceptedRecipientsIdsForDocument,
    checkIsAcceptedDocument,
    scrollByDrag,
    getDefaultDocumentVisibility,
    getDefaultDocumentsVisibility,
    getAvailableSignatureFields,
    getIsVisibilityChangeDisabled,
    scrollToBottom,
    configDuplicatedField,
    configDuplicatedFieldForOtherPages
};
