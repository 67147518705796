import {
    fetchTransaction
} from '../Transaction/transactionSlice';
import {
} from '../Packages/Recipients/recipientsSlice';
import {fetchSenderToken} from '../Login/userSlice';
import {fetchDesignerSettings, fetchLayouts, fetchDocumentsVisibility} from './designerSlice';

export default function ssrFetch({
    store, headers, baseUrl, params
}) {
    return Promise.all([
        store.dispatch(fetchDesignerSettings({headers, baseUrl, params})),
        store.dispatch(fetchTransaction({headers, baseUrl, params})),
        store.dispatch(fetchSenderToken({headers, baseUrl, params})),
        store.dispatch(fetchLayouts({headers, baseUrl, params})),
        store.dispatch(fetchDocumentsVisibility({headers, baseUrl, params}))
    ]);
}
