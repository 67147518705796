/* eslint-disable no-underscore-dangle */
import {configureStore} from '@reduxjs/toolkit';
import appReducer from '../components/appSlice';
import adminReducer from '../components/AdminPanel/adminSlice';
import userReducer from '../components/Login/userSlice';
import dashboardReducer from '../components/Dashboard/dashboardSlice';
import transactionsReducer from '../components/Transactions/transactionsSlice';
import transactionReducer from '../components/Transaction/transactionSlice';
import templatesReducer from '../components/Templates/templatesSlice';
import templateReducer from '../components/Template/templateSlice';
import delegationReducer from '../components/Delegation/delegationSlice';
import accountSettingsReducer from '../components/AccountSettings/accountSettingsSlice';
import passwordReducer from '../components/AccountSettings/ChangePassword/passwordSlice';
import recipientsReducer from '../components/Packages/Recipients/recipientsSlice';
import designerReducer from '../components/Designer/designerSlice';
import themesAndStylesReducer from '../components/AdminPanel/ThemesAndStyles/themesAndStylesSlice';
import rolesReducer from '../components/AdminPanel/Roles/rolesSlice';
import usersReducer from '../components/AdminPanel/UserManagement/usersSlice';
import reportsReducer from '../components/Reports/reportsSlice';
import signerUiReducer from '../components/SignerUi/signerUiSlice';
import signerRefsReducer from '../components/SignerUi/signerUIRefSlice';

// Load initial state from server side
let initialState;
if (typeof window !== 'undefined') {
    initialState = window.__INITIAL_STATE__;
    delete window.__INITIAL_STATE__;
}

export default () => configureStore({
    preloadedState: initialState,
    reducer: {
        app: appReducer,
        admin: adminReducer,
        user: userReducer,
        dashboard: dashboardReducer,
        transactions: transactionsReducer,
        transaction: transactionReducer,
        templates: templatesReducer,
        template: templateReducer,
        delegation: delegationReducer,
        accountSettings: accountSettingsReducer,
        password: passwordReducer,
        recipients: recipientsReducer,
        designer: designerReducer,
        themes: themesAndStylesReducer,
        roles: rolesReducer,
        users: usersReducer,
        reports: reportsReducer,
        signerUi: signerUiReducer,
        signerRefs: signerRefsReducer
    }
});
