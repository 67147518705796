export const SCROLL_SPEED_MS = 300;
export const ZOOM_STEP = 0.25;
export const ZOOM_ON_CLICK_STEP = ZOOM_STEP * 3;
export const DEFAULT_ZOOM_FACTOR = 1;
export const MAX_ZOOM_IN = 4;
export const MAX_ZOOM_OUT = 0.25;
export const SCROLL_DIRECTION_DOWN = 'DOWN';
export const SCROLL_DIRECTION_UP = 'UP';
export const VISIBLE_PAGES_AMOUNT = {
    BY_SCROLL_FORWARD: 4,
    BY_SCROLL_BACKWARD: 2
};
export const FOOTER_HEIGHT_PX = 30;
export const FOOTER_MARGIN_BOTTOM_PX = 16;
export const FOOTER_TOTAL_HEIGHT_PX = FOOTER_HEIGHT_PX + FOOTER_MARGIN_BOTTOM_PX;
