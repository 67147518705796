import React, {forwardRef} from 'react';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

export const AppIconButton = forwardRef(({
    className, disabled, size = 'small', children, ...restProps
}, ref) => {
    const containerClassName = classnames('app-icon-button', className, {disabled});

    return (
        <IconButton
            ref={ref}
            size={size}
            disabled={disabled}
            classes={{root: containerClassName}}
            {...restProps}
        >
            {children}
        </IconButton>
    );
});
