import {cloneElement, useEffect} from 'react';
import interact from 'interactjs';

/**
 * Interactable is a component that enhances child components with draggable, droppable,
 * and resizable functionalities using the interact.js library.
*/
export default function Interactable(props) {
    let interactEl = null;
    const {
        draggable = false,
        dropzone = false,
        resizable = false,
        draggableOptions = () => {},
        dropzoneOptions = () => {},
        resizableOptions = () => {},
        onClick,
        elRef,
        disabled
    } = props;

    function setInteractions() {
        if (draggable && !disabled) {
            interactEl.draggable(draggableOptions(elRef.current)).deltaSource('client');
        }
        if (dropzone && !disabled) {
            interactEl.dropzone(dropzoneOptions(elRef.current));
        }
        if (resizable && !disabled) {
            interactEl.resizable(resizableOptions(elRef.current));
        }

        if (onClick) {
            interactEl.on('tap', onClick);
            interactEl.on('contextmenu', (event) => {
                event.preventDefault();
            });
        }
    }

    function initializeInteractions() {
        const isInitialized = elRef.current.getAttribute('interact-init') === 'true';
        if (!isInitialized) {
            interact.dynamicDrop(true);
            interactEl = interact(elRef.current).origin('parent').preventDefault('never');
            setInteractions();
            elRef.current.setAttribute('interact-init', 'true');
        }
    }

    useEffect(() => {
        if (elRef && elRef.current) {
            initializeInteractions();
        }
    }, [elRef]);

    return cloneElement(props.children, {
        ref: elRef,
        draggable: false

    });
}
