import React from 'react';
import Document from './Document/Document';

export default function Documents({documents = [], getDocIconAndStatus, ...rest}) {
    return (
        <div className="welcome-documents-list-container">
            {documents.map((document) => (
                <Document key={document.id} document={document} {...rest} {...getDocIconAndStatus(document)} />))}
        </div>
    );
}
