import React from 'react';
import Attachment from './Attachment/Attachment';

export default function Attachments({attachments = [], onClick}) {
    return (
        <div className="attachments-list-container">
            {attachments.map((attachment) => (<Attachment key={attachment.id} attachment={attachment} onClick={onClick} />))}
        </div>
    );
}
