import {useCallback, memo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEqual from 'lodash.isequal';
import {
    decodeSignatureDataFromBase64,
    getChecksumFromBase64
} from '@components/Core/components/PerfectSignature/hooks/utils';
import {useCeremony} from '@components/SignerUi/hooks/queries';
import {selectConfig} from '@components/appSlice';
import {selectVSFeatures} from '@components/Login/userSlice';
import {isVsFeatureEnabled} from '@utils/helpers';
import useHasPreferenceEnabled from '@hooks/useHasPreferenceEnabled';
import useSignatureImage from '@components/SignerUi/hooks/useSignatureImage';
import SignatureField from '../SignatureField/SignatureField';
import AutofillField from '../AutofillField/AutofillField';
import {
    selectAnimatedSignatureIds, selectCachedImageSignature, selectCachedSignatureData, setHoveredField
} from '../../../signerUiSlice';
import InputField from '../InputField/InputField';
import {getSigningFieldTooltipLabel} from '../../utils';
import AcceptedSignature from '../SignatureField/AcceptedSignature/AcceptedSignature';
import AnimatedSignatureWrapper from '../SignatureField/AnimatedSignatureWrapper/AnimatedSignatureWrapper';
import {SIGNATURE_V2_FEATURE} from '../../../../../../constants';
import './signingField.less';

function getFieldWithPageDimensions(field, pageWithDimensions) {
    return {
        ...field,
        pageWithDimensions
    };
}

const SigningField = ({
    field: propField, pageWithDimensions, fields, originalField, fieldsErrors, changeFields, changeField, fieldsRef
}) => {
    const field = getFieldWithPageDimensions(propField, pageWithDimensions);
    const {
        approval: {isAccepted},
        isSignature,
        isAutofill,
        isInput,
        isCustomField,
        isLabel
    } = field;
    const dispatch = useDispatch();
    const {
        session
    } = useCeremony();
    const config = useSelector(selectConfig);
    const encodedSignatureData = session?.user?.signature?.handdrawn;
    const signatureData = decodeSignatureDataFromBase64(encodedSignatureData);
    const fieldTooltipLabel = getSigningFieldTooltipLabel(field);
    const getFieldData = useCallback(() => field, [field]);
    const vsFeatures = useSelector(selectVSFeatures);
    const hasPerfectFreehandFeature = isVsFeatureEnabled({
        feature: {id: SIGNATURE_V2_FEATURE},
        vsFeatures,
        env: config.VS_ENV
    });
    const approvalOriginalId = field?.approval?.originalId;
    const animatedSignatureIds = useSelector(selectAnimatedSignatureIds);
    const isAnimatedSignature = animatedSignatureIds.includes(approvalOriginalId);
    const hasSigningAnimationPreferenceEnabled = useHasPreferenceEnabled({
        preferenceName: 'signingAnimation',
        defaultValue: true
    });
    const canUseImageToSign = field.approval.isFromFile || hasPerfectFreehandFeature;
    const cachedSignatureData = useSelector(selectCachedSignatureData);
    const {signatureImage: backendCachedImageSignature} = useSignatureImage({skip: !canUseImageToSign});
    const signatureDataToUse = cachedSignatureData || signatureData;
    const cachedImageSignature = useSelector(selectCachedImageSignature);
    const isAnimating = isAnimatedSignature && !!hasPerfectFreehandFeature && hasSigningAnimationPreferenceEnabled;
    const cachedImageToUse = cachedImageSignature?.value || backendCachedImageSignature?.value;
    const cachedImageChecksum = getChecksumFromBase64(cachedImageToUse);
    const isSignatureChecksumCorrect = signatureDataToUse?.checksum === cachedImageChecksum;
    const isAnimatedSignatureVisible = isSignature && isAnimating && isSignatureChecksumCorrect;
    const isAcceptedSignatureVisible = isSignature && isAccepted && !isAnimatedSignatureVisible;

    function setFieldDataToRef({fieldRef}) {
        const refNode = fieldRef.current?.node || fieldRef?.current;
        const newFieldRef = refNode ? {
            htmlNode: refNode,
            getFieldData
        } : {};
        const currentFieldRef = fieldsRef.current[field.originalId];

        if (!isEqual(newFieldRef, currentFieldRef)) {
            // eslint-disable-next-line no-param-reassign
            fieldsRef.current[field.originalId] = newFieldRef;
        }
    }

    const selectField = useCallback(() => {
        dispatch(setHoveredField({
            approvalId: field.approval.originalId,
            ...field
        }));
    }, [field, setHoveredField]);

    const unselectField = useCallback(() => {
        dispatch(setHoveredField({}));
    }, [setHoveredField]);

    if (isAnimatedSignatureVisible) {
        return (
            <AnimatedSignatureWrapper
                field={field}
                isAnimatedSignature={isAnimatedSignature}
                isAccepted={isAccepted}
                signatureData={signatureDataToUse}
            />
        );
    }

    if (isAcceptedSignatureVisible) {
        return (
            <AcceptedSignature
                field={field}
                changeField={changeField}
                selectField={selectField}
                unselectField={unselectField}
                setFieldDataToRef={setFieldDataToRef}
            />
        );
    }
    if (isSignature && !isAccepted) {
        return (
            <SignatureField
                field={field}
                fieldsErrors={fieldsErrors}
                selectField={selectField}
                unselectField={unselectField}
                fieldsRef={fieldsRef}
                changeField={changeField}
                fieldTooltipLabel={fieldTooltipLabel}
                setFieldDataToRef={setFieldDataToRef}
            />
        );
    }
    if (isAutofill || isCustomField || isLabel) {
        return (
            <AutofillField
                field={field}
                fieldTooltipLabel={fieldTooltipLabel}
                setFieldDataToRef={setFieldDataToRef}
            />
        );
    }
    if (isInput) {
        return (
            <InputField
                field={field}
                originalField={originalField}
                fieldsErrors={fieldsErrors}
                fields={fields}
                changeFields={changeFields}
                changeField={changeField}
                selectField={selectField}
                unselectField={unselectField}
                fieldsRef={fieldsRef}
                fieldTooltipLabel={fieldTooltipLabel}
                setFieldDataToRef={setFieldDataToRef}
            />
        );
    }
    return null;
};

export default memo(SigningField);
