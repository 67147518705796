import {useMutation} from '@apollo/client';
import {CHANGE_LOCALE} from '../../../../../gql/mutations';

export const useChangeLocale = (options) => {
    const [changeLocale, {
        loading, error, data, reset
    }] = useMutation(CHANGE_LOCALE, options);

    return {
        changeLocale, loading, error, data, reset
    };
};
