import {useEffect} from 'react';
import isEmpty from 'lodash.isempty';
import {useSelector} from 'react-redux';
import {STORAGE_KEY_FROM_SENDER, STORAGE_KEY_HOST_SIGNER_ID} from '../../../../constants';
import {getValueForKey, setValueForKey} from '../../../utils/storageSelectors';
import {getHostInPerson, isTransactionInPerson} from '../utils/selectors';
import {useCeremony} from './queries';
import {selectisInPersonCeremonyControlledByHost} from '../signerUiSlice';

/**
 * This hook return data about in person ceremony host and also if that's in person signing proces using 1 device
 * @returns isInPersonSigning, inPersonCeremonyHost
 */
export default function useInPerson() {
    const inSender = !!getValueForKey(STORAGE_KEY_FROM_SENDER);
    const {
        session,
        transaction,
        role
    } = useCeremony();
    const isInPersonCeremonyControlledByHost = useSelector(selectisInPersonCeremonyControlledByHost);

    const isCeremonyFetched = !isEmpty(transaction) && !isEmpty(role);
    const inPersonCeremonyHost = isCeremonyFetched && getHostInPerson({
        currentRole: role,
        session,
        transaction,
        inSender
    });
    const isInPersonSigning = isCeremonyFetched && isTransactionInPerson({
        currentRole: role,
        session,
        transaction,
        inSender
    });

    useEffect(() => {
        if (inPersonCeremonyHost) {
            if (transaction.isInPerson && isInPersonSigning) {
                // Save host signer id
                setValueForKey(
                    STORAGE_KEY_HOST_SIGNER_ID,
                    inPersonCeremonyHost.signerId
                );
            }
        }
    }, [inPersonCeremonyHost, isInPersonSigning, transaction]);

    return {
        inPersonCeremonyHost,
        isInPersonSigning,
        isInPersonCeremonyControlledByHost
    };
}
