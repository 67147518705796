import GET_ATTACHMENT_THUMBNAILS from '../../../../gql/getAttachmentThumbnails.gql';
import GET_ATTACHMENT_FILES from '../../../../gql/attachmentFiles.gql';
import {getErrorMessage} from '../../../utils/requests/error';

export const fetchAttachmentFiles = ({transactionId,attachmentId}) => [
    {
        query: GET_ATTACHMENT_FILES,
        variables: {transactionId}
    },
    attachmentId && {
        query: GET_ATTACHMENT_THUMBNAILS,
        variables: {attachmentId, transactionId}
    }
];

export const updateCache = ({attachmentId, transactionId}) => (cache, {data: {uploadAttachment}}) => {
    const newThumbnails = uploadAttachment
        .filter(({thumbnail}) => thumbnail)
        .map((attachmentFile) => ({
            __typename: 'AttachmentFileThumbnail',
            id: attachmentFile.id,
            content: attachmentFile.thumbnail
        }));
    if (newThumbnails.length > 0) {
        const cachedAttachmentThumbnails = cache.readQuery({
            query: GET_ATTACHMENT_THUMBNAILS,
            variables: {attachmentId, transactionId}
        });
        const attachmentThumbnails = cachedAttachmentThumbnails.attachmentThumbnails || {};

        cache.writeQuery({
            query: GET_ATTACHMENT_THUMBNAILS,
            data: {
                attachmentThumbnails: [...attachmentThumbnails, ...newThumbnails]
            },
            variables: {attachmentId, transactionId}
        });
    }
};
export const handleError = (clientError) => {
    if (!clientError) {
        return null;
    }

    const error = clientError?.graphQLErrors?.[0];
    if (error?.messageKey === 'error.validation.attachments.unsupportedFileType'
        || error?.messageKey === 'error.validation.attachments.noSupportedFileTypes') {
        const {uploadedFileType, supportedFileTypes} = error.parameters;

        return {
            messageKey: `esl.${error.messageKey}`,
            parameters: {
                fileType: uploadedFileType,
                supportedFileTypes
            }
        };
    }
    if (error?.messageKey === 'error.validation.attachments.maxAttachmentFilesExceeded') {
        const {maxAttachmentFiles} = error.parameters;

        return {
            messageKey: `esl.${error.messageKey}`,
            parameters: {
                maxAttachmentFiles
            }
        };
    }

    return getErrorMessage(error);
};
