export const BASE_PATH = '';
export const DASHBOARD_PATH = '/dashboard';
export const LOGIN_PATH = '/login';
export const FORCE_LOGIN_PATH = '/force-login';
export const LOGOUT_PATH = `${BASE_PATH}${LOGIN_PATH}`;
export const PASSWORD_RECOVERY_PATH = '/forgotpassword';
export const PASSWORD_RESET_PATH = '/resetpassword';
export const REGISTRATION_PATH = '/invitation';
export const TRANSACTIONS_PATH = '/transactions';
export const TRANSACTION_PATH = '/transaction';
export const DESIGNER_PATH = '/designer';
export const DESIGN_PATH = '/design';
export const TEMPLATES_PATH = '/templates';
export const TEMPLATE_PATH = '/template';
export const REPORTS_PATH = '/reports';
export const SENDER_REPORT_PATH = '/sender';
export const SIGN_PATH = '/sign';
export const DIRECT_SIGN_PATH = '/sign/';
export const NEW_DIRECT_SIGN_PATH = '/ceremony/';
export const NEW_DIRECT_AUTH_PATH = '/authentication';
export const FROM_SENDER_QUERY_PARAM = 'fromSender';
export const ERROR_PATH = '/error';
export const FAST_TRACK_PATH = '/ft';
// admin
export const ADMIN_PATH = '/admin';
export const ADMIN_USER_MANAGEMENT_PATH = 'usermanagement';
export const ADMIN_GROUPS_PATH = 'groups';
export const ADMIN_GROUP_PATH = 'group';
export const ADMIN_SECURITY_PATH = 'security';
export const ADMIN_API_ACCESS_PATH = 'apiaccess';
export const ADMIN_EVENT_NOTIFICATION_PATH = 'eventnotification';
export const ADMIN_THEMES_PATH = 'themes';
export const ADMIN_DATA_RETENTION_PATH = 'dataretention';
export const ADMIN_CUSTOM_FIELDS_PATH = 'customfields';
export const ADMIN_TRANSACTION_EXPIRY_TIME_PATH = 'transactionexpirytime';
export const ADMIN_SUBSCRIPTION_PATH = 'subscription';
export const ROLES_PATH = 'roles';
// account
export const ACCOUNT_PATH = '/account';
export const ACCOUNT_PERSONAL_INFORMATION_PATH = 'personalinformation';
export const ACCOUNT_CHANGE_PASSWORD_PATH = 'password';
export const ACCOUNT_CAPTURE_SIGNATUES_PATH = 'signatures';
export const ACCOUNT_PERSONAL_PREFERENCES_PATH = 'personalpreferences';
export const ACCOUNT_ACCESS_DELEGATION_PATH = 'accessdelegation';
export const ACCOUNT_CUSTOM_FIELDS_PATH = 'customfields';
export const ACCOUNT_GROUPS_PATH = 'groups';
export const ACCOUNT_GROUP_PATH = 'group';
export const ACCOUNT_FEATURES_PATH = 'features';
export const SELECT_NONE_VALUE = 'none';

export const DESIGNER_SUB_LINKS = {
    BACK_TO_TRANSACTION: 'backToTransaction',
    BACK_TO_TEMPLATE: 'backToTemplate',
    RECIPIENTS: 'recipients',
    SIGNATURE_FIELDS: 'signatureFields',
    CUSTOM_FIELDS: 'customFields',
    TRANSACTION_SETTINGS: 'transactionSettings',
    TEMPLATE_SETTINGS: 'templateSettings',
    LAYOUTS: 'layouts',
    DOCUMENT_VISIBILITY: 'documentVisibility'
};
