import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import httpClient from '../../../utils/httpClient';
import {
    BASE_PATH
} from '../../../../constants';
import {getErrorMessage} from '../../../utils/requests/error';

export const passwordSlice = createSlice({
    name: 'password',
    initialState: {
        passwordPolicy: {},
        isPasswordPolicyFetching: false,
        passwordPolicyFetchError: null,
        isPasswordPolicySaving: false,
        passwordPolicySaveError: null,
        isPasswordSaving: false,
        passwordSaveError: null
    },
    reducers: {
        setPasswordPolicy: (state, action) => {
            state.passwordPolicy = action.payload;
        },
        setIsPasswordPolicyFetching: (state, action) => {
            state.isPasswordPolicyFetching = action.payload;
        },
        setPasswordPolicyFetchError: (state, action) => {
            state.passwordPolicyFetchError = action.payload;
        },
        setIsPasswordPolicySaving: (state, action) => {
            state.isPasswordPolicySaving = action.payload;
        },
        setPasswordPolicySaveError: (state, action) => {
            state.passwordPolicySaveError = action.payload;
        },
        setIsPasswordSaving: (state, action) => {
            state.isPasswordSaving = action.payload;
        },
        setPasswordSaveError: (state, action) => {
            state.passwordSaveError = action.payload;
        }
    }
});

export const {
    setPasswordPolicy,
    setIsPasswordPolicyFetching,
    setPasswordPolicyFetchError,
    setIsPasswordPolicySaving,
    setPasswordPolicySaveError,
    setIsPasswordSaving,
    setPasswordSaveError
} = passwordSlice.actions;

export const fetchPasswordPolicy = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/passwordPolicy`;
    dispatch(setIsPasswordPolicyFetching(true));
    dispatch(setPasswordPolicyFetchError(null));

    try {
        const result = await httpClient.get(url);
        dispatch(setPasswordPolicy(get(result, 'data', {})));
        dispatch(setIsPasswordPolicyFetching(false));
        dispatch(setPasswordPolicyFetchError(null));
        return true;
    } catch (err) {
        dispatch(setIsPasswordPolicyFetching(false));
        dispatch(setPasswordPolicyFetchError(getErrorMessage(err)));
        return false;
    }
};

export const savePasswordPolicy = (passwordPolicy) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/passwordPolicy`;
    dispatch(setIsPasswordPolicySaving(true));
    dispatch(setPasswordPolicySaveError(null));

    try {
        await httpClient.put(url, passwordPolicy);
        dispatch(setPasswordPolicy(passwordPolicy));
        dispatch(setIsPasswordPolicySaving(false));
        dispatch(setPasswordPolicySaveError(null));
        return true;
    } catch (err) {
        dispatch(setIsPasswordPolicySaving(false));
        dispatch(setPasswordPolicySaveError(getErrorMessage(err)));
        return false;
    }
};

export const savePassword = (passwords) => async (dispatch, getState) => {
    const {user} = getState();
    const url = `${BASE_PATH}/proxy/account/senders/${user.user.id}/password`;

    dispatch(setIsPasswordSaving(true));
    dispatch(setPasswordSaveError(null));

    try {
        await httpClient.post(url, {
            email: user.user.email,
            ...passwords
        });
        dispatch(setIsPasswordSaving(false));
        dispatch(setPasswordSaveError(null));
        return true;
    } catch (err) {
        dispatch(setIsPasswordSaving(false));
        dispatch(setPasswordSaveError(getErrorMessage(err)));
        return false;
    }
};

export const selectPasswordPolicy = (state) => state.password.passwordPolicy;
export const selectIsPasswordPolicyFetching = (state) => state.password.isPasswordPolicyFetching;
export const selectPasswordPolicyFetchError = (state) => state.password.passwordPolicyFetchError;
export const selectIsPasswordPolicySaving = (state) => state.password.isPasswordPolicySaving;
export const selectPasswordPolicySaveError = (state) => state.password.passwordPolicySaveError;
export const selectIsPasswordSaving = (state) => state.password.isPasswordSaving;
export const selectPasswordSaveError = (state) => state.password.passwordSaveError;

export default passwordSlice.reducer;
