import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import {isValidPhoneNumber} from 'libphonenumber-js';
import get from 'lodash.get';
import SidePanel from '../../../../shared/SidePanel/SidePanel';
import {
    selectIsReassignPaneOpen, selectTransactionId, setIsReassignPaneOpen
} from '../../../../SignerUi/signerUiSlice';
import useEnforcedReassignAuth from '../../../../SignerUi/hooks/useEnforcedReassignAuth';
import {useReassign} from '../../../../SignerUi/hooks/mutations';
import {useCeremony, useReassignView} from '../../../../SignerUi/hooks/queries';
import {REASSIGN_SCHEME_TYPES, REASSIGN_STEPS} from '../../../../../../constants';
import ReassignForm from './ReassignForm/ReassignForm';
import ReassignAuth from './ReassignAuth/ReassignAuth';
import {isEmail} from '../../../../../utils/helpers';
import ReassignThankYou from './ReassignThankYou/ReassignThankYou';
import getSignerAuthByType from './utils';
import './reassignPanel.less';
import {useGraphQlErrorNotification} from '../../../../../hooks/useErrorNotification';

export default function ReassignPanel() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const transactionId = useSelector(selectTransactionId);
    const {
        role: {id, hasLocalLanguage, localLanguage},
        session: {shouldShowAuth, maskResponse}
    } = useReassignView();
    const {
        refetch
    } = useCeremony();
    const enforcedAuth = useEnforcedReassignAuth();
    const initialAuthType = enforcedAuth?.scheme || REASSIGN_SCHEME_TYPES.EMAIL;
    const initialSignerAuth = useMemo(() => getSignerAuthByType({type: initialAuthType, maskResponse}),
        [initialAuthType, maskResponse]);
    const defaultRecipient = {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        company: '',
        message: ''
    };
    const [step, setStep] = useState(REASSIGN_STEPS.REASSIGN);
    const [recipient, setRecipient] = useState(defaultRecipient);
    const [auth, setAuth] = useState(initialSignerAuth);
    const isReassignPaneOpen = useSelector(selectIsReassignPaneOpen);
    const {
        reassign, loading, error, data
    } = useReassign({
        onCompleted: () => setStep(REASSIGN_STEPS.THANK_YOU)
    });
    const errorData = get(data, 'erorrs[0]', get(error, 'graphQLErrors[0]'));
    useGraphQlErrorNotification(errorData);

    function close() {
        dispatch(setIsReassignPaneOpen(false));
        setStep(REASSIGN_STEPS.REASSIGN);
        setRecipient(defaultRecipient);
    }

    function changeForm({target}) {
        setRecipient({
            ...recipient,
            [target.name]: target.value
        });
    }

    function handleReassign() {
        const newAuth = {...auth};
        const role = shouldShowAuth
            ? {...recipient, auth: newAuth}
            : recipient;

        if (hasLocalLanguage) {
            role.localLanguage = localLanguage;
        }

        if (role.auth?.scheme === REASSIGN_SCHEME_TYPES.QNA && !role.auth?.challenges?.[1]?.question) {
            role.auth.challenges = [role.auth?.challenges?.[0]];
        }

        reassign({
            variables: {
                transactionId,
                roleId: id,
                role
            }
        });
    }

    function submit(e) {
        e.preventDefault();

        switch (step) {
            case REASSIGN_STEPS.REASSIGN:
                if (shouldShowAuth) {
                    setStep(REASSIGN_STEPS.AUTH);
                } else {
                    handleReassign();
                }
                break;
            case REASSIGN_STEPS.AUTH:
                handleReassign();
                break;
            default:
                close();
                refetch();
                break;
        }
    }

    function cancel() {
        if (step === REASSIGN_STEPS.REASSIGN) {
            close();
        } else {
            setStep(REASSIGN_STEPS.REASSIGN);
        }
    }

    function getButtonLable() {
        switch (step) {
            case REASSIGN_STEPS.REASSIGN:
                return shouldShowAuth
                    ? intl.formatMessage({id: 'oss.components.Reassign.next', defaultMessage: ''})
                    : intl.formatMessage({id: 'oss.components.Reassign.reassign', defaultMessage: ''});
            case REASSIGN_STEPS.AUTH:
                return intl.formatMessage({id: 'oss.components.Reassign.reassign', defaultMessage: ''});
            default:
                return intl.formatMessage({id: 'oss.components.ReassignThankYou.okButtonLabel', defaultMessage: ''});
        }
    }

    function getIsStepValid() {
        if (step === REASSIGN_STEPS.REASSIGN) {
            return recipient.firstName && recipient.lastName && recipient.email && isEmail(recipient.email);
        }

        if (step === REASSIGN_STEPS.AUTH) {
            switch (auth?.scheme) {
                case REASSIGN_SCHEME_TYPES.QNA:
                    return !!auth?.challenges?.[0]?.question && !!auth?.challenges?.[0]?.answer;
                case REASSIGN_SCHEME_TYPES.SMS:
                    return !!auth?.challenges?.[0]?.question && isValidPhoneNumber(auth?.challenges?.[0]?.question);
                default:
                    return true;
            }
        }

        return true;
    }

    useEffect(() => {
        if (!isReassignPaneOpen) {
            setStep(REASSIGN_STEPS.REASSIGN);
            setRecipient(defaultRecipient);
            setAuth(initialSignerAuth);
        }
    }, [isReassignPaneOpen]);

    useEffect(() => {
        setAuth(initialSignerAuth);
    }, [initialSignerAuth]);

    const buttonsNode = (
        <>
            {step !== REASSIGN_STEPS.THANK_YOU && (
                <Button
                    onClick={cancel}
                    classes={{root: 'btn secondary-btn'}}
                >
                    {intl.formatMessage({id: 'oss.components.Reassign.cancel', defaultMessage: ''})}
                </Button>
            )}
            <Button
                classes={{root: 'btn primary-btn'}}
                type="submit"
                disabled={!getIsStepValid()}
                data-wdio="test-reassign-form-submit-btn"
            >
                {getButtonLable()}
            </Button>
        </>
    );

    return (
        <SidePanel
            className="reassign-signing-panel"
            open={isReassignPaneOpen}
            onClose={close}
            onSubmit={submit}
            isLoading={loading}
            buttonsNode={buttonsNode}
            title={intl.formatMessage({id: 'oss.components.Reassign.title', defaultMessage: ''})}
            ModalProps={{keepMounted: false}}
        >
            <div className="reassign-signing-panel-container">
                {step === REASSIGN_STEPS.REASSIGN && <ReassignForm form={recipient} changeForm={changeForm} />}
                {step === REASSIGN_STEPS.AUTH && <ReassignAuth auth={auth} enforcedAuth={enforcedAuth} setAuth={setAuth} />}
                {step === REASSIGN_STEPS.THANK_YOU && <ReassignThankYou {...recipient} />}
            </div>
        </SidePanel>
    );
}
