import {gql} from '@apollo/client';

/** In order to convert source gql/someGql.file need to execute request using this file and copy payload.query from dev tools */
export default gql`query getCeremony($transactionId: ID!) {
    ceremony(transactionId: $transactionId) {
      role {
        id
        name
        fullName
        email
        hasSigned
        recipientEmail
        isSender
        isReviewer
        isOwner
        isAccountSender
        canSign
        localLanguage
        hasLocalLanguage
        signerId
        hasAttachmentRequirements
        isNotary
        canReAssign
        attachmentRequirements {
          comment
          description
          id
          files {
            id
            name
            preview
            __typename
          }
          required
          status
          name
          numberOfFiles
          __typename
        }
        verificationType {
          id
          method
          __typename
        }
        __typename
      }
      transaction {
        raw
        autocomplete
        adHocCobrowsing
        canSignByAny
        isSenderVisible
        isVirtualRoomSigning
        virtualRoomHostUid
        virtualRoomSessionStatus
        isInPerson
        isSignatureCaptureEnforced
        isNotarized
        notaryRoleId
        lastDocumentSigningComplete
        isSigningComplete
        isConsentNullOrAccepted
        isOrdered
        canDisplayLogoInIFrame
        inPersonRoles {
          auth {
            scheme
            challenges {
              question
              __typename
            }
            __typename
          }
          id
          name
          email
          phoneNumberEnding
          fullName
          isSender
          isReviewer
          locked
          requiredAction
          progress
          localLanguage
          index
          displayIndex
          canSign
          isNotary
          __typename
        }
        hasCaptureSignatures
        hasMobileSignatures
        hasConditionalFields
        canDecline
        canDisplayHelp
        isAdHocCobrowsingSigner
        canDisplayOverview
        canChangeLanguage
        id
        name
        zipUrl
        isCompleted
        isDeclined
        language
        initialDocumentId
        firstNonConsentDocument {
          id
          __typename
        }
        canDownload
        isAda
        numOfDocuments
        senderName
        senderId
        cachedSignature
        conditions {
          id
          condition
          action
          __typename
        }
        roles {
          id
          email
          fullName
          isOwner
          isSender
          isNotary
          isReviewer
          hasSigned
          signerId
          canSign
          canReAssign
          localLanguage
          hasLocalLanguage
          attachmentRequirements {
            comment
            description
            id
            files {
              id
              name
              preview
              __typename
            }
            required
            status
            name
            numberOfFiles
            __typename
          }
          __typename
        }
        documents {
          id
          name
          index
          isReview
          isOngoing
          isOngoingByAll
          isConsent
          isDisclosure
          isDisclosureByAll
          isConfirmed
          isConfirmedByAll
          isReadyToConfirmByAll
          isReviewByAll
          hasOnlyOptionalApprovals
          hasOnlyOptionalApprovalsAndFields
          hasOptionalNotAcceptedApprovals
          hasDisabledApprovals
          hasAtLeastOneAcceptedApproval
          hasCurrentSignerApprovalsAsGroup
          isLast
          downloadUrl
          flattenedPdfUrl
          originalPdfUrl
          isReadyToConfirm
          approvals {
            id
            isOptional
            isSignatureCaptureEnforced
            isAccepted
            isSigned
            __typename
          }
          signerApprovals {
            id
            isAccepted
            isOptional
            isSignatureCaptureEnforced
            isDisabled
            isSigned
            fields {
              id
              isSignature
              value
              isDisabled
              __typename
            }
            role {
              signerId
              initials
              company
              title
              fullName
              isDelegate
              __typename
            }
            __typename
          }
          allSignersApprovals {
            id
            isAccepted
            isOptional
            isSignatureCaptureEnforced
            isDisabled
            isSigned
            fields {
              id
              isSignature
              value
              isDisabled
              __typename
            }
            role {
              signerId
              initials
              company
              title
              fullName
              isDelegate
              __typename
            }
            __typename
          }
          currentSignerApprovals {
            id
            isAccepted
            originalId
            isOptional
            isSignatureCaptureEnforced
            isDisabled
            isSigned
            isFromFile
            fields {
              id
              isMobileSig
              isCaptureSig
              isSignature
              isMobileSig
              value
              isDisabled
              __typename
            }
            fromFileData {
              value
              type
              __typename
            }
            role {
              signerId
              initials
              company
              title
              fullName
              isDelegate
              __typename
            }
            __typename
          }
          pages {
            top
            left
            height
            width
            index
            imageUrl
            signerFields {
              id
              originalId
              height
              width
              page
              number
              top
              left
              isTextField
              isTextArea
              isDateField
              isCheckbox
              isRadio
              isList
              isLabel
              isCustomField
              isSignature
              isClickToSign
              isCaptureSig
              isMobileSig
              isClickToInitial
              isAutofill
              isAutofillDate
              isAutofillCompany
              isAutofillTitle
              isAutofillName
              isSelected
              isMasked
              hasConditions
              name
              isRequired
              isDisabled
              maxLength
              minLength
              format
              options
              isInput
              value
              type
              subtype
              binding
              pattern
              patternMessage
              patternErrorCode
              acceptedDate
              fontSize
              lineHeight
              isConditionalTarget
              isGroupCheckbox
              checkboxGroup {
                id
                name
                minimumRequired
                maximumRequired
                size
                selectedFieldIds
                __typename
              }
              radioGroup {
                id
                name
                isRequired
                isSelected
                selectedFieldId
                __typename
              }
              approval {
                id
                originalId
                isAccepted
                isOptional
                isSignatureCaptureEnforced
                isDisabled
                isFromFile
                fromFileData {
                  value
                  type
                  __typename
                }
                role {
                  id
                  initials
                  company
                  title
                  fullName
                  isDelegate
                  signerId
                  __typename
                }
                __typename
              }
              customFieldNames {
                language
                name
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        settings {
          ceremony {
            layout {
              header {
                sessionBar
                __typename
              }
              showDocumentName
              showProgressBar
              showNavigator
              __typename
            }
            disableDeclineOther
            disableOptOutOther
            declineButton
            declineReasons
            disableDownloadForUncompletedPackage
            disableFirstInPersonAffidavit
            disableInPersonAffidavit
            disableSecondInPersonAffidavit
            handOver {
              parameters
              autoRedirect
              __typename
            }
            handOverUrl
            handOverText
            handOverTitle
            leftMenuExpand
            maxAttachmentFiles
            optionalNavigation
            geolocation
            __typename
          }
          __typename
        }
        timezoneId
        __typename
      }
      session {
        raw
        id
        allowInPersonForAccountSenders
        showConsentBar
        loginSessionTimeout
        loginSessionTimeoutWarning
        signerExpiryRedirect
        inPerson
        authTypes
        shouldShowAuth
        maskResponse
        isFullSession
        features {
          inPersonSmsAuth
          sendToMobile
          __typename
        }
        user {
          id
          showExitSigning
          email
          language
          data {
            showExitSigning
            __typename
          }
          signature {
            handdrawn
            __typename
          }
          __typename
        }
        support {
          phone
          email
          __typename
        }
        account {
          id
          __typename
        }
        __typename
      }
      __typename
    }
  }
  `;
