import React, {useMemo, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import useSigners from '../hooks/useSigners';
import Signer from './Signer/Signer';
import {selectSelectedSigner, startInPersonSigning} from '../signerUiSlice';
import useInPerson from '../hooks/useInPerson';
import {useCeremony} from '../hooks/queries';
import {getAvatarByEmail} from '../../../utils/helpers';
import {useAvatars} from '../hooks/useAvatars';
import useSetHostToSignerSession from '../hooks/useSetHostToSignerSession';
import {SectionWithTitle} from '../../Core/company/layouts/SectionWithTitle/SectionWithTitle';
import {AppButton} from '../../Core/buttons/AppButton/AppButton';
import InPersonDialogs from './InPersonDialogs/InPersonDialogs';
import './inPerson.less';

export default function InPerson() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {inPersonCeremonyHost} = useInPerson();

    const {
        role = {},
        transaction: {
            name,
            isOrdered,
            inPersonRoles = []
        } = {}
    } = useSigners();
    const signers = useMemo(
        () => inPersonRoles.map((inPersonRole) => ({
            type: inPersonRole.name,
            ...inPersonRole
        })),
        [inPersonRoles]
    );
    const selectedSignerId = useSelector(selectSelectedSigner);
    const selectedSigner = signers.find(({signerId}) => selectedSignerId === signerId);
    const {role: sessionRole} = useCeremony();
    const [isInPersonConfirmationOpen, setIsInPersonConfirmationOpen] = useState(false);
    const isHostSession = sessionRole?.signerId === inPersonCeremonyHost?.signerId;
    const {setHostToSignerSession} = useSetHostToSignerSession();
    const avatars = useAvatars({signers});

    function openInPersonConfirmation() {
        setIsInPersonConfirmationOpen(true);
    }

    function onStart() {
        const isRoleHost = selectedSignerId === inPersonCeremonyHost.signerId;

        if (isRoleHost) {
            dispatch(startInPersonSigning());
        } else {
            openInPersonConfirmation();
        }
    }

    useEffect(() => {
        setHostToSignerSession();
    }, []);

    if (!isHostSession) return <>The session should be started by host</>;
    return (
        <div className="in-person">
            <SectionWithTitle
                className="in-person-section"
                title={intl.formatMessage({
                    id: 'oss.components.InPerson.WelcomeScreen.textTitle',
                    defaultMessage: ''
                }, {name: role.fullName})}
            >
                {intl.formatMessage({id: 'oss.components.InPerson.WelcomeScreen.textTitleDescription', defaultMessage: ''}, {name})}
            </SectionWithTitle>
            <div className="in-person-signers">
                {signers.map((signer) => (
                    <Signer
                        key={signer.id}
                        signer={signer}
                        isOrdered={isOrdered}
                        avatarSrc={getAvatarByEmail(avatars, signer.email)}
                    />
                ))}
            </div>
            <AppButton
                size="small"
                onClick={onStart}
                className="start-signing-btn"
                disabled={!selectedSigner}
            >
                {intl.formatMessage({id: 'oss.components.InPerson.WelcomeScreen.start', defaultMessage: ''})}
            </AppButton>
            {selectedSigner && (
                <InPersonDialogs
                    role={role}
                    selectedSigner={selectedSigner}
                    signers={signers}
                    isOpen={isInPersonConfirmationOpen}
                    setIsOpen={setIsInPersonConfirmationOpen}
                />
            )}
        </div>
    );
}
