/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import {
    MAX_FIRST_REMIND_AFTER, MAX_INTERVAL_REMINDER, MAX_REMINDERS, MIN_REMINDER_FIELD_VAL
} from '../../../../../constants';

function isSuchReminderExist(reminderSchedules, reminderConfig) {
    return reminderSchedules.some(({
        first_reminder_after_days,
        interval_in_days,
        maximum_interval_reminders
    }) => first_reminder_after_days == reminderConfig.first_reminder_after_days
            && interval_in_days == reminderConfig.interval_in_days
            && maximum_interval_reminders == reminderConfig.maximum_interval_reminders);
}

export function configReminderSchedules({personalPreferences, packageReminderConfig}) {
    let reminderSchedules = [];
    const preferenceReminderSchedule = get(personalPreferences, 'reminder_schedule_values[0]', []);
    const packageReminderConfigInSnakeCase = packageReminderConfig;
    const isPreferenceOptionDuplicateSetReminder = isSuchReminderExist([packageReminderConfigInSnakeCase], preferenceReminderSchedule);

    if (!isPreferenceOptionDuplicateSetReminder && personalPreferences.reminder_schedule && !Array.isArray(preferenceReminderSchedule)) {
        reminderSchedules = [preferenceReminderSchedule];
    }

    if (!isEmpty(packageReminderConfig)) {
        reminderSchedules = [
            ...reminderSchedules,
            packageReminderConfigInSnakeCase
        ];
    }

    return reminderSchedules;
}

export function isEqualReminders(...reminders) {
    return reminders[0]?.first_reminder_after_days == reminders[1]?.first_reminder_after_days
            && reminders[0]?.interval_in_days == reminders[1]?.interval_in_days
            && reminders[0]?.maximum_interval_reminders == reminders[1]?.maximum_interval_reminders;
}

export function validateReminderField(data = {}, name, min, max) {
    if (isEmpty(data)) {
        return true;
    }
    return (Number(data[name]) < min || Number(data[name]) > max);
}

export function checkIsValidReminder(reminderConfig) {
    return validateReminderField(reminderConfig, 'first_reminder_after_days', MIN_REMINDER_FIELD_VAL, MAX_FIRST_REMIND_AFTER)
    || validateReminderField(reminderConfig, 'interval_in_days', MIN_REMINDER_FIELD_VAL, MAX_INTERVAL_REMINDER)
    || validateReminderField(reminderConfig, 'maximum_interval_reminders', MIN_REMINDER_FIELD_VAL, MAX_REMINDERS);
}

export function mapRemindersFromSnakeToCamelCase({
    first_reminder_after_days,
    interval_in_days,
    maximum_interval_reminders
}) {
    return {
        startInDaysDelay: first_reminder_after_days,
        intervalInDays: interval_in_days,
        repetitionsCount: maximum_interval_reminders
    };
}

export function mapRemindersFromCamelToSnakeCase({
    startInDaysDelay,
    intervalInDays,
    repetitionsCount
}) {
    return {
        first_reminder_after_days: startInDaysDelay,
        interval_in_days: intervalInDays,
        maximum_interval_reminders: repetitionsCount
    };
}
