import getStroke from 'perfect-freehand';
import {getSvgPathFromStroke} from '../../libs/getSvgPathFromStroke';
import {options as defaultOptions} from '../../constants';

export function PerfectSignatureView({
    svgRef,
    scaledSvgRef,
    currentStroke,
    color,
    lines,
    options,
    scaledLines,
    scaledOptions,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    withExport = true

}) {
    return (
        <>
            <svg
                ref={svgRef}
                onPointerDown={(e) => (handlePointerDown ? handlePointerDown(e, svgRef) : () => {})}
                onPointerMove={(e) => (handlePointerMove ? handlePointerMove(e, svgRef) : () => {})}
                onPointerUp={(e) => (handlePointerUp ? handlePointerUp(e) : () => {})}
                onPointerLeave={(e) => (handlePointerUp ? handlePointerUp(e) : () => {})}
                style={{touchAction: 'none'}}
                className="svg-drawer"
            >
                {lines.map((path, index) => {
                    const strokePath = path ? getStroke(path, options || defaultOptions) : [];
                    const pathData = strokePath ? getSvgPathFromStroke(strokePath) : '';
                    return <path key={index} d={pathData} fill={color} />;
                })}
                {currentStroke && currentStroke?.length > 0 && (
                    <path
                        d={getSvgPathFromStroke(getStroke(currentStroke, options))}
                        fill={color}
                    />
                )}
            </svg>
            {withExport && (
                <>
                    <div className="placeholder">
                        <div className="line" />
                        <div className="text">Draw your signature</div>
                    </div>
                    <div
                        className="export-svg-element"
                        style={{
                            position: 'absolute',
                            left: -999999,
                            top: -999999,
                            zIndex: -999999
                        }}
                    >
                        <svg
                            ref={scaledSvgRef}
                            style={{touchAction: 'none'}}
                        >
                            {scaledLines.map((path, index) => {
                                const strokePath = path ? getStroke(path, scaledOptions || defaultOptions) : [];
                                const pathData = strokePath ? getSvgPathFromStroke(strokePath) : '';
                                return <path key={index} d={pathData} fill={color} />;
                            })}
                            {currentStroke.length > 0 && (
                                <path
                                    d={getSvgPathFromStroke(getStroke(currentStroke, options))}
                                    fill={color}
                                />
                            )}
                        </svg>
                    </div>
                </>
            )}
        </>
    );
}
