import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import classnames from 'classnames';
import {isValidPhoneNumber} from 'libphonenumber-js';
import PhoneInput from 'react-phone-number-input';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import PhoneInputFieldComponent from './phoneInputField';
import AppInputLabel from '../../Core/labels/AppInputLabel/AppInputLabel';
import CountrySelect from './CountrySelect/CountrySelect';
import './phoneInput.less';

const DEFAULT_PHONE_COUNTRY = 'NL';

const PhoneInputField = ({
    value: propValue,
    onChange,
    className,
    errorMessageKey,
    label,
    id,
    disabled
}) => {
    const intl = useIntl();
    const [localValue, setLocalValue] = useState(propValue);

    useEffect(() => {
        if (propValue !== localValue) {
            setLocalValue(propValue);
        }
    }, [propValue]);

    const handleChange = (value) => {
        setLocalValue(value);
        onChange(value);
    };

    const hasPhoneError = !localValue || (localValue && !isValidPhoneNumber(localValue));
    return (
        <FormControl fullWidth>
            {label && <AppInputLabel>{label}</AppInputLabel>}
            <PhoneInput
                international
                disabled={disabled}
                defaultCountry={DEFAULT_PHONE_COUNTRY}
                value={localValue || ''}
                onChange={handleChange}
                className={classnames('phone-input', className)}
                inputComponent={PhoneInputFieldComponent}
                countrySelectComponent={CountrySelect}
                id={id}
            />
            {hasPhoneError && (
                <FormHelperText error>
                    {intl.formatMessage({id: errorMessageKey || 'esl.validation.phone_number_format', defaultMessage: ''})}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default PhoneInputField;
