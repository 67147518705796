export const USER_TYPES = {
    MANAGER: 'MANAGER',
    MEMBER: 'REGULAR',
    OWNER: 'OWNER'
};

export const DEFAULT_GROUPS_OPTIONS = {
    from: 1,
    sort: 'name',
    dir: 'asc',
    to: 15,
    query: 'accountGroups'
};

export const DEFAULT_USERS_OPTIONS = {
    query: 'accountSenders',
    sort: 'name',
    dir: 'asc',
    from: 1,
    to: 15,
    search: ''
};

export const DEFAULT_PASSWORD_POLICY = {
    includeLowercase: true,
    includeNumbers: true,
    includeSpecialCharacters: true,
    includeUppercase: true,
    length: 8,
    maxInvalidLoginAttempts: 10,
    requiredRules: 3
};

export const ADMIN_GROUPS_HEADINGS = {
    USERS: 'esl.account.account_role.permissions.users',
    APP_SETTING: 'esl.generic.application_settings',
    INTEGRATION: 'esl.generic.integration',
    SUBSCRIPTION: 'esl.generic.subscription_billing'
};

export const ACCOUNT_GROUPS_HEADINGS = {
    PROFILE: 'esl.generic.my_profile',
    SETTINGS: 'esl.generic.my_settings'
};

export const MIN_DELEGATION_START_DATE = '01-01-2017';
export const DELEGATION_STATUSES = {
    IN_PROGRESS: 'IN_PROGRESS',
    EXPIRED: 'EXPIRED',
    FUTURE: 'FUTURE'
};

export const FEATURE_USER_LEVEL = 'user';
export const FEATURE_ACCOUNT_LEVEL = 'account';
export const FREE_USERS_PLAN_NAME = 'Free Users';
