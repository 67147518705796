import React from 'react';
import {useIntl} from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {HiOutlineSupport} from '@react-icons/all-files/hi/HiOutlineSupport';

export default function SupportPortal({closeActions, isDisabled, tooltipPlacement}) {
    const intl = useIntl();

    function renderButton() {
        return (
            <div className="floating-action-btn-container">
                <IconButton
                    size="small"
                    classes={{root: 'floating-action-btn hide-actions'}}
                    onClick={closeActions}
                    href={intl.formatMessage({id: 'page.login.portalLink', defaultMessage: ''})}
                    rel="noopener noreferrer"
                    target="_blank"
                    disabled={isDisabled}
                >
                    <HiOutlineSupport aria-hidden className="icon" />
                </IconButton>
            </div>
        );
    }

    return (
        <>
            {isDisabled ? renderButton() : (
                <Tooltip
                    placement={tooltipPlacement}
                    title={intl.formatMessage({id: 'shared.floatingActions.supportPortal', defaultMessage: ''})}
                    arrow
                >
                    {renderButton()}
                </Tooltip>
            )}
        </>
    );
}
