import React from 'react';
import {Link} from 'react-router-dom';
import {useIntl} from 'react-intl';
import queryString from 'query-string';
import isEmpty from 'lodash.isempty';
import {HiOutlineChevronRight} from '@react-icons/all-files/hi/HiOutlineChevronRight';
import {REPORTS_PATH, SENDER_REPORT_PATH, TRANSACTION_SUMMARY_STATUSES} from '../../../../../../constants';
import './mobileRow.less';

export default function MobileRow({senderSummary = {}, startDate, endDate}) {
    const intl = useIntl();
    const {
        sender = {},
        packages = {}
    } = senderSummary;
    const {
        firstName = '',
        lastName = '',
        id = ''
    } = sender;
    const hasTransactions = !isEmpty(senderSummary.packages);

    function getSummaryUrl() {
        const query = queryString.stringify({
            startDate,
            endDate,
            senderId: id
        });
        return `${REPORTS_PATH}${SENDER_REPORT_PATH}?${query}`;
    }

    return (
        <div className="sender-summary-mobile-row">
            <div className="title">
                {hasTransactions ? (
                    <Link to={getSummaryUrl()} className="link">
                        <div className="link-container">
                            <div className="sender-name">
                                {`${firstName} ${lastName}`}
                            </div>
                            <div className="icon-container">
                                <HiOutlineChevronRight className="icon" />
                            </div>
                        </div>
                    </Link>
                ) : (
                    <span className="sender-name">
                        {`${firstName} ${lastName}`}
                    </span>
                )}
            </div>
            <div className="packages">
                {TRANSACTION_SUMMARY_STATUSES.map((status) => (
                    <div className="package">
                        <div className="status">
                            {intl.formatMessage({id: `esl.package.status.${status}`, defaultMessage: ''})}
                        </div>
                        <div className="amount">
                            {`(${packages[status] || 0})`}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
