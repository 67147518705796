import get from 'lodash.get';
import isEmpty from 'lodash.isempty';

export const getErrorMessage = (error, skipPrefix) => {
    if (isEmpty(error)) {
        return null;
    }
    const messageKey = get(error, 'data.messageKey', 'error_500.oops_broken');
    const parameters = get(error, 'data.parameters', {});

    if (skipPrefix) {
        return {messageKey, parameters};
    }

    return {messageKey: `esl.${messageKey}`, parameters};
};

export const getErrorCode = (error) => get(error, 'data.code', null);
