/* eslint-disable no-unused-vars */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
import chr from 'locutus/php/strings/chr';
import ord from 'locutus/php/strings/ord';
import strlen from 'locutus/php/strings/strlen';
import md5 from 'locutus/php/strings/md5';
import hex2bin from 'locutus/php/strings/hex2bin';

export default function decodeCanvasContext(context, rgbThreshold = 100) {
    const {width, height} = context.canvas;
    let previousX = 0;
    let previousY = 0;
    let numOfNots = 0;
    let numOfPixels = 0;
    let pixelX = 0;
    let pixelY = 0;
    let vectorResult = [];

    function stringToByteArray(s) {
        const result = [];
        for (let i = 0; i < strlen(s); i += 1) {
            result[i] = ord(s[i]);
        }
        return result;
    }

    function byteArrayToString(b) {
        let asciiString = '';

        for (let i = 0; i < b.length; i += 1) {
            asciiString += chr(b[i]);
        }

        return asciiString;
    }

    function javaMd5(data) {
        const dataString = byteArrayToString(data);
        const hashString = md5(dataString);
        const hash = stringToByteArray(hex2bin(hashString));
        return hash;
    }

    function parseStroke(x, y, inputPen) {
        const vector = [];
        const delx = Math.abs(previousX - x);
        const dely = Math.abs(previousY - y);
        const maximum = Math.max(delx, dely);
        let packetType = 0;
        let pen = 0;
        let signx = 0;
        let signy = 0;

        if (inputPen) {
            if (maximum <= 0x0003) { // fits in 2 bits
                packetType = 1;
            } else if (maximum <= 0x003f) { // fits in 6 bits
                packetType = 2;
            } else if (maximum <= 0x03ff) { // fits in 10 bits
                packetType = 3;
            } else if (maximum <= 0x0fff) { // fits in 12 bits
                packetType = 4;
            } else {
                packetType = 5;
            }

            pen = 0x01;
        } else {
            if (maximum <= 0x000f) { // fits in 4 bits
                packetType = 6;
            } else if (maximum <= 0x00ff) { // fits in 8 bits
                packetType = 7;
            } else if (maximum <= 0x0fff) { // fits in 12 bits
                packetType = 4;
            } else {
                packetType = 5;
            }

            pen = 0x00;
        }
        // set sign bit
        if (previousX <= x) {
            signx = 0;
        } else {
            signx = 0x20;
        }

        if (previousY <= y) {
            signy = 0;
        } else {
            signy = 0x10;
        }

        numOfNots += 1;

        switch (packetType) {
            case 1:
                vector.push((0x80 | signx | signy | ((delx & 0x03) << 2) | (dely & 0x03)));
                break;
            case 2:
                vector.push((0x40 | signx | signy | (delx >> 2 & 0x0c) | (dely >> 4 & 0x03)));
                vector.push((((delx << 4) & 0xf0) | (dely & 0x0f)));
                break;
            case 3:
                vector.push((0xc0 | signx | signy | (delx >> 6 & 0x0c) | (dely >> 8 & 0x03)));
                vector.push((delx & 0xff));
                vector.push((dely & 0xff));
                break;
            case 6:
                vector.push((signx | signy));
                vector.push((((delx << 4) & 0xf0) | (dely & 0x0f)));

                break;
            case 7:
                vector.push((signx | signy | 0x02));
                vector.push((delx & 0xff));
                vector.push((dely & 0xff));
                break;
            case 4:
                vector.push((signx | signy | 0x04 | pen));
                vector.push(((delx >> 4 & 0xf0) | (dely >> 8 & 0x0f)));
                vector.push((delx & 0xff));
                vector.push((dely & 0xff));
                break;
            case 5:
                vector.push((signx | signy | 0x06 | pen));
                vector.push((delx >> 8 & 0xff));
                vector.push((delx & 0xff));
                vector.push((dely >> 8 & 0xff));
                vector.push((dely & 0xff));
                break;
            default:
                break;
        }

        previousX = x;
        previousY = y;

        return vector;
    }

    const pixelData = context.getImageData(0, 0, width, height).data;

    function getPixelPositionRaw(x, y) {
        return (y * width + x) * 4;
    }

    function getPixel(x, y) {
        const pos = getPixelPositionRaw(x, y);
        return [pixelData[pos], pixelData[pos + 1], pixelData[pos + 2]];
    }

    for (let y = 0; y < height; y += 1) {
        let previous = false;

        for (let x = 0; x < width; x += 1) {
            const pixel = getPixel(x, y);
            const r = pixel[0];
            const g = pixel[1];
            const b = pixel[2];
            const drawPixel = (r + g + b) / 3 <= rgbThreshold;
            if (drawPixel) {
                numOfPixels += 1;
            }

            if (drawPixel && previous) {
                pixelX = x;
            } else if (drawPixel) {
                pixelX = x;
                pixelY = y;
                vectorResult = vectorResult.concat(parseStroke(pixelX, pixelY, false));
                previous = true;
            } else {
                if (previous) {
                    vectorResult = vectorResult.concat(parseStroke(pixelX, pixelY, true));
                }
                previous = false;
            }
        }
    }

    let resultArray = [];

    resultArray.push((width & 0xFF));
    resultArray.push(((width >> 8) & 0xFF));
    resultArray.push((height & 0xFF));
    resultArray.push(((height >> 8) & 0xFF));
    resultArray.push(0x03);
    resultArray.push(0x00);
    resultArray.push(0x03);
    resultArray.push(0x00);

    resultArray.push(((vectorResult.length + 12) & 0xFF));
    resultArray.push((((vectorResult.length + 12) >> 8) & 0xFF));
    resultArray.push((((vectorResult.length + 12) >> 16) & 0xFF));
    resultArray.push((((vectorResult.length + 12) >> 24) & 0xFF));

    resultArray.push(0x03);
    resultArray.push(0x00);
    resultArray.push(0x00);
    resultArray.push(0x00);

    resultArray.push((width & 0xFF));
    resultArray.push(((width >> 8) & 0xFF));
    resultArray.push((height & 0xFF));
    resultArray.push(((height >> 8) & 0xFF));

    resultArray.push((numOfNots & 0xFF));
    resultArray.push(((numOfNots >> 8) & 0xFF));
    resultArray.push(((numOfNots >> 16) & 0xFF));
    resultArray.push(((numOfNots >> 24) & 0xFF));

    resultArray = resultArray.concat(vectorResult);

    const afterMD5 = javaMd5(resultArray);

    let result = [];
    result.push(0x01);
    result.push(0x00);
    result.push(0x00);
    result.push(0x00);
    result = result.concat(afterMD5);
    result = result.concat(resultArray);

    const resultNew = result.map((value) => chr(value));

    const joinString = resultNew.join('');
    return window.btoa(joinString);
}
