import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import Collapse from '@material-ui/core/Collapse';
import {Retry} from '@components/shared/Documents/components/Retry';
import {
    selectDocumentFields,
    selectIsInterfaceHidden,
    selectIsLeftMenuOpen,
    selectSelectedDocumentId,
    selectTransactionId,
    setFieldsConditions
} from '../signerUiSlice';
import BottomBar from './BottomBar/BottomBar';
import DocumentsContainer from '../../shared/Documents/DocumentsContainer/DocumentsContainer';
import SigningFields from './SigningFields/SigningFields';
import DocumentsTools from '../../shared/Documents/DocumentsTools/DocumentsTools';
import {DEFAULT_ZOOM_FACTOR} from '../../shared/Documents/constants';
import {getSigningFieldsConditions} from './utils';
import {useCeremony, useDocumentsWithPages} from '../hooks/queries';
import ScrollProvider from '../../shared/Documents/ScrollContext/ScrollProvider/ScrollProvider';
import {getIsTurnToSign, isTransactionCompleted} from '../utils/selectors';
import IFrameEventNotifications from '../../../utils/iFrameNotifyer/IFrameEventNotifications';
import {DOCUMENT_NAVIGATION_SUCCESS_EVENT, DOCUMENT_VIEW_SUCCESS_EVENT} from '../../../utils/iFrameNotifyer/events';
import {getPageId} from '../../shared/Documents/utils';
import PersonalCertificate from '../PersonalCertificateClient/PersonalCertificate';
import MobileInterfaceToggle from './MobileInterface/Toggle';
import './signingDocumentView.less';

const SigningDocumentView = () => {
    const dispatch = useDispatch();
    const transactionId = useSelector(selectTransactionId);
    const documentId = useSelector(selectSelectedDocumentId);
    const isLeftMenuOpen = useSelector(selectIsLeftMenuOpen);
    const documentFields = useSelector(selectDocumentFields);
    const isInterfaceHidden = useSelector(selectIsInterfaceHidden);
    const [zoomFactor, setZoomFactor] = useState(DEFAULT_ZOOM_FACTOR);
    const [pageIndex, setPageIndex] = useState(0);
    const [retryCount, setRetryCount] = useState(0);
    const fieldsRef = useRef({});
    const documentsWithPages = useDocumentsWithPages();
    const {transaction, role, refetch: refetchDocuments} = documentsWithPages;
    const {transaction: {conditions}} = useCeremony();
    const documents = get(transaction, 'documents', []);
    const document = documents.find(({id}) => id === documentId) || {};
    const [isDocumentLoading, setIsDocumentLoading] = useState(true);
    const containerClassName = classnames('signing-document-view-container main-container', {
        'is-left-menu-open': isLeftMenuOpen
    });

    function retry() {
        setRetryCount(retryCount + 1);
    }
    const canSignDocument = useMemo(() => (getIsTurnToSign(documents, documentId) && role.canSign), [
        role.canSign,
        transaction.canSignByAny,
        documentId,
        documents
    ]);
    const memorizedDocument = useMemo(() => (isEmpty(document) ? [] : [document]), [document]);
    const iFrameEventNotifications = IFrameEventNotifications.getInstance();
    const activePageId = getPageId({documentId, pageIndex});
    const isCompleted = isTransactionCompleted(transaction);

    function onLoadSuccess() {
        iFrameEventNotifications.postEvent(DOCUMENT_NAVIGATION_SUCCESS_EVENT);
        iFrameEventNotifications.postEvent(
            DOCUMENT_VIEW_SUCCESS_EVENT(documentId)
        );
        setIsDocumentLoading(false);
    }

    function renderFields({pageWithDimensions}) {
        if (!canSignDocument) return;
        return (
            <SigningFields
                pageWithDimensions={pageWithDimensions}
                documentFields={documentFields}
                fieldsRef={fieldsRef}
            />
        );
    }

    function onDocumentAndPageChange({pageIndex: newPageIndex}) {
        setPageIndex(newPageIndex);
    }

    function renderBottomBar({pagesWithDimensions}) {
        return (
            <BottomBar
                transaction={transaction}
                isDocumentLoading={isDocumentLoading}
                pages={pagesWithDimensions}
                isLeftMenuOpen={isLeftMenuOpen}
                fieldsRef={fieldsRef}
                documentFields={documentFields}
            />
        );
    }

    useEffect(() => {
        const fieldsConditions = getSigningFieldsConditions({
            signingFields: documentFields, conditions, documentId
        });
        dispatch(setFieldsConditions(fieldsConditions));
    }, [documentFields, conditions, documentId]);

    useEffect(() => {
        if (isCompleted) {
            refetchDocuments();
        }
    }, [isCompleted]);

    if (isEmpty(transaction)) return null;
    return (
        <ScrollProvider zoomFactor={zoomFactor} setZoomFactor={setZoomFactor}>
            <div className={containerClassName}>
                <Collapse
                    in={!isInterfaceHidden}
                    timeout="auto"
                    classes={{root: 'documents-tools-collapsed-container'}}
                >
                    <DocumentsTools
                        zoomFactor={zoomFactor}
                        onZoomChange={setZoomFactor}
                        onDocumentAndPageChange={onDocumentAndPageChange}
                        pageIndex={pageIndex}
                        documentId={documentId}
                        documents={memorizedDocument}
                        navigationProps={{
                            isDocumentsNavigationVisible: false
                        }}
                    />
                </Collapse>
                <MobileInterfaceToggle />
                <DocumentsContainer
                    activePageId={activePageId}
                    onScroll={onDocumentAndPageChange}
                    zoomFactor={zoomFactor}
                    onZoomChange={setZoomFactor}
                    transactionId={transactionId}
                    documents={memorizedDocument}
                    pageProps={{
                        renderChild: renderFields,
                        onZoomChange: setZoomFactor
                    }}
                    documentProps={{
                        onLoadSuccess,
                        role,
                        error: <Retry buttonProps={{onClick: retry}} />,
                        keyPrefix: `retry-${retryCount}`
                    }}
                    pageFooterProps={{
                        isDocumentNameVisible: false,
                        isPageCountVisible: false
                    }}
                    renderChild={renderBottomBar}
                />
                <PersonalCertificate />
            </div>
        </ScrollProvider>

    );
};
export default SigningDocumentView;
