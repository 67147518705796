import React from 'react';
import {GetApp} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {PCC_STEPS} from '../../constants';
import {AppOutlinedButton} from '../../../../Core/buttons/AppOutlinedButton/AppOutlinedButton';
import {AppButton} from '../../../../Core/buttons/AppButton/AppButton';

const DriverSuccessDialog = ({
    url, onDone, setStep, setOpen
}) => {
    const intl = useIntl();

    const onClose = () => {
        setOpen(false);
        setStep(PCC_STEPS.driver);
    };

    return (
        <>
            <h2 className="title" id="pcc-dialog-title">
                {intl.formatMessage({id: 'oss.pcc.DriverDialog.Success.title', defaultMessage: ''})}
            </h2>
            <div className="content">
                <p>
                    {intl.formatMessage({id: 'oss.pcc.DriverDialog.Success.instructions', defaultMessage: ''})}
                </p>
                <AppOutlinedButton
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    endIcon={<GetApp aria-hidden />}
                    className="certificate-download-button"
                >
                    {intl.formatMessage({id: 'oss.pcc.DriverDialog.Success.retry', defaultMessage: ''})}
                </AppOutlinedButton>
            </div>
            <div className="actions">
                <AppOutlinedButton onClick={onClose}>
                    {intl.formatMessage({id: 'oss.pcc.generic.cancel', defaultMessage: ''})}
                </AppOutlinedButton>
                <AppButton onClick={onDone}>
                    {intl.formatMessage({id: 'oss.pcc.DriverDialog.Success.done', defaultMessage: ''})}
                </AppButton>
            </div>
        </>
    );
};

export default DriverSuccessDialog;
