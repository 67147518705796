import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import GET_SUMMARY_OPTIONS from '../../../../gql/getSummaryOptions.gql';
import {selectTransactionId} from '../signerUiSlice';
import {selectLocale} from '../../appSlice';

export default function useSummaryOptions() {
    const transactionId = useSelector(selectTransactionId);
    const locale = useSelector(selectLocale);
    const {
        data = {},
        loading,
        error
    } = useQuery(GET_SUMMARY_OPTIONS, {
        skip: !transactionId,
        variables: {locale, transactionId}
    });

    const {signingOptions = {}, handoverUrl = {}} = data;
    const {completeSummaryOptions = {}} = signingOptions;

    return useMemo(() => ({
        completeSummaryOptions,
        handoverUrl,
        loading,
        error
    }), [completeSummaryOptions, handoverUrl, error, loading]);
}
