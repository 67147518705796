import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useIntl} from 'react-intl';
import qs from 'qs';
import Button from '@material-ui/core/Button';
import {
    resetPassword, setWasPasswordReset, selectIsPasswordReseting, selectWasPasswordReset, selectPasswordResetError
} from '../Login/userSlice';
import useErrorNotification from '../../hooks/useErrorNotification';
import {LOGIN_PATH} from '../../../constants';
import './passwordRecovery.less';
import AppInput from '../Core/inputs/AppInput/AppInput';

export default function PasswordRecovery() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const location = useLocation();
    const isPasswordReseting = useSelector(selectIsPasswordReseting);
    const wasPasswordReset = useSelector(selectWasPasswordReset);
    const passwordResetError = useSelector(selectPasswordResetError);
    const [email, setEmail] = useState('');

    useErrorNotification(passwordResetError, {}, {
        position: 'top-center'
    });

    useEffect(() => {
        dispatch(setWasPasswordReset(false));
    }, []);

    useEffect(() => {
        const queryEmail = qs.parse(location.search, {ignoreQueryPrefix: true}).email;
        if (queryEmail) {
            setEmail(queryEmail);
        }
    }, [location.search]);

    function onEmailChange({target}) {
        setEmail(target.value);
    }

    function submit(e) {
        e.preventDefault();
        dispatch(resetPassword(email));
    }

    function renderForm() {
        return (
            <form className="password-recovery-form" onSubmit={submit}>
                <div className="title">
                    {intl.formatMessage({id: 'esl.generic.recover_password', defaultMessage: ''})}
                </div>
                <div className="info-to-recover">
                    {intl.formatMessage({id: 'esl.forgotpassword.to_recover_password', defaultMessage: ''})}
                </div>
                <div className="info-receive-email">
                    {intl.formatMessage({id: 'esl.forgotpassword.youll_receive_email', defaultMessage: ''})}
                </div>
                <AppInput
                    required
                    label={intl.formatMessage({id: 'esl.forgotpassword.account_email', defaultMessage: ''})}
                    id="password-recovery-email"
                    value={email}
                    name="email"
                    onChange={onEmailChange}
                    placeholder={intl.formatMessage({id: 'esl.generic.email_example', defaultMessage: ''})}
                    data-wdio="test-password-recovery-form-email-input"
                />
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={!email || isPasswordReseting}
                    classes={{root: 'password-recovery-btn'}}
                    data-wdio="test-password-recovery-form-submit-button"
                >
                    {intl.formatMessage({id: 'esl.generic.submit', defaultMessage: ''})}
                </Button>
                <div className="back-to-login">
                    {intl.formatMessage({id: 'esl.generic.oops_take_me_back_login', defaultMessage: ''}, {
                        link: (
                            <Link to={LOGIN_PATH} className="link" data-wdio="test-password-recovery-login-link">
                                {intl.formatMessage({id: 'esl.generic.login', defaultMessage: ''})}
                            </Link>
                        )
                    })}
                </div>
            </form>
        );
    }

    function renderSuccessScreen() {
        return (
            <>
                <div className="title">
                    {intl.formatMessage({id: 'esl.generic.recover_password', defaultMessage: ''})}
                </div>
                <div className="info-temail-sent" data-wdio="test-password-recovery-success-message">
                    {intl.formatMessage({id: 'esl.account.emailpassword.we_just_sent', defaultMessage: ''})}
                </div>
                <div className="back-to-login">
                    <Link to={LOGIN_PATH} className="link" data-wdio="test-password-recovery-login-link">
                        {intl.formatMessage({id: 'esl.generic.login', defaultMessage: ''})}
                    </Link>
                </div>
            </>
        );
    }

    return (
        <div className="password-recovery">
            <Helmet>
                <title>
                    {`${intl.formatMessage({id: 'esl.generic.recover_password', defaultMessage: ''})} - ${intl.formatMessage({id: 'page.title', defaultMessage: ''})}`}
                </title>
            </Helmet>
            <div className="container">
                {wasPasswordReset ? renderSuccessScreen() : renderForm()}
            </div>
        </div>
    );
}
