import React from 'react';
import classnames from 'classnames';
import {useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';
import useNextCeremony from '../hooks/useNextCeremony';
import messages from '../messages/nextToSignMessages';
import {NEW_DIRECT_SIGN_PATH} from '../../../../constants';
import {AppButton} from '../../Core/buttons/AppButton/AppButton';
import {Dialog, DialogButton} from '../../Core/company/modals/Dialog';
import './nextTransactionToSignModal.less';

const NextTransactionToSignModal = ({isOpen, setIsOpen}) => {
    const containerClassName = classnames('next-to-sign-modal-container');
    const params = useParams();
    const navigate = useNavigate();
    const intl = useIntl();
    const {nextCeremony: {transaction}} = useNextCeremony();
    const {
        name, senderName, lastUpdated, id: nextTransactionId
    } = transaction || {};

    function closeDialog() {
        setIsOpen(false);
    }

    async function goToNextTransaction() {
        navigate(`/transaction/${nextTransactionId}${NEW_DIRECT_SIGN_PATH}`);
        closeDialog();
    }

    if (!nextTransactionId || nextTransactionId === params.packageId) return null;

    return (
        <Dialog
            onClose={closeDialog}
            open={isOpen}
            status="info"
            className={containerClassName}
            data-wdio="test-modal-next-to-sign"
            title={intl.formatMessage({id: messages.title.id})}
            content={(
                <>
                    <p className="info-item">
                        {`${intl.formatMessage({id: messages.transaction.id, defaultMessage: ''})}: `}
                        <b>{name}</b>
                    </p>
                    <p className="info-item">
                        {`${intl.formatMessage({id: messages.from.id, defaultMessage: ''})}: `}
                        <b>{senderName}</b>
                    </p>
                    <p className="info-item">
                        {`${intl.formatMessage({id: 'oss.components.NextTransactionDialog.lastUpdated', defaultMessage: ''})}: `}
                        <b>{lastUpdated}</b>
                    </p>
                </>
            )}
            actions={(
                <>
                    <DialogButton onClick={closeDialog}>
                        {intl.formatMessage({id: messages.cancelButton.id, defaultMessage: ''})}
                    </DialogButton>
                    <AppButton onClick={goToNextTransaction}>
                        {intl.formatMessage({id: messages.proceedButton.id, defaultMessage: ''})}
                    </AppButton>
                </>
            )}
        />
    );
};
export default NextTransactionToSignModal;
