import {useState, useLayoutEffect} from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export default function useElementSize(target, x) {
    const [size, setSize] = useState({});

    useLayoutEffect(() => {
        if (target && target.current) {
            setSize(target.current.getBoundingClientRect());
        }
    }, [target?.current]);

    // entry.contentRect does not reflect to scale applied to element. We need to get getBoundingClientRect()
    useResizeObserver(target, ({target: observedTarget}) => {
        setSize(observedTarget.getBoundingClientRect());
    });

    return size;
}
