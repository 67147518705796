import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import classnames from 'classnames';
import {useIntl} from 'react-intl';
import {useApolloClient} from '@apollo/client';
import get from 'lodash.get';
import {HiOutlineDownload} from '@react-icons/all-files/hi/HiOutlineDownload';
import {HiDotsHorizontal} from 'react-icons/hi';
import {SectionWithTitle} from '../../../Core/company/layouts/SectionWithTitle/SectionWithTitle';
import useOverviewDocuments from '../../hooks/useOverviewDocuments';
import {useCeremony, useInPersonThankTouHostScreenOptions} from '../../hooks/queries';
import {allowDownload, isSigningComplete, isTransactionCompleted} from '../../utils/selectors';
import useSigners from '../../hooks/useSigners';
import Signer from '../../InPerson/Signer/Signer';
import {getAvatarByEmail, redirectTo} from '../../../../utils/helpers';
import {AppButton} from '../../../Core/buttons/AppButton/AppButton';
import {download, getCustomHandOverUrl} from '../../utils/functions';
import {openDocument} from '../../signerUiSlice';
import {AppOutlinedButton} from '../../../Core/buttons/AppOutlinedButton/AppOutlinedButton';
import {useAvatars} from '../../hooks/useAvatars';
import NextTransactionToSignModal from '../../NextTransactionToSignModal/NextTransactionToSignModal';
import useInPerson from '../../hooks/useInPerson';
import useNextCeremony from '../../hooks/useNextCeremony';
import useThankYouView from '../../hooks/useThankYouView';
import './inPersonThankYouHost.less';

/** This page is visible when the host of in person session finish the signing process. Is simmiliar to TransactionSummary.jsx */
const InPersonThankYouHost = () => {
    const intl = useIntl();
    const containerClassName = classnames('thank-you-host-page');
    const client = useApolloClient();
    const dispatch = useDispatch();
    const {transaction = {}, role} = useCeremony();
    const {transaction: {roles}} = useThankYouView();
    const [isOpenNextTransactionToSignModal, setIsOpenNextTransactionToSignModal] = useState(null);
    const {nextCeremony, refetch} = useNextCeremony();
    const {transaction: nextTransaction} = nextCeremony || {};
    const nextTransactionId = nextTransaction?.id;
    const {inPersonCeremonyHost} = useInPerson();
    const isCeremonyHostOwnerOfNextTransaction = transaction.isInPerson ? inPersonCeremonyHost?.signerId === role?.signerId : true;
    const isCompletedTransaction = isTransactionCompleted({roles});

    const haveNextTransactionToSign = nextTransactionId && transaction.id !== nextTransactionId
        && isCeremonyHostOwnerOfNextTransaction
        && role.isSender
        && isCompletedTransaction;

    const isHostSession = role?.signerId === inPersonCeremonyHost?.signerId;

    const {
        role: {
            fullName: signerFullName
        } = {}

    } = useOverviewDocuments();
    const {handOverText, handOverUrl, handOver = {}} = get(transaction, 'settings.ceremony', {});
    const {signingOptions} = useInPersonThankTouHostScreenOptions();
    const layoutOptions = signingOptions.inpersonHostThankYouOptions || {};
    const {
        title,
        body,
        downloadButton: showDownload,
        continue: showContinue,
        reviewDocumentsButton: showReview,
        recipientName,
        recipientRole,
        recipientEmail,
        recipientStatus
    } = layoutOptions;

    function redirectToContinueUrl() {
        const urlWithParams = getCustomHandOverUrl({
            handOverUrl,
            status: 'SIGNER_COMPLETE',
            transactionId: transaction.id,
            signerId: role.signerId,
            parameters: handOver.parameters
        });
        redirectTo(urlWithParams);
    }
    const signingComplete = isSigningComplete(transaction.documents);
    const canDownload = allowDownload(transaction);
    const canDownloadDocuments = canDownload && showDownload;
    const shouldShowContinue = showContinue && handOverUrl && signingComplete;
    const {
        transaction: {
            isOrdered,
            inPersonRoles = []
        } = {}
    } = useSigners();

    const signers = useMemo(
        () => inPersonRoles.map((inPersonRole) => ({
            type: inPersonRole.name,
            ...inPersonRole
        })),
        [inPersonRoles]
    );

    const onReview = () => {
        dispatch(openDocument({id: transaction.documents[0].id}));
    };
    const handleDownloadAll = () => {
        download({url: transaction.zipUrl, client});
    };

    const avatars = useAvatars({signers});
    useEffect(() => {
        if (handOver?.autoRedirect && !role.isSender) {
            redirectToContinueUrl();
        }
    }, [handOver, role]);

    useEffect(() => {
        refetch();
    }, [transaction.id]);

    useEffect(() => {
        setIsOpenNextTransactionToSignModal(haveNextTransactionToSign);
    }, [haveNextTransactionToSign]);

    if (!isHostSession) return <>The session should be controlled by host</>;
    return (
        <div className={containerClassName}>

            <SectionWithTitle
                className="message-section"
                title={title && (
                    <>
                        {intl.formatMessage({id: 'oss.components.InPerson.Decline.Signer.Message.Title', defaultMessage: ''},
                            {fullName: signerFullName})}
                        {body && (
                            <p className="sub-title">
                                {intl.formatMessage({
                                    id: 'oss.components.Notary.ThankYouHostScreen.textTitleDescription',
                                    defaultMessage: ''
                                })}
                            </p>
                        )}
                    </>
                )}
            >
                <>

                    <div className="in-person-signers">
                        {signers.map((signer) => (
                            <Signer
                                key={signer.id}
                                signer={signer}
                                isOrdered={isOrdered}
                                avatarSrc={getAvatarByEmail(avatars, signer.email)}
                                disabled
                                showEmail={recipientEmail}
                                showName={recipientName}
                                showRoleName={recipientRole}
                                showStatus={recipientStatus}
                            />
                        ))}
                    </div>
                    <div className="more-actions">
                        {showReview && (
                            <AppOutlinedButton
                                onClick={onReview}
                            >
                                {intl.formatMessage({id: 'oss.components.SummaryView.reviewDocuments', defaultMessage: ''})}
                            </AppOutlinedButton>
                        )}
                        {canDownloadDocuments && (
                            <AppOutlinedButton
                                onClick={handleDownloadAll}
                                className="download-all-button"
                            >
                                <HiOutlineDownload className="icon" />
                                {intl.formatMessage({id: 'oss.components.ThankYouHostScreen.downloadDocumentsButton', defaultMessage: ''}, {
                                    count: transaction.documents.length
                                })}
                            </AppOutlinedButton>
                        )}
                        {shouldShowContinue && (
                            <AppButton
                                onClick={redirectToContinueUrl}
                                className="continue-btn"
                            >
                                <span>
                                    {handOverText || intl.formatMessage({id: 'oss.components.SummaryView.continueText', defaultMessage: ''})}
                                </span>
                            </AppButton>
                        )}
                    </div>
                </>
            </SectionWithTitle>
            {haveNextTransactionToSign && (
                <SectionWithTitle
                    icon={<HiDotsHorizontal className="icon" aria-hidden />}
                    title={intl.formatMessage({id: 'oss.components.MoreActions.moreActions', defaultMessage: ''})}
                    className="more-actions-section"
                >
                    <AppButton
                        className="next-to-sign-btn"
                        onClick={() => setIsOpenNextTransactionToSignModal(true)}
                    >
                        {intl.formatMessage({id: 'oss.components.NextTransactionDialog.title', defaultMessage: ''})}
                    </AppButton>
                    <NextTransactionToSignModal
                        isOpen={isOpenNextTransactionToSignModal}
                        setIsOpen={setIsOpenNextTransactionToSignModal}
                    />
                </SectionWithTitle>
            )}

        </div>
    );
};
export default InPersonThankYouHost;
