import get from 'lodash.get';
import isEmpty from 'lodash.isempty';

export const getErrorMessage = (error) => {
    if (isEmpty(error)) {
        return null;
    }
    const messageKey = get(error, 'graphQLErrors[0].messageKey', 'error_500.oops_broken');

    return {messageKey};
};
