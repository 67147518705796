import pick from 'lodash.pick';
import getValidPackagesParams from '../../utils/getValidPackagesParams';
import {
    fetchTemplates,
    setOptions
} from './templatesSlice';
import {
    DEFAULT_TEMPLATES_OPTIONS
} from '../../../constants';

export default function ssrFetch({
    store, headers, baseUrl, params
}) {
    if (params && params.query) {
        const newOptions = {
            ...DEFAULT_TEMPLATES_OPTIONS,
            ...getValidPackagesParams(params)
        };
        const filteredNewOptions = pick(newOptions, [
            'from',
            'to',
            'sort',
            'dir',
            'search',
            'visibility'
        ]);
        store.dispatch(setOptions(filteredNewOptions));
    }
    return store.dispatch(fetchTemplates({headers, baseUrl}));
}
