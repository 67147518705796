import adminRoutes from '../setup/adminRoutes';
import accountRoutes from '../setup/accountRoutes';
import mainRoutes from '../setup/routes';
import signerUiRoutes from '../setup/signerUiRoutes';

function getAvailableRoutes({routes = [], skipIndex, ...params}) {
    return routes.reduce((acc, route) => {
        if (!route.isEnabled || route.isEnabled(params)) {
            const filteredRoute = {...route};
            if (filteredRoute.childrenRoutes) {
                filteredRoute.childrenRoutes = getAvailableRoutes({routes: filteredRoute.childrenRoutes, ...params});
            }
            acc.push(filteredRoute);
        }

        return acc;
    }, []).filter(({index}) => (skipIndex ? !index : true));
}

function getAvailableAdminRoutes(params = {}) {
    return getAvailableRoutes({
        routes: adminRoutes, ...params
    });
}

function getAvailableAccountRoutes(params = {}) {
    return getAvailableRoutes({
        routes: accountRoutes, ...params
    });
}

function getAvailableMainRoutes(params = {}) {
    return getAvailableRoutes({
        routes: mainRoutes, ...params
    });
}

function getAvailableSigningRoutes(params = {}) {
    return getAvailableRoutes({
        routes: signerUiRoutes, ...params
    });
}

export {
    getAvailableAdminRoutes,
    getAvailableAccountRoutes,
    getAvailableMainRoutes,
    getAvailableSigningRoutes
};
