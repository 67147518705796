export const defaultButtonValue = 'x'; // this value indicates that chackbox or radio button is checked

export const FIELD_SETTING_TYPES = {
    SIGNER_NAME: 'signerName',
    SIGNING_DATE: 'signingDate',
    SIGNER_TITLE: 'signerTitle',
    SIGNER_COMPANY: 'signerCompany',
    SINGLE_LINE: 'textfield',
    MULTI_LINE: 'textarea',
    CHECKBOX: 'checkbox',
    DROPDOWN: 'list',
    RADIO: 'radio',
    DATEPICKER: 'datepicker',
    LABEL: 'label'
};

export const FIELD_TYPES = {
    SIGNATURE: 'SIGNATURE',
    INPUT: 'INPUT',
    RECIPIENT: 'RECIPIENT',
    FORM: 'FORM'
};

export const FIELD_SUBTYPES = {
    FULLNAME: 'FULLNAME',
    INITIALS: 'INITIALS',
    MOBILE_CAPTURE: 'MOBILE_CAPTURE',
    CAPTURE: 'CAPTURE',
    LABEL: 'LABEL',
    TEXTFIELD: 'TEXTFIELD',
    CHECKBOX: 'CHECKBOX',
    RADIO: 'RADIO',
    DATEPICKER: 'DATEPICKER',
    TEXTAREA: 'TEXTAREA',
    LIST: 'LIST',
    CUSTOMFIELD: 'CUSTOMFIELD',
    SEAL: 'SEAL'
};

export const SIGNATURE_FIELDS_SUBTYPES = {
    FULLNAME: 'FULLNAME',
    INITIALS: 'INITIALS',
    MOBILE_CAPTURE: 'MOBILE_CAPTURE',
    CAPTURE: 'CAPTURE'
};

export const AFTER_PLACEHOLDER = 'after-';
export const BEFORE_PLACEHOLDER = 'before-';
export const PLACEHOLDER_ANCHOR = 'placeholder-anchor-';
export const ROLE_ANCHOR = 'role-anchor-';
