// eslint-disable-next-line max-classes-per-file
import {
    useEffect, useMemo, useRef, useState
} from 'react';
import SmoothScrollbar, {ScrollbarPlugin} from 'smooth-scrollbar';
import {
    SCROLL_DIRECTION_DOWN,
    SCROLL_DIRECTION_UP,
    SCROLL_SPEED_MS
} from '../components/shared/Documents/constants';
import useWindowSize from './useWindowSize';
import {getFieldScaleFactorToFitViewport} from '../utils/scrollBar';
import {SCREEN_SIZE} from '../../constants';

const MOBILE_OPTIONS = {
    damping: 0.1,
    plugins: {
        mobile: {
            dampingOnMove: 0.05, // Needed to improve scroling smooth on mobile
            speed: 1.25
        }
    }
};
const DESKTOP_OPTIONS = {
    damping: 0.8,
    speed: 1
};

class MobilePlugin extends ScrollbarPlugin {
    static pluginName = 'mobile';

    static defaultOptions = DESKTOP_OPTIONS;

    transformDelta(delta, fromEvent) {
        if (fromEvent.type === 'touchmove') {
            // smooth-scrollbar magic. Needed to reduce glitches while scrolling
            this.scrollbar.options.damping = this.options.dampingOnMove;
        }

        return {
            x: delta.x * this.options.speed,
            y: delta.y * this.options.speed
        };
    }
}
SmoothScrollbar.use(MobilePlugin);

function initializeScroller(containerEl, isMobile) {
    const options = isMobile ? MOBILE_OPTIONS : DESKTOP_OPTIONS;
    const scrollBar = SmoothScrollbar.init(containerEl, options);

    return scrollBar;
}
function findBestZoomFactorToZoomField(fieldEl, containerEl) {
    const pageToContainerScaleFactor = Number(fieldEl.getAttribute('data-page-to-container-scale-factor'));
    const field = {
        width: fieldEl.offsetWidth * pageToContainerScaleFactor,
        height: fieldEl.offsetHeight * pageToContainerScaleFactor
    };
    const zoomLevels = [1, 1.5, 2, 2.5, 3, 3.5, 4];
    const screenWidth = containerEl.offsetWidth;
    const desiredWidth = screenWidth * 0.6;
    // Calculate the ratio of the desired width to the field's current width
    const desiredRatio = desiredWidth / field.width;

    // Sort zoom levels to find the closest match more easily
    zoomLevels.sort((a, b) => a - b);

    // Find the zoom level closest to the desired ratio
    let closestZoom = zoomLevels[0];
    let smallestDiff = Math.abs(desiredRatio - zoomLevels[0]);

    for (let i = 1; i < zoomLevels.length; i++) {
        const diff = Math.abs(desiredRatio - zoomLevels[i]);

        if (diff < smallestDiff) {
            closestZoom = zoomLevels[i];
            smallestDiff = diff;
        }
    }

    return closestZoom;
}

export default function useScrollBar({zoomFactor, setZoomFactor}) {
    const windowSize = useWindowSize();
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const containerEl = containerRef.current;
    const isMobile = windowSize.width <= SCREEN_SIZE.MD;
    const [scrollBar, setScrollBar] = useState(null);
    const [topOffset, setTopOffset] = useState(0);
    const [leftOffset, setLeftOffset] = useState(0);
    const [offsetToRestoreAfterZoomChange, setOffsetToRestoreAfterZoomChange] = useState(null);
    const [scrollDirection, setScrollDirection] = useState(SCROLL_DIRECTION_DOWN);
    const prevTopOffset = useRef(0);
    const scrollBarRef = useRef(null);
    const {
        size: {
            container: {
                width: containerWidth,
                height: containerHeight
            } = {},
            content: {
                width: contentWidth = 0,
                height: contentHeight = 0
            } = {}
        } = {}
    } = scrollBar || {};
    const containerHalfHeight = containerHeight / 2;
    const containerHalfWidth = containerWidth / 2;

    function restoreOffsetAfterReCalculation({
        updatedContentHeight, updatedContentWidth, prevOffset = {}
    }) {
        const {
            top,
            left
        } = offsetToRestoreAfterZoomChange || {};
        const {
            x: prevLeftOffsetXXX,
            y: prevTopOffsetXXX
        } = prevOffset;
        const zoomTopScale = updatedContentHeight / contentHeight;
        const zoomLeftScale = updatedContentWidth / contentWidth;
        const scaledTopCornerOffset = prevTopOffsetXXX * zoomTopScale;
        const scaledLeftCornerOffset = prevLeftOffsetXXX * zoomLeftScale;
        const scaledTopPositionToOffset = top ? (top * zoomTopScale) - containerHalfHeight : null;
        const scaledLeftPositionToOffset = left ? (left * zoomLeftScale) - containerHalfWidth : null;
        const topPosition = scaledTopPositionToOffset || scaledTopCornerOffset;
        const leftPosition = scaledLeftPositionToOffset || scaledLeftCornerOffset;

        setOffsetToRestoreAfterZoomChange(null);

        scrollBar.setPosition(leftPosition, topPosition);
    }

    function reCalculateScrollBar() {
        if (scrollBar) {
            const prevOffset = {...scrollBar.offset};

            scrollBar.update();
            scrollBar.track.update();

            const updatedContentHeight = scrollBar.getSize()?.content?.height;
            const updatedContentWidth = scrollBar.getSize()?.content?.width;

            restoreOffsetAfterReCalculation({updatedContentHeight, updatedContentWidth, prevOffset});
        }
    }

    /**
     * In the last call in the end of scrolling values will be the same.
     * This might set oposite direction.
     * Because of this listerer is called very often, it cause performance downgrade.
     */
    function getNewScrollDirection({newTopOffset}) {
        if (newTopOffset === prevTopOffset.current) {
            return null;
        }

        /**
         * If new offset is 0 - this is a page top, means direction can be DOWN only
         */
        const newScrollDirection = newTopOffset > prevTopOffset.current || newTopOffset === 0
            ? SCROLL_DIRECTION_DOWN
            : SCROLL_DIRECTION_UP;

        return newScrollDirection;
    }

    function scrollListener({offset}) {
        const newTopOffset = offset.y;
        const newLeftOffset = offset.x;
        const newScrollDirection = getNewScrollDirection({newTopOffset});
        setTopOffset(newTopOffset);
        setLeftOffset(newLeftOffset);

        if (newScrollDirection) {
            setScrollDirection(newScrollDirection);
        }

        prevTopOffset.current = newTopOffset;
    }
    function scrollIntoViewByClick({event}) {
        if (containerEl) {
            const {left, top} = containerEl.getBoundingClientRect();
            const eventTopOffsetFromContainer = event.clientY - top;
            const eventLeftOffsetFromContainer = event.clientX - left;
            const eventTopOffsetFromContent = eventTopOffsetFromContainer + topOffset;
            const eventLeftOffsetFromContent = eventLeftOffsetFromContainer + leftOffset;

            setOffsetToRestoreAfterZoomChange({
                top: eventTopOffsetFromContent,
                left: eventLeftOffsetFromContent
            });
        }
    }

    function getMiddleOfFieldOffsets({field}) {
        const {
            top,
            left,
            width,
            height,
            pageWithDimensions: {
                topBoundary: topPageBoundary,
                pageToContainerScaleAndZoomFactor = 1
            }
        } = field;
        const scaledTop = top * pageToContainerScaleAndZoomFactor;
        const scaledLeft = left * pageToContainerScaleAndZoomFactor;
        const scaledWidth = width * pageToContainerScaleAndZoomFactor;
        const scaledHeight = height * pageToContainerScaleAndZoomFactor;

        // Offsets of middle of the element from the document start (not page)
        const middleOfElementTopOffset = topPageBoundary + scaledTop + (scaledHeight / 2);
        const middleOfElementLeftOffset = scaledLeft + (scaledWidth / 2);

        return {
            middleOfElementTopOffset,
            middleOfElementLeftOffset
        };
    }

    function centerScrollToTheFieldView({field, speed = SCROLL_SPEED_MS}) {
        const {
            middleOfElementTopOffset,
            middleOfElementLeftOffset
        } = getMiddleOfFieldOffsets({field});

        // Offsets of the center of element in the center of view container
        const containerCenteredElementTopOffset = middleOfElementTopOffset - containerHalfHeight;
        const containerCenteredElementLeftOffset = middleOfElementLeftOffset - containerHalfWidth;

        // Keep offset inside boundaries
        const topScrollTo = Math.max(containerCenteredElementTopOffset, 0);
        const leftScrollTo = Math.max(containerCenteredElementLeftOffset, 0);
        scrollBar.scrollTo(leftScrollTo, topScrollTo, speed);
    }

    function scrollIntoViewOnFocus({field}) {
        const {
            width,
            pageWithDimensions: {
                pageToContainerScaleFactor
            }
        } = field;
        const originalScaledWidth = width * pageToContainerScaleFactor;
        const scaleFactorToFitViewport = getFieldScaleFactorToFitViewport({
            viewportWidth: containerWidth,
            fieldWidth: originalScaledWidth,
            isMobile: windowSize.isMobile
        });
        const shouldChangeZoomForField = scaleFactorToFitViewport !== zoomFactor;
        const {
            middleOfElementTopOffset,
            middleOfElementLeftOffset
        } = getMiddleOfFieldOffsets({field});

        if (shouldChangeZoomForField) {
            setOffsetToRestoreAfterZoomChange({
                top: middleOfElementTopOffset,
                left: middleOfElementLeftOffset
            });
            setZoomFactor(scaleFactorToFitViewport);
        } else {
            centerScrollToTheFieldView({field});
        }
    }

    function scrollIntoViewByFieldWithPage({field, isFocused}) {
        if (isFocused) {
            scrollIntoViewOnFocus({field});
        } else {
            centerScrollToTheFieldView({field});
        }
    }

    function centerFieldOnFocusZoom({
        fieldEl, onZoomChange, forcedZoom, scrollAfterZoomChange = true
    }) {
        if (containerEl) {
            const bestZoomFactor = findBestZoomFactorToZoomField(fieldEl, containerEl);
            // Here we allow user to zoom more if he wants, in this case field wount be zoomed but only centered.
            // forced zoom used to center the field and set a zoom to specific value. For example on text field blur event
            const zoomFactorToUse = forcedZoom || (zoomFactor < bestZoomFactor ? bestZoomFactor : zoomFactor);

            onZoomChange(zoomFactorToUse);
            // eslint-disable-next-line no-inner-declarations
            function scrollToField() {
                // Padding adds a space to prevent case when keyboard overlay field
                const mobileKeyboardOverlayPadding = 50;
                const field = {
                    top: Number(fieldEl.getAttribute('data-top')) + mobileKeyboardOverlayPadding,
                    left: Number(fieldEl.getAttribute('data-left')),
                    width: Number(fieldEl.getAttribute('data-width')),
                    height: Number(fieldEl.getAttribute('data-height')),
                    pageWithDimensions: {
                        topBoundary: Number(fieldEl.getAttribute('data-top-boundary')),
                        pageToContainerScaleAndZoomFactor: zoomFactorToUse * Number(fieldEl.getAttribute('data-page-to-container-scale-factor'))
                    }
                };
                centerScrollToTheFieldView({field, speed: 0});
            }
            return scrollAfterZoomChange ? setTimeout(scrollToField, 0) : scrollToField();
        }
    }

    useEffect(() => {
        if (containerEl && windowSize.isDefined) {
            const newScrollBar = scrollBar || initializeScroller(containerEl, isMobile);

            newScrollBar.addListener(scrollListener);
            scrollBarRef.current = newScrollBar;
            setScrollBar(newScrollBar);
            window.documentsScrollbar = newScrollBar;
        }

        return () => {
            if (scrollBar) {
                scrollBar.removeListener(scrollListener);
                scrollBar.destroy();
                delete window.documentsScrollbar;
            }
        };
    }, [containerEl, windowSize.isDefined]);

    return useMemo(() => ({
        containerRef,
        contentRef,
        scrollBar,
        topOffset,
        leftOffset,
        scrollDirection,
        scrollDimensions: scrollBar?.size || {},
        reCalculateScrollBar,
        scrollIntoViewByFieldWithPage,
        scrollIntoViewByClick,
        centerFieldOnFocusZoom
    }), [
        containerRef,
        contentRef,
        scrollBar,
        topOffset,
        leftOffset,
        scrollDirection,
        reCalculateScrollBar,
        scrollIntoViewByFieldWithPage,
        scrollIntoViewByClick,
        centerFieldOnFocusZoom
    ]);
}
