import React from 'react';

function DocumentsLayers(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 470.46 470.46"
            height="25px"
            xmlSpace="preserve"
            {...props}
        >
            <path d="M386.11 69.765V34.882h-34.882V0H131.905L49.468 82.437v318.259h34.883v34.883h34.882v34.882h301.76V69.765H386.11zM127.805 23.899v54.437H73.367l54.438-54.437zM63.468 92.336h78.337V14h195.424v372.695H63.468V92.336zm34.883 308.359h252.878V48.882h20.882v372.696H98.351v-20.883zm308.641 55.765h-273.76v-20.882H386.11V83.765h20.882V456.46z" />
            <path d="M103.882 109.431h192.932v14H103.882zM103.882 146.83h192.932v14H103.882zM103.882 184.229h192.932v14H103.882zM103.882 221.629h192.932v14H103.882zM103.882 259.028h192.932v14H103.882zM103.882 296.427h192.932v14H103.882zM103.882 333.826h192.932v14H103.882z" />
        </svg>
    );
}

export default DocumentsLayers;
