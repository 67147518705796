import {createSlice} from '@reduxjs/toolkit';

export const refs = createSlice({
    name: 'refs',
    initialState: {
        pageWrapperRef: null
    },
    reducers: {
        setPageWrapperRef: (state, action) => {
            state.pageWrapperRef = action.payload;
        }
    }
});

export const {
    setPageWrapperRef
} = refs.actions;

export const selectPageWrapperRef = (state) => state.refs.pageWrapperRef;
export default refs.reducer;
