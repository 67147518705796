import React from 'react';
import {useIntl} from 'react-intl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {REASSIGN_SCHEME_TYPES, REASSIGN_SCHEME_TYPES_KEYS} from '../../../../../../../constants';
import {useReassignView} from '../../../../../SignerUi/hooks/queries';
import {getSchemeFromAuthType} from '../../../../../SignerUi/utils/selectors';
import getSignerAuthByType from '../utils';
import QnA from './QnA/QnA';
import SMS from './SMS/SMS';

export default function ReassignAuth({auth, enforcedAuth, setAuth}) {
    const intl = useIntl();
    const {
        session: {authTypes = [], maskResponse}
    } = useReassignView();
    const {
        scheme,
        challenges
    } = auth;
    const isTypesSelectionDisabled = !!enforcedAuth;
    const enforcedType = enforcedAuth?.scheme;
    const enforcedKey = REASSIGN_SCHEME_TYPES_KEYS[enforcedType];
    const authTypesWithEnforcedType = enforcedType && !authTypes?.includes(enforcedKey)
        ? [...authTypes, enforcedKey] : authTypes;

    function changeScheme({target}) {
        const signerAuth = getSignerAuthByType({type: target.value, maskResponse});
        setAuth(signerAuth);
    }

    function changeChallenges(newChallenges) {
        setAuth({...auth, challenges: newChallenges});
    }

    return (
        <>
            <h4 className="subtitle">
                {intl.formatMessage({id: 'oss.components.Reassign.Auth.formTitle', defaultMessage: ''})}
            </h4>
            <div className="form-instruction">
                {isTypesSelectionDisabled
                    ? intl.formatMessage({id: 'reassign.typeChangeDisabled.description', defaultMessage: ''})
                    : intl.formatMessage({id: 'oss.components.Reassign.Auth.instructions', defaultMessage: ''})}
            </div>
            <RadioGroup
                value={scheme}
                onChange={changeScheme}
                classes={{root: 'auth-type-radio-group'}}
            >
                {authTypesWithEnforcedType.map((label) => {
                    const messagekey = label !== REASSIGN_SCHEME_TYPES.SSO 
                        ? `oss.components.Reassign.Auth.${REASSIGN_SCHEME_TYPES_KEYS[getSchemeFromAuthType(label)]}.label`
                        : 'esl.generic.sso';

                    return (
                        <FormControlLabel
                            data-wdio={`test-reassing-auth-${label.toLowerCase()}`}
                            value={getSchemeFromAuthType(label)}
                            control={<Radio size="small" disabled={isTypesSelectionDisabled} />}
                            label={intl.formatMessage({
                                id: messagekey,
                                defaultMessage: ''
                            })}
                            key={label}
                        />
                    );
                })}
            </RadioGroup>
            {scheme !== REASSIGN_SCHEME_TYPES.SSO && (
                <div className="form-instruction">
                    {intl.formatMessage({
                        id: `oss.components.Reassign.Auth.${REASSIGN_SCHEME_TYPES_KEYS[scheme]}.info`,
                        defaultMessage: ''
                    })}
                </div>
            )}
            {scheme === REASSIGN_SCHEME_TYPES.QNA && <QnA challenges={challenges} changeChallenges={changeChallenges} />}
            {scheme === REASSIGN_SCHEME_TYPES.SMS && <SMS challenges={challenges} changeChallenges={changeChallenges} />}
        </>
    );
}
