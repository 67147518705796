import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import React, {forwardRef} from 'react';
import './appButton.less';

// TODO to get rid of Button as a dependency needs to implement an animation on click and hover
export const AppButton = forwardRef(({
    className,
    children,
    variant,
    disabled,
    ...rest
}, ref) => {
    const containerClassName = classNames('app-button', className, {disabled});

    return (
        <Button
            ref={ref}
            className={containerClassName}
            disabled={disabled}
            {...rest}
        >
            {children}
        </Button>
    );
});
