import {
    fetchOverview,
    fetchSignerTransactions
} from '../dashboardSlice';

export default function ssrFetch({store, headers, baseUrl}) {
    return Promise.all([
        store.dispatch(fetchOverview({headers, baseUrl})),
        store.dispatch(fetchSignerTransactions({headers, baseUrl}))
    ]);
}
