import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash.get';
import {HiOutlineChatAlt} from '@react-icons/all-files/hi/HiOutlineChatAlt';
import {fetchTransaction, selectTransaction} from '../../../Transaction/transactionSlice';
import {getCurrentSessionId} from '../../../../utils/storageSelectors';
import {selectTransactionId} from '../../signerUiSlice';
import {SectionWithTitle} from '@components/Core/company/layouts/SectionWithTitle/SectionWithTitle';
import './emailMessage.less';

export default function EmailMessage({roleId, signerFullName}) {
    const intl = useIntl();
    const transactionId = useSelector(selectTransactionId);
    const dispatch = useDispatch();
    const transaction = useSelector(selectTransaction);
    const roles = get(transaction, 'roles', []);
    const userRole = roles.find(({id}) => id === roleId);
    const privateEmailMessage = get(userRole, 'emailMessage.content', null);
    const emailMessage = privateEmailMessage || transaction.emailMessage;
    const titleMessage = privateEmailMessage
        ? intl.formatMessage({id: 'esl.generic.personal_message_to', defaultMessage: ''}, {name: signerFullName})
        : intl.formatMessage({id: 'esl.generic.message_to_all_signers', defaultMessage: ''});

    function fetchTransactionData() {
        dispatch(fetchTransaction({
            headers: {
                token: getCurrentSessionId()
            },
            params: {
                packageId: transactionId,
                skipAvatarsFetch: true
            }
        }));
    }

    useEffect(() => {
        fetchTransactionData();
    }, []);

    if (!emailMessage) {
        return null;
    }

    return (
        <SectionWithTitle
            className="email-welcome-section"
            title={titleMessage}
            icon={(<HiOutlineChatAlt className="icon" aria-hidden />)}
        >
            {emailMessage}
        </SectionWithTitle>
    );
}
