import {
    connect,
    disconnect,
    passParameters,
    selectCertificate,
    sign
} from './nativeBridgeApi';

export const PCCBridge = {
    connection: null,
    certificateList: [],
    certificateDetails: {},
    version: '',
    handlers: {},

    connect(userHandlers) {
        const callUserHandler = (handler, arg) => {
            if (userHandlers[handler]) {
                userHandlers[handler](arg);
            }
        };

        this.handlers = {
            onCertificatesList: (data) => {
                this.certificateList = data;
                callUserHandler('onCertificatesList', data);
            },
            onConnectionFailure: (error) => {
                callUserHandler('onConnectionFailure', error);
            },
            onConnectionSuccess: (conn) => {
                this.connection = conn;
                callUserHandler('onConnectionSuccess', conn);
            },
            onError: (error) => {
                callUserHandler('onError', error);
            },
            onSignedToken: (token) => {
                callUserHandler('onSignedToken', token);
            },
            onSignerToken: (token) => {
                callUserHandler('onSignerToken', token);
            },
            onSocketError: (error) => {
                callUserHandler('onSocketError', error);
            },
            onVersion: (version) => {
                this.version = version;
                callUserHandler('onVersion', version);
            },
            retryConnection: userHandlers.retryConnection
        };

        if (!this.connection) {
            connect(this.handlers);
        }
    },

    getVersion() {
        return this.version;
    },

    sign(value) {
        sign(this.connection, value);
    },

    passCertificateSigningToken(value) {
        passParameters(this.connection, {
            certificateSigningToken: value
        });
    },

    selectCertificate(certificate) {
        selectCertificate(this.connection, certificate);
    },

    setCertificateDetails(certificate) {
        this.certificateDetails = certificate;
    },

    disconnect() {
        if (this.connection) {
            disconnect(this.connection);
            this.connection = null;
            this.certificateList = [];
            this.certificateDetails = {};
            this.version = '';
            this.handlers = {};
        }
    },

    reconnect(userHandlers) {
        const newHandlers = userHandlers || {...this.handlers};
        this.disconnect();
        this.connect(newHandlers);
    }
};
