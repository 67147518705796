import {useLayoutEffect, useState} from 'react';
import isClient from '../../../utils/isClient';

export const ORIENTATION = {
    PORTRAIT: 'portrait', // vertical
    LANDSCAPE: 'landscape' // horizontal
};

export const useScreenOrientation = () => {
    const getOrientation = () => {
        if (isClient) {
            return window.innerHeight > window.innerWidth ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE;
        }
    };

    const [orientation, setOrientation] = useState(getOrientation());

    const handleResize = () => {
        setOrientation(getOrientation());
    };

    useLayoutEffect(() => {
        if (isClient) {
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    return {
        orientation,
        getOrientation
    };
};
