import {gql} from '@apollo/client';

export const SIGNING_THEMES = gql`query signingThemes {
  signingThemes {
    colors {
      primary
      secondary
      success
      warning
      error
      info
      signatureButton
      optionalSignatureButton
      signatureSticky
      __typename 
    } 
    __typename
  }
}`;
