import loadable from '@loadable/component';
import {AiOutlineIdcard} from '@react-icons/all-files/ai/AiOutlineIdcard';
import {RiLockPasswordLine} from '@react-icons/all-files/ri/RiLockPasswordLine';
import {FaSignature} from '@react-icons/all-files/fa/FaSignature';
import {VscSettings} from '@react-icons/all-files/vsc/VscSettings';
import {TiGroupOutline} from '@react-icons/all-files/ti/TiGroupOutline';
import {AiOutlineForm} from '@react-icons/all-files/ai/AiOutlineForm';
import {AiFillIdcard} from '@react-icons/all-files/ai/AiFillIdcard';
import {RiLockPasswordFill} from '@react-icons/all-files/ri/RiLockPasswordFill';
import {HiUserGroup} from '@react-icons/all-files/hi/HiUserGroup';
import {HiOutlineDesktopComputer} from '@react-icons/all-files/hi/HiOutlineDesktopComputer';
import queryString from 'query-string';
import pick from 'lodash.pick';
import isEmpty from 'lodash.isempty';
import GroupIcon from '../components/shared/icons/Group';
import {
    ACCOUNT_PERSONAL_INFORMATION_PATH,
    ACCOUNT_CHANGE_PASSWORD_PATH,
    ACCOUNT_CAPTURE_SIGNATUES_PATH,
    ACCOUNT_PERSONAL_PREFERENCES_PATH,
    ACCOUNT_ACCESS_DELEGATION_PATH,
    ACCOUNT_CUSTOM_FIELDS_PATH,
    ACCOUNT_GROUPS_HEADINGS,
    ACCOUNT_GROUPS_PATH,
    DEFAULT_GROUPS_OPTIONS,
    ACCOUNT_GROUP_PATH,
    ACCOUNT_FEATURES_PATH
} from '../../constants';
import permissionsService from '../utils/permissionsService';

const defaultOptions = [
    'from',
    'to',
    'sort',
    'dir',
    'query',
    'search'
];
const groupsOptions = pick(DEFAULT_GROUPS_OPTIONS, defaultOptions);
const groupsQueryString = queryString.stringify(groupsOptions);

const routes = [
    {
        path: ACCOUNT_PERSONAL_INFORMATION_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "personalInfo" */ '../components/AccountSettings/PersonalInfo/PersonalInfo')
        ),
        titleKey: 'page.account.personalInformation',
        Icon: AiOutlineIdcard,
        FatIcon: AiFillIdcard,
        heading: ACCOUNT_GROUPS_HEADINGS.PROFILE
    }, {
        path: `${ACCOUNT_CHANGE_PASSWORD_PATH}`,
        component: loadable(
            () => import(/* webpackChunkName: "changePassword" */ '../components/AccountSettings/ChangePassword/ChangePassword')
        ),
        titleKey: 'page.account.changePassword',
        Icon: RiLockPasswordLine,
        FatIcon: RiLockPasswordFill,
        heading: ACCOUNT_GROUPS_HEADINGS.PROFILE
    }, {
        path: ACCOUNT_CAPTURE_SIGNATUES_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "captureSignature" */ '../components/AccountSettings/CaptureSignature/CaptureSignature')
        ),
        titleKey: 'page.account.captureSignature',
        Icon: FaSignature,
        heading: ACCOUNT_GROUPS_HEADINGS.PROFILE
    }, {
        path: ACCOUNT_PERSONAL_PREFERENCES_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "preferences" */ '../components/AccountSettings/Preferences/Preferences')
        ),
        titleKey: 'page.account.personalPreferences',
        Icon: VscSettings,
        heading: ACCOUNT_GROUPS_HEADINGS.SETTINGS
    }, {
        path: ACCOUNT_ACCESS_DELEGATION_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "accessDelegation" */ '../components/AccountSettings/AccessDelegation/AccessDelegation')
        ),
        titleKey: 'page.account.accessDelegation',
        Icon: TiGroupOutline,
        FatIcon: HiUserGroup,
        heading: ACCOUNT_GROUPS_HEADINGS.SETTINGS
    }, {
        path: ACCOUNT_CUSTOM_FIELDS_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "customFields" */ '../components/AccountSettings/CustomFields/CustomFields')
        ),
        titleKey: 'page.account.customFields',
        Icon: AiOutlineForm,
        heading: ACCOUNT_GROUPS_HEADINGS.SETTINGS
    }, {
        path: `${ACCOUNT_GROUPS_PATH}`,
        query: groupsQueryString,
        component: loadable(
            () => import(/* webpackChunkName: "groups" */ '../components/AdminPanel/Groups/AccountGroups')
        ),
        titleKey: 'esl.generic.groups',
        Icon: GroupIcon,
        isEnabled: permissionsService.hasAccountGroupManagementPermission,
        heading: ACCOUNT_GROUPS_HEADINGS.SETTINGS
    }, {
        path: `${ACCOUNT_GROUP_PATH}/:groupId?`,
        component: loadable(
            () => import(/* webpackChunkName: "groupDetails" */ '../components/AdminPanel/Groups/GroupDetails/AccountGroupDetails')
        ),
        titleKey: 'esl.generic.group',
        isInnerPage: true,
        isEnabled: permissionsService.hasAccountGroupManagementPermission
    }, {
        path: `${ACCOUNT_FEATURES_PATH}`,
        component: loadable(
            () => import(/* webpackChunkName: "features" */ '../components/AccountSettings/Features/Features')
        ),
        titleKey: 'page.account.features',
        Icon: HiOutlineDesktopComputer,
        heading: ACCOUNT_GROUPS_HEADINGS.SETTINGS,
        // isEnabled: ({vsFeatures, vsAccountFeatures} = {}) => !isEmpty(vsFeatures) || !isEmpty(vsAccountFeatures)
    }
];

export default routes;
