import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {RiErrorWarningFill} from '@react-icons/all-files/ri/RiErrorWarningFill';
import './fieldMessageTooltip.less';

const FieldMessageTooltip = ({open, children, title = ''}) => {
    const titleNode = (
        <span className="tooltip-title-container">
            <RiErrorWarningFill className="warning-icon" />
            {title}
        </span>
    );
    return (
        <Tooltip
            placement="bottom"
            title={titleNode}
            open={!!open}
            classes={{tooltip: 'signing-field-tooltip', popper: 'signing-field-tooltip-popper'}}
        >
            {children}
        </Tooltip>
    );
};
export default FieldMessageTooltip;
