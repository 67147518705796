import {memo} from 'react';
import {Document} from 'react-pdf';
import classnames from 'classnames';
import PageRenderer from '../PageRenderer/PageRenderer';
import usePdfDocument from '../../hooks/usePdfDocument';
import {getActivePageId, getPageWithDimensions, pdfDocumentOptions} from '../../utils';
import Loading from '../../../Loading/Loading';

function DocumentRenderer({
    transactionId,
    document = {},
    pagesWithDimensions,
    renderedPagesIds,
    topOffset,
    leftOffset,
    scrollDimensions,
    pageProps,
    shouldRenderFooter,
    isFooterVisible,
    documentProps: {
        onLoadSuccess = () => {},
        renderChild = () => {},
        renderPages,
        keyPrefix,
        ...documentPropsRest
    },
    pageFooterProps
}) {
    const {
        id,
        name,
        pages = []
    } = document;
    const documentClassName = classnames('document-renderer');
    const file = usePdfDocument({
        transactionId,
        document,
        role: documentPropsRest?.role
    });
    const activePageId = getActivePageId({topOffset, pagesWithDimensions});
    const key = `${id}${keyPrefix ? `-${keyPrefix}` : ''}`;

    function handleLoadSuccess() {
        onLoadSuccess({id});
    }

    function renderPage(page, index) {
        const pageWithDimensions = getPageWithDimensions({pageIndex: page.index, pagesWithDimensions, documentId: id});
        const isRendered = renderedPagesIds.includes(pageWithDimensions.pageId);
        const isActive = activePageId === pageWithDimensions.pageId;
        const isLastPage = index === pages.length - 1;

        return (
            <PageRenderer
                isFooterVisible={isFooterVisible}
                pageWithDimensions={pageWithDimensions}
                documentName={name}
                totalPages={pages.length}
                isRendered={isRendered}
                isActive={isActive}
                topOffset={topOffset}
                leftOffset={leftOffset}
                scrollDimensions={scrollDimensions}
                pageProps={pageProps}
                pageFooterProps={pageFooterProps}
                isLastPage={isLastPage}
                shouldRenderFooter={shouldRenderFooter}
                key={pageWithDimensions.pageId}
            />
        );
    }

    return (
        <Document
            file={file}
            error="error"
            loading={<Loading isLoading isSceleton className="document-loader" />}
            className={documentClassName}
            options={pdfDocumentOptions}
            externalLinkTarget="_blank"
            onLoadSuccess={handleLoadSuccess}
            key={key}
            {...documentPropsRest}
        >
            <>
                {renderPages ? renderPages({pages, renderPage}) : pages.map(renderPage)}
                {renderChild()}
            </>

        </Document>
    );
}

export default memo(DocumentRenderer);
