import {useEffect} from 'react';
import {useAuditEvent} from './mutations';
import {useCeremony} from './queries';

const GEOLOCATION_AUDIT_TYPE = 'GEOLOCATION';

const getGeolocationPayload = ({transactionId, email, data}) => {
    const {latitude = null, longitude = null} = data.coords || {};
    const allowed = !(data.code && data.PERMISSION_DENIED);
    let geolocation = {allowed};

    if (latitude && longitude) {
        geolocation = {
            ...geolocation,
            longitude,
            latitude
        };
    }

    return {
        variables: {
            transactionId,
            auditType: GEOLOCATION_AUDIT_TYPE,
            recipient: email,
            data: {
                destination: email,
                geolocation
            }
        }
    };
};

export const useGeoLocationEvent = () => {
    const {
        role: {
            email
        },
        transaction: {
            id: transactionId,
            settings: {
                ceremony: {geolocation} = {}
            } = {}
        }
    } = useCeremony();
    const {sendAuditEvent} = useAuditEvent();

    useEffect(() => {
        if (geolocation && window.navigator.geolocation) {
            const eventCallback = (data) => sendAuditEvent(getGeolocationPayload({transactionId, email, data}));

            window.navigator.geolocation.getCurrentPosition(eventCallback, eventCallback);
        }
    }, []);
};
