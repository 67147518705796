import {gql} from '@apollo/client';

export default gql`query getExternalVerification(
  $documentId: ID!
  $transactionId: ID!
  $callbackUrl: String!
) {
  externalVerification(
    documentId: $documentId
    transactionId: $transactionId
    callbackUrl: $callbackUrl
  ) {
    url
  }
}`;
