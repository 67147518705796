/* eslint-disable import/prefer-default-export */
import {gql} from '@apollo/client/core';

export const updateCache = ({transactionId}) => (cache, {data}) => {
    const status = data?.decline?.status;
    cache.writeFragment({
        data: {
            __typename: 'Transaction',
            id: transactionId,
            status,
            isDeclined: status === 'DECLINED'
        },
        fragment: gql`
        fragment isDeclinedTransaction on Transaction {
          isDeclined
          status
        }
      `,
        id: `Transaction:${transactionId}`
    });
};
