import React from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import isEmpty from 'lodash.isempty';
import {selectSenderSummary} from '../../reportsSlice';
import Row from './Row/Row';
import {TRANSACTION_SUMMARY_STATUSES} from '../../../../../constants';
import MobileRow from './Row/MobileRow';
import './sendersSummary.less';

export default function SendersSummary({startDate, endDate}) {
    const intl = useIntl();
    const sendersSummary = useSelector(selectSenderSummary);

    return (
        <div className="senders-summary">
            <Table classes={{root: 'senders-summary-table'}}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            {intl.formatMessage({id: 'esl.generic.sender', defaultMessage: ''})}
                        </TableCell>
                        {TRANSACTION_SUMMARY_STATUSES.map((status) => (
                            <TableCell align="left" key="status">
                                {intl.formatMessage({id: `esl.package.status.${status}`, defaultMessage: ''})}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!isEmpty(sendersSummary)
                        ? sendersSummary.map((senderSummary) => (
                            <Row
                                senderSummary={senderSummary}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        ))
                        : (
                            <TableRow classes={{root: 'summary-empty-row'}}>
                                <TableCell colSpan={9} align="center">
                                    {intl.formatMessage({id: 'esl.partials.reports.select_before_submit', defaultMessage: ''})}
                                </TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
            <div className="mobile-list">
                {!isEmpty(sendersSummary)
                    ? sendersSummary.map((senderSummary) => (
                        <MobileRow
                            senderSummary={senderSummary}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    )) : (
                        <div className="empty-mobile-row">
                            {intl.formatMessage({id: 'esl.partials.reports.select_before_submit', defaultMessage: ''})}
                        </div>
                    )}
            </div>
        </div>
    );
}
