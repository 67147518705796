import React from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {HiInformationCircle} from '@react-icons/all-files/hi/HiInformationCircle';
import isEmpty from 'lodash.isempty';
import {selectInfoLinks} from '../../appSlice';
import useJoinMessage from '../../../hooks/useJoinMessage';
import './infoLink.less';

export default function InfoLink({infoKey, aboutText, focusId}) {
    const intl = useIntl();
    const joinMessage = useJoinMessage();
    const links = useSelector(selectInfoLinks);
    const infoLinkByKey = links.filter(({key, url}) => infoKey === key && url);

    if (isEmpty(infoLinkByKey)) {
        return null;
    }

    function navigateLink(e) {
        e.stopPropagation();
        window.open(infoLinkByKey[0].url, '_blank');
    }

    const additionalText = aboutText ? joinMessage('shared.about', aboutText) : '';
    const buttonLabel = joinMessage('shared.learnMore', additionalText);
    return (
        <Tooltip
            placement="top"
            title={intl.formatMessage({id: 'shared.learnMore', defaultMessage: ''})}
            arrow
        >
            <IconButton
                size="small"
                edge="end"
                color="inherit"
                role="link"
                classes={{root: `info-link ${infoKey}-info-link`}}
                aria-label={buttonLabel}
                onClick={navigateLink}
                data-focus-id={focusId}
            >
                <HiInformationCircle
                    className="info-icon"
                    aria-hidden="true"
                />
            </IconButton>
        </Tooltip>
    );
}
