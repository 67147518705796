import {useEffect} from 'react';

/**
 * This hoook use dynamic imports to import all necessary modules to be able to render graphical signature
 */
export default function useInitializeGraphicalSignature() {
    useEffect(() => {
        Promise.all([
            import(/* webpackChunkName: "signaturePad" */ '../../AccountSettings/CaptureSignature/utils/signaturePad/signaturePad'),
            import(/* webpackChunkName: "jSignature" */ 'jsignature/libs/jSignature.min')
        ]);
    }, []);
}
