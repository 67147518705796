/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import httpClient from '../../utils/httpClient';
import {loadTranslations, setLocale, setTheme} from '../appSlice';
import {
    BASE_PATH, FEATURE_USER_LEVEL, LOGOUT_PATH, USER_TYPES, VS_FEATURES
} from '../../../constants';
import DEFAULT_PERMISSIONS from '../../utils/defaultPermissions';
import {fetchPackageSettings} from '../Dashboard/dashboardSlice';
import {getErrorMessage} from '../../utils/requests/error';
import {fetchTransactionsSummary} from '../Transactions/transactionsSlice';

function getDefaultPermissions(user) {
    const isManager = get(user, 'user.type', USER_TYPES.MEMBER) === USER_TYPES.MANAGER;
    const isOwner = get(user, 'account.owner', '') === get(user, 'user.id', '');

    if (isOwner) {
        return DEFAULT_PERMISSIONS[USER_TYPES.OWNER];
    }
    if (isManager) {
        return DEFAULT_PERMISSIONS[USER_TYPES.MANAGER];
    }
    return DEFAULT_PERMISSIONS[USER_TYPES.MEMBER];
}

const initialUserData = {
    user: {},
    account: {},
    restricted: false,
    packages: [],
    features: {},
    permissions: [],
    hasUserPermissionOnRoot: false,
    delegationUser: null,
    loginSessionTimeout: 1800000,
    support: {
        email: 'support@validsign.eu',
        phone: '+31(0)853033676'
    },
    inPerson: false,
    certificateSigning: false,
    logoutPath: LOGOUT_PATH,
    senderExpiryRedirect: LOGOUT_PATH
};

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        ...initialUserData,
        isLoading: false,
        authError: null,
        isAuthenticating: false,
        isSavingUser: false,
        saveUserError: null,
        isPasswordReseting: false,
        isPasswordUpdating: false,
        passwordUpdateError: null,
        wasPasswordReset: false,
        passwordResetError: null,
        authenticationToken: null,
        isSessionInfoFetching: false,
        sessionFetchingError: null,
        wasFetchedSession: false,
        senderTokens: [],
        ssoIdpDiscoReturnURL: '',
        ssoIdps: [],
        ssoLoginRequired: false,
        isSsoIdpsFetching: false,
        emailSsoIdpsFetchedFor: '',
        ssoIdpsFetchingError: null,
        tokenOldUiLoggedInWith: null,
        permissions: [],
        hasUserPermissionOnRoot: false,
        activeAccountId: null,
        isFetchingResetPasswordUserInfo: false,
        resetPasswordUserInfoError: null,
        isFetchingPasswordPolicy: false,
        passwordPolicyError: null,
        isFetchingUserActivationInfo: false,
        userActivationInfoError: null,
        isActivatingUser: false,
        activatingUserError: null,
        isLoggingOut: false,
        imageSignature: {},
        vsFeatures: [],
        vsAccountFeatures: [],
        isFeaturesFetching: false,
        featuresFetchError: null,
        isFeatureSwitching: false,
        featureSwitchError: null
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setAccount: (state, action) => {
            state.account = action.payload;
        },
        setRestricted: (state, action) => {
            state.restricted = action.payload;
        },
        setPackages: (state, action) => {
            state.packages = action.payload;
        },
        setFeatures: (state, action) => {
            state.features = action.payload;
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload;
        },
        setHasUserPermissionOnRoot: (state, action) => {
            state.hasUserPermissionOnRoot = action.payload;
        },
        setDelegationUser: (state, action) => {
            state.delegationUser = action.payload;
        },
        setLoginSessionTimeout: (state, action) => {
            state.loginSessionTimeout = action.payload;
        },
        setSupport: (state, action) => {
            state.support = action.payload;
        },
        setInPerson: (state, action) => {
            state.inPerson = action.payload;
        },
        setCertificateSigning: (state, action) => {
            state.certificateSigning = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setAuthError: (state, action) => {
            state.authError = action.payload;
        },
        setIsAuthenticating: (state, action) => {
            state.isAuthenticating = action.payload;
        },
        setIsSavingUser: (state, action) => {
            state.isSavingUser = action.payload;
        },
        setSaveUserError: (state, action) => {
            state.saveUserError = action.payload;
        },
        setWasPasswordReset: (state, action) => {
            state.wasPasswordReset = action.payload;
        },
        setIsPasswordReseting: (state, action) => {
            state.isPasswordReseting = action.payload;
        },
        setPasswordResetError: (state, action) => {
            state.passwordResetError = action.payload;
        },
        setIsPasswordUpdating: (state, action) => {
            state.isPasswordUpdating = action.payload;
        },
        setPasswordUpdateError: (state, action) => {
            state.passwordUpdateError = action.payload;
        },
        setAuthenticationToken: (state, action) => {
            state.authenticationToken = action.payload;
        },
        setSenderTokens: (state, action) => {
            state.senderTokens = action.payload;
        },
        setIsSessionInfoFetching: (state, action) => {
            state.isSessionInfoFetching = action.payload;
        },
        setWasFetchedSession: (state, action) => {
            state.wasFetchedSession = action.payload;
        },
        setSsoIdps: (state, action) => {
            state.ssoIdps = action.payload;
        },
        setSsoIdpDiscoReturnURL: (state, action) => {
            state.ssoIdpDiscoReturnURL = action.payload;
        },
        setIsSsoIdpsFetching: (state, action) => {
            state.isSsoIdpsFetching = action.payload;
        },
        setSsoIdpsFetchingError: (state, action) => {
            state.ssoIdpsFetchingError = action.payload;
        },
        setSsoLoginRequired: (state, action) => {
            state.ssoLoginRequired = action.payload;
        },
        setEmailSsoIdpsFetchedFor: (state, action) => {
            state.emailSsoIdpsFetchedFor = action.payload;
        },
        setTokenOldUiLoggedInWith: (state, action) => {
            state.tokenOldUiLoggedInWith = action.payload;
        },
        setActiveAccountId: (state, action) => {
            state.activeAccountId = action.payload;
        },
        setIsFetchingResetPasswordUserInfo: (state, action) => {
            state.isFetchingResetPasswordUserInfo = action.payload;
        },
        setResetPasswordUserInfoError: (state, action) => {
            state.resetPasswordUserInfoError = action.payload;
        },
        setIsFetchingPasswordPolicy: (state, action) => {
            state.isFetchingPasswordPolicy = action.payload;
        },
        setPasswordPolicyError: (state, action) => {
            state.passwordPolicyError = action.payload;
        },
        setIsFetchingUserActivationInfo: (state, action) => {
            state.isFetchingUserActivationInfo = action.payload;
        },
        setUserActivationInfoError: (state, action) => {
            state.userActivationInfoError = action.payload;
        },
        setIsActivatingUser: (state, action) => {
            state.isActivatingUser = action.payload;
        },
        setActivatingUserError: (state, action) => {
            state.activatingUserError = action.payload;
        },
        setIsLoggingOut: (state, action) => {
            state.isLoggingOut = action.payload;
        },
        setLogoutPath: (state, action) => {
            state.logoutPath = action.payload;
        },
        setSenderExpiryRedirect: (state, action) => {
            state.senderExpiryRedirect = action.payload;
        },
        setImageSignature: (state, action) => {
            state.imageSignature = action.payload;
        },
        setIsFetchingImageSignature: (state, action) => {
            state.isFetchingImageSignature = action.payload;
        },
        setVSFeatures: (state, action) => {
            state.vsFeatures = action.payload;
        },
        setVSAccountFeatures: (state, action) => {
            state.vsAccountFeatures = action.payload;
        },
        setIsFeaturesFetching: (state, action) => {
            state.isFeaturesFetching = action.payload;
        },
        setIsFeatureSwitching: (state, action) => {
            state.isFeatureSwitching = action.payload;
        },
        setFeaturesFetchError: (state, action) => {
            state.featuresFetchError = action.payload;
        },
        setFeatureSwitchError: (state, action) => {
            state.featureSwitchError = action.payload;
        }
    }
});

export const {
    setUser,
    setAccount,
    setRestricted,
    setPackages,
    setPermissions,
    setHasUserPermissionOnRoot,
    setFeatures,
    setDelegationUser,
    setLoginSessionTimeout,
    setSupport,
    setInPerson,
    setCertificateSigning,
    setIsLoading,
    setAuthError,
    setIsAuthenticating,
    setIsSavingUser,
    setSaveUserError,
    setWasPasswordReset,
    setIsPasswordReseting,
    setPasswordResetError,
    setIsPasswordUpdating,
    setPasswordUpdateError,
    setAuthenticationToken,
    setSenderTokens,
    setIsSessionInfoFetching,
    setWasFetchedSession,
    setSsoIdpDiscoReturnURL,
    setSsoIdps,
    setIsSsoIdpsFetching,
    setSsoIdpsFetchingError,
    setSsoLoginRequired,
    setEmailSsoIdpsFetchedFor,
    setTokenOldUiLoggedInWith,
    setResetPasswordUserInfo,
    setIsFetchingResetPasswordUserInfo,
    setResetPasswordUserInfoError,
    setIsFetchingPasswordPolicy,
    setPasswordPolicyError,
    setActiveAccountId,
    setIsFetchingUserActivationInfo,
    setUserActivationInfoError,
    setIsActivatingUser,
    setActivatingUserError,
    setImageSignature,
    setIsFetchingImageSignature,
    setLogoutPath,
    setSenderExpiryRedirect,
    setIsLoggingOut,
    setVSFeatures,
    setVSAccountFeatures,
    setIsFeaturesFetching,
    setFeaturesFetchError,
    setIsFeatureSwitching,
    setFeatureSwitchError
} = userSlice.actions;

export const setSession = (session = {}) => async (dispatch) => {
    const isRolesAndPermissionsEnabled = get(session, 'features.rolesAndPermissions', false);

    dispatch(setUser(session.user));
    dispatch(setAccount(session.account));
    dispatch(setRestricted(session.restricted));
    dispatch(setPackages(session.packages));
    dispatch(setFeatures(session.features));
    dispatch(setPermissions(session.permissions));
    dispatch(setHasUserPermissionOnRoot(session.hasUserPermissionOnRoot));
    dispatch(setDelegationUser(session.delegationUser));
    dispatch(setLoginSessionTimeout(session.loginSessionTimeout));
    dispatch(setSupport(session.support));
    dispatch(setInPerson(session.inPerson));
    dispatch(setCertificateSigning(session.certificateSigning));
    dispatch(setActiveAccountId(session.activeAccountId));

    if (!isRolesAndPermissionsEnabled) {
        dispatch(setPermissions(getDefaultPermissions(session)));
    } else {
        dispatch(setPermissions(session.permissions));
    }
};

export const fetchSessionInfo = () => async (dispatch) => {
    dispatch(setIsSessionInfoFetching(true));
    dispatch(setWasFetchedSession(false));

    try {
        const result = await httpClient.get(`${BASE_PATH}/proxy/session`);
        dispatch(setSession(result.data));
        dispatch(setIsSessionInfoFetching(false));
        dispatch(setWasFetchedSession(true));
        return result.data;
    } catch (err) {
        dispatch(setWasFetchedSession(false));
        dispatch(setIsSessionInfoFetching(false));
        return false;
    }
};

export const fetchAuthenticationToken = () => async (dispatch) => {
    try {
        const result = await httpClient.post(`${BASE_PATH}/proxy/authenticationTokens/user`);
        dispatch(setAuthenticationToken(get(result, 'data.value', null)));
        dispatch(setTokenOldUiLoggedInWith(false));
        return result.data;
    } catch (err) {
        return false;
    }
};

export const fetchSenderToken = ({
    headers, baseUrl, params
} = {}) => async (dispatch, getState) => {
    const {senderTokens} = getState().user;
    const {packageId} = params;
    const servicePath = 'authenticationTokens/sender';
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const isSSR = !!baseUrl;
    const options = {};
    if (headers) {
        options.headers = headers;
    }
    try {
        const result = await httpClient.post(url, {
            packageId
        }, options);
        const updatedTokens = [...senderTokens];
        const updatedToken = {
            packageId: get(result, 'data.packageId', null),
            token: get(result, 'data.value', null),
            isSSR
        };
        const packageIndex = updatedTokens.findIndex(({packageId: prevPackageId}) => packageId === prevPackageId);

        if (packageIndex >= 0) {
            updatedTokens[packageIndex] = updatedToken;
        } else {
            updatedTokens.push(updatedToken);
        }

        dispatch(setSenderTokens(updatedTokens));
        return result.data;
    } catch (err) {
        return false;
    }
};

export const fetchLogoutRedirect = ({
    headers, baseUrl
} = {}) => async (dispatch) => {
    try {
        const options = {};
        const url = baseUrl ? `${baseUrl}/api/session/logout_redirect` : `${BASE_PATH}/proxy/session/logout_redirect`;
        if (headers) {
            options.headers = headers;
        }
        const result = await httpClient.get(url, options);
        const logoutPath = get(result, 'data.logoutRedirect', LOGOUT_PATH);
        const senderExpiryRedirect = get(result, 'data.senderExpiryRedirect', LOGOUT_PATH);
        dispatch(setLogoutPath(logoutPath));
        dispatch(setSenderExpiryRedirect(senderExpiryRedirect));
        return logoutPath;
    } catch (err) {
        return false;
    }
};

export const fetchVSFeatures = ({
    headers, baseUrl, userId, accountId
} = {}) => async (dispatch, getState) => {
    const {
        user: userState
    } = getState();
    const {
        user,
        delegationUser,
        account
    } = userState;
    const storeUserId = delegationUser ? delegationUser.id : user.id;
    const uId = userId || storeUserId;
    const aId = accountId || account?.id;
    const accountLevelServicePath = `api/frontend/features/account/${aId}`;
    const userLevelServicePath = `api/frontend/features/user/${uId}`;
    const accountLevelUrl = baseUrl ? `${baseUrl}/${accountLevelServicePath}` : `${BASE_PATH}/vip/${accountLevelServicePath}`;
    const userLevelUrl = baseUrl ? `${baseUrl}/${userLevelServicePath}` : `${BASE_PATH}/vip/${userLevelServicePath}`;
    const shouldSkip = !uId || !aId;
    const options = {
        validateStatus: null
    };

    if (headers) {
        options.headers = headers;
    }

    if (shouldSkip) {
        return false;
    }

    dispatch(setIsFeaturesFetching(true));

    try {
        const accountLevelResult = await httpClient.get(accountLevelUrl, options);
        const userLevelResult = await httpClient.get(userLevelUrl, options);
        const userFeatures = get(userLevelResult, 'data.data', []);
        const accountFeatures = get(accountLevelResult, 'data.data', []);
        dispatch(setVSFeatures(userFeatures));
        dispatch(setVSAccountFeatures(accountFeatures));
        dispatch(setIsFeaturesFetching(false));

        return {userFeatures, accountFeatures};
    } catch (err) {
        dispatch(setIsFeaturesFetching(false));
        dispatch(setFeaturesFetchError(getErrorMessage(err)));

        return false;
    }
};

export const switchFeature = ({level, key, shouldActivate}) => async (dispatch, getState) => {
    const {
        user: userState
    } = getState();
    const {
        user,
        delegationUser,
        account
    } = userState;
    const userId = delegationUser ? delegationUser.id : user.id;
    const accountId = account.id;
    const levelId = level === FEATURE_USER_LEVEL ? userId : accountId;
    const action = shouldActivate ? 'activate' : 'deactivate';
    const servicePath = `api/frontend/features/${level}/${levelId}/${action}`;
    const url = `${BASE_PATH}/vip/${servicePath}`;

    dispatch(setFeatureSwitchError(null));
    dispatch(setIsFeatureSwitching(true));

    try {
        await httpClient.post(url, {feature_key: key});
        dispatch(fetchVSFeatures());
        dispatch(setIsFeatureSwitching(false));
        return true;
    } catch (err) {
        dispatch(setFeatureSwitchError({
            messageKey: err?.data?.error
        }));
        dispatch(setIsFeatureSwitching(false));
        return false;
    }
};

export const authenticate = (options = {}) => async (dispatch) => {
    const payload = options.credentials;

    dispatch(setIsAuthenticating(true));
    dispatch(setAuthError(null));
    dispatch(setPasswordResetError(null));

    try {
        await httpClient.get(`${BASE_PATH}/clearcookie`);
        const result = await httpClient.post(`${BASE_PATH}/proxyAuth`, payload);
        if (result.data) {
            try {
                const session = await dispatch(fetchSessionInfo());
                const locale = get(session, 'user.language');
                const theme = get(session, 'user.data.theme');
                await dispatch(fetchAuthenticationToken());
                await dispatch(fetchPackageSettings());
                await dispatch(fetchLogoutRedirect());
                await dispatch(fetchVSFeatures({
                    userId: get(session, 'user.id'),
                    accountId: get(session, 'account.id')
                }));
                await dispatch(fetchTransactionsSummary());
                dispatch(setLocale({locale, shouldSetCookie: true}));
                dispatch(setTheme({theme, shouldSetCookie: true}));
                dispatch(loadTranslations());
                dispatch(setIsAuthenticating(false));
            } catch {
                dispatch(setIsAuthenticating(false));
            }
        }
        return true;
    } catch (err) {
        dispatch(setAuthError(getErrorMessage(err)));
        dispatch(setIsAuthenticating(false));
        return false;
    }
};

export const saveUserPreferences = (user) => async (dispatch) => {
    dispatch(setIsSavingUser(true));
    dispatch(setSaveUserError(null));

    try {
        await httpClient.post(`${BASE_PATH}/proxy/preferences/${user.id}`, user);

        dispatch(setUser(user));
        dispatch(setIsSavingUser(false));
        return true;
    } catch (err) {
        dispatch(setSaveUserError(getErrorMessage(err)));
        dispatch(setIsSavingUser(false));
        return false;
    }
};

export const resetPassword = (email = {}) => async (dispatch) => {
    dispatch(setIsPasswordReseting(true));
    dispatch(setAuthError(null));
    dispatch(setPasswordResetError(null));

    try {
        await httpClient.post(`${BASE_PATH}/baseProxy/a/router-password`, {email});

        dispatch(setWasPasswordReset(true));
        dispatch(setIsPasswordReseting(false));
        return true;
    } catch (err) {
        if (err?.status === 404) {
            dispatch(setPasswordResetError({
                messageKey: 'page.resetPassword.forbiddenError'
            }));
        }
        dispatch(setWasPasswordReset(false));
        dispatch(setIsPasswordReseting(false));
        return false;
    }
};
export const getActivationInfo = (token) => async (dispatch) => {
    dispatch(setIsFetchingUserActivationInfo(true));
    dispatch(setUserActivationInfoError(null));
    try {
        const url = `${BASE_PATH}/baseProxy/a/register?token=${token}`;
        const {data} = await httpClient.get(url);
        dispatch(setIsFetchingUserActivationInfo(false));

        return data;
    } catch (err) {
        dispatch(setUserActivationInfoError(getErrorMessage(err)));
        dispatch(setIsFetchingUserActivationInfo(false));
        return false;
    }
};

export const activateUser = ({token, form}) => async (dispatch) => {
    dispatch(setIsActivatingUser(true));
    dispatch(setActivatingUserError(null));

    try {
        const url = `${BASE_PATH}/baseProxy/a/register?token=${token}`;
        const {data} = await httpClient.post(url, form);
        dispatch(setIsActivatingUser(false));

        return data;
    } catch (err) {
        dispatch(setIsActivatingUser(false));
        dispatch(setActivatingUserError(getErrorMessage(err)));

        return false;
    }
};
export const updatePassword = (form = {}) => async (dispatch) => {
    dispatch(setIsPasswordUpdating(true));
    dispatch(setPasswordUpdateError(null));

    try {
        await httpClient.put(`${BASE_PATH}/baseProxy/a/password`, form);
        dispatch(setIsPasswordUpdating(false));
        return true;
    } catch (err) {
        dispatch(setIsPasswordUpdating(false));
        dispatch(setPasswordUpdateError(getErrorMessage(err)));

        return false;
    }
};

export const logout = () => async (dispatch) => {
    dispatch(setIsLoggingOut(true));

    try {
        const result = await httpClient.get(`${BASE_PATH}/proxy/session/logout_redirect`);
        await httpClient.delete(`${BASE_PATH}/proxyAuth`);
        await httpClient.get(`${BASE_PATH}/clearcookie`);
        dispatch(setIsLoggingOut(false));
        return get(result, 'data.logoutRedirect');
    } catch (err) {
        dispatch(setIsLoggingOut(false));
        return false;
    }
};

export const clearCookie = () => async () => {
    try {
        await httpClient.get(`${BASE_PATH}/clearcookie`);
        return true;
    } catch (err) {
        return false;
    }
};

export const fetchResetPasswordUserInfo = (passwordToken) => async (dispatch) => {
    dispatch(setIsFetchingResetPasswordUserInfo(true));
    dispatch(setResetPasswordUserInfoError(null));

    try {
        const url = `${BASE_PATH}/baseProxy/a/password?passwordToken=${passwordToken}`;
        const {data} = await httpClient.get(url);
        dispatch(setIsFetchingResetPasswordUserInfo(false));

        return data;
    } catch (err) {
        dispatch(setResetPasswordUserInfoError(getErrorMessage(err)));
        dispatch(setIsFetchingResetPasswordUserInfo(false));

        return false;
    }
};
export const fetchPasswordPolicy = (passwordToken, paramName = 'passwordToken') => async (dispatch) => {
    dispatch(setIsFetchingPasswordPolicy(true));
    dispatch(setPasswordPolicyError(null));

    try {
        const {data} = await httpClient.get(`${BASE_PATH}/baseProxy/a/passwordPolicySettings?${paramName}=${passwordToken}`);
        dispatch(setIsFetchingPasswordPolicy(false));
        return data;
    } catch (err) {
        dispatch(setIsFetchingPasswordPolicy(false));
        dispatch(setPasswordPolicyError(getErrorMessage(err)));
        return false;
    }
};

export const fetchSsoIdps = (email) => async (dispatch, getState) => {
    const {config} = getState().app;

    dispatch(setIsSsoIdpsFetching(true));
    dispatch(setEmailSsoIdpsFetchedFor(''));

    try {
        const encodedEmail = encodeURIComponent(email);
        const url = `${BASE_PATH}/proxysso/idp?email=${encodedEmail}`;
        const result = await httpClient.get(url, {
            withCredentials: false,
            timeout: config.VS_ENV === 'development' ? 5000 : undefined
        });

        dispatch(setSsoIdpDiscoReturnURL(get(result, 'data.idpDiscoReturnURL', '')));
        dispatch(setSsoIdps(get(result, 'data.idpNames', [])));
        dispatch(setSsoLoginRequired(get(result, 'data.ssoLoginRequired', false)));
        dispatch(setIsSsoIdpsFetching(false));
        dispatch(setEmailSsoIdpsFetchedFor(email));
        return result.data;
    } catch (err) {
        dispatch(setSsoIdps([]));
        dispatch(setSsoLoginRequired(false));
        dispatch(setIsSsoIdpsFetching(false));
        dispatch(setSsoIdpsFetchingError(getErrorMessage(err)));
        dispatch(setEmailSsoIdpsFetchedFor(email));

        return false;
    }
};

export const getImageSignature = () => async (dispatch, getState) => {
    const {user} = getState();
    const url = `${BASE_PATH}/proxy/account/senders/${user.user.id}/signature/image`;
    dispatch(setIsFetchingImageSignature(true));

    try {
        const {data} = await httpClient.get(url);
        dispatch(setImageSignature(data));
        dispatch(setIsFetchingImageSignature(false));

        return data;
    } catch (err) {
        dispatch(setIsFetchingImageSignature(false));

        return false;
    }
};

export const saveImageSignature = (payload) => async (dispatch, getState) => {
    const {user} = getState();
    const formDataHeaders = {
        Accept: '*/*',
        'Content-Type': 'multipart/form-data'
    };
    const url = `${BASE_PATH}/proxy/account/senders/${user.user.id}/signature/image`;
    dispatch(setIsFetchingImageSignature(true));

    try {
        await httpClient.post(url, payload, {headers: formDataHeaders});
        dispatch(setIsFetchingImageSignature(false));
        await dispatch(getImageSignature());

        return true;
    } catch (err) {
        dispatch(setIsFetchingImageSignature(false));
        return false;
    }
};
export const deleteImageSignature = () => async (dispatch, getState) => {
    const {user} = getState();
    const url = `${BASE_PATH}/proxy/account/senders/${user.user.id}/signature/image`;
    dispatch(setIsFetchingImageSignature(true));
    try {
        await httpClient.delete(url);
        dispatch(setIsFetchingImageSignature(true));
        dispatch(getImageSignature());

        return true;
    } catch (err) {
        dispatch(setIsFetchingImageSignature(false));
        return false;
    }
};

export const selectUser = (state) => state.user.user;
export const selectAccount = (state) => state.user.account;
export const selectRestricted = (state) => state.user.restricted;
export const selectPackages = (state) => state.user.packages;
export const selectFeatures = (state) => state.user.features;
export const selectPermissions = (state) => {
    const isRolesAndPermissionsEnabled = get(state, 'user.features.rolesAndPermissions', false);
    if (!isRolesAndPermissionsEnabled) {
        return getDefaultPermissions(state.user);
    }
    return state.user.permissions;
};
export const selectHasUserPermissionOnRoot = (state) => state.user.hasUserPermissionOnRoot;
export const selectDelegationUser = (state) => state.user.delegationUser;
export const selectLoginSessionTimeout = (state) => state.user.loginSessionTimeout;
export const selectSupport = (state) => state.user.support;
export const selectInPerson = (state) => state.user.inPerson;
export const selectCertificateSigning = (state) => state.user.certificateSigning;
export const selectIsLoading = (state) => state.user.isLoading;
export const selectAuthError = (state) => state.user.authError;
export const selectIsAuthenticating = (state) => state.user.isAuthenticating;
export const selectIsPasswordReseting = (state) => state.user.isPasswordReseting;
export const selectPasswordUpdateError = (state) => state.user.passwordUpdateError;
export const selectIsPasswordUpdating = (state) => state.user.isPasswordUpdating;
export const selectWasPasswordReset = (state) => state.user.wasPasswordReset;
export const selectPasswordResetError = (state) => state.user.passwordResetError;
export const selectAuthenticationToken = (state) => state.user.authenticationToken;
export const selectSenderTokens = (state) => state.user.senderTokens;
export const selectIsSessionInfoFetching = (state) => state.user.isSessionInfoFetching;
export const selectWasFetchedSession = (state) => state.user.wasFetchedSession;
export const selectSsoIdpDiscoReturnURL = (state) => state.user.ssoIdpDiscoReturnURL;
export const selectSsoIdps = (state) => state.user.ssoIdps;
export const selectSsoLoginRequired = (state) => state.user.ssoLoginRequired;
export const selectIsSsoIdpsFetching = (state) => state.user.isSsoIdpsFetching;
export const selectSsoIdpsFetchingError = (state) => state.user.ssoIdpsFetchingError;
export const selectEmailSsoIdpsFetchedFor = (state) => state.user.emailSsoIdpsFetchedFor;
export const selectTokenOldUiLoggedInWith = (state) => state.user.tokenOldUiLoggedInWith;
export const selectActiveAccountId = (state) => state.user.activeAccountId;
export const selectResetPasswordUserInfoError = (state) => state.user.resetPasswordUserInfoError;
export const selectIsFetchingResetPasswordUserInfo = (state) => state.user.isFetchingResetPasswordUserInfo;
export const selectPasswordPolicyError = (state) => state.user.passwordPolicyError;
export const selectIsFetchingPasswordPolicy = (state) => state.user.isFetchingPasswordPolicy;
export const selectIsFetchingUserActivationInfo = (state) => state.user.isFetchingUserActivationInfo;
export const selectUserActivationInfoError = (state) => state.user.userActivationInfoError;
export const selectIsActivatingUser = (state) => state.user.isActivatingUser;
export const selectActivatingUserError = (state) => state.user.activatingUserError;
export const selectIsLoggingOut = (state) => state.user.isLoggingOut;
export const selectLogoutPath = (state) => state.user.logoutPath;
export const selectSenderExpiryRedirect = (state) => state.user.senderExpiryRedirect;
export const selectImageSignature = (state) => state.user.imageSignature;
export const selectIsFetchingImageSignature = (state) => state.user.isFetchingImageSignature;
export const selectVSFeatures = (state) => state.user.vsFeatures;
export const selectVSAccountFeatures = (state) => state.user.vsAccountFeatures;
export const selectIsFeaturesFetching = (state) => state.user.isFeaturesFetching;
export const selectFeaturesFetchError = (state) => state.user.featuresFetchError;
export const selectIsFeatureSwitching = (state) => state.user.isFeatureSwitching;
export const selectFeatureSwitchError = (state) => state.user.featureSwitchError;

export default userSlice.reducer;
