import React from 'react';
import classnames from 'classnames';
import {Select} from '@material-ui/core';
import './baseSelect.less';
import '../common.less';

const BaseSelect = ({
    disabled, onChange, fullWidth = true, children, className, id, inputProps, classes = {}, ...rest
}) => {
    const containerClassName = classnames('base-select-container', className, {disabled});
    const selectClassName = classnames('base-select', classes.root, {disabled});
    function handleChange(e) {
        if (disabled) return;
        onChange(e);
    }
    return (
        <Select
            variant="outlined"
            fullWidth={fullWidth}
            className={containerClassName}
            onChange={handleChange}
            inputProps={{...inputProps, id}}
            disabled={disabled}
            classes={{...classes, root: selectClassName}}
            {...rest}
        >
            {children}
        </Select>
    );
};
export default BaseSelect;
