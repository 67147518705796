import Deferred from '../deferred';
import eventNames from './eventNames';
import {
    isInIframeOrWebView, postToParent, receiveMessage
} from './utils';

export default class IFrameEventNotifications {
    constructor() {
        this.isListenerSet = false;
        this.targetOrigin = null;
        this.isSystemActive = false;
        this.pendingPromises = {};
    }

    static instance = null;

    static getInstance() {
        if (IFrameEventNotifications.instance == null) {
            IFrameEventNotifications.instance = new IFrameEventNotifications();
        }

        return this.instance;
    }

    setListener() {
        if (!this.isListenerSet && isInIframeOrWebView()) {
            this.isListenerSet = true;
            window.addEventListener('message', receiveMessage.bind(this), false);
            postToParent(eventNames.REGISTER_EVENT_NAME);
        }
    }

    postEvent({eventName, canBeStoppedFromContinuing}) {
        let promise = Promise.resolve(null);
        if (this.isSystemActive) {
            if (canBeStoppedFromContinuing) {
                const deferred = new Deferred();
                this.pendingPromises[eventName] = deferred;
                ({promise} = deferred);
            }
            postToParent(eventName);
        }

        return promise;
    }
}
