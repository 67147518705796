const defaultSize = 30;
const minSize = 15;
const maxSize = defaultSize;

export const getCornerWidgetSize = ({width, height}) => {
    const smallestFieldDimension = Math.min(width, height);
    const relativeSize = smallestFieldDimension ? smallestFieldDimension * 0.6 : defaultSize;

    if (relativeSize > maxSize) return maxSize;
    if (relativeSize < minSize) return minSize;
    return Math.round(relativeSize);
};
