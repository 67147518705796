import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import {useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import useMarkedMessage from '../components/SignerUi/hooks/useMarkedMessage';
import useNotifications from './useNotifications';
import {selectLocale} from '../components/appSlice';
import enTranslations from '../../i18n/en.json';

export default function useErrorNotification(error, options = {}, notificationOptions) {
    const intl = useIntl();
    const toastId = useRef(null);
    const {showErrorAlert} = useNotifications();
    const parameters = !isEmpty(options) ? options : get(error, 'parameters', {});
    const messageKey = get(error, 'messageKey', '');

    useEffect(() => {
        if (!messageKey && toastId.current) {
            toast.dismiss(toastId.current);
        }
        if (messageKey) {
            toastId.current = showErrorAlert(intl.formatMessage({id: messageKey, defaultMessage: messageKey}, parameters), notificationOptions);
        }
    }, [error]);

    return null;
}

function isMessageKey(str) {
    // A regular expression to test if the string is like a key for example shared.about
    // This pattern checks for strings that contain one or more periods without spaces
    const keyPattern = /^[^. ]+(\.[^. ]+)+$/;

    return keyPattern.test(str);
}

// This workaround needs to be because some error messages doesn't have a key.key. RFT-158
function findMessageKeyByText(text) {
    for (const key in enTranslations) {
        if (enTranslations[key] === text) {
            return key;
        }
    }
    return text;
}

function getMessageKey(error, locale) {
    const messageKey = get(error, 'messageKey', '');
    if (isMessageKey(messageKey)) {
        return messageKey;
    }
    return findMessageKeyByText(messageKey, locale);
}

export function useGraphQlErrorNotification(error, reset, params) {
    const formatMessage = useMarkedMessage();
    const {showErrorAlert} = useNotifications();
    const locale = useSelector(selectLocale);
    const messageKey = error ? getMessageKey(error, locale) : '';
    const parameters = get(error, 'parameters', {});

    useEffect(() => {
        if (error) {
            showErrorAlert(formatMessage({
                key: messageKey || 'oss.generic.defaultError',
                options: params || parameters
            }));
            if (reset) {
                reset();
            }
        }
    }, [error]);

    return null;
}
