import React, {
    useEffect, useMemo, useState, memo
} from 'react';
import {useDispatch} from 'react-redux';
import isEmpty from 'lodash.isempty';
import SigningField from './SigningField/SigningField';
import {setPagesFields} from '../../signerUiSlice';
import {getFieldsErrors} from '../utils';
import './signingFields.less';

function SigningFields({pageWithDimensions, documentFields, fieldsRef}) {
    const dispatch = useDispatch();
    const [pageFields, setPageFields] = useState([]);
    const documentFieldsErrors = useMemo(() => getFieldsErrors(documentFields), [documentFields]);
    const {
        signerFields,
        index,
        documentId,
        pageToContainerScaleAndZoomFactor
    } = pageWithDimensions;
    const pageNumber = index + 1;
    const sourcePageFields = useMemo(() => signerFields, [signerFields]);

    const style = {
        transform: `scale(${pageToContainerScaleAndZoomFactor})`
    };

    function changeFields(newFields) {
        setPageFields(newFields);
    }

    function changeField(newField) {
        const updatedFields = pageFields.map((field) => {
            if (newField.id === field.id) {
                return newField;
            }
            return field;
        });
        setPageFields(updatedFields);
    }

    useEffect(() => {
        dispatch(setPagesFields({pageNumber, pageFields}));
    }, [pageNumber, pageFields]);

    useEffect(() => {
        setPageFields(sourcePageFields);
    }, [sourcePageFields]);

    if (isEmpty(pageFields)) {
        return null;
    }

    return (
        <div style={style} className="signing-fields-container">
            {pageFields.map((field) => (
                <SigningField
                    key={`field-id-${field.id}`}
                    pageWithDimensions={pageWithDimensions}
                    field={field}
                    fields={pageFields}
                    originalField={sourcePageFields.find(({id}) => id === field.id)}
                    fieldsErrors={documentFieldsErrors}
                    changeFields={changeFields}
                    changeField={changeField}
                    documentId={documentId}
                    fieldsRef={fieldsRef}
                />
            ))}
        </div>
    );
}
export default memo(SigningFields);
