import React from 'react';
import {useIntl} from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import './confirmationModal.less';

export default function ConfirmationModal({
    className,
    isOpen,
    titleKey,
    noBtnKey = 'esl.generic.no',
    yesBtnKey = 'esl.generic.yes',
    descriptionKeys = [],
    descriptionKeysVariables = [],
    setIsOpen = () => {},
    onConfirm = () => {},
    onDecline = () => {},
    id = '',
    dataWdio = '',
    noBtnId = '',
    yesBtnId = ''
}) {
    const intl = useIntl();
    const classNames = classnames('confirmation-modal', className, {
        'is-open': isOpen
    });

    function closeConfimationModal() {
        setIsOpen(false);
    }

    function handleConfirm() {
        closeConfimationModal();

        if (onConfirm) {
            onConfirm();
        }
    }

    function handleDecline() {
        closeConfimationModal();

        if (onDecline) {
            onDecline();
        }
    }

    return (
        <Dialog
            maxWidth="md"
            open={isOpen}
            classes={{root: classNames}}
            PaperProps={{
                id,
                'data-wdio': dataWdio
            }}
        >
            {titleKey && (
                <DialogTitle>
                    {intl.formatMessage({id: titleKey, defaultMessage: ''})}
                </DialogTitle>
            )}
            <DialogContent>
                {descriptionKeys.map((key, idx) => (
                    <p className="description-msg" key={key}>
                        {intl.formatMessage(
                            {id: key, defaultMessage: ''},
                            descriptionKeysVariables[idx] || {}
                        )}
                    </p>
                ))}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleDecline}
                    classes={{root: 'no-btn'}}
                    data-focus-id={noBtnId}
                >
                    {intl.formatMessage({id: noBtnKey, defaultMessage: ''})}
                </Button>
                <Button
                    onClick={handleConfirm}
                    classes={{root: 'yes-btn'}}
                    color="primary"
                    data-focus-id={yesBtnId}
                    autoFocus
                >
                    {intl.formatMessage({id: yesBtnKey, defaultMessage: ''})}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
