import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {isEmail} from '../../../../../../utils/helpers';
import AppInput from '../../../../../Core/inputs/AppInput/AppInput';

export default function ReassignForm({form, changeForm}) {
    const intl = useIntl();
    const [wasChanged, setWasChanged] = useState({
        firstName: false,
        lastName: false,
        email: false
    });

    function onBlur({target}) {
        if (!wasChanged[target.name]) {
            setWasChanged({
                ...wasChanged,
                [target.name]: true
            });
        }
    }

    function getFieldInvalidMessage(name) {
        if (wasChanged[name]) {
            if (!form[name]) {
                switch (name) {
                    case 'firstName':
                        return 'oss.components.PanelForm.Field.FirstName.invalid';
                    case 'lastName':
                        return 'oss.components.PanelForm.Field.LastName.invalid';
                    default:
                        return 'oss.components.PanelForm.Field.requiredFieldMissing';
                }
            }

            if (name === 'email' && !isEmail(form[name])) {
                return 'oss.components.PanelForm.Field.email.invalid';
            }
        }

        return null;
    }

    return (
        <>
            <div className="form-instruction">
                {intl.formatMessage({id: 'oss.components.Reassign.instruction', defaultMessage: ''})}
            </div>
            <h4 className="subtitle">
                {intl.formatMessage({id: 'oss.components.Reassign.formTitle', defaultMessage: ''})}
            </h4>
            <AppInput
                className="field-container"
                label={intl.formatMessage({id: 'oss.components.Reassign.firsNameLabel', defaultMessage: ''})}
                id="reassign-form-firstName"
                dataWdio="reassign-form-firstName"
                onChange={changeForm}
                onBlur={onBlur}
                value={form.firstName}
                name="firstName"
                error={getFieldInvalidMessage('firstName')}
                helperText={getFieldInvalidMessage('firstName')
                && intl.formatMessage({id: getFieldInvalidMessage('firstName'), defaultMessage: ''})}
            />
            <AppInput
                className="field-container"
                label={intl.formatMessage({id: 'oss.components.Reassign.lastNameLabel', defaultMessage: ''})}
                id="reassign-form-lastName"
                dataWdio="reassign-form-lastName"
                onChange={changeForm}
                onBlur={onBlur}
                value={form.lastName}
                name="lastName"
                error={getFieldInvalidMessage('lastName')}
                helperText={getFieldInvalidMessage('lastName')
                    && intl.formatMessage({id: getFieldInvalidMessage('lastName'), defaultMessage: ''})}
            />
            <AppInput
                className="field-container"
                label={intl.formatMessage({id: 'oss.components.Reassign.emailLabel', defaultMessage: ''})}
                id="reassign-form-email"
                dataWdio="reassign-form-email"
                onChange={changeForm}
                onBlur={onBlur}
                value={form.email}
                name="email"
                error={getFieldInvalidMessage('email')}
                helperText={getFieldInvalidMessage('email')
                && intl.formatMessage({id: getFieldInvalidMessage('email'), defaultMessage: ''})}
            />
            <AppInput
                className="field-container"
                label={intl.formatMessage({id: 'oss.components.Reassign.titleLabel', defaultMessage: ''})}
                id="reassign-form-title"
                onChange={changeForm}
                value={form.title}
                name="title"
            />
            <AppInput
                className="field-container"
                label={intl.formatMessage({id: 'oss.components.Reassign.companyLabel', defaultMessage: ''})}
                id="reassign-form-company"
                onChange={changeForm}
                value={form.company}
                name="company"
            />
            <AppInput
                className="field-container"
                label={intl.formatMessage({id: 'oss.components.Reassign.personalMessageLabel', defaultMessage: ''})}
                id="reassign-form-message"
                onChange={changeForm}
                value={form.message}
                name="message"
                multiline
                rows={5}
                rowsMax={5}
                helperText={intl.formatMessage({id: 'oss.components.Reassign.personalMessageSubLabel', defaultMessage: ''})}
            />
        </>
    );
}
