import React, {
    useEffect, useState
} from 'react';
import {useMutation, useLazyQuery} from '@apollo/client';
import {useIntl} from 'react-intl';
import {useSelector, useDispatch} from 'react-redux';
import {FormHelperText} from '@material-ui/core';
import get from 'lodash.get';
import {GET_NATIVE_BRIDGE_CONFIG} from '../../../../gql/queries';
import GET_CERTIFICATE_SIGNING_TOKEN from '../../../../gql/getToken.gql';
import SET_CERTIFICATE_SIGNING_HASH from '../../../../gql/setHash.gql';
import {PCCBridge} from './pccBridge';
import {PCC_STEPS} from './constants';
import {versionCompare, getPCCErrorMessages} from './utils';
import DriverDialog from './screens/DriverDialog/DriverDialog';
import DriverSuccessDialog from './screens/DriverSuccessDialog/DriverSuccessDialog';
import CertificateList from './screens/CertificateList/CertificateList';
import CertificateDetails from './screens/CertificateDetails/CertificateDetails';
import {
    selectSelectedDocumentId, selectIsPCCOpen, setIsPCCOpen, goToSummaryPage, doneThankYou, setSelectedDocumentId
} from '../signerUiSlice';
import Loading from '../../shared/Loading/Loading';
import {useConfirmDocument} from '../hooks/mutations';
import {useCeremony} from '../hooks/queries';
import {isMacOS} from '../../../reusable/libraries/isMacOs';
import {
    getHasPendingRequiredUpload, isSigningComplete, isTransactionCompleted, setAutoredirectIfAllowed
} from '../utils/selectors';
import useAttachmentFiles from '../hooks/useAttachmentFiles';
import useInPerson from '../hooks/useInPerson';
import {Dialog} from '../../Core/company/modals/Dialog';
import './personalCertificate.less';

const PersonalCertificateClient = () => {
    const {formatMessage} = useIntl();
    const [step, setStep] = useState();
    const dispatch = useDispatch();
    const {isInPersonCeremonyControlledByHost, inPersonCeremonyHost} = useInPerson();
    const {transaction, role} = useCeremony();
    const {
        role: {
            attachmentRequirements: attachments = []
        } = {}
    } = useAttachmentFiles();
    const documentId = useSelector(selectSelectedDocumentId);
    const documents = get(transaction, 'documents', []);
    const document = documents.find(({id}) => id === documentId) || {};
    const isPCCOpen = useSelector(selectIsPCCOpen);
    const [certsReady, setCertsReady] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const hasPendingUpload = getHasPendingRequiredUpload({attachments});
    const [getNativeBridgeConfig, {data: driverData, loading: driverLoading}] = useLazyQuery(GET_NATIVE_BRIDGE_CONFIG, {
        variables: {transactionId: transaction.id},
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (!PCCBridge.getVersion() || versionCompare(
                data?.nativeBridgeConfig?.version,
                PCCBridge.getVersion()
            ) > 0
            ) {
                setStep(step === PCC_STEPS.driverSuccess ? step : PCC_STEPS.driver);
            } else {
                setStep(PCC_STEPS.certificateList);
            }
        }
    });

    // TODO: Get rid of functionality duplication here and in NextButton.jsx
    function goToDocumentByIndex(index) {
        dispatch(setSelectedDocumentId(documents[index].id));
    }
    function goToNextDocument() {
        const currentDocumentIndex = documents.findIndex(({id}) => id === document.id);
        const nextDocumentIndex = currentDocumentIndex + 1 <= documents.length - 1 ? currentDocumentIndex + 1 : 0;
        goToDocumentByIndex(nextDocumentIndex);
    }

    function setIsOpen(isOpen) {
        dispatch(setIsPCCOpen(isOpen));
    }

    const [getToken, {loading: certificateTokenLoading}] = useMutation(
        GET_CERTIFICATE_SIGNING_TOKEN,
        {
            variables: {id: documentId, transactionId: transaction.id},
            onCompleted: ({getCertificateSigningToken: {value}}) => {
                PCCBridge.passCertificateSigningToken(value);
            }
        }
    );

    const [setHash] = useMutation(SET_CERTIFICATE_SIGNING_HASH, {
        onCompleted: ({setCertificateSigningHash: {value}}) => {
            PCCBridge.sign(value);
        }
    });
    // TODO: Get rid of functionality duplication here and in NextButton.jsx

    function finishSigningTransaction(transactionData = {}) {
        setAutoredirectIfAllowed();
        if (!hasPendingUpload) {
            if (isInPersonCeremonyControlledByHost) {
                dispatch(doneThankYou({
                    role,
                    roles: transaction.roles,
                    isTransactionCompleted: isTransactionCompleted(transactionData),
                    inPersonCeremonyHost
                }));
            } else {
                dispatch(goToSummaryPage());
            }
        }
        dispatch(goToSummaryPage());
    }

    const downloadUrl = isMacOS() ? 'macUrl' : 'url';
    const {handleConfirm} = useConfirmDocument({document});

    // TODO: Get rid of functionality duplication here and in NextButton.jsx
    async function onConfirm(params) {
        const ceremonyRes = await handleConfirm(params);

        if (ceremonyRes) {
            const updatedCeremony = get(ceremonyRes, 'data.ceremony', {});
            const currentRoleHasSigned = isSigningComplete(updatedCeremony?.transaction?.documents);
            if (currentRoleHasSigned) {
                return finishSigningTransaction(updatedCeremony);
            }
            if (!document.isLast) {
                goToNextDocument();
            }
        }
    }

    const callbacks = {
        onCertificatesList: () => {
            setCertsReady(true);
        },
        onConnectionFailure: () => {
            getNativeBridgeConfig();
        },
        onConnectionSuccess: () => {
            getToken();
        },
        onError: (error) => {
            const message = getPCCErrorMessages(error)
                .map((key) => (key ? formatMessage({id: key, defaultMessage: ''}) : ''))
                .join('\n');
            setErrorMessage(message);
            setStep(PCC_STEPS.certificateList);
        },
        onSignedToken: (signedHash = '') => {
            onConfirm({options: {signedHash}});
            dispatch(setIsPCCOpen(false));
            // Needed in case the onConfirm fails with an error.
            PCCBridge.disconnect();
            setCertsReady(false);
            setStep(undefined);
        },
        onSignerToken: (token) => {
            setHash({
                variables: {
                    id: documentId,
                    transactionId: transaction.id,
                    payload: {token}
                }
            });
        },
        onSocketError: () => null,
        onVersion: () => {
            getNativeBridgeConfig();
        },
        retryConnection: false
    };

    // Connect on first load and check if connected whenever open changes
    useEffect(() => {
        if (isPCCOpen) {
            PCCBridge.connect(callbacks);
        }
    }, [isPCCOpen]);

    useEffect(() => () => PCCBridge.disconnect(), []);

    const onDriverDone = () => {
        setCertsReady(false);
        PCCBridge.reconnect(callbacks);
    };

    const areCertsLoading = step === PCC_STEPS.certificateList && !certsReady;
    const isLoading = driverLoading || certificateTokenLoading || areCertsLoading || step === PCC_STEPS.confirmLoading;

    return (
        <Dialog
            isOpen={isPCCOpen}
            className="personal-certificate-dialog"
            aria-labelledby="pcc-dialog-title"
            fullWidth
        >
            {isMacOS() === true && !isLoading
                && (
                    <div className="certificate-signing-macos-message">
                        <p>
                            {formatMessage({id: 'oss.pcc.generic.macOs_warning', defaultMessage: ''})}
                        </p>
                    </div>
                )}
            {step === PCC_STEPS.driver && !isLoading && (
                <DriverDialog
                    url={driverData?.nativeBridgeConfig?.[downloadUrl]}
                    version={driverData?.nativeBridgeConfig?.version}
                    setStep={setStep}
                    setOpen={setIsOpen}
                />
            )}
            {step === PCC_STEPS.driverSuccess && !isLoading && (
                <DriverSuccessDialog
                    url={driverData?.nativeBridgeConfig?.[downloadUrl]}
                    version={driverData?.nativeBridgeConfig?.version}
                    onDone={onDriverDone}
                    setStep={setStep}
                    setOpen={setIsOpen}
                />
            )}
            {step === PCC_STEPS.certificateList && !isLoading && (
                <CertificateList
                    certificates={PCCBridge.certificateList}
                    setStep={setStep}
                    setOpen={setIsOpen}
                    reconnect={onDriverDone}
                />
            )}
            {step === PCC_STEPS.certificateDetail && !isLoading && (
                <CertificateDetails setStep={setStep} setOpen={setIsOpen} />
            )}
            <div>
                {isLoading && (
                    <Loading isLoading={isLoading} />
                )}
            </div>
            {errorMessage && (
                <FormHelperText className="personal-certificate-error-message" error={!!errorMessage}>
                    {errorMessage}
                </FormHelperText>
            )}
        </Dialog>
    );
};

export default PersonalCertificateClient;
