import {
    fetchNews,
    fetchNotifications
} from '../appSlice';

export default function ssrFetch({store}) {
    const baseUrl = store.getState().app.config.BASE_PUBLIC_URL;
    return Promise.all([
        store.dispatch(fetchNews({baseUrl})),
        store.dispatch(fetchNotifications({baseUrl}))
    ]);
}
