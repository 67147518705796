import React, {useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {selectLocale} from '../../../../appSlice';
import {getCustomFieldValue} from '../../utils';
import {getFieldPositionStyles} from '../utils/fieldUtils';
import './autofillField.less';

const AutofillField = ({field, fieldTooltipLabel, setFieldDataToRef}) => {
    const {
        acceptedDate,
        approval: {
            role: {company, title, fullName}
        },
        isAutofillDate,
        isAutofillCompany,
        isAutofillTitle,
        isAutofillName,
        isLabel,
        isCustomField,
        value
    } = field;
    const className = classNames('signing-field-container', 'autofill-field', 'label-style');
    const intl = useIntl();
    const locale = useSelector(selectLocale);
    const customFieldLabel = getCustomFieldValue({field, locale});
    const fieldRef = useRef(null);

    useEffect(() => {
        setFieldDataToRef({fieldRef});
    }, [field, fieldRef]);

    const getContent = () => {
        if (isAutofillCompany) {
            return company;
        }
        if (isAutofillDate) {
            return acceptedDate;
        }
        if (isAutofillName) {
            return fullName;
        }
        if (isAutofillTitle) {
            return title;
        }
        if (isLabel) {
            return value;
        }
        if (isCustomField) {
            return customFieldLabel;
        }
        return null;
    };

    const fieldLabel = getContent();
    if (!fieldLabel) return null;

    const titleNode = (
        <span className="tooltip-title-container">
            {fieldTooltipLabel ? intl.formatMessage({id: fieldTooltipLabel, defaultMessage: ''}) : ''}
        </span>
    );
    return (
        <div
            className={className}
            style={getFieldPositionStyles(field)}
            ref={fieldRef}
            tabIndex={0}
            role="presentation"
        >
            <Tooltip
                title={titleNode}
                placement="top"
                arrow

            >
                <label className="autofill-field-content">
                    {fieldLabel}
                </label>
            </Tooltip>
        </div>
    );
};

export default AutofillField;
