import {useQuery} from '@apollo/client';
import {useSelector} from 'react-redux';
import get from 'lodash.get';
import {GET_FEATURES} from '../../../../../gql/queries';
import {selectTransactionId} from '../../signerUiSlice';

export const useFeatures = () => {
    const transactionId = useSelector(selectTransactionId);
    const {
        data, error, loading, refetch
    } = useQuery(GET_FEATURES, {
        variables: {transactionId}
    });

    return {
        data, features: get(data, 'ceremony.session.features', {}), error, loading, refetch
    };
};
