import {gql} from '@apollo/client';

export const SEND_AUDIT_EVENT = gql`mutation auditTrackEvent(
  $transactionId: String!
  $auditType: AuditType!
  $recipient: String
  $data: AuditData
) {
  auditTrackEvent(
    transactionId: $transactionId
    auditType: $auditType
    recipient: $recipient
    data: $data
  )
}`;
