/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import httpClient from '../../utils/httpClient';
import {
    BASE_PATH,
    DEFAULT_TRANSACTIONS_OPTIONS,
    PACKAGES_PER_PAGE_OPTIONS,
    PACKAGE_TYPES,
    PACKAGE_QUERY_OPTIONS,
    PACKAGE_SORT_OPTIONS,
    PACKAGE_DIR_OPTIONS,
    PACKAGE_PREDEFINED_OPTIONS,
    FREE_USERS_PLAN_NAME
} from '../../../constants';
import {getRecipientsEmailsFromPackage} from '../../utils/helpers';
import {fetchAvatarsByEmails} from '../Packages/Recipients/recipientsSlice';
import {getErrorMessage} from '../../utils/requests/error';

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        transactions: [],
        count: 0,
        transactionsPerPage: PACKAGES_PER_PAGE_OPTIONS[10],
        visibility: {},
        isTransactionsFetching: false,
        isUpdatingTransaction: false,
        isDeletingTransaction: false,
        isReminderSaving: false,
        fetchingTransactionsError: null,
        updatingTransactionError: null,
        deletingTransactionError: null,
        reminderSavingError: null,
        isVisibilityFetching: false,
        fetchingVisibilityError: null,
        options: DEFAULT_TRANSACTIONS_OPTIONS,
        isNextToSignTransactionFetching: false,
        fetchingNextToSignTransactionError: null,
        transactionsSummary: {},
        isTransactionsSummaryFetching: false,
        fetchingTransactionsSummaryError: null
    },
    reducers: {
        setTransactions: (state, action) => {
            state.transactions = action.payload;
        },
        setCount: (state, action) => {
            state.count = action.payload;
        },
        setTransactionsPerPage: (state, action) => {
            state.transactionsPerPage = action.payload;
        },
        setVisibility: (state, action) => {
            state.visibility = action.payload;
        },
        setIsTransactionsFetching: (state, action) => {
            state.isTransactionsFetching = action.payload;
        },
        setIsUpdatingTransaction: (state, action) => {
            state.isUpdatingTransaction = action.payload;
        },
        setIsDeletingTransaction: (state, action) => {
            state.isDeletingTransaction = action.payload;
        },

        setFetchingTransactionsError: (state, action) => {
            state.fetchingTransactionsError = action.payload;
        },
        setUpdatingTransactionError: (state, action) => {
            state.updatingTransactionError = action.payload;
        },
        setDeletingTransactionError: (state, action) => {
            state.deletingTransactionError = action.payload;
        },
        setIsVisibilityFetching: (state, action) => {
            state.isVisibilityFetching = action.payload;
        },
        setFetchingVisibilityError: (state, action) => {
            state.fetchingVisibilityError = action.payload;
        },

        setOptions: (state, action) => {
            state.options = action.payload;
        },
        setIsNextToSignTransactionFetching: (state, action) => {
            state.isNextToSignTransactionFetching = action.payload;
        },
        setFetchingNextToSignTransactionError: (state, action) => {
            state.fetchingNextToSignTransactionError = action.payload;
        },
        setTransactionsSummary: (state, action) => {
            state.transactionsSummary = action.payload;
        },
        setIsTransactionsSummaryFetching: (state, action) => {
            state.isTransactionsSummaryFetching = action.payload;
        },
        setFetchingTransactionsSummaryError: (state, action) => {
            state.fetchingTransactionsSummaryError = action.payload;
        }
    }
});

export const {
    setTransactions,
    setCount,
    setTransactionsPerPage,
    setVisibility,
    setIsTransactionsFetching,
    setIsUpdatingTransaction,
    setFetchingTransactionsError,
    setUpdatingTransactionError,
    setIsVisibilityFetching,
    setFetchingVisibilityError,
    setOptions,
    setIsDeletingTransaction,
    setDeletingTransactionError,
    setIsNextToSignTransactionFetching,
    setFetchingNextToSignTransactionError,
    setTransactionsSummary,
    setIsTransactionsSummaryFetching,
    setFetchingTransactionsSummaryError
} = transactionsSlice.actions;

export const fetchTransactions = ({
    headers, baseUrl
} = {}) => async (dispatch, getState) => {
    const {transactions} = getState();
    const {
        from,
        to,
        sort,
        dir,
        query,
        search,
        predefined,
        lastUpdatedStartDate
    } = transactions.options;
    const servicePath = 'packages';
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {
        params: {
            query,
            type: PACKAGE_TYPES.PACKAGE,
            from,
            to,
            sort,
            dir,
            predefined
        }
    };
    if (headers) {
        options.headers = headers;
    }
    if (search) {
        options.params.search = search;
    }

    if (lastUpdatedStartDate) {
        options.params.lastUpdatedStartDate = lastUpdatedStartDate;
    }

    dispatch(setIsTransactionsFetching(true));
    dispatch(setFetchingTransactionsError(null));

    try {
        const result = await httpClient.get(url, options);
        const resultTransactions = get(result, 'data.results', []);
        const recipientEmails = getRecipientsEmailsFromPackage(resultTransactions);
        await dispatch(fetchAvatarsByEmails({baseUrl, headers, emails: recipientEmails}));

        dispatch(setTransactions(resultTransactions));
        dispatch(setCount(get(result, 'data.count', 0)));
        dispatch(setIsTransactionsFetching(false));
        dispatch(setFetchingTransactionsError(null));
        return true;
    } catch (err) {
        dispatch(setIsTransactionsFetching(false));
        dispatch(setFetchingTransactionsError(getErrorMessage(err)));
        return false;
    }
};

export const fetchNextToSignTransaction = () => async (dispatch, getState) => {
    const servicePath = 'packages';
    const url = `${BASE_PATH}/proxy/${servicePath}`;
    const options = {
        params: {
            query: PACKAGE_QUERY_OPTIONS.INBOX,
            type: PACKAGE_TYPES.PACKAGE,
            from: 1,
            to: 1,
            sort: PACKAGE_SORT_OPTIONS.UPDATED,
            dir: PACKAGE_DIR_OPTIONS.DESC,
            predefined: PACKAGE_PREDEFINED_OPTIONS.AWAITING_SIGNATURE
        }
    };

    dispatch(setIsNextToSignTransactionFetching(true));
    dispatch(setFetchingNextToSignTransactionError(null));

    try {
        const result = await httpClient.get(url, options);
        dispatch(setIsNextToSignTransactionFetching(false));
        dispatch(setFetchingNextToSignTransactionError(null));
        return get(result, 'data.results', []);
    } catch (err) {
        dispatch(setIsNextToSignTransactionFetching(false));
        dispatch(setFetchingNextToSignTransactionError(getErrorMessage(err)));
        return [];
    }
};

export const fetchVisibility = (packageId) => async (dispatch, getState) => {
    const {transactions} = getState();
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/visibility`;

    dispatch(setIsVisibilityFetching(true));
    dispatch(setFetchingVisibilityError(null));

    try {
        const result = await httpClient.get(url);
        const configurations = get(result, 'data.configurations', []);

        dispatch(setVisibility({
            ...transactions.visibility,
            [packageId]: configurations
        }));
        dispatch(setIsVisibilityFetching(false));
        dispatch(setFetchingVisibilityError(null));
        return true;
    } catch (err) {
        dispatch(setIsVisibilityFetching(false));
        dispatch(setFetchingVisibilityError(getErrorMessage(err)));
        return false;
    }
};

export const updateTransaction = (packageId, payload) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}`;

    dispatch(setIsUpdatingTransaction(true));
    dispatch(setUpdatingTransactionError(null));

    try {
        await httpClient.post(url, payload);

        dispatch(setIsUpdatingTransaction(false));
        dispatch(setUpdatingTransactionError(null));
        return true;
    } catch (err) {
        dispatch(setIsUpdatingTransaction(false));
        dispatch(setUpdatingTransactionError(getErrorMessage(err)));
        return false;
    }
};

export const deleteTransaction = (packageId) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}`;

    dispatch(setIsDeletingTransaction(true));
    dispatch(setDeletingTransactionError(null));

    try {
        await httpClient.delete(url);

        dispatch(setIsDeletingTransaction(false));
        dispatch(setDeletingTransactionError(null));
        return true;
    } catch (err) {
        dispatch(setIsDeletingTransaction(false));
        dispatch(setDeletingTransactionError(getErrorMessage(err)));
        return false;
    }
};

export const fetchTransactionsSummary = ({
    headers, baseUrl
} = {}) => async (dispatch, getState) => {
    const {account} = getState().user;
    const {id, licenses} = account || {};
    const isFreeUserPlan = licenses?.some(({plan} = {}) => plan?.name === FREE_USERS_PLAN_NAME);
    const servicePath = `api/frontend/accounts/${id}/summary/transactions`;
    const url = baseUrl ? `${baseUrl}/${servicePath}` : `${BASE_PATH}/vip/${servicePath}`;
    const options = {
        validateStatus: null
    };

    if (!isFreeUserPlan) {
        return true;
    }

    if (headers) {
        options.headers = headers;
    }

    dispatch(setIsTransactionsSummaryFetching(true));

    try {
        const {data} = await httpClient.get(url, options);
        dispatch(setTransactionsSummary(data.data));
        dispatch(setIsTransactionsSummaryFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsTransactionsSummaryFetching(false));
        dispatch(setFetchingTransactionsSummaryError(getErrorMessage(err)));
        return false;
    }
};

export const selectTransactions = (state) => state.transactions.transactions;
export const selectCount = (state) => state.transactions.count;
export const selectTransactionsPerPage = (state) => state.transactions.transactionsPerPage;
export const selectIsTransactionsFetching = (state) => state.transactions.isTransactionsFetching;
export const selectFetchingTransactionsError = (state) => state.transactions.fetchingTransactionsError;
export const selectVisibility = (state) => state.transactions.visibility;
export const selectIsVisibilityFetching = (state) => state.transactions.isVisibilityFetching;
export const selectIsUpdatingTransaction = (state) => state.transactions.isUpdatingTransaction;
export const selectIsDeletingTransaction = (state) => state.transactions.isDeletingTransaction;
export const selectOptions = (state) => state.transactions.options;
export const selectIsNextToSignTransactionFetching = (state) => state.transactions.isNextToSignTransactionFetching;
export const selectFetchingNextToSignTransactionError = (state) => state.transactions.fetchingNextToSignTransactionError;
export const selectTransactionsSummary = (state) => state.transactions.transactionsSummary;

export default transactionsSlice.reducer;
