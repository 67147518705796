import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import GET_HANDOVER_URL from '../../../../gql/getHandoverUrl.gql';
import {selectTransactionId} from '../signerUiSlice';
import {useMessages} from './queries';

export default function useGetHandoverUrl() {
    const transactionId = useSelector(selectTransactionId);
    const {locale} = useMessages();
    const {data = {}, error, loading} = useQuery(GET_HANDOVER_URL, {
        skip: !transactionId,
        variables: {transactionId, locale}
    });
    const {handoverData = {}} = data;

    return useMemo(() => ({
        handoverData,
        error,
        loading
    }), [handoverData, error, loading]);
}
