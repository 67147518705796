import httpClient from './httpClient';

const COMMON_HEADER = 'Content-Type: application/json\n\n';
const BATCH_BOUNDARY = '--batch';
const BOUNDARY_BOUNDARY = '--';

function generateSubBatchRequest(method = 'GET', url, data) {
    const requestUrl = `${method.toUpperCase()} ${url}`;
    const requestData = data === undefined ? '\n' : JSON.stringify(data);

    return `${COMMON_HEADER}${requestUrl}\n\n${requestData}\n`;
}

function generateBatchRequestBody(subRequests) {
    let requestString = '';

    subRequests.forEach((request) => {
        requestString = `${requestString}${BATCH_BOUNDARY}\n${request}\n`;
    });

    return `${requestString}${BATCH_BOUNDARY}${BOUNDARY_BOUNDARY}`;
}
export default function createBatchRequest(requestsData = []) {
    const subRequests = requestsData
        .map(({method = 'GET', url, data}) => generateSubBatchRequest(method, url, data));
    const batchRequestBody = generateBatchRequestBody(subRequests);

    return httpClient.post('/proxy/batch', batchRequestBody, {
        headers: {
            Accept: '*/*',
            'Content-Type': 'multipart/mixed'
        }
    });
}
