import {useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import {ReplyRounded} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import {useApolloClient, useMutation} from '@apollo/client';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {selectVSFeatures} from '@components/Login/userSlice';
import {selectConfig} from '@components/appSlice';
import {isVsFeatureEnabled} from '@utils/helpers';
import {updateCache} from '../../../utils';
import UNDO_APPROVAL from '../../utils/undoApproval.gql';
import {selectSelectedDocumentId, selectTransactionId} from '../../../../signerUiSlice';
import {getFieldPositionStyles} from '../../utils/fieldUtils';
import Image from '../../../../../common/Image/Image';
import AppOutlinedIconButton from '../../../../../Core/buttons/AppOutlinedIconButton/AppOutlinedIconButton';
import {useFieldClassName} from '../../hooks';
import FieldWrapper from '../../FieldWrapper/FieldWrapper';
import {CornerWidget} from '../../SigningField/CornerWidget/CornerWidget';
import {convertOneSpanSignatureToSVG} from '../../../../../AccountSettings/CaptureSignature/helpers';
import {SIGNATURE_V2_FEATURE} from '../../../../../../../constants';

const AcceptedSignature = ({
    field,
    selectField,
    unselectField,
    setFieldDataToRef
}) => {
    const {
        approval: {
            fromFileData, isFromFile, originalId: approvalId,
            role: {fullName}
        },
        isClickToSign,
        isCaptureSig,
        isMobileSig,
        value,
        hasConditions
    } = field;
    const transactionId = useSelector(selectTransactionId);
    const documentId = useSelector(selectSelectedDocumentId);
    const fieldSize = getFieldPositionStyles(field);
    const {formatMessage} = useIntl();
    const [undoApproval, {loading}] = useMutation(UNDO_APPROVAL);
    const client = useApolloClient();
    const vsFeatures = useSelector(selectVSFeatures);
    const config = useSelector(selectConfig);
    const hasPerfectFreehandFeature = isVsFeatureEnabled({
        feature: {id: SIGNATURE_V2_FEATURE},
        vsFeatures,
        env: config.VS_ENV
    });
    const isSignedUsingSignature = (isCaptureSig || isMobileSig) && value && !fromFileData?.type;
    const isSignedUsingImage = (isFromFile || hasPerfectFreehandFeature) && fromFileData?.type;
    const acceptedMessage = (isClickToSign || isCaptureSig || isMobileSig)
        ? 'oss.components.FieldsList.SignatureBlock.signedBy'
        : 'oss.components.FieldsList.SignatureBlock.initialedBy';
    const containerClassName = useFieldClassName(field);
    const fieldRef = useRef(null);
    const imageRef = useRef();
    const className = classNames('accepted-signature', containerClassName, {
        'with-undo': true
    });

    const onUndo = (originalId, val = '') => {
        undoApproval({
            update: updateCache({
                approvalId,
                client,
                documentId,
                hasConditions,
                isCaptureSig,
                isMobileSig,
                isUndo: true,
                transactionId,
                value: val
            }),
            variables: {
                documentId, originalId, transactionId, value
            }
        });
    };

    async function displaySignature() {
        if (isSignedUsingSignature && imageRef) {
            const svgSrc = await convertOneSpanSignatureToSVG({signature: value, format: 'svg'});
            imageRef.current.src = svgSrc;
        }
        if (isSignedUsingImage && imageRef) {
            imageRef.current.src = `${fromFileData?.type},${value}`;
        }
    }

    const handleUndo = () => {
        onUndo(approvalId, value);
    };

    useEffect(() => {
        if (value) displaySignature();
    }, [value]);

    useEffect(() => {
        setFieldDataToRef({fieldRef});
    }, [field, fieldRef]);

    function renderImage() {
        return (
            <Image
                ref={imageRef}
                style={{
                    width: fieldSize.width < fieldSize.height ? '100%' : 'auto',
                    height: fieldSize.height < fieldSize.width ? '100%' : 'auto'
                }}
                className="signature-image"
            />
        );
    }

    return (
        <FieldWrapper
            field={field}
            className={className}
            onMouseEnter={selectField}
            onMouseLeave={unselectField}
            loading={loading}
            dataWdio="test-accepted-signature-field"
        >
            <>
                {(isSignedUsingSignature || isSignedUsingImage) ? renderImage() : (
                    <>
                        <label className="label">
                            {formatMessage({
                                id: acceptedMessage,
                                defaultMessage: ''
                            })}
                            &nbsp;
                        </label>
                        <label className="name">{fullName}</label>
                    </>
                )}

                {!loading && (
                    <CornerWidget width={fieldSize.width} height={fieldSize.height} className="undo-widget" data-wdio="test-undo-widget-button">
                        <Tooltip
                            placement="top"
                            title={formatMessage({id: 'esl.generic.undo', defaultMessage: ''})}
                            arrow
                        >
                            <AppOutlinedIconButton
                                size="small"
                                onClick={handleUndo}
                                disableFocusRipple
                            >
                                <ReplyRounded aria-hidden />
                            </AppOutlinedIconButton>
                        </Tooltip>
                    </CornerWidget>
                )}
            </>
        </FieldWrapper>
    );
};

export default AcceptedSignature;
