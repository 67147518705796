/* eslint-disable no-plusplus */

const HEX_VALID = /^([0-9a-f]{2})+$/;

function pad(hexstr) {
    return (hexstr.length === 1 ? '0' : '') + hexstr;
}

export default {
    encode(raw) {
        let l = raw.length;
        const hexd = [];

        while (l--) {
            hexd[l] = pad(raw.charCodeAt(l).toString(16));
        }
        return hexd.join('');
    },
    decode(hex) {
        if (!hex) { return ''; }
        if (!HEX_VALID.test(hex)) { throw new Error(`Invalid hexadecimal string: ${hex}`); }

        const raw = [];
        let l = hex.length;

        while (l--) {
            raw.unshift(String.fromCharCode(parseInt(hex.substr(--l, 2), 16)));
        }
        return raw.join('');
    }
};
