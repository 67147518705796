/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable react/no-danger */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import moment from 'moment';
import {ADDITIONAL_FOCUS_ELEMENTS} from '@ValidSignBV/constants';
import {AppButton} from '@components/Core/buttons/AppButton/AppButton';
import {selectConfig, selectShouldShowGDPRBunner, setShouldShowGDPRBunner} from '../../appSlice';
import getCookieExpirationDate from '../../../../server/utils/getCookieExpirationDate';
import {AUTOMATION_ENV} from '../../../../constants';
import isClient from '../../../utils/isClient';
import useAccessRestrictions from '../../../hooks/useAccessRestrictions';
import './GDPRBunner.less';

export default function GDPRBunner() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const shouldShowGDPRBunner = useSelector(selectShouldShowGDPRBunner);
    const config = useSelector(selectConfig);
    const className = classNames('gdpr-bunner');
    const consentCookiesPolicyExpirationDate = isClient ? localStorage.getItem('cookiePolicyAgreedExpirationDate') : null;
    const hasConsentWithCookies = moment(consentCookiesPolicyExpirationDate).isAfter(moment()) || !shouldShowGDPRBunner;
    const {hideCookieBanner} = useAccessRestrictions();

    function hideGDPRBunner() {
        Cookies.set('esl-cookie-consent', true, config.VS_ENV !== AUTOMATION_ENV
            ? {sameSite: 'none', secure: true, expires: getCookieExpirationDate()}
            : {expires: getCookieExpirationDate()});
        if (isClient) {
            localStorage.setItem('cookiePolicyAgreedExpirationDate', getCookieExpirationDate());
        }
        dispatch(setShouldShowGDPRBunner(false));
    }

    if (hasConsentWithCookies || hideCookieBanner) {
        return null;
    }

    return (
        <div className={className} role="alert">
            <div className="message-container">
                <span>
                    {intl.formatMessage({id: 'esl.cookie_policy_message', defaultMessage: ''})}
                </span>

                <a
                    className="policies-link"
                    href={intl.formatMessage({id: 'esl.cookie_policy_url', defaultMessage: ''})}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {intl.formatMessage({id: 'esl.cookie_policy_read_more_ada', defaultMessage: ''})}
                </a>
            </div>

            <AppButton
                onClick={hideGDPRBunner}
                className="dismiss-btn"
                isPrimary
                aria-label={intl.formatMessage({id: 'esl.generic.close', defaultMessage: ''})}
                data-focus-id={ADDITIONAL_FOCUS_ELEMENTS.gdprButton}
                data-wdio="test-cookies-banner-close-btn"
            >
                {intl.formatMessage({id: 'esl.cookie_policy_ok', defaultMessage: ''})}
            </AppButton>
        </div>
    );
}
