import React from 'react';
import classnames from 'classnames';
import {v4} from 'uuid';
import BaseSelect from '../BaseSelect/BaseSelect';
import AppInputLabel from '../../labels/AppInputLabel/AppInputLabel';

const AppSelect = ({
    children, className, label, htmlFor, id, required, ...rest
}) => {
    const containerClassName = classnames('app-select-container', className);
    const uid = v4();
    const htmlForId = htmlFor !== undefined ? htmlFor : id || uid;
    return (
        <div className={containerClassName}>
            {label && <AppInputLabel id={htmlForId} required={required} htmlFor={uid}>{label}</AppInputLabel>}
            <BaseSelect
                labelId={htmlForId}
                {...rest}
            >
                {children}
            </BaseSelect>
        </div>
    );
};
export default AppSelect;
