import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import pick from 'lodash.pick';
import httpClient from '../../../utils/httpClient';
import {fetchSenders} from '../../Delegation/delegationSlice';
import {
    BASE_PATH
} from '../../../../constants';
import {getErrorMessage} from '../../../utils/requests/error';

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        roles: [],
        userRoles: [],
        usersRoles: {},
        isRolesFetching: false,
        isUsersRolesFetching: false,
        rolesFetchingError: null,
        isUserSaving: false,
        userSavingError: null,
        isUserRolesSaving: false,
        userRolesSavingError: null,
        isFetchingImageSignature: false
    },
    reducers: {
        setRoles: (state, action) => {
            state.roles = action.payload;
        },
        setUserRoles: (state, action) => {
            state.userRoles = action.payload;
        },
        setUsersRoles: (state, action) => {
            state.usersRoles = action.payload;
        },
        setIsRolesFetching: (state, action) => {
            state.isRolesFetching = action.payload;
        },
        setIsUsersRolesFetching: (state, action) => {
            state.isUsersRolesFetching = action.payload;
        },
        setRolesFetchingError: (state, action) => {
            state.rolesFetchingError = action.payload;
        },
        setIsUserSaving: (state, action) => {
            state.isUserSaving = action.payload;
        },
        setUserSavingError: (state, action) => {
            state.userSavingError = action.payload;
        },
        setIsUserRolesSaving: (state, action) => {
            state.isUserRolesSaving = action.payload;
        },
        setUserRolesSavingError: (state, action) => {
            state.userRolesSavingError = action.payload;
        }

    }
});

export const {
    setRoles,
    setUserRoles,
    setUsersRoles,
    setIsRolesFetching,
    setIsUsersRolesFetching,
    setRolesFetchingError,
    setIsUserSaving,
    setUserSavingError,
    setIsUserRolesSaving,
    setUserRolesSavingError,
    setImageSignature,
    setIsFetchingImageSignature
} = usersSlice.actions;

export const fetchRoles = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/roles`;
    dispatch(setIsRolesFetching(true));
    dispatch(setRolesFetchingError(null));

    try {
        const result = await httpClient.get(url);
        dispatch(setRoles(get(result, 'data.results', [])));
        dispatch(setIsRolesFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsRolesFetching(false));
        dispatch(setRolesFetchingError(getErrorMessage(err)));
        return false;
    }
};

export const fetchUserRoles = ({userId, accountId}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/senders/${userId}/roles?accountId=${accountId}`;

    dispatch(setIsRolesFetching(true));
    dispatch(setRolesFetchingError(null));

    try {
        const result = await httpClient.get(url);
        dispatch(setUserRoles(get(result, 'data[0].accountRoles', [])));
        dispatch(setIsRolesFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsRolesFetching(false));
        dispatch(setRolesFetchingError(getErrorMessage(err)));
        return false;
    }
};

export const fetchUsersRoles = (usersIds = [], accountId) => async (dispatch) => {
    dispatch(setIsUsersRolesFetching(true));

    try {
        const results = await Promise.all(usersIds.map((id) => {
            const url = `${BASE_PATH}/proxy/account/senders/${id}/roles?accountId=${accountId}`;
            return httpClient.get(url);
        }));

        const usersRoles = {};

        results.forEach(({data}) => {
            const id = [get(data, '[0].userId', '')];
            usersRoles[id] = get(data, '[0].accountRoles', []);
        });

        dispatch(setUsersRoles(usersRoles));
        dispatch(setIsUsersRolesFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsUsersRolesFetching(false));
        return false;
    }
};

async function createUser(user) {
    const url = `${BASE_PATH}/proxy/account/senders`;

    return httpClient.post(url, user);
}

async function updateUser(user) {
    const url = `${BASE_PATH}/proxy/account/senders/${user.id}`;

    return httpClient.post(url, user);
}

async function saveUserRoles({userId, accountId} = {}, roles) {
    const url = `${BASE_PATH}/proxy/account/senders/${userId}/roles`;
    return httpClient.post(url, {
        accountId,
        accountRoles: roles
    });
}

export const saveUser = (user = {}, roles = []) => async (dispatch, getState) => {
    const {activeAccountId, features} = getState().user;
    const userPayLoad = pick(user, [
        'id',
        'firstName',
        'lastName',
        'email',
        'company',
        'title',
        'data'
    ]);
    let userToSave = user;

    dispatch(setIsUserSaving(true));
    dispatch(setUserSavingError(null));

    try {
        if (userPayLoad.id) {
            await updateUser(userPayLoad);
        } else {
            const result = await createUser(userPayLoad);
            userToSave = get(result, 'data', {});
        }

        if (userToSave.id && features.rolesAndPermissions) {
            await saveUserRoles({
                userId: userToSave.id,
                accountId: activeAccountId || userToSave.account.id
            }, roles);
        }

        await dispatch(fetchSenders());
        await dispatch(fetchUserRoles({
            userId: userToSave.id,
            accountId: activeAccountId || userToSave.account.id
        }));
        dispatch(setIsUserSaving(false));
        return true;
    } catch (err) {
        dispatch(setIsUserSaving(false));
        dispatch(setUserSavingError(getErrorMessage(err)));
        return false;
    }
};

export const selectRoles = (state) => state.users.roles;
export const selectUsersRoles = (state) => state.users.usersRoles;
export const selectUserRoles = (state) => state.users.userRoles;
export const selectIsRolesFetching = (state) => state.users.isRolesFetching;
export const selectIsUsersRolesFetching = (state) => state.users.isUsersRolesFetching;
export const selectRolesFetchingError = (state) => state.users.rolesFetchingError;
export const selectIsUserSaving = (state) => state.users.isUserSaving;
export const selectUserSavingError = (state) => state.users.userSavingError;

export default usersSlice.reducer;
