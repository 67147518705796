import {useEffect} from 'react';

export const useKeyboardShortcuts = (undo, redo, clear) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.metaKey || event.ctrlKey) {
                switch (event.key.toLowerCase()) {
                    case 'z':
                        if (event.shiftKey) {
                            event.preventDefault();
                            redo();
                        } else {
                            event.preventDefault();
                            undo();
                        }
                        break;
                    case 'y':
                        event.preventDefault();
                        redo();
                        break;
                    case 'x':
                        event.preventDefault();
                        clear();
                        break;
                    default:
                        // Do nothing for other keys
                        break;
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [undo, redo, clear]);
};
