import getStroke from 'perfect-freehand';
// eslint-disable-next-line import/no-cycle
import {getSvgPathFromStroke} from '@components/Core/components/PerfectSignature/hooks/utils';

/**
 * Converts signatrue data to a PNG image and returns it as a Blob.
 * The SVG is created in the same way as in AnimatedSignature to ensure identical appearance.
 * @param {Object} signatureData - The scaled signature data for drawing.
 * @param {number} width - The width of the output image.
 * @param {number} height - The height of the output image.
 * @param {string} color - The color to use for the signature paths.
 * @param {Object} options - Options for the stroke rendering.
 * @returns {Promise<Blob>} - A promise that resolves to the PNG Blob.
 */
export async function convertSignatureDataToPNG({signatureData}) {
    const {
        lines, width, height, color, options
    } = signatureData;

    return new Promise((resolve, reject) => {
        // Generate strokes and SVG path commands from signatureData lines
        const strokes = lines.map((line) => getStroke(line, options));
        const pathsData = strokes.map((stroke) => getSvgPathFromStroke(stroke)).filter((path) => path !== '');

        // Create an SVG element using the same structure as in AnimatedSignature
        const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svgElement.setAttribute('width', width);
        svgElement.setAttribute('height', height);
        svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);

        pathsData.forEach((d) => {
            const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            path.setAttribute('d', d);
            path.setAttribute('fill', color);
            svgElement.appendChild(path);
        });

        // Serialize SVG and create canvas for conversion
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const svgBlob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});
        const url = URL.createObjectURL(svgBlob);

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;

        const image = new Image();
        image.onload = () => {
            context.drawImage(image, 0, 0);
            URL.revokeObjectURL(url);

            canvas.toBlob((blob) => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error('Failed to convert SVG to PNG'));
                }
            }, 'image/png');
        };

        image.onerror = () => {
            URL.revokeObjectURL(url);
            reject(new Error('Failed to load SVG image'));
        };

        image.src = url;
    });
}
