/* eslint-disable react/no-danger */
import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import './newsStep.less';

export default function NewsStep({
    title,
    description,
    background,
    image
}) {
    const styles = {
        backgroundImage: `url(${background})`
    };

    return (
        <div className="news-step">
            <div className="background-container" style={styles}>
                <img src={image} className="image" alt="" />
            </div>
            <div className="step-divider" />
            <div className="step-title">
                <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}} />
            </div>
            <div className="step-description">
                <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description)}} />
            </div>
        </div>
    );
}
