import React from 'react';
import MUIDialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import './dialogModal.less';

export const Dialog = ({
    isOpen,
    title,
    content,
    actions,
    className,
    children,
    titleProps,
    contentProps,
    actionsProps,
    fullWidth = false,
    classes,
    ...restProps
}) => {
    const containerClassName = classNames('dialog', className, {'dialog-full-width': fullWidth});

    return (
        <MUIDialog
            open={isOpen}
            classes={{root: containerClassName, ...classes}}
            {...restProps}
        >
            {children || (
                <>
                    {title && (
                        <h2 className="title" {...titleProps}>
                            {title}
                        </h2>
                    )}
                    {content && (
                        <div className="content" {...contentProps}>
                            {content}
                        </div>
                    )}
                    {actions && (
                        <div className="actions" {...actionsProps}>
                            {actions}
                        </div>
                    )}
                </>
            )}
        </MUIDialog>
    );
};
