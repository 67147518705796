import React from 'react';
import classNames from 'classnames';
import {getCornerWidgetSize} from './utils';

export const CornerWidget = ({
    width, height, children, className, ...restProps
}) => {
    const containerClasssName = classNames('corner-widget', className);
    const size = getCornerWidgetSize({width, height});
    return (
        <div
            className={containerClasssName}
            style={{
                right: `-${size / 2}px`,
                top: `-${size / 2}px`,
                width: `${size}px`,
                height: `${size}px`
            }}
            {...restProps}
        >
            {children}
        </div>
    );
};
