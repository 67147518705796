import React from 'react';
import classnames from 'classnames';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import {DEFAULT_TRANSACTIONS_OPTIONS, PACKAGES_PER_PAGE_OPTIONS} from '../../../../constants';
import useJoinMessage from '../../../hooks/useJoinMessage';
import BaseSelect from '../../Core/inputs/BaseSelect/BaseSelect';
import './pagination.less';

export default function PagePagination({
    from, perPage, total, changePage, changePerPage, className
}) {
    const joinMessage = useJoinMessage();
    const classNames = classnames('pagination', className);
    const pagesCount = Math.ceil(total / perPage);
    const currentpage = Math.ceil(from / perPage);
    const selectLabel = joinMessage('esl.generic.select_number_of_items_per_page');

    function onPageChange(e, page) {
        const newTo = page * perPage;
        const newFrom = newTo - perPage + 1;

        changePage({
            from: newFrom,
            to: newTo
        });
    }

    function onPerPageChange(e) {
        const newPerPage = e.target.value;

        const newTo = newPerPage;
        const newFrom = DEFAULT_TRANSACTIONS_OPTIONS.from;

        changePerPage(newPerPage);
        changePage({
            from: newFrom,
            to: newTo
        });
    }

    return (
        <div className={classNames}>
            <BaseSelect
                value={perPage}
                onChange={onPerPageChange}
                inputProps={{'aria-label': selectLabel}}
            >
                <MenuItem value={PACKAGES_PER_PAGE_OPTIONS[10]}>
                    {PACKAGES_PER_PAGE_OPTIONS[10]}
                </MenuItem>
                <MenuItem value={PACKAGES_PER_PAGE_OPTIONS[20]}>
                    {PACKAGES_PER_PAGE_OPTIONS[20]}
                </MenuItem>
            </BaseSelect>
            <Pagination
                page={currentpage}
                count={pagesCount}
                onChange={onPageChange}
                size="small"
                showFirstButton
                showLastButton
            />
        </div>
    );
}
