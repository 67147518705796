import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import {HiOutlineX} from '@react-icons/all-files/hi/HiOutlineX';
import SimpleBar from 'simplebar-react';
import classnames from 'classnames';
import Loading from '../Loading/Loading';
import {PACKAGE_PAGE_FOCUS_ELEMENTS} from '../../../../constants';
import './sidePanel.less';

let timeout = null;
export default function SidePanel({
    children,
    open,
    onClose,
    onCloseAway,
    onSubmit,
    className,
    isLoading = false,
    title = '',
    titleNode,
    subtitle,
    buttonsNode,
    firstFocusEl,
    scrollNodeRef,
    disablePortal = true,
    ...rest
}) {
    const intl = useIntl();
    const classNames = classnames('side-panel', className);

    useEffect(() => {
        if (open && firstFocusEl && firstFocusEl.current) {
            timeout = setTimeout(() => {
                firstFocusEl.current.focus();
            }, 0);
        }
        return () => clearTimeout(timeout);
    }, [open]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onCloseAway || onClose}
            classes={{root: classNames}}
            disablePortal={disablePortal}
            ModalProps={{keepMounted: true}}
            PaperProps={{
                'aria-label': title
            }}
            {...rest}
        >
            <div
                className="side-panel-container"
                id={PACKAGE_PAGE_FOCUS_ELEMENTS.recipientsSidePanel}
            >
                <Loading isLoading={isLoading} />
                <div className="side-panel-header">
                    <div className="title-container">
                        {titleNode || (
                            <>
                                <div className="title-block">
                                    <h2 className="title">
                                        {title}
                                    </h2>
                                    {subtitle && <h4 className="subtitle">{subtitle}</h4>}
                                </div>
                                {onClose && (
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        classes={{root: 'close-icon-container'}}
                                        onClick={onClose}
                                        aria-label={intl.formatMessage({id: 'esl.generic.close', defaultMessage: ''})}
                                    >
                                        <HiOutlineX className="close-icon" aria-hidden />
                                    </IconButton>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <form onSubmit={onSubmit} className="form-wrapper">
                    <SimpleBar
                        ref={scrollNodeRef}
                        className="side-panel-simple-bar"
                        style={{height: '100%', maxHeight: '100%', minHeight: 0}}
                    >
                        <section className="side-panel-content">
                            {children}
                        </section>
                    </SimpleBar>
                    {buttonsNode && (
                        <div className="side-panel-btn-container">
                            {buttonsNode}
                        </div>
                    )}
                </form>
            </div>
        </Drawer>
    );
}
