import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {GET_NSC_MESSAGES} from '../../../../../gql/queries';
import {selectAuthObject, selectTransactionId} from '../../signerUiSlice';
import useCeremony from './useCeremony';
import {DEFAULT_LOCALE} from '../../../../../constants';
import {validateAndGetAvailableLocale} from '../../utils/selectors';
import {getIsExampleTransactionID} from '../../utils/functions';

export default function useMessages() {
    const transactionId = useSelector(selectTransactionId);
    const authObject = useSelector(selectAuthObject);
    const signerDataLanguage = authObject?.signerData?.language;
    /**
     * Existing of signerData means this is Signer UI Login (no sessionId).
     * We have to skip ceremony call, to prevent redirection to logout url
     */
    const {
        role = {},
        session = {},
        transaction = {}
    } = useCeremony({skip: signerDataLanguage});
    const unvalidatedLocale = (role.hasLocalLanguage && role.localLanguage)
        || session.user?.language
        || transaction.language
        || signerDataLanguage
        || DEFAULT_LOCALE;
    const locale = validateAndGetAvailableLocale(unvalidatedLocale);
    const {data: {getNscMessages = {}} = {}, error, loading} = useQuery(GET_NSC_MESSAGES, {
        skip: !locale || !transactionId || getIsExampleTransactionID(transactionId),
        variables: {locale, transactionId}
    });
    const {messages = {}} = getNscMessages;

    return useMemo(() => ({
        messages,
        locale,
        error,
        loading
    }), [messages, locale, error, loading]);
}
