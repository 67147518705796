import {gql} from '@apollo/client';

export default gql`mutation authenticate(
  $loginToken: String!
  $authenticationUrl: String!
  $authObject: AuthenticationInput!
  $transactionId: String!
) {
  authenticate(
    loginToken: $loginToken
    authenticationUrl: $authenticationUrl
    authObject: $authObject
    transactionId: $transactionId
  ) {
    sessionId
    redirectTo
    qna {
      question
      answer
      maskInput
      __typename
    }
    sms {
      passcode
      __typename
    }
    saa {
      skippedPhoneNumberCapture
      phoneNumber
      redirectUrl
      __typename
    }
    kba {
      kbaSessionData
      questions {
        id
        question
        answers {
          id
          answer
          selected
          __typename
        }
        __typename
      }
      errorKey
      __typename
    }
    signerData {
      language
      ada
      senderName
      signerName
      transactionName
      virtualRoom
      __typename
    }
    __typename
  }
}`;
