const DOCUMENT_ACCEPT_STARTED_EVENT_NAME = 'ESL:MESSAGE:STARTED:DOCUMENT_ACCEPT'; // done
const DOCUMENT_ACCEPT_SUCCESS_EVENT_NAME = 'ESL:MESSAGE:SUCCESS:DOCUMENT_ACCEPT'; // done
const DOCUMENT_ACCEPT_ERROR_EVENT_NAME = 'ESL:MESSAGE:ERROR:DOCUMENT_ACCEPT'; // done
const DOCUMENT_CONFIRM_STARTED_EVENT_NAME = 'ESL:MESSAGE:STARTED:DOCUMENT_CONFIRM'; // done
const DOCUMENT_CONFIRM_SUCCESS_EVENT_NAME = 'ESL:MESSAGE:SUCCESS:DOCUMENT_CONFIRM'; // done
const DOCUMENT_CONFIRM_ERROR_EVENT_NAME = 'ESL:MESSAGE:ERROR:DOCUMENT_CONFIRM'; // done
const PACKAGE_OPT_OUT_STARTED_EVENT_NAME = 'ESL:MESSAGE:STARTED:PACKAGE_OPT_OUT'; // WHAT IS IT
const PACKAGE_OPT_OUT_SUCCESS_EVENT_NAME = 'ESL:MESSAGE:SUCCESS:PACKAGE_OPT_OUT'; // WHAT IS IT
const PACKAGE_OPT_OUT_ERROR_EVENT_NAME = 'ESL:MESSAGE:ERROR:PACKAGE_OPT_OUT';
const PACKAGE_DECLINE_STARTED_EVENT_NAME = 'ESL:MESSAGE:STARTED:PACKAGE_DECLINE'; // done
const PACKAGE_DECLINE_SUCCESS_EVENT_NAME = 'ESL:MESSAGE:SUCCESS:PACKAGE_DECLINE'; // done
const PACKAGE_DECLINE_ERROR_EVENT_NAME = 'ESL:MESSAGE:ERROR:PACKAGE_DECLINE'; // done
const SIGNER_COMPLETE_STARTED_EVENT_NAME = 'ESL:MESSAGE:STARTED:SIGNER_COMPLETE'; // done
const SIGNER_COMPLETE_SUCCESS_EVENT_NAME = 'ESL:MESSAGE:SUCCESS:SIGNER_COMPLETE'; // done
const DOCUMENT_NAVIGATION_STARTED_EVENT_NAME = 'ESL:MESSAGE:STARTED:DOCUMENT_NAVIGATION'; // done
const DOCUMENT_NAVIGATION_SUCCESS_EVENT_NAME = 'ESL:MESSAGE:SUCCESS:DOCUMENT_NAVIGATION'; // done
const DOCUMENT_VIEW_SUCCESS_EVENT_NAME = 'ESL:MESSAGE:SUCCESS:DOCUMENT_VIEW:'; // done
const GOTO_TRANSACTION_EVENT_NAME = 'ESL:MESSAGE:SUCCESS:GOTO_TRANSACTION'; // done
const SIGNER_COMPLETE_STARTED_REVIEWED_NAME = 'ESL:MESSAGE:STARTED:SIGNER_COMPLETE_REVIEWED'; // done
const SIGNER_COMPLETE_SUCCESS_REVIEWED_NAME = 'ESL:MESSAGE:SUCCESS:SIGNER_COMPLETE_REVIEWED'; // done
const SIGNER_DONE_EVENT_NAME = 'ESL:MESSAGE:SUCCESS:SIGNER_DONE:'; // done
const REGISTER_EVENT_NAME = 'ESL:MESSAGE:REGISTER'; // done
const ACTIVATE_EVENT_NAME = 'ESL:MESSAGE:ACTIVATE_EVENTS'; // done

export default {
    DOCUMENT_ACCEPT_STARTED_EVENT_NAME,
    DOCUMENT_ACCEPT_SUCCESS_EVENT_NAME,
    DOCUMENT_CONFIRM_SUCCESS_EVENT_NAME,
    DOCUMENT_ACCEPT_ERROR_EVENT_NAME,
    PACKAGE_OPT_OUT_STARTED_EVENT_NAME,
    PACKAGE_OPT_OUT_SUCCESS_EVENT_NAME,
    PACKAGE_DECLINE_STARTED_EVENT_NAME,
    PACKAGE_DECLINE_SUCCESS_EVENT_NAME,
    PACKAGE_DECLINE_ERROR_EVENT_NAME,
    DOCUMENT_CONFIRM_STARTED_EVENT_NAME,
    PACKAGE_OPT_OUT_ERROR_EVENT_NAME,
    DOCUMENT_CONFIRM_ERROR_EVENT_NAME,
    SIGNER_COMPLETE_STARTED_EVENT_NAME,
    SIGNER_COMPLETE_SUCCESS_EVENT_NAME,
    DOCUMENT_NAVIGATION_STARTED_EVENT_NAME,
    DOCUMENT_NAVIGATION_SUCCESS_EVENT_NAME,
    DOCUMENT_VIEW_SUCCESS_EVENT_NAME,
    GOTO_TRANSACTION_EVENT_NAME,
    SIGNER_COMPLETE_STARTED_REVIEWED_NAME,
    SIGNER_COMPLETE_SUCCESS_REVIEWED_NAME,
    SIGNER_DONE_EVENT_NAME,
    REGISTER_EVENT_NAME,
    ACTIVATE_EVENT_NAME
};
