/**
 * Checks if the user agent is running on MacOS
 * @returns {boolean} true if the browser is running on MacOS
 */
export const isMacOS = () => {
    if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
        return window.navigator.userAgent.toUpperCase().includes('MAC');
    }
    return false;
};
