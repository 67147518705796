import {
    fetchReminderConfig,
    fetchTemplate
} from './templateSlice';
import {
    fetchGroupsSummary,
    fetchVerificationTypes
} from '../Packages/Recipients/recipientsSlice';

export default function ssrFetch({
    store, headers, baseUrl, params
}) {
    return Promise.all([
        store.dispatch(fetchTemplate({headers, baseUrl, params})),
        store.dispatch(fetchReminderConfig({headers, baseUrl, params})),
        store.dispatch(fetchGroupsSummary({headers, baseUrl})),
        store.dispatch(fetchVerificationTypes({headers, baseUrl}))
    ]);
}
