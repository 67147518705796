import React from 'react';
import classnames from 'classnames';
import AppLabel from '../BaseLabel/BaseLabel';

function appendRequiredCharacterIfNecessary({label, required, requiredCharacter}) {
    if (required && typeof label === 'string' && !label.includes(requiredCharacter)) {
        return ` ${requiredCharacter}`;
    }
    return '';
}

const AppInputLabel = ({
    children, className, disabled, required, error, ...rest
}) => {
    const containerClassName = classnames('app-input-label', className, {
        disabled,
        required,
        error
    });
    const requiredCharacter = '*';

    return (
        <AppLabel
            className={containerClassName}
            {...rest}
        >
            {children}
            {appendRequiredCharacterIfNecessary({label: children, required, requiredCharacter})}
        </AppLabel>
    );
};
export default AppInputLabel;
