import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import GET_DECLINE_DATA from '../../../../gql/getDeclineData.gql';
import {selectTransactionId} from '../signerUiSlice';

export default function useGetDeclineData() {
    const transactionId = useSelector(selectTransactionId);
    const {data = {}, error, loading} = useQuery(GET_DECLINE_DATA, {
        skip: !transactionId,
        variables: {transactionId}
    });

    const {ceremony = {}} = data;
    const {
        role = {},
        transaction = {}
    } = ceremony;

    return useMemo(() => ({
        role,
        transaction,
        error,
        loading
    }), [ceremony, error, loading]);
}
