import React from 'react';
import {useIntl} from 'react-intl';
import FormHelperText from '@material-ui/core/FormHelperText';
import AppInput from '../../../Core/inputs/AppInput/AppInput';

export default function EmailForm({
    email,
    wasChanged,
    onLoginFormChange,
    disabled,
    helperTextProps,
    ...restProps
}) {
    const intl = useIntl();
    const hasError = wasChanged && email === '';

    return (
        <div className="login-email-form">
            <AppInput
                required
                error={hasError}
                id="login-email"
                value={email}
                name="email"
                type="email"
                onChange={onLoginFormChange}
                placeholder={disabled ? '' : intl.formatMessage({id: 'esl.generic.email_example', defaultMessage: ''})}
                dataWdio="test-login-form-email-input"
                aria-describedby="login-email-error"
                autoComplete="username"
                disabled={disabled}
                label={intl.formatMessage({id: 'esl.generic.email', defaultMessage: ''})}
                {...restProps}
            />
            {hasError && (
                <FormHelperText id="login-email-error" data-wdio="test-login-email-required-error" {...helperTextProps}>
                    {intl.formatMessage({id: 'esl.error.validation.userAddition.invalidEmail', defaultMessage: ''})}
                </FormHelperText>
            )}
        </div>
    );
}
