import React from 'react';
import {useIntl} from 'react-intl';
import {HiOutlineDownload} from '@react-icons/all-files/hi/HiOutlineDownload';
import {PCC_STEPS} from '../../constants';
import {DialogButton} from '../../../../Core/company/modals/Dialog';
import {AppButton} from '../../../../Core/buttons/AppButton/AppButton';

const DriverDialog = ({
    url, version, setStep, setOpen
}) => {
    const intl = useIntl();

    const onClose = () => {
        setOpen(false);
        setStep(PCC_STEPS.driver);
    };

    const onDownload = () => {
        setStep(PCC_STEPS.driverSuccess);
    };

    return (
        <>
            <h2 className="title" id="pcc-dialog-title">
                {intl.formatMessage({id: 'oss.pcc.DriverDialog.title', defaultMessage: ''})}
            </h2>
            <div className="content">
                <p>
                    {intl.formatMessage({id: 'oss.pcc.DriverDialog.instructions', defaultMessage: ''})}
                </p>
                <AppButton
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="certificate-download-button"
                    onClick={onDownload}
                    endIcon={<HiOutlineDownload className="icon" />}
                >
                    <p>
                        {intl.formatMessage({id: 'oss.pcc.DriverDialog.download', defaultMessage: ''})}
                        <span> </span>
                        {intl.formatMessage({id: 'oss.pcc.generic.title', defaultMessage: ''})}
                        <span> </span>
                        {version}
                    </p>
                </AppButton>
            </div>
            <div className="actions">
                <DialogButton onClick={onClose} variant="outlined">
                    {intl.formatMessage({id: 'oss.pcc.generic.cancel', defaultMessage: ''})}
                </DialogButton>
            </div>
        </>
    );
};

export default DriverDialog;
