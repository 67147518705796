import {defineMessages} from 'react-intl';

export default defineMessages({
    cancelButton: {
        defaultMessage: 'Cancel',
        id: 'oss.components.NextTransactionDialog.cancel.button'
    },
    from: {
        defaultMessage: 'From',
        id: 'oss.components.NextTransactionDialog.from'
    },
    lastUpdated: {
        defaultMessage: 'Last Updated',
        id: 'oss.components.NextTransactionDialog.lastUpdated'
    },
    proceedButton: {
        defaultMessage: 'Sign Now',
        id: 'oss.components.NextTransactionDialog.proceed.button'
    },
    title: {
        defaultMessage: 'Requires your signature',
        id: 'oss.components.NextTransactionDialog.title'
    },
    transaction: {
        defaultMessage: 'Transaction',
        id: 'oss.components.NextTransactionDialog.transaction'
    }
});
