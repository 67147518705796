import React from 'react';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {DASHBOARD_PATH, RESPONSE_STATUS_CODES} from '../../../../constants';
import './errorPage.less';

export default function ErrorPage({
    goToButtonNode,
    errorCode,
    isDashboardLinkVisible = true,
    customTitleMessage,
    customErrorMessage
}) {
    const intl = useIntl();
    const notFoundErrorMessage = (
        <>
            <p className="message">{intl.formatMessage({id: 'page.notFound.message1', defaultMessage: ''})}</p>
            <p className="message">{intl.formatMessage({id: 'page.notFound.message2', defaultMessage: ''})}</p>
        </>
    );
    const defaultButtonNode = (
        <Link to={DASHBOARD_PATH} className="link">
            {`${intl.formatMessage({id: 'esl.header.logo.goto', defaultMessage: ''})}...`}
        </Link>
    );

    function getTitleByErrorCode() {
        if (errorCode === RESPONSE_STATUS_CODES[403]) {
            return intl.formatMessage({id: 'page.forbidden.title', defaultMessage: ''});
        }
        return intl.formatMessage({id: 'page.notFound.title', defaultMessage: ''});
    }

    return (
        <div className="not-found-page">
            <h1 className="page-title">
                {customTitleMessage || getTitleByErrorCode()}
            </h1>
            <div className="message-container">
                {customErrorMessage || notFoundErrorMessage}
            </div>
            {isDashboardLinkVisible && (
                <span className="link-container">
                    {goToButtonNode || defaultButtonNode}
                </span>
            )}
            <img
                src="/assets/pencil.png"
                className="signature-image"
                alt={intl.formatMessage({id: 'esl.generic.signature', defaultMessage: ''})}
            />
        </div>
    );
}
