import React from 'react';
import {
    TripOriginRounded
} from '@material-ui/icons';
import {HiOutlineCheckCircle} from '@react-icons/all-files/hi/HiOutlineCheckCircle';
import {MdDonutLarge} from '@react-icons/all-files/md/MdDonutLarge';
import {SIGNING_TRANSACTION_STATUS} from '../SigningDocumentView/SigningFields/utils/constants';

export default function getSignerIconAndStatus(status) {
    const {
        INPROGRESS, DONE, NOTSTARTED, REVIEWER, NONE
    } = SIGNING_TRANSACTION_STATUS;

    switch (status) {
        case INPROGRESS:
            return {
                icon: <MdDonutLarge className="in-person-status-icon is-in-progress" />,
                status: 'oss.components.InPerson.TransactionStatus.textTransactionStatusStarted'
            };
        case DONE:
            return {
                icon: <HiOutlineCheckCircle className="in-person-status-icon is-done" />,
                status: 'oss.components.InPerson.TransactionStatus.textTransactionStatusDone'
            };
        case NOTSTARTED:
            return {
                icon: <TripOriginRounded className="in-person-status-icon is-not-started" />,
                status: 'oss.components.InPerson.TransactionStatus.textTransactionStatusNotStarted'
            };
        case REVIEWER:
        case NONE:
        default:
            return null;
    }
}
