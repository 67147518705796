import {gql} from '@apollo/client';

export default gql`query getDocument($transactionId: ID!) {
    ceremony(transactionId: $transactionId) {
      role {
        id
        hasLocalLanguage
        localLanguage
      }
      session {
        shouldShowAuth
        authTypes
        maskResponse
      }
      transaction {
        roles {
          email
          fullName
        }
      }
    }
  }`;
