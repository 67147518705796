import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import isEmpty from 'lodash.isempty';
import PhoneInput from '../../../../../../shared/PhoneInput/PhoneInput';
import {DEFAULT_PHONE_COUNTRY} from '../../../../../../../../constants';
import './sms.less';

export default function SMS({challenges, changeChallenges}) {
    const intl = useIntl();
    const [wasChanged, setWasChanged] = useState(false);
    const phoneNumber = challenges[0].question;

    function change(number = '') {
        changeChallenges([{
            question: number
        }]);
    }

    function onBlur({target}) {
        if (!wasChanged[target.name]) {
            setWasChanged({
                ...wasChanged,
                [target.name]: true
            });
        }
    }

    if (isEmpty(challenges)) return null;

    return (
        <div className="reassign-auth-sms-container field-container">
            <PhoneInput
                label={intl.formatMessage({id: 'oss.components.Reassign.Auth.SMS.phoneNumber.label', defaultMessage: ''})}
                defaultCountry={DEFAULT_PHONE_COUNTRY}
                value={phoneNumber || ''}
                onChange={change}
                onBlur={onBlur}
            />
        </div>
    );
}
