import React, {forwardRef} from 'react';
import './styles.less';
import classnames from 'classnames';

export const Button = forwardRef(({
    children,
    className,
    disabled,
    ...rest
}, ref) => (
    <button
        ref={ref}
        type="button"
        disabled={disabled}
        className={classnames('base-button', className, {disabled})}
        {...rest}
    >
        {children}
    </button>
));
