export const EVENT_TYPES = [
    'PACKAGE_CREATE',
    'PACKAGE_READY_FOR_COMPLETE',
    'PACKAGE_RESTORE',
    'PACKAGE_EXPIRE',
    'DOCUMENT_SIGNED',
    'DOCUMENT_VIEWED',
    'SIGNER_LOCKED',
    'PACKAGE_ACTIVATE',
    'PACKAGE_COMPLETE',
    'PACKAGE_DELETE',
    'PACKAGE_OPT_OUT',
    'ROLE_REASSIGN',
    'KBA_FAILURE',
    'PACKAGE_DEACTIVATE',
    'PACKAGE_TRASH',
    'PACKAGE_DECLINE',
    'PACKAGE_ATTACHMENT',
    'SIGNER_COMPLETE',
    'EMAIL_BOUNCE',
    'TEMPLATE_CREATE',
    'PACKAGE_ARCHIVE',
    'RECORDINGS_READY',
    'COBROWSE_REQUEST'
];
