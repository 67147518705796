import React from 'react';
import Fade from '@material-ui/core/Fade';
import classnames from 'classnames';
import './loading.less';

export default function Loading({
    isLoading, isSceleton, style, className
}) {
    const classNames = classnames('loading', className, {
        'page-sceleton-loading': isSceleton
    });

    return (
        <Fade
            in={isLoading}
            timeout={{
                enter: 250, exit: 250
            }}
        >
            <div className={classNames} style={style}>
                {isSceleton
                    ? <div className="page-sceleton" />
                    : <img src="/assets/spinner.gif" className="spinner" alt="Please wait..." />}
            </div>
        </Fade>
    );
}
