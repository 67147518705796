import {selectHoveredField} from '@components/SignerUi/signerUiSlice';
import {useSelector} from 'react-redux';
import {useSignatureMetadata} from '../hooks';
import ClickToSignSiganture from './ClickToSignSignature/ClickToSignSignature';
import CaptureSignature from './CaptureSignature/CaptureSignature';
import './signatureFields.less';

const SignatureField = ({
    field,
    fieldsErrors,
    selectField,
    unselectField,
    fieldsRef,
    setFieldDataToRef
}) => {
    const {
        approval,
        isClickToSign,
        isCaptureSig,
        isMobileSig,
        isClickToInitial
    } = field;
    const selectedField = useSelector(selectHoveredField);

    const signatureMetaData = useSignatureMetadata({
        isClickToSign,
        isCaptureSig,
        isClickToInitial,
        isOptional: approval.isOptional,
        isMobileSig
    });

    return isCaptureSig || isMobileSig ? (
        <CaptureSignature
            field={field}
            signatureMetaData={signatureMetaData}
            selectedField={selectedField}
            selectField={selectField}
            unselectField={unselectField}
            fieldsErrors={fieldsErrors}
            fieldsRef={fieldsRef}
            setFieldDataToRef={setFieldDataToRef}
        />
    ) : (
        <ClickToSignSiganture
            field={field}
            signatureMetaData={signatureMetaData}
            selectedField={selectedField}
            selectField={selectField}
            unselectField={unselectField}
            fieldsErrors={fieldsErrors}
            fieldsRef={fieldsRef}
            setFieldDataToRef={setFieldDataToRef}
        />
    );
};

export default SignatureField;
