import React, {useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import Button from '@material-ui/core/Button';
import {useSelector} from 'react-redux';
import messages from '../../../../messages/fieldsMessages';
import {useFieldClassName} from '../../hooks';
import FieldWrapper from '../../FieldWrapper/FieldWrapper';
import {selectDocumentFields} from '../../../../signerUiSlice';
import {isFieldDisabled} from '../../../../utils/selectors';

const SignatureButton = ({
    children,
    classes,
    onClick,
    ariaLabel,
    field = {approval: {}},
    errorMessage,
    selectedField = {},
    selectField,
    unselectField,
    fieldsRef,
    className,
    setFieldDataToRef,
    loading,
    ...other
}) => {
    const {
        approval: {isOptional}
    } = field;
    const {formatMessage} = useIntl();
    const fields = useSelector(selectDocumentFields);
    const isDisabled = isFieldDisabled(field, fields);
    const containerClassName = useFieldClassName(field, {[className]: !!className});
    const fieldRef = useRef(null);

    useEffect(() => {
        setFieldDataToRef({fieldRef});
    }, [field, fieldRef]);

    const buttonField = (
        <Button
            className="signature-button"
            onClick={onClick}
            disabled={isDisabled}
            ref={fieldRef}
            aria-label={ariaLabel}
            {...other}
        >
            <div className="text-container">
                <p className="message">
                    {children}
                </p>
                {isOptional && (
                    <p className="sub-message">
                        {formatMessage(messages.signatureOptional)}
                    </p>
                )}
            </div>
        </Button>
    );

    return (
        <FieldWrapper
            field={field}
            fieldsRef={fieldsRef}
            className={containerClassName}
            onMouseEnter={selectField}
            onMouseLeave={unselectField}
            showErrorTooltip={!!errorMessage && selectedField.id === field.id}
            errorMessage={errorMessage}
            loading={loading}
        >
            {buttonField}
        </FieldWrapper>
    );
};

export default SignatureButton;
