import {REASSIGN_SCHEME_TYPES} from '../../../../constants';
import {useCeremony} from './queries';

export default function useEnforcedReassignAuth() {
    const {
        role: {id: currentRoleId},
        transaction: {
            inPersonRoles = []
        }
    } = useCeremony();
    const currentRole = inPersonRoles.find(({id}) => id === currentRoleId);
    const currentAuth = currentRole?.auth || {};

    if (currentAuth?.scheme && currentAuth?.scheme !== REASSIGN_SCHEME_TYPES.EMAIL) {
        return currentAuth;
    }

    return null;
}
