import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectConfig} from '../../../appSlice';
import {getPdfDocumentForRendered} from '../utils';
import {getIsNotYourTurn, getIsSigningCompleted} from '../../../SignerUi/utils/selectors';

export default function usePdfDocument({
    transactionId, document, role
}) {
    const config = useSelector(selectConfig);
    const isRoleCompletedSigning = getIsSigningCompleted([document]) && !getIsNotYourTurn(role);
    const roleId = role?.id;
    /**
     * It is critical to memorize file object, because "react-pdf" compare object links.
     * And trigger re-render if links is different
    */
    return useMemo(() => getPdfDocumentForRendered({
        transactionId, config, document, roleId
    }), [
        isRoleCompletedSigning,
        transactionId,
        roleId,
        document.id,
        document.isConfirmed,
        document.isConfirmedByAll,
        document.originalPdfUrl,
        document.flattenedPdfUrl
    ]);
}
