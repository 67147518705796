import {useMutation} from '@apollo/client';
import UPLOAD_ATTACHMENT from '../../../../gql/uploadAttachment.gql';

export default function useUploadAttachment({options} = {}) {
    const [uploadAttachment, {
        loading, error, data, reset
    }] = useMutation(UPLOAD_ATTACHMENT, options);

    return {
        uploadAttachment, loading, error, data, reset
    };
}
