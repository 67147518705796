/* eslint-disable react/no-danger */
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import useGetRoleCeremonyInfo from '../hooks/useGetRoleCeremonyInfo';
import {selectTransactionId} from '../signerUiSlice';
import {exitToSender} from '../utils/selectors';
import useMarkedMessage from '../hooks/useMarkedMessage';
import './decline.less';

const DECLINE_SCENARIOS = {
    HOST: 'HOST',
    SIGNER: 'SIGNER'
};

export default function InPersonDecline() {
    const intl = useIntl();
    const formatMessage = useMarkedMessage();
    const transactionId = useSelector(selectTransactionId);
    const {role, transaction} = useGetRoleCeremonyInfo();
    const [currentView, setCurrentView] = useState(DECLINE_SCENARIOS.SIGNER);

    function done() {
        // TODO: changeLocale(inPersonCeremonyHost.localLanguage);
        setCurrentView(DECLINE_SCENARIOS.HOST);
    }

    function exitSigning() {
        exitToSender(transactionId);
    }

    return (
        <div className="decline-signing">
            {currentView === DECLINE_SCENARIOS.SIGNER ? (
                <>
                    <section className="decline-section">
                        <div className="title">
                            {intl.formatMessage({id: 'oss.components.InPerson.Decline.Signer.Message.Title', defaultMessage: ''}, {
                                fullName: role?.fullName
                            })}
                        </div>
                        <div
                            className="title-message"
                            dangerouslySetInnerHTML={{
                                __html: formatMessage({
                                    key: 'oss.components.InPerson.Decline.Signer.Message.Description',
                                    options: {transactionName: transaction?.name},
                                    markedOptions: {breaks: true},
                                    formatAfterMarked: true,
                                    beforeFormat: (msg) => msg.replace('\'\'{transactionName}\'\'', '**{transactionName}**')
                                })
                            }}
                        />
                    </section>
                    <section className="actions-container">
                        <Button
                            size="small"
                            onClick={done}
                            classes={{root: 'decline-signing-btn'}}
                        >
                            {intl.formatMessage({id: 'oss.components.InPerson.Decline.Signer.doneButton', defaultMessage: ''})}
                        </Button>
                    </section>
                </>
            ) : (
                <>
                    <section className="decline-section">
                        <div className="title">
                            {intl.formatMessage({id: 'oss.components.InPerson.Decline.Host.Message.Title', defaultMessage: ''})}
                        </div>
                        <div className="title-message">
                            {intl.formatMessage({id: 'oss.components.InPerson.Decline.Host.Message.Description', defaultMessage: ''}, {
                                fullName: role?.fullName
                            })}
                        </div>
                    </section>
                    <section className="actions-container">
                        <Button
                            size="small"
                            onClick={exitSigning}
                            classes={{root: 'decline-signing-btn'}}
                        >
                            {intl.formatMessage({id: 'oss.components.InPerson.Decline.Host.gotoTransactionButton', defaultMessage: ''})}
                        </Button>
                    </section>
                </>
            )}
        </div>

    );
}
