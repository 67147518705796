import {useMutation} from '@apollo/client';
import {AUTHENTICATE} from '../../../../../gql/mutations';

export default function useAuthenticate(options) {
    const [authenticate, {
        loading, error, data, reset
    }] = useMutation(AUTHENTICATE, options);

    return {
        authenticate, loading, error, data, reset
    };
}
