import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import GET_NEXT_CEREMONY from '../../../../gql/getNextCeremony.gql';
import {selectTransactionId} from '../signerUiSlice';

export default function useNextCeremony() {
    const transactionId = useSelector(selectTransactionId);
    const {
        data: {nextCeremony = {}} = {}, error, loading, refetch
    } = useQuery(GET_NEXT_CEREMONY, {
        variables: {transactionId}
    });

    return useMemo(() => ({
        nextCeremony,
        error,
        loading,
        refetch
    }), [nextCeremony, error, loading, refetch]);
}
