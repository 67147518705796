export default function getDocumentUId(document = {}) {
    const {
        name,
        lastModified,
        size,
        type
    } = document;

    return escape(`${name}-${lastModified}-${size}-${type}`);
}
