export default function isAllDocsToDo(documents, isOwner) {
    return !documents.some((doc) => {
        const {signerApprovals, isConsent} = doc;
        const acceptedApprovals = signerApprovals.filter(
            (approval) => approval.isAccepted && !approval.isOptional
        );
        return (acceptedApprovals.length > 0 || doc.isConfirmed)
            && (!isConsent || !isOwner);
    });
}
