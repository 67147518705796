import React from 'react';
import {ScrollContext} from '../index';
import useScrollBar from '../../../../../hooks/useScrollBar';

const ScrollProvider = ({children, zoomFactor, setZoomFactor}) => {
    const {
        containerRef,
        contentRef,
        scrollBar,
        topOffset,
        leftOffset,
        scrollDirection,
        scrollDimensions,
        reCalculateScrollBar,
        scrollIntoViewByFieldWithPage,
        scrollIntoViewByClick,
        centerFieldOnFocusZoom
    } = useScrollBar({zoomFactor, setZoomFactor});

    return (
        <ScrollContext.Provider value={{
            containerRef,
            contentRef,
            scrollBar,
            topOffset,
            leftOffset,
            scrollDirection,
            scrollDimensions,
            reCalculateScrollBar,
            scrollIntoViewByFieldWithPage,
            scrollIntoViewByClick,
            centerFieldOnFocusZoom,
            setZoomFactor
        }}
        >
            {children}
        </ScrollContext.Provider>
    );
};
export default ScrollProvider;
