import React from 'react';
import {useIntl} from 'react-intl';
import AppDownloadIconButton from '../../../../Core/buttons/AppDownloadIconButton/AppDownloadIconButton';
import {UnderlinedButton} from '../../../../Core/company/buttons/UnderlinedButton/UnderlinedButton';
import './document.less';

export default function Document({
    document, icon, status, onClick, onDownload
}) {
    const intl = useIntl();

    return (
        <div className="sui-document-container">
            <div className="status-container">
                {icon}
                <div className="status">
                    {intl.formatMessage({id: status, defaultMessage: ''})}
                </div>
            </div>
            <div className="name-container">
                {onClick ? (
                    <UnderlinedButton
                        onClick={() => onClick(document)}
                    >
                        {document.name}
                    </UnderlinedButton>
                ) : document.name}

            </div>
            {onDownload && (
                <AppDownloadIconButton
                    className="document-download-btn"
                    onClick={() => onDownload(document.downloadUrl, document.name)}
                />
            )}
        </div>
    );
}
