import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import GET_OVERVIEW_DOCUMENTS from '../../../../gql/getOverviewDocuments.gql';
import {selectTransactionId} from '../signerUiSlice';

export default function useOverviewDocuments() {
    const transactionId = useSelector(selectTransactionId);
    const {data = {}, error, loading} = useQuery(GET_OVERVIEW_DOCUMENTS, {
        skip: !transactionId,
        variables: {transactionId}
    });
    const {ceremony = {}} = data;
    const {
        role = {},
        transaction = {}
    } = ceremony;

    return useMemo(() => ({
        role,
        transaction,
        error,
        loading
    }), [role, transaction, error, loading]);
}
