/* eslint-disable import/prefer-default-export */
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import httpClient from '../httpClient';
import {BASE_PATH} from '../../../constants';

export const getVerificationType = (packageId, roleId) => {
    const servicePath = `packages/${packageId}/roles/${roleId}/verification`;
    return httpClient.get(`${BASE_PATH}/proxy/${servicePath}`);
};

export async function fetchAvatars(baseUrl, emails, options) {
    if (isEmpty(emails)) {
        return false;
    }

    try {
        const results = await Promise.all(emails.map((email) => {
            const url = `${baseUrl}/api/account/senders?search=${email}`;
            return httpClient.get(url, options);
        }));

        const avatars = results.filter(({data}) => !isEmpty(data.results)).map(({data}) => ({
            email: get(data, 'results[0].email'),
            avatarSrc: get(data, 'results[0].data.avatar')
        }));

        return avatars;
    } catch (err) {
        return false;
    }
}
