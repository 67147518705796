import React, {forwardRef} from 'react';
import {TextField} from '@material-ui/core';

const phoneInputField = (props, ref) => (
    <TextField
        {...props}
        className="base-input-container"
        inputRef={ref}
        name="phone"
        InputProps={{
            className: 'base-input-container'
        }}
        // InputProps and inputProps are different props in eyes of MUI
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
            className: 'base-input',
            'data-wdio': 'test-phone-field'
        }}
    />
);

export default forwardRef(phoneInputField);
