/* eslint-disable react/no-danger */
import React from 'react';
import {useSelector} from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import isEmpty from 'lodash.isempty';
import DOMPurify from 'isomorphic-dompurify';
import {selectNotifications} from '../../appSlice';
import './notifications.less';

export default function Notifications() {
    const notifications = useSelector(selectNotifications);

    return (
        <div className="login-notifications">
            {!isEmpty(notifications) && notifications.map(({type, title, description}) => (
                <Alert
                    severity={type}
                    variant="outlined"
                    classes={{root: 'notification-alert'}}
                    key={`${title}-${type}`}
                >
                    {title && <AlertTitle>{title}</AlertTitle>}
                    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description)}} />
                </Alert>
            ))}
        </div>
    );
}
