import eventNames from './eventNames';

export function postToParent(event) {
    const targetWindowOrigin = '*';
    window.parent.postMessage(event, targetWindowOrigin);
    window?.webkit?.messageHandlers?.message?.postMessage(
        event,
        targetWindowOrigin
    );
    window?.android?.postMessage(event, targetWindowOrigin);
}

export const isInBrowser = () => typeof window !== 'undefined';

export function isInIframe() {
    if (isInBrowser()) {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    } else {
        return false;
    }
}
export const isInIOSWebkit = () => !!window?.webkit
    && !/CriOS/.test(window.navigator.userAgent)
    && !/FxiOS/.test(window.navigator.userAgent)
    && !/EdgiOS/.test(window.navigator.userAgent)
    && !/OPiOS/.test(window.navigator.userAgent);

export const isInAndroidWebview = () => window?.navigator?.userAgent?.includes('wv');

export const isInIframeOrWebView = () => isInIframe() || isInIOSWebkit() || isInAndroidWebview();

export function receiveMessageToCallback(event, callback) {
    if (callback) {
        callback(event);
    }
}

export function receiveMessage(event) {
    const self = this;
    const origin = event.origin || event.originalEvent.origin;
    const eventName = event.data;

    function isActivateEventFromUnregisteredOrigin(name) {
        return (name === eventNames.ACTIVATE_EVENT_NAME && !self.targetOrigin);
    }

    function isWaitingForEventFromOrigin(name) {
        return self.pendingPromises[name] && origin === self.targetOrigin;
    }

    if (isActivateEventFromUnregisteredOrigin(eventName)) {
        self.isSystemActive = true;
        self.targetOrigin = origin;
    }

    if (isWaitingForEventFromOrigin(eventName)) {
        self.pendingPromises[eventName].resolve();
        delete self.pendingPromises[eventName];
    }
}
