/* eslint-disable react/no-danger */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DOMPurify from 'isomorphic-dompurify';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {HiOutlinePencilAlt} from '@react-icons/all-files/hi/HiOutlinePencilAlt';
import {
    FROM_SENDER_QUERY_PARAM,
    NEW_DIRECT_SIGN_PATH,
    TRANSACTION_PATH,
} from '../../../../../constants';
import {fetchNextToSignTransaction, selectIsNextToSignTransactionFetching} from '../../../Transactions/transactionsSlice';
import useMarkedMessage from '../../../SignerUi/hooks/useMarkedMessage';

export default function SignNext({closeActions, isDisabled, tooltipPlacement}) {
    const dispatch = useDispatch();
    const formatMessage = useMarkedMessage();
    const isNextToSignTransactionFetching = useSelector(selectIsNextToSignTransactionFetching);
    const [transaction, setTransaction] = useState(null);
    const message = transaction
        ? formatMessage({
            key: 'shared.floatingActions.signNext',
            options: {name: transaction?.name}
        })
        : formatMessage({key: 'shared.floatingActions.signNextEmpty'});

    async function fetch() {
        const result = await dispatch(fetchNextToSignTransaction());

        if (result.length > 0) {
            setTransaction(result[0]);
        } else {
            setTransaction(null);
        }
    }

    useEffect(() => {
        if (!transaction) {
            fetch();
        }
    }, []);

    function renderButton() {
        return (
            <div className="floating-action-btn-container">
                <IconButton
                    size="small"
                    classes={{root: 'floating-action-btn hide-actions'}}
                    onClick={closeActions}
                    href={`${TRANSACTION_PATH}/${transaction?.id}${NEW_DIRECT_SIGN_PATH}?${FROM_SENDER_QUERY_PARAM}=true`}
                    rel="noopener noreferrer"
                    target="_blank"
                    disabled={!transaction || isNextToSignTransactionFetching || isDisabled}
                >
                    <HiOutlinePencilAlt aria-hidden className="icon" />
                </IconButton>
            </div>
        );
    }

    return (
        <>
            {isDisabled ? renderButton() : (
                <Tooltip
                    placement={tooltipPlacement}
                    title={(<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(message)}} />)}
                    classes={{popper: 'floating-action-btn-tooltip'}}
                    arrow
                >
                    {renderButton()}
                </Tooltip>
            )}
        </>
    );
}
