import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {HiOutlineTrash} from '@react-icons/all-files/hi/HiOutlineTrash';
import {useIntl} from 'react-intl';
import classnames from 'classnames';
import './trashButton.less';

const TrashButton = ({
    className = '',
    buttonClassName = '',
    iconClassName = '',
    onClick,
    disabled,
    title,
    size
}) => {
    const intl = useIntl();
    const containerClassName = classnames('trash-btn-container', className);
    const iconButtonClassName = classnames('trash-button', buttonClassName);
    const iconClass = classnames('trash-icon', iconClassName, size);
    const text = title || intl.formatMessage({id: 'shared.delete', defaultMessage: ''});

    const button = (
        <IconButton
            edge="end"
            classes={{root: iconButtonClassName}}
            onClick={onClick}
            color="inherit"
            disabled={disabled}
            aria-label={text}
        >
            <HiOutlineTrash className={iconClass} aria-hidden />
        </IconButton>
    );

    return (
        <div className={containerClassName}>
            <Tooltip
                placement="top"
                title={text}
                arrow
            >
                <div>
                    {button}
                </div>
            </Tooltip>
        </div>
    );
};
export default TrashButton;
