export const ACTION_BASIC_MARGIN_PX = 8;
export const MENU_WIDTH_PX = 50;
export const MENU_HIGHT_PX = MENU_WIDTH_PX;
export const MENU_SIDE_OFFSET_PX = 5;
export const MENU_HORIZONTAL_MERGIN_PX = 70;
export const DEFAULT_ACTION_BUTTON_POSITION = {
    top: '90%',
    left: 'unset',
    right: `${(-MENU_WIDTH_PX + MENU_WIDTH_PX / 2 + MENU_SIDE_OFFSET_PX)}px`
};
export const TRANSITION_DELAY_MULTIPLIER = 0.1;
export const MANU_BUTTON_TRANSITION_DELAY = 0.3;
const {FIRST, SECOND, THIRD} = {FIRST: 0, SECOND: 1, THIRD: 2};
const TRIPLE_MENU = {
    VERTICAL: {
        FLANK_LEFT_PERCENTAGE: 120,
        FLANK_TOP_PERCENTAGE: 75,
        CENTER_TOP: 160
    },
    HORIZONTAL: {
        FLANK_LEFT_PERCENTAGE: 75,
        FLANK_TOP_PERCENTAGE: 120,
        CENTER_TOP: 160
    }
};
const DOUBLE_MENU = {
    VERTICAL: {
        LEFT_PERCENTAGE: 120,
        TOP_PERCENTAGE: 55
    },
    HORIZONTAL: {
        LEFT_PERCENTAGE: 55,
        TOP_PERCENTAGE: 120
    }
};
const TRIPLE_MENU_STYLES = {
    VERTICAL: {
        LEFT: {
            [FIRST]: {
                top: `-${TRIPLE_MENU.VERTICAL.FLANK_TOP_PERCENTAGE}%`,
                left: `${TRIPLE_MENU.VERTICAL.FLANK_LEFT_PERCENTAGE}%`
            },
            [SECOND]: {
                left: `${TRIPLE_MENU.VERTICAL.CENTER_TOP}%`
            },
            [THIRD]: {
                top: `${TRIPLE_MENU.VERTICAL.FLANK_TOP_PERCENTAGE}%`,
                left: `${TRIPLE_MENU.VERTICAL.FLANK_LEFT_PERCENTAGE}%`
            }
        },
        RIGHT: {
            [FIRST]: {
                top: `-${TRIPLE_MENU.VERTICAL.FLANK_TOP_PERCENTAGE}%`,
                left: `-${TRIPLE_MENU.VERTICAL.FLANK_LEFT_PERCENTAGE}%`
            },
            [SECOND]: {
                left: `-${TRIPLE_MENU.VERTICAL.CENTER_TOP}%`
            },
            [THIRD]: {
                top: `${TRIPLE_MENU.VERTICAL.FLANK_TOP_PERCENTAGE}%`,
                left: `-${TRIPLE_MENU.VERTICAL.FLANK_LEFT_PERCENTAGE}%`
            }
        }
    },
    HORIZONTAL: {
        [FIRST]: {
            top: `-${TRIPLE_MENU.HORIZONTAL.FLANK_TOP_PERCENTAGE}%`,
            left: `-${TRIPLE_MENU.HORIZONTAL.FLANK_LEFT_PERCENTAGE}%`
        },
        [SECOND]: {
            top: `-${TRIPLE_MENU.HORIZONTAL.CENTER_TOP}%`
        },
        [THIRD]: {
            top: `-${TRIPLE_MENU.HORIZONTAL.FLANK_TOP_PERCENTAGE}%`,
            left: `${TRIPLE_MENU.HORIZONTAL.FLANK_LEFT_PERCENTAGE}%`
        }
    }
};
const DOUBLE_MENU_STYLES = {
    VERTICAL: {
        LEFT: {
            [FIRST]: {
                top: `-${DOUBLE_MENU.VERTICAL.TOP_PERCENTAGE}%`,
                left: `${DOUBLE_MENU.VERTICAL.LEFT_PERCENTAGE}%`
            },
            [SECOND]: {
                top: `${DOUBLE_MENU.VERTICAL.TOP_PERCENTAGE}%`,
                left: `${DOUBLE_MENU.VERTICAL.LEFT_PERCENTAGE}%`
            }
        },
        RIGHT: {
            [FIRST]: {
                top: `-${DOUBLE_MENU.VERTICAL.TOP_PERCENTAGE}%`,
                left: `-${DOUBLE_MENU.VERTICAL.LEFT_PERCENTAGE}%`
            },
            [SECOND]: {
                top: `${DOUBLE_MENU.VERTICAL.TOP_PERCENTAGE}%`,
                left: `-${DOUBLE_MENU.VERTICAL.LEFT_PERCENTAGE}%`
            }
        }
    },
    HORIZONTAL: {
        [FIRST]: {
            top: `-${DOUBLE_MENU.HORIZONTAL.TOP_PERCENTAGE}%`,
            left: `-${DOUBLE_MENU.HORIZONTAL.LEFT_PERCENTAGE}%`
        },
        [SECOND]: {
            top: `-${DOUBLE_MENU.HORIZONTAL.TOP_PERCENTAGE}%`,
            left: `${DOUBLE_MENU.HORIZONTAL.LEFT_PERCENTAGE}%`
        }
    }
};

const CLOSED_MENU_STYLES = {
    top: 0,
    left: 0
};

function getTrippleMenuItemStyle(index, isVertical, isOnLeft, transitionDelay) {
    if (isVertical) {
        const verticalStyles = isOnLeft
            ? TRIPLE_MENU_STYLES.VERTICAL.LEFT[index]
            : TRIPLE_MENU_STYLES.VERTICAL.RIGHT[index];

        return {
            ...verticalStyles,
            transitionDelay
        };
    }

    return {
        ...TRIPLE_MENU_STYLES.HORIZONTAL[index],
        transitionDelay
    };
}

function getDoubleMenuItemStyle(index, isVertical, isOnLeft, transitionDelay) {
    if (isVertical) {
        const verticalStyles = isOnLeft
            ? DOUBLE_MENU_STYLES.VERTICAL.LEFT[index]
            : DOUBLE_MENU_STYLES.VERTICAL.RIGHT[index];

        return {
            ...verticalStyles,
            transitionDelay
        };
    }

    return {
        ...DOUBLE_MENU_STYLES.HORIZONTAL[index],
        transitionDelay
    };
}

export function getSubMenuStyle({
    index, length, isVertical, isOnLeft, isOpen
}) {
    const transitionDelay = `${TRANSITION_DELAY_MULTIPLIER * index}s`;

    if (isOpen) {
        if (length === 3) {
            return getTrippleMenuItemStyle(index, isVertical, isOnLeft, transitionDelay);
        }

        if (length === 2) {
            return getDoubleMenuItemStyle(index, isVertical, isOnLeft, transitionDelay);
        }
    }

    return {
        ...CLOSED_MENU_STYLES,
        transitionDelay
    };
}

export function getActionStyles({
    index, length, isVertical, maxHorizontalIndex, isOnLeft, isToTop, isOpen
}) {
    const leftReversedIndex = maxHorizontalIndex - index;
    const reversedIndex = length - index;
    const transitionDelay = `${TRANSITION_DELAY_MULTIPLIER * index}s`;
    const left = isOnLeft
        ? `calc(${100 * leftReversedIndex}% + ${ACTION_BASIC_MARGIN_PX * leftReversedIndex}px + ${MENU_SIDE_OFFSET_PX}px)`
        : `calc(${-100 * leftReversedIndex}% - ${ACTION_BASIC_MARGIN_PX * leftReversedIndex}px - ${MENU_SIDE_OFFSET_PX}px)`;
    const top = isToTop
        ? `calc(${-100 * reversedIndex}% - ${ACTION_BASIC_MARGIN_PX * reversedIndex}px - ${MENU_SIDE_OFFSET_PX}px)`
        : `calc(${100 * reversedIndex}% + ${ACTION_BASIC_MARGIN_PX * reversedIndex}px + ${MENU_SIDE_OFFSET_PX}px)`;

    if (isVertical) {
        return {
            top: isOpen ? top : 0,
            transitionDelay: isOpen ? transitionDelay : 0
        };
    }

    return {
        left: isOpen ? left : 0,
        transitionDelay: isOpen ? transitionDelay : 0
    };
}
