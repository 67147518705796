import React from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {DASHBOARD_PATH} from '../../../../constants';
import {selectAccount} from '../../Login/userSlice';
import './logo.less';

export default function Logo({src, isRedirectDisabled, ...props}) {
    const intl = useIntl();
    const {logoUrl} = useSelector(selectAccount) || {};
    const logoSrc = logoUrl || src;

    const svg = (
        <svg
            id="prefix__Laag_1"
            x={0}
            y={0}
            viewBox="0 0 116.7 40"
            width="200"
            height="68"
            xmlSpace="preserve"
            aria-label={intl.formatMessage({id: 'esl.header.logo.goto', defaultMessage: ''})}
            alt={intl.formatMessage({id: 'esl.branding.default_alt_text', defaultMessage: ''})}
            {...props}
            className="default-logo-img"
        >
            <style>{'.prefix__st0{fill:var(--logo-secondary-color)}.prefix__st1{fill:#f26522}'}</style>
            <path
                className="prefix__st0"
                d="M9.4 23.5L4.2 10.6s-.1-.1-.1-.2v-.2-.2-.3c0-.5.2-.9.5-1.2.4-.3.8-.4 1.3-.4.4 0 .7.1 1 .3.3.2.5.5.6.8l3.6 9.6 3.6-9.6c.2-.3.4-.6.6-.8.3-.2.6-.3 1-.3.5 0 .9.2 1.3.5s.6.7.6 1.2v.7c0 .1 0 .1-.1.2l-5.2 12.9c-.2.4-.4.7-.6 1-.2.3-.6.4-1.1.4-.5 0-.9-.1-1.1-.4-.4-.4-.6-.8-.7-1.1zM16.5 22.4l4.7-12.6c.2-.5.5-1 1-1.3.4-.4 1-.6 1.6-.6.6 0 1.2.2 1.6.5.4.4.7.8.9 1.4l4.6 12.6c.1.2.1.4.2.5v.3c0 .4-.2.8-.5 1.1-.3.3-.7.4-1.1.4-.5 0-.9-.1-1.1-.3-.3-.2-.5-.5-.6-.8l-.7-2.2h-6.5l-.7 2.2c-.1.4-.3.7-.6.9-.3.2-.6.3-1.1.3-.5 0-.9-.2-1.2-.5-.3-.3-.5-.7-.5-1.1v-.3c0-.1 0-.2.1-.3v-.1c-.1 0-.1 0-.1-.1zm5-4.2H26l-2.2-6.7-2.3 6.7zM32.2 22.8v-13c0-.6.2-1 .5-1.3.3-.3.8-.5 1.3-.5s.9.2 1.3.5c.3.3.5.8.5 1.3v11.6H40c.5 0 .9.2 1.2.5.3.3.4.6.5.9v.2c0 .4-.2.7-.5 1-.3.3-.7.5-1.2.5h-5.9c-.6 0-1-.2-1.4-.5-.3-.2-.5-.6-.5-1.2zM42.3 23V9.8c0-.6.2-1 .5-1.3.3-.3.8-.5 1.3-.5s.9.2 1.3.5c.3.3.5.8.5 1.3V23c0 .6-.2 1-.5 1.3-.3.3-.8.5-1.3.5s-.9-.2-1.3-.5-.5-.7-.5-1.3zM47.9 22.9V10c0-.5.2-.9.5-1.3.3-.3.8-.5 1.3-.5h3.9c2.6 0 4.7.8 6.1 2.3 1.4 1.5 2.2 3.6 2.2 6.2 0 2.4-.7 4.4-2.2 5.8-1.5 1.4-3.4 2.2-5.9 2.2H49.1c-.3-.1-.6-.2-.9-.5-.2-.4-.3-.7-.3-1.3zm3.5-1.4h2.4c1.5 0 2.6-.5 3.4-1.4.7-1 1.1-2.1 1.1-3.5 0-1.5-.4-2.7-1.1-3.7-.7-1-1.9-1.4-3.6-1.4h-2.1v10z"
            />
            <path
                className="prefix__st1"
                d="M68.9 7.9c.3 0 .6 0 .9.1.4 0 .7.1 1.1.2.6.2 1.2.4 1.8.7.5.3.8.8.8 1.3 0 .4-.1.8-.4 1.2-.3.3-.7.5-1.2.5h-.3c-.1 0-.2 0-.3-.1-.1 0-.2-.1-.3-.1-.1 0-.2-.1-.4-.1-.3-.1-.5-.2-.8-.2-.3-.1-.6-.1-1-.1-.5 0-.9.1-1.3.4-.4.3-.6.6-.6 1.1 0 .4.2.7.6.9.4.2.9.4 1.5.6l.9.3.9.3c1 .3 1.9.8 2.7 1.5.8.7 1.2 1.8 1.2 3.2 0 1.7-.6 3-1.8 3.9-1.2 1-2.6 1.4-4.2 1.4h-1.1c-.4 0-.9-.1-1.3-.2-.8-.1-1.6-.4-2.3-.8-.7-.4-1-.9-1-1.6 0-.4.1-.8.4-1.2.3-.3.7-.5 1.2-.5.3 0 .6 0 .9.1l.9.3c.3.1.7.2 1 .3.4.1.7.1 1.1.1.8 0 1.4-.2 1.8-.6.4-.4.6-.9.6-1.4 0-.5-.3-.9-.8-1.2-.5-.3-1.1-.5-1.8-.7-.2-.1-.4-.1-.7-.2-.2-.1-.4-.1-.7-.2-1-.3-1.9-.7-2.7-1.3-.8-.6-1.2-1.5-1.2-2.9 0-1.6.6-2.9 1.7-3.8 1.3-.7 2.6-1.2 4.2-1.2zM76.3 23V9.8c0-.6.2-1 .5-1.3.3-.3.8-.5 1.3-.5s.9.2 1.3.5c.3.3.5.8.5 1.3V23c0 .6-.2 1-.5 1.3-.3.3-.8.5-1.3.5s-.9-.2-1.3-.5-.5-.7-.5-1.3zM95 14.9c.7 0 1.3.2 1.6.6.3.4.5.9.5 1.7 0 2.2-.7 4.1-2.1 5.6-1.4 1.5-3.2 2.2-5.6 2.2-2.4 0-4.3-.8-5.8-2.5-1.5-1.6-2.3-3.6-2.3-6s.7-4.4 2.2-6.1c1.5-1.6 3.4-2.5 5.9-2.5 1.8 0 3.3.3 4.5 1 1.3.7 1.9 1.4 2 2.2 0 .5-.1.9-.4 1.2-.3.3-.6.4-1 .4-.3 0-.5 0-.7-.1l-.6-.3c-.1-.1-.2-.2-.3-.2-.1-.1-.2-.2-.4-.2-.3-.2-.7-.4-1.2-.6-.5-.2-1.1-.3-1.9-.3-1.5 0-2.6.5-3.3 1.6-.7 1.1-1.1 2.3-1.1 3.7 0 1.4.4 2.6 1.1 3.7.7 1.1 1.8 1.6 3.3 1.6h.6c.8-.1 1.6-.4 2.3-1 .7-.6 1-1.4 1-2.6h-2.4c-.5 0-.9-.1-1.2-.4-.3-.3-.5-.7-.5-1.2s.2-.9.5-1.2c.3-.3.7-.4 1.2-.4H95zM98.3 23V9.8c0-.6.2-1 .5-1.3.3-.3.8-.5 1.3-.5h.3c.1 0 .2.1.3.1.2.1.3.2.5.3.2.1.3.2.4.3l7.5 9.8V9.8c0-.6.2-1 .5-1.3.3-.3.8-.5 1.3-.5s.9.2 1.3.5c.3.3.5.8.5 1.3V23c0 .6-.2 1-.5 1.3-.3.3-.8.5-1.3.5h-.3c-.1 0-.2-.1-.3-.1-.2-.1-.3-.1-.5-.3-.2-.1-.3-.2-.4-.3l-7.5-9.7V23c0 .6-.2 1-.5 1.3-.3.3-.8.5-1.3.5s-.9-.2-1.3-.5c-.3-.3-.5-.7-.5-1.3z"
            />
            <path
                className="prefix__st0"
                d="M46.4 35.6c.2-.7.4-1.4.7-2.1.1-.4.3-.7.4-1 .1-.3.3-.7.5-1 0-.1.1-.2.2-.3.1-.1.1-.2.2-.3l.1-.1h.1l.2-.1c.2 0 .3 0 .4.1.2.1.3.2.4.3.3.4.4.8.5 1.2.1.4.1.8.1 1.2 0 .4-.1.8-.1 1.2v.4s0-.1-.1-.1h-.1c.1 0 .2-.1.3-.3.1-.1.2-.3.3-.4l.6-.9c.2-.3.3-.7.4-1 0-.1.1-.2.1-.3 0 0 0-.1.1-.1v-.1s0-.1.1-.1h.2l.1.1s0 .1.1.1c0 .1 0 .1.1.1 0 .1.1.2.1.2.1.2.2.3.3.3h.4c.3-.1.7-.3 1-.5l.9-.6c.3-.2.6-.5.8-.7 0 0 0-.1.1-.1v-.1l.1-.1.2-.2c.2-.1.4-.1.6-.1.2 0 .3.1.4.2.4.3.7.6.9 1l.1.1.1.1v.1l-.1-.1h-.1l.1-.1c.2-.2.5-.5.8-.7.1-.1.2-.1.3-.2l.1-.1h.1s.1-.1.2-.1c.2-.1.5-.2.8-.2.5 0 1 .1 1.4.2l.6.3c.1.1.2.1.3.2l.1.1h.1s.2-.1.3-.2l.8-.8c.4-.3.9-.5 1.3-.5.5 0 .9.1 1.3.2.2.1.4.1.6.2l.3.1h.2c.3.1.6.1.9-.1.3-.1.7-.3 1-.5.3-.2.7-.3 1.1-.4h.1v.1c-.3.2-.6.4-.9.7-.3.2-.6.5-.9.7-.2.1-.4.2-.6.2-.2.1-.5.1-.7 0h-.4l-.3-.1c-.2-.1-.3-.1-.5-.1-.3-.1-.7-.1-.9-.1-.3 0-.5.2-.7.3l-.8.8c-.2.1-.3.3-.6.4-.2.1-.3.1-.5.1s-.4-.1-.5-.1c0 0-.1 0-.1-.1h-.1l-.1-.1c-.1 0-.1-.1-.2-.1-.1-.1-.3-.1-.4-.2-.3-.1-.6-.2-.8-.2-.4 0-.9.5-1.6 1-.1.1-.2.1-.4.2h-.4c-.1 0-.3-.1-.4-.2-.1-.1-.1-.2-.2-.2 0 0 0-.1-.1-.1v-.2-.1c-.1-.3-.3-.5-.5-.7l-.1-.1-.1.1-.1.1c-.3.3-.6.5-1 .7-.7.4-1.3.8-2.1 1-.1 0-.2 0-.3.1-.1 0-.2 0-.4-.1s-.3-.3-.4-.5c0-.1-.1-.2-.1-.3v-.1h.1c.1 0 0 0 0 0v.1c0 .1-.1.2-.1.3 0 .2-.1.4-.1.5 0 .2-.1.4-.2.6-.1.4-.3.7-.5 1.1-.1.2-.2.3-.3.5-.1.2-.2.3-.5.5-.1 0-.1.1-.2.1s-.3.1-.4 0c-.2-.1-.3-.2-.3-.3-.1-.1-.1-.2-.1-.2-.1-.3-.1-.5 0-.7v-.5-.5c0-.3 0-.7-.1-1-.1-.3-.2-.7-.3-.8 0 0-.1-.1 0-.1h.2s-.1 0 0 0l-.1.1c0 .1-.1.1-.1.2-.8 1.2-1.5 2.6-2.3 3.8-.9.1-.9.2-1 .1z"
            />
        </svg>
    );

    const image = (
        <img
            src={logoSrc}
            alt={intl.formatMessage({id: 'esl.branding.default_alt_text', defaultMessage: ''})}
            aria-label={intl.formatMessage({id: 'esl.header.logo.goto', defaultMessage: ''})}
            height="68"
        />
    );

    if (isRedirectDisabled) {
        return (
            <div className="logo">
                {logoSrc ? image : svg}
            </div>
        );
    }

    return (
        <Link className="logo" to={DASHBOARD_PATH}>
            {logoSrc ? image : svg}
        </Link>
    );
}
