import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Navigate, useNavigate, useLocation} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {HiOutlineDownload} from '@react-icons/all-files/hi/HiOutlineDownload';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import queryString from 'query-string';
import fileDownload from 'js-file-download';
import SendersSummary from './SendersSummary/SendersSummary';
import PagePagination from '../../shared/Pagination/Pagination';
import PageTitle from '../../shared/PageTitle';
import {
    fetchSenderTransactionSummary,
    fetchUsageSummaryCSV,
    selectSenderSummaryCount,
    setSenderSummary,
    setSenderSummaryCount
} from '../reportsSlice';
import {PER_PAGE_OPTIONS, REPORTS_PATH, SENDER_REPORT_PATH} from '../../../../constants';
import DatePicker from '../../shared/DatePicker/DatePicker';
import {
    getISODateAtEndOfDay,
    getMomentDateAtEndOfDay,
    getISODateAtStartOfDay,
    getMomentDateAtStartOfDay
} from '../../../utils/helpers';
import './usageSummary.less';

export default function UsageSummary() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const [from, setFrom] = useState(params.from || 1);
    const [to, setTo] = useState(params.to || PER_PAGE_OPTIONS['20']);
    const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS['20']);
    const [startMomentDate, setStartMomentDate] = useState(params.startDate || null);
    const [endMomentDate, setEndMomentDate] = useState(params.endDate || null);
    const senderSummaryCount = useSelector(selectSenderSummaryCount);
    const currentDate = moment();
    const defaultMinDate = moment(100, 'years');
    const startISODate = getISODateAtStartOfDay(startMomentDate);
    const endISODate = getISODateAtEndOfDay(endMomentDate);

    function changeStartDate(date) {
        const momentDate = getMomentDateAtStartOfDay(date);
        setStartMomentDate(momentDate);
    }

    function changeEndDate(date) {
        const momentDate = getMomentDateAtEndOfDay(date);
        setEndMomentDate(momentDate);
    }

    function getUrlWithParams() {
        const query = queryString.stringify({
            from,
            to,
            startDate: startISODate,
            endDate: endISODate
        });
        return `${REPORTS_PATH}?${query}`;
    }

    function fetchSendersSummary(options) {
        navigate(getUrlWithParams(), {replace: true});
        dispatch(fetchSenderTransactionSummary({
            from,
            to,
            startDate: startISODate,
            endDate: endISODate,
            ...options
        }));
    }

    function changePage(options) {
        setFrom(options.from);
        setTo(options.to);

        fetchSendersSummary({
            ...options
        });
    }

    function changePerPage(changedPerPage) {
        setPerPage(changedPerPage);
    }

    async function downloadCsv() {
        const result = await dispatch(fetchUsageSummaryCSV({
            startDate: startISODate,
            endDate: endISODate
        }));

        fileDownload(result, `${intl.formatMessage({id: 'esl.generic.report', defaultMessage: ''})}.csv`);
    }

    useEffect(() => {
        if (params.startDate && params.endDate) {
            dispatch(fetchSenderTransactionSummary({
                startDate: startMomentDate,
                endDate: endMomentDate,
                from,
                to
            }));
        } else {
            dispatch(setSenderSummary([]));
            dispatch(setSenderSummaryCount(0));
        }
    }, []);

    return (
        <div className="usage-summary-report">
            {params.senderId && <Navigate to={`${REPORTS_PATH}${SENDER_REPORT_PATH}${location.search}`} />}
            <PageTitle
                pageTitle={intl.formatMessage({id: 'esl.generic.account_summary_report', defaultMessage: ''})}
            />
            <div className="page-title-container">
                <div className="page-title">
                    <div className="sub-title">
                        {intl.formatMessage({id: 'esl.generic.report', defaultMessage: ''})}
                    </div>
                    <h1 className="report-name">
                        {intl.formatMessage({id: 'esl.generic.account_summary_report', defaultMessage: ''})}
                    </h1>
                </div>
            </div>
            <div className="header-panel">
                <div className="from-date-picker-container">
                    <DatePicker
                        label={intl.formatMessage({id: 'esl.generic.from', defaultMessage: ''})}
                        value={startMomentDate}
                        maxDate={endMomentDate || currentDate}
                        onChange={changeStartDate}
                        fullWidth
                        disableFuture
                        clearable
                        DialogProps={{
                            disablePortal: true
                        }}
                    />
                </div>
                <div className="to-date-picker-container">
                    <DatePicker
                        label={intl.formatMessage({id: 'esl.generic.to', defaultMessage: ''})}
                        value={endMomentDate}
                        minDate={startMomentDate || defaultMinDate}
                        onChange={changeEndDate}
                        fullWidth
                        disableFuture
                        clearable
                        DialogProps={{
                            disablePortal: true
                        }}
                    />
                </div>
                <div className="run-template-container">
                    <Button
                        onClick={fetchSendersSummary}
                        classes={{root: 'run-btn'}}
                        disabled={!startMomentDate || !endMomentDate}
                    >
                        {intl.formatMessage({id: 'esl.partials.reports.submit', defaultMessage: ''})}
                    </Button>
                    <div className="download-container">
                        <Tooltip
                            placement="top"
                            title={intl.formatMessage({id: 'esl.generic.export_to_csv', defaultMessage: ''})}
                            arrow
                        >
                            <IconButton
                                edge="end"
                                classes={{root: 'download-button'}}
                                onClick={downloadCsv}
                                color="inherit"
                            >
                                <HiOutlineDownload className="download-icon" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <SendersSummary
                startDate={startISODate}
                endDate={endISODate}
            />
            {senderSummaryCount > perPage && (
                <div className="senders-summary-pagination-container">
                    <PagePagination
                        from={from}
                        to={to}
                        total={senderSummaryCount}
                        perPage={perPage}
                        className="senders-summary-pagination"
                        changePage={changePage}
                        changePerPage={changePerPage}
                    />
                </div>
            )}
        </div>
    );
}
