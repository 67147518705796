import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import {HiOutlineChevronDown} from '@react-icons/all-files/hi/HiOutlineChevronDown';
import {HiOutlineChevronUp} from '@react-icons/all-files/hi/HiOutlineChevronUp';
import isEmpty from 'lodash.isempty';
import {fetchTransactionDetail, selectTransactionDetails} from '../../../reportsSlice';
import TransactionDetails from './TransactionDetails/TransactionDetails';
import './transactionSummary.less';

export default function TransactionSummary({transaction = {}}) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const transactionDetails = useSelector(selectTransactionDetails);
    const [isOpen, setIsOpen] = useState();
    const [isFetching, setIsFetching] = useState();
    const details = transactionDetails[transaction.uid];

    async function fetchDetails() {
        setIsFetching(true);
        await dispatch(fetchTransactionDetail(transaction.uid));
        setIsFetching(false);
    }

    function toggle() {
        if (!isOpen && isEmpty(details) && !isFetching) {
            fetchDetails();
        }
        setIsOpen(!isOpen);
    }

    return (
        <div className="transaction-summary">
            <ListItem classes={{root: 'transaction-summary-header'}} onClick={toggle}>
                <ListItemText primary={transaction.name} />
                <ListItemSecondaryAction>
                    <IconButton
                        aria-label={intl.formatMessage({id: 'esl.generic.expand', defaultMessage: ''})}
                        onClick={toggle}
                    >
                        {isOpen ? <HiOutlineChevronUp className="chevron-icon" /> : <HiOutlineChevronDown className="chevron-icon" />}
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse
                in={isOpen}
                timeout="auto"
                unmountOnExit
            >
                {isFetching
                    ? <LinearProgress classes={{root: 'linear-progress'}} />
                    : <TransactionDetails details={details} />}
            </Collapse>
        </div>
    );
}
