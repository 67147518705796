import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchAvatarsByEmails, selectAvatars} from '../../Packages/Recipients/recipientsSlice';
import {getCurrentSessionId} from '../../../utils/storageSelectors';
import {useCeremony} from './queries';
import useInPerson from './useInPerson';

export const useAvatars = ({signers}) => {
    const avatars = useSelector(selectAvatars);
    const dispatch = useDispatch();
    const {role} = useCeremony();
    const {inPersonCeremonyHost} = useInPerson();
    const isHostSession = role?.signerId === inPersonCeremonyHost?.signerId;

    useEffect(() => {
        if (isHostSession && signers) {
            dispatch(fetchAvatarsByEmails({
                headers: {
                    token: getCurrentSessionId(),
                    skipAuth: true
                },
                emails: signers.map(({email}) => email)
            }));
        }
    }, [signers, isHostSession]);

    return avatars;
};
