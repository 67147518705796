/**
 * Calculates the bounding box of all paths in the SVG element.
 * @param {SVGElement} svgElement - The SVG element containing paths.
 * @returns {Object} - An object containing minX, minY, width, and height.
 */
export function calculateSVGBoundingBox(svgElement) {
    const paths = svgElement.querySelectorAll('path');
    let [minX, minY, maxX, maxY] = [Infinity, Infinity, -Infinity, -Infinity];

    paths.forEach((path) => {
        const bbox = path.getBBox();

        // Check if the bounding box has a non-zero width and height
        if (bbox.width > 0 && bbox.height > 0) {
            minX = Math.min(minX, bbox.x);
            minY = Math.min(minY, bbox.y);
            maxX = Math.max(maxX, bbox.x + bbox.width);
            maxY = Math.max(maxY, bbox.y + bbox.height);
        }
    });

    // If minX or minY are still Infinity, set them to 0 (fallback)
    if (minX === Infinity) minX = 0;
    if (minY === Infinity) minY = 0;
    if (maxX === -Infinity) maxX = 0;
    if (maxY === -Infinity) maxY = 0;

    return {
        minX,
        minY,
        width: maxX - minX,
        height: maxY - minY
    };
}
