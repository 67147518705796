/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Portal from '@material-ui/core/Portal';
import classnames from 'classnames';
import {HiOutlineStar} from '@react-icons/all-files/hi/HiOutlineStar';
import isEqual from 'lodash.isequal';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import useWindowSize from '../../../hooks/useWindowSize';
import {saveDataAttr} from '../../Dashboard/dashboardSlice';
import {
    selectFeatures,
    selectHasUserPermissionOnRoot,
    selectPermissions,
    selectUser
} from '../../Login/userSlice';
import {RESTRICTED_SHORTCUTS, SHORTCUTS} from '../../../../constants';
import floatingActionsComponentsMap from './floatingActionsComponentsMap';
import permissionsService from '../../../utils/permissionsService';
import {selectIsGlobalLoading} from '../../appSlice';
import './floatingActions.less';
import {
    ACTION_BASIC_MARGIN_PX, DEFAULT_ACTION_BUTTON_POSITION, MANU_BUTTON_TRANSITION_DELAY, MENU_HIGHT_PX, MENU_HORIZONTAL_MERGIN_PX, MENU_SIDE_OFFSET_PX, MENU_WIDTH_PX, TRANSITION_DELAY_MULTIPLIER, getActionStyles
} from './stylesUtils';

export default function FloatingActions({className}) {
    const dispatch = useDispatch();
    const isGlobalLoading = useSelector(selectIsGlobalLoading);
    const features = useSelector(selectFeatures);
    const permissions = useSelector(selectPermissions);
    const hasUserPermissionOnRoot = useSelector(selectHasUserPermissionOnRoot);
    const user = useSelector(selectUser);
    const {
        isEnabled = false,
        shortcuts = [],
        templates = []
    } = get(user, 'data.personal_preferences.floatingMenu', {});
    const storredPosition = get(user, 'data.floatingActionsPosition', DEFAULT_ACTION_BUTTON_POSITION);
    const {
        width: windowWidth,
        height: windowHeight
    } = useWindowSize();
    const [position, setPosition] = useState(storredPosition);
    const [activeAction, setActiveAction] = useState(null);
    const [oldPosition, setOldPosition] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [draggingEventType, setDraggingEventType] = useState(null);
    const allShortcuts = [
        ...shortcuts,
        ...Object.values(RESTRICTED_SHORTCUTS)
    ];
    const evailableShortcuts = allShortcuts.filter((id) => {
        if (SHORTCUTS.CLONE_TRANSACTION === id) {
            if (isEmpty(templates)) {
                return false;
            }
            return permissionsService.hasTransactionPermission({features, permissions, hasUserPermissionOnRoot});
        }

        if (SHORTCUTS.CREATE_TRANSACTION === id) {
            return permissionsService.hasTransactionPermission({features, permissions, hasUserPermissionOnRoot});
        }

        return true;
    });
    const containerEl = useRef(null);
    const buttonElement = useRef(null);
    const isAuthorized = user && user.id;
    const actions = evailableShortcuts
        .map((id) => floatingActionsComponentsMap.find((action) => id === action.id));
    const buttonTransitionDuration = `${(TRANSITION_DELAY_MULTIPLIER * actions.length) + MANU_BUTTON_TRANSITION_DELAY}s`;
    const freeHorizontalSpace = windowWidth - MENU_WIDTH_PX - MENU_SIDE_OFFSET_PX * 2 - MENU_HORIZONTAL_MERGIN_PX;
    const topOffsetPX = (parseFloat(position.top) * windowHeight) / 100;
    const maxHorizontalIndex = Math.min(Math.floor(freeHorizontalSpace / (MENU_WIDTH_PX + ACTION_BASIC_MARGIN_PX)), actions.length);
    const hasVertical = maxHorizontalIndex < actions.length;
    const isToTop = topOffsetPX > windowHeight / 2;
    const isOnLeft = position.right === 'unset';
    const containerClassName = classnames('floating-action-button-container', className, {
        'is-open': isOpen,
        'is-dragging': isDragging,
        'is-left': isOnLeft,
        'is-right': !isOnLeft
    });

    function getVerticalOffsetPercentage(value) {
        return ((value / windowHeight) * 100).toFixed(4);
    }

    function savePosition(positionToSave) {
        dispatch(saveDataAttr({floatingActionsPosition: positionToSave}));
    }

    const snapToSide = useCallback((event) => {
        const newPosition = {
            top: containerEl.current.style.top,
            left: containerEl.current.style.left,
            right: containerEl.current.style.right
        };
        const currentPositionX = event.type === 'touchend' ? event.changedTouches[0].clientX : event.clientX;
        const currentPositionY = event.type === 'touchend' ? event.changedTouches[0].clientY : event.clientY;

        if (currentPositionY < MENU_HIGHT_PX + MENU_SIDE_OFFSET_PX) {
            newPosition.top = `${getVerticalOffsetPercentage(MENU_HIGHT_PX + MENU_SIDE_OFFSET_PX)}%`;
        }

        if (currentPositionY > windowHeight - MENU_HIGHT_PX - MENU_SIDE_OFFSET_PX) {
            newPosition.top = `${getVerticalOffsetPercentage(windowHeight - MENU_HIGHT_PX - MENU_SIDE_OFFSET_PX)}%`;
        }

        if (currentPositionX < windowWidth / 2) {
            newPosition.left = `${(MENU_WIDTH_PX - MENU_WIDTH_PX / 2 + MENU_SIDE_OFFSET_PX)}px`;
            newPosition.right = 'unset';
        } else {
            newPosition.left = 'unset';
            newPosition.right = `${(-MENU_WIDTH_PX + MENU_WIDTH_PX / 2 + MENU_SIDE_OFFSET_PX)}px`;
        }

        containerEl.current.style.top = newPosition.top;
        containerEl.current.style.left = newPosition.left;
        containerEl.current.style.right = newPosition.right;

        return newPosition;
    }, [windowWidth, windowHeight]);

    function move(event) {
        event.preventDefault();

        if (!isOpen) {
            if (event.type === 'touchmove') {
                containerEl.current.style.top = `${getVerticalOffsetPercentage(event.touches[0].clientY)}%`;
                containerEl.current.style.left = `${event.touches[0].clientX}px`;
                containerEl.current.style.right = `${windowWidth - event.touches[0].clientX - MENU_WIDTH_PX}px`;
            } else {
                containerEl.current.style.top = `${getVerticalOffsetPercentage(event.clientY)}%`;
                containerEl.current.style.left = `${event.clientX}px`;
                containerEl.current.style.right = `${windowWidth - event.clientX - MENU_WIDTH_PX - MENU_WIDTH_PX / 2}px`;
            }
        }
    }

    const mouseDown = useCallback((event) => {
        const draggingType = event.type === 'mousedown' ? 'mousemove' : 'touchmove';
        setOldPosition(position);
        setDraggingEventType(draggingType);
        setIsDragging(true);
    }, [position]);

    const mouseUp = useCallback((event) => {
        setIsDragging(false);

        const positionToSave = snapToSide(event);

        if (!isEqual(positionToSave, position)) {
            setPosition(positionToSave);

            if (isAuthorized) {
                savePosition(positionToSave);
            }
        }
    }, [snapToSide, position, isAuthorized]);

    function onClick() {
        if (isEqual(position, oldPosition)) {
            if (isOpen) {
                setActiveAction(null);
            }

            setIsOpen(!isOpen);
        }
    }

    function getActionsWithStyles() {
        return actions.map((action, index) => {
            const isVertical = index > maxHorizontalIndex - 1;
            const style = getActionStyles({
                index,
                length: actions.length,
                isVertical,
                maxHorizontalIndex,
                isOnLeft,
                isToTop,
                isOpen
            });

            return {
                style,
                isVertical,
                ...action
            };
        });
    }

    function closeActions() {
        setActiveAction(null);
        setIsOpen(false);
    }

    function renderAction({
        id, ActionComponent, style, isVertical
    }) {
        const verticalTooltipPlacement = hasVertical && isToTop ? 'bottom' : 'top';
        const horizontalTooltipPlacement = isOnLeft ? 'right' : 'left';
        const tooltipPlacement = isVertical ? horizontalTooltipPlacement : verticalTooltipPlacement;

        return (
            <li className="floating-action-container" key={id} style={style}>
                <ActionComponent
                    closeActions={closeActions}
                    isOpen={isOpen}
                    isOnLeft={isOnLeft}
                    isVertical={isVertical}
                    setActiveAction={setActiveAction}
                    isDisabled={activeAction !== null && activeAction !== id}
                    tooltipPlacement={tooltipPlacement}
                />
            </li>
        );
    }

    useEffect(() => {
        if (!isEqual(storredPosition, position)) {
            setPosition(storredPosition);
        }
    }, [storredPosition]);

    useEffect(() => {
        if (isDragging && !isOpen) {
            window.document.body.addEventListener(draggingEventType, move, {passive: false});
        } else {
            window.document.body.removeEventListener(draggingEventType, move);
        }
        return () => {
            window.document.body.removeEventListener('mousemove', move);
            window.document.body.removeEventListener('touchmove', move);
        };
    }, [isDragging]);

    useEffect(() => {
        if (buttonElement.current) {
            buttonElement.current.addEventListener('mousedown', mouseDown, {passive: false});
            buttonElement.current.addEventListener('mouseup', mouseUp, {passive: false});
            buttonElement.current.addEventListener('touchstart', mouseDown, {passive: false});
            buttonElement.current.addEventListener('touchend', mouseUp, {passive: false});
        }
        return () => {
            if (buttonElement.current) {
                buttonElement.current.removeEventListener('mousedown', mouseDown);
                buttonElement.current.removeEventListener('mouseup', mouseUp);
                buttonElement.current.removeEventListener('touchstart', mouseDown);
                buttonElement.current.removeEventListener('touchend', mouseUp);
                buttonElement.current.removeEventListener('touchend', mouseUp);
            }
        };
    }, [buttonElement.current, mouseDown, mouseUp]);

    if (!isEnabled) {
        return false;
    }

    return (
        <Portal>
            <div
                className={containerClassName}
                ref={containerEl}
                style={position}
            >
                <IconButton
                    size="small"
                    classes={{root: 'floating-actions-btn'}}
                    ref={buttonElement}
                    disabled={isGlobalLoading}
                    onClick={onClick}
                >
                    <HiOutlineStar
                        style={{transitionDuration: buttonTransitionDuration}}
                        aria-hidden
                        className="btn-icon"
                    />
                </IconButton>
                <ul className="floating-actions-list">
                    {getActionsWithStyles().map(renderAction)}
                </ul>
            </div>
        </Portal>
    );
}
