import React from 'react';
import {useIntl} from 'react-intl';
import './decline.less';

export default function Decline() {
    const intl = useIntl();

    return (
        <div className="decline-signing">
            <section className="decline-section">
                <div className="title">
                    {intl.formatMessage({id: 'oss.components.DeclineToSign.declineMessageTitle', defaultMessage: ''})}
                </div>
                <div className="title-message">
                    {intl.formatMessage({id: 'oss.components.DeclineToSign.declineMessageDescription', defaultMessage: ''})}
                </div>
            </section>
        </div>
    );
}
