export const MAX_ANSWER_LENGTH = 100;
export const MIN_PASSCODE_LENGTH = 4;
export const MAX_PASSCODE_LENGTH = 10;
export const ONLY_DIGITS_REGEXP = /^\d+$/;
export const SIGNER_GUEST_TYPES = {
    kba: 'kba',
    qna: 'qna',
    saa: 'saa',
    saaTryAgain: 'saaTryAgain',
    session: 'sessionId',
    sms: 'sms'
};
