import isEmpty from 'lodash.isempty';
import flatten from 'lodash.flatten';

import {
    ATTACHMENT_STATUSES,
    AUTH_TYPES,
    DEFAULT_LOCALE,
    defaultButtonValue,
    FIELD_SUBTYPES,
    FIELD_TYPES,
    LOCALES,
    REASSIGN_SCHEME_TYPES,
    REDIRECT_VIEW_KEY,
    STORAGE_KEY_FROM_SENDER,
    STORAGE_KEY_HOST_SIGNER_ID
} from '../../../../constants';
import {hardRedirectTo} from '../../../utils/helpers';
import {
    clearStorageForKey, getValueForKey, isSessionStorageAllowed, setValueForKey, valueFromTempStorage
} from '../../../utils/storageSelectors';
import {saveToTempStorage} from './functions';

export const getHostInPersonRole = ({currentRole, session, transaction}) => {
    // Determine host role by prioritizing the saved host signer id
    const savedSignerId = getValueForKey(STORAGE_KEY_HOST_SIGNER_ID);
    const savedHostInPerson = transaction.roles.find(
        (role) => role.signerId === savedSignerId
    );
    if (savedHostInPerson) return savedHostInPerson;

    // Regular host role calculation
    if (transaction.isNotarized) {
        // There can be only one notary role per transaction
        // Hence, it's clearly the host
        return transaction.roles.find((role) => role.isNotary);
    }
    if (
        (currentRole.isAccountSender && session.allowInPersonForAccountSenders)
      || currentRole.isSender
    ) {
        // The current role can be an account sender or transaction owner
        return currentRole;
    }
    // By default, the host is the transaction owner
    return transaction.roles.find((role) => role.isSender);
};

export const isTransactionInPerson = ({
    currentRole,
    session,
    transaction,
    inSender
}) => {
    const hostRole = getHostInPersonRole({currentRole, session, transaction});

    return (
        (session.isFullSession
        || inSender
        || (hostRole?.id === currentRole.id && session.inPerson))
      && (transaction.isInPerson || transaction.isNotarized || session.inPerson)
    );
};

export const getHostInPerson = ({
    currentRole = {},
    session,
    transaction,
    inSender
}) => {
    if (isEmpty(transaction)) {
        return null;
    }

    const hostRole = getHostInPersonRole({currentRole, session, transaction});
    const isInPersonSigning = isTransactionInPerson({
        currentRole,
        session,
        transaction,
        inSender
    });

    return isInPersonSigning ? hostRole : null;
};

export const exitToSender = (transactionId, isOwner, isDeclined) => {
    let target = `/transaction/${transactionId}`;
    if (!isOwner && isDeclined) {
        target = '/dashboard';
    }
    clearStorageForKey(STORAGE_KEY_FROM_SENDER);
    hardRedirectTo(target);
};

export const getIsDownloadAllowed = (transaction = {}) => {
    const {
        canDownload,
        isCompleted,
        settings: {
            ceremony: {disableDownloadForUncompletedPackage} = {}
        } = {}
    } = transaction;

    return canDownload && (!disableDownloadForUncompletedPackage || isCompleted);
};

export const getRequiredApprovals = (approvals = []) => approvals.filter((approval) => !approval.isOptional);

export const getRequiredFields = (pages, signerApprovals = []) => {
    // Used to only count a group as one action.
    const checkedGroups = new Set();
    const radioGroups = new Set();
    const approvalIds = signerApprovals.map(({id}) => id);
    return pages
        .reduce((fields, page) => {
            const filteredFields = page.signerFields?.filter(
                ({
                    isSignature,
                    isRequired,
                    isRadio,
                    isGroupCheckbox,
                    checkboxGroup,
                    radioGroup
                }) => {
                    if (isGroupCheckbox) {
                        const requiredCheckbox = !checkedGroups.has(checkboxGroup.name)
                            ? checkboxGroup.minimumRequired > 0
                            : false;
                        checkedGroups.add(checkboxGroup.name);
                        return requiredCheckbox;
                    }
                    if (isRadio && isRequired) {
                        const requiredRadio = !radioGroups.has(radioGroup.id);
                        radioGroups.add(radioGroup.id);
                        return requiredRadio;
                    }
                    return !isSignature && isRequired;
                }
            ) || [];
            return [...fields, ...filteredFields];
        }, [])
        .filter((field) => approvalIds.includes(field?.approval?.id));
};

export const getIsAdHocSession = (role, isSenderJoinAdHoc, adHocCobrowsingTransaction) => (role.isSender
    ? isSenderJoinAdHoc && adHocCobrowsingTransaction
    : adHocCobrowsingTransaction);

export const getRemainingActions = (
    {pages, signerApprovals, allSignersApprovals},
    isAllSignersApprovals
) => {
    const requiredApprovalsCount = getRequiredApprovals(
        isAllSignersApprovals ? allSignersApprovals : signerApprovals
    ).length;
    const requiredFieldsCount = getRequiredFields(pages, signerApprovals).length;

    return requiredApprovalsCount + requiredFieldsCount;
};

export const getOptionalApprovals = (approvals) => approvals.filter((approval) => approval.isOptional);

export const getOptionalFields = (pages, signerApprovals) => {
    const approvalIds = signerApprovals.map(({id}) => id);
    return pages
        .reduce((fields, page) => {
            const filteredFields = page.signerFields?.filter(({isSignature, isRequired}) => !isSignature && !isRequired) || [];
            return [...fields, ...filteredFields];
        }, [])
        .filter((field) => approvalIds.includes(field?.approval?.id));
};

export const getAcceptedRequiredApprovals = (approvals) => approvals.filter((approval) => approval.isAccepted && !approval.isOptional);

export const getAcceptedOptionalApprovals = (approvals) => approvals.filter((approval) => approval.isAccepted && approval.isOptional);

export const isValidCheckboxGroupSelections = (checkboxGroup, selections) => checkboxGroup.minimumRequired <= selections.length
    && checkboxGroup.maximumRequired >= selections.length;

export const getFilledFields = ({pages, isRequired = true}) => {
    // Used to only count a group as one action.
    const checkedGroups = new Set();
    const radioGroups = new Set();
    return pages.reduce((fields, page) => {
        const filteredFields = page.signerFields?.filter((field) => {
            const {
                isSignature,
                isRequired: isRequiredField,
                isRadio,
                radioGroup,
                value,
                isCheckbox,
                isSelected,
                isGroupCheckbox,
                checkboxGroup
            } = field;

            if (isGroupCheckbox) {
                const checkboxRequired = !checkedGroups.has(checkboxGroup.name)
                    ? checkboxGroup.minimumRequired > 0
                    && isValidCheckboxGroupSelections(
                        checkboxGroup,
                        checkboxGroup.selectedFieldIds
                    )
                    : false;
                checkedGroups.add(checkboxGroup.name);
                return checkboxRequired;
            }

            if (isRadio && isRequired === isRequiredField) {
                const radioRequired = !radioGroups.has(radioGroup.id)
                    ? radioGroup.isSelected
                    : false;
                radioGroups.add(radioGroup.id);
                return radioRequired;
            }

            return (
                !isSignature && isRequired === isRequiredField && ((isCheckbox && isSelected) || !!value)
            );
        }) || [];
        return [...fields, ...filteredFields];
    }, []);
};

export const getFilledRequiredFields = (pages) => getFilledFields({pages, isRequired: true});

export const getFilledOptionalFields = (pages) => getFilledFields({pages, isRequired: false});

const getDocumentByOperation = (
    documents,
    requestedDocumentId,
    operation,
    fallbackDocumentId
) => {
    // Cancel the flow and return consent document if not confirmed
    const consentDocument = documents.find(
        ({isConsent, isConfirmed, isReview}) => isConsent && !isConfirmed && !isReview
    );
    if (consentDocument != null) {
        return consentDocument.id;
    }

    const requestedDocumentIdx = (documents || []).findIndex(
        ({id}) => id === requestedDocumentId
    );
    const requestedDocument = documents[requestedDocumentIdx];

    // Work based on operation
    switch (operation) {
        case 'next':
        // Make sure requested document is confirmed and return next document ID
            if (requestedDocument?.isLast === false) {
                return documents[requestedDocumentIdx + 1].id;
            }
            // Falling back
            return fallbackDocumentId;
        case 'noop':
        default:
        // Default behaviour
            break;
    }

    // Return requested document id or fallback if not available
    return requestedDocument?.id ?? fallbackDocumentId;
};

export const haveAllRolesSigned = (roles = []) => roles.every(({hasSigned}) => hasSigned);
export const isTransactionCompleted = (transaction = {}) => transaction.isCompleted || haveAllRolesSigned(transaction.roles);
export const getIsSigningCompleted = (documents = []) => documents.every(({isConfirmed, isReview} = {}) => isConfirmed || isReview);

export const getInitialDocumentId = (
    transaction,
    potentialInitialDocumentId,
    operation
) => {
    const isCompleted = isTransactionCompleted(transaction);

    // When the transaction is completed, we always show the first document.
    if (isCompleted) {
        return transaction.documents[0].id;
    }

    if (potentialInitialDocumentId == null) {
        return transaction.initialDocumentId;
    }
    return getDocumentByOperation(
        transaction.documents,
        potentialInitialDocumentId,
        operation ?? 'noop',
        transaction.initialDocumentId
    );
};

export const shouldShowSummaryPage = (
    isInPersonCeremony,
    documents,
    {isReviewer}
) => {
    if (isInPersonCeremony) return false;
    const {isReview: isLastDocumentReview} = documents[documents.length - 1];
    return !isReviewer && getIsSigningCompleted(documents) && !isLastDocumentReview;
};

export const getHasPendingRequiredUpload = ({attachments = []}) => attachments?.some(
    (attachment) => (attachment.status === ATTACHMENT_STATUSES.INCOMPLETE || attachment.status === ATTACHMENT_STATUSES.REJECTED)
        && attachment.required
);

export const firstPendingUpload = (attachments) => attachments?.find(
    (attachment) => attachment.status === ATTACHMENT_STATUSES.INCOMPLETE || attachment.status === ATTACHMENT_STATUSES.REJECTED
);

const pendingAttachments = (attachments) => attachments.filter(
    ({status}) => status === ATTACHMENT_STATUSES.INCOMPLETE || status === ATTACHMENT_STATUSES.REJECTED
);
export const nextPendingUpload = (attachments, currentAttachmentId) => {
    if (!currentAttachmentId) {
        return firstPendingUpload(attachments);
    }
    const pendingIncompleteOrRejectedAttachments = pendingAttachments(attachments);
    const index = pendingIncompleteOrRejectedAttachments.findIndex(
        ({id}) => id === currentAttachmentId
    ) + 1;
    const nextIndex = index >= pendingIncompleteOrRejectedAttachments.length ? 0 : index;

    return pendingIncompleteOrRejectedAttachments[nextIndex];
};

export const isPendingUploads = (attachments, attachmentId) => {
    const pendingIncompleteOrRejectedAttachments = pendingAttachments(attachments);

    return pendingIncompleteOrRejectedAttachments.length === 1
        ? pendingIncompleteOrRejectedAttachments.some(({id}) => id !== attachmentId)
        : pendingIncompleteOrRejectedAttachments.length !== 0;
};

export const getIsSigningCompleteByAll = (documents) => documents.every(
    ({isConfirmedByAll, isReviewByAll}) => isConfirmedByAll || isReviewByAll
);

export const getIsLastDocumentForSigning = (documents) => documents.filter(({isConfirmed, isReview}) => !isConfirmed && !isReview)
    .length === 1;

export const getIsSigningCompleteForAll = (documents) => documents.every(({isConfirmed, approvals, isReview}) => (
    isReview
      || (isConfirmed
        && getRequiredApprovals(approvals).every(({isSigned}) => isSigned))
));

export const getIsNotYourTurn = (role = {}) => !role.canSign && !role.isReviewer;

function getPageViewWeight(upperBound, lowerBound, pagesMarginBottom) {
    return (page) => {
        const boundedSpace = lowerBound - upperBound;
        const slicedUpperBound = Math.max(upperBound, page.offset);
        const pageLowerBound = page.offset + page.height * page.scale + pagesMarginBottom;
        const slicedLowerBound = Math.min(lowerBound, pageLowerBound);
        const occupiedBoundedSpace = slicedUpperBound < slicedLowerBound
            ? slicedLowerBound - slicedUpperBound
            : 0;

        return [page, occupiedBoundedSpace / boundedSpace];
    };
}

function pageWeightSort(weight1, weight2) {
    return weight2[1] - weight1[1];
}

export function getPageFromOffset(pagesMarginBottom, viewMidpointPadding) {
    return (pages, availableHeight, zoomFactor) => (scrollTop) => {
        const baseScrollTop = scrollTop / zoomFactor;
        const middlePoint = availableHeight / 2;
        const upperBound = baseScrollTop + (middlePoint - availableHeight * viewMidpointPadding);
        const lowerBound = baseScrollTop + (middlePoint + availableHeight * viewMidpointPadding);

        // If the last item is in view return it
        const lastItem = pages[pages.length - 1];

        if (lastItem.offset < baseScrollTop + availableHeight) {
            return lastItem;
        }

        return pages
            .map(getPageViewWeight(upperBound, lowerBound, pagesMarginBottom))
            .slice(0)
            .sort(pageWeightSort)[0][0];
    };
}
export const getDocumentById = (documents = [], docId) => documents.find(({id}) => id === docId) || {};

export const getIsTurnToSignByAll = (documents, id) => {
    const {index, isConfirmedByAll} = getDocumentById(documents, id);
    if (isConfirmedByAll) return false;
    return !documents.some(
        (doc) => doc.index < index && !doc.isConfirmedByAll && !doc.isReviewByAll
    );
};
export const getIsTurnToSign = (documents, id) => {
    const {index, isConfirmed} = getDocumentById(documents, id);
    if (isConfirmed) return false;
    return !documents.some(
        (doc) => doc.index < index && !doc.isConfirmed && !doc.isReview
    );
};
export const getAttachmentById = (attachments = [], attachmentId) => attachments.find(({id}) => id === attachmentId) || {};

export const isAnyAttachmentNotUploaded = (attachments) => !attachments?.every((attachment) => attachment.status === 'COMPLETE');

export const isEveryRequiredAttachmentUploaded = (attachments) => attachments
    ?.filter((attachment) => attachment.required)
    .every((attachment) => attachment.status === 'COMPLETE');

export const firstPendingDocumentId = (documents) => documents?.find((document) => !document.isConfirmed && !document.isReview)?.id;

export const firstPendingDocumentIdByAll = (documents) => documents?.find(
    (document) => !document.isConfirmedByAll && !document.isReviewByAll
)?.id;

export const hasUnAcceptedApprovals = (documents) => documents.some(
    ({signerApprovals, isConfirmed}) => !isConfirmed && signerApprovals.some(({isAccepted}) => !isAccepted)
);

export const getSchemeFromAuthType = (authType) => REASSIGN_SCHEME_TYPES[authType?.toUpperCase()];

export const getFieldOrGroupId = (field) => {
    let fieldId;
    if (field.isRadio) {
        fieldId = field.radioGroup.id;
    } else if (field.isGroupCheckbox) {
        fieldId = field.checkboxGroup.id;
    } else {
        fieldId = field.id;
    }
    return fieldId;
};

export const getRequiredActionsByPages = ({pages = []}) => {
    const fields = pages.reduce((reducedFields, {signerFields}) => {
        reducedFields.push(
            signerFields.filter((field = {}) => (field.type === FIELD_TYPES.SIGNATURE
                ? !field.approval?.isOptional
                : field.isRequired))
        );
        return reducedFields;
    }, []);
    return flatten(fields);
};

export const hasOptionalApprovals = (approvals) => approvals.length > 0 && approvals.some((approval) => approval.isOptional);

export const hasOptionalNotAcceptedApprovals = (approvals) => approvals.length > 0
  && approvals.some((approval) => approval.isOptional && !approval.isAccepted);

export const hasOnlyOptionalApprovals = (approvals) => approvals.length > 0 && approvals.every((approval) => approval.isOptional);

export const hasDisabledApprovals = (approvals) => approvals.length > 0 && approvals.some((approval) => approval.isDisabled);

export const hasAtLeastOneAcceptedApproval = (approvals) => approvals.length > 0 && approvals.some((approval) => approval.isAccepted);
export const getFieldsByApprovalId = ({fields, approvalId}) => fields.filter((field) => field.approval.originalId === approvalId);

export const getFieldsByPages = ({pages = []} = {}) => {
    const fields = pages.reduce((reducedFields, {signerFields}) => {
        reducedFields.push(signerFields.filter((flds) => !flds.isSignature));
        return reducedFields;
    }, []);

    return flatten(fields);
};

export const getApprovalRelatedErrors = (({approvalId, fieldsErrors, fields}) => {
    const approvalRelatedFields = getFieldsByApprovalId({
        fields,
        approvalId
    });

    return approvalRelatedFields.reduce((acc, field) => {
        const fieldId = getFieldOrGroupId(field);
        const containsField = acc.some((f) => {
            const fId = getFieldOrGroupId(f);
            return fId === fieldId;
        });
        const error = fieldsErrors[fieldId];

        if (!containsField && error) {
            acc.push(error);
        }

        return acc;
    }, []);
});

export const isFieldDisabled = (field, fields = []) => {
    if (field.isSignature) {
        const fieldRelatedRequiredFields = fields.filter((fld) => fld.isRequired && field.approval.originalId === fld.approval.originalId);
        const isAllRequiredFieldsFilled = fieldRelatedRequiredFields.every((fld) => {
            if (fld.subtype === FIELD_SUBTYPES.RADIO) {
                return !!fld.radioGroup.selectedFieldId;
            }
            if (fld.subtype === FIELD_SUBTYPES.CHECKBOX && typeof fld.value === 'string') {
                return fld.value?.toLowerCase() === defaultButtonValue;
            }
            return !!fld.value;
        });

        return field.approval.isDisabled || !isAllRequiredFieldsFilled;
    }
    return field.isInput && field.isDisabled;
};

export const getDocumentStatus = ({
    pages = [],
    signerApprovals = []
}) => {
    const requiredApprovalsCount = getRequiredApprovals(signerApprovals).length;
    const requiredFieldsCount = getRequiredFields(pages, signerApprovals).length;
    const requiredActionsTotalCount = requiredApprovalsCount + requiredFieldsCount;
    const requiredCompletedActionsCount = getAcceptedRequiredApprovals(signerApprovals).length
            + getFilledRequiredFields(pages).length;
    const optionalApprovalsCount = getOptionalApprovals(signerApprovals).length;
    const optionalFieldsCount = getOptionalFields(pages, signerApprovals).filter(({isLabel}) => !isLabel).length;
    const optionalActionsTotalCount = optionalApprovalsCount + optionalFieldsCount;
    const optionalCompletedApprovalsCount = getAcceptedOptionalApprovals(signerApprovals).length;
    const optionalCompletedActionsCount = optionalCompletedApprovalsCount
            + getFilledOptionalFields(pages).length;
    const allActionsTotalCount = requiredActionsTotalCount + optionalActionsTotalCount;
    const allCompletedActionsCount = requiredCompletedActionsCount + optionalCompletedActionsCount;

    return {
        requiredActionsTotalCount,
        requiredCompletedActionsCount,
        optionalActionsTotalCount,
        optionalApprovalsCount,
        optionalCompletedApprovalsCount,
        optionalCompletedActionsCount,
        allActionsTotalCount,
        allCompletedActionsCount
    };
};

export const isInputFieldEnabled = (field) => (field.isInput && !field.isDisabled)
    || (field.isSignature && !field.approval.isDisabled);

export const isInputFieldRequired = (field) => (field.isSignature && !field.approval.isOptional) || field.isRequired;
export const isActionContainValue = (field) => {
    const {
        approval, isSelected, isCheckbox, isRadio, radioGroup, type, value
    } = field;

    if (type === FIELD_TYPES.SIGNATURE) {
        return approval?.isAccepted;
    }
    if (isCheckbox) {
        return isSelected;
    }
    if (type !== FIELD_TYPES.SIGNATURE && isRadio) {
        return radioGroup?.isSelected;
    }
    return !!value;
};

export const isEnabledOrRequiredInputField = (field) => !!field
    && (isInputFieldEnabled(field) || isInputFieldRequired(field))
    && !field.isLabel
    && !field.isCustomField;

export const isEveryEnabledOrRequiredInputFieldFilled = ({pages}) => !pages?.some(({signerFields}) => signerFields.find(
    (field) => isEnabledOrRequiredInputField(field) && !isActionContainValue(field)
));
export const isSigningComplete = (documents = []) => documents.every(({isConfirmed, isReview}) => isConfirmed || isReview);

export const allowDownload = (transaction) => {
    if (isEmpty(transaction)) return;
    const {
        canDownload,
        isCompleted,
        settings: {
            ceremony: {disableDownloadForUncompletedPackage}
        }
    } = transaction;

    return canDownload && (!disableDownloadForUncompletedPackage || isCompleted);
};

export const isRecipientSmsAuth = (recipient) => recipient?.auth?.scheme === AUTH_TYPES.SMS;
export const isSignerNeedToAcceptOnlyDocument = (docApprovals = []) => docApprovals.length === 1 && isEmpty(docApprovals[0].fields);

export const validateAndGetAvailableLocale = (locale = DEFAULT_LOCALE) => {
    if (locale && LOCALES.includes(locale)) {
        return locale;
    }

    return DEFAULT_LOCALE;
};

export const shouldDisplayRedirectView = () => !!(isSessionStorageAllowed()
    ? getValueForKey(REDIRECT_VIEW_KEY)
    : valueFromTempStorage(REDIRECT_VIEW_KEY));

export const setAutoredirectIfAllowed = () => {
    if (isSessionStorageAllowed()) {
        setValueForKey(REDIRECT_VIEW_KEY, true);
    } else {
        saveToTempStorage({
            key: REDIRECT_VIEW_KEY,
            value: true
        });
    }
};
