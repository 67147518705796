import {
    useEffect, useRef, useState
} from 'react';
import {isMobile} from 'react-device-detect';
import {
    DEFAULT_ZOOM_FACTOR,
    ZOOM_ON_CLICK_STEP
} from '../constants';

import {
    getScaleInZoomRange,
    roundScaleToNearestZoomStep
} from '../utils';
import {useScrollContext} from '../ScrollContext';

const DEFAULT_SCALE = 1;

function getDistanceBetweenFingers(event) {
    return Math.hypot(
        event.touches[0].pageX - event.touches[1].pageX,
        event.touches[0].pageY - event.touches[1].pageY
    );
}

const initialTouchStart = {
    x: 0,
    y: 0,
    distance: 0,
    initialZoomFactor: DEFAULT_SCALE
};

export default function usePinchAndZoom({
    ref, zoomFactor, onZoomChange
}) {
    const el = ref?.current;
    const {
        scrollBar,
        topOffset,
        leftOffset,
    } = useScrollContext();
    const [scale, setScale] = useState(DEFAULT_ZOOM_FACTOR);
    const [start, setStart] = useState({
        ...initialTouchStart,
        initialZoomFactor: zoomFactor
    });

    function onTouchStart(event) {
        if (event.touches.length === 2) {
            event.preventDefault(); // Prevent page scroll

            // Calculate where the fingers have started on the X and Y axis
            const x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
            const y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
            const distance = getDistanceBetweenFingers(event);

            setStart({
                x,
                y,
                distance,
                initialZoomFactor: zoomFactor
            });
        } else {
            setStart({
                ...initialTouchStart,
                initialZoomFactor: zoomFactor
            });
            setScale(zoomFactor);
        }
    }

    function onTouchMove(event) {
        if (event.touches.length === 2) {
            event.preventDefault(); // Prevent page scroll

            let currentScale;

            if (event.scale) {
                currentScale = event.scale;
            } else {
                const deltaDistance = getDistanceBetweenFingers(event);
                currentScale = deltaDistance / start.distance;
            }

            const updatedCurrentScale = start.initialZoomFactor * currentScale;
            const scaleInZoomRange = getScaleInZoomRange(updatedCurrentScale);

            onZoomChange(scaleInZoomRange);
            setScale(scaleInZoomRange);
        }
    }

    function onTouchEnd(event) {
        // to round zoom to the nearest step only if using zooming with multiple fingers
        if (event.changedTouches.length <= 1) return;
        const roundedScale = roundScaleToNearestZoomStep(scale);
        const roundedZoomFactor = roundScaleToNearestZoomStep(zoomFactor);
        if (roundedScale !== roundedZoomFactor) {
            onZoomChange(roundedScale);
        }
    }



    useEffect(() => {
        if (el) {
            el.addEventListener('touchstart', onTouchStart, {passive: true});
            el.addEventListener('touchmove', onTouchMove, {passive: true});
            el.addEventListener('touchend', onTouchEnd);
        }
        return () => {
            if (el) {
                el.removeEventListener('touchstart', onTouchStart);
                el.removeEventListener('touchmove', onTouchMove);
                el.removeEventListener('touchend', onTouchEnd);
            }
        };
    }, [
        el,
        start,
        scale,
        zoomFactor,
        topOffset,
        leftOffset,
        scrollBar
    ]);
}
