/* eslint-disable import/prefer-default-export */
export const NOTIFICATION_GROUPS = {
    default: 'default',
    error: 'error',
    formValidation: 'form-validation'
};

// FONT_SIZES in px
export const FONT_SIZES = [
    72, 48, 36, 28, 26, 24, 22, 20, 18, 16, 14, 12, 11, 10, 9, 8
];
// LINE_HEIGHT in px
export const LINE_HEIGHT = 1.5;

// INPUT_PADDING in px
export const INPUT_PADDING = 12;

export const SIGNING_TRANSACTION_STATUS = {
    DONE: 'DONE',
    INPROGRESS: 'INPROGRESS',
    NOTSTARTED: 'NOTSTARTED',
    REVIEWER: 'REVIEWER',
    NONE: 'NONE'
};
