import {useMutation} from '@apollo/client';
import {SEND_AUDIT_EVENT} from '../../../../../gql/mutations';

export const useAuditEvent = (options) => {
    const [sendAuditEvent, {
        loading, error, data, reset
    }] = useMutation(SEND_AUDIT_EVENT, options);

    return {
        sendAuditEvent, loading, error, data, reset
    };
};
