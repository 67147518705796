import {createRef, useCallback} from 'react';
import {Page} from 'react-pdf';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';
import {isMobile} from 'react-device-detect';
import {useInView} from 'react-intersection-observer';
import {
    FOOTER_HEIGHT_PX,
    FOOTER_MARGIN_BOTTOM_PX
} from '../../constants';
import Loading from '../../../Loading/Loading';
import Interactable from '../../Interactable/Interactable';
import PageFooter from './PageFooter/PageFooter';
import './pageRenderer.less';

export default function PageRenderer({
    pageWithDimensions = {},
    documentName,
    totalPages,
    isRendered: shouldRender,
    isActive,
    isLastPage,
    isFooterVisible,
    topOffset,
    leftOffset,
    scrollDimensions,
    pageProps: {
        containerProps,
        interactableProps,
        renderChild = () => {},
        renderTextLayer = false,
        renderAnnotationLayer = true,
        setDataAttributes = () => {},
        shouldAddBottomMargin = true,
        ...pagePropsRest
    } = {},
    pageFooterProps
}) {
    const {
        index,
        widthScaledToContainer,
        heightScaledToContainer,
        widthScaledToContainerAndZoomFactor,
        heightScaledToContainerAndZoomFactor,
        zoomFactor
    } = pageWithDimensions;
    const [ref, inView] = useInView();
    const interactableRef = createRef();
    const isRendered = shouldRender || inView;
    const pageHeight = isFooterVisible
        ? heightScaledToContainerAndZoomFactor + FOOTER_HEIGHT_PX
        : heightScaledToContainerAndZoomFactor;
    const pageMarginBottom = isLastPage || !shouldAddBottomMargin ? 0 : FOOTER_MARGIN_BOTTOM_PX;
    const className = classnames('page-renderer', {
        'is-rendered': isRendered,
        'is-active': isActive
    });
    const renderedChild = useCallback(renderChild({
        pageWithDimensions,
        isRendered,
        ref,
        interactableRef
    }), [
        pageWithDimensions,
        isRendered,
        ref,
        interactableRef
    ]);

    function getScale() {
        /**
         * Mobile devices have a fixed zoom factor of 1 to prevent rerendering which will
         * disable the pinch to zoom functionality.
         */
        if (isMobile) {
            return 1;
        }
        return zoomFactor;
    }

    const RenderedPage = (
        <div
            ref={ref}
            className={className}
            style={{
                height: pageHeight,
                width: widthScaledToContainerAndZoomFactor,
                marginBottom: pageMarginBottom
            }}
            {...setDataAttributes(pageWithDimensions)}
            {...containerProps}
        >
            {isRendered ? (
                <div
                    className="page-renderer-wrapper"
                    style={isMobile ? {
                        width: widthScaledToContainer,
                        height: heightScaledToContainer,
                        transform: `scale(${zoomFactor})`
                    } : {}}
                >
                    <Page
                        pageIndex={index}
                        scale={getScale()}
                        width={widthScaledToContainer}
                        height={heightScaledToContainer}
                        renderTextLayer={renderTextLayer}
                        renderAnnotationLayer={renderAnnotationLayer}
                        loading={(
                            <Loading
                                isLoading
                                isSceleton
                                style={{
                                    width: widthScaledToContainer,
                                    height: heightScaledToContainer
                                }} 
                            />
                        )}
                        {...pagePropsRest}
                    />
                </div>
            ) : (
                <Loading
                    isLoading
                    isSceleton
                />
            )}
            {renderedChild}
            {isFooterVisible && (
                <PageFooter
                    pageWithDimensions={pageWithDimensions}
                    documentName={documentName}
                    totalPages={totalPages}
                    topOffset={topOffset}
                    leftOffset={leftOffset}
                    scrollDimensions={scrollDimensions}
                    isRendered={isRendered}
                    pageFooterProps={pageFooterProps}
                />
            )}
        </div>
    );

    if (isEmpty(interactableProps)) return RenderedPage;

    return (
        <Interactable elRef={interactableRef} {...interactableProps}>
            {RenderedPage}
        </Interactable>
    );
}
