import React from 'react';
import {useSelector} from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import classNames from 'classnames';
import NextButton from '../NextButton/NextButton';
import InPersonSessionInformation from '../../InPerson/InPersonSessionInfo/InPersonSessionInfo';
import {selectIsInterfaceHidden} from '../../signerUiSlice';
import './bottomBar.less';

const BottomBar = ({
    transaction,
    documentLoading,
    pages,
    isLeftMenuOpen,
    fieldsRef,
    isDocumentLoading
}) => {
    const isInterfaceHidden = useSelector(selectIsInterfaceHidden);
    const containerClassName = classNames('bottom-bar', {
        'is-left-menu-open': isLeftMenuOpen
    });
    const nextButtonProps = {
        transaction,
        documentLoading,
        pages,
        isLeftMenuOpen,
        fieldsRef,
        disabled: isDocumentLoading
    };

    // TODO: isNoInterface prop usage in conditions should be reviewed because it always renders components
    return (
        <>
            <Collapse
                in={!isInterfaceHidden}
                timeout="auto"
                classes={{root: 'bottom-bar-collapsed-container'}}
            >
                <div className={containerClassName}>
                    <InPersonSessionInformation />
                    <NextButton {...nextButtonProps} />
                </div>
            </Collapse>
            <NextButton {...nextButtonProps} isNoInterface />
        </>
    );
};

export default BottomBar;
