import {gql} from '@apollo/client';

export default gql`query getDocumentsWithPages($transactionId: ID!) {
  ceremony(transactionId: $transactionId) {
    session {
      showConsentBar
      signerExpiryRedirect
      features {
        sendToMobile
      }
    }
    transaction {
      canSignByAny
      cachedSignature
      id
      isAda
      isCompleted
      canDisplayOverview
      initialDocumentId
      documents {
        id
        index
        name
        isReview
        isOngoing
        isDisclosure
        isDisclosureByAll
        isConfirmed
        isConsent
        isReadyToConfirm
        isLast
        flattenedPdfUrl
        originalPdfUrl
        hasOnlyOptionalApprovals
        hasOnlyOptionalApprovalsAndFields
        hasOptionalNotAcceptedApprovals
        hasDisabledApprovals
        hasAtLeastOneAcceptedApproval
        hasCurrentSignerApprovalsAsGroup
        isConfirmedByAll
        isReadyToConfirmByAll
        isReviewByAll
        currentSignerApprovals {
          id
          originalId
          isAccepted
          isOptional
          isSignatureCaptureEnforced
          isDisabled
          fields {
            id
            isSignature
            value
          }
        }
        signerApprovals {
          id
          isAccepted
          isOptional
          isDisabled
          fields {
            id
            isSignature
            value
            isDisabled
          }
        }
        allSignersApprovals {
          id
          isAccepted
          isOptional
          isDisabled
          fields {
            id
            isSignature
            value
          }
        }
        pages {
          top
          left
          height
          width
          index
          imageUrl
          signerFields {
            id
            originalId
            height
            width
            page
            top
            left
            number
            isTextField
            isTextArea
            isDateField
            isCheckbox
            isLabel
            isCustomField
            isRadio
            isList
            isSignature
            isClickToSign
            isCaptureSig
            isMobileSig
            isClickToInitial
            isAutofill
            isAutofillDate
            isAutofillCompany
            isAutofillTitle
            isAutofillName
            isSelected
            isInput
            isMasked
            hasConditions
            value
            type
            subtype
            binding
            acceptedDate
            name
            isRequired
            isDisabled
            maxLength
            minLength
            pattern
            patternMessage
            patternErrorCode
            options
            fontSize
            lineHeight
            isConditionalTarget
            isGroupCheckbox
            radioGroup {
              id
              name
              isRequired
              isSelected
              selectedFieldId
            }
            checkboxGroup {
              id
              name
              minimumRequired
              maximumRequired
              size
              selectedFieldIds
            }
            format
            options
            approval {
              id
              originalId
              isAccepted
              isOptional
              isSignatureCaptureEnforced
              isDisabled
              isFromFile
              fromFileData {
                value
                type
              }
              role {
                id
                initials
                company
                title
                fullName
                isDelegate
                signerId
              }
            }
            customFieldNames {
              language
              name
            }
          }
        }
      }
      settings {
        ceremony {
          layout {
            showDocumentName
            showProgressBar
            showNavigator
          }
          optionalNavigation
        }
      }
    }
    role {
      id
      email
      fullName
      isSender
      isReviewer
      canSign
    }
  }
}`;
