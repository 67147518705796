import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Launch} from '@material-ui/icons';
import {useCeremony} from '../../hooks/queries';
import {getCustomHandOverUrl} from '../../utils/functions';
import {AppButton} from '../../../Core/buttons/AppButton/AppButton';
import {redirectTo} from '../../../../utils/helpers';
import {SectionWithTitle} from '../../../Core/company/layouts/SectionWithTitle/SectionWithTitle';
import {clearStorageForKey} from '../../../../utils/storageSelectors';
import {REDIRECT_VIEW_KEY} from '../../../../../constants';
import './HandOverRedirect.less';

export default function HandOverRedirect() {
    const {transaction, role} = useCeremony();
    const {handOverUrl, handOver} = transaction?.settings?.ceremony || {};
    const intl = useIntl();

    const DEFAULT_REDIRECT_DELAY_IN_MS = 3000;

    /**
     * Redirects user to the handover url with the correct parameters.
     * @param {*} delay delay in milliseconds
     */
    const redirectToHandoverUrl = (delay) => {
        const urlWithParams = getCustomHandOverUrl({
            handOverUrl,
            status: 'SIGNING_COMPLETE',
            transactionId: transaction.id,
            signerId: role.signerId,
            parameters: handOver?.parameters
        });

        setTimeout(() => {
            clearStorageForKey(REDIRECT_VIEW_KEY);
            redirectTo(urlWithParams);
        }, delay);
    };

    useEffect(() => {
        redirectToHandoverUrl(DEFAULT_REDIRECT_DELAY_IN_MS);
    }, []);

    return (
        <div className="handover-redirect">
            <Launch className="launch-icon" />
            <h2>{intl.formatMessage({id: 'oss.components.FeedbackBar.signingComplete', defaultMessage: ''})}</h2>
            <SectionWithTitle
                title={intl.formatMessage({id: 'oss.components.SummaryView.redirectTitle', defaultMessage: ''})}
            >
                <p>
                    {intl.formatMessage({id: 'oss.components.SummaryView.redirectBody', defaultMessage: ''})}
                </p>
            </SectionWithTitle>
            <AppButton onClick={() => redirectToHandoverUrl(0)}>
                {intl.formatMessage({id: 'oss.components.TopBar.exit', defaultMessage: ''})}
            </AppButton>
        </div>
    );
}
