import classnames from 'classnames';
import './sectionWithTitle.less';

export const SectionWithTitle = ({
    title, icon, children, className = ''
}) => {
    const containerClassName = classnames('section-with-title', className);
    return (
        <section className={containerClassName}>
            {(title || icon) && (
                <div className="section-title-container">
                    {icon}
                    <h2 className="section-title">{title}</h2>
                </div>
            )}
            {children && (
                <div className="section-content-container">
                    {children}
                </div>
            )}
        </section>
    );
};
