import {SIGNER_UI_PAGES} from '../../../../constants';
import {isTransactionCompleted, shouldShowSummaryPage} from './selectors';

export const getInPersonPageUIState = () => ({
    xAccessibility: false,
    xLanguageOption: false,
    xLogo: false,
    isMoreActionsVisible: true,
    signerUiPage: SIGNER_UI_PAGES.IN_PERSON,
    isLeftMenuVisible: false,
    xZoom: false
});

// This function return needed redux states of Signer UI components state when opening summary page..
// TODO: This and simmiliar functions which responsible for app UI state should be renamed to be more descriptive like.
// And it should be in redux store

export const getSummaryPageUIState = ({isMoreActionsVisible = true} = {}) => ({
    isFeedbackCollapsed: false,
    xAccessibility: false,
    xLanguageOption: true,
    xLogo: true,
    isMoreActionsVisible,
    signerUiPage: SIGNER_UI_PAGES.SUMMARY,
    isLeftMenuVisible: false,
    xZoom: false
});

export const getThankYouPageUIState = () => ({
    isFeedbackCollapsed: false,
    xAccessibility: false,
    xLanguageOption: true,
    xLogo: false,
    isMoreActionsVisible: true,
    signerUiPage: SIGNER_UI_PAGES.THANK_YOU,
    isLeftMenuVisible: false,
    xZoom: false
});

export const getThankYouHostPageUIState = () => ({
    isFeedbackCollapsed: false,
    isInPersonDone: true,
    xAccessibility: false,
    xLanguageOption: true,
    xLogo: true,
    isMoreActionsVisible: true,
    signerUiPage: SIGNER_UI_PAGES.THANK_YOU_HOST,
    isLeftMenuVisible: true,
    xZoom: false
});

export const viewStatusForInDecline = {
    xAccessibility: false,
    xLanguageOption: true,
    xLogo: true,
    isMoreActionsVisible: true,
    signerUiPage: SIGNER_UI_PAGES.DECLINE,
    isLeftMenuVisible: false,
    xZoom: false
};

export const viewStatusForInPersonDecline = {
    xAccessibility: false,
    xLanguageOption: true,
    xLogo: true,
    isMoreActionsVisible: true,
    signerUiPage: SIGNER_UI_PAGES.IN_PERSON_DECLINE,
    isLeftMenuVisible: false,
    xZoom: false
};

const applyVirtualRoomView = (res, isVirtualRoomView, isVirtualRoomHost) => {
    const result = {...res};
    result.selectedSideMenu = '';
    if (isVirtualRoomView) {
        result.xZoom = false;
        result.isMoreActionsVisible = false;
        if (!isVirtualRoomHost) {
            result.xDisabledTopBar = true;
            result.isLeftMenuVisible = false;
        }
    } else {
        result.isLeftMenuVisible = false;
        result.isSideMenuOpen = false;
        result.xZoom = true;
        result.isMoreActionsVisible = true;
        result.xLanguageOption = true;
        result.xAccessibility = false;
    }

    return result;
};

const viewStatusForDocumentView = ({
    isSenderJoinAdHoc,
    isSignerFromSenderUI,
    isInPersonCeremony,
    isVirtualRoomSigning,
    isVirtualRoomView,
    isVirtualRoomHost,
    page,
    sideBar,
    previewApplication,
    role,
    zoom
}) => {
    const isOverviewPage = page === SIGNER_UI_PAGES.OVERVIEW_PAGE;
    const isVROverviewPage = page === SIGNER_UI_PAGES.VR_OVERVIEW_PAGE;
    const isHtmlView = page === SIGNER_UI_PAGES.HTML;

    const result = {
        signerEmail: role.email,
        xAccessibility: !isOverviewPage,
        isExitSigningVisible: isInPersonCeremony && !isSignerFromSenderUI,
        xLanguageOption: false,
        xLogo: false,
        isMoreActionsVisible: true,
        signerUiPage: page,
        isLeftMenuVisible:
        !isOverviewPage
        && !isVROverviewPage
        && !isHtmlView
        && !isSenderJoinAdHoc
        && (role.isReviewer || sideBar || !!previewApplication),
        xZoom: zoom
    };

    if (isVirtualRoomSigning) {
        return applyVirtualRoomView(result, isVirtualRoomView, isVirtualRoomHost);
    }

    return result;
};

export const isHTMLViewer = (viewer) => viewer === 'html';

export const getAttachmentView = (attachment, isSideBarEnabled = true) => ({
    selectedAttachment: attachment,
    lastSelectedAttachmentTimestamp: Date.now(),
    signerUiPage: SIGNER_UI_PAGES.ATTACHMENT,
    xZoom: false,
    isLeftMenuVisible: isSideBarEnabled
});

export const currentPage = ({
    inPersonCeremonyHost,
    isConsentNullOrAccepted,
    previewApplication,
    transaction,
    role,
    viewer,
    inSender,
    isSenderJoinAdHoc,
    isVirtualRoomView,
    isVirtualRoomHost,
    isVirtualRoomSigning,
    initialAttachmentId
}) => {
    const {
        canDecline,
        isDeclined,
        documents,
        isInPerson,
        isNotarized,
        canDisplayOverview,
        virtualRoomSessionStatus
    } = transaction;
    if (isDeclined) {
        return viewStatusForInDecline;
    }

    const isInPersonCeremony = !!inPersonCeremonyHost;
    const isCurrentRoleHost = role?.email === inPersonCeremonyHost?.email;
    let isSignerFromSenderUI;

    // Anything related to inPerson goes here
    if (isInPersonCeremony || transaction?.isInPerson) {
        const isTransactionCompletedAsHost = isCurrentRoleHost && isTransactionCompleted(transaction);
        const isTransactionCompletedAsSigner = role.hasSigned && !isCurrentRoleHost;

        if (isTransactionCompletedAsSigner) {
            return getThankYouPageUIState();
        }

        if (isTransactionCompletedAsHost) {
            return getThankYouHostPageUIState();
        }

        if (isCurrentRoleHost) {
            return getInPersonPageUIState();
        }
    } if (
        (!isVirtualRoomSigning || virtualRoomSessionStatus === 'COMPLETED')
      && shouldShowSummaryPage(isInPersonCeremony, documents, role)
      && !initialAttachmentId
    ) {
        return getSummaryPageUIState({moreActions: !isVirtualRoomView});
    }

    if (initialAttachmentId) {
        const initialAttachment = role.attachmentRequirements.find(
            ({id}) => id === initialAttachmentId
        );
        const isSideBarEnabled = isVirtualRoomSigning ? isVirtualRoomHost : true;
        if (initialAttachment) {
            return getAttachmentView(initialAttachment, isSideBarEnabled);
        }
        if (isVirtualRoomSigning) {
            const initialAttachmentForOtherRole = transaction.roles
                .flatMap(({attachmentRequirements}) => attachmentRequirements)
                .find(({id}) => id === initialAttachmentId);

            if (initialAttachmentForOtherRole) {
                return getAttachmentView(
                    initialAttachmentForOtherRole,
                    isSideBarEnabled
                );
            }
        }
    }

    const inPersonAndSender = (isInPerson || isNotarized) && inSender;
    const shouldDisplayOverview = canDisplayOverview && !isInPersonCeremony;

    const view = previewApplication || inPersonAndSender || !shouldDisplayOverview ? SIGNER_UI_PAGES.PDF : SIGNER_UI_PAGES.OVERVIEW_PAGE;

    let page = isHTMLViewer(viewer) ? SIGNER_UI_PAGES.HTML : view;

    if (isVirtualRoomSigning && !isVirtualRoomView) {
        page = SIGNER_UI_PAGES.VIRTUAL_ROOM;
    }

    return viewStatusForDocumentView({
        canDecline,
        isInPersonCeremony,
        isSenderJoinAdHoc,
        isSignerFromSenderUI,
        isVirtualRoomHost,
        isVirtualRoomSigning,
        isVirtualRoomView,
        page,
        previewApplication,
        role,
        sideBar: isConsentNullOrAccepted,
        zoom: !canDisplayOverview || isInPersonCeremony
    });
};
