import loadable from '@loadable/component';
import Login from '../components/Login/Login';
import FullErrorPage from '../components/FullErrorPage/FullErrorPage';
import loginSsrFetch from '../components/Login/ssrFetch';
import dashboardSsrFetch from '../components/Dashboard/ssrFetch';
import transactionsSsrFetch from '../components/Transactions/ssrFetch';
import transactionSsrFetch from '../components/Transaction/ssrFetch';
import transactionDesignerSsrFetch from '../components/Designer/transactionSsrFetch';
import templateDesignerSsrFetch from '../components/Designer/templateSsrFetch';
import templatesSsrFetch from '../components/Templates/ssrFetch';
import templateDetailsSsrFetch from '../components/Template/ssrFetch';
import {
    LOGIN_PATH,
    FORCE_LOGIN_PATH,
    DASHBOARD_PATH,
    PASSWORD_RECOVERY_PATH,
    TRANSACTIONS_PATH,
    TRANSACTION_PATH,
    TEMPLATES_PATH,
    TEMPLATE_PATH,
    ACCOUNT_PATH,
    DESIGNER_PATH,
    ADMIN_PATH,
    PASSWORD_RESET_PATH,
    REGISTRATION_PATH,
    REPORTS_PATH,
    DESIGN_PATH,
    ERROR_PATH,
    FAST_TRACK_PATH,
    SENDER_REPORT_PATH
} from '../../constants';
import permissionsService from '../utils/permissionsService';
import LoginForms from '../components/Login/LoginForms/LoginForms';
import PasswordRecovery from '../components/PasswordRecovery/PasswordRecovery';
import PasswordReset from '../components/PassworReset/PasswordReset';
import Registration from '../components/Login/Registration/Registration';
import accountRoutes from './accountRoutes';
import adminRoutes from './adminRoutes';
import UsageSummary from '../components/Reports/UsageSummary/UsageSummary';
import SenderSummary from '../components/Reports/UsageSummary/SenderSummary/SenderSummary';

const routes = [
    {
        path: LOGIN_PATH,
        component: Login,
        fetchData: loginSsrFetch,
        isPrivate: false,
        childrenRoutes: [{
            index: true,
            component: LoginForms
        }]  
    }, {
        path: FORCE_LOGIN_PATH,
        component: Login,
        fetchData: loginSsrFetch,
        isPrivate: false,
        childrenRoutes: [{
            index: true,
            component: LoginForms
        }]
    }, {
        path: PASSWORD_RECOVERY_PATH,
        component: Login,
        fetchData: loginSsrFetch,
        isPrivate: false,
        childrenRoutes: [{
            index: true,
            component: PasswordRecovery
        }]
    }, {
        path: `${PASSWORD_RESET_PATH}/*`,
        component: Login,
        fetchData: loginSsrFetch,
        isPrivate: false,
        childrenRoutes: [{
            index: true,
            component: PasswordReset
        }]
    }, {
        path: PASSWORD_RESET_PATH,
        component: Login,
        fetchData: loginSsrFetch,
        isPrivate: false,
        childrenRoutes: [{
            index: true,
            component: PasswordReset
        }]
    }, {
        path: `${REGISTRATION_PATH}/*`,
        component: loadable(() => import(/* webpackChunkName: "login" */ '../components/Login/Login')),
        fetchData: loginSsrFetch,
        isPrivate: false,
        childrenRoutes: [{
            index: true,
            component: Registration
        }]
    }, {
        path: ERROR_PATH,
        component: FullErrorPage,
        isPrivate: false
    }, {
        path: DASHBOARD_PATH,
        component: loadable(() => import(/* webpackChunkName: "dashboard" */ '../components/Dashboard/Dashboard')),
        fetchData: dashboardSsrFetch,
        isPrivate: true
    }, {
        path: `${TRANSACTIONS_PATH}/:query?`,
        component: loadable(
            () => import(/* webpackChunkName: "transactions" */ '../components/Transactions/Transactions')
        ),
        fetchData: transactionsSsrFetch,
        isPrivate: true,
        isEnabled: permissionsService.hasTransactionPermission
    }, {
        path: `${TRANSACTION_PATH}/:packageId${DESIGNER_PATH}/:subLink?`,
        component: loadable(
            () => import(/* webpackChunkName: "transactionDesigner" */ '../components/Designer/Designer')
        ),
        fetchData: transactionDesignerSsrFetch,
        isPrivate: true,
        end: true,
        isEnabled: permissionsService.hasTransactionPermission
    },
    {
        path: `${TRANSACTION_PATH}/:packageId${DESIGN_PATH}/:subLink?`,
        component: loadable(
            () => import(/* webpackChunkName: "transactionDesigner" */ '../components/Designer/Designer')
        ),
        fetchData: transactionDesignerSsrFetch,
        isPrivate: true,
        end: true,
        isEnabled: permissionsService.hasTransactionPermission
    }, {
        path: `${TRANSACTION_PATH}/:packageId?`,
        component: loadable(
            () => import(/* webpackChunkName: "transaction" */ '../components/TransactionPageWrapper/TransactionPageWrapper')
        ),
        fetchData: transactionSsrFetch,
        isPrivate: true,
        end: true,
        isEnabled: permissionsService.hasTransactionPermission
    }, {
        path: `${TEMPLATE_PATH}/:packageId${DESIGNER_PATH}/:subLink?`,
        component: loadable(
            () => import(/* webpackChunkName: "templateDesigner" */ '../components/Designer/Designer')
        ),
        fetchData: templateDesignerSsrFetch,
        isPrivate: true,
        end: true,
        isEnabled: permissionsService.hasTransactionPermission
    },
    {
        path: `${TEMPLATE_PATH}/:packageId${DESIGN_PATH}/:subLink?`,
        component: loadable(
            () => import(/* webpackChunkName: "templateDesign" */ '../components/Designer/Designer')
        ),
        fetchData: templateDesignerSsrFetch,
        isPrivate: true,
        end: true,
        isEnabled: permissionsService.hasTransactionPermission
    }, {
        path: TEMPLATES_PATH,
        component: loadable(() => import(/* webpackChunkName: "templates" */ '../components/Templates/Templates')),
        fetchData: templatesSsrFetch,
        isPrivate: true,
        isEnabled: permissionsService.hasTransactionPermission
    },
    {
        path: `${TEMPLATE_PATH}/:packageId?`,
        component: loadable(() => import(/* webpackChunkName: "template" */ '../components/TemplatePageWrapper/TemplatePageWrapper')),
        fetchData: templateDetailsSsrFetch,
        isPrivate: true
    }, {
        path: ACCOUNT_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "accountSettings" */ '../components/AccountSettings/AccountSettings')
        ),
        isPrivate: true,
        childrenRoutes: accountRoutes
    },
    {
        path: ADMIN_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "adminPanel" */ '../components/AdminPanel/AdminPanel')
        ),
        isPrivate: true,
        childrenRoutes: adminRoutes
    }, {
        path: REPORTS_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "reports" */ '../components/Reports/Reports')
        ),
        isPrivate: true,
        isEnabled: permissionsService.hasReportPagePermission,
        childrenRoutes: [{
            path: `${REPORTS_PATH}/:query?`,
            component: UsageSummary,
            index: true
        }, {
            path: `${REPORTS_PATH}${SENDER_REPORT_PATH}`,
            component: SenderSummary,
        }]
    }, {
        path: `${FAST_TRACK_PATH}`,
        component: loadable(
            () => import(/* webpackChunkName: "fast track" */ '../components/Templates/FastTrack/FastTrackPage/FastTrackPage')
        ),
        isPrivate: false
    }
];

export default routes;
