import eventNames from './eventNames';

class IFrameEvent {
    constructor(name, canBeStoppedFromContinuing) {
        this.eventName = name;
        this.canBeStoppedFromContinuing = canBeStoppedFromContinuing;
    }
}

export const DOCUMENT_ACCEPT_STARTED_EVENT = new IFrameEvent(
    eventNames.DOCUMENT_ACCEPT_STARTED_EVENT_NAME,
    false
);

export const DOCUMENT_ACCEPT_SUCCESS_EVENT = new IFrameEvent(
    eventNames.DOCUMENT_ACCEPT_SUCCESS_EVENT_NAME,
    true
);

export const DOCUMENT_ACCEPT_ERROR_EVENT = new IFrameEvent(
    eventNames.DOCUMENT_ACCEPT_ERROR_EVENT_NAME,
    false
);

export const PACKAGE_OPT_OUT_STARTED_EVENT = new IFrameEvent(
    eventNames.PACKAGE_OPT_OUT_STARTED_EVENT_NAME,
    false
);

export const PACKAGE_OPT_OUT_SUCCESS_EVENT = new IFrameEvent(
    eventNames.PACKAGE_OPT_OUT_SUCCESS_EVENT_NAME,
    true
);

export const PACKAGE_OPT_OUT_ERROR_EVENT = new IFrameEvent(
    eventNames.PACKAGE_OPT_OUT_ERROR_EVENT_NAME,
    false
);

export const PACKAGE_DECLINE_STARTED_EVENT = new IFrameEvent(
    eventNames.PACKAGE_DECLINE_STARTED_EVENT_NAME,
    false
);

export const PACKAGE_DECLINE_SUCCESS_EVENT = new IFrameEvent(
    eventNames.PACKAGE_DECLINE_SUCCESS_EVENT_NAME,
    true
);

export const PACKAGE_DECLINE_ERROR_EVENT = new IFrameEvent(
    eventNames.PACKAGE_DECLINE_ERROR_EVENT_NAME,
    false
);

export const DOCUMENT_CONFIRM_STARTED_EVENT = new IFrameEvent(
    eventNames.DOCUMENT_CONFIRM_STARTED_EVENT_NAME,
    false
);

export const DOCUMENT_CONFIRM_SUCCESS_EVENT = new IFrameEvent(
    eventNames.DOCUMENT_CONFIRM_SUCCESS_EVENT_NAME,
    true
);

export const DOCUMENT_CONFIRM_ERROR_EVENT = new IFrameEvent(
    eventNames.DOCUMENT_CONFIRM_ERROR_EVENT_NAME,
    false
);

export const SIGNER_COMPLETE_STARTED_EVENT = new IFrameEvent(
    eventNames.SIGNER_COMPLETE_STARTED_EVENT_NAME,
    true
);

export const SIGNER_COMPLETE_SUCCESS_EVENT = new IFrameEvent(
    eventNames.SIGNER_COMPLETE_SUCCESS_EVENT_NAME,
    false
);

export const DOCUMENT_NAVIGATION_STARTED_EVENT = new IFrameEvent(
    eventNames.DOCUMENT_NAVIGATION_STARTED_EVENT_NAME,
    true
);

export const DOCUMENT_NAVIGATION_SUCCESS_EVENT = new IFrameEvent(
    eventNames.DOCUMENT_NAVIGATION_SUCCESS_EVENT_NAME,
    false
);

export const DOCUMENT_VIEW_SUCCESS_EVENT = (id) => new IFrameEvent(
    `${eventNames.DOCUMENT_VIEW_SUCCESS_EVENT_NAME}${id}`,
    false
);

// TODO: origin name GOTO_TRANSACTION
export const GOTO_TRANSACTION_EVENT = new IFrameEvent(
    eventNames.GOTO_TRANSACTION_EVENT_NAME,
    false
);

export const SIGNER_COMPLETE_STARTED_REVIEWED = new IFrameEvent(
    eventNames.SIGNER_COMPLETE_STARTED_REVIEWED_NAME,
    true
);

export const SIGNER_COMPLETE_SUCCESS_REVIEWED = new IFrameEvent(
    eventNames.SIGNER_COMPLETE_SUCCESS_REVIEWED_NAME,
    false
);

export const SIGNER_DONE_EVENT = (signerId) => new IFrameEvent(
    `${eventNames.SIGNER_DONE_EVENT_NAME}${signerId}`,
    false
);
