import React from 'react';
import {useIntl} from 'react-intl';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import './buttons.less';

export default function Buttons({loginForm, isLoginButtonVisible, ssoLoginRequired}) {
    const intl = useIntl();
    const isLoginButtonDisabled = !loginForm.email || !loginForm.password;
    const isContinueButtonDisabled = !loginForm.email;
    const className = classnames('login-buttons', {
        'is-visible': !ssoLoginRequired
    });

    return (
        <div className={className}>
            {isLoginButtonVisible ? (
                <div className="login-buttons-container">
                    <div className="login-button-container">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoginButtonDisabled || ssoLoginRequired}
                            size="small"
                            classes={{root: 'login-button primary-button'}}
                            data-wdio="test-login-form-submit-button"
                            fullWidth
                        >
                            {intl.formatMessage({id: 'esl.generic.login', defaultMessage: ''})}
                        </Button>
                    </div>
                    <div className="trial-button-container">
                        <Button
                            variant="outlined"
                            href={intl.formatMessage({id: 'trial.link', defaultMessage: ''})}
                            classes={{root: 'trial-button primary-button-inverted'}}
                            target="_blank"
                            fullWidth
                        >
                            {intl.formatMessage({id: 'page.login.freeTrial', defaultMessage: ''})}
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="continue-buttons-container">
                    <div className="continue-button-container">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isContinueButtonDisabled}
                            size="small"
                            classes={{root: 'continue-button primary-button'}}
                            data-wdio="test-login-form-continue-button"
                            fullWidth
                        >
                            {intl.formatMessage({id: 'shared.continue', defaultMessage: ''})}
                        </Button>
                    </div>
                    <div className="trial-button-container">
                        <Button
                            variant="outlined"
                            href={intl.formatMessage({id: 'trial.link', defaultMessage: ''})}
                            classes={{root: 'trial-button primary-button-inverted'}}
                            target="_blank"
                            fullWidth
                        >
                            {intl.formatMessage({id: 'page.login.freeTrial', defaultMessage: ''})}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}
