import {gql} from '@apollo/client/core';
import {isSigningComplete} from '../../utils/selectors';
import {SIGNING_TRANSACTION_STATUS} from '../SigningFields/utils/constants';

export const updateCache = ({
    onSign = () => {},
    transactionId,
    role = {}
}) => async (cache, {data}) => {
    const {id} = data.confirmDocument || data.acceptDisclosure;
    const {
        approvals,
        isConfirmedByAll,
        isReadyToConfirmByAll
    } = cache.readFragment({
        fragment: gql`
        fragment pageImgUrls on Document {
          approvals {
            id
            isSigned
          }
          signerApprovals {
            id
            isSigned
          }
          allSignersApprovals {
            id
            isSigned
            role {
              signerId
              initials
              company
              title
              fullName
              isDelegate
            }
          }
          isConfirmedByAll
          isReadyToConfirmByAll
          pages {
            imageUrl
          }
        }
      `,
        id: `Document:${id}`
    });

    const {documents} = cache.readFragment({
        fragment: gql`
        fragment documents on Transaction {
          documents {
            id
            index
            isConfirmed
            isReview
          }
          isVirtualRoomSigning
        }
      `,
        id: `Transaction:${transactionId}`
    });

    cache.writeFragment({
        data: {
            __typename: 'Document',
            approvals: approvals.map((approval) => ({
                __typename: 'Approval',
                id: approval.id,
                isSigned: true
            })),
            id,
            isConfirmedByAll,
            isReadyToConfirmByAll,
            signerApprovals: []
        },
        fragment: gql`
        fragment documentIsConfirmed on Document {
          approvals {
            isSigned
          }
          isConfirmedByAll
          isReadyToConfirmByAll
          signerApprovals {
            isSigned
          }
        }
      `,
        id: `Document:${id}`
    });

    const currentRoleHasSigned = isSigningComplete(documents);

    if (currentRoleHasSigned) {
        cache.writeFragment({
            data: {
                id: role.id,
                __typename: 'Role',
                hasSigned: true,
                canReAssign: false,
                progress: SIGNING_TRANSACTION_STATUS.DONE
            },
            fragment: gql`
          fragment role on Role {
            id
            hasSigned
            canReAssign
            progress
          }
        `,
            id: `Role:${role.id}`
        });
        onSign();
    }
    const {autocomplete, roles} = cache.readFragment({
        fragment: gql`
        fragment roles on Transaction {
          autocomplete
          roles {
            id
            email
            hasSigned
            isReviewer
            isOwner
            canSign
          }
        }
      `,
        id: `Transaction:${transactionId}`
    });

    const isTransactionCompleted = roles.every((roleData) => roleData.hasSigned) && autocomplete;

    if (isTransactionCompleted) {
        cache.writeFragment({
            data: {
                __typename: 'Transaction',
                id: transactionId,
                isCompleted: isTransactionCompleted
            },
            fragment: gql`
          fragment isCompletedTransaction on Transaction {
            isCompleted
          }
        `,
            id: `Transaction:${transactionId}`
        });
    }
};
