import React from 'react';
import {useDispatch} from 'react-redux';
import classnames from 'classnames';
import {useIntl} from 'react-intl';
import {AppButton} from '../../../Core/buttons/AppButton/AppButton';
import {goToInPersonPage, goToThankYouHostPage} from '../../signerUiSlice';
import useInPerson from '../../hooks/useInPerson';
import {SectionWithTitle} from '../../../Core/company/layouts/SectionWithTitle/SectionWithTitle';
import IFrameEventNotifications from '../../../../utils/iFrameNotifyer/IFrameEventNotifications';
import {SIGNER_DONE_EVENT} from '../../../../utils/iFrameNotifyer/events';
import {useCeremony} from '../../hooks/queries';
import useSetHostToSignerSession from '../../hooks/useSetHostToSignerSession';
import {isTransactionCompleted} from '../../utils/selectors';
import './inPersonThankYou.less';

/** This page is visible when signer finish the signing process in person. */
const InPersonThankYou = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {role, transaction} = useCeremony();
    const iFrameEventNotifications = IFrameEventNotifications.getInstance();
    const {inPersonCeremonyHost} = useInPerson();
    const containerClassName = classnames('thank-you-page');
    const isCurrentRoleHost = inPersonCeremonyHost?.signerId === role?.signerId;
    const {setHostToSignerSession} = useSetHostToSignerSession();
    const isCompletedTransaction = isTransactionCompleted(transaction);

    async function passControlsToTheHost() {
        iFrameEventNotifications.postEvent(SIGNER_DONE_EVENT(role?.signerId));
        await setHostToSignerSession();
        if (isCompletedTransaction) {
            dispatch(goToThankYouHostPage());
        } else {
            dispatch(goToInPersonPage());
        }
    }

    return (
        <div className={containerClassName} data-wdio={`test-thank-you-screen-${role.email}`}>
            <SectionWithTitle
                title={intl.formatMessage({id: 'oss.components.InPerson.ThankYouSignerScreen.textTitle', defaultMessage: ''}, {name: role?.fullName})}
            >
                <p className="title-message">
                    {intl.formatMessage({id: isCurrentRoleHost ? 'oss.components.InPerson.ThankYouSignerScreen.Signer.textDescription' : 'oss.components.InPerson.ThankYouSignerScreen.Signer.textDescription', defaultMessage: ''})}
                </p>
                <AppButton
                    onClick={passControlsToTheHost}
                >
                    {intl.formatMessage({id: 'oss.components.Affidavits.proceed.button', defaultMessage: ''})}
                </AppButton>
            </SectionWithTitle>
        </div>
    );
};
export default InPersonThankYou;
