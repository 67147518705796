import React, {useEffect, useState} from 'react';
import qs from 'qs';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router';
import {Helmet} from 'react-helmet';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import Loading from '../shared/Loading/Loading';
import useJoinMessage from '../../hooks/useJoinMessage';
import ChangePassword from '../AccountSettings/ChangePassword/ChangePassword';
import {
    fetchPasswordPolicy,
    fetchResetPasswordUserInfo,
    fetchSessionInfo,
    selectIsFetchingPasswordPolicy,
    selectIsFetchingResetPasswordUserInfo,
    selectIsPasswordUpdating,
    selectPasswordPolicyError,
    selectPasswordUpdateError,
    selectResetPasswordUserInfoError,
    updatePassword
} from '../Login/userSlice';
import {DASHBOARD_PATH, DEFAULT_PASSWORD_POLICY, PASSWORD_RECOVERY_PATH} from '../../../constants';
import useNotifications from '../../hooks/useNotifications';
import useErrorNotification from '../../hooks/useErrorNotification';
import './passwordReset.less';

export default function PasswordReset() {
    const intl = useIntl();
    const {showSuccessAlert} = useNotifications();
    const joinMessage = useJoinMessage();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const resetPasswordUserInfoError = useSelector(selectResetPasswordUserInfoError);
    const passwordPolicyError = useSelector(selectPasswordPolicyError);
    const passwordUpdateError = useSelector(selectPasswordUpdateError);
    const isFetchingPasswordPolicy = useSelector(selectIsFetchingPasswordPolicy);
    const isFetchingResetPasswordUserInfo = useSelector(selectIsFetchingResetPasswordUserInfo);
    const isPasswordUpdating = useSelector(selectIsPasswordUpdating);

    const [token, setToken] = useState('');
    const [user, setUser] = useState({});
    const [passwordPolicy, setPasswordPolicy] = useState({});
    const error = resetPasswordUserInfoError || passwordPolicyError;
    const loading = isFetchingPasswordPolicy || isFetchingResetPasswordUserInfo || isPasswordUpdating;

    useErrorNotification(resetPasswordUserInfoError);
    useErrorNotification(passwordPolicyError);
    useErrorNotification(passwordUpdateError);

    async function getUserDataAndPolicyToResetPassword(passwordToken) {
        const userData = await dispatch(fetchResetPasswordUserInfo(passwordToken));
        if (userData) {
            const policyData = await dispatch(fetchPasswordPolicy(passwordToken));
            setPasswordPolicy(policyData);
        }
        setUser(userData);
    }

    useEffect(() => {
        const {passwordToken} = qs.parse(location.search, {ignoreQueryPrefix: true});
        setToken(passwordToken);
        getUserDataAndPolicyToResetPassword(passwordToken);
    }, [location.search]);

    const handleSubmit = async (form, shouldAllowUpdatePassword) => {
        if (shouldAllowUpdatePassword && user.email) {
            const wasSuccessful = await dispatch(updatePassword({
                currentPassword: '',
                email: user.email,
                newPassword: form.newPassword,
                password: token
            }));
            if (wasSuccessful) {
                const res = await dispatch(fetchSessionInfo());
                if (res) {
                    navigate(DASHBOARD_PATH);
                    showSuccessAlert(intl.formatMessage({id: 'page.admin.userManagement.passwordHasBeenReset', defaultMessage: ''}));
                } else {
                    showSuccessAlert(intl.formatMessage({id: 'esl.error.internal.default', defaultMessage: ''}));
                }
            }
        }
    };

    return (
        <div className="password-reset login-forms">
            <Helmet>
                <title>{joinMessage('esl.generic.reset_password', '-', 'page.title')}</title>
            </Helmet>
            <div className="container">
                <Loading isLoading={loading} />
                <h1 className="title">
                    {intl.formatMessage({id: 'esl.generic.reset_password', defaultMessage: ''})}
                </h1>
                <div className="content">
                    {(error || !token) && (
                        <div className="password-recovery-link-container">
                            {intl.formatMessage({id: 'esl.generic.oops_take_me_back_login', defaultMessage: ''}, {
                                link: (
                                    <Link
                                        to={`${PASSWORD_RECOVERY_PATH}`}
                                        className="password-recovery-link"
                                    >
                                        {intl.formatMessage({id: 'esl.generic.recover_password', defaultMessage: ''})}
                                    </Link>
                                )
                            })}
                        </div>
                    )}
                    {!error && !isEmpty(user) && (
                        <ChangePassword
                            isResetPassword
                            updatePassword={handleSubmit}
                            passwordPolicy={isEmpty(passwordPolicy) ? DEFAULT_PASSWORD_POLICY : passwordPolicy}
                            registration
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
