import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash.isempty';
import {useParams} from 'react-router-dom';
import {
    selectIsInitialStateSet,
    selectSelectedAttachmentId,
    setInitialState,
    setIsInitialStateSet,
    setTransactionId
} from '../signerUiSlice';
import {currentPage} from '../utils/rules';
import {getInitialDocumentId} from '../utils/selectors';
import useInPerson from './useInPerson';
import {useCeremony, useMessages} from './queries';
import {getHostSessionId, getValueForKey, setHostSession} from '../../../utils/storageSelectors';
import {STORAGE_KEY_FROM_SENDER} from '../../../../constants';
import {getIsExampleTransactionID} from '../utils/functions';

export default function useInitialSigningState() {
    const dispatch = useDispatch();
    const params = useParams();
    const isInitialStateSet = useSelector(selectIsInitialStateSet);
    const selectedAttachmentId = useSelector(selectSelectedAttachmentId);
    const inSender = !!getValueForKey(STORAGE_KEY_FROM_SENDER);
    const {
        inPersonCeremonyHost,
        isInPersonSigning
    } = useInPerson();
    const {
        transaction,
        role,
        session,
        refetch
    } = useCeremony();
    const {messages, locale} = useMessages();

    function handleNewTransactionOpen() {
        dispatch(setTransactionId(params.packageId));
        dispatch(setIsInitialStateSet(false));
    }

    useEffect(() => {
        if (params.packageId && params.packageId !== transaction.id) {
            handleNewTransactionOpen();
        }
    }, [params.packageId, transaction.id]);

    async function initializeState() {
        const shouldUpdateInitialState = params.packageId === transaction.id
            && !isEmpty(transaction)
            && !isEmpty(role)
            && (getIsExampleTransactionID(transaction.id) || !isEmpty(messages));
        if (shouldUpdateInitialState) {
            const initialDocumentId = getInitialDocumentId(transaction, null);
            const initialState = {
                initialDocumentId,
                isInPersonCeremonyControlledByHost: false,
                signerCanSign: role.canSign,
                signerIsOwner: role.isOwner,
                inPersonCeremonyHost,
                locale,
                inSender,
                ...currentPage({
                    inPersonCeremonyHost,
                    isConsentNullOrAccepted: transaction.isConsentNullOrAccepted,
                    transaction,
                    role,
                    inSender,
                    initialDocumentId,
                    previewApplication: false, // TODO: find out what is it and how to get
                    viewer: 'pdf', // TODO: find out what is it and how to get
                    isSenderJoinAdHoc: false, // TODO: find out what is it and how to get
                    isVirtualRoomView: false, // TODO: find out what is it and how to get
                    isVirtualRoomHost: false, // TODO: find out what is it and how to get
                    isVirtualRoomSigning: false, // TODO: find out what is it and how to get
                    initialAttachmentId: selectedAttachmentId, // TODO: find out what is it and how to get
                    signerId: null // TODO: find out what is it and how to get
                })
            };

            if (!isInitialStateSet) {
                if (isInPersonSigning || transaction.isInPerson) {
                    // Reset sessionId to hostSessionId on each refresh
                    const sessionId = getHostSessionId();
                    setHostSession(sessionId);
                    await refetch();
                }
                dispatch(setInitialState(initialState));
            }
        }
    }
    useEffect(() => {
        initializeState();
    }, [params.packageId, transaction, role, session, messages, isInitialStateSet]);
}
