import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import useInPerson from '../../hooks/useInPerson';
import {useSwitchRole} from '../../hooks/mutations';
import {Dialog, DialogButton} from '../../../Core/company/modals/Dialog';
import {AppButton} from '../../../Core/buttons/AppButton/AppButton';
import {startInPersonSigning} from '../../signerUiSlice';
import {useDocumentsWithPages, useFeatures} from '../../hooks/queries';
import {isRecipientSmsAuth} from '../../utils/selectors';
import {SmsDialogComponent} from '../SmsDialogComponent/SmsDialogComponent';

const InPersonDialogs = ({
    isOpen, role, selectedSigner, signers, setIsOpen
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [switchRole] = useSwitchRole();
    const {inPersonCeremonyHost} = useInPerson();
    const {features} = useFeatures();
    const [isStartSigningConfirmationOpen, setIsStartSigningConfirmationOpen] = useState(false);
    const [showSmsAuthanticationDialog, setShowSmsAuthanticationDialog] = useState(false);
    const selectedSignerId = selectedSigner.signerId;
    const showSMSAuth = features.inPersonSmsAuth && isRecipientSmsAuth(selectedSigner);

    function onClose() {
        setIsOpen(false);
    }

    function openStartSigningConfirmation() {
        setIsStartSigningConfirmationOpen(true);
        onClose();
    }

    function closeStartSigningConfirmation() {
        setIsStartSigningConfirmationOpen(false);
    }

    function closeSmsAuthanticationDialog() {
        setShowSmsAuthanticationDialog(false);
    }
    const {refetch} = useDocumentsWithPages();

    async function handleStartInPersonSigning() {
        closeStartSigningConfirmation();
        const isRoleHost = selectedSignerId === inPersonCeremonyHost.signerId;
        if (showSMSAuth && selectedSigner) {
            return setShowSmsAuthanticationDialog(true);
        }
        if (!isRoleHost) {
            const swithcedRoleSuccess = await switchRole({roleId: selectedSignerId});
            if (swithcedRoleSuccess) {
                refetch();
                dispatch(startInPersonSigning({
                    isOwner: role.isOwner,
                    canSign: role.canSign
                }));
            }
        }
    }

    return (
        <>
            <Dialog
                open={isOpen}
                title={intl.formatMessage({id: 'oss.components.Affidavits.title', defaultMessage: ''})}
                content={(intl.formatMessage(
                    {id: 'oss.components.Affidavits.host.message', defaultMessage: ''},
                    {signerFullName: selectedSigner.fullName}
                )
                )}
                actions={(
                    <>
                        <DialogButton onClick={onClose}>
                            {intl.formatMessage({id: 'oss.components.Affidavits.cancel.button', defaultMessage: ''})}
                        </DialogButton>
                        <AppButton
                            onClick={openStartSigningConfirmation}
                            autoFocus
                        >
                            {intl.formatMessage({id: 'oss.components.Affidavits.proceed.button', defaultMessage: ''})}
                        </AppButton>

                    </>
                )}
            />
            <Dialog
                open={isStartSigningConfirmationOpen}
                title={intl.formatMessage({id: 'oss.components.Affidavits.title', defaultMessage: ''})}
                content={(intl.formatMessage(
                    {id: 'oss.components.Affidavits.signer.message', defaultMessage: ''},
                    {signerFullName: selectedSigner.fullName}
                ))}
                actions={(
                    <>
                        <DialogButton onClick={closeStartSigningConfirmation}>
                            {intl.formatMessage({id: 'oss.components.Affidavits.cancel.button', defaultMessage: ''})}
                        </DialogButton>
                        <AppButton
                            onClick={handleStartInPersonSigning}
                            autoFocus
                        >
                            {intl.formatMessage({id: 'oss.components.ConfirmBar.confirm', defaultMessage: ''})}
                        </AppButton>
                    </>
                )}
            />
            {showSMSAuth && (
                <SmsDialogComponent
                    isOpen={showSmsAuthanticationDialog}
                    close={closeSmsAuthanticationDialog}
                    signer={signers.find(({signerId}) => selectedSignerId === signerId)}
                />
            )}
        </>
    );
};
export default InPersonDialogs;
