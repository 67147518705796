/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */

import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import httpClient from '../../utils/httpClient';
import {
    fetchDocument as fetchTemplateDocument,
    fetchDocuments as fetchTemplateDocuments,
    fetchTemplate
} from '../Template/templateSlice';
import {
    fetchDocument as fetchTransactionDocument,
    fetchDocuments as fetchTransactionDocuments,
    fetchTransaction,
    setTransaction
} from '../Transaction/transactionSlice';
import {
    BASE_PATH,
    LAYOUTS_SEARCH_TYPES,
    LAYOUT_TYPE,
    TRANSACTION_STATUSES,
    PACKAGE_TYPE_NAME,
    VISIBILITY_SENDER,
    DESIGNER_PATH,
    RECIPIENT_TYPES,
    FETCH_OPTIONS_STEP
} from '../../../constants';
import {getAllPackageApprovals, getFieldById} from './utils';
import {getErrorMessage} from '../../utils/requests/error';

export const designerSlice = createSlice({
    name: 'designer',
    initialState: {
        settings: {},
        releaseFeatures: {},
        selectedInfo: {},
        fetchDocumentEque: {
            documentIds: [],
            eque: [],
            selectedInfo: {}
        },
        copiedFieldInfo: {},
        customFields: [],
        layouts: [],
        packageType: '',
        activeDocument: {
            id: null,
            pageIndex: 0
        },
        documentsVisibility: [],
        ifFetchingDocumentsVisibility: false,
        fetchDocumentsVisibilityError: null,
        ifSavingDocumentsVisibility: false,
        saveDocumentsVisibilityError: null,
        isFetchingLayouts: false,
        fetchLayoutsError: null,
        isSavingLayout: false,
        saveLayoutError: null,
        isApplyingLayout: false,
        applyLayoutError: null,
        isFetchingSettings: false,
        fetchSettingsErrors: null,
        isSendingToSign: false,
        sendToSignErrors: null,
        isUpdatingField: false,
        updateApprovalsError: null,
        updateConditionError: null,
        hasPlacedSignature: false,
        createApprovalError: null,
        isFieldCreating: false,
        duplicationFieldIdInProgress: ''
    },
    reducers: {
        setSettings: (state, action) => {
            state.settings = action.payload;
        },
        setCustomFields: (state, action) => {
            state.customFields = action.payload;
        },
        setIsSendingToSign: (state, action) => {
            state.isSendingToSign = action.payload;
        },
        setSendToSignErrors: (state, action) => {
            state.sendToSignErrors = action.payload;
        },
        setIsFetchingSettings: (state, action) => {
            state.isFetchingSettings = action.payload;
        },
        setFetchSettingsErrors: (state, action) => {
            state.fetchSettingsErrors = action.payload;
        },
        setActiveDocument: (state, action) => {
            state.activeDocument = action.payload;
        },
        setIsUpdatingField: (state, action) => {
            state.isUpdatingField = action.payload;
        },
        setUpdateApprovalsError: (state, action) => {
            state.updateApprovalsError = action.payload;
        },
        setReleaseFeatures: (state, action) => {
            state.releaseFeatures = action.payload;
        },
        setSelectedInfo: (state, action) => {
            if (action.payload.unSelect) {
                state.selectedInfo = {};
            } else {
                state.selectedInfo = {
                    ...state.selectedInfo,
                    ...action.payload
                };
            }
        },
        setCopiedFieldInfo: (state, action) => {
            state.copiedFieldInfo = action.payload;
        },
        setFetchDocumentEque: (state, action) => {
            const {
                documentId, selectedInfo, shouldSkipMessage, popQueue
            } = action.payload;
            const fetchState = state.fetchDocumentEque;

            if (popQueue) {
                fetchState.eque.pop();
                fetchState.shouldSkipMessage = true;
                return;
            }
            if (action.payload.fetched) {
                state.fetchDocumentEque = {
                    documentIds: [],
                    eque: [],
                    selectedInfo: {},
                    shouldSkipMessage: false
                };
            }
            if (documentId) {
                state.fetchDocumentEque = {
                    documentIds: state.fetchDocumentEque.documentIds.includes(documentId)
                        ? [...state.fetchDocumentEque.documentIds]
                        : [...state.fetchDocumentEque.documentIds, documentId],

                    eque: [...state.fetchDocumentEque.eque, true],
                    selectedInfo,
                    shouldSkipMessage
                };
            } else {
                state.fetchDocumentEque = {
                    documentIds: [
                        ...state.fetchDocumentEque.documentIds
                    ],
                    eque: [...state.fetchDocumentEque.eque.splice(0, state.fetchDocumentEque.eque.length - 1)],
                    selectedInfo,
                    shouldSkipMessage
                };
            }
        },
        setLayouts: (state, action) => {
            const {payload} = action;
            let updatedLayouts = [...state.layouts];
            if (payload.addLayout) {
                updatedLayouts.unshift(payload.data);
            } else if (payload.reset) {
                updatedLayouts = [];
            } else {
                updatedLayouts.splice(payload.from - 1, payload.data.length, ...payload.data);
            }
            state.layouts = updatedLayouts;
        },
        setLayoutsCount: (state, action) => {
            state.layoutsCount = action.payload;
        },
        setIsFetchingLayouts: (state, action) => {
            state.isFetchingLayouts = action.payload;
        },
        setFetchLayoutsError: (state, action) => {
            state.fetchLayoutsError = action.payload;
        },
        setIsSavingLayout: (state, action) => {
            state.isSavingLayout = action.payload;
        },
        setSaveLayoutError: (state, action) => {
            state.saveLayoutError = action.payload;
        },
        setIsApplyingLayout: (state, action) => {
            state.isApplyingLayout = action.payload;
        },
        setApplyLayoutError: (state, action) => {
            state.applyLayoutError = action.payload;
        },
        setDocumentsVisibility: (state, action) => {
            state.documentsVisibility = action.payload;
        },
        setIfFetchingDocumentsVisibility: (state, action) => {
            state.ifFetchingDocumentsVisibility = action.payload;
        },
        setFetchDocumentsVisibilityError: (state, action) => {
            state.fetchDocumentsVisibilityError = action.payload;
        },
        setIfSavingDocumentsVisibility: (state, action) => {
            state.ifSavingDocumentsVisibility = action.payload;
        },
        setSaveDocumentsVisibilityError: (state, action) => {
            state.saveDocumentsVisibilityError = action.payload;
        },
        setPackageType: (state, action) => {
            state.packageType = action.payload;
        },
        setUpdateConditionError: (state, action) => {
            state.updateConditionError = action.payload;
        },
        setHasPlacedSignature: (state, action) => {
            state.hasPlacedSignature = action.payload;
        },
        setCreateApprovalError: (state, action) => {
            state.createApprovalError = action.payload;
        },
        setIsFieldCreating: (state, action) => {
            state.isFieldCreating = action.payload;
        },
        setDuplicationFieldIdInProgress: (state, action) => {
            state.duplicationFieldIdInProgress = action.payload;
        }
    }
});

export const {
    setSettings,
    setCustomFields,
    setActiveDocument,
    setIsSendingToSign,
    setSendToSignErrors,
    setIsFetchingSettings,
    setFetchSettingsErrors,
    setIsUpdatingField,
    setUpdateApprovalsError,
    setReleaseFeatures,
    setSelectedInfo,
    setCopiedFieldInfo,
    setFetchDocumentEque,
    setIsPreventScroll,
    setLayouts,
    setLayoutsCount,
    setIsFetchingLayouts,
    setFetchLayoutsError,
    setIsSavingLayout,
    setSaveLayoutError,
    setIsApplyingLayout,
    setApplyLayoutError,
    setDocumentsVisibility,
    setIfFetchingDocumentsVisibility,
    setFetchDocumentsVisibilityError,
    setIfSavingDocumentsVisibility,
    setSaveDocumentsVisibilityError,
    setPackageType,
    setUpdateConditionError,
    setHasPlacedSignature,
    setCreateApprovalError,
    setIsFieldCreating,
    setDuplicationFieldIdInProgress
} = designerSlice.actions;

const fetchPackage = (params) => async (dispatch, getState) => {
    const {packageType} = getState().designer;
    if (packageType === PACKAGE_TYPE_NAME.TRANSACTION) {
        const wasSuccessful = await dispatch(fetchTransaction(params));
        return wasSuccessful;
    } if (packageType === PACKAGE_TYPE_NAME.TEMPLATE) {
        const wasSuccessful = await dispatch(fetchTemplate(params));
        return wasSuccessful;
    }
};

const fetchDocument = (packageId, documentId, packageDataType) => async (dispatch, getState) => {
    const packageType = packageDataType || getState().designer.packageType;
    if (packageType === PACKAGE_TYPE_NAME.TRANSACTION) {
        await dispatch(fetchTransactionDocument(packageId, documentId));
    } if (packageType === PACKAGE_TYPE_NAME.TEMPLATE) {
        await dispatch(fetchTemplateDocument(packageId, documentId));
    }
};

const fetchDocuments = (packageDataType) => async (dispatch, getState) => {
    const packageType = packageDataType || getState().designer.packageType;
    if (packageType === PACKAGE_TYPE_NAME.TRANSACTION) {
        await dispatch(fetchTransactionDocuments());
    } else if (packageType === PACKAGE_TYPE_NAME.TEMPLATE) {
        await dispatch(fetchTemplateDocuments());
    }
};

export const setFetchDocumentEqueInfo = ({
    documentId = '', selectedInfo = {}, shouldSkipMessage, popQueue
}) => (dispatch) => {
    dispatch(setFetchDocumentEque({
        documentId, selectedInfo, shouldSkipMessage, popQueue
    }));
};

export const deleteFetchDocumentEque = () => async (dispatch) => {
    dispatch(setFetchDocumentEque({fetched: true}));
    dispatch(setIsFieldCreating(false));
};

export const sendToSign = (packageId) => async (dispatch) => {
    dispatch(setIsSendingToSign(true));
    dispatch(setSendToSignErrors(null));

    try {
        const result = await httpClient
            .get(`${BASE_PATH}/proxy/packages/${packageId}/readiness?stageName=send&checkSenderVisibility=true`);

        const wasSuccessful = get(result, 'data.status', false);
        const errorKeys = get(result, 'data.errorKeys', ['error_500.oops_broken']);

        if (wasSuccessful) {
            await httpClient.post(`${BASE_PATH}/proxy/packages/${packageId}`, {
                status: TRANSACTION_STATUSES.SENT
            });

            dispatch(fetchPackage({
                params: {
                    packageId
                }
            }));
            dispatch(setIsSendingToSign(false));
            return true;
        }
        dispatch(setIsSendingToSign(false));
        dispatch(setSendToSignErrors(errorKeys));
        return false;
    } catch (err) {
        dispatch(setSendToSignErrors([getErrorMessage(err, true).messageKey]));
        dispatch(setIsSendingToSign(false));
        return false;
    }
};

export const fetchDesignerSettings = ({
    headers, baseUrl, params
} = {}) => async (dispatch) => {
    const {packageId} = params;
    const servicePath = `packages/${packageId}${DESIGNER_PATH}`;

    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {};
    if (headers) {
        options.headers = headers;
    }

    dispatch(setIsFetchingSettings(true));
    dispatch(setFetchSettingsErrors(null));

    try {
        const result = await httpClient.get(url, options);

        dispatch(setSettings(result.data));
        dispatch(setIsFetchingSettings(false));
        return true;
    } catch (err) {
        dispatch(setIsFetchingSettings(false));
        dispatch(setFetchSettingsErrors(getErrorMessage(err)));
        return false;
    }
};

export const fetchReleaseFeatures = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/releaseFeatures`;
    dispatch(setIsFetchingSettings(true));
    dispatch(setFetchSettingsErrors(null));

    try {
        const result = await httpClient.get(url);
        dispatch(setReleaseFeatures(result.data));
        dispatch(setIsFetchingSettings(false));
        return true;
    } catch (err) {
        dispatch(setIsFetchingSettings(false));
        dispatch(setFetchSettingsErrors(getErrorMessage(err)));
        return false;
    }
};

export const fetchCustomFields = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/customfields`;

    try {
        const {data} = await httpClient.get(url);
        dispatch(setCustomFields(data));
        return true;
    } catch (err) {
        return false;
    }
};

export const getCurrentFieldState = ({
    documentId,
    fieldId
}) => (dispatch, getState) => {
    const {packageType} = getState().designer;
    const document = getState()[packageType][packageType].documents.find(({id}) => id === documentId);
    const field = getFieldById(document.approvals, fieldId);
    return field;
};

export const getActiveDocument = () => (dispatch, getState) => {
    const {packageType, activeDocument} = getState().designer;

    return {
        id: activeDocument.id || get(getState()[packageType], `${packageType}.documents[0].id`),
        pageIndex: activeDocument.pageIndex
    };
};

export const getIsFieldCreating = () => (dispatch, getState) => {
    const {designer} = getState();
    return designer.isFieldCreating;
};

export const getPageFields = (activeDocumentId, activeDocumentPageIndex) => (dispatch, getState) => {
    const {designer} = getState();
    const packageState = get(getState(), `${[designer.packageType]}`);
    const packageDocuments = get(packageState, `${[designer.packageType]}.documents`, []);
    const document = packageDocuments.find(({id}) => id === activeDocumentId) || {};
    const documentFields = [];

    if (document.approvals) {
        document.approvals.forEach((approval) => {
            approval.fields.forEach((field) => documentFields.push({
                documentId: document.id,
                approvalId: approval.id,
                role: approval.role,
                ...field
            }));
        });
    }

    return documentFields.filter((field) => field.page === activeDocumentPageIndex);
};

export const updateField = ({
    packageId,
    documentId,
    approvalId,
    field
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals/${approvalId}/fields/${field.id}`;
    dispatch(setIsUpdatingField(true));
    try {
        await httpClient.put(url, field);
        await dispatch(fetchDocument(packageId, documentId));
        dispatch(setIsUpdatingField(false));

        return true;
    } catch (err) {
        dispatch(setIsUpdatingField(false));

        return false;
    }
};

export const updateFieldPosition = ({
    packageId,
    documentId,
    approvalId,
    field,
    roleId
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals/${approvalId}/fields/${field.id}`;
    dispatch(setFetchDocumentEqueInfo({documentId}));

    try {
        const {data} = await httpClient.put(url, field);
        dispatch(setFetchDocumentEqueInfo({
            selectedInfo: {
                fieldId: data.id, approvalId, documentId, roleId
            }
        }));

        return true;
    } catch (err) {
        dispatch(setFetchDocumentEqueInfo({popQueue: true}));
        await dispatch(fetchDocument(packageId, documentId));
        return false;
    }
};

export const deleteField = ({
    packageId,
    documentId,
    approvalId,
    fieldId
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals/${approvalId}/fields/${fieldId}`;

    try {
        await httpClient.delete(url);
        await dispatch(fetchDocument(packageId, documentId));

        return true;
    } catch (err) {
        return false;
    }
};
export const updateFieldCondition = ({
    packageId,
    documentId,
    approvalId,
    conditionalField
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals/${approvalId}/fields/${conditionalField.id}/conditionalFields`;
    dispatch(setIsUpdatingField(true));

    try {
        const {data} = await httpClient.put(url, conditionalField);
        return data.conditions;
    } catch (err) {
        dispatch(setUpdateConditionError(getErrorMessage(err)));
        dispatch(setIsUpdatingField(false));
        return false;
    }
};

export const createFieldRequest = ({
    packageId,
    approvalId,
    documentId,
    field
}) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals/${approvalId}/fields`;
    return httpClient.post(url, field);
};
export const createField = ({
    packageId,
    documentId,
    approvalId,
    field,
    roleId,
    isDuplicate
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals/${approvalId}/fields`;
    await dispatch(setFetchDocumentEqueInfo({documentId}));
    dispatch(setIsFieldCreating(true));

    try {
        const {data} = await httpClient.post(url, field);
        await dispatch(setFetchDocumentEqueInfo({
            selectedInfo: isDuplicate ? {} : {
                fieldId: data.id, documentId, approvalId, roleId
            },
            shouldSkipMessage: isDuplicate
        }));

        return true;
    } catch (err) {
        dispatch(setIsFieldCreating(false));
        dispatch(setFetchDocumentEqueInfo({popQueue: true}));

        return false;
    }
};

export const createApprovalRequest = ({
    packageId,
    documentId,
    approval
}) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals`;
    return httpClient.post(url, approval);
};
export const createApproval = ({
    packageId,
    documentId,
    approval,
    isDuplicate
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals`;

    await dispatch(setFetchDocumentEqueInfo({documentId}));
    dispatch(setIsFieldCreating(true));

    try {
        const {data} = await httpClient.post(url, approval);
        await dispatch(setFetchDocumentEqueInfo({
            selectedInfo: isDuplicate ? {} : {
                fieldId: data.fields[0].id, approvalId: data.id, roleId: data.role, documentId
            },
            shouldSkipMessage: isDuplicate
        }));

        return true;
    } catch (err) {
        dispatch(setIsFieldCreating(false));
        dispatch(setCreateApprovalError(getErrorMessage(err)));
        dispatch(setFetchDocumentEqueInfo({popQueue: true}));

        return false;
    }
};

export const updateApproval = ({
    packageId,
    documentId,
    approvalId,
    payload
}) => async (dispatch, getState) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals/${approvalId}`;
    dispatch(setUpdateApprovalsError(null));
    dispatch(setIsUpdatingField(true));

    try {
        await httpClient.put(url, payload);
        await dispatch(fetchDocument(packageId, documentId));
        dispatch(setIsUpdatingField(false));
        return true;
    } catch (err) {
        dispatch(setIsUpdatingField(false));
        dispatch(setUpdateApprovalsError(err));
        return false;
    }
};

export const deleteApproval = ({
    packageId,
    documentId,
    approvalId
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals/${approvalId}`;
    dispatch(setUpdateApprovalsError(null));
    dispatch(setIsUpdatingField(true));
    try {
        await httpClient.delete(url);
        dispatch(setIsUpdatingField(false));
        return true;
    } catch (err) {
        dispatch(setIsUpdatingField(false));
        dispatch(setUpdateApprovalsError(getErrorMessage()));
        return false;
    }
};

export const updateApprovals = ({
    packageId,
    documentId,
    payload
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals`;
    dispatch(setUpdateApprovalsError(null));
    dispatch(setIsUpdatingField(true));
    try {
        await httpClient.put(url, payload);
        await dispatch(fetchDocument(packageId, documentId));

        dispatch(setIsUpdatingField(false));
        return true;
    } catch (err) {
        dispatch(setIsUpdatingField(false));
        dispatch(setUpdateApprovalsError(err));
        return false;
    }
};

export const deleteAllApprovalsAndFields = ({
    packageId,
    documentId
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/approvals`;
    dispatch(setUpdateApprovalsError(null));
    dispatch(setIsUpdatingField(true));
    try {
        await httpClient.put(url, []);
        dispatch(setIsUpdatingField(false));
        return true;
    } catch (err) {
        dispatch(setIsUpdatingField(false));
        dispatch(setUpdateApprovalsError(err));
        return false;
    }
};

export const fetchLayouts = ({
    headers, baseUrl, params = {}
} = {}) => async (dispatch) => {
    const {
        search = '',
        searchtype = LAYOUTS_SEARCH_TYPES.EXACT_NAME,
        visibility = VISIBILITY_SENDER,
        summary = true,
        from = 1,
        to = FETCH_OPTIONS_STEP,
        shouldSkipLayoutsSet
    } = params;
    const url = baseUrl ? `${baseUrl}/api/layouts` : `${BASE_PATH}/proxy/layouts`;
    const options = {
        params: {
            search,
            searchtype,
            visibility,
            summary,
            from,
            to
        }
    };

    if (headers) {
        options.headers = headers;
    }
    dispatch(setIsFetchingLayouts(true));
    dispatch(setFetchLayoutsError(null));

    try {
        const result = await httpClient.get(url, options);
        if (!shouldSkipLayoutsSet) {
            dispatch(setLayouts({from, data: result.data.results}));
            dispatch(setLayoutsCount(result.data.count));
        }
        dispatch(setIsFetchingLayouts(false));
        return result.data.results;
    } catch (err) {
        dispatch(setIsFetchingLayouts(false));
        dispatch(setFetchLayoutsError(getErrorMessage(err)));
        return false;
    }
};

export const saveLayout = ({
    packageId,
    name,
    description = '',
    documents,
    visibility = VISIBILITY_SENDER
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/layouts`;
    const payload = {
        id: packageId,
        type: LAYOUT_TYPE,
        name,
        description,
        documents,
        visibility
    };

    dispatch(setIsSavingLayout(true));
    dispatch(setSaveLayoutError(null));

    try {
        const {data} = await httpClient.post(url, payload);
        dispatch(setIsSavingLayout(false));
        if (data) {
            dispatch(setLayouts({addLayout: true, data}));
        }

        return true;
    } catch (err) {
        dispatch(setIsSavingLayout(false));
        dispatch(setSaveLayoutError('esl.feedback.layout_save.error'));

        return false;
    }
};

export const applyLayout = ({
    packageId,
    documentId,
    layoutId
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}/layout`;

    const options = {
        params: {
            layoutId
        }
    };

    dispatch(setIsApplyingLayout(true));
    dispatch(setApplyLayoutError(null));

    try {
        await httpClient.post(url, null, options);
        await dispatch(fetchDocuments());
        dispatch(setIsApplyingLayout(false));

        return true;
    } catch (err) {
        dispatch(setIsApplyingLayout(false));
        dispatch(setApplyLayoutError(getErrorMessage(err)));

        return false;
    }
};

export const fetchDocumentsVisibility = ({
    headers, baseUrl, params = {}
} = {}) => async (dispatch) => {
    const {
        packageId
    } = params;
    const servicePath = `packages/${packageId}/documents/visibility`;
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {};

    if (headers) {
        options.headers = headers;
    }

    dispatch(setIfFetchingDocumentsVisibility(true));
    dispatch(setFetchDocumentsVisibilityError(null));

    try {
        const result = await httpClient.get(url, options);
        dispatch(setDocumentsVisibility(result.data.configurations));
        dispatch(setIfFetchingDocumentsVisibility(false));
        return result.data.configurations;
    } catch (err) {
        dispatch(setFetchDocumentsVisibilityError(getErrorMessage(err)));
        dispatch(setIfFetchingDocumentsVisibility(false));
        return false;
    }
};

export const saveDocumentsVisibility = ({
    packageId,
    configurations
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/visibility`;

    dispatch(setIfSavingDocumentsVisibility(true));
    dispatch(setSaveDocumentsVisibilityError(null));

    try {
        await httpClient.post(url, {configurations});
        dispatch(setIfSavingDocumentsVisibility(false));
        dispatch(fetchDocumentsVisibility({
            params: {
                packageId
            }
        }));

        return true;
    } catch (err) {
        dispatch(setIfSavingDocumentsVisibility(false));
        dispatch(setSaveDocumentsVisibilityError('esl.feedback.layout_save.error'));

        return false;
    }
};

export const getDefaultActiveDocumentId = (state) => {
    const packageType = get(state, 'designer.packageType');
    return get(state, `${packageType}.${packageType}.documents[0].id`);
};

export const updateHasSignatur = (state) => async (dispatch) => {
    dispatch(setHasPlacedSignature(true));
};

export const deleteSenderFields = (packageData, packageType) => async (dispatch) => {
    const approvals = getAllPackageApprovals(packageData);
    const senderRoleId = packageData.roles.find(({type}) => type === RECIPIENT_TYPES.SENDER).id;
    const senderApprovals = approvals.filter(({role}) => role === senderRoleId);
    const uniqueDocumentsIds = [];

    if (!isEmpty(senderApprovals)) {
        await dispatch(setSelectedInfo({unSelect: true}));
        const deleteApprovalResults = await Promise.all(senderApprovals.map(async (approval) => {
            const result = await dispatch(deleteApproval({
                packageId: packageData.id,
                documentId: approval.documentId,
                approvalId: approval.id
            }));

            if (!uniqueDocumentsIds.includes(approval.documentId)) {
                uniqueDocumentsIds.push(approval.documentId);
            }
            return result;
        }));

        const shouldFetchDocuments = deleteApprovalResults.some((wasSuccessful) => wasSuccessful);
        if (shouldFetchDocuments) {
            if (uniqueDocumentsIds === 1) {
                uniqueDocumentsIds.map(async (id) => {
                    dispatch(fetchDocument(
                        packageData.id,
                        id,
                        packageType
                    ));
                });
            } else {
                dispatch(fetchDocuments(
                    packageType
                ));
            }
        }
    }
};

export const selectSettings = (state) => state.designer.settings;
export const selectCustomFields = (state) => state.designer.customFields;
export const selectFetchDocumentEque = (state) => state.designer.fetchDocumentEque;
export const selectSelectedInfo = (state) => state.designer.selectedInfo;
export const selectReleaseFeatures = (state) => state.designer.releaseFeatures;
export const selectActiveDocument = (state) => {
    if (!state.designer.activeDocument.id) {
        return {
            id: getDefaultActiveDocumentId(state),
            pageIndex: state.designer.activeDocument.pageIndex
        };
    }
    return state.designer.activeDocument || {};
};
export const selectIsFetchingSettings = (state) => state.designer.isFetchingSettings;
export const selectFetchSettingsErrors = (state) => state.designer.fetchSettingsErrors;
export const selectIsSendingToSign = (state) => state.designer.isSendingToSign;
export const selectSendToSignErrors = (state) => state.designer.sendToSignErrors;
export const selectIsUpdatingField = (state) => state.designer.isUpdatingField;
export const selectUpdateApprovalsError = (state) => state.designer.updateApprovalsError;
export const selectIsPreventScroll = (state) => state.designer.isPreventScroll;
export const selectLayouts = (state) => state.designer.layouts;
export const selectLayoutsCount = (state) => state.designer.layoutsCount;
export const selectIsFetchingLayouts = (state) => state.designer.isFetchingLayouts;
export const selectFetchLayoutsError = (state) => state.designer.fetchLayoutsError;
export const selectIsSavingLayout = (state) => state.designer.isSavingLayout;
export const selectSaveLayoutError = (state) => state.designer.saveLayoutError;
export const selectIsApplyingLayout = (state) => state.designer.isApplyingLayout;
export const selectApplyLayoutError = (state) => state.designer.applyLayoutError;
export const selectDocumentsVisibility = (state) => state.designer.documentsVisibility;
export const selectIfFetchingDocumentsVisibility = (state) => state.designer.ifFetchingDocumentsVisibility;
export const selectFetchDocumentsVisibilityError = (state) => state.designer.fetchDocumentsVisibilityError;
export const selectIfSavingDocumentsVisibility = (state) => state.designer.ifSavingDocumentsVisibility;
export const selectSaveDocumentsVisibilityError = (state) => state.designer.saveDocumentsVisibilityError;
export const selectPackageType = (state) => state.designer.packageType;
export const selectUpdateConditonError = (state) => state.designer.updateConditionError;
export const selectHasPlacedSignature = (state) => state.designer.hasPlacedSignature;
export const selectCreateApprovalError = (state) => state.designer.createApprovalError;
export const selectCopiedFieldInfo = (state) => state.designer.copiedFieldInfo;
export const selectDuplicationFieldIdInProgress = (state) => state.designer.duplicationFieldIdInProgress;

export default designerSlice.reducer;
