import React, {createContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchLastTransactions, fetchOverview, fetchSignerTransactions} from './Dashboard/dashboardSlice';
import {fetchTransactions} from './Transactions/transactionsSlice';
import {selectConfig} from './appSlice';
import {BROADCAST_CHANNEL_NAME, BROADCAST_MESSAGES} from '../../constants';

export const BroadcastContext = createContext();

export const BroadcastStation = ({children}) => {
    const dispatch = useDispatch();
    const config = useSelector(selectConfig);
    const [broadcasterChannel, setBroadcasterChannel] = useState();
    function onTransactionUpdate() {
        dispatch(fetchLastTransactions());
        dispatch(fetchTransactions());
        dispatch(fetchOverview());
        dispatch(fetchSignerTransactions());
    }

    function reloadPage() {
        if (window) {
            window.location.reload(true);
        }
    }

    function handleMessage(msg = '') {
        switch (msg) {
            case BROADCAST_MESSAGES.TRANSACTIONS_UPDATED:
                onTransactionUpdate();
                break;
            case BROADCAST_MESSAGES.SUBACCAUNT_CHANGED:
            case BROADCAST_MESSAGES.DELEGATION_CHANGED:
                reloadPage();
                break;
            default:
                break;
        }
    }

    const value = {
        broadcasterChannel,
        handleMessage
    };

    async function initBroadcastChannel() {
        const {BroadcastChannel} = await import('broadcast-channel');
        const newBroadcasterChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME(config.VS_ENV));
        newBroadcasterChannel.onmessage = handleMessage;
        setBroadcasterChannel(newBroadcasterChannel);
    }

    useEffect(() => {
        initBroadcastChannel();

        return () => {
            if (broadcasterChannel && broadcasterChannel.close) {
                broadcasterChannel.close();
            }
        };
    }, []);

    return <BroadcastContext.Provider value={value}>{children}</BroadcastContext.Provider>;
};
