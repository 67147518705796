import isEmpty from 'lodash.isempty';
import CryptoJS from 'crypto-js';
import {getDataOfBase64String} from '@components/AccountSettings/CaptureSignature/helpers';
import {calculateSVGBoundingBox} from '../libs/calculateSVGBoundingBox';
import {convertSignatureDataToPNG} from '../libs/convertSignatureDataToPNG';

const MOVETO_COMMANT = 'M';
const QUADRATIC_BEZIER_CURVE_COMMANT = 'Q';
const CLOSEPATH_COMMANT = 'Z';
const COMMAND_SEPARATOR = ' ';
const PADDING_PIXELS = 4;
const DEFAULT_DATA = {
    lines: [],
    width: 0,
    height: 0,
    options: {},
    color: '#000'
};
const DEFAULT_SIGNATURE_SIZE = 1;

export function getSvgPathFromStroke(stroke) {
    if (!stroke.length) {
        return '';
    }

    const pathCommands = [];

    // Start with the move-to command
    pathCommands.push(`${MOVETO_COMMANT} ${stroke[0][0]} ${stroke[0][1]}`);

    for (let i = 0; i < stroke.length; i += 1) {
        const [x0, y0] = stroke[i];
        const [x1, y1] = stroke[(i + 1) % stroke.length];

        // Calculate the control point for the quadratic Bezier curve
        const cx = (x0 + x1) / 2;
        const cy = (y0 + y1) / 2;

        // Add the quadratic Bezier curve command
        pathCommands.push(`${QUADRATIC_BEZIER_CURVE_COMMANT} ${cx} ${cy} ${x1} ${y1}`);
    }

    // Close the path
    pathCommands.push(CLOSEPATH_COMMANT);

    return pathCommands.join(COMMAND_SEPARATOR);
}

export function getLastPointFromLines(lines) {
    const nonEmptyLines = lines.filter((line) => !isEmpty(line));

    if (!nonEmptyLines.length) {
        return {x: 0, y: 0}; // Default if no lines
    }

    // Get the last line
    const lastLine = nonEmptyLines[nonEmptyLines.length - 1];
    if (!lastLine.length) {
        return {x: 0, y: 0}; // Default if last line has no points
    }

    // Get the last point in the last line
    const lastPoint = lastLine[lastLine.length - 1];
    return {x: lastPoint[0], y: lastPoint[1]};
}

// Function to calculate the bounding box of the signature lines
export function calculateSignatureBoundingBox(lines) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    // Find the bounding box of the signature
    lines.forEach((line) => {
        line.forEach(([x, y]) => {
            if (x < minX) minX = x;
            if (y < minY) minY = y;
            if (x > maxX) maxX = x;
            if (y > maxY) maxY = y;
        });
    });

    return {
        minX, minY, maxX, maxY
    };
}

export function getCenteredSignatureLines({lines, width, height}) {
    const {
        minX, minY, maxX, maxY
    } = calculateSignatureBoundingBox(lines);

    // Calculate the current width and height of the signature
    const signatureWidth = maxX - minX;
    const signatureHeight = maxY - minY;

    // Determine the offset required to center the signature
    const offsetX = (width - signatureWidth) / 2 - minX;
    const offsetY = (height - signatureHeight) / 2 - minY;

    // Adjust each point's x and y coordinates for each line
    const centeredLines = lines.map((line) => line.map(([x, y, pressure]) => [x + offsetX, y + offsetY, pressure]));

    return centeredLines;
}

/**
 * Scales and centers the signature lines to fit inside a target container, optionally adding padding.
 *
 * @param {Object} signatureData - The signature data object containing lines, options, and color.
 * @param {number} width - The target width of the container where the signature will be scaled to fit.
 * @param {number} height - The target height of the container where the signature will be scaled to fit.
 * @param {boolean} shouldAddPadding - A flag indicating whether to add padding to the signature.
 *
 * @returns {Object} - Returns the scaled and centered signature data, including updated lines and options.
 */
export function getScaledSignature({
    signatureData,
    width = 0,
    height = 0,
    shouldAddPadding = true
}) {
    const {lines, options} = signatureData;

    // Early return if no lines are present in the signature
    if (!lines) return {lines: [], options: {}};

    // Calculate the bounding box of the signature lines (find the min/max x and y coordinates)
    const {
        minX, minY, maxX, maxY
    } = calculateSignatureBoundingBox(lines);

    // Compute the actual width and height of the signature based on the bounding box
    const signatureWidth = maxX - minX;
    const signatureHeight = maxY - minY;

    // Calculate the scale factor for padding based on the smallest dimension (width or height)
    const paddingScaleFactor = Math.min(width / signatureWidth, height / signatureHeight);

    // Scale the padding value if padding is needed, otherwise set to 0
    const scaledPadding = shouldAddPadding ? PADDING_PIXELS * paddingScaleFactor : 0;

    // Calculate the available space for the signature, considering padding (if applied)
    const targetWidth = width;// - 2 * scaledPadding;
    const targetHeight = height;// - 2 * scaledPadding;

    // Determine the scaling factor to fit the signature inside the target container
    // while preserving the aspect ratio (using the smaller of the two scale factors)
    const widthScale = targetWidth / signatureWidth;
    const heightScale = targetHeight / signatureHeight;
    const scale = Math.min(widthScale, heightScale); // Maintain aspect ratio

    // Apply the calculated scale factor to each point in the signature lines
    const scaledLines = lines.map((line) => line.map(([x, y, pressure]) => [
        (x - minX) * scale, // Scale and shift the x-coordinate to fit within the new dimensions
        (y - minY) * scale, // Scale and shift the y-coordinate to fit within the new dimensions
        pressure // Keep the pressure value unchanged
    ]));

    // Scale the line size (thickness) and the start/end taper options based on the overall scale factor
    const scaledSize = (options?.size || DEFAULT_SIGNATURE_SIZE) * scale;

    // Ensure the taper values don't go below a minimum threshold (to avoid extremely thin lines)
    const minTaper = 1;
    const scaledStartTaper = Math.max(options.start.taper * scale, minTaper); // Scale the start taper
    const scaledEndTaper = Math.max(options.end.taper * scale, minTaper); // Scale the end taper

    // Center the scaled lines within the new container, taking into account the scaled padding
    const centeredLines = getCenteredSignatureLines({
        lines: scaledLines,
        width: targetWidth,
        height: targetHeight,
        padding: scaledPadding // Pass the scaled padding to ensure lines are centered with padding
    });

    // Return the updated signature data with scaled lines, options, and padding applied (if necessary)
    return {
        ...signatureData,
        lines: centeredLines,
        options: {
            ...options,
            size: scaledSize,
            start: {
                ...options.start,
                taper: scaledStartTaper
            },
            end: {
                ...options.end,
                taper: scaledEndTaper
            }
        }
    };
}

export function encodeSignatureDataToBase64(data) {
    try {
        const jsonString = JSON.stringify(data);
        return btoa(jsonString);
    } catch {
        return '';
    }
}

export function decodeSignatureDataFromBase64(data) {
    try {
        const decodedJsonString = atob(data);
        const parsedData = JSON.parse(decodedJsonString);
        return {...DEFAULT_DATA, ...parsedData};
    } catch {
        return {};
    }
}

function convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export async function exportImage({svgRef, perfectSignatureProps}) {
    if (!perfectSignatureProps) {
        return false;
    }
    // TODO: add an export via an hidden element instead of rendering additional if it creates any issues
    const svgElement = svgRef.current;
    if (!svgElement) {
        console.error('SVG element not found');
        return false;
    }

    const {
        width, height
    } = calculateSVGBoundingBox(svgElement);

    const {
        lines,
        options,
        color,
        checksum
    } = perfectSignatureProps;    

    const signatureData = {
        lines,
        width,
        height,
        options,
        color,
        checksum
    };

    const scaledSignatureData = getScaledSignature({
        signatureData,
        width: width,
        height: height,
        shouldAddPadding: true
    })

    try {
        const pngBlob = await convertSignatureDataToPNG({signatureData: scaledSignatureData});
        const base64String = await convertBlobToBase64(pngBlob);
        const checksum = getChecksumFromBase64(getDataOfBase64String(base64String));
        const signatureDataWithChecksum = {...scaledSignatureData, checksum};
        return {base64String, pngBlob, signatureData: signatureDataWithChecksum};
    } catch (error) {
        console.error('Error during SVG to PNG conversion:', error);
        return false;
    }
}

export function getHasSignatureDataStorred(data) {
    return !!data.lines
        && !!data.width
        && !!data.height
        && !!data.options
        && !!data.color;
}

export function getChecksumFromBase64(base64String) {
    try {
        if (!base64String) {
            return '';
        }
        const binaryString = atob(base64String);
        const hash = CryptoJS.SHA256(binaryString);
        return hash.toString(CryptoJS.enc.Hex);
    } catch {
        return '';
    }
}
