import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import GET_TRANSACTION_OPTIONS from '../../../../gql/getTransactionOptions.gql';
import {selectTransactionId} from '../signerUiSlice';

export default function useTransactionOptions() {
    const transactionId = useSelector(selectTransactionId);
    const {
        data = {},
        loading,
        error
    } = useQuery(GET_TRANSACTION_OPTIONS, {
        skip: !transactionId,
        variables: {transactionId}
    });
    const {ceremony = {}} = data;
    const {
        role = {},
        session = {},
        transaction = {}
    } = ceremony;

    return useMemo(() => ({
        role,
        session,
        transaction,
        loading
    }), [ceremony, transaction, error, loading]);
}
