import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import isEmpty from 'lodash.isempty';
import {REPORTS_PATH, SENDER_REPORT_PATH, TRANSACTION_SUMMARY_STATUSES} from '../../../../../../constants';

export default function Row({senderSummary = {}, startDate, endDate}) {
    const {
        sender = {},
        packages = {}
    } = senderSummary;
    const {
        firstName = '',
        lastName = '',
        id = ''
    } = sender;
    const hasTransactions = !isEmpty(senderSummary.packages);

    function getSummaryUrl() {
        const query = queryString.stringify({
            startDate,
            endDate,
            senderId: id
        });
        return `${REPORTS_PATH}${SENDER_REPORT_PATH}?${query}`;
    }

    return (
        <TableRow className="sender-summary-table-row">
            <TableCell align="left">
                {hasTransactions ? (
                    <Link to={getSummaryUrl()} className="link">
                        {`${firstName} ${lastName}`}
                    </Link>
                ) : (
                    <span className="sender-name">
                        {`${firstName} ${lastName}`}
                    </span>
                )}
            </TableCell>
            {TRANSACTION_SUMMARY_STATUSES.map((status) => (
                <TableCell align="left" key={status}>
                    {packages[status] || 0}
                </TableCell>
            ))}
        </TableRow>
    );
}
