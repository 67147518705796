import React, {useEffect, useState} from 'react';
import qs from 'qs';
import {Helmet} from 'react-helmet';
import isEmpty from 'lodash.isempty';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import {Navigate, useNavigate, useLocation} from 'react-router';
import EmailForm from '../LoginForms/EmailForm/EmailForm';
import ChangePassword from '../../AccountSettings/ChangePassword/ChangePassword';
import {
    DASHBOARD_PATH,
    DEFAULT_PASSWORD_POLICY,
    LOGIN_PATH,
    TERMS_AND_CONDITIONS_LINK
} from '../../../../constants';
import useErrorNotification from '../../../hooks/useErrorNotification';
import useJoinMessage from '../../../hooks/useJoinMessage';
import Loading from '../../shared/Loading/Loading';
import useNotifications from '../../../hooks/useNotifications';
import {
    activateUser,
    clearCookie,
    fetchPasswordPolicy,
    fetchSessionInfo,
    getActivationInfo,
    logout,
    selectActivatingUserError,
    selectIsActivatingUser,
    selectIsFetchingUserActivationInfo,
    selectUserActivationInfoError
} from '../userSlice';
import './registration.less';
import AppInput from '../../Core/inputs/AppInput/AppInput';

export default function Registration() {
    const intl = useIntl();
    const location = useLocation();
    const {showSuccessAlert} = useNotifications();
    const joinMessage = useJoinMessage();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isFetchingUserActivationInfo = useSelector(selectIsFetchingUserActivationInfo);
    const userActivationInfoError = useSelector(selectUserActivationInfoError);
    const isActivatingUser = useSelector(selectIsActivatingUser);
    const activatingUserError = useSelector(selectActivatingUserError);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [passwordPolicy, setPasswordPolicy] = useState({});
    const [form, setForm] = useState({
        email: null,
        firstName: null,
        lastName: null,
        newPassword: null,
        confirmedPassword: null,
        terms: false
    });
    const [token, setToken] = useState(null);
    const isLoading = isFetchingUserActivationInfo || isActivatingUser;
    const allFieldsFilled = Object.values(form).every((val) => !!val);
    const isValidForm = isValidPassword && allFieldsFilled;

    useErrorNotification(userActivationInfoError);
    useErrorNotification(activatingUserError);

    function changeForm({target}) {
        setForm({
            ...form,
            [target.name]: target.value
        });
    }

    function changeCheckbox({target}) {
        setForm({
            ...form,
            [target.name]: target.checked
        });
    }

    async function submit(e) {
        e.preventDefault();
        await dispatch(clearCookie());
        await dispatch(logout());
        const wasSuccessfull = await dispatch(activateUser({
            token,
            form: {
                email: form.email,
                firstName: form.firstName,
                lastName: form.lastName,
                password: form.newPassword,
                confirmPassword: form.confirmedPassword
            }
        }));
        if (wasSuccessfull) {
            const res = await dispatch(fetchSessionInfo());
            if (res) {
                navigate(DASHBOARD_PATH);
                showSuccessAlert(intl.formatMessage({id: 'esl.feedback.signup.success', defaultMessage: ''}));
            } else {
                showSuccessAlert(intl.formatMessage({id: 'esl.error.internal.default', defaultMessage: ''}));
            }
        }
    }

    async function getUserInfo(activationToken) {
        const data = await dispatch(getActivationInfo(activationToken));
        const policyData = await dispatch(fetchPasswordPolicy(activationToken, 'signupToken'));
        setPasswordPolicy(policyData);
        if (data && !isEmpty(data)) {
            const {
                email, firstName, lastName, newPassword, password
            } = data;
            setForm({
                ...form,
                email,
                firstName,
                lastName,
                newPassword,
                confirmedPassword: password
            });
        }
    }

    useEffect(() => {
        const activationToken = qs.parse(location.search, {ignoreQueryPrefix: true})?.token;
        setToken(activationToken);
        getUserInfo(activationToken);
    }, [location.search]);

    const termsAndConditionsLink = (
        <a
            href={TERMS_AND_CONDITIONS_LINK}
            rel="noopener noreferrer"
            target="_blank"
            className="link"
        >
            {intl.formatMessage({id: 'esl.footer.read_terms_and_conditions', defaultMessage: ''})}
        </a>
    );

    function renderForm() {
        return (
            <form className="registration-form login-forms" onSubmit={submit}>
                <h1 className="title">
                    {intl.formatMessage({id: 'esl.generic.account_registration', defaultMessage: ''})}
                </h1>
                <EmailForm
                    email={form.email}
                    onLoginFormChange={changeForm}
                    disabled
                />
                <AppInput
                    label={intl.formatMessage({id: 'esl.generic.first_name', defaultMessage: ''})}
                    id="firstName"
                    onChange={changeForm}
                    value={form.firstName}
                    name="firstName"
                    placeholder={intl.formatMessage({id: 'esl.generic.first_name', defaultMessage: ''})}
                    required
                />
                <AppInput
                    label={intl.formatMessage({id: 'esl.generic.last_name', defaultMessage: ''})}
                    id="lastName"
                    onChange={changeForm}
                    value={form.lastName}
                    name="lastName"
                    placeholder={intl.formatMessage({id: 'esl.generic.last_name', defaultMessage: ''})}
                    required
                />
                <ChangePassword
                    isRegistration
                    passwordPolicy={isEmpty(passwordPolicy) ? DEFAULT_PASSWORD_POLICY : passwordPolicy}
                    onChange={changeForm}
                    setIsValidPassword={setIsValidPassword}
                    registration
                />
                <div className="terms-and-conditions">
                    <Checkbox
                        checked={form.terms}
                        onChange={changeCheckbox}
                        color="primary"
                        size="small"
                        inputProps={{
                            'aria-label': joinMessage('esl.signup.i_agree_terms_and_conds', 'esl.footer.read_terms_and_conditions'),
                            name: 'terms'
                        }}
                    />
                    <div className="link-container">
                        {intl.formatMessage(
                            {id: 'esl.signup.i_agree_terms_and_conds', defaultMessage: ''},
                            {term: termsAndConditionsLink}
                        )}
                    </div>
                </div>
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    classes={{root: 'primary-button'}}
                    disabled={!isValidForm || isLoading}
                >
                    {intl.formatMessage({id: 'esl.generic.submit', defaultMessage: ''})}
                </Button>
            </form>
        );
    }

    if (userActivationInfoError === 'esl.error.validation.invalidToken.userAlreadyRegistered') {
        return <Navigate to={LOGIN_PATH} />;
    }
    return (
        <div className="registration">
            <Loading isLoading={isLoading} />
            <Helmet><title>{joinMessage('esl.generic.account_registration', '-', 'page.title')}</title></Helmet>
            <div className="container">
                {renderForm()}
            </div>
        </div>
    );
}
