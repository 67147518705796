import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import ConfirmationModal from '../../../shared/ConfirmationModal/ConfirmationModal';
import {useResendSms} from '../../hooks/mutations';
import {selectAuthObject, selectTransactionId} from '../../signerUiSlice';
import useNotifications from '../../../../hooks/useNotifications';
import AppInput from '../../../Core/inputs/AppInput/AppInput';
import {
    getAuthTypeObjectToSend, getPathcodeErrorMessageKey
} from '../utils';
import {SectionWithTitle} from '../../../Core/company/layouts/SectionWithTitle/SectionWithTitle';
import {AppButton} from '../../../Core/buttons/AppButton/AppButton';
import {AppOutlinedButton} from '../../../Core/buttons/AppOutlinedButton/AppOutlinedButton';
import './smsLogin.less';

export default function SmsLogin({authenticate}) {
    const intl = useIntl();
    const {showSuccessAlert} = useNotifications();
    const transactionId = useSelector(selectTransactionId);
    const authObject = useSelector(selectAuthObject);
    const [wasChanged, setWasChanged] = useState(false);
    const {
        loginToken,
        authenticationUrl,
        sms: {
            passcode: storedPasscode,
            phoneNumberSuffix
        }
    } = authObject;
    const [passcode, setPasscode] = useState(storedPasscode || '');
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const pathcodeErrorMessageKey = getPathcodeErrorMessageKey({passcode, formatMessage: intl.formatMessage});
    const hasError = !!pathcodeErrorMessageKey;

    const {resendSms} = useResendSms({
        onCompleted: () => {
            showSuccessAlert(intl.formatMessage({id: 'oss.components.SmsLoginView.notificationMessage', defaultMessage: ''}));
        }
    });

    function openConfirmation() {
        setIsConfirmationModalOpen(true);
    }

    function reSendCode() {
        resendSms({variables: {loginToken}});
    }

    function login(e) {
        e.preventDefault();
        const updatedAuthObject = {
            sms: {passcode}
        };
        const authTypeObjectToSend = getAuthTypeObjectToSend({authObject, updatedAuthObject});
        authenticate({
            variables: {
                loginToken,
                authenticationUrl,
                authObject: authTypeObjectToSend,
                transactionId
            }
        });
    }

    function onPasscodeChange({target}) {
        setPasscode(target.value);
    }

    function onBlur() {
        setWasChanged(true);
    }

    return (
        <SectionWithTitle
            className="signer-login-section sms-login"
            title={intl.formatMessage({id: 'oss.components.SmsLoginView.signerLogin', defaultMessage: ''})}
        >
            <>
                <form onSubmit={login}>
                    <div className="signer-login-section-description">
                        {intl.formatMessage({id: 'oss.components.SmsLoginView.instruction', defaultMessage: ''}, {
                            phoneNumberSuffix
                        })}
                    </div>
                    <AppInput
                        value={passcode}
                        onChange={onPasscodeChange}
                        onBlur={onBlur}
                        error={wasChanged && hasError}
                        helperText={wasChanged && pathcodeErrorMessageKey}
                    />
                    <div className="signer-login-actions">
                        <AppOutlinedButton
                            size="small"
                            onClick={openConfirmation}
                            className="re-send-btn"
                            data-wdio="test-btn-new-passcode"
                        >
                            {intl.formatMessage({id: 'oss.components.SmsAffidavitDialog.newPasscode.button', defaultMessage: ''})}
                        </AppOutlinedButton>
                        <AppButton
                            type="submit"
                            onClick={login}
                            className="authenticate-btn"
                            disabled={hasError}
                            data-wdio="test-btn-login"

                        >
                            {intl.formatMessage({id: 'oss.components.SmsLoginView.login', defaultMessage: ''})}
                        </AppButton>
                    </div>
                </form>
                <ConfirmationModal
                    isOpen={isConfirmationModalOpen}
                    titleKey="oss.components.SmsLoginView.ResendDialog.dialogTitle"
                    descriptionKeys={['oss.components.SmsLoginView.ResendDialog.dialogContent']}
                    noBtnKey="oss.components.SmsLoginView.ResendDialog.cancelButton"
                    yesBtnKey="oss.components.SmsLoginView.ResendDialog.resendButton"
                    setIsOpen={setIsConfirmationModalOpen}
                    onConfirm={reSendCode}
                />
            </>
        </SectionWithTitle>
    );
}
