import {useIntl, createIntl} from 'react-intl';
import DOMPurify from 'isomorphic-dompurify';
import {marked} from 'marked';

export default function useMarkedMessage() {
    const intl = useIntl();

    return ({
        key = '', options = {}, markedOptions = {}, beforeFormat
    }) => {
        let result;
        if (beforeFormat) {
            const message = beforeFormat(intl.messages[key] || '');
            const localIntl = createIntl({
                locale: intl.locale,
                messages: {message}
            });
            const intlMessage = localIntl.formatMessage({id: 'message', defaultMessage: message}, options);
            result = marked.parse(intlMessage, markedOptions);
        } else {
            const intlMessage = intl.formatMessage({id: key, defaultMessage: ''}, options);
            result = marked.parse(intlMessage, markedOptions);
        }

        return DOMPurify.sanitize(result);
    };
}
