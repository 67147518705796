import React from 'react';
import classnames from 'classnames';
import AppLabel from '../../../labels/BaseLabel/BaseLabel';
import './borderedTextLabel.less';

export const BorderedTextLabel = ({children, className, ...rest}) => {
    const containerClassName = classnames('bordered-text-label', className);

    return (
        <AppLabel className={containerClassName} {...rest}>{children}</AppLabel>
    );
};
