import {gql, useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectTransactionId} from '../signerUiSlice';

export default function useSignatureImage({skip}) {
    const transactionId = useSelector(selectTransactionId);

    const GET_SIGNATURE_IMAGE = gql`
        query getImage($transactionId: ID!) {
            getImage(transactionId: $transactionId) {
            fileName
            mediaType
            content
            }
        }
    `;
    const {data: {getImage} = {}} = useQuery(GET_SIGNATURE_IMAGE, {
        variables: {
            transactionId
        },
        skip
    });
    const signatureImage = getImage && getImage.content
        ? {
            type: `data:${getImage.mediaType};base64`,
            value: getImage.content
        }
        : null;

    return useMemo(() => ({signatureImage}), [signatureImage?.value]);
}
