import React, {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import LinearProgress from '@material-ui/core/LinearProgress';
import {MdBorderColor as PencilIcon} from '@react-icons/all-files/md/MdBorderColor';
import {MdCheckCircle as DoneIcon} from '@react-icons/all-files/md/MdCheckCircle';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash.get';
import {HiUpload} from '@react-icons/all-files/hi/HiUpload';
import {HiOutlineCheck} from '@react-icons/all-files/hi/HiOutlineCheck';
import {HiOutlineChevronDoubleRight} from 'react-icons/hi';
import {
    getNextFieldInfo,
    hasSomeSignerField,
    isStickyButtonAvailable
} from '../utils';
import {
    getDocumentStatus,
    getHasPendingRequiredUpload,
    getIsTurnToSign,
    isSignerNeedToAcceptOnlyDocument,
    isSigningComplete,
    isTransactionCompleted,
    setAutoredirectIfAllowed
} from '../../utils/selectors';
import {useConfirmDocument} from '../../hooks/mutations';
import {
    openNextPendingUpload,
    goToSummaryPage,
    selectSelectedDocumentId,
    setSelectedDocumentId,
    doneThankYou,
    setIsPCCOpen,
    selectIsInterfaceHidden
} from '../../signerUiSlice';
import ConfirmationModal from '../../../shared/ConfirmationModal/ConfirmationModal';
import useAttachmentFiles from '../../hooks/useAttachmentFiles';
import {useScrollContext} from '../../../shared/Documents/ScrollContext';
import {AppButton} from '../../../Core/buttons/AppButton/AppButton';
import {useCeremony} from '../../hooks/queries';
import useInPerson from '../../hooks/useInPerson';
import './nextButton.less';

const NextButton = ({
    pages,
    fieldsRef,
    disabled,
    isNoInterface
}) => {
    const {transaction, role} = useCeremony();
    const {canSign, verificationType} = role;
    const {canSignByAny} = transaction;
    const optionalNavigation = get(transaction, 'settings.ceremony.optionalNavigation');
    const documents = get(transaction, 'documents', []);
    const showNavigator = get(transaction, 'settings.ceremony.layout.showNavigator');
    const documentId = useSelector(selectSelectedDocumentId);
    const isInterfaceHidden = useSelector(selectIsInterfaceHidden);
    const document = documents.find(({id}) => id === documentId) || {};
    const canSignDocument = useMemo(() => (getIsTurnToSign(documents, documentId) && canSign), [
        canSign,
        canSignByAny,
        documentId,
        documents
    ]);
    const {scrollIntoViewByFieldWithPage} = useScrollContext();
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const {isInPersonSigning, inPersonCeremonyHost} = useInPerson();
    const {
        role: {
            attachmentRequirements: attachments = []
        } = {}
    } = useAttachmentFiles();
    const {
        requiredActionsTotalCount,
        requiredCompletedActionsCount,
        allActionsTotalCount,
        optionalActionsTotalCount,
        optionalApprovalsCount,
        optionalCompletedApprovalsCount,
        optionalCompletedActionsCount
    } = getDocumentStatus(document);
    const [isShowPopupToContinueWithOptionalSignatures, setIsShowPopupToContinueWithOptionalSignatures] = useState(false);
    const requiredActionsPersentageProgress = ((requiredCompletedActionsCount / requiredActionsTotalCount) * 100) || 0;
    const isRequiredActionsDone = requiredActionsTotalCount === requiredCompletedActionsCount;
    const hasOnlyOptionalActions = allActionsTotalCount > 0 && allActionsTotalCount === optionalActionsTotalCount;
    const hasOpenOptionalActions = optionalApprovalsCount > 0 && optionalApprovalsCount > optionalCompletedApprovalsCount;
    const [currentFocusableFieldIndex, setCurrentFocusableFieldIndex] = useState(-1);
    const areAllDocumentsConfirmed = documents.every((doc) => doc.isConfirmed || doc.isReview);
    const {
        nextFieldNode,
        nextIndex,
        nextFieldWithPage
    } = getNextFieldInfo({fieldsRef, currentFocusableFieldIndex, shouldIncludeOptional: optionalNavigation});
    const hasPendingUpload = getHasPendingRequiredUpload({attachments});
    const containerClassName = classNames('next-action-container', {
        'is-all-done': !hasPendingUpload && (isRequiredActionsDone || document.isConfirmed),
        'is-no-interface': isNoInterface,
        'is-interface-hidden': isInterfaceHidden,
        'has-pending-upload': hasPendingUpload && document.isConfirmed
    });
    function goToDocumentByIndex(index) {
        dispatch(setSelectedDocumentId(documents[index].id));
    }
    function goToNextDocument() {
        const currentDocumentIndex = documents.findIndex(({id}) => id === document.id);
        const nextDocumentIndex = currentDocumentIndex + 1 <= documents.length - 1 ? currentDocumentIndex + 1 : 0;
        goToDocumentByIndex(nextDocumentIndex);
    }
    /** This function should executes only after user finished signing */
    function finishSigningTransaction({transaction: transactionData, disableAutoRedirect}) {
        if (!disableAutoRedirect) {
            setAutoredirectIfAllowed();
        }
        if (!hasPendingUpload) {
            if (isInPersonSigning) {
                dispatch(doneThankYou({
                    role,
                    roles: transaction.roles,
                    isTransactionCompleted: isTransactionCompleted(transactionData),
                    inPersonCeremonyHost
                }));
            } else {
                dispatch(goToSummaryPage({}));
            }
        }
    }

    function openContinueWithOptionalSignaturesPopup() {
        setIsShowPopupToContinueWithOptionalSignatures(true);
    }
    // useAutomaticESVConfirmation()
    const {handleConfirm, loading} = useConfirmDocument({document});

    async function onConfirm() {
        const isPersonalCertificateRequired = verificationType && verificationType.method === 'CERTIFICATE'
            && verificationType.id === 'personalCertificateSigning';
        if (isPersonalCertificateRequired) {
            return dispatch(setIsPCCOpen(true));
        }

        const ceremonyRes = await handleConfirm();

        if (ceremonyRes) {
            const updatedCeremony = get(ceremonyRes, 'data.ceremony', {});
            const currentRoleHasSigned = isSigningComplete(updatedCeremony?.transaction?.documents);
            if (currentRoleHasSigned) {
                return finishSigningTransaction(updatedCeremony);
            }
            if (!document.isLast) {
                goToNextDocument();
            }
        }
    }

    function triggerConfirm() {
        if (hasOpenOptionalActions) {
            return openContinueWithOptionalSignaturesPopup();
        }
        onConfirm();
    }

    function changeFocusOnField(node) {
        node.focus();
    }

    function goToNextField() {
        if (nextFieldNode) {
            changeFocusOnField(nextFieldNode);
        }

        setCurrentFocusableFieldIndex(nextIndex);
        scrollIntoViewByFieldWithPage({field: nextFieldWithPage});
    }

    function openNextUpload() {
        dispatch(openNextPendingUpload(attachments));
    }

    useEffect(() => {
        setCurrentFocusableFieldIndex(-1);
    }, []);

    const remainingActionsCounter = ({className = ''} = {}) => (
        <div className={`remaining-actions-counter ${className}`}>
            {formatMessage({id: 'oss.components.FeedbackBar.requiredActions'}, {
                total: requiredActionsTotalCount,
                count: requiredCompletedActionsCount
            })}
            <LinearProgress
                variant="determinate"
                value={requiredActionsPersentageProgress}
                classes={{
                    root: 'signing-progress'
                }}
            />
        </div>
    );

    const nextUpload = {
        message: (
            <p className="go-to-uploads-msg">
                {formatMessage({id: 'oss.components.ConfirmBar.document.pendingUploads.text', defaultMessage: ''})}
            </p>
        ),
        btn: (
            <AppButton
                onClick={openNextUpload}
                className="go-to-uploads action-button"
            >
                {formatMessage({id: 'oss.components.AttachmentBottomBar.nextUpload', defaultMessage: ''})}
            </AppButton>
        ),
        noInterfaceBtn: (
            <AppButton
                className="no-interface-action-button action-button"
                onClick={openNextUpload}
            >
                <HiUpload className="action-icon" />
                <span className="action-name">
                    {formatMessage({id: 'oss.components.AttachmentBottomBar.nextUpload', defaultMessage: ''})}
                </span>
            </AppButton>
        )
    };
    const continueFlow = {
        message: formatMessage({id: 'oss.components.ConfirmBar.document.signing.confirmWithoutSaving', defaultMessage: ''}),
        btn: (
            <AppButton
                onClick={() => setIsShowPopupToContinueWithOptionalSignatures(true)}
                className="action-button"
                disabled={disabled}
            >
                {formatMessage({id: 'oss.generic.continue', defaultMessage: ''})}
            </AppButton>
        ),
        noInterfaceBtn: (
            <AppButton
                className="no-interface-action-button action-button"
                onClick={() => setIsShowPopupToContinueWithOptionalSignatures(true)}
            >
                <HiOutlineCheck className="action-icon" />
                <span className="action-name">
                    {formatMessage({id: 'oss.generic.continue', defaultMessage: ''})}
                </span>
            </AppButton>
        )
    };

    const nextDocument = {
        message: formatMessage({id: 'oss.components.ConfirmBar.document.next.text', defaultMessage: ''}),
        btn: (
            <AppButton
                onClick={goToNextDocument}
                className="action-button"
                disabled={disabled}
            >
                {formatMessage({id: 'oss.components.ConfirmBar.next', defaultMessage: ''})}
            </AppButton>
        ),
        noInterfaceBtn: (
            <AppButton
                className="no-interface-action-button action-button"
                onClick={goToNextDocument}
            >
                <HiOutlineChevronDoubleRight className="action-icon" />
                <span className="action-name">
                    {formatMessage({id: 'oss.components.ConfirmBar.next', defaultMessage: ''})}
                </span>
            </AppButton>
        )
    };

    const confirmDocument = {
        message: formatMessage({id: 'oss.components.ConfirmBar.document.signing.text', defaultMessage: ''}),
        btn: (
            <AppButton
                onClick={triggerConfirm}
                className="action-button"
                disabled={disabled || loading}
                data-wdio="test-next-btn-confirm-document"
            >
                <DoneIcon aria-hidden className="icon done-icon" />
                {formatMessage({id: 'oss.components.ConfirmBar.confirm', defaultMessage: ''})}
            </AppButton>
        ),
        noInterfaceBtn: (
            <AppButton
                className="no-interface-action-button action-button"
                onClick={triggerConfirm}
                disabled={disabled || loading}
            >
                <HiOutlineCheck className="action-icon" />
                <span className="action-name">
                    {formatMessage({id: 'oss.components.ConfirmBar.confirm', defaultMessage: ''})}
                </span>
            </AppButton>
        )
    };

    const finishFlow = {
        message: formatMessage({id: 'oss.components.ConfirmBar.document.completed.text', defaultMessage: ''}),
        btn: (
            <AppButton
                onClick={() => finishSigningTransaction({transaction, disableAutoRedirect: true})}
                className="action-button"
                disabled={disabled}
                data-wdio="test-next-btn-finish"

            >
                <DoneIcon aria-hidden className="icon done-icon" />
                {formatMessage({id: 'oss.components.ConfirmBar.finished', defaultMessage: ''})}
            </AppButton>
        ),
        noInterfaceBtn: (
            <AppButton
                className="no-interface-action-button action-button"
                onClick={() => finishSigningTransaction({transaction})}
            >
                <HiOutlineCheck className="action-icon" />
                <span className="action-name">
                    {formatMessage({id: 'oss.components.ConfirmBar.finished', defaultMessage: ''})}
                </span>
            </AppButton>
        )
    };
    const acceptDocumentField = {
        message: formatMessage({id: 'oss.components.ConfirmBar.document.nonConsentDisclosure.text', defaultMessage: ''}),
        btn: (
            <AppButton
                onClick={onConfirm}
                className="action-button"
                disabled={loading}
            >
                {formatMessage({id: 'oss.components.ConfirmBar.accept', defaultMessage: ''})}
            </AppButton>
        ),
        noInterfaceBtn: (
            <AppButton
                className="no-interface-action-button action-button"
                onClick={onConfirm}
                disabled={loading}
            >
                <HiOutlineCheck className="action-icon" />
                <span className="action-name">
                    {formatMessage({id: 'oss.components.ConfirmBar.accept', defaultMessage: ''})}
                </span>
            </AppButton>
        )
    };

    const nextField = {
        message: remainingActionsCounter(),
        btn: (
            <AppButton
                onClick={goToNextField}
                className="action-button"
                disabled={disabled}
                data-wdio="test-next-btn-next-field"
            >
                <PencilIcon aria-hidden className="icon next-icon" />
                {formatMessage({id: 'oss.components.ConfirmBar.next', defaultMessage: ''})}
            </AppButton>
        ),
        noInterfaceBtn: (
            <AppButton
                className="no-interface-action-button action-button"
                onClick={goToNextField}
            >
                <PencilIcon className="action-icon" />
                <span className="action-name">
                    {formatMessage({id: 'oss.components.ConfirmBar.next', defaultMessage: ''})}
                </span>
            </AppButton>
        )
    };
    const cantSignContent = {
        message: formatMessage({id: 'oss.components.FeedbackBar.signOtherDocumentBeforeThis', defaultMessage: ''})
    };

    function renderContent() {
        if (!document.isConfirmed && isSignerNeedToAcceptOnlyDocument(document.signerApprovals)) return acceptDocumentField;
        if (hasPendingUpload && document.isConfirmed) return nextUpload;
        if (areAllDocumentsConfirmed && document.isLast) return finishFlow;
        if (document.isConfirmed || document.isReview) return nextDocument;
        if (hasOnlyOptionalActions && optionalCompletedActionsCount === 0) return continueFlow;
        if (isRequiredActionsDone || (hasOnlyOptionalActions && optionalCompletedActionsCount > 0)) {
            return confirmDocument;
        }
        if (!canSignDocument) return cantSignContent;
        if (canSignDocument && isStickyButtonAvailable(pages) && hasSomeSignerField(pages)) return nextField;
    }

    const content = renderContent();

    if (!content || !showNavigator) return null;
    // TODO: isNoInterface prop usage in conditions should be reviewed because it always renders components
    return (
        <div className={containerClassName}>
            {!isNoInterface && content?.message && content.message}
            {!isNoInterface && content?.btn && content.btn}
            {isNoInterface && content.noInterfaceBtn}
            <ConfirmationModal
                titleKey="oss.components.ConfirmBar.confirm"
                descriptionKeys={['oss.components.ConfirmBar.dialog.unsignedOptionalSignatures.content']}
                onConfirm={onConfirm}
                isOpen={isShowPopupToContinueWithOptionalSignatures}
                setIsOpen={setIsShowPopupToContinueWithOptionalSignatures}
            />
        </div>
    );
};

export default NextButton;
