import React from 'react';

export default function UnknownIdpIcon(props) {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
            {...props}
        >
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            >
                <path d="M2540 5114 c-245 -31 -453 -108 -654 -241 -135 -89 -299 -255 -386
                    -388 l-64 -100 -166 -1 c-150 -1 -176 -4 -282 -32 -442 -115 -786 -455 -903
                    -893 -196 -734 306 -1474 1065 -1568 46 -6 257 -11 467 -11 l383 0 0 104 0
                    104 -38 23 c-302 183 -510 521 -542 879 -48 558 308 1059 858 1208 88 24 114
                    26 282 26 168 0 194 -2 282 -26 208 -57 375 -153 524 -302 243 -242 363 -569
                    334 -906 -32 -358 -240 -696 -542 -879 l-38 -23 0 -104 0 -104 478 0 c365 0
                    499 4 572 15 639 97 1044 730 864 1353 -112 388 -448 684 -852 750 l-104 17
                    -23 80 c-145 498 -565 885 -1083 995 -95 21 -349 34 -432 24z"
                />
                <path d="M2385 3916 c-426 -98 -711 -490 -664 -913 34 -311 209 -549 507 -692
                    l72 -35 0 -284 0 -284 120 -143 c66 -78 120 -146 120 -150 0 -4 -54 -67 -120
                    -140 -66 -74 -120 -141 -120 -149 0 -9 52 -78 116 -154 64 -75 119 -142 121
                    -149 3 -7 -50 -72 -116 -146 l-121 -134 0 -184 1 -184 130 -88 129 -88 130 88
                    129 88 1 1051 0 1051 63 28 c208 93 373 259 456 460 44 106 63 201 63 325 1
                    366 -229 682 -582 801 -86 29 -109 33 -235 35 -94 3 -160 -1 -200 -10z m274
                    -474 c147 -68 172 -276 46 -380 -174 -142 -433 33 -361 244 45 134 188 195
                    315 136z"
                />
            </g>
        </svg>
    );
}
