import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import httpClient from '../../utils/httpClient';
import {fetchSessionInfo, setUser} from '../Login/userSlice';
import {BASE_PATH} from '../../../constants';
import {setIntroductionInfo} from '../appSlice';
import {getErrorMessage} from '../../utils/requests/error';

export const accountSettingsSlice = createSlice({
    name: 'accountSettings',
    initialState: {
        isAvatarSaving: false,
        avatarSavingError: null,
        timezones: [],
        isPersonalInfoSaving: false,
        personalInfoSaveError: null,
        subaccounts: [],
        accessibleSubaccounts: []
    },
    reducers: {
        setIsAvatarSaving: (state, action) => {
            state.isAvatarSaving = action.payload;
        },
        setAvatarSavingError: (state, action) => {
            state.avatarSavingError = action.payload;
        },
        setTimezones: (state, action) => {
            state.timezones = action.payload;
        },
        setIsPersonalInfoSaving: (state, action) => {
            state.isPersonalInfoSaving = action.payload;
        },
        setPersonalInfoSaveError: (state, action) => {
            state.personalInfoSaveError = action.payload;
        },
        setSubaccounts: (state, action) => {
            state.subaccounts = action.payload;
        },
        setAccessibleSubaccounts: (state, action) => {
            state.accessibleSubaccounts = action.payload;
        }
    }
});

export const {
    setIsAvatarSaving,
    setAvatarSavingError,
    setTimezones,
    setIsPersonalInfoSaving,
    setPersonalInfoSaveError,
    setSubaccounts,
    setAccessibleSubaccounts
} = accountSettingsSlice.actions;

export const saveAvatar = ({
    avatar
}) => async (dispatch) => {
    dispatch(setIsAvatarSaving(true));
    dispatch(setAvatarSavingError(null));

    try {
        const result = await httpClient.post(`${BASE_PATH}/avatar`, {
            avatar
        });
        dispatch(setUser(result.data));
        dispatch(setIsAvatarSaving(false));
        dispatch(setAvatarSavingError(null));
        return result.data;
    } catch (err) {
        dispatch(setIsAvatarSaving(false));
        dispatch(setAvatarSavingError(getErrorMessage(err)));
        return false;
    }
};

export const removeAvatar = () => async (dispatch) => {
    dispatch(setIsAvatarSaving(true));
    dispatch(setAvatarSavingError(null));

    try {
        const result = await httpClient.delete(`${BASE_PATH}/avatar`);
        dispatch(setUser(result.data));
        dispatch(setIsAvatarSaving(false));
        dispatch(setAvatarSavingError(null));
        return result.data;
    } catch (err) {
        dispatch(setIsAvatarSaving(false));
        dispatch(setAvatarSavingError(getErrorMessage(err)));
        return false;
    }
};

export const fetchTimezones = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/eslTimeZones`;

    try {
        const result = await httpClient.get(url);
        dispatch(setTimezones(get(result, 'data.results', [])));
        return true;
    } catch (err) {
        return false;
    }
};

export const saveUser = (data) => async (dispatch, getState) => {
    const {user} = getState();

    dispatch(setIsPersonalInfoSaving(true));
    dispatch(setPersonalInfoSaveError(null));

    try {
        await httpClient.post(`${BASE_PATH}/proxy/account/senders/${user.user.id}`, data);

        dispatch(fetchSessionInfo());
        dispatch(setIsPersonalInfoSaving(false));
        dispatch(setPersonalInfoSaveError(null));
        return true;
    } catch (err) {
        dispatch(setIsPersonalInfoSaving(false));
        dispatch(setPersonalInfoSaveError(getErrorMessage(err)));
        return false;
    }
};

export const startIntroduction = () => async (dispatch, getState) => {
    const {user} = getState();

    const wasSuccessful = await dispatch(saveUser({
        data: {
            ...user.data,
            showIntro: true
        }
    }));
    return wasSuccessful;
};

export const finishIntroduction = () => async (dispatch, getState) => {
    const {user} = getState();

    const wasSuccessful = await dispatch(saveUser({
        data: {
            ...user.data,
            showIntro: false
        }
    }));
    if (wasSuccessful) {
        dispatch(setIntroductionInfo({
            stepIndex: null,
            validation: {
                transactionId: null
            }
        }));
    }
    return wasSuccessful;
};

export const fetchSubaccounts = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/subaccounts`;

    try {
        const result = await httpClient.get(url);
        dispatch(setSubaccounts(get(result, 'data', [])));
        return result.data;
    } catch (err) {
        return false;
    }
};

export const fetchAccessibleSubaccounts = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/accessibleaccounts`;
    try {
        const result = await httpClient.get(url);
        dispatch(setAccessibleSubaccounts(get(result, 'data', [])));

        return result.data;
    } catch (err) {
        return false;
    }
};

export const changeSubaccountId = (subaccountId) => async () => {
    const url = `${BASE_PATH}/proxy/session/activeAccount`;
    const options = {
        headers: {'content-type': 'application/text'}
    };

    try {
        await httpClient.post(url, subaccountId, options);
        return true;
    } catch (err) {
        return false;
    }
};

export const selectIsAvatarSaving = (state) => state.accountSettings.isAvatarSaving;
export const selectAvatarSavingError = (state) => state.accountSettings.avatarSavingError;
export const selectTimezones = (state) => state.accountSettings.timezones;
export const selectIsPersonalInfoSaving = (state) => state.accountSettings.isPersonalInfoSaving;
export const selectPersonalInfoSaveError = (state) => state.accountSettings.personalInfoSaveError;
export const selectSubaccounts = (state) => state.accountSettings.subaccounts;
export const selectAccessibleAccounts = (state) => state.accountSettings.accessibleSubaccounts;

export default accountSettingsSlice.reducer;
