import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {GET_SIGNING_LOGO} from '../../../../../gql/queries';
import {selectAuthObject, selectTransactionId} from '../../signerUiSlice';
import {validateAndGetAvailableLocale} from '../../utils/selectors';
import useCeremony from './useCeremony';

export default function useSigningLogo() {
    const transactionId = useSelector(selectTransactionId);
    const authObject = useSelector(selectAuthObject);
    const signerDataLanguage = authObject?.signerData?.language;
    /**
     * Existing of signerData means this is Signer UI Login (no sessionId).
     * We have to skip ceremony call, to prevent redirection to logout url
     */
    const {
        role = {},
        session = {},
        transaction = {}
    } = useCeremony({skip: signerDataLanguage});
    const unvalidatedLocale = (role.hasLocalLanguage && role.localLanguage)
        || session.user?.language
        || transaction.language
        || signerDataLanguage;
    const locale = validateAndGetAvailableLocale(unvalidatedLocale);
    const {data: {signingLogo = {}} = {}, error, loading} = useQuery(GET_SIGNING_LOGO, {
        skip: !locale || !transactionId,
        variables: {locale, transactionId}
    });
    const {image} = signingLogo;

    return useMemo(() => ({
        image,
        error,
        loading
    }), [signingLogo, locale, error, loading]);
}
