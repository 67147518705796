import {
    useEffect, useRef, useState, useCallback
} from 'react';
import getStroke from 'perfect-freehand';
import isEmpty from 'lodash.isempty';
import classNames from 'classnames';
import {
    getScaledSignature,
    getSvgPathFromStroke
} from '@components/Core/components/PerfectSignature/hooks/utils';
import useElementSize from '../../../hooks/useElementSize';
import './animatedSignature.less';

const ANIMATION_DURATION_MS = 7;

export default function AnimatedSignature({
    signatureData,
    startDrawing,
    className,
    onDrawEnd = () => {}
}) {
    const svgRef = useRef(null);
    const {height: observedHeight} = useElementSize(svgRef);
    const originalAspect = signatureData ? signatureData.width / signatureData.height : 1;
    const height = observedHeight !== undefined ? observedHeight.toFixed(2) : 0;
    const width = (height * originalAspect).toFixed(2);
    const [isDrawing, setIsDrawing] = useState(false);
    const [animatedLines, setAnimatedLines] = useState([]);

    const {lines: initialLines, color, options} = getScaledSignature({
        signatureData, width, height, shouldAddPadding: false
    });

    const animatedStrokes = !isEmpty(animatedLines)
        ? animatedLines.map((line) => getStroke(line, options)) : [];
    const animatedPathsData = !isEmpty(animatedStrokes)
        ? animatedStrokes.map((stroke) => getSvgPathFromStroke(stroke)).filter((path) => path !== '') : [];
    const svgCommandsAttr = isDrawing ? animatedPathsData : [];
    const containerClassName = classNames('animated-signature-container', className);

    const drawAnimatedPoints = useCallback(() => {
        if (isDrawing) {
            return;
        }

        setIsDrawing(true);

        // Initialize animatedLines with the same structure as initialLines but empty
        setAnimatedLines(Array.from({length: initialLines.length}, () => []));

        let lineIndex = 0;
        let pointIndex = 0;

        const intervalId = setInterval(() => {
            if (lineIndex < initialLines.length) {
                const currentLine = initialLines[lineIndex];
                if (Array.isArray(currentLine) && pointIndex < currentLine.length) {
                    setAnimatedLines((prevAnimatedLines) => {
                        const newAnimatedLines = prevAnimatedLines
                            .map((line, index) => (index === lineIndex ? [...line, currentLine[pointIndex]] : line));

                        pointIndex += 1;

                        // Move to the next line if all points in the current line are processed
                        if (pointIndex >= currentLine.length) {
                            lineIndex += 1;
                            pointIndex = 0;
                        }

                        // Stop the interval and setIsDrawing(false) when all initialLines are processed
                        if (lineIndex >= initialLines.length) {
                            clearInterval(intervalId);
                            setIsDrawing(false);
                            onDrawEnd();
                        }

                        return newAnimatedLines;
                    });
                } else {
                    // Move to the next line if the current line is not valid or has no points
                    lineIndex += 1;
                    pointIndex = 0;
                }
            } else {
                clearInterval(intervalId);
                setIsDrawing(false);
                onDrawEnd();
            }
        }, ANIMATION_DURATION_MS);
    }, [initialLines, isDrawing, onDrawEnd]);

    useEffect(() => {
        if (startDrawing && width && height) {
            drawAnimatedPoints();
        }
    }, [startDrawing, width, height]);

    return (
        <div className={containerClassName}>
            <svg
                ref={svgRef}
                viewBox={`0 0 ${width} ${height}`}
                style={{touchAction: 'none'}}
                className="animated-signature-svg"
            >
                {!isEmpty(svgCommandsAttr) && svgCommandsAttr.map((commandAttr) => (
                    <path
                        d={commandAttr}
                        key={commandAttr}
                        fill={color}
                    />
                ))}
            </svg>
        </div>
    );
}
