import {getAllPackageFields} from '../../utils';
import {FIELD_SUBTYPES} from '../../../../../constants';

function getGeneratedValue(field) {
    return `${field.subtype}_${field.id}`;
}

export function getFieldNameOrGenerateOne(field) {
    return field.name || getGeneratedValue(field);
}

function isValidForConditional(field = {}) {
    return field.subtype !== FIELD_SUBTYPES.CUSTOMFIELD
        && field.subtype !== FIELD_SUBTYPES.LABEL
        && field.subtype !== FIELD_SUBTYPES.SEAL;
}

export function isFieldEqualToSelected(field = {}, condition) {
    return field.id === condition.field.id;
}

export function isFieldRoleInConditon(field = {}, condition) {
    return field.role === condition?.field?.role;
}

export function isFieldDocumentInCondition(field = {}, condition) {
    return field.documentId === condition.field.documentId;
}

export function isValidForActionLogic(field, condition) {
    return isValidForConditional(field)
    && isFieldRoleInConditon(field, condition)
    && isFieldDocumentInCondition(field, condition)
    && !isFieldEqualToSelected(field, condition);
}

export function getFieldsValidForActionLogic(allFields, condition) {
    return allFields.filter((field) => isValidForActionLogic(field, condition));
}

export function shallowClone(obj) {
    return Object.create(
        Object.getPrototypeOf(obj),
        Object.getOwnPropertyDescriptors(obj)
    );
}

const actionValueRegex = /.(disabled|required)=(.*)$/;
// conditionValueRegex is for getting value from string example: 'document['abc'].field['xyz'].value = 'X'' => 'X'
const conditionValueRegex = /==(.*)$/;
// fieldIdRegex is for getting field id from string example: 'document['abc'].field['xyz'].disabled = true' => xyz
const fieldIdRegex = /field\['(.*)'\]/;
// documentIdRegex is for getting document id from string example: 'document['abc'].field['xyz'].disabled = true' => abc
// const documentIdRegex = /document\['([^\n\r']*)'\]/;
// valueFromStringRegex is for getting value from string example: 'X' => X
const valueFromStringRegex = /'(.*)'/;
// whitespacesRegex is for removing all whitespaces from condition/action string
const whitespacesRegex = /\s/g;

const CHECKBOX_STATE_OPTIONS = ['checked', 'unchecked'];
const RADIO_STATE_OPTIONS = ['selected', 'notSelected'];
const EMPTY_TEXT_OPTIONS = ['notEmpty', 'empty'];
// true === empty, false === notEmpty
const BOOLEAN_EMPTY_TEXT_OPTIONS = {
    true: 'empty',
    false: 'notEmpty'
};
const ACTION_OPTIONS = ['enable', 'disable', 'required', 'optional'];

export const getStates = (field) => {
    if (!field) {
        return '';
    }

    switch (field.subtype) {
        case FIELD_SUBTYPES.TEXTAREA:
        case FIELD_SUBTYPES.TEXTFIELD:
        case FIELD_SUBTYPES.DATEPICKER:
            return EMPTY_TEXT_OPTIONS;
        case FIELD_SUBTYPES.LIST:
            return field.validation?.enum?.filter((e) => e) || [''];
        case FIELD_SUBTYPES.CHECKBOX:
            return CHECKBOX_STATE_OPTIONS;
        case FIELD_SUBTYPES.RADIO:
            return RADIO_STATE_OPTIONS;
        default:
            return [];
    }
};

export function getActions() {
    return [{
        value: ACTION_OPTIONS[0],
        fieldProperties: {
            disabled: false
        }
    },
    {
        value: ACTION_OPTIONS[1],
        fieldProperties: {
            disabled: true
        }
    },
    {
        value: ACTION_OPTIONS[2],
        fieldProperties: {
            required: true
        }
    },
    {
        value: ACTION_OPTIONS[3],
        fieldProperties: {
            required: false
        }
    }
    ];
}

const getStateIndexFromString = ({conditionString, field}) => {
    if (!field) {
        return null;
    }
    let index = null;
    const stateOptions = getStates(field);
    const operandSplit = conditionString.split('==');
    const leftSide = operandSplit[0].replace(whitespacesRegex, '');
    const rightSide = operandSplit[1].trim();
    const result = `${leftSide}==${rightSide}`.match(
        conditionValueRegex
    );
    // This approach uses indexed to get value from EMPTY_TEXT_OPTIONS what makes a lot of confusion while reading this code
    // instead I would consider to.. at least directly use state, like (empty, notEmpty, etc)

    // resultString includes 'true' | 'false' | 'X' | '' which indicates crazy way of understaning if field is empty | notEmpty and so on
    // for example 'document['abc'].field['xyz'].empty = true' => selected option is empty
    // 'document['abc'].field['xyz'].empty = false' => selected option is notEmpty
    const resultString = result[1];
    if ([FIELD_SUBTYPES.TEXTAREA, FIELD_SUBTYPES.TEXTFIELD, FIELD_SUBTYPES.DATEPICKER].includes(field.subtype)) {
        index = stateOptions.indexOf(BOOLEAN_EMPTY_TEXT_OPTIONS[resultString]);
    } else if (field.subtype === FIELD_SUBTYPES.LIST) {
        const match = resultString
            .replace(/\\+'/g, "'")
            .match(valueFromStringRegex);
        const newStateIndex = stateOptions.indexOf(match[1]);
        index = newStateIndex !== -1 ? newStateIndex : 0;
    } else {
        index = resultString === "'X'" ? 0 : 1;
    }
    return index;
};

const getActionIndexByFieldProperty = (propertyName, propertyValue) => {
    const actionIndex = getActions().findIndex(
        (action) => Object.keys(action.fieldProperties).includes(propertyName)
      && action.fieldProperties[propertyName] === propertyValue
    );
    return actionIndex !== -1 ? actionIndex : 0;
};

export function getConditionFromString({field, condition = {}, allFields = []}) {
    const newCondition = {};
    // gets wrong condition.condition

    if (!condition.condition && !condition.action) {
        return newCondition;
    }
    let actionIndex = 0;

    const stateIndex = getStateIndexFromString({
        conditionString: condition.condition,
        field
    });

    const actionFields = condition.action
        .replace(whitespacesRegex, '')
        .split(';');
    if (actionFields[0]) {
        const regexResult = actionFields[0].match(actionValueRegex);
        actionIndex = getActionIndexByFieldProperty(
            regexResult[1],
            regexResult[2] === 'true'
        );
    }
    const updActionFields = [];
    actionFields.forEach((actionField) => {
        const actionFieldId = actionField.match(fieldIdRegex)[1];
        const fieldInAllFields = allFields.find((f) => f.id === actionFieldId);
        if (fieldInAllFields) {
            updActionFields.push({
                ...fieldInAllFields
            });
        }
    });
    newCondition.actionFields = updActionFields;
    return {
        stateIndex,
        actionIndex,
        actionFields: updActionFields,
        field,
        id: condition.id,
        state: getStates(field)[stateIndex],
        action: getActions(field)[actionIndex]
    };
}

export function getFieldConditions({
    packageData,
    documentId,
    approvalId,
    roleId,
    field
}) {
    if (!packageData) {
        return [];
    }
    const allPackageFields = getAllPackageFields(packageData);
    return packageData.conditions.filter((condition) => condition.condition.includes(field.id)
        && condition.condition.includes(documentId))
        .map((condition) => getConditionFromString({
            field: {
                documentId,
                approvalId,
                role: roleId,
                ...field
            },
            condition,
            allFields: allPackageFields
        }));
}

const getConditionStateProperty = (field) => (field.subtype === FIELD_SUBTYPES.TEXTAREA
  || field.subtype === FIELD_SUBTYPES.TEXTFIELD
  || field.subtype === FIELD_SUBTYPES.DATEPICKER
    ? 'empty'
    : 'value');

const getConditionStateValue = (field, stateIndex) => {
    let result = '';
    if (
        field.subtype === FIELD_SUBTYPES.TEXTAREA
    || field.subtype === FIELD_SUBTYPES.TEXTFIELD
    || field.subtype === FIELD_SUBTYPES.DATEPICKER
    ) {
        // WARNIGN PLEASE MAKE IT CLEAR
        const isEmpty = EMPTY_TEXT_OPTIONS[stateIndex] === 'empty';
        result = isEmpty;
    } else if (field.subtype === FIELD_SUBTYPES.LIST) {
        const escapedVal = field.validation.enum[stateIndex].replace(
            /\\*'/g,
            "\\'"
        );
        result = `'${escapedVal}'`;
    } else {
        // stateIndex === 0 stays for 'checked' option in CHECKBOX_STATE_OPTIONS, so we can't change the order of that array
        result = stateIndex === 0 ? "'X'" : "''";
    }

    return result;
};

const parseAction = (action, actionFields) => {
    const result = [];
    actionFields.forEach((field) => {
        if (field?.id) {
            Object.keys(action.fieldProperties)
                .map((key) => `document['${field.documentId}'].field['${field.id}'].${key} = ${action.fieldProperties[key]}`)
                .forEach((fieldText) => result.push(fieldText));
        }
    });
    return result.join(';');
};

export const parseCondition = (field, stateIndex) => `document['${field.documentId}'].field['${field.id}'].${getConditionStateProperty(field)} == ${getConditionStateValue(field, stateIndex)}`;

export function parseConditionToString(condition) {
    const parsedCondition = {
        condition: parseCondition(condition.field, condition.stateIndex),
        action: parseAction(condition.action, condition.actionFields)
    };
    if (condition.id) {
        parsedCondition.id = condition.id;
    }
    return parsedCondition;
}

export const defaultConditionForm = {
    state: '',
    action: {
        value: ''
    }
};
