import {useMutation} from '@apollo/client';
import DECLINE_SIGNING from '../../../../gql/declineSigning.gql';

export default function useDeclineSigning(options) {
    const [declineSigning, {
        loading, error, data, reset
    }] = useMutation(DECLINE_SIGNING, options);

    return {
        declineSigning, loading, error, data, reset
    };
}
