import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import debounce from 'lodash.debounce';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
    HiOutlineChevronLeft,
    HiOutlineChevronRight,
    HiOutlineChevronDoubleLeft,
    HiOutlineChevronDoubleRight
} from 'react-icons/hi';
import AppInput from '../../../../Core/inputs/AppInput/AppInput';
import './documentsNavigation.less';

const MIN_PAGE_NUMBER_INPUT_LENGTH = 1;

export default function DocumentsNavigation({
    documentId,
    pageIndex,
    documents = [],
    navigationProps = {},
    onDocumentAndPageChange = () => {}
}) {
    const intl = useIntl();
    const classNames = classnames('documents-navigation');
    const documentIndex = documents.findIndex(({id}) => id === documentId);
    const document = documents[documentIndex];
    const totalDocuments = documents.length;
    const pageNumber = pageIndex + 1;
    const [pageNumberStringValue, setPageNumberStringValue] = useState(pageNumber.toString());
    const totalPages = document?.pages?.length;
    const pageNumberInputLength = Math.max(pageNumberStringValue.length, MIN_PAGE_NUMBER_INPUT_LENGTH);
    const hasPrevPage = pageIndex > 0;
    const hasNextPage = pageIndex < totalPages - 1;
    const hasPrevDocument = documentIndex > 0;
    const hasNextDocument = documentIndex < totalDocuments - 1;
    const {
        isNavigationVisible = true,
        isPageNavigationVisible = true,
        isDocumentsNavigationVisible = true
    } = navigationProps;

    function getIsPageIndexValid(str) {
        return !isNaN(str) && str >= 0 && str < totalPages;
    }

    function changePageIndex(newPageNumberStringValue) {
        const newPageNumber = parseFloat(newPageNumberStringValue);
        const newPageIndex = newPageNumber - 1;

        if (getIsPageIndexValid(newPageIndex)) {
            onDocumentAndPageChange({
                documentId,
                pageIndex: newPageIndex
            });
        }
    }

    const changePageIndexDebounced = useCallback(debounce((newPageNumberStringValue) => {
        changePageIndex(newPageNumberStringValue);
    }, 150), [documentId]);

    function onChange({target}) {
        const newPageNumberStringValue = target.value;

        setPageNumberStringValue(newPageNumberStringValue);
        changePageIndexDebounced(newPageNumberStringValue);
    }

    function onBlur({target}) {
        const newPageNumberStringValue = target.value;
        const newPageNumber = parseFloat(newPageNumberStringValue);
        const newPageIndex = newPageNumber - 1;
        const isPageIndexValid = getIsPageIndexValid(newPageIndex);

        if (!isPageIndexValid) {
            setPageNumberStringValue(pageIndex.toString());
        }
    }

    function goToPrevPage() {
        const prevPageIndex = pageIndex - 1;

        onDocumentAndPageChange({
            documentId,
            pageIndex: prevPageIndex
        });
    }

    function goToNextPage() {
        const nextPageIndex = pageIndex + 1;

        onDocumentAndPageChange({
            documentId,
            pageIndex: nextPageIndex
        });
    }

    function goToPrevDocument() {
        const nextDocumentIndex = documentIndex - 1;
        const nextDocumentId = documents[nextDocumentIndex]?.id || documentId;

        onDocumentAndPageChange({
            documentId: nextDocumentId,
            pageIndex: 0
        });
    }

    function goToNextDocument() {
        const nextDocumentIndex = documentIndex + 1;
        const nextDocumentId = documents[nextDocumentIndex]?.id || documentId;

        onDocumentAndPageChange({
            documentId: nextDocumentId,
            pageIndex: 0
        });
    }

    useEffect(() => {
        setPageNumberStringValue(pageNumber.toString());
    }, [pageNumber]);

    const pageNumberInput = (
        <AppInput
            value={pageNumberStringValue}
            onChange={onChange}
            onBlur={onBlur}
            className="document-navigation-page-input"
            inputProps={{
                style: {width: `${pageNumberInputLength}ch`}
            }}
        />
    );

    if (!isNavigationVisible) {
        return (
            <div className={classNames}>
                <div className="pages-counter">
                    {intl.formatMessage({id: 'oss.components.Pages.Page.PageFooter.documentPageNumber', defaultMessage: ''}, {
                        pageNumber,
                        totalPages
                    })}
                </div>
            </div>
        );
    }

    return (
        <div className={classNames} id="pages-navigation">
            <div className="pages-counter">
                {intl.formatMessage({id: 'oss.components.Pages.Page.PageFooter.documentPageNumber', defaultMessage: ''}, {
                    pageNumber: pageNumberInput,
                    totalPages
                })}
            </div>
            <div className="pages-documents-navigation">
                {isDocumentsNavigationVisible && (
                    <Tooltip
                        placement="top"
                        title={intl.formatMessage({id: 'page.designer.previousDocument', defaultMessage: ''})}
                        arrow
                    >
                        <IconButton
                            size="small"
                            edge="end"
                            classes={{root: 'navigation-btn'}}
                            disabled={!hasPrevDocument}
                            onClick={goToPrevDocument}
                        >
                            <HiOutlineChevronDoubleLeft />
                        </IconButton>
                    </Tooltip>
                )}
                {isPageNavigationVisible && (
                    <>
                        <Tooltip
                            placement="top"
                            title={intl.formatMessage({id: 'page.designer.previousPage', defaultMessage: ''})}
                            arrow
                        >
                            <IconButton
                                size="small"
                                edge="end"
                                classes={{root: 'navigation-btn'}}
                                disabled={!hasPrevPage}
                                onClick={goToPrevPage}
                            >
                                <HiOutlineChevronLeft />
                            </IconButton>
                        </Tooltip>
                        <div className="document-navigation-page-input-mobile-container">
                            {pageNumberInput}
                        </div>
                        <Tooltip
                            placement="top"
                            title={intl.formatMessage({id: 'page.designer.nextPage', defaultMessage: ''})}
                            arrow
                        >
                            <IconButton
                                size="small"
                                edge="end"
                                classes={{root: 'navigation-btn'}}
                                disabled={!hasNextPage}
                                onClick={goToNextPage}
                            >
                                <HiOutlineChevronRight />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
                {isDocumentsNavigationVisible && (
                    <Tooltip
                        placement="top"
                        title={intl.formatMessage({id: 'page.designer.nextDocument', defaultMessage: ''})}
                        arrow
                    >
                        <IconButton
                            size="small"
                            edge="end"
                            classes={{root: 'navigation-btn'}}
                            disabled={!hasNextDocument}
                            onClick={goToNextDocument}
                        >
                            <HiOutlineChevronDoubleRight />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </div>
    );
}
