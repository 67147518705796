import React from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import Badge from '@material-ui/core/Badge';
import getRoleActionStatusText from '../../utils/getRoleActionStatusText';
import {selectSelectedSigner, setSelectedSigner} from '../../signerUiSlice';
import getSignerIconAndStatus from '../../utils/getSignerIconAndStatus';
import './signer.less';

export default function Signer({
    signer = {}, isOrdered, avatarSrc, disabled,
    hideEmail,
    showEmail = true,
    showName = true,
    showRoleName = true,
    showStatus = true
}) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const selectedSigner = useSelector(selectSelectedSigner);
    const {
        signerId,
        canSign,
        locked,
        fullName,
        email,
        type,
        requiredAction,
        progress,
        displayIndex
    } = signer;
    const iconStatus = getSignerIconAndStatus(progress);
    const actionKey = getRoleActionStatusText(requiredAction);
    const actionMessage = actionKey ? intl.formatMessage({id: actionKey, defaultMessage: ''}) : null;
    const isDisabled = disabled || (isOrdered && !canSign) || locked;

    function selectSigner() {
        if (canSign && !locked) {
            dispatch(setSelectedSigner(signerId));
        }
    }

    return (
        <ListItem
            classes={{root: 'in-person-signer'}}
            button
            disableGutters
            component="div"
            onClick={selectSigner}
            selected={selectedSigner === signerId}
            disabled={isDisabled}
        >
            <div className="avatar-container">
                {isOrdered ? (
                    <Badge badgeContent={displayIndex} classes={{root: 'order-badge'}}>
                        <Avatar
                            classes={{root: 'avatar'}}
                            src={avatarSrc}
                            alt={`${fullName}`}
                        >
                            {`${fullName?.substr(0, 1)}`}
                        </Avatar>
                    </Badge>
                ) : (
                    <Avatar
                        classes={{root: 'avatar'}}
                        src={avatarSrc}
                        alt={`${fullName}`}
                    >
                        {`${fullName?.substr(0, 1)}`}
                    </Avatar>
                )}

            </div>
            <div className="info-status-container">
                <div className="info-container">
                    {showName && (
                        <div className="name-container">
                            {fullName}
                        </div>
                    )}
                    {showEmail && (
                        <div className="email-container">
                            {email}
                        </div>
                    )}
                    {showRoleName && (
                        <div className="type-container">
                            {type}
                        </div>
                    )}

                    {actionMessage && (
                        <div className="action-container">
                            {actionMessage}
                        </div>
                    )}
                </div>
                {showStatus && iconStatus && (
                    <div className="status-container">
                        {iconStatus.icon}
                        {intl.formatMessage({id: iconStatus.status, defaultMessage: ''})}
                    </div>
                )}
            </div>
        </ListItem>
    );
}
