import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import GET_ATTACHMENT_FILES from '../../../../gql/attachmentFiles.gql';
import {selectTransactionId} from '../signerUiSlice';

export default function useAttachmentFiles() {
    const transactionId = useSelector(selectTransactionId);
    const {
        data = {}, error, loading, refetch
    } = useQuery(GET_ATTACHMENT_FILES, {
        skip: !transactionId,
        variables: {transactionId}
    });
    const {ceremony = {}} = data;
    const {
        role = {},
        session = {},
        transaction = {}
    } = ceremony;

    return useMemo(() => ({
        role,
        session,
        transaction,
        refetch,
        error,
        loading
    }), [ceremony, error, loading]);
}
