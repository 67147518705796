import React, {useEffect} from 'react';
import classNames from 'classnames';
import {renderJsSignature} from '../../../AccountSettings/CaptureSignature/helpers';
import './graphicalSignature.less';

export default function GraphicalSignature({
    signature,
    width = 300,
    height = 150,
    lineBottomPercentage = 0.28,
    pseudoCanvasEl = {},
    disabledToDraw,
    className
}) {
    const containerClassName = classNames('graphical-signature-container', className, {'disabled-to-draw': disabledToDraw});

    useEffect(() => {
        if (signature) {
            renderJsSignature({
                canvasEl: pseudoCanvasEl.current, width, height, signature
            });
        }
    }, [signature, width, height]);

    return (
        <div
            className={containerClassName}
            style={{
                width,
                height
            }}
        >
            <div className="line-overlay">
                <span className="signature-line" style={{bottom: height * lineBottomPercentage}} />
            </div>
            <div
                style={{
                    pointerEvents: disabledToDraw ? 'none' : 'auto'
                }}
                ref={pseudoCanvasEl}
                className="display-canvas"
                width={width}
                height={height}
            />
        </div>
    );
}
