import React from 'react';
import {useIntl} from 'react-intl';
import isEmpty from 'lodash.isempty';
import {useMutation, useApolloClient} from '@apollo/client';
import {useSelector} from 'react-redux';
import {getIsExampleTransactionID} from '@components/SignerUi/utils/functions';
import SIGN_APPROVAL from '../../utils/signApproval.gql';
import {selectSelectedDocumentId, selectTransactionId} from '../../../../signerUiSlice';
import SignatureButton from '../SignatureButton/SignatureButton';
import {useValidateSignature} from '../../hooks';
import {getSignatureErrorMessage, getSignatureErrors} from '../../../utils';
import {updateCache} from '../../utils/fieldUtils';

const ClickToSignSignature = ({
    field,
    signatureMetaData,
    selectedField,
    selectField,
    unselectField,
    fieldsErrors,
    fieldsRef,
    setFieldDataToRef
}) => {
    const intl = useIntl();
    const {
        ariaLabel,
        approval,
        isCaptureSig,
        isMobileSig,
        hasConditions,
        approval: {originalId: approvalId},
        embeddedAriaLabel
    } = field;
    const transactionId = useSelector(selectTransactionId);
    const documentId = useSelector(selectSelectedDocumentId);
    const isExampleTransactionID = getIsExampleTransactionID(transactionId);
    const [signApproval, mutationStatus] = useMutation(SIGN_APPROVAL);
    const client = useApolloClient();
    const isFormValid = false;

    const onSign = async (value) => {
        await signApproval({
            update: updateCache({
                approvalId: approval.id,
                client,
                documentId,
                hasConditions,
                isCaptureSig,
                isFormValid,
                isMobileSig,
                transactionId,
                value
            }),
            variables: {
                documentId, originalId: approval.originalId, transactionId, value: ''
            }
        });
    };

    const {signMessage} = signatureMetaData;
    const {getErrors} = useValidateSignature({
        callback: () => (!isExampleTransactionID ? onSign(approvalId) : () => {}),
        approvalId,
        fieldsErrors
    });
    const validationErrors = getSignatureErrors({
        approvalId,
        fieldsErrors
    });

    const validationError = !isEmpty(validationErrors) ? validationErrors[0] : null;
    const errorMessage = getSignatureErrorMessage({
        intl, mutationError: mutationStatus.error, validationError
    });
    const handleClick = async () => {
        await getErrors(approvalId);
    };

    return (
        <SignatureButton
            ariaLabel={embeddedAriaLabel || ariaLabel}
            onClick={handleClick}
            field={field}
            selectField={selectField}
            unselectField={unselectField}
            errorMessage={errorMessage}
            selectedField={selectedField}
            fieldsRef={fieldsRef}
            setFieldDataToRef={setFieldDataToRef}
            loading={mutationStatus.loading}
            data-wdio={field.isClickToInitial ? 'test-click-to-initial-field-btn' : 'test-click-to-sign-field-btn'}
        >
            {signMessage}
        </SignatureButton>
    );
};

export default ClickToSignSignature;
