import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import {HiOutlineChevronDown} from '@react-icons/all-files/hi/HiOutlineChevronDown';
import {HiOutlineChevronUp} from '@react-icons/all-files/hi/HiOutlineChevronUp';
import {HiOutlineSun} from '@react-icons/all-files/hi/HiOutlineSun';
import {HiOutlineMoon} from '@react-icons/all-files/hi/HiOutlineMoon';
import {IoAccessibility} from '@react-icons/all-files/io5/IoAccessibility';
import {IoAccessibilityOutline} from '@react-icons/all-files/io5/IoAccessibilityOutline';
import {fetchSessionInfo, selectUser} from '../../../Login/userSlice';
import {saveDataAttr} from '../../../Dashboard/dashboardSlice';
import {THEMES} from '../../../../../constants';
import {selectTheme, setTheme} from '../../../appSlice';
import InfoLink from '../../../shared/InfoLink/InfoLink';
import './themeSelector.less';

export default function ThemeSelector({shouldShowIcon}) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const currentTheme = useSelector(selectTheme);
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = !!anchorEl;
    const menuId = 'theme-menu';
    const ITEM_HEIGHT_PX = 46;
    const iconsMap = {
        [THEMES.LIGHT]: <HiOutlineSun className="theme-icon" />,
        [THEMES.DARK]: <HiOutlineMoon className="theme-icon" />,
        [THEMES.LIGHT_CONTRAST]: <IoAccessibilityOutline className="theme-icon" />,
        [THEMES.DARK_CONTRAST]: <IoAccessibility className="theme-icon" />
    };

    function handleMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function changeTheme(theme) {
        return async () => {
            handleMenuClose();
            dispatch(setTheme({theme, shouldSetCookie: true}));
            if (user && user.id) {
                await dispatch(saveDataAttr({theme}));
                await dispatch(fetchSessionInfo());
            }
        };
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT_PX * 9
                }
            }}
            MenuListProps={{
                disablePadding: true,
                classes: {root: 'theme-menu-list'}
            }}
        >
            {Object.keys(THEMES).map((theme) => (
                <MenuItem
                    onClick={changeTheme(THEMES[theme])}
                    selected={THEMES[theme] === currentTheme}
                    key={THEMES[theme]}
                    classes={{
                        root: 'theme-item'
                    }}
                >
                    <div className="theme-name">
                        {intl.formatMessage({id: `shared.theme.${THEMES[theme]}`, defaultMessage: ''})}
                    </div>
                    {THEMES[theme] === currentTheme && <InfoLink infoKey="themes_info" />}
                </MenuItem>
            ))}
        </Menu>
    );

    const selectorButton = shouldShowIcon
        ? (
            <IconButton
                edge="end"
                aria-label="select theme"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color="inherit"
            >
                {iconsMap[currentTheme]}
            </IconButton>
        )
        : (
            <button type="button" className="selector-btn-container" onClick={handleMenuOpen}>
                <div className="selector-title">
                    {intl.formatMessage({id: `shared.theme.${currentTheme}`, defaultMessage: ''})}
                </div>
                <div className="selector-chevron-container">
                    {isMenuOpen ? <HiOutlineChevronUp className="selector-chevron" /> : <HiOutlineChevronDown className="selector-chevron" />}
                </div>
            </button>
        );
    return (
        <div className="theme-selector">
            <Tooltip
                title={intl.formatMessage({id: 'shared.tooltips.themelist', defaultMessage: ''})}
                arrow
            >
                {selectorButton}
            </Tooltip>
            {renderMenu}
        </div>
    );
}
