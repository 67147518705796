import {gql} from '@apollo/client';

export const GET_THANK_YOU_HOST_OPTIONS = gql`query getThankYouHostOptions($transactionId: ID!) {
  signingOptions(transactionId: $transactionId) {
    id
    inpersonHostThankYouOptions {
      title
      body
      downloadButton
      continue
      reviewDocumentsButton
      recipientName
      recipientRole
      recipientEmail
      recipientStatus
      __typename
    }
    __typename
  }
}`;
