export const enterFullScreen = (isIOS) => {
    const element = document.documentElement;
    const {body} = document;

    const requestFullscreen = (el) => {
        const methods = [
            'requestFullscreen',
            'mozRequestFullScreen',
            'webkitRequestFullscreen',
            'msRequestFullscreen'
        ];

        for (const method of methods) {
            if (el[method]) {
                el[method]();
                return true;
            }
        }
        return false;
    };

    if (!requestFullscreen(element)) {
        requestFullscreen(body);
    }

    if (isIOS) {
        element.webkitEnterFullscreen?.() || element.webkitRequestFullscreen?.();
    }
};

export const getIsFullscreen = () => !!document.fullscreenElement
    || !!document.msFullscreenElement
    || !!document.mozFullscreenElement
    || !!document.webkitFullscreenElement;

export const exitFullscreen = () => {
    // If no fullscreen element
    // No need to trigger exit
    if (!getIsFullscreen()) {
        return;
    }

    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
    }
};
