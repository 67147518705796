import classNames from 'classnames';
import {forwardRef} from 'react';
import {AppButton} from '@components/Core/buttons/AppButton/AppButton';
import {useIntl} from 'react-intl';
import {PiWarningFill} from 'react-icons/pi';
import {IoMdRefresh} from 'react-icons/io';
import './retry.less';

export const Retry = forwardRef(({
    className,
    children,
    disabled,
    buttonProps,
    titleProps
}, ref) => {
    const containerClassName = classNames('retry-container', className, {disabled});
    const intl = useIntl();

    return (
        <div
            className={containerClassName}
            ref={ref}
        >
            <PiWarningFill aria-hidden className="icon" />
            <h2 className="title" {...titleProps}>
                {intl.formatMessage({id: 'esl.generic.oops', defaultMessage: ''})}
            </h2>
            <p className="sub-title" {...titleProps}>
                {intl.formatMessage({id: 'esl.error.document.somethingWentWrong', defaultMessage: ''})}
            </p>
            {children}
            <AppButton {...buttonProps}>
                <IoMdRefresh aria-hidden className="icon" />
                {intl.formatMessage({id: 'esl.generic.tryAgain', defaultMessage: ''})}
            </AppButton>
        </div>
    );
});
