import {useMemo} from 'react';
import {getDocumentsWithSortedPagesByIndex, getPageId, getPageToContainerScaleFactor} from '../utils';
import {
    FOOTER_MARGIN_BOTTOM_PX,
    FOOTER_TOTAL_HEIGHT_PX
} from '../constants';

function getTopBoundary(previousPage) {
    if (previousPage) {
        return previousPage.bottomBoundary;
    }

    return 0;
}

function getAndAddPageDimensions({
    index, page, dimensions, isFooterVisible, containerWidth, zoomFactor
}) {
    const docId = page.documentId;
    const pageId = getPageId({documentId: docId, pageIndex: page.index});
    const previousPage = index > 0 ? dimensions[index - 1] : null;
    // Scale factor to fit page into the container by multiplying page width
    const pageToContainerScaleFactor = getPageToContainerScaleFactor({containerWidth, page});
    const pageToContainerScaleAndZoomFactor = pageToContainerScaleFactor * zoomFactor;
    const widthScaledToContainerAndZoomFactor = page.width * pageToContainerScaleAndZoomFactor;
    const heightScaledToContainerAndZoomFactor = page.height * pageToContainerScaleAndZoomFactor;
    const widthScaledToContainer = page.width * pageToContainerScaleFactor;
    const heightScaledToContainer = page.height * pageToContainerScaleFactor;
    const topBoundary = getTopBoundary(previousPage);
    const footerHeight = isFooterVisible ? FOOTER_TOTAL_HEIGHT_PX : FOOTER_MARGIN_BOTTOM_PX;
    const bottomBoundary = topBoundary + heightScaledToContainerAndZoomFactor + footerHeight;

    return [...dimensions, {
        pageId,
        widthScaledToContainer: Math.round(widthScaledToContainer),
        heightScaledToContainer: Math.round(heightScaledToContainer),
        widthScaledToContainerAndZoomFactor: Math.round(widthScaledToContainerAndZoomFactor),
        heightScaledToContainerAndZoomFactor: Math.round(heightScaledToContainerAndZoomFactor),
        topBoundary: Math.round(topBoundary),
        bottomBoundary: Math.round(bottomBoundary),
        pageToContainerScaleFactor,
        pageToContainerScaleAndZoomFactor,
        zoomFactor,
        ...page
    }];
}

/**
 * Calculates actual width and hight in container. And page boundaries.
 * To calculate scroll offset by calculations instead of asking DOM.
 */
export default function usePageWithDimensions({
    documents,
    containerWidth,
    zoomFactor = 1,
    isFooterVisible
}) {
    const documentsWithSortedPagesByIndex = useMemo(() => getDocumentsWithSortedPagesByIndex(documents), [documents]);

    return useMemo(() => {
        const sortedPages = documentsWithSortedPagesByIndex.reduce((acc, doc) => [...acc, ...doc.sortedPages], []);

        return sortedPages.reduce((dimensions, page, index) => getAndAddPageDimensions({
            index, page, dimensions, isFooterVisible, containerWidth, zoomFactor
        }), []);
    }, [documentsWithSortedPagesByIndex, containerWidth, zoomFactor]);
}
