import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {GET_REASSIGN_VIEW} from '../../../../../gql/queries';
import {selectTransactionId} from '../../signerUiSlice';

export default function useReassignView() {
    const transactionId = useSelector(selectTransactionId);
    const {
        data = {}, error, loading, refetch
    } = useQuery(GET_REASSIGN_VIEW, {
        skip: !transactionId,
        variables: {transactionId}
    });
    const {ceremony = {}} = data;
    const {
        role = {},
        session = {},
        transaction = {}
    } = ceremony;

    return useMemo(() => ({
        role,
        session,
        transaction,
        refetch,
        error,
        loading
    }), [ceremony, error, loading]);
}
