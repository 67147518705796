import React from 'react';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import {HiOutlineDownload} from '@react-icons/all-files/hi/HiOutlineDownload';

const AppDownloadIconButton = ({
    className, disabled, size = 'small', ...rest
}) => {
    const containerClassName = classnames('download-icon-button', className, {disabled});

    return (
        <IconButton
            size={size}
            classes={{root: containerClassName}}
            {...rest}
        >
            <HiOutlineDownload className="icon" aria-hidden />
        </IconButton>
    );
};
export default AppDownloadIconButton;
