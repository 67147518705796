import {useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import {useSelector} from 'react-redux';
import {GET_EXTERNAL_VERIFICATION} from '../../../../../gql/queries';
import {NEW_DIRECT_SIGN_PATH, THEMES} from '../../../../../constants';
import useMessages from './useMessages';
import {selectTheme} from '../../signerUiSlice';

export default ({
    documentId,
    onUrlReady,
    signerId,
    transactionId,
    verificationType
}) => {
    const isVerificationRequired = verificationType?.method === 'EXTERNAL';
    const [loadVerificationUrl, queryState] = useLazyQuery(GET_EXTERNAL_VERIFICATION);
    const {locale} = useMessages();
    const theme = useSelector(selectTheme) || THEMES.LIGHT;

    function setThemeAndLocaleQueryParams({url}) {
        const parsedUrl = new URL(url);
        const query = parsedUrl.searchParams;
        query.set('theme', theme);
        query.set('locale', locale);

        return parsedUrl;
    }

    useEffect(() => {
        if (
            queryState.called === true
      && queryState.error == null
      && queryState.data != null
        ) {
            const {
                externalVerification: {url}
            } = queryState.data;
            const urlWithThemeAndLocale = setThemeAndLocaleQueryParams({url});
            onUrlReady(urlWithThemeAndLocale);
        }
    }, [
        queryState.error,
        queryState.data,
        queryState.called,
        queryState,
        onUrlReady
    ]);

    const handleUrlFetch = () => {
        const url = new URL(window.location.href);
        const query = url.searchParams;
        query.set('initialDocumentId', documentId);
        query.set('signerId', signerId);
        query.set('documentNavOperation', 'next');
        // Callback will be used to return to NSE after verification completed
        const callbackUrl = `${url.protocol}//${
            url.host
        }/transaction/${transactionId}${NEW_DIRECT_SIGN_PATH}?${query.toString()}`;

        // Fetch verification URL
        loadVerificationUrl({
            variables: {
                transactionId,
                documentId,
                callbackUrl
            }
        });
    };

    return {
        isVerificationRequired,
        queryState,
        startProcess: handleUrlFetch
    };
};
