import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import classnames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {HiOutlineEye} from '@react-icons/all-files/hi/HiOutlineEye';
import {HiOutlineEyeOff} from '@react-icons/all-files/hi/HiOutlineEyeOff';
import './passwordField.less';
import AppInput from '../../Core/inputs/AppInput/AppInput';

export default function PasswordForm({
    value,
    wasChanged,
    onChange,
    isVisible,
    name,
    autocomplete,
    error,
    label,
    classNames,
    placeholder
}) {
    const intl = useIntl();
    const [shouldShowPassword, setShouldShowPassword] = useState(false);
    const hasError = !!error || (wasChanged && wasChanged.value && value === '');

    function handleClickShowPassword() {
        setShouldShowPassword(!shouldShowPassword);
    }

    return (
        <div className={classnames('password-field', classNames, {'is-visible': isVisible})}>
            <AppInput
                required={isVisible}
                value={value}
                name={name || 'password'}
                onChange={onChange}
                type={shouldShowPassword ? 'text' : 'password'}
                dataWdio="test-login-form-password-input"
                aria-describedby="login-password-error"
                autoComplete={autocomplete}
                placeholder={placeholder}
                label={label}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password HiOutlineEye"
                                onClick={handleClickShowPassword}
                                size="small"
                            >
                                {shouldShowPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                error={hasError}
                helperText={hasError && (error || intl.formatMessage({id: 'esl.validation.required', defaultMessage: ''}))}
                helperTextProps={{id: 'login-password-error', 'data-wdio': 'test-login-password-required-error'}}
            />
        </div>
    );
}
