import {useState, useEffect} from 'react';
import {SCREEN_SIZE} from '../../constants';

export default function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
        viewportHeight: undefined,
        isDefined: false
    });
    const isMobile = windowSize.width <= SCREEN_SIZE.SM;

    function handleResize() {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            viewportHeight: window.visualViewport?.height,
            isDefined: true
        });
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        window.visualViewport?.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.visualViewport?.removeEventListener('resize', handleResize);
        };
    }, []);

    return {
        isMobile,
        ...windowSize
    };
}
