import React, {forwardRef} from 'react';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import get from 'lodash.get';
import SELECT_FIELD from '../../../../../../../gql/selectField.gql';
import useFieldMutation from '../../utils/useFieldMutation';
import {getFieldErrorMessage, shouldShowErrorTooltip} from '../../../utils';
import FieldWrapper from '../../FieldWrapper/FieldWrapper';

const CheckboxField = forwardRef(({
    field,
    className,
    selectField,
    unselectField,
    validationError,
    hasInteracted,
    setHasInteracted,
    isHovered,
    isFocused,
    setIsFocused,
    changeFields,
    fields,
    onChangeField,
    fieldsRef

}, ref) => {
    const intl = useIntl();
    const {
        value,
        hasConditions,
        checkboxGroup,
        isGroupCheckbox,
        originalId,
        isConditionalTarget,
        isDisabled
    } = field || {checkboxGroup: {}};
    const {
        updateFieldValue, loading, error: mutationError
    } = useFieldMutation({
        approvalId: field?.approval?.originalId,
        checkboxGroupId: checkboxGroup?.id,
        hasConditions,
        isConditionalTarget,
        mutation: SELECT_FIELD,
        originalId
    });
    const {name: groupName} = checkboxGroup || {};
    const selectedIds = checkboxGroup?.selectedFieldIds;
    const isChecked = isGroupCheckbox ? selectedIds.includes(originalId) : !!value;
    const errorMessage = getFieldErrorMessage({intl, mutationError, validationError});
    const showError = hasInteracted && !!errorMessage;
    const classNameContainer = classNames(className, 'checkbox', {
        loading,
        error: showError
    });
    const showErrorTooltip = shouldShowErrorTooltip({
        isHovered, isFocused, hasInteracted, isDisabled, errorMessage
    });
    const handleChange = () => {
        setHasInteracted(true);
        if (isGroupCheckbox) {
            const updatedSelectedIds = isChecked ? selectedIds.filter((id) => id !== originalId) : [...selectedIds, originalId];

            const updatedFieldsWithChangedGroupBtns = fields.map((data) => {
                if (get(data, 'checkboxGroup.id') === checkboxGroup.id) {
                    return {
                        ...data,
                        checkboxGroup: {
                            ...data.checkboxGroup,
                            selectedFieldIds: updatedSelectedIds
                        },
                        value: !isChecked
                    };
                }
                return data;
            });
            changeFields(updatedFieldsWithChangedGroupBtns);
        } else {
            onChangeField({name: 'value', value: !isChecked});
        }
        updateFieldValue(!isChecked);
    };
    function onFocus() {
        setIsFocused(true);
    }

    function onBlur() {
        setIsFocused(false);
    }

    const checkbox = (
        <Checkbox
            name={groupName}
            checked={isChecked}
            disabled={isDisabled}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            inputRef={ref}
        />
    );
    return (
        <FieldWrapper
            field={field}
            fieldsRef={fieldsRef}
            className={classNameContainer}
            onMouseEnter={selectField}
            onMouseLeave={unselectField}
            showErrorTooltip={showErrorTooltip}
            errorMessage={errorMessage}
            loading={loading}
        >
            {checkbox}
        </FieldWrapper>
    );
});

export default CheckboxField;
