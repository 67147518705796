import React from 'react';
import {useIntl} from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import isEmpty from 'lodash.isempty';
import AppInput from '../../../../../../Core/inputs/AppInput/AppInput';
import './qna.less';

export default function QnA({challenges, changeChallenges}) {
    const intl = useIntl();

    function change(index) {
        return ({target}) => {
            const newChallenges = [...challenges];
            const updatedChallenge = {
                ...newChallenges[index],
                [target.name]: target.name === 'maskInput' ? target.checked : target.value
            };
            newChallenges.splice(index, 1, updatedChallenge);
            changeChallenges(newChallenges);
        };
    }

    if (isEmpty(challenges)) return null;

    return (
        <div className="reassign-auth-challenges-container">
            <div className="challenge-container">
                <AppInput
                    className="field-container"
                    label={intl.formatMessage({id: 'oss.components.Reassign.Auth.QnA.questions.first.label', defaultMessage: ''})}
                    id="reassign-auth-form-question-1"
                    onChange={change(0)}
                    value={challenges[0].question}
                    name="question"
                />
                <AppInput
                    className="field-container"
                    label={intl.formatMessage({id: 'oss.components.Reassign.Auth.QnA.answers.first.label', defaultMessage: ''})}
                    id="reassign-auth-form-answer-1"
                    onChange={change(0)}
                    value={challenges[0].answer}
                    name="answer"
                />
                <FormControlLabel
                    classes={{root: 'mask-input-container'}}
                    control={(
                        <Switch
                            checked={challenges[0].maskInput}
                            onChange={change(0)}
                            name="maskInput"
                            classes={{root: 'mask-input-switch'}}
                            size="small"
                            disableRipple
                        />
                    )}
                    label={intl.formatMessage({id: 'oss.components.Reassign.Auth.answers.maskInfo', defaultMessage: ''})}
                />
            </div>
            <div className="challenge-container">
                <AppInput
                    className="field-container"
                    label={intl.formatMessage({id: 'oss.components.Reassign.Auth.QnA.questions.second.label', defaultMessage: ''})}
                    id="reassign-auth-form-question-2"
                    onChange={change(1)}
                    value={challenges[1].question}
                    name="question"
                />
                <AppInput
                    className="field-container"
                    label={intl.formatMessage({id: 'oss.components.Reassign.Auth.QnA.answers.second.label', defaultMessage: ''})}
                    id="reassign-auth-form-answer-2"
                    onChange={change(1)}
                    value={challenges[1].answer}
                    name="answer"
                />
                <FormControlLabel
                    classes={{root: 'mask-input-container'}}
                    control={(
                        <Switch
                            checked={challenges[1].maskInput}
                            onChange={change(1)}
                            name="maskInput"
                            classes={{root: 'mask-input-switch'}}
                            size="small"
                            disableRipple
                        />
                    )}
                    label={intl.formatMessage({id: 'oss.components.Reassign.Auth.answers.maskInfo', defaultMessage: ''})}
                />
            </div>
        </div>
    );
}
