import useAuthenticate from './useAuthenticate';
import useResendSms from './useResendSms';
import useReassign from './useReassign';

export {
    useAuthenticate,
    useResendSms,
    useReassign
};

// ATTENTION: Prefer syntax of direct named export!
export {useSwitchRole} from './useSwitchRole';
export {useConfirmDocument} from './useConfirmDocument';
export {useChangeLocale} from './useChangeLocale';
export {useAuditEvent} from './useAuditEvent';
