import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import DOMPurify from 'isomorphic-dompurify';
import {HiOutlineUpload} from '@react-icons/all-files/hi/HiOutlineUpload';
import isEmpty from 'lodash.isempty';
import {Button} from '@material-ui/core';
import DocumentsLayers from '../../shared/icons/DocumentsLayers';
import useOverviewDocuments from '../hooks/useOverviewDocuments';
import useMarkedMessage from '../hooks/useMarkedMessage';
import Documents from './Documents/Documents';
import getDocIconAndStatus from '../utils/getDocIconAndStatus';
import Attachments from './Attachments/Attachments';
import isAllDocsToDo from '../utils/isAllDocsToDo';
import EmailMessage from './EmailMessage/EmailMessage';
import {startSigning} from '../signerUiSlice';
import {SectionWithTitle} from '../../Core/company/layouts/SectionWithTitle/SectionWithTitle';
import {useCeremony, useDocumentsWithPages} from '../hooks/queries';
import usePdfDocument from '../../shared/Documents/hooks/usePdfDocument';
import httpClient from '../../../utils/httpClient';
import './welcome.less';

export default function Welcome() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const formatMessage = useMarkedMessage();
    const {
        role: {
            id,
            fullName: signerFullName,
            isReviewer,
            isOwner,
            attachmentRequirements: attachments = []
        } = {},
        transaction: {
            documents = []
        } = {}
    } = useOverviewDocuments();
    const isFirstVisit = isAllDocsToDo(documents, isOwner) || isReviewer;
    const buttonKey = isFirstVisit ? 'oss.components.WelcomePage.getStarted' : 'oss.components.WelcomePage.continue';
    const {transaction: {documents: documentsWithPages}} = useDocumentsWithPages();
    const document = documentsWithPages[0];
    const {role, transaction} = useCeremony();

    function handleStartSigning() {
        dispatch(startSigning({isReviewer}));
    }

    const file = usePdfDocument({
        transactionId: transaction.id,
        document,
        role
    });

    useEffect(() => {
        /**
         * When there's already an action done in the signing process, we don't show the
         * potential welcome screen. The user will be redirected to the PDF view instantly.
         *
         * The reason we need to do this is when an external verification is done, the user
         * will otherwise be redirected to the welcome screen again in stead of the PDF view
         * where the transaction is confirmed.
         */

        if (!isFirstVisit) {
            dispatch(startSigning({isReviewer}));
        }
        // prefetch pdf
        if (file?.url) {
            httpClient.get(file.url, {
                headers: file.httpHeaders,
                withCredentials: file.withCredentials
            });
        }
    }, [isFirstVisit, file.url]);

    return (
        <div className="signing-welcome-screen" data-wdio="signing-welcome-screen">
            <SectionWithTitle
                className="title-container welcome-section"
                title={(
                    <p dangerouslySetInnerHTML={{
                        __html: formatMessage({key: 'oss.components.WelcomePage.welcome', options: {signerFullName}})
                    }}
                    />
                )}
            >
                <div
                    className="title-message"
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(formatMessage({key: 'oss.components.WelcomePage.titleMessage'}))}}
                />
            </SectionWithTitle>
            <EmailMessage roleId={id} signerFullName={signerFullName} />
            <SectionWithTitle
                icon={<DocumentsLayers className="icon" aria-hidden />}
                title={(<p dangerouslySetInnerHTML={{__html: formatMessage({key: 'oss.components.SummaryView.reviewDocuments'})}} />)}
                className="documents-container"
            >
                <div
                    className="documents-message"
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(formatMessage({key: 'oss.components.WelcomePage.Documents.subHeader'}))
                    }}
                />
                <Documents documents={documents} getDocIconAndStatus={getDocIconAndStatus({isReviewer, isOwner})} />

            </SectionWithTitle>
            {!isEmpty(attachments) && (
                <SectionWithTitle
                    icon={<HiOutlineUpload className="icon" aria-hidden />}
                    title={(<p dangerouslySetInnerHTML={{__html: formatMessage({key: 'oss.components.WelcomePage.Uploads.title'})}} />)}
                    className="attachments-section"
                >
                    <div
                        className="uploads-message"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(formatMessage({key: 'oss.comonents.WelcomePage.Uploads.subHeader'}))
                        }}
                    />
                    <Attachments attachments={attachments} />
                </SectionWithTitle>
            )}
            <section className="actions-container">
                <Button
                    size="small"
                    onClick={handleStartSigning}
                    classes={{root: 'start-signing-btn primary-button'}}
                    autoFocus
                    data-wdio="test-go-to-signing-ceremony"
                >
                    {intl.formatMessage({id: buttonKey, defaultMessage: ''})}
                </Button>
            </section>
        </div>
    );
}
