import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import GET_DECLINE_REASONS from '../../../../gql/getDeclineReasons.gql';
import {useMessages} from './queries';

export default function useGetDeclineReasons() {
    const {locale} = useMessages();
    const {data = {}, error, loading} = useQuery(GET_DECLINE_REASONS, {
        variables: {locale}
    });
    const {declineReasons = {}} = data;

    return useMemo(() => ({
        declineReasons
    }), [declineReasons, error, loading]);
}
