import React from 'react';
import {useIntl} from 'react-intl';
import {getPageStickyFooterStyles} from '../../../utils';
import {FOOTER_HEIGHT_PX} from '../../../constants';
import './pageFooter.less';

export default function PageFooter({
    pageWithDimensions = {},
    documentName,
    totalPages,
    topOffset,
    leftOffset,
    scrollDimensions,
    isRendered,
    pageFooterProps: {
        renderChild = () => {},
        ...pageFooterPropsRest
    } = {}
}) {
    const intl = useIntl();
    const {
        index,
        heightScaledToContainer,
        topBoundary,
        bottomBoundary
    } = pageWithDimensions;
    const getStyles = ({isStickToLeft}) => getPageStickyFooterStyles({
        topOffset,
        topBoundary,
        bottomBoundary,
        heightScaledToContainer,
        leftOffset,
        scrollDimensions,
        isStickToLeft
    });
    const {
        isDocumentNameVisible = true,
        isPageCountVisible = true
    } = pageFooterPropsRest;

    const StickyFooterParts = (
        <>
            {isDocumentNameVisible && (
                <div className="document-name" style={getStyles({isStickToLeft: true})}>
                    {documentName}
                </div>
            )}
            {isPageCountVisible && (
                <div className="page-info-container" style={getStyles({isStickToLeft: false})}>
                    {intl.formatMessage({id: 'oss.components.Pages.Page.PageFooter.documentPageNumber', defaultMessage: ''}, {
                        pageNumber: index + 1,
                        totalPages
                    })}
                </div>
            )}
        </>
    );

    return (
        <div className="page-renderer-footer" style={{height: FOOTER_HEIGHT_PX}} {...pageFooterPropsRest}>
            {isRendered && StickyFooterParts}
            {renderChild({
                topOffset,
                leftOffset,
                pageWithDimensions
            })}
        </div>
    );
}
