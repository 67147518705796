import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash.isempty';
import AnimatedSignature from '@components/shared/AnimatedSignature/AnimatedSignature';
import {removeAnimatedSignatureId} from '../../../../signerUiSlice';
import {getFieldPositionStyles} from '../../utils/fieldUtils';
import FieldWrapper from '../../FieldWrapper/FieldWrapper';
import './animatedSignatureWrapper.less';

const AnimatedSignatureWrapper = ({
    field,
    isAnimatedSignature,
    isAccepted,
    signatureData
}) => {
    const dispatch = useDispatch();
    const [wasAnimationEnded, setWasAnimationEnded] = useState(false);
    const className = classNames('animated-signature-wrapper');
    const {width, height} = getFieldPositionStyles(field);

    function onDrawEnd() {
        setWasAnimationEnded(true);
    }

    // wait for signature will be accepted even if animation was ended already
    useEffect(() => {
        if (isAccepted && isAnimatedSignature && wasAnimationEnded) {
            dispatch(removeAnimatedSignatureId(field?.approval?.originalId));
            setWasAnimationEnded(false);
        }
    }, [isAccepted, wasAnimationEnded, isAnimatedSignature]);

    if (isEmpty(signatureData)) {
        return null;
    }

    return (
        <FieldWrapper
            field={field}
            className={className}
            isTooltipDisabled
        >
            <AnimatedSignature
                width={width}
                height={height}
                signatureData={signatureData}
                startDrawing={isAnimatedSignature}
                onDrawEnd={onDrawEnd}
            />
        </FieldWrapper>
    );
};

export default AnimatedSignatureWrapper;
