/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import httpClient from '../../utils/httpClient';
import {BASE_PATH, DEFAULT_USERS_OPTIONS} from '../../../constants';
import {setDelegationUser, setSession} from '../Login/userSlice';
import {getErrorMessage} from '../../utils/requests/error';

export const delegationSlice = createSlice({
    name: 'delegation',
    initialState: {
        users: [],
        delegates: [],
        delegatesCount: null,
        senders: [],
        allSenders: [],
        delegatesSenders: [],
        delegatesSendersCount: null,
        IsFetchingDelegatesSenders: false,
        sendersPerPage: 15,
        sendersOptions: DEFAULT_USERS_OPTIONS,
        sendersCount: null,
        allSendersCount: null,
        isFetchingUsers: false,
        isFetchingDelegates: false,
        isFetchingSenders: false,
        isDelegating: false,
        isStoppingDelegation: false,
        isSavingDelegates: false,
        usersFetchingError: null,
        delegatesFetchingError: null,
        sendersFetchingError: null,
        delegatingError: null,
        stopDelegationError: null,
        savingDelegatesError: null,
        inviteSenderError: null,
        updatingSenderError: null,
        isUpdatingSender: false,
        deletingSenderError: null,
        isDeletingSender: false
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setIsFetchingUsers: (state, action) => {
            state.isFetchingUsers = action.payload;
        },
        setUsersFetchingError: (state, action) => {
            state.usersFetchingError = action.payload;
        },
        setDelegates: (state, action) => {
            state.delegates = action.payload;
        },
        setDelegatesCount: (state, action) => {
            state.delegatesCount = action.payload;
        },
        setIsFetchingDelegates: (state, action) => {
            state.isFetchingDelegates = action.payload;
        },
        setDelegatesFetchingError: (state, action) => {
            state.delegatesFetchingError = action.payload;
        },
        setSenders: (state, action) => {
            state.senders = action.payload;
        },
        setAllSenders: (state, action) => {
            state.allSenders = action.payload;
        },
        setIsFetchingSenders: (state, action) => {
            state.isFetchingSenders = action.payload;
        },
        setIsSavingDelegates: (state, action) => {
            state.isSavingDelegates = action.payload;
        },
        setSendersFetchingError: (state, action) => {
            state.sendersFetchingError = action.payload;
        },
        setSavingDelegatesError: (state, action) => {
            state.savingDelegatesError = action.payload;
        },
        setIsDelegating: (state, action) => {
            state.isDelegating = action.payload;
        },
        setDelegatingError: (state, action) => {
            state.delegatingError = action.payload;
        },
        setIsStoppingDelegation: (state, action) => {
            state.isStoppingDelegation = action.payload;
        },
        setStopDelegationError: (state, action) => {
            state.stopDelegationError = action.payload;
        },
        setSendersOptions: (state, action) => {
            state.sendersOptions = action.payload;
        },
        setSendersCount: (state, action) => {
            state.sendersCount = action.payload;
        },
        setAllSendersCount: (state, action) => {
            state.allSendersCount = action.payload;
        },

        setInviteSenderError: (state, action) => {
            state.inviteSenderError = action.payload;
        },
        setUpdatingSenderError: (state, action) => {
            state.updatingSenderError = action.payload;
        },
        setIsUpdatingSender: (state, action) => {
            state.isUpdatingSender = action.payload;
        },
        setDeletingSenderError: (state, action) => {
            state.deletingSenderError = action.payload;
        },
        setIsDeletingSender: (state, action) => {
            state.isDeletingSender = action.payload;
        },
        setDelegatesSenders: (state, action) => {
            const {payload} = action;
            let updatedDelegatesSenders = [...state.delegatesSenders];
            if (payload.add) {
                updatedDelegatesSenders.unshift(payload.data);
            } else if (payload.override) {
                updatedDelegatesSenders = payload.data;
            } else {
                updatedDelegatesSenders.splice(payload.from - 1, payload.data.length, ...payload.data);
            }
            state.delegatesSenders = updatedDelegatesSenders;
        },
        setDelegatesSendersCount: (state, action) => {
            state.delegatesSendersCount = action.payload;
        },
        setIsFetchingDelegatesSenders: (state, action) => {
            state.IsFetchingDelegatesSenders = action.payload;
        },
        resetDelegatesSenders: (state) => ({
            ...state,
            delegatesSenders: [],
            delegatesSendersCount: 0
        })
    }
});

export const {
    setUsers,
    setIsFetchingUsers,
    setUsersFetchingError,
    setDelegates,
    setDelegatesCount,
    setIsFetchingDelegates,
    setDelegatesFetchingError,
    setSenders,
    setAllSenders,
    setIsFetchingSenders,
    setSendersFetchingError,
    setIsDelegating,
    setDelegatingError,
    setIsStoppingDelegation,
    setStopDelegationError,
    setIsSavingDelegates,
    setSavingDelegatesError,
    setSendersOptions,
    setSendersCount,
    setAllSendersCount,
    setInviteSenderError,
    setUpdatingSenderError,
    setIsUpdatingSender,
    setDeletingSenderError,
    setIsDeletingSender,
    setDelegatesSenders,
    setDelegatesSendersCount,
    setIsFetchingDelegatesSenders,
    resetDelegatesSenders
} = delegationSlice.actions;

/**
 * Fetches all the delegated users for the logged in user
 * @returns {array} delegated users for the logged in user
 */
export const fetchUsers = ({
    headers, baseUrl
} = {}) => async (dispatch, getState) => {
    const options = {};
    const {user} = getState();
    const userId = get(user, 'user.id', '');
    const servicePath = `session/delegators/${userId}`;
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;

    if (headers) {
        options.headers = headers;
    }

    dispatch(setIsFetchingUsers(true));
    dispatch(setUsersFetchingError(null));

    try {
        const result = await httpClient.get(url, options);
        const users = get(result, 'data', []);
        dispatch(setUsers(users));
        dispatch(setIsFetchingUsers(false));
        return users;
    } catch (err) {
        dispatch(setIsFetchingUsers(false));
        dispatch(setUsersFetchingError(getErrorMessage(err)));
        return false;
    }
};

export const fetchDelegates = ({from = 1, to, search} = {}) => async (dispatch, getState) => {
    const {user} = getState();
    const url = `${BASE_PATH}/proxy/account/delegates/${user.user.id}`;

    dispatch(setIsFetchingDelegates(true));
    dispatch(setUsersFetchingError(null));

    try {
        const result = await httpClient.get(url, {
            params: {from, to, search}
        });
        const delegates = get(result, 'data.results', []);
        const delegatesCount = get(result, 'data.count', 0);

        dispatch(setDelegates(delegates));
        dispatch(setDelegatesCount(delegatesCount));
        dispatch(setIsFetchingDelegates(false));
        return delegates;
    } catch (err) {
        dispatch(setDelegatesFetchingError(getErrorMessage(err)));
        dispatch(setIsFetchingDelegates(false));
        return false;
    }
};

export const fetchSenders = () => async (dispatch, getState) => {
    const {sendersOptions} = getState().delegation;
    const {
        query,
        dir,
        sort,
        from,
        to,
        search
    } = sendersOptions;
    const url = `${BASE_PATH}/proxy/account/senders?query=${query}&sort=${sort}&dir=${dir}&from=${from}&to=${to}&search=${search}`;

    dispatch(setIsFetchingSenders(true));
    dispatch(setUsersFetchingError(null));

    try {
        const result = await httpClient.get(url);
        const senders = get(result, 'data.results', []);
        const sendersCount = get(result, 'data.count', 0);
        dispatch(setSenders(senders));
        dispatch(setSendersCount(sendersCount));
        dispatch(setIsFetchingSenders(false));

        return senders;
    } catch (err) {
        dispatch(setIsFetchingSenders(false));

        return false;
    }
};

export const fetchDelegatesSendersBySenderId = ({
    from, to, search, override, senderId
}) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/delegates/${senderId}/senders`;

    dispatch(setIsFetchingDelegatesSenders(true));

    try {
        const result = await httpClient.get(url, {
            params: {from, to, search}
        });
        const senders = get(result, 'data.results', []);
        const sendersCount = get(result, 'data.count', 0);
        dispatch(setDelegatesSenders({from, data: senders, override}));
        dispatch(setDelegatesSendersCount(sendersCount));
        dispatch(setIsFetchingDelegatesSenders(false));

        return senders;
    } catch (err) {
        dispatch(setIsFetchingDelegatesSenders(false));

        return false;
    }
};

export const fetchDelegatesSenders = (params) => async (dispatch, getState) => {
    const {user} = getState();
    const senderId = user.user.id;

    return dispatch(fetchDelegatesSendersBySenderId({
        ...params,
        senderId: user.user.id
    }));
};

export const getAllSendersCount = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/senders?query=${DEFAULT_USERS_OPTIONS.query}&sort=${DEFAULT_USERS_OPTIONS.sort}&dir=${DEFAULT_USERS_OPTIONS.dir}&from=1&to=1`;

    try {
        const result = await httpClient.get(url);
        const sendersCount = get(result, 'data.count', 0);
        dispatch(setAllSendersCount(sendersCount));
        return sendersCount;
    } catch (err) {
        return false;
    }
};

export const searchSender = (search) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/senders?query=${DEFAULT_USERS_OPTIONS.query}&sort=${DEFAULT_USERS_OPTIONS.sort}&dir=${DEFAULT_USERS_OPTIONS.dir}&from=1&search=${search}`;

    try {
        const result = await httpClient.get(url);
        const senders = get(result, 'data.results', []);
        const sendersCount = get(result, 'data.count', 0);
        dispatch(setAllSendersCount(sendersCount));
        return senders;
    } catch (err) {
        return false;
    }
};

export const fetchAllSenders = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/senders?query=${DEFAULT_USERS_OPTIONS.query}&sort=${DEFAULT_USERS_OPTIONS.sort}&dir=${DEFAULT_USERS_OPTIONS.dir}&from=1`;

    dispatch(setUsersFetchingError(null));
    dispatch(setIsFetchingSenders(true));

    try {
        const result = await httpClient.get(url);
        const senders = get(result, 'data.results', []);
        const sendersCount = get(result, 'data.count', 0);
        dispatch(setAllSenders(senders));
        dispatch(setAllSendersCount(sendersCount));
        dispatch(setIsFetchingSenders(false));

        return senders;
    } catch (err) {
        dispatch(setIsFetchingSenders(false));

        return false;
    }
};
export const inviteSender = (email) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/senders`;
    dispatch(setInviteSenderError(null));

    try {
        await httpClient.post(url, {email});
        await dispatch(fetchSenders());
        return true;
    } catch (err) {
        dispatch(setInviteSenderError(getErrorMessage(err)));
        return false;
    }
};
export const updateSender = (sender) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/senders/${sender.id}`;
    dispatch(setUpdatingSenderError(null));
    dispatch(setIsUpdatingSender(true));

    try {
        await httpClient.post(url, sender);
        await dispatch(fetchSenders());
        dispatch(setIsUpdatingSender(false));
        return true;
    } catch (err) {
        dispatch(setIsUpdatingSender(false));
        dispatch(setUpdatingSenderError(getErrorMessage(err)));
        return false;
    }
};

export const deleteSender = (id) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/senders/${id}`;
    dispatch(setDeletingSenderError(null));
    dispatch(setIsDeletingSender(true));

    try {
        await httpClient.delete(url);
        dispatch(setIsDeletingSender(false));
        return true;
    } catch (err) {
        dispatch(setIsDeletingSender(false));
        dispatch(setDeletingSenderError(getErrorMessage(err)));
        return false;
    }
};

export const delegateUser = (userid) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/session/delegate/${userid}`;
    dispatch(setIsDelegating(true));
    dispatch(setDelegatingError(null));

    try {
        const result = await httpClient.put(url);
        dispatch(setIsDelegating(false));
        dispatch(setSession(result.data));
        return true;
    } catch (err) {
        dispatch(setIsDelegating(false));
        dispatch(setDelegatingError(getErrorMessage(err)));
        return false;
    }
};

export const stopDelegation = () => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/session/delegate`;
    dispatch(setIsStoppingDelegation(true));
    dispatch(setStopDelegationError(null));

    try {
        const wasSuccessful = await httpClient.delete(url);
        dispatch(setIsStoppingDelegation(false));
        if (wasSuccessful) {
            dispatch(setDelegationUser({}));
        }
        return true;
    } catch (err) {
        dispatch(setIsStoppingDelegation(false));
        dispatch(setStopDelegationError(getErrorMessage(err)));
        return false;
    }
};
export const saveDelegates = (payload) => async (dispatch, getState) => {
    const {user} = getState();
    const url = `${BASE_PATH}/proxy/account/delegates/${user.user.id}`;
    dispatch(setIsSavingDelegates(true));
    dispatch(setSavingDelegatesError(null));
    try {
        await httpClient.post(url, payload);
        dispatch(setIsSavingDelegates(false));
        dispatch(fetchDelegates());
        return true;
    } catch (err) {
        dispatch(setSavingDelegatesError(getErrorMessage(err)));
        dispatch(setIsSavingDelegates(false));
        return false;
    }
};
export const updateDelegates = (delegatesIds) => async (dispatch, getState) => {
    const {user} = getState();
    dispatch(setIsSavingDelegates(true));
    dispatch(setSavingDelegatesError(null));

    try {
        await httpClient.put(`${BASE_PATH}/proxy/account/senders/${user.user.id}/delegates`, delegatesIds);
        dispatch(setIsSavingDelegates(false));
        dispatch(fetchDelegates());
        return true;
    } catch (err) {
        dispatch(setSavingDelegatesError(getErrorMessage(err)));
        dispatch(setIsSavingDelegates(false));
        return false;
    }
};
export const updateDelegatesForAccount = (delegates) => async (dispatch, getState) => {
    const {user} = getState();
    dispatch(setIsSavingDelegates(true));
    dispatch(setSavingDelegatesError(null));

    try {
        await httpClient.put(`${BASE_PATH}/proxy/account/delegates/${user.user.id}`, delegates);
        dispatch(setIsSavingDelegates(false));
        dispatch(fetchDelegates());
        return true;
    } catch (err) {
        dispatch(setSavingDelegatesError(getErrorMessage(err)));
        dispatch(setIsSavingDelegates(false));
        return false;
    }
};

export const updateSenderDelegates = (senderId, delegates) => async (dispatch) => {
    dispatch(setIsUpdatingSender(true));
    dispatch(setUpdatingSenderError(null));

    try {
        await httpClient.put(`${BASE_PATH}/proxy/account/senders/${senderId}/delegates`, delegates);
        dispatch(setIsUpdatingSender(false));
        dispatch(fetchDelegates());
        return true;
    } catch (err) {
        dispatch(setUpdatingSenderError(getErrorMessage(err)));
        dispatch(setIsUpdatingSender(false));
        return false;
    }
};

/**
 * The isCheckForRights param is optional and is used to prevent the error message to be shown.
 * This is due to a specific case where a hotfix is to call this function to see when it breaks.
 * It's related to story RFT-152
 */
export const fetchSenderDelegates = (id) => async (dispatch, isCheckForRights) => {
    const url = `${BASE_PATH}/proxy/account/senders/${id}/delegates`;
    dispatch(setIsFetchingDelegates(true));
    dispatch(setUsersFetchingError(null));

    try {
        const result = await httpClient.get(url);
        const delegates = get(result, 'data', []);
        dispatch(setIsFetchingDelegates(false));
        return delegates;
    } catch (err) {
        if (!isCheckForRights) {
            dispatch(setDelegatesFetchingError(getErrorMessage(err)));
        }
        dispatch(setIsFetchingDelegates(false));

        return false;
    }
};
export const resetPassword = (id) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/account/senders/${id}/resetpassword`;
    dispatch(setUpdatingSenderError(null));
    dispatch(setIsUpdatingSender(true));

    try {
        await httpClient.post(url, {});
        dispatch(setIsUpdatingSender(false));
        return true;
    } catch (err) {
        dispatch(setIsUpdatingSender(false));
        dispatch(setUpdatingSenderError(getErrorMessage(err)));
        return false;
    }
};

export const selectUsers = (state) => state.delegation.users;
export const selectDelegates = (state) => state.delegation.delegates;
export const selectDelegatesCount = (state) => state.delegation.delegatesCount;
export const selectDelegatesFetchingError = (state) => state.delegation.delegatesFetchingError;
export const selectIsFetchingDelegates = (state) => state.delegation.isFetchingDelegates;
export const selectSenders = (state) => state.delegation.senders;
export const selectAllSenders = (state) => state.delegation.allSenders;
export const selectSendersFetchingError = (state) => state.delegation.sendersFetchingError;
export const selectIsFetchingSenders = (state) => state.delegation.isFetchingSenders;
export const selectIsSavingDelegates = (state) => state.delegation.isSavingDelegates;
export const selectSavingDelegatesError = (state) => state.delegation.savingDelegasendersOptionstesError;
export const selectSendersOptions = (state) => state.delegation.sendersOptions;
export const selectSendersCount = (state) => state.delegation.sendersCount;
export const selectAllSendersCount = (state) => state.delegation.allSendersCount;
export const selectInviteSenderError = (state) => state.delegation.inviteSenderError;
export const selectUpdatingSenderError = (state) => state.delegation.updatingSenderError;
export const selectIsUpdatingSender = (state) => state.delegation.isUpdatingSender;
export const selectDeletingSenderError = (state) => state.delegation.deletingSenderError;
export const selectisDeletingSender = (state) => state.delegation.isDeletingSender;
export const selectSendersPerPage = (state) => state.delegation.sendersPerPage;
export const selectDelegatesSenders = (state) => state.delegation.delegatesSenders;
export const selectDelegatesSendersCount = (state) => state.delegation.delegatesSendersCount;
export const selectIsFetchingDelegatesSenders = (state) => state.delegation.IsFetchingDelegatesSenders;

export default delegationSlice.reducer;
