import React from 'react';

export default function SurfIcon(props) {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="55px"
            height="55px"
            viewBox="0 0 800.000000 368.000000"
            preserveAspectRatio="xMidYMid meet"
            {...props}
        >
            <g
                transform="translate(0.000000,368.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
            >
                <path d="M490 3634 c-209 -45 -388 -220 -429 -422 -15 -72 -15 -862 0 -934 33
                        -159 152 -308 300 -377 46 -22 111 -44 143 -50 41 -8 567 -11 1705 -11 1814 0
                        1705 3 1840 -63 87 -42 195 -149 238 -237 61 -122 63 -143 63 -590 0 -449 5
                        -490 62 -608 42 -85 159 -206 238 -243 144 -70 12 -64 1467 -67 1459 -3 1390
                        -6 1532 64 95 47 193 143 238 234 61 122 63 141 63 602 0 264 -4 436 -11 470
                        -37 181 -179 340 -363 407 l-71 26 -1355 5 -1355 5 -59 22 c-173 65 -303 198
                        -358 362 -21 63 -22 89 -28 521 -7 494 -6 486 -68 604 -74 141 -216 247 -377
                        281 -102 21 -3315 21 -3415 -1z m571 -503 c78 -30 109 -60 109 -107 0 -46 -32
                        -84 -70 -84 -15 0 -53 9 -86 20 -115 40 -237 26 -251 -28 -9 -38 20 -56 166
                        -102 209 -65 266 -120 266 -256 0 -60 -4 -76 -28 -112 -65 -100 -162 -137
                        -331 -129 -129 6 -202 26 -251 68 -39 35 -47 78 -20 116 24 34 63 36 169 8 54
                        -14 117 -25 140 -25 53 1 122 32 131 60 13 42 -17 65 -127 99 -57 18 -122 39
                        -145 47 -143 51 -201 183 -138 311 29 57 78 97 158 125 71 26 228 20 308 -11z
                        m483 -1 c20 -19 21 -35 26 -264 5 -238 5 -243 30 -279 73 -108 245 -99 308 16
                        14 27 18 69 22 269 5 231 6 238 28 260 32 32 102 32 134 0 22 -22 23 -30 26
                        -225 3 -219 -6 -302 -42 -382 -30 -65 -97 -132 -166 -164 -49 -23 -67 -26
                        -165 -26 -97 0 -116 3 -160 25 -73 36 -147 114 -176 185 -22 56 -23 76 -27
                        298 -4 260 0 288 47 306 37 14 91 5 115 -19z m1253 5 c73 -22 136 -74 169
                        -141 54 -111 29 -257 -57 -326 l-37 -30 49 -101 c27 -55 49 -108 49 -118 0
                        -34 -52 -80 -95 -86 -54 -7 -72 13 -135 147 l-48 105 -86 3 -86 3 0 -105 c0
                        -125 -11 -145 -81 -153 -38 -4 -50 -1 -75 20 l-29 25 0 362 0 362 28 24 c27
                        23 32 24 206 24 128 0 191 -4 228 -15z m997 -6 c22 -17 26 -29 26 -68 0 -83
                        -8 -85 -241 -89 l-199 -3 0 -85 0 -84 130 0 c126 0 132 -1 155 -25 31 -30 34
                        -92 6 -126 -18 -23 -26 -24 -155 -27 l-136 -4 0 -119 c0 -114 -1 -121 -25
                        -144 -18 -19 -35 -25 -65 -25 -30 0 -47 6 -65 25 l-25 24 0 361 0 361 25 24
                        24 25 259 0 c247 0 261 -1 286 -21z m1315 -1794 c16 -8 113 -119 217 -246
                        l189 -233 5 227 c6 262 6 262 90 262 38 0 55 -5 72 -23 l23 -23 0 -357 c0
                        -323 -2 -360 -17 -384 -24 -36 -80 -47 -122 -24 -17 9 -114 120 -216 245
                        l-185 228 -5 -217 c-4 -195 -7 -220 -24 -239 -40 -44 -132 -35 -155 15 -15 34
                        -15 703 0 738 20 43 77 58 128 31z m1431 -5 c42 -22 47 -112 8 -147 -18 -16
                        -44 -18 -219 -21 l-199 -3 0 -65 0 -64 114 0 c62 0 127 -5 143 -10 55 -20 72
                        -102 28 -145 -23 -24 -29 -25 -155 -25 l-130 0 0 -70 0 -70 194 0 c178 0 195
                        -2 219 -20 31 -25 41 -62 27 -105 -18 -53 -37 -56 -329 -53 -262 3 -263 3
                        -282 27 -18 22 -19 44 -19 378 l0 354 25 24 24 25 266 0 c155 0 274 -4 285
                        -10z m820 -16 c27 -23 31 -32 28 -67 -5 -64 -36 -80 -158 -85 l-99 -4 -3 -293
                        c-3 -271 -4 -295 -22 -314 -40 -44 -132 -35 -155 15 -7 17 -11 119 -11 309 l0
                        283 -101 4 c-92 3 -103 5 -120 27 -27 33 -25 98 3 128 l21 23 293 0 292 0 32
                        -26z"
                />
                <path d="M2520 2864 l0 -106 116 4 c113 3 116 4 140 31 17 20 24 40 24 68 0
                        87 -37 109 -180 109 l-100 0 0 -106z"
                />
            </g>
        </svg>
    );
}
