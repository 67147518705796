import React from 'react';

function Signature2(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 37" fill="currentcolor" {...props}>
            <g>
                <path
                    d="M18.541.451a1.539 1.539 0 012.176 0l6.526 6.526c.6.601.6 1.575 0 2.176L9.154 27.242c-.288.288-.68.45-1.088.45H1.54c-.85 0-1.538-.688-1.539-1.538v-6.526c0-.408.163-.8.451-1.088L18.542.451zm5.44 7.614l-4.352-4.35-16.55 16.55v4.35h4.35L23.98 8.066z"
                    fill="currentcolor"
                />
                <path d="M22.518 21.54a1.54 1.54 0 00-1.058.464l-7.064 7.058a4.53 4.53 0 00-1.064 1.714H1.562A1.536 1.536 0 000 32.326a1.546 1.546 0 001.563 1.524h11.763c.22.633.568 1.23 1.07 1.732 1.79 1.788 4.74 1.788 6.53 0l4.353-4.35c.203.475.476.854.898 1.276 1.79 1.788 4.738 1.788 6.527 0l1.748-1.747h3.988c2.08.03 2.08-3.103 0-3.074h-4.624c-.409 0-.8.162-1.088.45l-2.2 2.198c-.621.62-1.553.62-2.175 0-.622-.621-.622-1.553 0-2.173l.91-.91c1.39-1.449-.725-3.562-2.175-2.174l-.91.91-7.428 7.42c-.622.622-1.557.622-2.179 0-.621-.62-.621-1.551 0-2.172l7.064-7.058c1.007-.977.285-2.68-1.117-2.639z" />
            </g>
        </svg>
    );
}

export default Signature2;
