import {
    fetchTemplate
} from '../Template/templateSlice';
import {
} from '../Packages/Recipients/recipientsSlice';
import {fetchSenderToken} from '../Login/userSlice';
import {
    fetchDesignerSettings,
    fetchLayouts
} from './designerSlice';

export default function ssrFetch({
    store, headers, baseUrl, params
}) {
    return Promise.all([
        store.dispatch(fetchDesignerSettings({headers, baseUrl, params})),
        store.dispatch(fetchTemplate({headers, baseUrl, params})),
        store.dispatch(fetchSenderToken({headers, baseUrl, params})),
        store.dispatch(fetchLayouts({headers, baseUrl, params}))
    ]);
}
