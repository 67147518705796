import {useMutation} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash.get';
import SWITCH_USER_SESSION from '../../../../../gql/mutateUserSession.gql';
import {setSignerSession} from '../../../../utils/storageSelectors';
import {clearCachedSignatures, selectTransactionId} from '../../signerUiSlice';
import {useCeremony} from '../queries';
import {useGraphQlErrorNotification} from '../../../../hooks/useErrorNotification';

// ATTENTION: switchRole doesn't work if signing is completed
export const useSwitchRole = ({options} = {}) => {
    const transactionId = useSelector(selectTransactionId);
    const [switchRole, {
        error, data, loading, reset
    }] = useMutation(SWITCH_USER_SESSION, options);
    const dispatch = useDispatch();
    const {refetch} = useCeremony();

    useGraphQlErrorNotification(get(data, 'errors[0]') || error, reset);

    const updateSession = async ({roleId, payload = {}}) => {
        try {
            const {data: resData} = await switchRole({
                variables: {
                    transactionId,
                    roleId,
                    inPerson: true,
                    payload
                }
            });

            dispatch(clearCachedSignatures());
            setSignerSession(resData?.switchRole?.sessionId);
            await refetch();
            return resData;
        } catch (e) {
            return null;
        }
    };
    return [updateSession, {error, data, loading}];
};
