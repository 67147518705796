import React from 'react';

export default function CSV(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 318.188 318.188"
            style={{
                enableBackground: "new 0 0 318.188 318.188",
            }}
            xmlSpace="preserve"
            {...props}
        >
            <path d="M182.882 155.008h33.713v15h-33.713zM101.592 132.689h33.713v15h-33.713zM182.882 132.689h33.713v15h-33.713zM182.882 88.053h33.713v15h-33.713zM182.882 110.371h33.713v15h-33.713zM101.592 155.008h33.713v15h-33.713zM112.09 123.663v-.001l6.196-10.041 6.262 10.041h10.04l-10.941-15.753L133.82 91.54h-9.909l-5.581 9.932-5.8-9.932h-9.624l10.019 15.688-10.656 16.435zM201.02 249.514c-.339 1.27-.73 3.015-1.174 5.236-.445 2.222-.741 4.073-.889 5.555-.127-2.053-.847-5.691-2.158-10.918l-6.316-23.519h-14.092l15.139 46.401h14.759l15.202-46.401h-14.027l-6.444 23.646zM142.457 110.371h33.713v15h-33.713zM142.457 88.053h33.713v15h-33.713z" />
            <path d="M283.149 52.723 232.624 2.197A7.495 7.495 0 0 0 227.321 0H40.342a7.5 7.5 0 0 0-7.5 7.5v303.188a7.5 7.5 0 0 0 7.5 7.5h237.504a7.5 7.5 0 0 0 7.5-7.5V58.025c0-1.989-.79-3.896-2.197-5.302zm-48.328-27.117 24.918 24.919h-24.918V25.606zM47.842 15h171.979v10.263H47.842V15zm0 288.188V40.263h171.979v17.763a7.5 7.5 0 0 0 7.5 7.5h43.024v237.662H47.842z" />
            <path d="M142.457 132.689h33.713v15h-33.713zM122.372 235.484c1.969 0 3.809.275 5.523.826a43.151 43.151 0 0 1 5.141 2.031l3.841-9.871c-4.57-2.18-9.362-3.27-14.378-3.27-4.591 0-8.585.98-11.98 2.937-3.396 1.957-5.999 4.755-7.808 8.395-1.81 3.64-2.714 7.86-2.714 12.663 0 7.682 1.867 13.553 5.602 17.615 3.734 4.063 9.104 6.094 16.107 6.094 4.888 0 9.268-.857 13.14-2.57v-10.602a59.443 59.443 0 0 1-5.808 2.063 20.882 20.882 0 0 1-5.967.857c-6.793 0-10.188-4.464-10.188-13.393 0-4.295.836-7.665 2.507-10.109 1.672-2.443 4-3.666 6.982-3.666zM163.57 244.594c-4.169-1.904-6.724-3.216-7.665-3.936-.942-.719-1.412-1.533-1.412-2.443-.002-.847.368-1.556 1.11-2.127.74-.571 1.925-.857 3.555-.857 3.152 0 6.897.995 11.234 2.984l3.841-9.681c-4.994-2.222-9.892-3.333-14.694-3.333-5.439 0-9.713 1.196-12.822 3.587-3.111 2.392-4.666 5.724-4.666 9.997 0 2.285.365 4.264 1.095 5.936s1.851 3.152 3.364 4.443 3.782 2.624 6.809 3.999c3.343 1.503 5.4 2.497 6.173 2.983.771.486 1.333.968 1.682 1.444.35.476.524 1.031.524 1.666 0 1.016-.435 1.847-1.302 2.491-.868.647-2.233.969-4.095.969-2.158 0-4.527-.344-7.109-1.032a37.314 37.314 0 0 1-7.458-2.872v11.172c2.264 1.079 4.443 1.836 6.538 2.27 2.095.434 4.687.65 7.775.65 3.703 0 6.93-.619 9.681-1.856 2.75-1.238 4.856-2.973 6.315-5.205 1.461-2.232 2.191-4.787 2.191-7.665 0-3.131-.777-5.729-2.333-7.792-1.555-2.063-4.332-3.993-8.331-5.792zM142.457 155.008h33.713v15h-33.713z" />
        </svg>
    );
}
