import pick from 'lodash.pick';
import isEmpty from 'lodash.isempty';
import {
    ONLY_DIGITS_REGEXP,
    MAX_PASSCODE_LENGTH,
    MIN_PASSCODE_LENGTH,
    SIGNER_GUEST_TYPES
} from './constants';

export function getAuthenticationType(auth, idvFailure) {
    const type = Object.keys(auth).filter((key) => auth[key])[0];

    if (type === SIGNER_GUEST_TYPES.saa && idvFailure) {
        return SIGNER_GUEST_TYPES.saaTryAgain;
    }
    return type;
}

export function getPathcodeErrorMessageKey({passcode = '', formatMessage}) {
    if (passcode.length < MIN_PASSCODE_LENGTH) {
        return formatMessage({id: 'oss.components.PanelForm.Field.minLength', defaultMessage: ''}, {
            minLength: MIN_PASSCODE_LENGTH
        });
    }

    if (passcode.length > MAX_PASSCODE_LENGTH) {
        return formatMessage({id: 'oss.components.PanelForm.Field.maxLength', defaultMessage: ''}, {
            maxLength: MAX_PASSCODE_LENGTH
        });
    }

    if (!ONLY_DIGITS_REGEXP.test(passcode)) {
        return formatMessage({id: 'oss.components.PanelForm.Field.onlyDigits', defaultMessage: ''});
    }

    return null;
}

export default function getIsQnaValid({qna}) {
    return !isEmpty(qna) && qna.every(({answer}) => !!answer);
}

export function getAuthTypeObjectToSend({authObject, updatedAuthObject}) {
    const filteredAuthObject = pick(authObject, [
        'kba',
        'qna',
        'saa',
        'sessionId'
    ]);
    return {
        ...filteredAuthObject,
        ...updatedAuthObject
    };
}
