import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import classnames from 'classnames';
import {selectAuthObject, selectTransactionId} from '../../signerUiSlice';
import AppInput from '../../../Core/inputs/AppInput/AppInput';
import getIsQnaValid, {getAuthTypeObjectToSend} from '../utils';
import {SectionWithTitle} from '../../../Core/company/layouts/SectionWithTitle/SectionWithTitle';
import {AppButton} from '../../../Core/buttons/AppButton/AppButton';
import './qnaLogin.less';

export default function QnaLogin({authenticate}) {
    const intl = useIntl();
    const transactionId = useSelector(selectTransactionId);
    const authObject = useSelector(selectAuthObject);
    const {
        loginToken,
        authenticationUrl,
        qna: storedQna
    } = authObject;
    const [qna, setQna] = useState(storedQna);
    const isQnaValid = getIsQnaValid({qna});

    function login(e) {
        e.preventDefault();
        const updatedAuthObject = {
            qna
        };
        const authTypeObjectToSend = getAuthTypeObjectToSend({authObject, updatedAuthObject});

        authenticate({
            variables: {
                loginToken,
                authenticationUrl,
                authObject: authTypeObjectToSend,
                transactionId
            }
        });
    }

    function onAnswerChange(index) {
        return ({target}) => {
            const {value} = target;
            const updatedQna = qna.map((qa, i) => {
                if (i === index) {
                    return {
                        ...qa,
                        answer: value
                    };
                }
                return qa;
            });
            setQna(updatedQna);
        };
    }

    function renderQna(qa, index) {
        const {
            question,
            answer,
            maskInput
        } = qa;
        const className = classnames('qna-input', {
            'is-masked': maskInput
        });

        return (
            <AppInput
                key={index}
                className={className}
                label={question}
                onChange={onAnswerChange(index)}
                value={answer}
                dataWdio="test-input-qna"
            />
        );
    }

    return (
        <SectionWithTitle
            className="signer-login-section qna-login"
            title={intl.formatMessage({id: 'oss.components.QnaLoginView.signerLogin', defaultMessage: ''})}
        >
            <>
                <div className="signer-login-section-description">
                    {intl.formatMessage({id: 'oss.components.QnaLoginView.instruction', defaultMessage: ''})}
                </div>
                <form onSubmit={login}>
                    <div className="qna-container">
                        {qna.map(renderQna)}
                    </div>
                    <div className="signer-login-actions">
                        <AppButton
                            type="submit"
                            onClick={login}
                            className="authenticate-btn"
                            disabled={!isQnaValid}
                            data-wdio="test-button-login"
                        >
                            {intl.formatMessage({id: 'oss.components.QnaLoginView.login', defaultMessage: ''})}
                        </AppButton>
                    </div>
                </form>
            </>
        </SectionWithTitle>
    );
}
