/* eslint-disable import/prefer-default-export */
import {FIELD_TYPES, FIELD_SUBTYPES, FIELD_SETTING_TYPES} from '../../../../../constants';

export function getDefaultSignatureType(isMobileActive, defaultSignatureType = '') {
    switch (defaultSignatureType.toUpperCase()) {
        case 'SIGN':
            return 'FULLNAME';
        case 'CAPTURE':
            return 'CAPTURE';
        case 'MOBILE':
            return isMobileActive ? 'MOBILE_CAPTURE' : 'FULLNAME';
        default:
            return 'FULLNAME';
    }
}

export const SIGNATURE_FIELDS = [
    {
        id: 1,
        type: FIELD_TYPES.SIGNATURE,
        subtype: FIELD_SUBTYPES.CAPTURE,
        isOptional: false

    }, {
        id: 2,
        type: FIELD_TYPES.SIGNATURE,
        subtype: FIELD_SUBTYPES.FULLNAME,
        isOptional: false

    }, {
        id: 3,
        type: FIELD_TYPES.SIGNATURE,
        subtype: FIELD_SUBTYPES.INITIALS,
        isOptional: false

    }, {
        id: 4,
        type: FIELD_TYPES.SIGNATURE,
        subtype: FIELD_SUBTYPES.MOBILE_CAPTURE,
        isOptional: false

    },
    {
        id: 5,
        type: FIELD_TYPES.SIGNATURE,
        isOptional: true
    }

];

export const RECIPIENT_FIELDS = [
    {
        id: 6,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.SIGNER_NAME

    }, {
        id: 7,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.SIGNING_DATE

    }, {
        id: 8,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.SIGNER_TITLE

    }, {
        id: 9,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.SIGNER_COMPANY

    }
];

export const FORM_FIELDS = [
    {
        id: 15,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.DATEPICKER
    },
    {
        id: 10,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.SINGLE_LINE
    }, {
        id: 11,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.MULTI_LINE
    }, {
        id: 12,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.CHECKBOX
    }, {
        id: 13,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.DROPDOWN
    }, {
        id: 14,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.RADIO
    }, {
        id: 16,
        type: FIELD_TYPES.INPUT,
        settingType: FIELD_SETTING_TYPES.LABEL
    }
];
