import React, {forwardRef} from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import classnames from 'classnames';
import './baseInput.less';
import '../common.less';

const inputContainerNameSpace = 'base-input-container';
const inputNameSpace = 'base-input';

const BaseInput = forwardRef(({
    className, disabled, readOnly, dataWdio, ...props
}, ref) => {
    const containerClassName = classnames(inputContainerNameSpace, className, {disabled, readOnly});
    const inputClassName = classnames(inputNameSpace, props?.inputProps?.className, {disabled, readOnly});

    return (
        <OutlinedInput
            {...props}
            ref={ref}
            disabled={disabled}
            className={containerClassName}
            inputProps={{
                ...props?.inputProps,
                className: inputClassName,
                'data-wdio': dataWdio
            }}
        />
    );
});
export default BaseInput;
