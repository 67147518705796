export const STORAGE_KEY_HOST_SIGNER_ID = 'host-signer-id';
export const STORAGE_KEY_TRANSACTION_ID = 'transaction-id';
export const STORAGE_KEY_FROM_SENDER = 'from';
export const DIALOG_HAS_SHOWN_KEY = 'exit-dialog-shown';
export const REDIRECT_VIEW_KEY = 'redirect-view-key';
export const STORAGE_KEY_SESSION = 'session';
// Margin (in pixels) to allow spacing between pages
export const SIGNER_PAGES_MARGIN_BOTTOM = 44;
// Maximum scaling factor to allow showing signature sticky in margin
export const PAGE_PADDING = 10;
export const FEEDBACK_BAR_HEIGHT = 92;
export const PAGES_MARGIN_BOTTOM = 44;
export const VIEW_MIDPOINT_PADDING = 0.1;
export const DECLINE_REASON_CHARACTER_LIMIT = 4000;

export const ROLE_REQUIRED_ACTION = {
    NONE: 'NONE',
    REVIEW: 'REVIEW',
    REVIEW_SIGN: 'REVIEW_SIGN'
};

export const INPERSON_TRANSACTION_STATUS = {
    DONE: 'DONE',
    INPROGRESS: 'INPROGRESS',
    NOTSTARTED: 'NOTSTARTED',
    REVIEWER: 'REVIEWER',
    NONE: 'NONE'
};

export const SIGNER_UI_PAGES = {
    ATTACHMENT: 'ATTACHMENT',
    DECLINE: 'DECLINE',
    HTML: 'HTML',
    IN_PERSON: 'IN_PERSON',
    IN_PERSON_DECLINE: 'IN_PERSON_DECLINE',
    OVERVIEW_PAGE: 'OVERVIEW_PAGE',
    PDF: 'PDF',
    SUMMARY: 'SUMMARY',
    THANK_YOU: 'THANK_YOU',
    THANK_YOU_HOST: 'THANK_YOU_HOST',
    VR_OVERVIEW_PAGE: 'VR_OVERVIEW_PAGE',
    VIRTUAL_ROOM: 'VIRTUAL_ROOM'
};

export const REASSIGN_SCHEME_TYPES = {
    EMAIL: 'NONE',
    QNA: 'CHALLENGE',
    SMS: 'SMS',
    SSO: 'SSO'
};

export const REASSIGN_SCHEME_TYPES_KEYS = {
    NONE: 'email',
    CHALLENGE: 'QnA',
    SMS: 'SMS',
    SSO: 'SSO'
};

export const REASSIGN_STEPS = {
    REASSIGN: 'reassign',
    THANK_YOU: 'thankYou',
    AUTH: 'auth'
};
