import {USER_TYPES} from '../../constants';

export default {
    [USER_TYPES.MEMBER]: [
        'templates_layouts.share_templates',
        'transaction.in_person',
        'transaction.change_signer',
        'templates_layouts.share_layouts',
        'transaction.transaction'
    ],
    [USER_TYPES.MANAGER]: [
        'sender_admin.custom_fields',
        'sender_admin.users',
        'templates_layouts.share_templates',
        'sender_admin.subscription',
        'transaction.transaction',
        'transaction.in_person',
        'transaction.change_signer',
        'sender_admin.sub_account_management',
        'groups.group_signing_management',
        'sender_admin.event_notification',
        'sender_admin.data_management',
        'sender_admin.customization',
        'sender_admin.security_settings',
        'templates_layouts.share_layouts',
        'sender_admin.self_serve_account_settings',
        'transaction.delegation_visibility',
        'sender_admin.reports'
    ],
    [USER_TYPES.OWNER]: [
        'sender_admin.custom_fields',
        'sender_admin.users',
        'templates_layouts.share_templates',
        'sender_admin.subscription',
        'transaction.transaction',
        'transaction.in_person',
        'sender_admin.api_access',
        'transaction.change_signer',
        'sender_admin.sub_account_management',
        'groups.group_signing_management',
        'sender_admin.event_notification',
        'sender_admin.data_management',
        'sender_admin.customization',
        'sender_admin.notary',
        'sender_admin.security_settings',
        'templates_layouts.share_layouts',
        'sender_admin.self_serve_account_settings',
        'transaction.delegation_visibility',
        'sender_admin.reports'
    ]
};
