import React, {useEffect, useState} from 'react';
import isEmpty from 'lodash.isempty';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {HiOutlineXCircle} from '@react-icons/all-files/hi/HiOutlineXCircle';
import Dropzone from '../../shared/Dropzone/Dropzone';
import useAttachmentFiles from '../hooks/useAttachmentFiles';
import useUploadAttachment from '../hooks/useUploadAttachment';
import {selectSelectedAttachmentId, selectTransactionId, setSelectedAttachmentId} from '../signerUiSlice';
import {fetchAttachmentFiles, updateCache, handleError} from './utils';
import {
    getAttachmentById
} from '../utils/selectors';
import useAttachmentThumnails from '../hooks/useAttachmentThumnails';
import UploadedAttachment from './UploadedAttachmentFile/UploadedAttachmentFile';
import useDeleteAttachmentFile from '../hooks/useDeleteAttachmentFile';
import ActionsBar from './ActionsBar/ActionsBar';
import Loading from '../../shared/Loading/Loading';
import {useGraphQlErrorNotification} from '../../../hooks/useErrorNotification';
import {ATTACHMENT_STATUSES} from '../../../../constants';
import './uploadAttachment.less';
import {BorderedTextLabel} from '../../Core/company/labels/BorderedTextLabel/BorderedTextLabel';
import {useCeremony} from '../hooks/queries';

export default function UploadAttachment() {
    const intl = useIntl();
    const transactionId = useSelector(selectTransactionId);
    const selectedAttachmentId = useSelector(selectSelectedAttachmentId);
    const {role} = useCeremony();
    const dispatch = useDispatch();
    const attachments = role?.attachmentRequirements || [];
    const {
        loading
    } = useAttachmentFiles();
    const refetchQueries = () => fetchAttachmentFiles({
        transactionId
    });
    const attachment = getAttachmentById(attachments, selectedAttachmentId);
    const {
        id: attachmentId,
        required,
        name,
        status,
        comment
    } = attachment;
    const {attachmentThumbnails, loading: thumbnailLoading} = useAttachmentThumnails();
    const {
        uploadAttachment, loading: uploading, error: uploadError, reset: resetUpload
    } = useUploadAttachment({options: {refetchQueries}});
    const {
        deleteAttachmentFile, loading: deleting, error: deleteError, reset: resetDelete
    } = useDeleteAttachmentFile({options: {refetchQueries}});
    const [fileIdToDelete, setFileIdToDelete] = useState(null);
    const [fileIdToPreview, setFileIdToPreview] = useState(null);
    const files = attachment?.files || [];
    const isLoading = loading || uploading || thumbnailLoading || deleting;
    const roleHasAttachments = role && role.attachmentRequirements?.length > 0;

    useGraphQlErrorNotification(handleError(uploadError), resetUpload);
    useGraphQlErrorNotification(deleteError, resetDelete);

    function uploadFile(acceptedFiles) {
        if (!isEmpty(acceptedFiles)) {
            uploadAttachment({
                update: updateCache({attachmentId, transactionId}),
                variables: {file: acceptedFiles[0], id: attachmentId, transactionId}
            });
        }
    }

    function openDeleteConfirmationModal(id) {
        return (event) => {
            event.stopPropagation();
            event.preventDefault();
            setFileIdToDelete(id);
        };
    }

    function closeDeleteConfirmationModal(event) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        setFileIdToDelete(null);
    }

    function openPreview(id) {
        return (event) => {
            event.stopPropagation();
            event.preventDefault();
            setFileIdToPreview(id);
        };
    }

    function closePreview(event) {
        event.stopPropagation();
        event.preventDefault();
        setFileIdToPreview(null);
    }

    function deleteFile() {
        deleteAttachmentFile({
            variables: {id: fileIdToDelete, attachmentId, transactionId}
        });
        closeDeleteConfirmationModal();
    }

    /**
     * Checks if there's a rejected attachment, if so, it selects the first rejected attachment.
     * If not, it will select the first attachment.
     * @returns {string} attachment id
     */
    function selectDefaultAttachmentId() {
        const firstRejectedAttachment = role.attachmentRequirements.find((att) => att.status === ATTACHMENT_STATUSES.REJECTED);
        const attachmentToSelect = firstRejectedAttachment || role.attachmentRequirements[0];
        return attachmentToSelect.id;
    }

    useEffect(() => {
        setFileIdToPreview(null);
    }, [attachmentId]);

    useEffect(() => {
        if (roleHasAttachments && !selectedAttachmentId) {
            dispatch(setSelectedAttachmentId(selectDefaultAttachmentId()));
        }
    }, [role]);

    return (
        <div className="signer-ui-upload-attachment">
            <Loading isLoading={isLoading} />
            <section className="upload-attachment-section">
                <div className="title">
                    {name}
                    <BorderedTextLabel
                        className="optional-label"
                    >
                        {intl.formatMessage({
                            id: required
                                ? 'oss.components.SideBar.Uploads.required'
                                : 'oss.components.SideBar.Document.optional',
                            defaultMessage: ''
                        })}
                    </BorderedTextLabel>
                </div>
                <div className="title-message">
                    {intl.formatMessage({id: 'oss.components.UploadAttachment.topBarInstruction', defaultMessage: ''})}
                </div>
            </section>
            <section className="upload-attachment-section">
                <Dropzone
                    updateDocuments={uploadFile}
                    acceptedFilesExt={[]}
                    hideDocuments
                    hideDropzoneTitle
                />
            </section>
            {status === ATTACHMENT_STATUSES.REJECTED && (
                <section className="upload-attachment-section rejected-section">
                    <div className="title">
                        <HiOutlineXCircle className="icon rejected-icon" aria-hidden />
                        {intl.formatMessage({id: 'oss.components.WelcomePage.status.rejected', defaultMessage: ''})}
                    </div>
                    {comment && (
                        <div className="title-message">
                            {comment}
                        </div>
                    )}
                </section>
            )}
            {!isEmpty(files) && (
                <List classes={{root: 'upload-attachment-files'}}>
                    {files.map((file) => (
                        <UploadedAttachment
                            key={`attachment=${file.id}`}
                            file={file}
                            thumbnail={attachmentThumbnails?.find(({id}) => id === file.id)?.content}
                            deleteFile={openDeleteConfirmationModal(file.id)}
                            openPreview={openPreview(file.id)}
                            closePreview={closePreview}
                            isPreviewOpen={fileIdToPreview === file.id}
                        />
                    ))}
                </List>
            )}
            <ActionsBar />
            <Dialog
                maxWidth="md"
                open={!!fileIdToDelete}
                classes={{root: 'attachment-file-delete-modal'}}
            >
                <DialogTitle>
                    {intl.formatMessage({id: 'oss.components.AttachmentView.deleteDialog.title', defaultMessage: ''})}
                </DialogTitle>
                <DialogContent>
                    {intl.formatMessage({id: 'oss.components.AttachmentView.deleteDialog.contentText', defaultMessage: ''})}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={closeDeleteConfirmationModal}
                        classes={{root: 'cancel-btn'}}
                    >
                        {intl.formatMessage({id: 'oss.components.AttachmentView.deleteDialog.cancelButton', defaultMessage: ''})}
                    </Button>
                    <Button
                        onClick={deleteFile}
                        classes={{root: 'ok-btn'}}
                        color="primary"
                        autoFocus
                    >
                        {intl.formatMessage({id: 'oss.components.AttachmentView.deleteDialog.confirmButton', defaultMessage: ''})}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
