import React from 'react';
import {HiOutlineCheckCircle} from '@react-icons/all-files/hi/HiOutlineCheckCircle';
import {HiOutlineUpload} from '@react-icons/all-files/hi/HiOutlineUpload';
import {HiOutlineXCircle} from '@react-icons/all-files/hi/HiOutlineXCircle';
import {ATTACHMENT_STATUSES} from '../../../../constants';

export default function getUploadIconAndStatus(attachment = {}) {
    const {status} = attachment;

    switch (status) {
        case ATTACHMENT_STATUSES.REJECTED:
            return {
                icon: <HiOutlineXCircle className="attachment-icon is-rejected" />,
                status: 'oss.components.WelcomePage.status.rejected'
            };
        case ATTACHMENT_STATUSES.COMPLETE:
            return {
                icon: <HiOutlineCheckCircle className="attachment-icon is-done" />,
                status: 'oss.components.WelcomePage.status.done'
            };
        default:
            return {
                icon: <HiOutlineUpload className="attachment-icon" />,
                status: 'oss.components.WelcomePage.status.toDo'
            };
    }
}
