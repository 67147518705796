import React from 'react';
import classnames from 'classnames';
import {BiImageAdd} from 'react-icons/bi';
import './imageThumbnail.less';

const ImageThumbnail = ({icon, ...restProps}) => {
    const containerClassName = classnames('image-thumbnail');

    return (
        <div className={containerClassName} {...restProps}>
            {icon || <BiImageAdd className="icon" aria-hidden /> }
        </div>
    );
};
export default ImageThumbnail;
