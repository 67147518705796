import {useQuery} from '@apollo/client';
import {useSelector} from 'react-redux';
import {GET_THANK_YOU_HOST_OPTIONS} from '../../../../../gql/queries';
import {selectTransactionId} from '../../signerUiSlice';

export const useInPersonThankTouHostScreenOptions = () => {
    const transactionId = useSelector(selectTransactionId);
    const {
        data: {signingOptions = {}} = {}, error, loading, refetch
    } = useQuery(GET_THANK_YOU_HOST_OPTIONS, {
        variables: {transactionId}
    });

    return {
        signingOptions, error, loading, refetch
    };
};
