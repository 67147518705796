import {useDispatch} from 'react-redux';
import {getHostSessionId, setSignerSession} from '../../../utils/storageSelectors';
import {clearCachedSignatures} from '../signerUiSlice';
import {useCeremony} from './queries';

export default function useSetHostToSignerSession() {
    const dispatch = useDispatch();
    const {refetch} = useCeremony();

    async function setHostToSignerSession() {
        const hostSesssionId = getHostSessionId();
        dispatch(clearCachedSignatures());
        setSignerSession(hostSesssionId);
        await refetch();
    }
    return {setHostToSignerSession};
}
