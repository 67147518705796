import React from 'react';
import classNames from 'classnames';
import AppInput from '../AppInput/AppInput';

export default function AppTextField({
    className, inputRef, InputProps, ...rest
}) {
    const containerClassName = classNames('app-text-field', className);

    return (
        <AppInput
            {...rest}
            {...InputProps}
            className={containerClassName}
            inputRef={inputRef || InputProps?.ref}
        />
    );
}
