import {STORAGE_KEY_SESSION} from '../../constants';

const IS_OLD_SSIGNER_UI_KEY = 'isOldSignerUi';

export const isSessionStorageAllowed = () => {
    try {
        return window.sessionStorage !== null;
    } catch (e) {
        return false;
    }
};

export const isLocalStorageAllowed = () => {
    try {
        return window.localStorage !== null;
    } catch (e) {
        return false;
    }
};

const saveSession = (session) => {
    if (isSessionStorageAllowed()) {
        return window.sessionStorage.setItem(STORAGE_KEY_SESSION, JSON.stringify(session));
    }
    window.sessionStore = JSON.stringify(session);
};

export const clearSessions = () => {
    if (isSessionStorageAllowed()) {
        window.sessionStorage.removeItem(STORAGE_KEY_SESSION);
    }
    window.sessionStore = '';
};

const retrieveSession = () => {
    if (isSessionStorageAllowed() && window.sessionStorage.session) {
        return JSON.parse(window.sessionStorage.session);
    }
    if (window.sessionStore) {
        return JSON.parse(window.sessionStore);
    }
    return {};
};

export const getCurrentSessionId = () => {
    const session = retrieveSession();
    return session?.signer || session?.host || '';
};
export const getHostSessionId = () => retrieveSession()?.host;

export const setHostSession = (sessionId) => saveSession({host: sessionId});
export const restoreHostSession = () => saveSession({host: getHostSessionId()});
export const setSignerSession = (sessionId) => saveSession({host: getHostSessionId(), signer: sessionId});

export const getValueForKey = (name) => {
    if (!isSessionStorageAllowed()) return false;
    return window.sessionStorage.getItem(name);
};

export const setValueForKey = (name, value) => {
    if (!isSessionStorageAllowed()) return;
    window.sessionStorage.setItem(name, value);
};

export const valueFromTempStorage = (key) => {
    if (!window.tempStore) {
        window.tempStore = {};
    }
    return !!window.tempStore?.[key];
};

export const isHostAndSignerInSessionStorage = (key) => {
    const hostSignerValues = getValueForKey(key);
    const {host, signer} = hostSignerValues
        ? JSON.parse(hostSignerValues)
        : JSON.parse(valueFromTempStorage(key));
    return !!host && !!signer;
};

export const clearStorageForKey = (name) => {
    if (!isSessionStorageAllowed()) return false;
    window.sessionStorage.removeItem(name);
};

export const setIsOldSignerUi = () => {
    setValueForKey(IS_OLD_SSIGNER_UI_KEY, true);
};

export const getIsOldSignerUi = () => getValueForKey(IS_OLD_SSIGNER_UI_KEY);
