import {ROLE_REQUIRED_ACTION} from '../../../../constants';

export default function getRoleActionStatusText(action) {
    switch (action) {
        case ROLE_REQUIRED_ACTION.REVIEW:
            return 'oss.components.InPerson.WelcomeScreen.optionalReview';
        case ROLE_REQUIRED_ACTION.REVIEW_SIGN:
            return 'oss.components.InPerson.WelcomeScreen.actionsRequired';
        case ROLE_REQUIRED_ACTION.NONE:
        default:
            return null;
    }
}
