import {useMutation} from '@apollo/client';
import DELETE_ATTACHMENT_FILE from '../../../../gql/deleteAttachmentFile.gql';

export default function useDeleteAttachmentFile({options} = {}) {
    const [deleteAttachmentFile, {loading, error, reset}] = useMutation(DELETE_ATTACHMENT_FILE, options);

    return {
        deleteAttachmentFile, loading, error, reset
    };
}
