/* eslint-disable no-unused-vars */
import {createSlice, current} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import get from 'lodash.get';
import httpClient from '../utils/httpClient';
import getTranslations from '../utils/getTranslations';
import getCookieExpirationDate from '../../server/utils/getCookieExpirationDate';
import {
    BASE_PATH,
    DASHBOARD_PATH,
    defaultIntroductionInfo,
    DEFAULT_LOCALE,
    THEMES,
    AUTOMATION_ENV
} from '../../constants';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        translations: {},
        locale: 'nl',
        theme: THEMES.LIGHT,
        config: {},
        refererUrl: DASHBOARD_PATH,
        isNavOpen: false,
        shouldShowGDPRBunner: true,
        news: [],
        notifications: [],
        infoLinks: [],
        lastLoadedNewsLocale: '',
        lastLoadedNotificationsLocale: '',
        isFetchingNews: false,
        isFetchingNotifications: false,
        introductionInfo: defaultIntroductionInfo,
        isGlobalLoading: false
    },
    reducers: {
        setLocale: (state, action) => {
            const {
                locale,
                shouldSetCookie
            } = action.payload;
            state.locale = locale;
            const env = current(state).config.VS_ENV;
            if (shouldSetCookie) {
                Cookies.set('locale', locale, env !== AUTOMATION_ENV
                    ? {sameSite: 'none', secure: true, expires: getCookieExpirationDate()}
                    : {expires: getCookieExpirationDate()});
            }
        },
        setTheme: (state, action) => {
            const {
                theme = THEMES.LIGHT,
                shouldSetCookie
            } = action.payload;
            state.theme = theme;
            const env = current(state).config.VS_ENV;
            if (shouldSetCookie) {
                Cookies.set('theme', theme, env !== AUTOMATION_ENV
                    ? {sameSite: 'none', secure: true, expires: getCookieExpirationDate()}
                    : {expires: getCookieExpirationDate()});
            }
        },
        setTranslations: (state, action) => {
            state.translations = action.payload;
        },
        resetState: (state) => {
            // eslint-disable-next-line no-param-reassign
            state = undefined;
        },
        setConfig: (state, action) => {
            state.config = action.payload;
        },
        setRefererUrl: (state, action) => {
            state.refererUrl = action.payload;
        },
        setIsNavOpen: (state, action) => {
            state.isNavOpen = action.payload;
        },
        setShouldShowGDPRBunner: (state, action) => {
            state.shouldShowGDPRBunner = action.payload;
        },
        setNews: (state, action) => {
            state.news = action.payload;
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        setInfoLinks: (state, action) => {
            state.infoLinks = action.payload;
        },
        setLastLoadedNewsLocale: (state, action) => {
            state.lastLoadedNewsLocale = action.payload;
        },
        setLastLoadedNotificationsLocale: (state, action) => {
            state.lastLoadedNotificationsLocale = action.payload;
        },
        setIsFetchingNews: (state, action) => {
            state.isFetchingNews = action.payload;
        },
        setIsFetchingNotifications: (state, action) => {
            state.isFetchingNotifications = action.payload;
        },
        setIntroductionInfo: (state, action) => {
            if (action.payload.reset) {
                state.introductionInfo = defaultIntroductionInfo;
            } else {
                state.introductionInfo = {
                    ...state.introductionInfo,
                    ...action.payload,
                    validation: {
                        ...state.introductionInfo.validation,
                        ...action.payload.validation
                    }
                };
            }
        },
        setIsGlobalLoading: (state, action) => {
            state.isGlobalLoading = action.payload;
        }
    }
});

export const {
    setLocale,
    setTheme,
    setTranslations,
    resetState,
    setConfig,
    setRefererUrl,
    setIsNavOpen,
    setShouldShowGDPRBunner,
    setNews,
    setNotifications,
    setInfoLinks,
    setLastLoadedNewsLocale,
    setLastLoadedNotificationsLocale,
    setIsFetchingNews,
    setIsFetchingNotifications,
    setIntroductionInfo,
    setIsGlobalLoading
} = appSlice.actions;
export const loadTranslations = (localeParam) => async (dispatch, getState) => {
    const {locale} = getState().app;
    const translations = await getTranslations(localeParam || locale);
    dispatch(setTranslations(translations.default));
};

export const fetchNews = ({
    baseUrl,
    locale: fallbackLocale
} = {}) => async (dispatch, getState) => {
    const {locale, config} = getState().app;
    const localeToFetch = fallbackLocale || locale;
    const urlPrefix = config.VS_ENV === 'my' ? '' : 'try.';
    const servicePath = `api/custom/${localeToFetch}/${urlPrefix}newsItems.loginpage`;
    const url = baseUrl ? `${baseUrl}/${servicePath}` : `${BASE_PATH}/public/${servicePath}`;
    dispatch(setIsFetchingNews(true));

    try {
        const result = await httpClient.get(url);
        const news = get(result, 'data.items', []);

        dispatch(setNews(news));
        dispatch(setLastLoadedNewsLocale(localeToFetch));
        dispatch(setIsFetchingNews(false));
        return result.data;
    } catch (err) {
        dispatch(setIsFetchingNews(false));
        if (localeToFetch !== DEFAULT_LOCALE) {
            await dispatch(fetchNews({locale: DEFAULT_LOCALE}));
        }
        return false;
    }
};

export const fetchNotifications = ({
    baseUrl,
    locale: fallbackLocale
} = {}) => async (dispatch, getState) => {
    const {locale, config} = getState().app;
    const localeToFetch = fallbackLocale || locale;
    const urlPrefix = config.VS_ENV === 'my' ? '' : 'try.';
    const servicePath = `api/custom/${localeToFetch}/${urlPrefix}notificationInfo.loginpage`;
    const url = baseUrl ? `${baseUrl}/${servicePath}` : `${BASE_PATH}/public/${servicePath}`;
    dispatch(setIsFetchingNotifications(true));

    try {
        const result = await httpClient.get(url);
        const notifications = get(result, 'data.notifications', []);
        dispatch(setNotifications(notifications));
        dispatch(setLastLoadedNotificationsLocale(localeToFetch));
        dispatch(setIsFetchingNotifications(false));
        return result.data;
    } catch (err) {
        dispatch(setIsFetchingNotifications(false));
        if (localeToFetch !== DEFAULT_LOCALE) {
            dispatch(fetchNotifications({locale: DEFAULT_LOCALE}));
        }
        return false;
    }
};

export const fetchInfoLinks = ({
    baseUrl,
    headers,
    locale: fallbackLocale
} = {}) => async (dispatch, getState) => {
    const options = {};
    const {locale} = getState().app;
    const localeToFetch = fallbackLocale || locale;
    const servicePath = `api/custom/${localeToFetch}/block-information`;
    const url = baseUrl ? `${baseUrl}/${servicePath}` : `${BASE_PATH}/public/${servicePath}`;

    if (headers) {
        options.headers = headers;
    }
    try {
        const result = await httpClient.get(url);
        const links = get(result, 'data.items', []);
        dispatch(setInfoLinks(links));
        return result.data;
    } catch (err) {
        if (localeToFetch !== DEFAULT_LOCALE) {
            dispatch(fetchInfoLinks({locale: DEFAULT_LOCALE}));
        }
        return false;
    }
};

export const updateIntroductionInfo = (data) => (dispatch) => {
    dispatch(setIntroductionInfo(data));
};

export const selectTranslations = (state) => state.app.translations;
export const selectLocale = (state) => state.app.locale;
export const selectTheme = (state) => state.app.theme;
export const selectConfig = (state) => state.app.config;
export const selectRefererUrl = (state) => state.app.refererUrl;
export const selectIsNavOpen = (state) => state.app.isNavOpen;
export const selectShouldShowGDPRBunner = (state) => state.app.shouldShowGDPRBunner;
export const selectNews = (state) => state.app.news;
export const selectNotifications = (state) => state.app.notifications;
export const selectInfoLinks = (state) => state.app.infoLinks;
export const selectIsFetchingNews = (state) => state.app.isFetchingNews;
export const selectIsFetchingNotifications = (state) => state.app.isFetchingNotifications;
export const selectLastLoadedNewsLocale = (state) => state.app.lastLoadedNewsLocale;
export const selectLastLoadedNotificationsLocale = (state) => state
    .app.lastLoadedNotificationsLocale;
export const selectIntroductionInfo = (state) => state.app.introductionInfo;
export const selectIsGlobalLoading = (state) => state.app.isGlobalLoading;

export default appSlice.reducer;
