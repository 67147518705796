/* eslint-disable no-param-reassign */
import clone from 'lodash.clonedeep';

const defaults = {
    wsUrl: 'wss://localhost.esignlive.com',
    portCandidates: [26666, 31222, 32444, 44555, 47777, 48888],
    pollInterval: 10000,
    msgKeyBase: 'esl.personal_certificate_client.error',
    errCodesMsgKey: {
        11: 'unavailable_method',
        16: 'invalid_token_signature',
        17: 'invalid_token_signature_certificate',
        18: 'invalid_signer_certificate',
        19: 'unsigned_token',
        20: 'untrusted_token_signature_certificate',
        21: 'invalid_token',
        22: 'cannot_build_certificate_chain',
        27: 'nonce_mismatch',
        28: 'signing_session_timeout',
        41: 'general',
        42: 'tampered_message',
        101: 'capi_internal_function',
        102: 'load_parameters',
        103: 'signing_ceremony_code_exception',
        104: 'finish_approvals_code_exception',
        105: 'signing_ceremony_choice_code_exception',
        106: 'empty_certificate_list',
        107: 'unfindable_chosen_certificate'
    },
    subErrCodesMsgKey: {
        1: 'service.indeterminate_cert_choice',
        2: 'service.unrecognizable_cert_choice_issuername',
        3: 'service.unrecognizable_cert_choice_serial',
        6: 'service.missing_token_response_from_socket',
        7: 'service.missing_json_array',
        30: 'certificate.expired',
        31: 'certificate.unknown_expiration',
        32: 'certificate.revoked',
        33: 'certificate.unknown_revocation_status',
        34: 'certificate.verification',
        35: 'certificate.unknown_signature_validity',
        36: 'certificate.incomplete_path',
        37: 'certificate.invalid_path',
        38: 'certificate.untrusted_issuer',
        39: 'certificate.unknown_trust_status',
        40: 'certificate.self_signed'
    }
};

const readyStates = {
    CONNECTING: 0, OPEN: 1, CLOSING: 2, CLOSED: 3
};
const wait = {
    interval: 100,
    maxIterations: 100
};

const connectionParams = {
    SignMethods: 'CERT,REALTIME,EPERSONA',
    ClientMode: 'SIGN_DATA'
};

function waitForSocketConnection(connection, callback, iteration) {
    if (connection.readyState === readyStates.OPEN) {
        callback();
    } else if (connection.readyState === readyStates.CONNECTING && iteration < wait.maxIterations) {
        setTimeout(() => {
            waitForSocketConnection(connection, callback, iteration + 1);
        }, wait.interval);
    } else {
    // Closing when not expecting it, should trigger socket error here
    }
}

function handleError(msg, handlers) {
    if (msg.family !== undefined) {
        handlers.onError(msg);
        return;
    }

    const err = {
        family: 'UNKNOWN',
        code: msg.errCode,
        message: msg.errMessage
    };

    err.messageKeys = [];

    if (msg.errCode !== undefined && defaults.errCodesMsgKey[msg.errCode]) {
        err.messageKeys.push(`${defaults.msgKeyBase}.${defaults.errCodesMsgKey[msg.errCode]}`);
    }

    if (msg.subCode !== undefined) {
        err.subCode = msg.subCode;
        err.messageKeys = err.messageKeys.concat(
            msg.subCode
                .filter((subCode) => defaults.subErrCodesMsgKey[subCode.code] !== undefined)
                .map((subCode) => `${defaults.msgKeyBase}.${defaults.subErrCodesMsgKey[subCode.code]}`)
        );
    }

    if (err.messageKeys.length === 0) {
        delete err.messageKeys;
    }

    if (msg.serverCertDetails) {
        err.serverCertDetails = msg.serverCertDetails;
    }

    if (msg.type) {
        err.type = msg.type;
    }

    if (
        msg.errCode === '21'
    || msg.errCode === '20'
    || msg.errCode === '19'
    || msg.errCode === '17'
    || msg.errCode === '16'
    ) {
        err.family = 'BAD_CERTIFICATE_SIGNING_TOKEN';
        if (handlers.onBadCertificateSigningToken) {
            handlers.onBadCertificateSigningToken(err);
            return;
        }
    }

    if (msg.errCode === '7' || msg.errCode === '18' || msg.errCode === '105') {
        err.family = 'BAD_CERTIFICATE';
        if (handlers.onBadCertificate) {
            handlers.onBadCertificate(err);
            return;
        }
    }

    if (msg.errCode === '28') {
        err.family = 'BAD_TOKEN_TO_SIGN';
        if (handlers.onBadSigningToken) {
            handlers.onBadTokenToSign();
            return;
        }
    }

    handlers.onError(err);
}

function processCertificateDetails(certificatesListDetailed, certificatesList) {
    return certificatesListDetailed.map((certificate) => {
        const certificateShort = certificatesList.filter((cert) => cert.sn === certificate.sn && cert.issuer === certificate.issuer)[0];
        const expiryDate = new Date(certificate.details['Valid to']);
        return {
            sn: certificate.sn,
            issuer: certificateShort.issuer,
            issuerDN: certificate.details.Issuer,
            subject: certificateShort.subject,
            subjectDN: certificate.details.Subject,
            expiryDate: `${`0${expiryDate.getUTCDate()}`.slice(-2)}/${`0${expiryDate.getUTCMonth()
        + 1}`.slice(-2)}/${expiryDate.getUTCFullYear()}`,
            validFrom: certificate.details['Valid from'],
            validTo: certificate.details['Valid to'],
            publicKey: certificate.details['Public key'],
            certificateThumbprint: certificate.details['Certificate thumbprint'],
            keyUsage: certificate.details['Key usage']
        };
    });
}

function assignWsHandlers(connection, handlers) {
    connection.onerror = handlers.onSocketError ? handlers.onSocketError : () => { };

    connection.onmessage = (evt) => {
        const msg = JSON.parse(evt.data);
        if (Object.prototype.hasOwnProperty.call(msg, 'version')) {
            handlers.onVersion(msg.version);
        } else if (Object.prototype.hasOwnProperty.call(msg, 'certdetails')) {
            handlers.onCertificatesList(processCertificateDetails(msg.certdetails, msg.certList));
        } else if (
            msg.type
      && msg.type === 'INFO'
      && msg.message
      && msg.message.startsWith('Signing Ceremony - Cert chosen:')
      && handlers.onCertificateConfirmation
        ) {
            handlers.onCertificateConfirmation(msg);
        } else if (Object.prototype.hasOwnProperty.call(msg, 'outToken')) {
            handlers.onSignerToken(msg.outToken);
        } else if (Object.prototype.hasOwnProperty.call(msg, 'signedToken')) {
            handlers.onSignedToken(msg.signedToken);
        } else if (Object.prototype.hasOwnProperty.call(msg, 'errMessage')) {
            handleError(msg, handlers);
        } else if (handlers.onMessage) {
            handlers.onMessage(msg);
        }
    };
}

function connectWithOneTry(params) {
    const wsUrl = params.url;
    const wsCandidatePorts = params.portCandidates;
    let failedConnections = 0;
    let successfulConnections = 0;

    wsCandidatePorts.forEach((port) => {
        let connection;
        try {
            if (typeof window.MozWebSocket !== 'undefined') {
                connection = new window.MozWebSocket(`${wsUrl}:${port}`, 'execute');
            } else {
                connection = new window.WebSocket(`${wsUrl}:${port}`, 'execute');
            }
        } catch (err) {
            throw new Error(err);
        }

        connection.onopen = () => {
            successfulConnections += 1;
            if (successfulConnections > 1) {
                connection.close();
                handleError(
                    {
                        family: 'BAD_ENVIRONMENT',
                        message: 'Managed to connect to native bridge more than once on different ports!'
                    },
                    params
                );
            } else {
                assignWsHandlers(connection, params);
                params.onConnectionSuccess(connection, port);
            }
        };

        connection.onerror = () => {
            connection.close();
            failedConnections += 1;
            if (failedConnections >= wsCandidatePorts.length) {
                params.onConnectionFailure(new Error('Connection to native bridge service failed'));
            }
        };
    });
}

function connectWithRetries(params) {
    if (!params.setWithRetries) {
        const originalConnFailureHandler = params.onConnectionFailure;
        params = clone(params);
        params.setWithRetries = true;
        params.pollInterval = params.pollInterval !== undefined ? params.pollInterval : defaults.pollInterval;
        params.onConnectionFailure = (err) => {
            if (originalConnFailureHandler) {
                originalConnFailureHandler(err);
            }
            setTimeout(() => {
                connectWithRetries(params);
            }, params.pollInterval);
        };
    }
    connectWithOneTry(params);
}

function connect(params) {
    params = clone(params);
    params.url = params.url !== undefined ? params.url : defaults.wsUrl;
    params.portCandidates = params.portCandidates !== undefined ? params.portCandidates : defaults.portCandidates;
    const connectFn = params.retryConnection === true ? connectWithRetries : connectWithOneTry;
    connectFn(params);
}

function disconnect(connection) {
    if (connection && connection.close) {
        connection.close();
    }
}

function passParameters(connection, params) {
    waitForSocketConnection(
        connection,
        () => {
            if (connection && connection.send) {
                params = params !== undefined ? params : {};
                const fullParams = Object.keys(params).reduce((aggregate, key) => {
                    if (key === 'certificateSigningToken') {
                        aggregate.ApprovalXML = params[key];
                    } else {
                        aggregate[key] = params[key];
                    }
                    return aggregate;
                }, clone(connectionParams));
                connection.send(JSON.stringify({params: fullParams}));
            }
        },
        0
    );
}

function selectCertificate(connection, certificate) {
    waitForSocketConnection(
        connection,
        () => {
            connection.send(
                JSON.stringify({
                    certName: {
                        issuer: certificate.issuer,
                        sn: certificate.sn
                    }
                })
            );
        },
        0
    );
}

function sign(connection, tokenToSign) {
    waitForSocketConnection(
        connection,
        () => {
            connection.send(
                JSON.stringify({
                    inToken: tokenToSign
                })
            );
        },
        0
    );
}

export {
    connect,
    disconnect,
    passParameters,
    selectCertificate,
    sign
};
