import {useMutation} from '@apollo/client';
import {
    useCallback,
    useMemo,
    useEffect
} from 'react';
import queryString from 'query-string';
import ACCEPT_DISCLOSURE from '../../../../../gql/acceptDisclosure.gql';
import CONFIRM_DOCUMENT from '../../../../../gql/confirmDocument.gql';
import {updateCache} from '../../SigningDocumentView/BottomBar/confirmDocumentUtils';
import {hardRedirectTo} from '../../../../utils/helpers';
import {useCeremony, useExternalVerification} from '../queries';
import IFrameEventNotifications from '../../../../utils/iFrameNotifyer/IFrameEventNotifications';
import {
    DOCUMENT_ACCEPT_ERROR_EVENT,
    DOCUMENT_ACCEPT_STARTED_EVENT,
    DOCUMENT_ACCEPT_SUCCESS_EVENT,
    DOCUMENT_CONFIRM_ERROR_EVENT,
    DOCUMENT_CONFIRM_STARTED_EVENT,
    DOCUMENT_CONFIRM_SUCCESS_EVENT
} from '../../../../utils/iFrameNotifyer/events';

export const useConfirmDocument = ({
    onSign, document
}) => {
    const {role, transaction, refetch: refetchCeremony} = useCeremony();
    const {verificationType, signerId} = role;
    const {isDisclosure} = document;
    const transactionId = transaction.id;
    const documentId = transaction?.initialDocumentId;
    const iFrameEventNotifications = IFrameEventNotifications.getInstance();

    /**
     * @param url
     * If url is present, hard redirect to provided url.
     */
    const redirectToExternalUrlIfReady = useCallback((url) => {
        if (!url) return null;
        hardRedirectTo(url);
    }, []);

    /**
     * If verificationType.method is EXTERNAL, startExternalVerificationProcess is called.
     * startExternalVerificationProcess is a function that is defined in useExternalVerification.js.
     * It is used to start the external verification process.
     */
    const {
        startProcess: startExternalVerificationProcess
    } = useExternalVerification({
        documentId,
        onUrlReady: redirectToExternalUrlIfReady,
        signerId,
        transactionId,
        verificationType
    });
    // TODO: UpdateCache should be deprecated

    const [confirmDocumentMutation, {loading}] = useMutation(
        isDisclosure ? ACCEPT_DISCLOSURE : CONFIRM_DOCUMENT,
        {

            update: updateCache({
                onSign,
                role,
                transactionId: transaction.id
            })
        }
    );

    /**
     * Deletes the verifcationResponseToken from the URL.
     * This is needed to prevent the external signing service to provide
     * us a request URL that's too long.
     *
     * @returns redirect to url without verificationResponseToken
     */
    const deleteVerificationResponseToken = () => {
        if (!window.location.href) return null;

        const searchTerm = 'verificationResponseToken';
        const indexOfFirstLetter = window.location.href.indexOf(searchTerm);
        const newUrl = window.location.href.substring(0, indexOfFirstLetter - 1);
        /**
         * In some cases the newUrl is empty.
         * We need to check if the newUrl does have a value. Otherwise it will cause an error
         */
        if (newUrl.length < 10) return null;

        return hardRedirectTo(newUrl);
    };

    /**
     * @param signerVerificationToken
     * Handles the confirm of the document with the external verification token.
     * @returns {Promise<unknown>}
     */
    async function confirmWithExternalVerificationToken({signerVerificationToken}) {
        await confirmDocumentMutation({
            variables: {
                id: document.id,
                transactionId: transaction.id,
                options: {signerVerificationToken}
            }

        });
        await refetchCeremony();
    }

    /**
     * Start the confirm of the document.
     * Checks which route needs to be taken.
     * The possible routes are:
     *
     * - Default confirm
     * - External verification
     * - Personal certificate signing
     */

    const postEvent = async (event) => {
        try {
            await iFrameEventNotifications.postEvent(event);
        } catch (e) {
            console.error('Event posting error:', e);
        }
    };

    const handleConfirm = async ({options = {}} = {}) => {
        try {
            await postEvent(isDisclosure ? DOCUMENT_ACCEPT_STARTED_EVENT : DOCUMENT_CONFIRM_STARTED_EVENT);

            let ceremonyRes;

            if (verificationType?.method === 'EXTERNAL') {
                await startExternalVerificationProcess(); // Assuming this is an async function
                ceremonyRes = await refetchCeremony();
            } else {
                await confirmDocumentMutation({
                    variables: {
                        id: document.id,
                        transactionId: transaction.id,
                        options
                    }
                });
                ceremonyRes = await refetchCeremony();
            }

            await postEvent(isDisclosure ? DOCUMENT_ACCEPT_SUCCESS_EVENT : DOCUMENT_CONFIRM_SUCCESS_EVENT);

            return ceremonyRes;
        } catch (e) {
            await postEvent(isDisclosure ? DOCUMENT_ACCEPT_ERROR_EVENT : DOCUMENT_CONFIRM_ERROR_EVENT);
        }
    };

    /**
     * The URL is parsed to extract the params. This is needed to get the signerVerificationToken.
     */
    const parsedURL = queryString.parse(window.location.href) || {};

    /**
     * Triggers when the verificationResponseToken is present in the URL params.
     * This is used to confirm the document with the external verification token.
     *
     * After the confirmation is finished, the verificationResponseToken is deleted from the URL.
     */
    useEffect(() => {
        const {verificationResponseToken} = parsedURL;

        const handleExternalConfirmation = async () => {
            await confirmWithExternalVerificationToken({signerVerificationToken: verificationResponseToken});
            return deleteVerificationResponseToken();
        };

        if (verificationResponseToken) {
            return handleExternalConfirmation();
        }
    }, [parsedURL.verificationResponseToken]);

    return useMemo(() => ({handleConfirm, loading}), [handleConfirm, loading]);
};
