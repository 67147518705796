export const options = {
    id: 1,
    size: 4,
    smoothing: 0.7,
    thinning: 0,
    streamline: 0.4,
    easing: (t) => t,
    start: {
        taper: 50,
        cap: true,
        easing: (t) => t * (2 - t)
    },
    end: {
        taper: 50,
        cap: true,
        easing: (t) => t * (2 - t)
    }
};

export const defaultSignatureColor = '#284283';
