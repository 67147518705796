/* eslint-disable no-nested-ternary */
import React from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import useAttachmentFiles from '../../hooks/useAttachmentFiles';
import {
    doneThankYou,
    goToSummaryPage,
    openFirstPendingDocument,
    openNextPendingUpload,
    selectIsVirtualRoomHost,
    selectSelectedAttachmentId
} from '../../signerUiSlice';
import {
    getAttachmentById,
    getIsSigningCompleted,
    getIsSigningCompleteByAll,
    isAnyAttachmentNotUploaded,
    isEveryRequiredAttachmentUploaded,
    isPendingUploads,
    isTransactionCompleted,
    setAutoredirectIfAllowed
} from '../../utils/selectors';
import {useCeremony} from '../../hooks/queries';
import useOverviewDocuments from '../../hooks/useOverviewDocuments';
import useInPerson from '../../hooks/useInPerson';
import IFrameEventNotifications from '../../../../utils/iFrameNotifyer/IFrameEventNotifications';
import {
    DOCUMENT_NAVIGATION_STARTED_EVENT,
    SIGNER_COMPLETE_STARTED_REVIEWED,
    SIGNER_COMPLETE_SUCCESS_REVIEWED
} from '../../../../utils/iFrameNotifyer/events';
import './actionsBar.less';

export default function ActionsBar() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const selectedAttachmentId = useSelector(selectSelectedAttachmentId);
    const isVirtualRoomHost = useSelector(selectIsVirtualRoomHost);
    const {isInPersonCeremonyControlledByHost, inPersonCeremonyHost} = useInPerson();
    const {role = {}} = useAttachmentFiles();
    const {
        attachmentRequirements: attachments = []
    } = role;
    const {transaction} = useCeremony();
    const {
        transaction: {
            documents = []
        }
    } = useOverviewDocuments();
    const {
        isVirtualRoomSigning
    } = transaction;
    const attachment = getAttachmentById(attachments, selectedAttachmentId);
    const {
        id: attachmentId
    } = attachment;
    const signingCompleted = isVirtualRoomSigning
        ? getIsSigningCompleteByAll(documents)
        : getIsSigningCompleted(documents);
    const isRequiredAttachmentsUploaded = isEveryRequiredAttachmentUploaded(attachments);
    const pendingUploads = isAnyAttachmentNotUploaded(attachments);
    const isNextPendingUploads = isPendingUploads(attachments, attachmentId);
    const allUploadsDoneText = isVirtualRoomSigning
        ? isVirtualRoomHost
            ? 'oss.components.AttachmentBottomBar.allUploadsDoneByAll'
            : 'oss.components.AttachmentBottomBar.allUploadsDoneForSigner'
        : 'oss.components.AttachmentBottomBar.allUploadsDone';
    const allTasksDoneText = isVirtualRoomSigning
        ? isVirtualRoomHost
            ? 'oss.components.AttachmentBottomBar.allTasksDoneByAll'
            : 'oss.components.AttachmentBottomBar.allUploadsDoneForSigner'
        : 'oss.components.AttachmentBottomBar.allTasksDone';
    const uploadsPendingForSignerText = isRequiredAttachmentsUploaded
        ? 'oss.components.AttachmentBottomBar.optionalUploadsPendingForSigner'
        : 'oss.components.AttachmentBottomBar.uploadsPendingForSigner';
    const signingAndUploadsPendingText = isVirtualRoomSigning
        ? isVirtualRoomHost
            ? 'oss.components.AttachmentBottomBar.uploadsPendingByAll'
            : uploadsPendingForSignerText
        : 'oss.components.AttachmentBottomBar.uploadsPendingByAll';
    const uploadsPendingByAllWithSigningCompletedText = isRequiredAttachmentsUploaded
        ? 'oss.components.AttachmentBottomBar.uploadsPendingByAllWithSigningCompleted'
        : 'oss.components.AttachmentBottomBar.requiredUploadsPendingByAllWithSigningCompleted';
    const requiredOrOptionalUploadsPendingText = isRequiredAttachmentsUploaded
        ? 'oss.components.AttachmentBottomBar.optionalUploadsPending'
        : 'oss.components.AttachmentBottomBar.requiredUploadsPendingByAllWithSigningCompleted';
    const pendingUploadsText = isVirtualRoomSigning
        ? isVirtualRoomHost
            ? uploadsPendingByAllWithSigningCompletedText
            : uploadsPendingForSignerText
        : requiredOrOptionalUploadsPendingText;
    const messagePendingActions = pendingUploads ? signingAndUploadsPendingText : allUploadsDoneText;
    const messageAllRequiredActionsCompleted = pendingUploads ? pendingUploadsText : allTasksDoneText;
    const showGoToDocumentsButton = isVirtualRoomSigning
        ? isVirtualRoomHost && !signingCompleted
        : !signingCompleted;
    const showFinishButton = isVirtualRoomSigning
        ? isVirtualRoomHost && signingCompleted && isRequiredAttachmentsUploaded
        : isRequiredAttachmentsUploaded && signingCompleted;
    const message = intl.formatMessage({
        id: signingCompleted ? messageAllRequiredActionsCompleted : messagePendingActions, defaultMessage: ''
    });
    const iFrameEventNotifications = IFrameEventNotifications.getInstance();

    async function finishHandler() {
        setAutoredirectIfAllowed();
        await iFrameEventNotifications.postEvent(
            SIGNER_COMPLETE_STARTED_REVIEWED
        );

        if (isInPersonCeremonyControlledByHost) {
            dispatch(doneThankYou({
                role,
                roles: transaction.roles,
                isTransactionCompleted: isTransactionCompleted(transaction),
                inPersonCeremonyHost
            }));
        } else {
            dispatch(goToSummaryPage());
        }

        await iFrameEventNotifications.postEvent(
            SIGNER_COMPLETE_SUCCESS_REVIEWED
        );
    }

    async function nextDocumentHandler() {
        await iFrameEventNotifications.postEvent(
            DOCUMENT_NAVIGATION_STARTED_EVENT
        );
        dispatch(openFirstPendingDocument(documents));
    }

    function nextUploadHandler() {
        dispatch(openNextPendingUpload(attachments));
    }

    function backToHostHandler() {
        // TODO: VR staff: passControlToHost();
    }

    return (
        <section className="upload-attachment-actions-container">
            <div className="message-container">
                {message}
            </div>
            <div className="buttons-container">
                {isNextPendingUploads && (
                    <Button
                        variant="outlined"
                        onClick={nextUploadHandler}
                        classes={{root: 'attachment-action-btn attachment-secondary-action-btn'}}
                    >
                        {intl.formatMessage({id: 'oss.components.AttachmentBottomBar.nextUpload', defaultMessage: ''})}
                    </Button>
                )}
                {showGoToDocumentsButton && (
                    <Button
                        variant="contained"
                        onClick={nextDocumentHandler}
                        classes={{root: 'attachment-action-btn attachment-primary-action-btn'}}
                    >
                        {intl.formatMessage({id: 'oss.components.AttachmentBottomBar.goToDocuments', defaultMessage: ''})}
                    </Button>
                )}
                {isVirtualRoomSigning && isRequiredAttachmentsUploaded && !isVirtualRoomHost && (
                    <Button
                        variant="outlined"
                        onClick={backToHostHandler}
                        classes={{root: 'attachment-action-btn attachment-primary-action-btn'}}
                    >
                        {intl.formatMessage({id: 'oss.components.AttachmentBottomBar.backToHostButton', defaultMessage: ''})}
                    </Button>
                )}
                {showFinishButton && (
                    <Button
                        variant="contained"
                        onClick={finishHandler}
                        classes={{root: 'attachment-action-btn attachment-primary-action-btn'}}
                    >
                        {intl.formatMessage({id: 'oss.components.AttachmentBottomBar.finish', defaultMessage: ''})}
                    </Button>
                )}
            </div>
        </section>
    );
}
