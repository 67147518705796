import React, {
    useState, useRef, useEffect
} from 'react';
import {KeyboardDatePicker} from '@material-ui/pickers';
import classNames from 'classnames';
import useDateFormats from '../../../hooks/useDateFormats';
import AppInputLabel from '../../Core/labels/AppInputLabel/AppInputLabel';
import AppTextField from '../../Core/inputs/AppTextField/AppTextField';

export default function DatePicker({
    editModeFormat, displayModeFormat, label, className, ...rest
}) {
    const ref = useRef();
    const [isInFocus, setIsInFocus] = useState(false);
    const {SHORT_DATE_FORMAT, SHORTER_DATE_FORMAT} = useDateFormats();
    const editFormat = editModeFormat || SHORT_DATE_FORMAT;
    const displayFormat = displayModeFormat || SHORTER_DATE_FORMAT;
    const format = isInFocus ? editFormat : displayFormat;
    const containerClassName = classNames('date-picker', className);

    function onFocus() {
        setIsInFocus(true);
    }

    function onBlur() {
        setIsInFocus(false);
    }

    useEffect(() => {
        if (isInFocus && ref.current) {
            ref.current.focus();
        }
    }, [isInFocus]);

    return (
        <>
            {label && <AppInputLabel>{label}</AppInputLabel>}
            <KeyboardDatePicker
                {...rest}
                className={containerClassName}
                onFocus={onFocus}
                onBlur={onBlur}
                format={format}
                inputRef={ref}
                TextFieldComponent={AppTextField}
            />
        </>

    );
}
