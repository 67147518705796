import {useMutation, useApolloClient} from '@apollo/client';
import {useSelector} from 'react-redux';
import UPDATE_FIELD from '../../../../../../gql/updateField.gql';
import {updateCache} from '../../utils';
import {selectSelectedDocumentId, selectTransactionId} from '../../../signerUiSlice';

export default (data) => {
    const {
        approvalId = data.approvalId || data.approval.originalId,
        hasConditions,
        isConditionalTarget,
        mutation = UPDATE_FIELD,
        mutationOptions,
        originalId,
        radioGroupId,
        checkboxGroupId
    } = data;

    const client = useApolloClient();
    const transactionId = useSelector(selectTransactionId);
    const documentId = useSelector(selectSelectedDocumentId);
    const [updateField, {loading, error}] = useMutation(mutation, {
        update: updateCache({
            checkboxGroupId,
            client,
            documentId,
            hasConditions,
            isConditionalTarget,
            originalId,
            radioGroupId,
            transactionId
        }),
        ...mutationOptions
    });

    const updateFieldValue = (value) => {
        updateField({
            variables: {
                approvalId,
                originalId,
                documentId,
                transactionId,
                value
            }
        });
    };

    return {updateFieldValue, loading, error};
};
