import {gql} from '@apollo/client';

export const GET_THEME = gql`query getTheme($transactionId: ID!) {
  theme(transactionId: $transactionId) {   
    colors {
      primary
      secondary
      success
      warning
      error
      info
      signatureButton
      optionalSignatureButton
      signatureSticky
      __typename
    }    
    __typename
}}`;
