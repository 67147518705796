import {DEFAULT_ZOOM_FACTOR, MAX_ZOOM_IN, ZOOM_STEP} from '../components/shared/Documents/constants';

/**
 * MIN ratio means how less times field can fit into viewport by width. If less times field can fit, zoom scale will be decreased.
 * MAX ratio means how many times field can fit into viewport by width. If more times field can fit, zoom scale will be increased.
 */
const MIN_FIELD_TO_VIEWPORT_RATIO_MOBILE = 1;
const MAX_FIELD_TO_VIEWPORT_RATIO_MOBILE = 2;
const MIN_FIELD_TO_VIEWPORT_RATIO = 2;
const MAX_FIELD_TO_VIEWPORT_RATIO = 8;

function isFieldWidthInRange({
    viewportWidth, fieldWidth, minRation, maxRatio
}) {
    const ratio = viewportWidth / fieldWidth;
    return ratio >= minRation && ratio <= maxRatio;
}

export function getFieldScaleFactorToFitViewport({
    viewportWidth, fieldWidth, isMobile
}) {
    const currentRatio = viewportWidth / fieldWidth;
    const minRation = isMobile ? MIN_FIELD_TO_VIEWPORT_RATIO_MOBILE : MIN_FIELD_TO_VIEWPORT_RATIO;
    const maxRatio = isMobile ? MAX_FIELD_TO_VIEWPORT_RATIO_MOBILE : MAX_FIELD_TO_VIEWPORT_RATIO;

    if (isFieldWidthInRange({
        viewportWidth, fieldWidth, minRation, maxRatio
    })) {
        // If the item width is already in the specified range, return 1
        return 1;
    }

    // Calculate the scale factor needed to bring the ratio within the range
    const scaleFactor = currentRatio > maxRatio
        ? viewportWidth / (maxRatio * fieldWidth)
        : viewportWidth / (minRation * fieldWidth);

    // Round the scale factor to the largest multiple of the step that still fits within the range
    let roundedScaleFactor = Math.floor(scaleFactor / ZOOM_STEP) * ZOOM_STEP;

    // Adjust to the highest scale factor that still fits within the range
    while (!isFieldWidthInRange({
        viewportWidth, fieldWidth: fieldWidth * roundedScaleFactor, minRation, maxRatio
    })) {
        roundedScaleFactor += ZOOM_STEP;
    }

    // Zoom out not more then default value and max zoom factor
    return Math.min(Math.max(roundedScaleFactor, DEFAULT_ZOOM_FACTOR), MAX_ZOOM_IN);
}