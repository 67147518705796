export default {
    Fields: {
        Field: {
            AutofillField: {
                id: 'AutofillField'
            },
            CustomField: {
                id: 'CustomField'
            },
            InputField: {
                Checkbox: {
                    Input: {
                        id: 'CheckboxInput'
                    },
                    id: 'Checkbox'
                },
                DateField: {
                    id: 'DateField',
                    Input: {id: 'DateField-Input'}
                },
                ListField: {
                    Input: {
                        id: 'ListFieldInput'
                    },
                    id: 'ListField'
                },
                RadioButton: {
                    Input: {
                        id: 'RadioButtonInput'
                    },
                    id: 'RadioButton'
                },
                TextField: {
                    Input: {
                        id: 'TextFieldInput'
                    },
                    id: 'TextField'
                },
                id: 'InputField'
            },
            Label: {
                id: 'Label'
            },
            SignatureField: {
                AcceptedSignature: {
                    UndoSignature: {
                        id: 'UndoSignature'
                    },
                    id: 'AcceptedSignature'
                },
                CaptureInline: {
                    id: 'CaptureInline'
                },
                CaptureSignatureButton: {
                    id: 'CaptureSignature'
                },
                CaptureSignatureOptionalButton: {
                    id: 'CaptureSignatureOptional'
                },
                FromFileImage: {
                    id: 'FromFileImage'
                },
                InitialButton: {
                    id: 'InitialButton'
                },
                InitialOptionalButton: {
                    id: 'InitialOptionalButton'
                },
                MobileCaptureButton: {
                    id: 'MobileCapture'
                },
                MobileCaptureOptionalButton: {
                    id: 'MobileCaptureOptional'
                },
                SignButton: {
                    id: 'SignButton'
                },
                SignOptionalButton: {
                    id: 'SignOptionalButton'
                },
                id: 'SignatureField'
            },
            id: 'Field'
        },
        id: 'Fields'
    }
};
