import React from 'react';
import {HiOutlineEye} from '@react-icons/all-files/hi/HiOutlineEye';
import {HiOutlinePencilAlt} from '@react-icons/all-files/hi/HiOutlinePencilAlt';
import {HiOutlineCheckCircle} from '@react-icons/all-files/hi/HiOutlineCheckCircle';
import {MdDonutLarge} from '@react-icons/all-files/md/MdDonutLarge';

export default function getDocIconAndStatus({isReviewer, isOwner}) {
    return (document = {}) => {
        const {isConsent, hasCurrentSignerApprovalsAsGroup} = document;
        if (
            document.isReview
          || isReviewer
          || (!hasCurrentSignerApprovalsAsGroup && isOwner && isConsent)
        ) {
            return {
                icon: <HiOutlineEye className="document-icon" />,
                status: 'oss.components.WelcomePage.status.review'
            };
        }
        if (document.isConfirmed) {
            return {
                icon: <HiOutlineCheckCircle className="document-icon is-done" />,
                status: 'oss.components.WelcomePage.status.done'
            };
        }
        if (document.isOngoing) {
            return {
                icon: <MdDonutLarge className="document-icon is-in-progress" />,
                status: 'oss.components.WelcomePage.status.ongoing'
            };
        }
        return {
            icon: <HiOutlinePencilAlt className="document-icon" />,
            status: 'oss.components.WelcomePage.status.toDo'
        };
    };
}
