export const DEFAULT_LOCALE = 'en';
export const LOCALES = [
    'nl',
    'en',
    'da',
    'de',
    'es',
    'fr',
    'it',
    'pt'
];
export const TRANSACTION_LOCALES = [
    'en',
    'da',
    'de',
    'es',
    'fr',
    'it',
    'nl',
    'pt',
    'ru',
    'zh-CN',
    'zh-TW',
    'ja',
    'ko',
    'el'
];

export const PER_PAGE_OPTIONS = {
    10: '10',
    20: '20',
    50: '50'
};

export const USER_STATUSES = {
    LOCKED: 'LOCKED',
    ACTIVE: 'ACTIVE',
    INVITED: 'INVITED'
};

export const DESIGNER_MOBILE_WIDTH = 1220;
export const DESIGNER_SM_WIDTH = 780;
export const DESIGNER_XS_WIDTH = 480;

export const SSO_ENTITY_ID = 'nl:ValidSign:sso:middleware';

export const THEMES = {
    LIGHT: 'light',
    DARK: 'dark',
    LIGHT_CONTRAST: 'light-contrast'
};

export const TERMS_AND_CONDITIONS_LINK = 'https://www.validsign.eu/terms-and-conditions/';
export const BROADCAST_CHANNEL_NAME = (env) => `vs-sender-ui-${env}`;
export const BROADCAST_MESSAGES = {
    TRANSACTIONS_UPDATED: 'TRANSACTIONS_UPDATED',
    SUBACCAUNT_CHANGED: 'SUBACCAUNT_CHANGED',
    DELEGATION_CHANGED: 'DELEGATION_CHANGED'
};

export const TAMPER_SEAL = 'Tamper Seal';
export const FETCH_OPTIONS_STEP = 20;
export const REQUEST_DELAY_IN_MS = 300;
export const RESPONSE_STATUS_CODES = {
    404: 404,
    403: 403
};

export const VS_FEATURES = {
    REMINDERS_CONSISTANCY: {id: 'remindersConcistency'},
    FLOATING_MENU: {id: 'floating-menu'},
    DESIGN_V2: {id: 'design-v2'}
};

export const MAX_DOCUMENTS_CHUNK_SIZE = 20 * 1024 * 1024; // 20 Mb

export const DEFAULT_PHONE_COUNTRY = 'NL';

export const SHORTCUTS = {
    SIGN_NEXT: 'SIGN_NEXT',
    CREATE_TRANSACTION: 'CREATE_TRANSACTION',
    CLONE_TRANSACTION: 'CLONE_TRANSACTION'
};

export const RESTRICTED_SHORTCUTS = {
    FEEDBACK: 'FEEDBACK',
    SUPPORT_PORTAL: 'SUPPORT_PORTAL',
    HIDE: 'HIDE'
};

export const LAZY_MIN_OPTIONS_TO_LOAD_MORE = 5;

export const Z_INDEX = {
    DIALOG: 1300,
    TOOLTIP: 1500,
    DEFAULT: 0,
    BASE: 1
};

export const SIGNATURE_TYPES = {
    UPLOAD_IMAGE: 'UPLOAD_IMAGE',
    DRAW_SIGNATURE: 'DRAW_SIGNATURE',
    IMAGE_AS_GRAPHICAL_SIGNATURE: 'IMAGE_AS_GRAPHICAL_SIGNATURE'
};
export const MAX_SIZE_1MB_BYTES = 1048576;

export const METHODS_TO_SIGN = {
    IMAGE: 'IMAGE',
    DRAWED_SIGNATURE: 'DRAWED_SIGNATURE'
};

export const SCREEN_SIZE = {
    XS: 480, // mobile
    SM: 780, // mobille
    MD: 992, // tablet
    LG: 1200 // desktop
};

export const CSRF_TOKEN_META_TAG_NAME = 'csrf-token';

export const DEVELOPMENT_ENV = 'development';
export const AUTOMATION_ENV = 'automation';
export const DEV_ENV = 'dev';
export const TRY_ENV = 'try';
export const MY_ENV = 'my';

export const SIGNATURE_V2_FEATURE = 'animated-perfect-signature';
