import {FaRegUser} from '@react-icons/all-files/fa/FaRegUser';
import {IoColorPaletteOutline} from '@react-icons/all-files/io5/IoColorPaletteOutline';
import {BiEdit} from '@react-icons/all-files/bi/BiEdit';
import {AiOutlineDollarCircle} from '@react-icons/all-files/ai/AiOutlineDollarCircle';
import {AiOutlineApi} from '@react-icons/all-files/ai/AiOutlineApi';
import {GrUserAdmin} from '@react-icons/all-files/gr/GrUserAdmin';
import loadable from '@loadable/component';
import queryString from 'query-string';
import pick from 'lodash.pick';
import SecurityIcon from '../components/shared/icons/Security';
import GroupIcon from '../components/shared/icons/Group';
import NotificationBell from '../components/shared/icons/Notification';
import permissionsService from '../utils/permissionsService';
import {
    ADMIN_USER_MANAGEMENT_PATH,
    ADMIN_GROUPS_PATH,
    ADMIN_SECURITY_PATH,
    ADMIN_THEMES_PATH,
    ADMIN_API_ACCESS_PATH,
    ADMIN_EVENT_NOTIFICATION_PATH,
    ADMIN_CUSTOM_FIELDS_PATH,
    ADMIN_SUBSCRIPTION_PATH,
    DEFAULT_GROUPS_OPTIONS,
    ADMIN_GROUP_PATH,
    ROLES_PATH,
    DEFAULT_USERS_OPTIONS,
    ADMIN_GROUPS_HEADINGS
} from '../../constants';

const defaultOptions = [
    'from',
    'to',
    'sort',
    'dir',
    'query',
    'search'
];
const groupsOptions = pick(DEFAULT_GROUPS_OPTIONS, defaultOptions);
const usersOptions = pick(DEFAULT_USERS_OPTIONS, defaultOptions);
const groupsQueryString = queryString.stringify(groupsOptions);
const usersQueryString = queryString.stringify(usersOptions);
const routes = [
    {
        path: `${ADMIN_USER_MANAGEMENT_PATH}`,
        component: loadable(
            () => import(/* webpackChunkName: "userManagement" */ '../components/AdminPanel/UserManagement/UserManagement')
        ),
        query: usersQueryString,
        titleKey: 'page.admin.userManagement',
        Icon: FaRegUser,
        isEnabled: permissionsService.hasUserManagementPermission,
        heading: ADMIN_GROUPS_HEADINGS.USERS
    }, {
        path: ROLES_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "roles" */ '../components/AdminPanel/Roles/Roles')
        ),
        titleKey: 'page.admin.roles',
        Icon: GrUserAdmin,
        isEnabled: permissionsService.hasRolesPermission,
        heading: ADMIN_GROUPS_HEADINGS.USERS
    }, {
        path: `${ADMIN_GROUPS_PATH}`,
        query: groupsQueryString,
        component: loadable(
            () => import(/* webpackChunkName: "groups" */ '../components/AdminPanel/Groups/AdminGroups')
        ),
        titleKey: 'esl.generic.groups',
        Icon: GroupIcon,
        isEnabled: permissionsService.hasGroupManagementPermission,
        heading: ADMIN_GROUPS_HEADINGS.USERS
    }, {
        path: `${ADMIN_GROUP_PATH}/:groupId?`,
        component: loadable(
            () => import(/* webpackChunkName: "groupDetails" */ '../components/AdminPanel/Groups/GroupDetails/AdminGroupDetails')
        ),
        titleKey: 'esl.generic.group',
        isInnerPage: true,
        isEnabled: permissionsService.hasGroupManagementPermission
    }, {
        path: ADMIN_SECURITY_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "securitySettings" */ '../components/AdminPanel/SecuritySettings/SecuritySettings')
        ),
        titleKey: 'page.admin.securitySettings',
        Icon: SecurityIcon,
        isEnabled: permissionsService.hasPasswordSettingsPermission,
        heading: ADMIN_GROUPS_HEADINGS.APP_SETTING
    }, {
        path: ADMIN_CUSTOM_FIELDS_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "customFields" */ '../components/AdminPanel/CustomFields/CustomFields')
        ),
        titleKey: 'esl.generic.custom_fields',
        Icon: BiEdit,
        isEnabled: permissionsService.hasCustomFieldPermission,
        heading: ADMIN_GROUPS_HEADINGS.APP_SETTING
    }, {
        path: ADMIN_THEMES_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "themesAndStyles" */ '../components/AdminPanel/ThemesAndStyles/ThemesAndStyles')
        ),
        titleKey: 'page.admin.themesAndStyles',
        Icon: IoColorPaletteOutline,
        isEnabled: permissionsService.hasCustomizationPermission,
        heading: ADMIN_GROUPS_HEADINGS.APP_SETTING
    },
    {
        path: ADMIN_API_ACCESS_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "apiAccesss" */ '../components/AdminPanel/ApiAccess/ApiAccess')
        ),
        titleKey: 'esl.generic.api_access',
        Icon: AiOutlineApi,
        isEnabled: permissionsService.hasApiAccessPermissions,
        heading: ADMIN_GROUPS_HEADINGS.INTEGRATION
    }, {
        path: ADMIN_EVENT_NOTIFICATION_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "eventNotification" */ '../components/AdminPanel/EventNotification/EventNotification')
        ),
        titleKey: 'esl.generic.event_notification',
        Icon: NotificationBell,
        isEnabled: permissionsService.hasEventNotificationPermission,
        heading: ADMIN_GROUPS_HEADINGS.INTEGRATION
    }, {
        path: ADMIN_SUBSCRIPTION_PATH,
        component: loadable(
            () => import(/* webpackChunkName: "subscription" */ '../components/AdminPanel/Subscription/Subscription')
        ),
        titleKey: 'esl.generic.subscription',
        Icon: AiOutlineDollarCircle,
        isEnabled: permissionsService.hasSubscriptionPermission,
        heading: ADMIN_GROUPS_HEADINGS.SUBSCRIPTION
    }
];

export default routes;
