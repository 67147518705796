/* eslint-disable camelcase */
import get from 'lodash.get';
import stringSimilarity from 'string-similarity';
import uniq from 'lodash.uniq';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import {
    DEVELOPMENT_ENV,
    DEV_ENV,
    TRY_ENV,
    DUE_DATE_DATE_TYPE, DUE_DATE_DAYS_TYPE, MAX_DAYS, PACKAGE_EXPIRY_DAYS, SIGNATURE_V2_FEATURE, TAMPER_SEAL, TRANSACTION_PATH
} from '../../constants';

export function textToNumber(e) {
    const {value} = e.target;
    const regExpr = new RegExp(/^\d*$/);
    if (regExpr.test(value)) {
        return e;
    }
}

export function configurePackageDueDate({selectedTemplate, personalPreferences}) {
    const personalPreferencesDue = get(personalPreferences, 'expiry_days_value', PACKAGE_EXPIRY_DAYS);
    if (!isEmpty(selectedTemplate)) {
        const templateExpiryDays = get(selectedTemplate, 'settings.ceremony.remainingDays', 0);
        if (templateExpiryDays > 0) {
            return moment().add(templateExpiryDays, 'days').toISOString();
        }
    } if (isEmpty(selectedTemplate) && personalPreferences.expiry_days && personalPreferencesDue > 0) {
        return moment().add(personalPreferencesDue, 'days').toISOString();
    }
    return null;
}

export function checkIsWrongExpirationDate(selectedTemplate) {
    const templateExpiryDays = get(selectedTemplate, 'settings.ceremony.remainingDays', 0);
    const templateHasWrongExpirationDate = templateExpiryDays <= 0
        && (moment(selectedTemplate?.due).isBefore(moment(), 'day') || moment().isSame(selectedTemplate?.due, 'day'));
    return !isEmpty(selectedTemplate) && templateHasWrongExpirationDate;
}

export function getMomentDateAtStartOfDay(date) {
    if (!date) return null;

    return moment(date).startOf('day');
}

export function getMomentDateAtEndOfDay(date) {
    if (!date) return null;

    return moment(date).endOf('day');
}

export function getISODateAtStartOfDay(date) {
    if (!date) return null;

    return getMomentDateAtStartOfDay(date).toISOString(true);
}

export function getISODateAtEndOfDay(date) {
    if (!date) return null;

    return getMomentDateAtEndOfDay(date).toISOString(true);
}

export function getSearchResults({options, search = '', optionKeys = []}) {
    const optionsResults = !isEmpty(options) ? options.filter((option) => {
        const optionKeysResults = optionKeys.map((key) => stringSimilarity
            .compareTwoStrings(option[key]?.toLowerCase() || '', search.toLowerCase()));
        const isSomeParamMatched = optionKeysResults.some((param) => param >= 0.3);
        return search === '' || search.length < 3 || isSomeParamMatched;
    }) : [];

    return optionsResults;
}

export function get30StartDaysToCurrent() {
    return new Date(new Date().setDate(new Date().getDate() - 30)).toISOString();
}

export function getVisibleApprovals(approvals = []) {
    return approvals.filter((approval) => !approval.id.includes(TAMPER_SEAL));
}

export function getRecipientsEmailsFromPackage(transactions = []) {
    if (!isEmpty(transactions)) {
        let emails = [];

        transactions.forEach((transaction) => {
            const roles = get(transaction, 'roles', []);
            emails = [...emails, ...roles.map((role) => get(role, 'signers[0].email'))];
        });
        return uniq(emails.filter((email) => !!email));
    }
    return [];
}

export function getAvatarByEmail(avatars, email) {
    if (!isEmpty(avatars) && email) {
        const userAvatar = avatars.find((avatar) => avatar.email === email);

        if (userAvatar && userAvatar.avatarSrc) {
            return userAvatar.avatarSrc;
        }
    }
    return null;
}

export function getTransactionIdFromSignPath(str = '') {
    const result = str.match(new RegExp(`${TRANSACTION_PATH}/([^/?]+)`));
    return result ? result[1] : '';
}

export const redirectTo = (url) => {
    if (window?.__CEREMONY__?.parentRedirection) {
        window.parent.location.assign(url);
    } else {
        window.location.assign(url);
    }
};

export const hardRedirectTo = (url) => {
    if (window?.__CEREMONY__?.parentRedirection) {
        window.parent.location.replace(url);
    } else {
        window.location.replace(url);
    }
};
export const getVerificationToken = () => window?.__CEREMONY__?.verificationResponseToken;
export const isAdaEnabled = (transaction) => transaction.isAda;

export function getErrorMessage({intl, error}) {
    if (!isEmpty(error)) {
        return intl.formatMessage(
            {id: `esl.${get(error, 'data.messageKey', 'esl.error_500.oops_broken')}`, defaultMessage: ''},
            get(error, 'data.parameters', {})
        );
    }
}

export function isEmail(emailAdress) {
    // eslint-disable-next-line no-useless-escape
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailAdress.match(regex)) return true;

    return false;
}

function hasEnvFeature({featureKey, env}) {
    if (featureKey === SIGNATURE_V2_FEATURE) {
        return env === DEVELOPMENT_ENV || env === DEV_ENV || env === TRY_ENV;
    }
    return false;
}

function hasVsFeature({id: featureKey, vsFeatures = []}) {
    return !!vsFeatures.find(({key}) => key === featureKey);
}

export function isVsFeatureEnabled({feature = {}, vsFeatures = [], env}) {
    const {
        id = '',
        isEnabledByDefault = false
    } = feature;
    const hasFeature = hasVsFeature({id, vsFeatures});

    if (env) {
        return hasEnvFeature({featureKey: id, env});
    }

    if (hasFeature) {
        return vsFeatures.some(({key, activated}) => key === id && activated);
    }

    return isEnabledByDefault;
}

export function getExpirationTimeType(packageData) {
    const remainingDays = get(packageData, 'settings.ceremony.remainingDays', 0);
    return remainingDays > 0 ? DUE_DATE_DAYS_TYPE : DUE_DATE_DATE_TYPE;
}

export function getExpirationErrors({
    packageData = {},
    expirationTimeType = getExpirationTimeType(packageData),
    getLongDate = () => {},
    getFormatErrorMessage = () => {},
    SHORT_DATE_FORMAT,
    date = packageData.due

}) {
    const isWrongExpirationDate = checkIsWrongExpirationDate(packageData);
    const maxDate = moment().add(MAX_DAYS, 'days');
    const maxDateLabel = getLongDate(maxDate);
    const maxDateError = expirationTimeType === DUE_DATE_DATE_TYPE && moment(date).isAfter(maxDate);
    const minDateError = expirationTimeType === DUE_DATE_DATE_TYPE && isWrongExpirationDate;
    const isValid = !date || moment(moment(date), SHORT_DATE_FORMAT).isValid();
    return (minDateError || maxDateError || !isValid || isWrongExpirationDate) ? {
        minDateError,
        maxDateError,
        formatErrorLabel: isValid || !date ? '' : getFormatErrorMessage(),
        maxDateLabel,
        isWrongExpirationDate
    } : {};
}

export function getExpirationErrorMessages({
    minDateError, maxDateError, maxDateLabel, formatErrorLabel, intl
}) {
    if (formatErrorLabel) {
        return formatErrorLabel;
    } if (maxDateError) {
        return intl.formatMessage(
            {id: 'esl.error.validation.packageSettings.maximum_expiry_date_exceeded', defaultMessage: ''},
            {maxDate: maxDateLabel}
        );
    } if (minDateError) {
        return intl.formatMessage(
            {id: 'esl.error.validation.settingDueDate.pastDueDate', defaultMessage: ''}
        );
    }
    return null;
}

export function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function sequentialAsync(values, callback) {
    return values.reduce(async (acc, value) => {
        const resultsObject = await acc;
        try {
            const result = await callback(value);
            return {
                ...resultsObject,
                results: [...resultsObject.results, result]
            };
        } catch (error) {
            return {
                ...resultsObject,
                errors: [...resultsObject.errors, error]
            };
        }
    }, Promise.resolve({
        results: [],
        errors: []
    }));
}
