import Login from '@components/SignerUi/Login/Login';
import SigningCeremony from '@components/SignerUi/SigningCeremony/SigningCeremony';
import {
    NEW_DIRECT_AUTH_PATH,
    NEW_DIRECT_SIGN_PATH, TRANSACTION_PATH
} from '../../constants';

const routes = [{
    path: `${TRANSACTION_PATH}/:packageId${NEW_DIRECT_SIGN_PATH}*`,
    component: SigningCeremony,
    end: true
}, {
    path: `${NEW_DIRECT_AUTH_PATH}/*`,
    component: Login,
    end: true
}];

export default routes;
