import {forwardRef} from 'react';
import {Panel} from './components/Panel';
import {PerfectSignatureView} from './components/View';
import {useKeyboardShortcuts} from './hooks/useKeyboardShortcuts';
import {ORIENTATION, useScreenOrientation} from '../../../../reusable/hooks/useScreenOrientation';
import {Colors} from './components/Colors';
import './perfectSignature.less';

export const PerfectSignature = forwardRef(({exportSvgRef, actions, ...perfectSignatureProps}, ref) => {
    const {
        handlePointerDown,
        handlePointerMove,
        handlePointerUp,
        undo,
        redo,
        clear,
        animateDrawing,
        handleColorHover,
        handleColorLeave,
        handleColorChange,
        lines,
        tempOptions,
        tempColor,
        currentStroke,
        scaledLines,
        scaledOptions,
        options,
        color,
        undoStack,
        redoStack
    } = perfectSignatureProps;

    const {orientation} = useScreenOrientation();
    const isLandscape = orientation === ORIENTATION.LANDSCAPE;

    const handlePointerDownWithPresets = (...args) => {
        handlePointerDown(...args);
    };

    const handlePointerUpWithPresets = (...args) => {
        handlePointerUp(...args);
    };

    useKeyboardShortcuts(undo, redo, clear);

    return (
        <div className={`svg-drawer-container ${isLandscape ? 'landscape' : 'portrait'}`}>
            <Panel
                undo={{
                    onClick: undo,
                    disabled: lines?.length === 0 && undoStack?.length <= 1
                }}
                redo={{
                    onClick: redo,
                    disabled: redoStack.length === 0
                }}
                play={{
                    onClick: animateDrawing,
                    disabled: lines.length === 0
                }}
                clear={{
                    onClick: clear,
                    disabled: lines.length === 0
                }}
                isLandscape={isLandscape}
            />
            <div className="content-wrapper">
                <div className="svg-drawer-wrapper">
                    <PerfectSignatureView
                        svgRef={ref}
                        scaledSvgRef={exportSvgRef}
                        currentStroke={currentStroke}
                        color={tempColor || color}
                        lines={lines}
                        options={tempOptions || options}
                        scaledLines={scaledLines}
                        scaledOptions={scaledOptions}
                        handlePointerDown={handlePointerDownWithPresets}
                        handlePointerMove={handlePointerMove}
                        handlePointerUp={handlePointerUpWithPresets}
                        isLandscape={isLandscape}
                    />
                </div>
                <Colors
                    color={tempColor || color}
                    handleColorChange={handleColorChange}
                    handleColorHover={handleColorHover}
                    handleColorLeave={handleColorLeave}
                />
                <div className="bottom-right-actions-container">
                    {actions}
                </div>
            </div>
        </div>
    );
});
