/* eslint-disable react/no-danger */
import React, {useState, createRef} from 'react';
import {toast} from 'react-toastify';
import IconButton from '@material-ui/core/IconButton';
import {HiOutlineX} from '@react-icons/all-files/hi/HiOutlineX';
import {HiOutlineExclamation} from '@react-icons/all-files/hi/HiOutlineExclamation';
import {HiOutlineCheckCircle} from '@react-icons/all-files/hi/HiOutlineCheckCircle';

const CloseButton = ({closeToast}) => (
    <div className="toast-close-button-container">
        <IconButton
            size="small"
            edge="end"
            classes={{root: 'toast-close-icon-container'}}
            onClick={closeToast}
        >
            <HiOutlineX className="toast-close-icon" />
        </IconButton>
    </div>
);

const getErrorMessage = (msg, count) => (
    <div
        className="toast-alert error"
        data-wdio="test-toast-error-notification"
    >
        <div className="toast-alert-icon-container">
            <HiOutlineExclamation className="toast-alert-icon" />
        </div>
        <div className="toast-alert-msg-container">
            <div
                className="toast-alert-msg"
                dangerouslySetInnerHTML={{
                    __html: msg
                }}
            />
            {count && count > 1 && (
                <div className="toast-alert-msg-count">
                    {`(${count})`}
                </div>
            )}
        </div>
    </div>
);

const getSuccessMessage = (msg, count) => (
    <div
        className="toast-alert success"
        data-wdio="test-toast-success-notification"
    >
        <div className="toast-alert-icon-container">
            <HiOutlineCheckCircle className="toast-alert-icon" />
        </div>
        <div className="toast-alert-msg-container">
            <div
                className="toast-alert-msg"
                dangerouslySetInnerHTML={{
                    __html: msg
                }}
            />
            {count && count > 1 && (
                <div className="toast-alert-msg-count">
                    {`(${count})`}
                </div>
            )}
        </div>
    </div>
);

export default function useNotifications() {
    const [notifications, setNotification] = useState([]);

    function removeNotifications(msg) {
        setNotification((prevNotifications) => prevNotifications.filter(({message}) => message !== msg));
    }

    function addNotification(message, getMessage, props) {
        const notification = notifications.find(({message: notificationMessage}) => notificationMessage === message);

        if (notification) {
            const updatedCount = notification.count + 1;
            toast.update(notification.toastRef.current, {
                render: getMessage(message, updatedCount)
            });
            setNotification((prevNotifications) => prevNotifications.map((prevnotification) => {
                if (prevnotification.message === notification.message) {
                    return {
                        ...notification,
                        count: updatedCount
                    };
                }
                return prevnotification;
            }));
            return notification.toastRef.current;
        }

        const toastRef = createRef();
        const toastId = toast(getMessage(message), {
            ...props,
            onClose: () => removeNotifications(message)
        });
        toastRef.current = toastId;
        const newNotification = {
            toastRef,
            message,
            count: 1
        };
        setNotification((prevNotifications) => ([
            ...prevNotifications,
            newNotification
        ]));

        return toastId;
    }

    function showErrorAlert(msg, props = {}) {
        return addNotification(msg, getErrorMessage, {
            autoClose: 10000,
            className: 'toast-notification error',
            bodyClassName: 'toast-notification-body',
            progressClassName: 'toast-notification-progress-bar',
            closeButton: CloseButton,
            ...props
        });
    }

    function showSuccessAlert(msg, props = {}) {
        return addNotification(msg, getSuccessMessage, {
            className: 'toast-notification success',
            bodyClassName: 'toast-notification-body',
            progressClassName: 'toast-notification-progress-bar',
            closeButton: CloseButton,
            ...props
        });
    }

    return {
        showErrorAlert,
        showSuccessAlert
    };
}
