import React from 'react';
import {useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import isEmpty from 'lodash.isempty';
import MicrosoftIcon from '../../../shared/icons/sso/Microsoft';
import SurfIcon from '../../../shared/icons/sso/SurfIcon';
import OktaIcon from '../../../shared/icons/sso/OktaIcon';
import AzureIcon from '../../../shared/icons/sso/Azure';
import UnknownIdpIcon from '../../../shared/icons/sso/UnknownIdpIcon';
import {
    selectSsoIdpDiscoReturnURL,
    selectSsoIdps
} from '../../userSlice';
import {DASHBOARD_PATH} from '../../../../../constants';
import './ssoForm.less';

export default function SsoForm({wasSsoFetchedForEmail, ssoLoginRequired}) {
    const intl = useIntl();
    const ssoIdpDiscoReturnURL = useSelector(selectSsoIdpDiscoReturnURL);
    const ssoIdps = useSelector(selectSsoIdps);
    const hasSso = !!ssoIdpDiscoReturnURL && !isEmpty(ssoIdps);
    const ssoCallbackUrl = typeof window !== 'undefined' ? `${window.location.origin}${DASHBOARD_PATH}` : '';
    const ssoTitleClassnames = classnames('sso-title', {
        'is-visible': !ssoLoginRequired
    });
    const idpIcons = [
        {
            name: 'Microsoft',
            icon: <AzureIcon className="sso-icon" />
        },
        {
            name: 'Surf',
            icon: <SurfIcon className="sso-icon" />
        },
        {
            name: 'Okta',
            icon: <OktaIcon className="sso-icon" />
        },
        {
            name: 'ADFS',
            icon: <MicrosoftIcon className="sso-icon" />
        }
    ];

    function getIcon(name = '') {
        const iconObject = idpIcons.find((idp) => idp.name.toLowerCase() === name.toLowerCase());

        if (!iconObject) {
            return <UnknownIdpIcon className="sso-icon" />;
        }

        return iconObject.icon;
    }

    function renderSsoButton(ssoIdp = {}) {
        const {name, url} = ssoIdp;
        const ssoUrl = ssoIdp ? `${ssoIdpDiscoReturnURL}&idp=${url}&callbackUrl=${ssoCallbackUrl}` : '';

        return (
            <Button
                href={ssoUrl}
                classes={{root: 'sso-login-btn'}}
                variant="outlined"
                startIcon={getIcon(name)}
                key={name}
            >
                <div className="sso-name">
                    {intl.formatMessage({id: 'page.login.sso.loginWith', defaultMessage: ''}, {
                        name
                    })}
                </div>
            </Button>
        );
    }

    return (
        <Collapse in={hasSso && !!wasSsoFetchedForEmail}>
            <div className="login-sso-form">
                <div className="sso-title-container">
                    <div className="sso-divider" />
                    <div className={ssoTitleClassnames}>
                        {intl.formatMessage({id: 'esl.generic.or', defaultMessage: ''})}
                    </div>
                </div>
                <div className="sso-buttons-container">
                    {ssoIdps.map(renderSsoButton)}
                </div>
            </div>
        </Collapse>
    );
}
