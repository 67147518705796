import {selectUser} from '@components/Login/userSlice';
import {useSelector} from 'react-redux';

export default function useHasPreferenceEnabled({
    preferenceName, 
    defaultValue = false
}) {
    const user = useSelector(selectUser);

    const personalPreferences = user?.data?.personal_preferences?.[preferenceName];

    if (typeof personalPreferences === 'undefined') {
        return defaultValue;
    }

    return personalPreferences;
}
