/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
// import errorMessages from './errorMessages';

// Function from classic-ui
export function versionCompare(v1, v2, options) {
    const lexicographical = options && options.lexicographical;
    const zeroExtend = options && options.zeroExtend;
    let v1parts = v1.split('.');
    let v2parts = v2.split('.');

    function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }

    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push('0');
        while (v2parts.length < v1parts.length) v2parts.push('0');
    }

    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }

    for (let i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
            return 1;
        }

        if (v1parts[i] === v2parts[i]) {
            continue;
        } else if (v1parts[i] > v2parts[i]) {
            return 1;
        } else {
            return -1;
        }
    }

    if (v1parts.length !== v2parts.length) {
        return -1;
    }

    return 0;
}

// Returns the matching error translation
export function getPCCErrorMessages(error) {
    return error.messageKeys.map((msgKey) => msgKey.replace(/esl/g, 'oss'));
}

function dateComparator(a, b) {
    const firstDate = new Date(a);
    const secondDate = new Date(b);
    if (firstDate > secondDate) return 1;
    if (firstDate < secondDate) return -1;
    return 0;
}

export function getComparator(order, orderBy) {
    const isExpiryDate = orderBy === 'expiryDate';
    return order === 'desc'
        ? (a, b) => (isExpiryDate
            ? dateComparator(a[orderBy], b[orderBy])
            : -a[orderBy].localeCompare(b[orderBy]))
        : (a, b) => (isExpiryDate
            ? -dateComparator(a[orderBy], b[orderBy])
            : a[orderBy].localeCompare(b[orderBy]));
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
