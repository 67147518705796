import React, {
    useState, useEffect, memo, useCallback
} from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';
import debounce from 'lodash.debounce';
import {useDebounce} from '@react-hook/debounce';
import DocumentRenderer from '../Renderers/DocumentRenderer/DocumentRenderer';
import usePageWithDimensions from '../hooks/usePageWithDimensions';
import useWindowSize from '../../../../hooks/useWindowSize';
import useElementSize from '../../../../hooks/useElementSize';
import {
    getActivePageId,
    getDocIdAndPageIndexByPageId,
    getPageWithDimensionsByPageId,
    getRenderedPagesIds
} from '../utils';
import {SCROLL_SPEED_MS} from '../constants';
import {useScrollContext} from '../ScrollContext';
import usePinchAndZoom from '../hooks/usePinchAndZoom';
import './documentsContainer.less';

const containerWidthDebounceTimeoutMS = 225;

function DocumentsContainer({
    /**
     * activePageIdToNavigate makes component controlable from outside.
     * Because of 'smooth-scrollbar' we can't do it straight way and use separate value.
     * activePageIdToNavigate and activePageId are synchronized with delays.
     */
    activePageId: activePageIdToNavigate,
    transactionId,
    documents,
    documentProps,
    pageProps,
    pageFooterProps,
    zoomFactor = 1,
    className,
    onZoomChange,
    onScroll = () => {},
    renderChild = () => {}
}) {
    const containerClassName = classnames('documents-renderer-container', className);
    const windowSize = useWindowSize();
    const [containerWidth, setContainerWidth] = useDebounce(0, containerWidthDebounceTimeoutMS);
    const [isReadyForRender, setIsReadyForRender] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const {
        isDocumentNameVisible,
        isPageCountVisible,
        renderChild: footerRenderChild
    } = pageFooterProps;
    const isFooterVisible = isDocumentNameVisible || isPageCountVisible || footerRenderChild;
    const pagesWithDimensions = usePageWithDimensions({
        documents,
        containerWidth,
        zoomFactor,
        isFooterVisible
    });
    const {
        containerRef,
        contentRef,
        scrollBar,
        topOffset,
        leftOffset,
        scrollDirection,
        scrollDimensions,
        reCalculateScrollBar = () => {}
    } = useScrollContext();
    const renderedPagesIds = getRenderedPagesIds({topOffset, pagesWithDimensions, scrollDirection});
    const activePageId = getActivePageId({topOffset, pagesWithDimensions});
    const containerSize = useElementSize(containerRef);

    usePinchAndZoom({
        ref: containerRef, zoomFactor, onZoomChange
    });

    /**
    * handleActiveIdPagePropChange function is called when active page ID changes by parent
    * and it scrolls to the new active page
    */
    function handleActivePageIdToNavigateChange() {
        if (activePageIdToNavigate !== activePageId && scrollBar) {
            const pageDimensions = getPageWithDimensionsByPageId({
                pagesWithDimensions,
                pageId: activePageIdToNavigate
            });
            const scrollToOffset = pageDimensions?.topBoundary;

            scrollBar.scrollTo(0, scrollToOffset, SCROLL_SPEED_MS);
        }
    }

    function calculateAndSetContainerWidth() {
        if (!contentRef?.current) {
            return;
        }

        const newContainerWidth = contentRef?.current.getBoundingClientRect().width;
        setContainerWidth(newContainerWidth);
    }

    const onScrollDebounced = useCallback(debounce((activeDocIdAndPageIndex) => {
        onScroll(activeDocIdAndPageIndex);
    }, SCROLL_SPEED_MS), []);

    useEffect(() => {
        if (activePageId) {
            const activeDocIdAndPageIndex = getDocIdAndPageIndexByPageId({pageId: activePageId});

            onScrollDebounced(activeDocIdAndPageIndex);
            /**
             * RFT-336 -- Added this timeout to fake some loading time, which
             * gives the application time to show the right active page.
             */
            setTimeout(() => {
                setIsReady(true);
            }, 1000);
        }
    }, [activePageId]);

    useEffect(() => {
        handleActivePageIdToNavigateChange();
    }, [activePageIdToNavigate]);

    useEffect(() => {
        reCalculateScrollBar();
    }, [zoomFactor]);

    useEffect(() => {
        calculateAndSetContainerWidth();
        setIsReadyForRender(true);
    }, [contentRef?.current, windowSize, containerSize.width, containerSize.height]);

    return (
        <div className={containerClassName}>
            <div className="documents-renderer-content-wrapper" ref={containerRef}>
                <div className="documents-renderer-content" ref={contentRef}>
                    {isReadyForRender && isReady && !isEmpty(documents) && documents.map((document) => (
                        <DocumentRenderer
                            transactionId={transactionId}
                            document={document}
                            pagesWithDimensions={pagesWithDimensions}
                            renderedPagesIds={renderedPagesIds}
                            topOffset={topOffset}
                            leftOffset={leftOffset}
                            scrollDimensions={scrollDimensions}
                            pageProps={pageProps}
                            documentProps={documentProps}
                            pageFooterProps={pageFooterProps}
                            isFooterVisible={isFooterVisible}
                            key={document.id}
                        />
                    ))}
                </div>
            </div>
            {renderChild({
                pagesWithDimensions
            })}
        </div>
    );
}

export default memo(DocumentsContainer);
